// Libraries
import { useState, useEffect } from 'react';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Grid,
    GridProps,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ExpandIcon from '@mui/icons-material/ExpandMore';

// Core
import FormValidator from '../core/FormValidator';
import { paramRepository, ParameterValues } from '../core/resources';
import utils from '../core/utils';
import auth from '../core/authorization';

// Common
import { ViewTitle } from '../common/typography';
import { AAutocomplete, AAutocompleteValue } from '../common/inputs';

// Api
import api from '../api/contentApi';

// Feature
import './contentEditor.scss';
import VersionList from './editor/VersionList';
import { IEditableContent, EditableContentType } from './IEditableContent';
import ContentForm from './editor/ContentForm';
import contentUtils from './editor/contentUtils';
import EditorContainer from './editor/EditorContainer';

interface IEditorState {
    currentStep: 1 | 2 | 3;
    selectedContent: IEditableContent | null;
    selectedType: EditableContentType | null;
}

export default function ContentEditor() {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['AnelmaLayout', 'AnelmaGeneral', 'AnelmaContent']);

    const [editorState, setEditorState] = useState<IEditorState>({
        currentStep: 1,
        selectedContent: null,
        selectedType: null,
    });
    const [contents, setContents] = useState<IEditableContent[]>([]);
    const [editorFi, setEditorFi] = useState<EditorJS>();
    const [editorSv, setEditorSv] = useState<EditorJS>();
    const [editorEn, setEditorEn] = useState<EditorJS>();
    const [loading, setLoading] = useState<boolean>(false);
    const [types, setTypes] = useState<ParameterValues>([]);
    const [copiedContent, setCopiedContent] = useState<OutputData>();

    useEffect(() => {
        paramRepository.load(['AnelmaContent']).then(() => {
            setTypes(paramRepository.resource('AnelmaContent', 'ContentType'));
        });
    }, []);

    useEffect(() => {
        if (!editorState.selectedType) {
            setContents([]);
            return;
        }

        setLoading(true);
        api.getContentListByType(editorState.selectedType).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaContent:1002'), {
                    variant: 'error',
                });
            }

            response?.Items.forEach((i) => (i._uiId = utils.generateUniqueId()));

            setContents(response ? response.Items : []);
            setLoading(false);
        });
    }, [editorState.selectedType]);

    const save = (from: string, due: string, comment: string) => {
        setLoading(true);

        Promise.all([editorFi?.save(), editorSv?.save(), editorEn?.save()]).then((newContents) => {
            for (let i = 0; i < newContents.length; i++) {
                if (newContents[i]?.blocks.length === 0) {
                    enqueueSnackbar(t('AnelmaContent:1031'), {
                        variant: 'error',
                    });
                    setLoading(false);
                    return;
                }
            }

            if (!editorState.selectedContent) return;

            const savedData: IEditableContent = {
                ...editorState.selectedContent,
                Comment: comment,
                Translations: [
                    {
                        ContentId: editorState.selectedContent.Id,
                        Content: newContents[0] ? JSON.stringify(newContents[0]) : '',
                        Culture: 'fi',
                    },
                    {
                        ContentId: editorState.selectedContent.Id,
                        Content: newContents[1] ? JSON.stringify(newContents[1]) : '',
                        Culture: 'sv-SE',
                    },
                    {
                        ContentId: editorState.selectedContent.Id,
                        Content: newContents[2] ? JSON.stringify(newContents[2]) : '',
                        Culture: 'en-GB',
                    },
                ],
                Due: due || undefined,
                From: from || undefined,
            };

            const isUpdate = !!savedData.Id;
            const promise = isUpdate ? api.updateContent(savedData) : api.createContent(savedData);

            promise.then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaContent:1003'), {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar(t('AnelmaContent:1004'), {
                        variant: 'success',
                    });
                    const newContents: IEditableContent[] = [...contents];

                    response.Entity._uiId = savedData._uiId;

                    if (isUpdate) {
                        const index = newContents.findIndex((i) => i.Id === savedData.Id);
                        if (index !== -1) newContents[index] = response.Entity;
                    } else {
                        const index = newContents.findIndex((i) => !i.Id);
                        if (index !== -1) newContents[index] = response.Entity;
                    }
                    setContents(newContents);
                    changeVersion(response.Entity);
                }
                setLoading(false);
            });
        });
    };

    const getStep1Title = (type: EditableContentType | null) => {
        if (!type) return '';
        return types.find((i) => i.code === `${type}`)?.text || '';
    };

    const getStep2Title = (content: IEditableContent | null) => {
        if (!content) return '';

        const validity = contentUtils.contentValidity(t, content, contents);
        const from = utils.date.formatUtcString(content.From, 'DD.MM.YYYY HH:mm');
        const due = utils.date.formatUtcString(content.Due, 'DD.MM.YYYY HH:mm');
        const comment = content.Comment ? `, ${content.Comment}` : '';
        const interval = due ? `${from} - ${due}` : `${from} -`;

        return `${validity}, ${interval}${comment}`;
    };

    const changeStep = (v: 1 | 2 | 3) => {
        const newState: IEditorState = { ...editorState, currentStep: v };
        setEditorState(newState);
    };

    const changeType = (v: AAutocompleteValue) => {
        const newState: IEditorState = {
            ...editorState,
            selectedContent: null,
            selectedType: v ? parseInt(`${v}`, 10) : null,
        };

        if (newState.selectedType !== null) newState.currentStep = 2;

        setEditorState(newState);
    };

    const changeVersion = (v: IEditableContent | null) => {
        const newState: IEditorState = { ...editorState, selectedContent: v };

        if (newState.selectedContent !== null) newState.currentStep = 3;

        setEditorState(newState);
    };

    const col1: GridProps = {
        xs: 12,
        sm: 4,
        md: 2,
    };

    const col2: GridProps = {
        xs: 12,
        sm: 8,
        md: 10,
    };

    return (
        <Container data-robot-id={'app-body-content-editor'}>
            <ViewTitle>
                <>
                    {t('AnelmaLayout:1032')}
                    <Tooltip
                        title={
                            <>
                                Ominaisuudet:
                                <ul style={{ paddingLeft: 10 }}>
                                    <li>Otsikot</li>
                                    <li>Leipäteksti</li>
                                    <li>Listat</li>
                                    <li>Taulukot</li>
                                    <li>Kuvat</li>
                                    <li>Raaka HTML</li>
                                </ul>
                            </>
                        }
                    >
                        <InfoIcon />
                    </Tooltip>
                </>
            </ViewTitle>

            <Accordion
                disabled={!auth.canViewContentManagement}
                expanded={editorState.currentStep === 1}
                onChange={() => changeStep(1)}
            >
                <AccordionSummary expandIcon={<ExpandIcon />}>
                    <Grid container>
                        <Grid item {...col1}>
                            <Typography variant='h4'>{t('AnelmaContent:1005')}</Typography>
                        </Grid>

                        <Grid item {...col2}>
                            <Typography>{getStep1Title(editorState.selectedType)}</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                {auth.canViewContentManagement && (
                    <AccordionDetails>
                        <Grid container>
                            <Grid item xs={12}>
                                <AAutocomplete
                                    label={t('AnelmaContent:1006')}
                                    id='content-type'
                                    onChange={(v) => changeType(v)}
                                    parameterName='AnelmaContent:ContentType'
                                    sortValues='alphabetical'
                                    validator={new FormValidator()}
                                    value={
                                        editorState.selectedType
                                            ? `${editorState.selectedType}`
                                            : null
                                    }
                                    withoutContainer
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                )}
            </Accordion>

            <Accordion
                disabled={!editorState.selectedType}
                expanded={editorState.currentStep === 2}
                onChange={() => changeStep(2)}
            >
                <AccordionSummary expandIcon={<ExpandIcon />}>
                    <Grid container>
                        <Grid item {...col1}>
                            <Typography variant='h4'>{t('AnelmaContent:1007')}</Typography>
                        </Grid>

                        <Grid item {...col2}>
                            <Typography>{getStep2Title(editorState.selectedContent)}</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    {editorState.selectedType && (
                        <VersionList
                            addVersion={(v) => setContents([v])}
                            contents={contents}
                            loading={loading}
                            onSelect={(v) => changeVersion(v)}
                            type={editorState.selectedType}
                        />
                    )}
                </AccordionDetails>
            </Accordion>

            <Accordion
                disabled={!editorState.selectedContent}
                expanded={editorState.currentStep === 3}
                onChange={() => changeStep(3)}
            >
                <AccordionSummary expandIcon={<ExpandIcon />}>
                    <Typography variant='h4'>{t('AnelmaContent:1008')}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {editorState.selectedContent && (
                        <>
                            <ContentForm
                                content={editorState.selectedContent}
                                onSave={(from, due, comment) => save(from, due, comment)}
                                t={t}
                                validator={new FormValidator()}
                            />
                        </>
                    )}
                </AccordionDetails>
            </Accordion>

            {editorState.selectedContent?.Translations && auth.canModifyContentManagement && (
                <>
                    <EditorContainer
                        content={
                            editorState.selectedContent.Translations.find(
                                (i) => i.Culture.trim() === 'fi'
                            )?.Content
                        }
                        copiedContent={copiedContent}
                        editor={editorFi}
                        setCopiedContent={setCopiedContent}
                        setEditor={(e) => setEditorFi(e)}
                        title={t('AnelmaContent:1009')}
                    />

                    <EditorContainer
                        content={
                            editorState.selectedContent.Translations.find(
                                (i) => i.Culture.trim() === 'sv-SE'
                            )?.Content
                        }
                        copiedContent={copiedContent}
                        editor={editorSv}
                        setCopiedContent={setCopiedContent}
                        setEditor={(e) => setEditorSv(e)}
                        title={t('AnelmaContent:1010')}
                    />

                    <EditorContainer
                        content={
                            editorState.selectedContent.Translations.find(
                                (i) => i.Culture.trim() === 'en-GB'
                            )?.Content
                        }
                        copiedContent={copiedContent}
                        editor={editorEn}
                        setCopiedContent={setCopiedContent}
                        setEditor={(e) => setEditorEn(e)}
                        title={t('AnelmaContent:1011')}
                    />
                </>
            )}
        </Container>
    );
}
