import {
    IProductionPlan,
    IProductionPlanSummary,
    IProductionPlanUserAccess,
} from '../../../interfaces/IProductionPlan';

import { ProductionPlanType } from '../../../interfaces/enums';

export default function (
    plan: IProductionPlan | IProductionPlanSummary,
    access: IProductionPlanUserAccess
) {
    const planType = (<IProductionPlanSummary>plan).PlanType
        ? (<IProductionPlanSummary>plan).PlanType
        : (<IProductionPlan>plan).Type;
    if (access.IsAdmin && access.CanUpdate && planType !== ProductionPlanType.Approved)
        return false;
    else if (access.IsAdmin || planType === ProductionPlanType.Approved) return true;
    return true;
}
