// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
import moment from 'moment';

// MUI
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

// Core
import {
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../../../core/resources';
import { dayFormat } from '../../../../core/constants';

// Common
import { ADataGrid } from '../../../../common/dataGrid';
import { AGridColumns, gridColumns } from '../../../../common/dataGrid';
import { TextWithTooltip } from '../../../../common/typography';
import { ADeleteIconButton } from '../../../../common/buttons';

// Interfaces
import { DialogModeEnum } from '../../../../interfaces/enums';
import {
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
} from '../../../../interfaces/IAnnouncement';
import { IAnimalSummaryExtraInfo } from '../../../../interfaces/IAnimal';

// Store
// API
// Feature - Announcements
import { SecondaryDialogTypeEnum } from '../../types/enum';
import ConfirmDialog from '../confirm/ConfirmDialog';
import { collectWeekString } from '../../../announcements/helpers/collectWeek';

export interface ISecondaryDialogDataGridProps {
    id?: string;
    fromDialog: SecondaryDialogTypeEnum;
    mode: DialogModeEnum;
    handleRowSelectionChange: (params: GridRowSelectionModel) => void;
    handleRowDeleteClick: (animalId: string) => void;
    data: IAnimalSummaryExtraInfo[] | null;
    animals: IAnimalAnnouncementAnimal[] | undefined;
    batches?: IAnimalAnnouncementBatch[];
}

export default function SecondaryDialogDataGrid(props: ISecondaryDialogDataGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);

    const [bovineSexParams, setBovineSexParams] = useState<ParameterValues>([]);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues>([]);
    const [collecWeekParams, setCollecWeekParams] = useState<ParameterValues>([]);
    const [selectedAnimalId, setSelectedAnimalId] = useState<string>('');
    const [dataRows, setDataRows] = useState<IAnimalSummaryExtraInfo[]>([]);
    const [animalsRows, setAnimalsRows] = useState<IAnimalAnnouncementAnimal[]>([]);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);

    const defaultColumns: AGridColumns = [
        gridColumns.euIdentifier(t('AnelmaBovine:1005')),
        gridColumns.dateTime('BirthDate', t('AnelmaBovine:1011'), dayFormat, 150),
        {
            field: 'Sex',
            headerName: t('AnelmaBovine:1048'),
            renderCell: (params) => {
                const value = String(params.api.getCellValue(params.id, params.field));
                const actualValue = value !== 'undefined' ? value : '';
                return <TextWithTooltip text={actualValue} />;
            },
            valueGetter: (params) => {
                return bovineSexParams.find((param) => {
                    if (param.code === String(params.row.Sex)) return param.text;
                })?.text;
            },
            width: 80,
        },
        {
            field: 'SalesType',
            headerName: t('AnelmaBovine:1019'),
            renderCell: (params) => {
                return (
                    <TextWithTooltip
                        text={String(params.api.getCellValue(params.id, params.field))}
                    />
                );
            },
            valueGetter: (params) => {
                return params.row.SalesType === null ||
                    params.row.SalesType === undefined ||
                    params.row.SalesType === 0
                    ? ''
                    : salesTypeParams.find((param) => {
                          if (param.code === String(params.row.SalesType)) return param.text;
                      })?.text;
            },
            width: 130,
        },
        {
            field: 'HoldingSiteId',
            headerName: t('AnelmaBovine:1063'),
            renderCell: (params) => {
                return (
                    <TextWithTooltip
                        text={String(params.api.getCellValue(params.id, params.field))}
                    />
                );
            },
            valueGetter: (params) => {
                return params.row.HoldingSiteId === null || params.row.HoldingSiteId === ''
                    ? ''
                    : params.row.HoldingSiteId;
            },
            width: 140,
        },
    ];

    const modifyCollectWeekColumns: AGridColumns = [
        {
            field: 'EuIdentifier',
            headerName: t('AnelmaBovine:1005'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return params.row.Animals.map((_: IAnimalAnnouncementAnimal) => {
                    return _.EuIdentifier;
                });
            },
            width: 150,
        },
        {
            field: 'BirthDate',
            headerName: t('AnelmaBovine:1011'),
            renderCell: (params) => {
                const value = params.value?.toString();

                return (
                    <TextWithTooltip
                        text={`${
                            value && value?.length > 0
                                ? moment(String(params.value)).format(dayFormat)
                                : ''
                        }`}
                    />
                );
            },
            valueGetter: (params) => {
                return params.row.Animals.map((_: IAnimalAnnouncementAnimal) => {
                    return _.BirthDate;
                });
            },
            width: 160,
        },
        {
            field: 'Sex',
            headerName: t('AnelmaBovine:1048'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return bovineSexParams.find((param) => {
                    if (param.code === String(params.row.Animals[0].Sex)) {
                        return param.text;
                    }
                })?.text;
            },
            width: 100,
        },
        {
            field: 'SalesType',
            headerName: t('AnelmaBovine:1019'),
            renderCell: (params) => {
                return (
                    <TextWithTooltip
                        text={String(params.api.getCellValue(params.id, params.field))}
                    />
                );
            },
            valueGetter: (params) => {
                const salesType = params.row.Animals[0].SalesType;
                return salesType === null
                    ? ''
                    : salesTypeParams.find((param) => {
                          if (param.code === String(salesType)) {
                              return param.text;
                          }
                      })?.text;
            },
            width: 160,
        },
        // Field below only has value when updating bovine slaughter announcement
        {
            field: 'Week',
            headerName: t('AnelmaBovine:1097'),
            renderCell: (params) => {
                return params.row.CollectWeekStart !== undefined &&
                    params.row.CollectWeekEnd !== null ? (
                    <TextWithTooltip
                        text={collectWeekString(
                            params.row.CollectWeekStart,
                            params.row.CollectWeekEnd
                        )}
                    />
                ) : (
                    ''
                );
            },
            width: 160,
        },
        gridColumns.singleSelectText('BatchInfo', [], t('AnelmaBovine:1039'), 140),
    ];

    useEffect(() => {
        construcDataGridRows();
        paramRepository.load(['AnelmaBovine']).then(() => {
            setBovineSexParams(paramRepository.resource('AnelmaBovine', 'BovineSex'));
            setSalesTypeParams(paramRepository.resource('AnelmaBovine', 'SalesType'));
            setCollecWeekParams(
                paramRepository.resource('AnelmaAnimalAnnouncement', 'CollectWeekDay')
            );
        });
    }, []);

    const construcDataGridRows = () => {
        if (props.data !== null) {
            setDataRows(props.data.map((row) => mergeRight(row, { id: row.Id })));
        } else if (props.animals !== undefined) {
            setAnimalsRows(props.animals.map((row) => mergeRight(row, { id: row.AnimalId })));
        }
    };

    const deduceRows = () => {
        return props.data !== null ? dataRows : props.animals !== undefined ? animalsRows : [];
    };

    const handleRowDelete = (): void => {
        if (props.animals !== undefined && selectedAnimalId !== '') {
            const deletableRows = Array.from(animalsRows);
            const idx = deletableRows.findIndex((_) => _.AnimalId === selectedAnimalId);
            if (idx !== -1) {
                deletableRows.splice(idx, 1);
                setAnimalsRows(deletableRows);
                setSelectedAnimalId('');
            }
        }
    };

    return props.fromDialog === SecondaryDialogTypeEnum.SalesType ? (
        <>
            <ADataGrid
                actions={[
                    (params) => {
                        return (
                            <ADeleteIconButton
                                key={`${params.id}-delete-animal-from-data-grid`}
                                onClick={() => {
                                    setSelectedAnimalId(String(params.id));
                                    setConfirmDialogVisible(true);
                                }}
                                tooltip={t('AnelmaBovine:1074')}
                            />
                        );
                    },
                ]}
                columns={defaultColumns}
                rows={deduceRows()}
                enableFiltering
                enableRowNumbers
                enablePagination
            />

            {confirmDialogVisible && (
                <ConfirmDialog
                    title={'AnelmaGeneral:1142'}
                    content={'Olet poistamassa eläintä'}
                    actionButtonType={'delete'}
                    onClose={() => {
                        setConfirmDialogVisible(false);
                    }}
                    onConfirm={() => {
                        handleRowDelete();
                    }}
                />
            )}
        </>
    ) : props.fromDialog === SecondaryDialogTypeEnum.AddAnimal ? (
        <ADataGrid
            columns={defaultColumns}
            rows={deduceRows()}
            onSelectionModelChange={(selectionModel) => {
                props.handleRowSelectionChange(selectionModel);
            }}
            enableFiltering
            enableRowNumbers
            enablePagination
        />
    ) : (
        <ADataGrid
            columns={modifyCollectWeekColumns}
            rows={
                props.batches !== undefined
                    ? props.batches.map((_) => mergeRight(_, { id: _.Animals[0].AnimalId }))
                    : []
            }
            enablePagination
        />
    );
}
