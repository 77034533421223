// Libraries
import { Container, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
// Core
import FormValidator from '../../core/FormValidator';

// Common
import { ARadioButtons } from '../../common/inputs';
import { ViewTitle } from '../../common/typography';

// Interfaces
// Store
import { useAppSelector } from '../../store/hooks';

// API
import api from '../../api/contentApi';

// EditableContent - Feature
import RenderContent from '../../editableContent/RenderContent';
import { EditableContentType } from '../../editableContent/IEditableContent';

export default function PriceList() {
    const { t } = useTranslation(['AnelmaGeneral', 'AnelmaLayout', 'AnelmaPriceList']);

    const loginStatus = useAppSelector((state) => state.loginStatus);

    const [section, setSection] = useState<string>('1');
    const [bovineContent, setBovineContent] = useState<string>('');
    const [pigContent, setPigContent] = useState<string>('');

    useEffect(() => {
        Promise.all([
            api.getContentByType(false, EditableContentType.PriceListBovine),
            api.getContentByType(false, EditableContentType.PriceListPig),
        ]).then((responses) => {
            setBovineContent(responses[0] ? responses[0].Entity.Content : '');
            setPigContent(responses[1] ? responses[1].Entity.Content : '');
        });
    }, []);

    const deduceContentFromState = (): string => {
        switch (section) {
            case '1':
                return bovineContent;
            case '2':
                return pigContent;
            default:
                return '';
        }
    };

    return (      
        <Container  data-robot-id = {"app-body-price-list"}>
            <Grid container>
                <Grid item xs={12}>
                    <ViewTitle>{t('AnelmaLayout:1064')}</ViewTitle>

                    <ARadioButtons
                        id='sections'
                        parameterName='AnelmaPriceList:PriceListSections'
                        onChange={(v) => {
                            setSection(v as string);
                        }}
                        validator={new FormValidator()}
                        value={section}
                        withoutContainer
                    />

                    <br />
                    <br />

                    <RenderContent
                        publicSite={!loginStatus.data.loggedIn}
                        content={deduceContentFromState()}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
