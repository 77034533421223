// Libraries
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../../interfaces/enums';

// Feature
import './headerLogo.scss';

export default function HeaderLogo() {
    return (
        <Link className='an-header-logo-container' to={RoutePaths.GeneralDashboard}>
            <span className='an-header-logo'></span>
        </Link>
    );
}
