import { ProductionPlanAnimalType } from "../../../../interfaces/enums";
import { 
    IPlannedAnimal,
    IProductionPlan,
    IProductionPlanIntermediationSales 
} from "../../../../interfaces/IProductionPlan";
import { PlannedAnimalMapperBase } from "./PlannedAnimalMapperBase";

class Mapper extends PlannedAnimalMapperBase {

    ToDataGrid(a: IPlannedAnimal[]): IProductionPlanIntermediationSales {
        return {
            ColostrumCalfBulls: a.find(x => x.Type === ProductionPlanAnimalType.FirstMilkBullCalf)?.Amount || 0,
            ColostrumCalfCows: a.find(x => x.Type === ProductionPlanAnimalType.FirstMilkCowCalf)?.Amount || 0,
            IntermediationBulls: a.find(x => x.Type === ProductionPlanAnimalType.TransmissionBullCalf)?.Amount || 0,
            IntermediationCows: a.find(x => x.Type === ProductionPlanAnimalType.TransmissionCowCalf)?.Amount || 0,
            VkBreederCalfBulls: a.find(x => x.Type === ProductionPlanAnimalType.IntergrowBullCalf)?.Amount || 0,
            VkBreederCalfCows: a.find(x => x.Type === ProductionPlanAnimalType.IntergrowCowCalf)?.Amount || 0,
            BeefCalfBulls: a.find(x => x.Type === ProductionPlanAnimalType.BeefBullCalf)?.Amount || 0,
            BeefCalfCows: a.find(x => x.Type === ProductionPlanAnimalType.BeefCowCalf)?.Amount || 0,
        }
    }

    ToPlan(
        plan: IProductionPlan,
        collection: keyof IProductionPlan,
        x: IProductionPlanIntermediationSales
    ) {
        const a = plan[collection] as IPlannedAnimal[];
        this.MapToArrayByType(a, ProductionPlanAnimalType.FirstMilkBullCalf, x.ColostrumCalfBulls);
        this.MapToArrayByType(a, ProductionPlanAnimalType.FirstMilkCowCalf, x.ColostrumCalfCows);
        this.MapToArrayByType(a, ProductionPlanAnimalType.TransmissionBullCalf, x.IntermediationBulls);
        this.MapToArrayByType(a, ProductionPlanAnimalType.TransmissionCowCalf, x.IntermediationCows);
        this.MapToArrayByType(a, ProductionPlanAnimalType.IntergrowBullCalf, x.VkBreederCalfBulls);
        this.MapToArrayByType(a, ProductionPlanAnimalType.IntergrowCowCalf, x.VkBreederCalfCows);
        this.MapToArrayByType(a, ProductionPlanAnimalType.BeefBullCalf, x.BeefCalfBulls);
        this.MapToArrayByType(a, ProductionPlanAnimalType.BeefCowCalf, x.BeefCalfCows);
    }
}

export default new Mapper();