import { GUIDType } from '../interfaces/types';

export interface IDocumentList {
    documents: IBasicDocumentInfo[];
}

export interface IBasicDocumentInfo {
    DisplayName: string;
    FileName: string;
    FileType: DocumentFileTypeEnum;
    Id: GUIDType;
    Owner: string;
    Type: number;
    ValidDue: string | null;
    ValidFrom: string;
    FarmId: GUIDType | null;
}

export interface IBasicDocument extends IBasicDocumentInfo {
    Content: string;
}

export interface IDocumentAddDialogData {
    displayName: string;
    file: File[];
    owner: GUIDType;
    validFrom: string;
    validDue: string;
    type: number;
    farmId?: GUIDType;
}

export enum DocumentTypeEnum {
    All = 'all',
}

export enum DocumentFileTypeEnum {
    // Unknown = 0,
    // PriceList = 1,
    // Form = 2,
    Image = 3,
    InfoDocument = 4,
    NewsDocument = 5,
    FarmVisitDocument = 6,
    FarmDocument = 7,
}
