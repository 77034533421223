// Content
import { EditableContentType } from '../editableContent/IEditableContent';

// Feature
import InfoView from './InfoView';

export default function GMFreeInfo() {
    return (
        <div data-robot-id = {"app-body-info-shop"}>
        <InfoView
            anelmaInfoContentType={EditableContentType.GMFree}
            bovineContentType={EditableContentType.GMFreeBovine}
            porkContentType={EditableContentType.GMFreePork}
            sharedContentType={EditableContentType.GMFree}
            title={1045}
            applyContainer={true}
        />
        </div>
    );
}
