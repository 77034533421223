// Libraries
import styled from '@emotion/styled';
import { Grid } from '@mui/material';

// MUI
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import CreateIcon from '@mui/icons-material/Create';
import ChatIcon from '@mui/icons-material/Chat';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import HouseIcon from '@mui/icons-material/House';

// Core
// Common
// Interfaces
import { NotificationTypeEnum } from '../../interfaces/INotification';

// Store
// API
// Messaging
// Feature - Notifications

const StyledNotificationItemType = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

interface INotificationItemTypeProps {
    type: NotificationTypeEnum;
}

/** Display specified icon(s) for different notifications
 *
 *  @props type: NotificationTypeEnum
 */

export default function NotificationItemType(props: INotificationItemTypeProps) {
    const gridSize = 2;

    switch (props.type) {
        case NotificationTypeEnum.CreateProductionPlanForNextYear:
            return (
                <StyledNotificationItemType
                    item
                    xs={gridSize}
                    sm={gridSize}
                    md={gridSize}
                    lg={gridSize}
                >
                    <CreateIcon />
                </StyledNotificationItemType>
            );
        case NotificationTypeEnum.NewMessageInNewConversation:
        case NotificationTypeEnum.NewMessageInOldConversation:
            return (
                <StyledNotificationItemType
                    item
                    xs={gridSize}
                    sm={gridSize}
                    md={gridSize}
                    lg={gridSize}
                >
                    <ChatIcon />
                </StyledNotificationItemType>
            );
        case NotificationTypeEnum.NewFeedbackMessageInOldFeedbackItem:
        case NotificationTypeEnum.FeedbackStatusChanged:
        case NotificationTypeEnum.NewFeedback:
            return (
                <StyledNotificationItemType
                    item
                    xs={gridSize}
                    sm={gridSize}
                    md={gridSize}
                    lg={gridSize}
                >
                    <AnnouncementIcon />
                </StyledNotificationItemType>
            );
        case NotificationTypeEnum.NewInvoice:
        case NotificationTypeEnum.NewCreditReport:
        case NotificationTypeEnum.NewCreditNote:
            return (
                <StyledNotificationItemType
                    item
                    xs={gridSize}
                    sm={gridSize}
                    md={gridSize}
                    lg={gridSize}
                >
                    <DescriptionIcon />
                </StyledNotificationItemType>
            );
        case NotificationTypeEnum.NewAnimalPickupPayloadSms:
        case NotificationTypeEnum.NewAnimalArrivalPayloadSms:
            return (
                <StyledNotificationItemType
                    item
                    xs={gridSize}
                    sm={gridSize}
                    md={gridSize}
                    lg={gridSize}
                >
                    <LocalShippingIcon />
                </StyledNotificationItemType>
            );
        case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm:
        case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson:
            return (
                <StyledNotificationItemType
                    item
                    xs={gridSize}
                    sm={gridSize}
                    md={gridSize}
                    lg={gridSize}
                >
                    <AgricultureIcon />
                </StyledNotificationItemType>
            );
        case NotificationTypeEnum.NewCompany:
            return (
                <StyledNotificationItemType
                    item
                    xs={gridSize}
                    sm={gridSize}
                    md={gridSize}
                    lg={gridSize}
                >
                    <HouseIcon />
                </StyledNotificationItemType>
            );
        default:
            return (
                <StyledNotificationItemType
                    item
                    xs={gridSize}
                    sm={gridSize}
                    md={gridSize}
                    lg={gridSize}
                >
                    <HelpCenterIcon />
                </StyledNotificationItemType>
            );
    }
}
