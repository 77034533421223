// Core
import moment from 'moment';
import { Authorization } from '../../../core/authorization';
import { ParameterValues } from '../../../core/resources';

// Interfaces
import { IAnimalAnnouncementBatch } from '../../../interfaces/IAnnouncement';
import { IProductionPlanSummary } from '../../../interfaces/IProductionPlan';

export const mapParameters = (parameters: ParameterValues) => {
    return parameters.map((parameter) => ({
        text: parameter.text,
        value: parameter.code,
    }));
};

export const isAnimalsAndSalesType = (row: IAnimalAnnouncementBatch) => {
    if (
        row.Animals[0] !== undefined &&
        row.Animals[0].SalesType !== null &&
        row.Animals[0].SalesType !== undefined
    )
        return true;
    return false;
};

// http://jira.mtech.fi/browse/AN-984
export const checkCanCreateAnimalAnnouncement = (
    auth: Authorization,
    prodPlans: IProductionPlanSummary[] | null,
    productionPlanCompleted: ParameterValues
): boolean => {
    if (!auth.canViewAdminContextSelector) {

        var expirationDate = undefined;

        if (productionPlanCompleted && productionPlanCompleted.length > 0) {
            expirationDate = new Date(productionPlanCompleted[productionPlanCompleted.length - 1].code);
        }

        if (
            productionPlanCompleteDateLastYear(expirationDate) &&
            prodPlans !== null &&
            prodPlans.some((_) => _.TargetYear === moment().year().valueOf())
        )
            return true;
        else if (
            hasProductionPlanCompleteDateExpired(expirationDate) &&
            prodPlans !== null &&
            !prodPlans.some((_) => _.TargetYear === moment().year().valueOf() + 1)
        )
            return false;
    }
    return true;
};

const hasProductionPlanCompleteDateExpired = (expirationDate: Date | undefined) => {
    if (expirationDate !== undefined)
        if (moment().format('YYYY-MM-DD') > moment(expirationDate).format('YYYY-MM-DD')) return true;
    return false;
};

// In case customer hasn't changed the parameter just yet, but the validity for prod plans is alright.
// If more than one years old, then return false as the parameter should've changed already and therefore producer isn't able to create prod. plan
const productionPlanCompleteDateLastYear = (expirationDate: Date | undefined) => {
    if (expirationDate !== undefined)
        if (moment().subtract(1, 'year').format('YYYY') === moment(expirationDate).format('YYYY'))
            return true;
    return false;
};
