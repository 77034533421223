// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Menu, MenuItem } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Feature
import { Context, ContextData } from './ContextSelector';
import SelectedContext from './SelectedContext';

interface UserContextSelectorProps {
    contextData: ContextData;
    setSelectedContext: (context: Context | null) => void;
}

export default function UserContextSelector(props: UserContextSelectorProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { contextData } = props;

    const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);

    return (
        <div className='an-context-selector--selector' title={t('AnelmaGeneral:1149')}>
            <SelectedContext
                data-robot-id = "user-context-selector"
                context={contextData.selectedContext}
                onClick={(e) => setMenuAnchor(e.currentTarget)}
            />

            <Menu anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)} open={!!menuAnchor} data-robot-id ="user-context-selector-menu" >
                {contextData.contexts.map((context, index) => (
                    <MenuItem
                        data-robot-id  = {"user-context-menu-item-"+ index}
                        key={index}
                        onClick={() => {
                            props.setSelectedContext(context);
                            setMenuAnchor(null);
                        }}
                    >
                        {context.displayName}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
