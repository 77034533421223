// Libraries
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableSortLabel } from '@mui/material';
import { styled } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
// Interfaces
// Store
// API
// Feature
import CollapsibleRow, { ICQRSEventBaseRow } from './CollapsibleRow';

export interface CollapsibleTableProps {
    rows: ICQRSEventBaseRow[];
    rowOnClick: (par: string | number) => any;
}

interface SortingProperties {
    Author: string;
    Command: string;
    Field: string;
    EntityType: string;
    Timestamp: string;
}

const TableWrapper = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const SortOrder = styled('span')(({ theme }) => ({
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (orderBy == 'Timestamp') {
        if (
            moment(a[orderBy] as unknown as string, 'DD.MM.YYYY HH:mm') <
            moment(b[orderBy] as unknown as string, 'DD.MM.YYYY HH:mm')
        )
            return 1;
        else return -1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function CollapsibleTable({ rows, rowOnClick }: CollapsibleTableProps): JSX.Element {
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof SortingProperties>('Timestamp');

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingProperties
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler =
        (property: keyof SortingProperties) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaAudit']);

    const headers: { text: string; key: keyof SortingProperties }[] = [
        { text: t('AnelmaAudit:1042'), key: 'Author' },
        { text: t('AnelmaAudit:1043'), key: 'Command' },
        { text: t('AnelmaAudit:1026'), key: 'Field' },
        { text: t('AnelmaAudit:1044'), key: 'EntityType' },
        { text: t('AnelmaAudit:1045'), key: 'Timestamp' },
    ];

    return (
        <TableContainer component={Paper} sx={{ marginTop: '50px' }}>
            <Table aria-label='collapsible table' stickyHeader size='medium'>
                <TableHead>
                    <TableWrapper>
                        <TableCell>
                            {rows.length}/{rows.length}
                        </TableCell>
                        {headers.map((header) => (
                            <TableCell
                                align='left'
                                sortDirection={orderBy === header.key ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === header.key}
                                    direction={orderBy === header.key ? order : 'asc'}
                                    onClick={createSortHandler(header.key)}
                                >
                                    {header.text}
                                    {orderBy === header.key ? (
                                        <SortOrder>
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </SortOrder>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableWrapper>
                </TableHead>
                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map((row) => (
                        <CollapsibleRow key={row.id} row={row} onClick={() => rowOnClick(row.id)} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
