// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import AddIcon from '@mui/icons-material/Add';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AActionIconButton, ADefaultIconButton, AIconButtonBaseProps } from '../../common/buttons';

export interface AAddIconButtonProps extends AIconButtonBaseProps {
    variant?: 'action' | 'default';
}

export default function AAddIconButton(props: AAddIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, variant, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1072');

    if (variant === 'action')
        return <AActionIconButton {...buttonProps} icon={<AddIcon />} tooltip={tooltip2} />;
    return <ADefaultIconButton {...buttonProps} icon={<AddIcon />} tooltip={tooltip2} />;
}
