import ApiBase from './ApiBase';
import axios, { AxiosResponse } from 'axios';

// Interfaces
import {
    IConversationResult,
    INewMessageResult,
    INewConversation,
    INewMessage,
    INewMessageResponse,
    INewConversationResponse,
    IBooleanResult,
    IModifyMessage,
    IUserMessages,
    IConversation,
} from '../interfaces/ICommunication';
import { GUIDType } from '../interfaces/types';

// API
import { communicationApi } from './apiEndpoints';

class CommunicationApi extends ApiBase {
    // #region Conversation
    getUserConversationList(userId: GUIDType) {
        return this.getList<IConversationResult>(`${communicationApi}/Conversation/${userId}`);
    }

    getAdminConversationList(userId: GUIDType) {
        return this.getList<IConversationResult>(
            `${communicationApi}/Conversation/AdminConversations/${userId}`
        );
    }

    getMessagesList(conversationId: GUIDType) {
        return this.getList<INewMessageResult>(
            `${communicationApi}/Conversation/Messages/${conversationId}`
        );
    }

    getMessageStatus(userId: GUIDType, messageId: GUIDType) {
        return this.get<IBooleanResult>(
            `${communicationApi}/Conversation/MessageStatus/${userId}/${messageId}`
        );
    }

    getMessageStatuses(payload: IUserMessages) {
        return this.postList<IBooleanResult>(
            `${communicationApi}/Conversation/MessageStatuses`,
            payload
        );
    }

    setMessageRead(payload: IModifyMessage) {
        return this.patch(`${communicationApi}/Conversation/SetMessageRead`, payload);
    }

    /*  Fix to return this.post<>() instead of the current, but the Messaging.API API result has to work correctly before that.
        This issue persists only when we're actually wanting to handle the response (e.g. show somewhere etc.).

        FarmId was added because of notifications for conversation/messages that are not about feedback.
    */
    createConversation(payload: INewConversation, farmId?: string) {
        const formData = new FormData();
        formData.append('Title', payload.Title);
        formData.append('Topic', payload.Topic);
        formData.append(`SenderId`, payload.SenderId);
        formData.append(`Body`, payload.Body);
        payload.RecipientList.forEach((recipient) => formData.append(`RecipientList[]`, recipient));
        payload.Attachments?.forEach((attachment, index) => {
            formData.append(
                `attachments[` + index + `].formfile`,
                attachment.File,
                attachment.File.name
            );
            formData.append(`attachments[` + index + `].filename`, attachment.File.name);
            formData.append(`attachments[` + index + `].filetype`, `Image`);
            formData.append(`attachments[` + index + `].displayname`, attachment.File.name);
        });
        formData.append('FarmId', farmId ?? '');
        return this.postCommunication<INewConversationResponse>(
            `${communicationApi}/Conversation/Create`,
            formData
        );
    }

    /*  Fix to return this.post<>() instead of the current, but the Messaging.API API result has to work correctly before that.
        This issue persists only when we're actually wanting to handle the response (e.g. show somewhere etc.).

        FarmId/isFeedback was added because of notifications for conversation/messages that are not about feedback.
    */
    createMessage(payload: INewMessage, isFeedback: boolean, farmId?: string) {
        const formData = new FormData();
        formData.append(`ConversationId`, payload.ConversationId);
        formData.append(`SenderId`, payload.SenderId);
        formData.append(`Body`, payload.Body);
        payload.RecipientList.forEach((recipient) => formData.append(`RecipientList[]`, recipient));
        payload.Attachments?.forEach((attachment, index) => {
            formData.append(
                `attachments[` + index + `].formfile`,
                attachment.File,
                attachment.File.name
            );
            formData.append(`attachments[` + index + `].filename`, attachment.File.name);
            formData.append(`attachments[` + index + `].filetype`, `Image`);
            formData.append(`attachments[` + index + `].displayname`, attachment.File.name);
        });
        formData.append('FarmId', farmId ?? '');
        formData.append('IsFeedback', isFeedback ? 'true' : 'false');
        return this.postCommunication<INewMessageResponse>(
            `${communicationApi}/Conversation/CreateMessage`,
            formData
        );
    }

    // Delete these after API returns DomainResultDto correctly
    private postCommunication<T>(url: string, payload: any): Promise<T | null> {
        return this.communicationPromiseWrapper(axios.post<any>(url, payload));
    }

    communicationPromiseWrapper<T>(axiosPromise: Promise<AxiosResponse<T>>): Promise<T | null> {
        return new Promise<T | null>((resolve, reject) => {
            axiosPromise.then(
                (response) => resolve(response.data),
                () => {
                    resolve(null);
                    reject();
                }
            );
        });
    }

    retrieveConversations(payload: string[]) {
        return this.postList<IConversation>(
            `${communicationApi}/Conversation/Conversations`,
            payload
        );
    }
    // #endregion

    // #region Feedback
    getUserFeedbackList(userId: GUIDType) {
        return this.getList<IConversationResult>(
            `${communicationApi}/Conversation/${userId}?isFeedback=${true}`
        );
    }

    getAdminFeedbackList(userId: GUIDType) {
        return this.getList<IConversationResult>(
            `${communicationApi}/Conversation/AdminConversations/${userId}?isFeedback=${true}`
        );
    }
    // #endregion
}

export default new CommunicationApi();
