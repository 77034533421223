// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
import moment from 'moment';

// MUI
import { Container } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../core/resources';
import { dayFormat } from '../../core/constants';

// Common
import { ADataGrid, ADataGridActions, AGridColumns } from '../../common/dataGrid';
import { ADefaultIconButton, ASearchIconButton } from '../../common/buttons';
import { TextWithTooltip } from '../../common/typography';

// Interfaces
import { IAnimalPayloadItem } from '../../interfaces/IAnimal';
import { PictureAsPdf } from '@mui/icons-material';
import printingApi from '../../api/printingApi';
import { EmptyGUID } from '../../interfaces/types';

// Store
// API
// Feature

export interface SearchObj {
    searchTerm: string;
    searthType: string;
}

export interface AnimalPayloadsViewGridProps {
    isLoading: boolean;
    onPayloadClick: (payloadNumber: number) => void;
    payloadData: IAnimalPayloadItem[];
    searchObj: SearchObj | null;
}

export const AnimalPayloadsViewGrid = (props: AnimalPayloadsViewGridProps) => {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaAnimalAnnouncement',
        'AnelmaAnimalPayload',
        'AnelmaCompany',
        'AnelmaGeneral',
    ]);

    const [animalAnnouncementTypeParams, setAnimalAnnouncementTypeParams] =
        useState<ParameterValues | null>(null);
    const [animalPayloadBatchStatusParams, setAnimalPayloadBatchStatusParams] =
        useState<ParameterValues | null>(null);

    const [paramsInit, setParamsInit] = useState<boolean>(false);

    useEffect(() => {
        const promises = [
            paramRepository.load(['AnelmaAnimalAnnouncement', 'AnelmaAnimalPayload']),
        ];
        Promise.all(promises).then(
            () => {
                setParamsInit(true);
            },
            () => {
                setParamsInit(false);
                // enqueueSnackbar(t('AnelmaGeneral:1020'), {
                //     variant: 'error',
                // });
            }
        );
    }, []);

    const columns: AGridColumns = [
        {
            field: 'CompanyName',
            headerName: t('AnelmaAnimalPayload:1034'),
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            valueGetter: (params) => {
                return (
                    (params.row as IAnimalPayloadItem)?.CompanyInfo?.Name ??
                    (params.row as IAnimalPayloadItem)?.Batches[0]?.CompanyInfo?.Name ??
                    ''
                );
            },
            width: 150,
        },
        {
            field: 'ProducerNumber',
            headerName: t('AnelmaAnimalPayload:1036'),
            valueGetter: (params) => {
                return (
                    (params.row as IAnimalPayloadItem)?.CompanyInfo?.ProducerNumber ??
                    (params.row as IAnimalPayloadItem)?.Batches[0]?.CompanyInfo?.ProducerNumber ??
                    ''
                );
            },
            width: 130,
        },
        {
            field: 'AnimalPayloadNumber',
            headerName: t('AnelmaAnimalPayload:1002'),
            valueGetter: (params) => {
                return (params.row as IAnimalPayloadItem).AnimalPayloadNumber;
            },
            width: 125,
        },
        {
            field: 'AnnouncementType',
            headerName: t('AnelmaAnimalPayload:1028'),
            renderCell: (params) => {
                if (!animalAnnouncementTypeParams) return '';

                const paramFound = animalAnnouncementTypeParams.find(
                    (p) => Number(p.code) === params.formattedValue
                );
                return paramFound ? paramFound.text : '';
            },
            valueGetter: (params) => {
                return (
                    (params.row as IAnimalPayloadItem)?.AnnouncementInfo?.AnnouncementType ??
                    (params.row as IAnimalPayloadItem)?.Batches[0]?.AnnouncementInfo
                        ?.AnnouncementType ??
                    ''
                );
            },
            width: 150,
        },
        {
            field: 'PayloadStatus',
            headerName: t('AnelmaAnimalPayload:1001'),
            renderCell: (params) => {
                if (!animalPayloadBatchStatusParams) return '';

                const paramFound = animalPayloadBatchStatusParams.find(
                    (p) => Number(p.code) === params.formattedValue
                );
                return paramFound ? paramFound.text : '';
            },
            width: 150,
        },
        {
            field: 'AnimalCountFromBatches',
            headerName: t('AnelmaAnimalPayload:1008'),
            valueGetter: (params) => {
                const batches = (params.row as IAnimalPayloadItem).Batches;

                if (!batches) return '';

                let animalCount: number = 0;

                batches.forEach((b) => (animalCount += b.AnimalCount));

                return animalCount;
            },
        },
        {
            field: 'CollectYearWeek',
            headerName: t('AnelmaAnimalPayload:1003'),
            valueGetter: (params) => {
                const collectWeekData = (params.row as IAnimalPayloadItem).CollectWeek;

                if (!collectWeekData?.Year || !collectWeekData?.Week) return '';

                return `${collectWeekData.Year}-${collectWeekData.Week}`;
            },
        },
        {
            field: 'CollectDay',
            headerName: t('AnelmaAnimalPayload:1004'),
            type: 'date',
            renderCell: (params) => {
                if (!(params.row as IAnimalPayloadItem)?.CollectDay) return '';

                const day = moment(new Date((params.row as IAnimalPayloadItem).CollectDay)).format(
                    dayFormat
                );

                return day ? day : '';
            },
            valueGetter: (params) => {
                if (!(params.row as IAnimalPayloadItem)?.CollectDay) return '';

                return new Date((params.row as IAnimalPayloadItem).CollectDay);
            },
        },
    ];

    useEffect(() => {
        if (paramsInit) {
            const statusParams = paramRepository.resource(
                'AnelmaAnimalPayload',
                'AnimalBatchStatus'
            );
            if (statusParams) setAnimalPayloadBatchStatusParams(statusParams);

            const announcementTypeParams = paramRepository.resource(
                'AnelmaAnimalAnnouncement',
                'AnimalAnnouncementType'
            );

            if (announcementTypeParams) setAnimalAnnouncementTypeParams(announcementTypeParams);
        }
    }, [paramsInit]);

    const dataRows = props.payloadData.map((i) => mergeRight(i, { id: i.AnimalPayloadNumber }));

    const actions: ADataGridActions = [
        (params) => {
            const payloadNumber = (params.row as IAnimalPayloadItem).AnimalPayloadNumber;

            return <ASearchIconButton onClick={() => props.onPayloadClick(payloadNumber)} />;
        },
        (params) => {
            return (
                <ADefaultIconButton
                    icon={<PictureAsPdf />}
                    onClick={() => {
                        let payload = params.row as IAnimalPayloadItem;
                        printingApi.printAnimalPayload(payload.AnimalPayloadNumber, payload.CompanyInfo?.Guid ?? EmptyGUID);
                    }}
                    tooltip=""
                />);
        }
    ];

    return (
        <Container id='anelma-animals-animal-payloads-view-grid'>
            <ADataGrid
                actions={actions}
                enableRowNumbers
                columns={columns}
                loading={props.isLoading}
                rows={!dataRows ? [] : dataRows}
            />
        </Container>
    );
};
