// Libraries
import { useEffect, useState } from 'react';

// MUI
import { ButtonGroup } from '@mui/material';

// Core
import { paramRepository, ResourceTextApplication, ParameterSorting } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import { AButtonBaseProps, ADefaultButton, ASelectedButton } from '../../common/buttons';
import { AInputContainer, ALabel, AInputItem } from '../../common/inputs';

import './buttonGroupInput.scss';

interface ACheckboxButtonsBaseProps {
    error?: boolean;
    fullWidth?: boolean;
    id: string;
    label?: string;
    onChange: (value: ACheckboxButtonsValue[]) => void;
    required?: boolean;
    size?: 'small' | 'medium' | 'large';
    validator: IFormValidator;
    value: ACheckboxButtonsValue[];
    withoutContainer?: boolean;
}

interface ACheckboxButtonsWithItemsProps extends ACheckboxButtonsBaseProps {
    items: AInputItem<ACheckboxButtonsValue>[];
}

interface ACheckboxButtonsWithParamProps extends ACheckboxButtonsBaseProps {
    parameterName: string;
    sortValues?: ParameterSorting;
}

export type ACheckboxButtonsProps = ACheckboxButtonsWithItemsProps | ACheckboxButtonsWithParamProps;
export type ACheckboxButtonsValue = boolean | number | string;

export default function ACheckboxButtons(props: ACheckboxButtonsProps) {
    const {
        error,
        id,
        parameterName,
        required,
        size,
        sortValues,
        validator,
        value,
    } = props as ACheckboxButtonsWithParamProps;

    const [items, setItems] = useState<AInputItem<ACheckboxButtonsValue>[]>(
        (props as ACheckboxButtonsWithItemsProps).items || []
    );
    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<ACheckboxButtonsValue[]>(value);
    const [err, setErr] = useState<boolean>(false);

    const optionIsSelected = (option: AInputItem<ACheckboxButtonsValue>) =>
        val.includes(option.value);

    const onClick = (clickedValue: ACheckboxButtonsValue) => {
        const newValue = val.slice();
        const existingIndex = newValue.findIndex((v) => v === clickedValue);
        existingIndex === -1 ? newValue.push(clickedValue) : newValue.splice(existingIndex, 1);
        setTouched(true);
        setVal(newValue);
        props.onChange(newValue);
    };

    useEffect(() => {
        setItems((props as ACheckboxButtonsWithItemsProps).items || []);
    }, [(props as ACheckboxButtonsWithItemsProps).items]);

    useEffect(() => {
        if (!parameterName) return;
        const [groupName, paramName] = parameterName.split(':', 2);
        const group = groupName as ResourceTextApplication;
        paramRepository.load([group]).then(() => {
            const params = paramRepository.resource(group, paramName, sortValues);
            setItems(params.map((p) => ({ text: p.text, value: p.code })));
        });
    }, [parameterName]);

    useEffect(() => {
        setVal(value);
    }, [value]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (required && val.length === 0) return false;
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setErr(!isValidAfterTouch());
    }, [error, id, required, touched, val, validator]);

    const label = (text: string | JSX.Element) => (
        <ALabel error={err} required={required} style={{ fontSize: '12px' }}>
            {text}
        </ALabel>
    );

    const group = (
        <>
            {props.label ? label(props.label) : label(<span>&nbsp;</span>)}
            <ButtonGroup
                className={err ? 'an-button-group-error' : ''}
                fullWidth={props.fullWidth}
                style={{ marginTop: '5px' }}
            >
                {items.map((item) => {
                    const buttonProps: AButtonBaseProps = {
                        children: item.text,
                        key: `${item.value}`,
                        onClick: () => onClick(item.value),
                        size: size || 'small',
                    };
                    return optionIsSelected(item) ? (
                        <ASelectedButton {...buttonProps} />
                    ) : (
                        <ADefaultButton {...buttonProps} />
                    );
                })}
            </ButtonGroup>
        </>
    );

    return props.withoutContainer ? group : <AInputContainer>{group}</AInputContainer>;
}
