// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// MUI
import { Grid, SxProps, Theme, Typography } from '@mui/material';

// Core
import { IFormValidator } from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';

// Common
import { AInputItem, ARadioButtons } from '../../../common/inputs';

// Interfaces
import { IProductionPlan } from '../../../interfaces/IProductionPlan';

// Store
// API
// Feature

interface IEndingOfProductionProps {
    data: IProductionPlan;
    readonly: boolean;
    validator: IFormValidator;
    changeProductionEndYear: (_: number) => void;
    currentTargetYear: number;
}

export default function EndingOfProduction(props: IEndingOfProductionProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCattleProductionPlan']);

    const [productionEndYears, setProductionEndYears] = useState<AInputItem<number>[]>([]);

    const gridContainerStyles: SxProps<Theme> | undefined = {
        paddingLeft: '40px',
        paddingBottom: '40px',
    };

    useEffect(() => {
        initializeProductionEndYears();
    }, []);

    const initializeProductionEndYears = () => {
        const dateNow = props.currentTargetYear;

        setProductionEndYears([
            {
                text: dateNow.toString(),
                value: dateNow,
            },
            {
                text: (dateNow + 1).toString(),
                value: dateNow + 1,
            },
            {
                text: (dateNow + 2).toString(),
                value: dateNow + 2,
            },
        ]);
    };

    return (
        <Grid container direction='row' style={{ paddingLeft: 40 }} sx={gridContainerStyles}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h4'>{t('AnelmaCattleProductionPlan:1080')}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <ARadioButtons
                    id='anelma-cattle-production-plan-production-end-years'
                    onChange={(_) => props.changeProductionEndYear(_ as number)}
                    validator={props.validator}
                    value={props.data.ProductionEndYear}
                    items={productionEndYears}
                    sortValues={'default'}
                    withoutContainer
                    unselectable
                />
            </Grid>
        </Grid>
    );
}
