// Core
import { ParameterValues } from '../core/resources';
import { PhoneNumberTypeEnum } from '../interfaces/enums';

class InfoViewUtils {
    getParamText(param: ParameterValues, code: number | string | null): string {
        if (!code) return '';
        return param.find((i) => i.code === `${code}`)?.text ?? '-';
    }
}

export default new InfoViewUtils();
