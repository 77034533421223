import axios from 'axios';
import { keys, isNil } from 'ramda';

export type LogLevel = 'debug' | 'info' | 'warning' | 'error' | 'critical';
interface LogProperties {
    [key: string]: string;
}

export default function log(
    Level: LogLevel,
    Message: string,
    object?: LogProperties
): Promise<void> {
    const objectKeys = keys(object || {});
    const Properties: any[] = isNil(object)
        ? []
        : objectKeys.map((k) => ({ Key: k, Value: object[k] }));

    if (window.location.hostname === 'localhost') {
        if (Level === 'critical' || Level === 'error')
            console.error(`Logged ${Level}:\n`, Message, '\n', object);
        else if (Level === 'warning') console.warn(`Logged ${Level}:\n`, Message, '\n', object);
        else if (Level === 'info') console.info(`Logged ${Level}:\n`, Message, '\n', object);
        else if (Level === 'debug') console.debug(`Logged ${Level}:\n`, Message, '\n', object);
        else console.log(`Logged ${Level}:\n`, Message, '\n', object);
    }

    return axios.post('api/bootstrapper/log-message', {
        Level,
        Message,
        Properties,
    });
}
