// Libraries
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../core/resources';
import utils from '../core/utils';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogContent,
    ADialogTitle,
    ADialogSubHeading,
} from '../common/dialog';
import { ADataGrid } from '../common/dataGrid';

// Feature
import { IAccessRole, IAccessRoleMemberList } from './IAccessRightsManagement';

export interface RoleMembersDialogProps {
    onClose: () => void;
    role: IAccessRole;
    roleMembers?: IAccessRoleMemberList;
}

interface MemberGridData {
    company: string;
    id: number;
    name: string;
    username: string;
}

export default function RoleMembersDialog(props: RoleMembersDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaAccessRights',
        'AnelmaGeneral',
        'AnelmaPerson',
        'AnelmaCompany'
    ]);

    const memberData: MemberGridData[] =
        props.roleMembers?.userMembers.map((i, index) => {
            const name: string[] = [];
            if (i.userFirstName) name.push(i.userFirstName);
            if (i.userLastName) name.push(i.userLastName);
            return {
                company: '',
                id: index,
                name: name.join(' '),
                username: i.username,
            };
        }) || [];

    const farmMemberData: MemberGridData[] = [];
    let id = 0;
    props.roleMembers?.contextScopedMembers.forEach((c) => {
        let context = utils.context.parseFarmContext(c.contextName);

        c.userMembers.forEach((i) => {
            const name: string[] = [];
            if (i.userFirstName) name.push(i.userFirstName);
            if (i.userLastName) name.push(i.userLastName);
            farmMemberData.push({
                company: context?.producerId + ' - ' + context?.customerName || '***',
                id: id++,
                name: name.join(' '),
                username: i.username,
            });
        });
    });

    return (
        <ADialog onClose={() => props.onClose()} open={true}>
            <ADialogTitle>
                {t('AnelmaAccessRights:1113') +
                    ` (${t(`AnelmaAccessRights:${props.role.textCode}`)})`}
            </ADialogTitle>

            <ADialogContent size='lg'>
                <ADialogSubHeading>{t('AnelmaAccessRights:1115')}</ADialogSubHeading>

                <ADataGrid 
                    columns={[
                        {
                            field: 'name',
                            width: 240,
                            headerName: t('AnelmaGeneral:1014'),
                        },
                        {
                            field: 'username',
                            width: 240,
                            headerName: t('AnelmaPerson:1004'),
                        },
                        {
                            field: 'company',
                            width: 450,
                            headerName: t('AnelmaCompany:1003') + ' - ' + t('AnelmaAccessRights:1107'),
                        },
                    ]}
                    rows={farmMemberData}
                />

                <ADialogSubHeading>{t('AnelmaAccessRights:1063')}</ADialogSubHeading>

                <ADataGrid
                    columns={[
                        {
                            field: 'name',
                            flex: 1,
                            headerName: t('AnelmaGeneral:1014'),
                        },
                        {
                            field: 'username',
                            flex: 1,
                            headerName: t('AnelmaPerson:1004'),
                        },
                    ]}
                    rows={memberData}
                />
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onClose(),
                            type: 'cancel',
                        },
                    ],
                    right: [],
                }}
            />
        </ADialog>
    );
}
