import { useMemo, useState, useEffect } from 'react';

// MUI
import { Box, Chip } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import AImageList from './imageGallery/AImageList';
import { DocumentDialogConfig } from '../documents/addDialog/documentAddDialogConfigs';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ResourceTextApplication } from '../core/resources';

const baseStyle = {
    padding: '20px',
    borderWidth: 1,
    borderRadius: 0,
    borderColor: '#eeeeee',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    marginTop: '10px',
    marginBottom: '20px',
    transition: 'border .24s ease-in-out',
};

const focusedStyle = {
    borderColor: '#0a4c31',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    maxWidth: 100,
    maxHeight: 100,
    padding: 4,
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

//format prop is used as a trigger to clear attachment state when changed
export interface MyDropzoneProps {
    Text?: string;
    onAttachmentsChanged?: <T extends File>(attachments: T[]) => void;
    Format?: boolean;
    disabled?: boolean;
    acceptedFiles?: string[] | undefined;
    filesLimit: number;
    maxFileSize: number;
}

export interface FileWithPreview extends File {
    preview?: string;
}

export default function AttachmentDropzone(props: MyDropzoneProps) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaDocument']);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: (acceptedFiles) => {
            const files = acceptedFiles as FileWithPreview[];
            attachments.forEach((file) => files.push(file));

            let hasMatchingFile = false;
            const accFiles = props.acceptedFiles || [];

            if (accFiles.length > 0) {
                hasMatchingFile = files.some((file) => {
                    return accFiles.some((acceptedType) => {
                        const regex = new RegExp(`^${acceptedType.replace('*', '.+')}`);
                        return regex.test(file.type);
                    });
                });
            } else {
                hasMatchingFile = true;
            }

            if (!hasMatchingFile) {
                enqueueSnackbar(t('AnelmaDocument:1031'), {
                    variant: 'error',
                });
            }

            if (props.onAttachmentsChanged && props.filesLimit >= files.length && hasMatchingFile) {
                props.onAttachmentsChanged(files);
                setAttachments(files);
            } else if (hasMatchingFile) {
                enqueueSnackbar(t('AnelmaDocument:1030'), {
                    variant: 'error',
                });
            }
        },
    });

    const [attachments, setAttachments] = useState<File[]>([]);

    const clearAttachmentState = () => {
        setAttachments([]);
    };

    useEffect(() => {
        clearAttachmentState();
    }, [props.Format]);

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <div className='container' style={props.disabled ? { pointerEvents: 'none' } : {}}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <div>
                    <p>{props.Text}</p>
                </div>
                <div>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            p: 0,
                            m: 0,
                        }}
                    >
                        {' '}
                        <>
                            {attachments.map((attachment: File) => {
                                return (
                                    <Box sx={{ m: 1 }}>
                                        <Chip
                                            label={attachment.name}
                                            onDelete={() => {
                                                const files = [
                                                    ...attachments.filter((a) => a !== attachment),
                                                ];

                                                setAttachments(files);
                                                if (props.onAttachmentsChanged) {
                                                    props.onAttachmentsChanged(files);
                                                }
                                            }}
                                        />
                                    </Box>
                                );
                            })}
                        </>
                    </Box>
                </div>
                <AImageList
                    files={attachments}
                    cols={5}
                    gap={5}
                    imgMaxHeight={1000}
                    imgMaxWidth={1000}
                ></AImageList>
            </div>
        </div>
    );
}
