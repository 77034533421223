// Libraries
import { useState } from 'react';

// MUI
import { Button, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Core
import { baseUrl } from '../../../../core/constants';

// Store
import { useAppSelector } from '../../../../store/hooks';

// Feature
import './cultureSelector.scss';

export interface ICultureSelectorProps {
    isInMobileMenu?: boolean;
}

export default function CultureSelector(props: ICultureSelectorProps) {
    const btnDefaultClassName = 'button-normal';
    const btnClassName =
        props.isInMobileMenu !== undefined ? 'button-normal-mobile' : btnDefaultClassName;
    const configuration = useAppSelector((state) => state.configuration.data);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const changeCulture = (culture: string) => {
        const returnUrl = encodeURI(window.location.href);
        window.location.href = `${baseUrl}${configuration.culture.changeCultureUrl}?culture=${culture}&returnUrl=${returnUrl}`;
    };

    const selectedCulture =
        configuration.culture.supportedCultures.find((i) => i.selected) ||
        configuration.culture.supportedCultures[0];
    const otherCultures = configuration.culture.supportedCultures.filter((i) => !i.selected);

    if (!selectedCulture) return <></>;

    return (
        <div className='an-user-menu-cultures' data-robot-id ="menu-cultures">
            <Button data-robot-id="menu-cultures-button"
                className={btnClassName}
                aria-controls='culture-menu'
                aria-haspopup='true'
                onClick={(e) => setAnchorEl(e.currentTarget)}
                variant='text'
            >
                {selectedCulture.text}
                <ExpandMoreIcon />
            </Button>

            <Menu
                anchorEl={anchorEl}
                id='culture-menu'
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {otherCultures.map((i, index) => (
                    <MenuItem key={index} onClick={() => changeCulture(i.value)}>
                        {i.text}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
