// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// MUI
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../../core/resources';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../../common/dialog';

// Interfaces
import { DialogModeEnum } from '../../../interfaces/enums';
import { GUIDType } from '../../../interfaces/types';
import { IAnimalSummaryExtraInfo } from '../../../interfaces/IAnimal';

// Store
// API
// Feature
import { IAddAnimalDialogProps } from '../../shared/types/propTypes';
import SecondaryDialogDataGrid from '../../shared/components/data-grid/SecondaryDialogDataGrid';
import { SecondaryDialogTypeEnum } from '../../shared/types/enum';
import { holdingSiteHasValue } from '../helpers/utils';

export default function AddAnimalDialog(props: IAddAnimalDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);

    const [isLoading, setIsLoading] = useState<boolean>();
    const [selectedAnimals, setSelectedAnimals] = useState<GUIDType[]>([]);
    const [animals, setAnimals] = useState<IAnimalSummaryExtraInfo[] | null>(props.animals);

    useEffect(() => {
        if (props.fromDialog === SecondaryDialogTypeEnum.SlaughterOrIntermediationAnnouncement)
            preprocessAnimalData();
    }, []);

    const defaultFooterActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => props.onClose(),
                disabled: selectedAnimals.length === 0 ? true : false,
                type: 'ok',
            },
        ],
    };

    const addAnimalFooterActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => props.handleAnimalDataChange(selectedAnimals),
                disabled: selectedAnimals.length === 0 ? true : false,
                type: 'ok',
            },
        ],
    };

    const handleSelectionChange = (selectionModel: GridRowSelectionModel) => {
        setSelectedAnimals(
            selectionModel.map((value) => {
                return value as GUIDType;
            })
        );
    };

    const resolveActionButtons = (): ADialogButtonsProp => {
        switch (props.fromDialog) {
            case SecondaryDialogTypeEnum.SalesType:
                return defaultFooterActionButtons;
            case SecondaryDialogTypeEnum.AddAnimal:
            case SecondaryDialogTypeEnum.ModifyCollectWeek:
            case SecondaryDialogTypeEnum.SlaughterOrIntermediationAnnouncement:
                return addAnimalFooterActionButtons;
        }
    };

    const preprocessAnimalData = () => {
        if (holdingSiteHasValue(props.holdingSite)) filterAnimalsOnHoldingSite(true);
        else filterAnimalsOnHoldingSite(false);
    };

    const filterAnimalsOnHoldingSite = (hasHoldingsite: boolean) => {
        if (hasHoldingsite) {
            const animalsWithHoldingSite = animals?.filter((_) => _.HoldingSiteId === props.holdingSite);
            if (animalsWithHoldingSite !== undefined) setAnimals(animalsWithHoldingSite);
            else setAnimals(null);
        } else {
            const animalsWithNoHoldingSite = animals?.filter(
                (_) => _.HoldingSiteId === '' || _.HoldingSiteId === null
            );
            if (animalsWithNoHoldingSite !== undefined) setAnimals(animalsWithNoHoldingSite);
            else setAnimals(null);
        }
    };

    return (
        <>
            <ADialog open onClose={props.onClose} disableEnforceFocus>
                <ADialogTitle>{t('AnelmaBovine:1049')}</ADialogTitle>

                <Grid container sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                    <ADialogContent size={'lg'} isLoading={isLoading}>
                        <SecondaryDialogDataGrid
                            fromDialog={SecondaryDialogTypeEnum.AddAnimal}
                            mode={DialogModeEnum.Create}
                            handleRowSelectionChange={(selectionModel: GridRowSelectionModel) =>
                                handleSelectionChange(selectionModel)
                            }
                            handleRowDeleteClick={() => false}
                            data={animals}
                            animals={undefined}
                        />
                    </ADialogContent>
                </Grid>
                
                <ADialogActions buttons={resolveActionButtons()} />
            </ADialog>
        </>
    );
}
