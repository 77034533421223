// Libraries
import { useCallback, useState, useEffect, CSSProperties } from 'react';

// Store
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadProfilePictureAsync } from '../store/profilePictureSlice';

// Feature
import './userAvatar.scss';

export interface UserAvatarProps {
    personId?: string | null;
}

interface UserAvatarState {
    profilePicture: string;
    status: 'default' | 'loading' | 'loaded' | 'profile';
}

export default function UserAvatar(props: UserAvatarProps) {
    const profilePictures = useAppSelector((state) => state.profilePictures.data);

    const dispatch = useAppDispatch();
    const loadProfilePicture = useCallback((id: string) => dispatch(loadProfilePictureAsync(id)), [
        dispatch,
    ]);

    const hideDefaultAvatar: CSSProperties = { background: 'none' };
    const showDefaultAvatar: CSSProperties = {};

    const [state, setState] = useState<UserAvatarState>({
        profilePicture: '',
        status: 'loading',
    });

    useEffect(() => {
        if (props.personId) {
            setState({
                profilePicture: '',
                status: 'loading',
            });

            loadProfilePicture(props.personId).then(() => {
                setState({
                    profilePicture: '',
                    status: 'loaded',
                });
            });
        } else {
            setState({
                profilePicture: '',
                status: 'default',
            });
        }
    }, [props.personId]);

    useEffect(() => {
        const pic = profilePictures.find((i) => i.Owner === props.personId);

        if (state.status === 'loaded') {
            if (props.personId && pic) {
                setState({
                    profilePicture: pic.Content,
                    status: 'profile',
                });
            } else {
                setState({
                    profilePicture: '',
                    status: 'default',
                });
            }
        } else if (
            ['profile', 'default'].includes(state.status) &&
            props.personId &&
            pic &&
            pic.Content !== state.profilePicture
        ) {
            setState({
                profilePicture: pic.Content,
                status: 'profile',
            });
        }
    }, [props.personId, profilePictures, state]);

    return (
        <div
            className='an-user-avatar'
            style={state.status === 'default' ? showDefaultAvatar : hideDefaultAvatar}
        >
            {state.status === 'profile' && (
                <img src={`data:image/jpg;base64,${state.profilePicture}`} />
            )}
        </div>
    );
}
