// Feature
import './mobileHeaderLogo.scss';

export default function MobileHeaderLogo() {
    return (
        <>
            <span className='an-mobile-header-logo'></span>
        </>
    );
}
