export enum NotificationTypeEnum {
    Default = 0,
    CreateProductionPlanForNextYear = 1,
    NewMessageInNewConversation = 2,
    NewMessageInOldConversation = 3,
    NewFeedbackMessageInOldFeedbackItem = 4,
    FeedbackStatusChanged = 5,
    NewFeedback = 6,
    NewInvoice = 7,
    NewCreditReport = 8,
    NewCreditNote = 9,
    NewAnimalPickupPayloadSms = 10,
    NewAnimalArrivalPayloadSms = 11,
    NewFarmVisitTwoDaysBeforeStartDateToFarm = 12,
    NewFarmVisitTwoDaysBeforeStartDateToPerson = 13,
    NewCompany = 14, // http://jira.mtech.fi/browse/AN-1095 at this point used only for farms but who knows if in the near future will expand to traffic contractors
}

export interface INotification {
    NotificationId: string;
    NumbericKey: number | null;
    UuidKey: string | null;
    TypeId: NotificationTypeEnum;
    Flags: NotificationFlagsEnum;
    ApplicationResource: IApplicationResource;
    Created: string;
    ReadDateTime: string | null;
    DeletedDateTime: string | null;
    UserId: string | null;
    FarmId: string;
    FarmVisitResourceTxt: string | null; // Used in the client-side to hold the resolved resource text
    CompanyData: INotificationCompanyData | null; // Used in the client-side to hold the resolved resource text
    ProductionPlanResourceTxt: string | null;
    ConversationResourceTxt: string | null;
    RelatedEntities: IRelatedEntity[] | null;
}

export interface IRelatedEntity {
    Id: string;
    Role: RelatedEntityRole;
}

export interface INotificationCompanyData {
    CompanyResourceTxt: string | null;
    FarmId: string; // Production number or tiltu in finnish
}

export enum RelatedEntityRole {
    MessageSender = 1,
    MessageReceiver = 2,
}

export enum NotificationFlagsEnum {
    Initial = 1,
    Read = 2,
    Deleted = 3,
}

export interface IApplicationResource {
    ApplicationCode: string;
    ResourceTextCode: number;
}

export interface IModifyNotifications {
    Items: IModifyNotificationsPayload[];
}

export interface IModifyNotificationsPayload {
    UserId: string;
    NotificationId: string;
    Flags: NotificationFlagsEnum;
    ReadDateTime: string | null;
    DeletedDateTime: string | null;
}
