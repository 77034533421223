// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// MUI
import { Container, Grid } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../core/resources';
import { dayFormat } from '../../core/constants';
import auth from '../../core/authorization';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogContentProps,
    ADialogSubHeading,
    ADialogTitle,
} from '../../common/dialog';
import { ALabel } from '../../common/inputs';

// Interfaces
import { IAnimalPayloadItem } from '../../interfaces/IAnimal';

// Store
// API
// Feature - Animal Payload
import {
    AnimalPayloadsBatchesGrid,
    AnimalPayloadsBatchesGridProps,
} from './AnimalPayloadsBatchesGrid';
import {
    AnimalPayloadsSmsDialog,
    AnimalPayloadsSmsDialogProps,
} from './sms-send-dialog/AnimalPayloadsSmsDialog';

export interface AnimalPayloadsBatchFormProps {
    payloadData: IAnimalPayloadItem;
    onClose: () => void;
    isLoading: boolean;
}

export const AnimalPayloadsBatchForm = (props: AnimalPayloadsBatchFormProps) => {
    const admin = auth.canViewAdminContextSelector;

    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaAnimalPayload',
    ]);

    const [batchStatuParams, setBatchStatuParams] = useState<ParameterValues | null>(null);
    const [collectWeekDayParams, setCollectWeekDayParams] = useState<ParameterValues | null>(null);
    const [animalPayloadsSmsDialogProps, setAnimalPayloadsSmsDialogProps] =
        useState<AnimalPayloadsSmsDialogProps | null>(null);
    const [isSmsDialogVisible, setIsSmsDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        initializeParameters();
    }, []);

    const initializeParameters = () => {
        paramRepository.load(['AnelmaAnimalAnnouncement', 'AnelmaAnimalPayload']).then(() => {
            setBatchStatuParams(
                paramRepository.resource('AnelmaAnimalPayload', 'AnimalBatchStatus')
            );
            setCollectWeekDayParams(
                paramRepository.resource('AnelmaAnimalAnnouncement', 'CollectWeekDay')
            );
        });
    };

    const handleSmsDialogOpening = () => {
        setIsSmsDialogVisible(true);
        setAnimalPayloadsSmsDialogProps({
            payloadData: props.payloadData,
            onCloseButtonClicked: () => setIsSmsDialogVisible(false),
        });
    };

    const animalPayloadsBatchesGridProps: AnimalPayloadsBatchesGridProps = {
        payload: props.payloadData,
        batches: props.payloadData.Batches,
    };

    const dialogContentProps: ADialogContentProps = {
        size: 'xl',
        isLoading: props.isLoading,
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                label: t('AnelmaAnimalPayload:1031'),
                onClick: () => handleSmsDialogOpening(),
                type: 'default',
            },
        ],
    };

    return (
        <ADialog open disableEnforceFocus>
            <ADialogTitle>
                {`${t('AnelmaAnimalPayload:1009')} ${props.payloadData.AnimalPayloadNumber}`}{' '}
            </ADialogTitle>
            <ADialogContent {...dialogContentProps}>
                <Container id='anelma-animals-animal-payloads-batch-form-container'>
                    <Grid container>
                        <Grid container style={{ paddingBottom: '10px' }}>
                            <Grid item xs={3}>
                                <ALabel>{t('AnelmaCompany:1104')}</ALabel>
                                {props.payloadData.CarNumber}
                            </Grid>

                            <Grid item xs={3}>
                                <ALabel>{t('AnelmaAnimalPayload:1012')}</ALabel>
                                {props.payloadData?.TrafficContractor?.Name ?? ''}
                            </Grid>
                        </Grid>

                        <Grid container style={{ paddingBottom: '10px' }}>
                            <Grid item xs={3}>
                                <ALabel>{t('AnelmaAnimalPayload:1044')}</ALabel>
                                {props.payloadData?.PhoneNumber?.NormalizedNumber ?? ''}
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingBottom: '10px' }}>
                            <Grid item xs={3}>
                                <ALabel>{t('AnelmaAnimalPayload:1021')}</ALabel>
                                {`${props.payloadData.CollectWeek.Year}-${props.payloadData.CollectWeek.Week}`}
                            </Grid>

                            <Grid item xs={3}>
                                <ALabel>{t('AnelmaAnimalPayload:1020')}</ALabel>
                                {collectWeekDayParams?.find(
                                    (f) => Number(f.code) === props.payloadData.CollectWeekDay
                                )?.text ?? ''}
                            </Grid>
                        </Grid>

                        <Grid container style={{ paddingBottom: '10px' }}>
                            <Grid item xs={3}>
                                <ALabel>{t('AnelmaAnimalPayload:1022')}</ALabel>
                                {moment(props.payloadData.CollectDay).format(dayFormat)}
                            </Grid>

                            <Grid item xs={3}>
                                <ALabel>{t('AnelmaAnimalPayload:1024')}</ALabel>
                                {batchStatuParams?.find(
                                    (f) => Number(f.code) === props.payloadData.PayloadStatus
                                )?.text ?? ''}
                            </Grid>
                            {admin ? (
                                <Grid item xs={3}>
                                    <ALabel>{t('AnelmaAnimalPayload:1023')}</ALabel>
                                    {`${props.payloadData.DepartureTime}-${props.payloadData.ArrivalTime}`}
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                        {admin ? (
                            <Grid container style={{ paddingBottom: '10px' }}>
                                <Grid item xs={3}>
                                    <ALabel>{t('AnelmaAnimalPayload:1023')}</ALabel>
                                    {`${props.payloadData.DepartureTime}-${props.payloadData.ArrivalTime}`}
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Grid>

                    <Grid container>
                        <ADialogSubHeading children={t('AnelmaAnimalPayload:1026')} />

                        {!props.isLoading && (
                            <AnimalPayloadsBatchesGrid {...animalPayloadsBatchesGridProps} />
                        )}
                    </Grid>

                    {animalPayloadsSmsDialogProps !== null && isSmsDialogVisible ? (
                        <AnimalPayloadsSmsDialog {...animalPayloadsSmsDialogProps} />
                    ) : (
                        ''
                    )}
                </Container>
            </ADialogContent>
            <ADialogActions buttons={footerButtons} />
        </ADialog>
    );
};
