// Libraries
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import SaveIcon from '@mui/icons-material/Save';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AActionButton, AButtonBaseProps } from '../../common/buttons';

export interface ASaveButtonProps extends Omit<AButtonBaseProps, 'children'> {
    children?: ReactNode;
}

export default function ASaveButton(props: ASaveButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { children, ...actionButtonProps } = props;
    const children2 = children ? children : t('AnelmaGeneral:1001');

    return <AActionButton {...actionButtonProps} children={children2} icon={<SaveIcon />} />;
}
