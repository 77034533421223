// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { IParameterValue, paramRepository, ResourceTextApplication } from '../../core/resources';
import defaultData from '../../core/defaultData';
import utils from '../../core/utils';
import FormValidator, { IFormValidator } from '../../core/FormValidator';

// Common
import { ADialogSubHeading } from '../../common/dialog';
import { AAddButton } from '../../common/buttons';
import {
    ACheckboxButtons,
    ACheckboxButtonsProps,
    ACheckboxButtonsValue,
    AInputContainer,
} from '../../common/inputs';

// Interfaces
import {
    IProductionLine,
    IHoldingSite,
    IHoldingSiteWithId,
} from '../../interfaces/IBusinessEntities';
import { AnimalTypeEnum, FarmingTypeEnum } from '../../interfaces/enums';

// Feature
import CompanyHoldingSiteGrid, { CompanyHoldingSitesGridProps } from './CompanyHoldingSiteGrid';
import AddCompanyHoldingSite from './AddCompanyHoldingSite';

export interface CompanyHoldingSitesProps {
    productionLines: IProductionLine[];
    onChange: (data: IHoldingSite[] | IProductionLine[], property: string) => void;
    holdingSites: IHoldingSite[];
    validator: IFormValidator;
}

export default function CompanyHoldingSitesForm(props: CompanyHoldingSitesProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany', 'AnelmaGeneral']);
    const { enqueueSnackbar } = useSnackbar();
    const { productionLines } = props;

    const [addHoldingSiteModalVisible, setAddHoldingSiteModalVisible] = useState(false);
    const [holdingSiteData, setHoldingSiteData] = useState<IHoldingSiteWithId>(
        defaultData.holdingSite() as IHoldingSiteWithId
    );
    const [holdingSiteModalMode, setHoldingSiteModalMode] = useState<'create' | 'modify'>('create');
    const [selectedHoldingSiteCode, setSelectedHoldingSiteCode] = useState(-1);
    const [speciesParams, setSpeciesParams] = useState<IParameterValue[]>([]);

    useEffect(() => {
        if (speciesParams.length === 0) {
            paramRepository.load(['AnelmaCompany']).then(() => {
                const params = paramRepository.resource('AnelmaCompany', 'Species');
                setSpeciesParams(
                    params.map((p) => ({ text: p.text, code: p.code } as IParameterValue))
                );
            });
        }

        validateProductionLines(productionLines);
    }, []);

    useEffect(() => {
        validateProductionLines(productionLines);
    }, [productionLines]);

    const validateProductionLines = (lines: IProductionLine[]) => {
        let valid = false;
        lines.forEach((l) => {
            if (l.FarmingTypes.length) valid = true;
        });
        props.validator.setState('production-lines', valid, (valid) => {
            if (valid) return;
            enqueueSnackbar(t('AnelmaCompany:1122'), {
                variant: 'error',
            });
        });
    };

    const addHoldingSiteButtonClick = (speciesType: number) => {
        let newAdd = defaultData.holdingSite() as IHoldingSiteWithId;
        newAdd._i = utils.generateUniqueId();

        setHoldingSiteData(newAdd);
        setAddHoldingSiteModalVisible(true);
        setHoldingSiteModalMode('create');
        setSelectedHoldingSiteCode(speciesType);
    };

    const editHoldingSiteButtonClick = (speciesType: number) => {
        setAddHoldingSiteModalVisible(true);
        setHoldingSiteModalMode('modify');
        setSelectedHoldingSiteCode(speciesType);
    };

    const onCloseHoldingSiteModal = () => {
        let newAdd = defaultData.holdingSite() as IHoldingSiteWithId;
        newAdd._i = utils.generateUniqueId();

        setHoldingSiteData(newAdd);
        setAddHoldingSiteModalVisible(false);
        setSelectedHoldingSiteCode(-1);
    };

    const onHoldingSiteAdd = (data: IHoldingSite) => {
        let newData = [...props.holdingSites];
        newData.push(data);
        props.onChange(newData, 'holdingSites');
        onCloseHoldingSiteModal();
    };

    const onHoldingSiteEdit = (data: IHoldingSiteWithId) => {
        let newData = [...props.holdingSites];
        let found = newData.map((h) => h as IHoldingSiteWithId).find((p) => p._i === data._i);

        if (found != null && newData.indexOf(found) > -1) {
            newData[newData.indexOf(found)] = data;
            props.onChange(newData, 'holdingSites');
        }
        onCloseHoldingSiteModal();
    };

    const onHoldingSiteDelete = (data: IHoldingSite) => {
        const found = props.holdingSites
            .map((h) => h as IHoldingSiteWithId)
            .find((p) => p._i === (data as IHoldingSiteWithId)._i);

        if (found != null) {
            let newData = ([...props.holdingSites] as IHoldingSiteWithId[]).filter(
                (f) => f._i !== found._i
            ) as IHoldingSite[];
            props.onChange(newData, 'holdingSites');
        }
    };

    const productionLinesMap = speciesParams.map((p, index) => {
        let productionLine = productionLines.find((line) => line.Type === Number(p.code));
        const pLineValues = productionLine ? productionLine.FarmingTypes : [];

        const paramCheckboxGroupProps: ACheckboxButtonsProps = {
            parameterName: `AnelmaCompany:${
                Number(p.code) === 1 ? 'FarmingTypeBovine' : 'FarmingTypePig'
            }`,
            id: `production-line-${index}`,
            label: t('AnelmaCompany:1024'),
            onChange: (value: ACheckboxButtonsValue[]) => {
                const farmingTypes = value.map((v) => parseInt(v as string, 10) as FarmingTypeEnum);
                const newProductionLines = [...productionLines];

                const line = newProductionLines.find(
                    (line) => Number(line.Type) === Number(p.code)
                );

                if (line) line.FarmingTypes = farmingTypes;
                else {
                    newProductionLines.push({
                        FarmingTypes: farmingTypes,
                        Type: Number(p.code),
                    });
                }
                props.onChange(newProductionLines, 'productionLines');
                validateProductionLines(newProductionLines);
            },
            validator: props.validator,
            value: pLineValues.map((value) => `00${value}`.substr(-2)),
            sortValues: 'default',
        };

        const filteredHoldingSites: IHoldingSite[] = props.holdingSites.filter(
            (h) => Number(h.Type) === Number(p.code)
        );

        const holdingSiteGridProps: CompanyHoldingSitesGridProps = {
            holdingSites: filteredHoldingSites,
            onDelete: (data: IHoldingSite) => onHoldingSiteDelete(data),
            onSetHoldingSite: (data: IHoldingSite) => {
                setHoldingSiteData(data as IHoldingSiteWithId);
                editHoldingSiteButtonClick(Number(data.Type));
            },
            specieType: Number(p.code) === AnimalTypeEnum.Bovine ? 'bovine' : 'pork',
        };

        return (
            <Grid container key={index}>
                <Grid item sm={12}>
                    <ADialogSubHeading>{p.text}</ADialogSubHeading>
                </Grid>
                <Grid item sm={12}>
                    <ACheckboxButtons {...paramCheckboxGroupProps} />
                </Grid>

                <CompanyHoldingSiteGrid {...holdingSiteGridProps} />

                <div style={{ textAlign: 'right', width: '100%' }}>
                    <AInputContainer>
                        <AAddButton
                            onClick={() => addHoldingSiteButtonClick(Number(p.code))}
                            size='small'
                        >
                            {t('AnelmaCompany:1029')}
                        </AAddButton>
                    </AInputContainer>
                </div>
            </Grid>
        );
    });

    return (
        <>
            <Grid container>
                {productionLinesMap}

                <Grid item>
                    {addHoldingSiteModalVisible && selectedHoldingSiteCode > 0 ? (
                        <AddCompanyHoldingSite
                            data={holdingSiteData as IHoldingSiteWithId}
                            mode={holdingSiteModalMode}
                            onAdd={(data: IHoldingSite) => onHoldingSiteAdd(data)}
                            onClose={() => onCloseHoldingSiteModal()}
                            onEdit={(data: IHoldingSite) =>
                                onHoldingSiteEdit(data as IHoldingSiteWithId)
                            }
                            specieType={selectedHoldingSiteCode}
                            validator={new FormValidator()}
                        />
                    ) : (
                        ''
                    )}
                </Grid>
            </Grid>
        </>
    );
}
