// Libraries
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

// Api
import api from '../api/startupApi';

// Store
import { SliceStatus } from './IStore';

// Interfaces
import { AnelmaCultureType } from '../interfaces/types';

interface ConfigurationState {
    data: Configuration;
    selectedCulture: AnelmaCultureType;
    status: SliceStatus;
}

export interface Configuration {
    culture: {
        changeCultureUrl: string;
        supportedCultures: {
            selected: boolean;
            text: string;
            value: AnelmaCultureType;
        }[];
    };
    emailMessages: {
        supportRequest: {
            from: string;
            to: string;
        };
    };
    sessionNotification: {
        countDownStartInMinutes: number | null;
    };
    qlik: {
        qlikCloudBaseUrl: string | null;
        webIntegrationId: string | null;
    };
}

const initialState: ConfigurationState = {
    data: {
        culture: {
            changeCultureUrl: '',
            supportedCultures: [],
        },
        emailMessages: {
            supportRequest: {
                from: '',
                to: '',
            },
        },
        sessionNotification: {
            countDownStartInMinutes: null,
        },
        qlik: {
            qlikCloudBaseUrl: null,
            webIntegrationId: null,
        },
    },
    selectedCulture: 'fi',
    status: 'initial',
};

function loadData(state: Draft<ConfigurationState>, action: PayloadAction<Configuration>) {
    state.data = action.payload;
    let cultureObject = action.payload.culture.supportedCultures.find(
        (item) => item.selected === true
    );
    state.selectedCulture = cultureObject ? cultureObject.value : 'fi';
    state.status = 'ready';
    state.data.qlik = action?.payload?.qlik;
}

const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        load: loadData,
        updateIsLoading(state) {
            state.status = 'loading';
        },
    },
});

const { load, updateIsLoading } = configurationSlice.actions;

export const loadConfigurationAsync = () => {
    return async (dispatch: any) => {
        try {
            dispatch(updateIsLoading());
            const loginStatus = await api.getConfiguration();
            if (loginStatus) dispatch(load(loginStatus.Entity));
        } catch (err) {
            console.error(err);
        }
    };
};

export default configurationSlice.reducer;
