// MUI
import { Typography } from '@mui/material';

// Feature
import utils from './utils';
import './contentTypography.scss';

interface ContentTypographyProps {
    text: string;
    variant: ContentTypographyVariant;
}

export type ContentTypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1';

export default function ContentTypography(props: ContentTypographyProps) {
    const { text, ...rest } = props;

    return <Typography {...rest} dangerouslySetInnerHTML={utils.wrapHtml(text)} />;
}
