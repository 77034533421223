import moment from 'moment';

export type UiFormats = 'DD.MM.YYYY' | 'DD.MM.YYYY HH:mm' | 'HH:mm';
export type UtcString = string | null | undefined;
export type LocalMoment = moment.Moment | null | undefined;

class DateUtils {
    formatLocalMoment(local: LocalMoment, format: UiFormats): string {
        return local?.format(format) || '';
    }

    formatUtcString(utc: UtcString, format: UiFormats): string {
        const local = this.utcStringToLocalMoment(utc);
        return this.formatLocalMoment(local, format);
    }

    localMomentToUtcString(local: LocalMoment): string {
        if (!local || !local.isValid()) return '';
        return moment(local).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    formatLocalMomentToUtcString(local: LocalMoment, format: string): string {
        if (!local || !local.isValid()) return '';
        return moment(local).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    stringToDate(value: UtcString): Date | null {
        const local = this.stringToMoment(value);
        return local?.toDate() || null;
    }

    stringToMoment(utc: UtcString): moment.Moment | null {
        if (!utc) return null;
        const local = moment(`${utc}`);
        return local.isValid() ? local : null;
    }

    updateDatePickerDate = (
        type: 'date' | 'time',
        currentDate: Date,
        oldDate: moment.Moment | null
    ) => {
        const newDate = moment(currentDate);
        if (!oldDate) return newDate;
        const oldDate2 = moment(oldDate);
        type === 'date'
            ? newDate.hours(oldDate2.hours()).minutes(oldDate2.minutes())
            : newDate.year(oldDate2.year()).month(oldDate2.month()).date(oldDate2.date());
        return newDate;
    };

    utcStringToLocalDate(utc: UtcString): Date | null {
        const local = this.utcStringToLocalMoment(utc);
        return local?.toDate() || null;
    }

    utcStringToLocalMoment(utc: UtcString): moment.Moment | null {
        if (!utc) return null;
        const local = moment(`${utc}Z`);
        return local.isValid() ? local : null;
    }
}

export default new DateUtils();
