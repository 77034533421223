// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import FormValidator from '../../core/FormValidator';
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AAutocomplete, AAutocompleteValue } from '../../common/inputs';

// Api
import api from '../../api/authorizationApi';

// Feature
import { Context, ContextData } from './ContextSelector';

interface AdminContextSelectorProps {
    contextData: ContextData;
    setContexts: (contexts: Context[]) => void;
    setSelectedContext: (context: Context | null) => void;
}

export default function AdminContextSelector(props: AdminContextSelectorProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const { contextData } = props;

    const [contexts, setContexts] = useState<Context[]>(contextData.contexts);
    const [value, setValue] = useState<AAutocompleteValue>(
        contextData.selectedContext?.context || null
    );

    useEffect(() => {
        setContexts(contextData.contexts);
    }, [contextData.contexts]);

    useEffect(() => {
        setValue(contextData.selectedContext?.context || null);
    }, [contextData.selectedContext]);

    const formatItem = (item: Context) => {
        return {
            text: item?.displayName || '',
            value: item?.context || null,
        };
    };

    const search = (searchTerm: string): Promise<Context[]> => {
        return new Promise<Context[]>((resolve) => {
            api.getContextList(searchTerm).then((response) => {
                const c = response?.Items || [];
                props.setContexts(c);
                resolve(c);
            });
        });
    };

    return (
        <Grid container>
            <Grid item style={{ width: '300px' }}>
                <AAutocomplete
                    formatItem={(item: Context) => formatItem(item)}
                    id='admin-context-selector'
                    initialOptions={
                        contextData.selectedContext ? [formatItem(contextData.selectedContext)] : []
                    }
                    label={t('AnelmaGeneral:1149')}
                    minSearchLength={2}
                    onChange={(v) => {
                        setValue(v);
                        const context = contexts.find((c) => c.context === v) || null;
                        props.setSelectedContext(context);
                    }}
                    search={(inputValue) => search(inputValue)}
                    validator={new FormValidator()}
                    value={value}
                    withoutContainer
                    isContextSelector
                />
            </Grid>
        </Grid>
    );
}
