// Libraries
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

// MUI
import { useTheme, useMediaQuery, Grid, ImageList, ImageListItem } from '@mui/material';
import { Add, Edit, Search } from '@mui/icons-material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { ADefaultButton } from '../../../common/buttons';
import AImageList from '../../../common/imageGallery/AImageList';
import AdditionalDialogActionButtons from '../../shared/components/dialog/AdditionalDialogActionButtons';
import EditIcon from '@mui/icons-material/Edit';

// Core
import { boundaryRepository } from '../../../core/boundaries';
import FormValidator from '../../../core/FormValidator';
import { ParameterValues, ResourceTextApplication, paramRepository } from '../../../core/resources';
import utils from '../../../core/utils';
import auth from '../../../core/authorization';
import AddAnimalDialog from '../../shared/components/AddAnimalDialog';
import DocumentAddDialog from '../../../documents/addDialog/DocumentAddDialog';
import ChainInformationDialog from '../../chain-information/components/ChainInformationDialog';
import ModifySalesTypeDialog from './sales-type/ModifySalesTypeDialog';
import MidSaveConfirmDialog from './general/MidSaveConfirmDialog';
import ModifyCollectWeekDialog from './general/ModifyCollectWeekDialog';

// Common
import { ADialogButtonsProp, ADialogContent, ADialogActions } from '../../../common/dialog';
import {
    AInputItem,
    AAutocompleteValue,
    AAutocomplete,
    AErrorTextInput,
    ATextInput,
    ADropdown,
    ALabel,
} from '../../../common/inputs';
import { Context } from '../../../common/contextSelector/ContextSelector';
import Annotation from '../../../common/annotations/Annotation';
import { SecondaryDialogTypeEnum } from '../../shared/types/enum';

// Interfaces
import { DialogModeEnum, AnnouncementTypeEnum } from '../../../interfaces/enums';
import { IAnimalSummary, IAnimalSummaryExtraInfo, IFarmAnimal } from '../../../interfaces/IAnimal';
import {
    IAnimalAnnouncement,
    IAnimalAnnouncementBatch,
    IAnimalAnnouncementContainsAnimals,
    IAnimalAnnouncementContainsAnimalsResult,
    ICollectWeek,
    ICreateAnnouncement,
} from '../../../interfaces/IAnnouncement';
import { IFarmDetails } from '../../../interfaces/IBusinessEntities';
import { IApiResultMessage, ITiltuApiResult } from '../../../interfaces/IGeneral';
import { GUIDType } from '../../../interfaces/types';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import companyApi from '../../../api/companyApi';
import animalAnnouncementApi from '../../../api/animalAnnouncementApi';
import productionPlanApi from '../../../api/productionPlanApi';

// Feature - Animal Announcement

import { Strings } from '../../shared/constants/strings';
import { IGridSize } from './bovine-slaughter-announcement/BovineSlaughterAnnouncement';
import {
    hasExpressCow,
    omitAnimalAnnouncementAnimalsFromSelectableAnimals,
} from '../helpers/animal';
import {
    collectWeekStartNotValid,
    collectWeekStartHasValue,
    collectWeekEndHasValue,
    collectWeekEndNotValid,
    batchesHaveValidCollectWeek,
    defaultCollectWeek,
} from '../helpers/collectWeek';
import {
    getAnnouncementAnimals,
    hasAnnouncementResponseErrors,
    isFarmDetailsValidForSalesTypeUpdate,
    mapAnimalAnnouncementUpdate,
    mapAnimalsToAnimalAnnouncement,
    mapAnimalSummaryAnimalsToIAnimalAnnouncementAnimal,
    mapAnimalSummaryToAnnouncementDialog,
    mapBovineInfos,
    mapSelectedAnimalsToExistingAnimalAnnouncement,
} from '../helpers/data';
import {
    generateCollectWeekSequence,
    initializeCollectWeekStart,
    mapCollectWeeksForDropdown,
} from '../helpers/date';
import { removeDashes } from '../helpers/euIdentifier';
import { initializeHoldingSiteId, getHoldingSiteItems } from '../helpers/holdingSite';
import { getAllowedAnnouncementSalesTypes } from '../helpers/salesType';
import { getDistinctSalesTypeAnnotationMsgs } from '../helpers/salesTypeAnnotation';
import {
    constructAnnouncementNumberGridSize,
    constructCollectWeekGridSize,
} from '../../shared/helpers/gridSize';
import { isReadOnlyMode } from '../../shared/helpers/utils';
import AnimalAnnouncementDataGrid from './data-grid/AnimalAnnouncementDataGrid';
import { ISalesTypeDialogProps, IModifyCollectWeekDialogProps } from '../../shared/types/propTypes';
import '../styles/announcementStyles.scss';
import { checkCanCreateAnimalAnnouncement } from '../../shared/helpers/data';

import { IDocumentAddDialogData } from '../../../documents/IDocuments';
import defaultData from '../../../core/defaultData';
import {
    isDataValidForSave,
    isSalesTypeInvalidForSave,
} from '../../shared/validators/bovineAnnouncementValidators';
import { announcementDataService } from '../services/announcementDataService';
import documentApi from '../../../api/documentApi';
import { DocumentFileTypeEnum } from '../../../documents/IDocuments';
import {
    canCreateOrUpdateSlaughterAnnouncementWithoutChainInformation,
    canViewBovineSlaughterAnnouncementSaveAsAdmin,
    canCreateSlaughterAnnouncement,
    canViewBovineSlaughterAnnouncementDialog,
} from './bovine-slaughter-announcement/helpers/bovineSlaughterAnnouncementAccessRights';

export interface IAnnouncementDialogProps {
    data: IAnimalAnnouncement | null;
    announcementType: AnnouncementTypeEnum;
    onClose: () => void;
    handleDialogClose: () => void;
    mode: DialogModeEnum;
    selectedAnimals: IAnimalSummary[] | null;
    selectedHoldingSites?: string[];
    animals: IAnimalSummaryExtraInfo[] | null;
    processAnnouncementSave: (
        data: ITiltuApiResult<any>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => void;
    mapUpdatedSalesTypeToAllAnimalsData: (data: IFarmAnimal[]) => void;
    loading: boolean;
    readOnly?: boolean;
    chainInformationFound?: boolean;
    dataImportedViaMmfi?: boolean;
}

export default function AnimalAnnouncement(props: IAnnouncementDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBovine',
        'AnelmaGeneral',
        'AnelmaCompany',
        'AnelmaAnimalAnnouncement',
    ]);

    const theme = useTheme();
    const isBelowMdDevice = useMediaQuery(theme.breakpoints.down('md'));

    const context = useAppSelector((state) => state.context);
    const validator = new FormValidator();
    const { enqueueSnackbar } = useSnackbar();

    const announcementType = props.announcementType;

    const [isLoading, setIsLoading] = useState<boolean>();
    const [announcementData, setAnnouncementData] = useState<IAnimalAnnouncement>(
        props.data
            ? JSON.parse(JSON.stringify(props.data))
            : mapAnimalsToAnimalAnnouncement(props.selectedAnimals || [])
    );
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();
    const [producerNumber, setProducerNumber] = useState<string>('');
    const [holdingSiteItems, setHoldingSiteItems] = useState<AInputItem<AAutocompleteValue>[]>([]);
    const [holdingSiteId, setHoldingSiteId] = useState<string>('');
    const [collectWeekStart, setCollectWeekStart] = useState<ICollectWeek>(
        initializeCollectWeekStart(announcementType)
    );
    const [collectWeekEnd, setCollectWeekEnd] = useState<ICollectWeek>({
        Year: collectWeekStart.Year,
        Week: collectWeekStart.Week + 1,
    });

    const [collectWeeks, setCollectWeeks] = useState<ICollectWeek[]>([]);

    const [addDialogVisibility, setAddDialogVisibility] = useState<boolean>(false);

    const [allowedSalesTypes, setAllowedSalesTypes] = useState<ParameterValues | null>(null);
    const [salesTypesAreValid, setSalesTypesAreValid] = useState<boolean>(false);
    const [holdingSiteIsValid, setHoldingSiteIsValid] = useState<boolean>(false);
    const [salesTypeAnnotations, setSalesTypeAnnotations] = useState<string[]>([]);

    const [selectedAnimals, setSelectedAnimals] = useState<IAnimalSummary[]>([]);
    const [animalsForDialog, setAnimalsForDialog] = useState<IAnimalSummary[] | null>(null);

    const [addInfoMinLength, setAddInfoMinLength] = useState<number | undefined>();
    const [addInfoMaxLength, setAddInfoMaxLength] = useState<number | undefined>();
    const [boundariesLoaded, setBoundariesLoaded] = useState<boolean>(false);

    const [addAnimalDialogVisible, setAddAnimalDialogVisible] = useState<boolean>(false);
    const [chainInformationDialogVisible, setChainInformationDialogVisible] =
        useState<boolean>(false);
    const [salesTypeDialogVisible, setSalesTypeDialogVisible] = useState<boolean>(false);
    const [collectWeekDialogVisible, setCollectWeekDialogVisible] = useState<boolean>(false);
    const [chainInfoConfirmDialogVisible, setChainInfoConfirmDialogVisible] =
        useState<boolean>(false);
    const [collectWeekGridSize, setCollectWeekGridSize] = useState<IGridSize>({
        Xs: 2,
        Sm: 2,
        Md: 2,
        Lg: 2,
    });
    const [announcementNumberGridSize, setAnnouncementNumberGridSize] = useState<IGridSize>({
        Xs: 4,
        Sm: 4,
        Md: 4,
        Lg: 4,
    });
    const [readOnlyMode] = useState<boolean>(isReadOnlyMode(props.mode, props.readOnly ?? false));
    const [hideCollectWeeks, setHideCollectWeeks] = useState<boolean>(false);
    const [canCreateAnimalAnnouncementViaMmfi, setCanCreateAnimalAnnouncementViaMmfi] = useState<
        boolean | null
    >(null);
    const [productionPlanCompletedParameters, setProductionPlanCompletedParameters] =
        useState<ParameterValues | null>(null);

    const [preview, setPreview] = useState<IDocumentAddDialogData>(defaultData.documentDetails);

    const initializeFarmDetails = (context: Context) => {
        setIsLoading(true);
        const companyByContext = companyApi
            .getCompanyByContext(context.context)
            .then((response) => {
                setFarmDetails(response?.Entity as IFarmDetails);
            });
        Promise.all([companyByContext]).then(() => {
            setIsLoading(false);
        });
        let producerId = utils.context.parseFarmContext(context.context)?.producerId;
        if (producerId) setProducerNumber(producerId);

        validateHoldingSite();
    };

    // Generate collect week sequences for the current and next year
    const initializeCollectWeeks = (from: number) => {
        setCollectWeeks(
            generateCollectWeekSequence(
                from,
                moment().isoWeeksInYear() - from,
                moment().isoWeekYear()
            )
        );
        if (moment().add(12, 'months').year() === moment().add(1, 'y').year()) {
            setCollectWeeks((previousState) => [
                ...previousState,
                ...generateCollectWeekSequence(
                    1,
                    moment().add(12, 'months').isoWeek(),
                    moment().isoWeekYear() + 1
                ),
            ]);
        }
    };

    useEffect(() => {
        boundaryRepository.load(['AnelmaAnimalAnnouncement']).then(() => {
            const boundaries = boundaryRepository.resource(
                'AnelmaAnimalAnnouncement',
                'BovinePurchaseAnnouncementInfo'
            );

            if (boundaries) {
                setAddInfoMaxLength(
                    Number.isInteger(boundaries.maxValue)
                        ? (boundaries.maxValue as number)
                        : undefined
                );
                setAddInfoMinLength(
                    Number.isInteger(boundaries.minValue)
                        ? (boundaries.minValue as number)
                        : undefined
                );
            }

            setBoundariesLoaded(true);
        });

        setProductionPlanCompletedParameters(
            paramRepository.resource('AnelmaBovine', 'ProductionPlanCompleted')
        );
    }, []);

    useEffect(() => {
        if (getAnnouncementAnimals(announcementData).length === 0)
            enqueueSnackbar(t('AnelmaBovine:1061'), {
                variant: 'error',
            });
        const start = initializeCollectWeekStart(props.announcementType);
        initializeCollectWeeks(start.Week);
        validateSalesTypes();
    }, [props.mode, props.readOnly, props.data, props.selectedAnimals]);

    useEffect(() => {
        if (context.data.currentContext) {
            initializeFarmDetails(context.data.currentContext);
        } else {
            setFarmDetails(undefined);
            setHoldingSiteIsValid(false);
        }
    }, [context]);

    useEffect(() => {
        resolveSalesTypeAnnotations(announcementData);
        validateSalesTypes();
        setHideCollectWeeks(hasExpressCow(props.announcementType, announcementData));
    }, [announcementData]);

    useEffect(() => {
        if (farmDetails && props.mode === DialogModeEnum.Create) {
            announcementContainsAnimal(announcementData.Batches.flatMap((b) => b.Animals));
        }
        setHoldingSiteId(
            props.selectedHoldingSites && props.selectedHoldingSites?.length === 1
                ? props.selectedHoldingSites[0]
                : initializeHoldingSiteId(
                      props.mode,
                      props.selectedAnimals,
                      farmDetails,
                      props.data?.HoldingSiteId
                  )
        );

        if (farmDetails) {
            setHoldingSiteItems(getHoldingSiteItems(farmDetails));
            getAllowedAnnouncementSalesTypes(farmDetails, announcementType).then(
                (salesTypeParams) => {
                    setAllowedSalesTypes(salesTypeParams);
                }
            );
        }
    }, [farmDetails]);

    useEffect(() => {
        if (collectWeekStartNotValid(collectWeekStart, collectWeekEnd)) {
            if (
                collectWeekStartHasValue(collectWeekStart) &&
                collectWeekEndHasValue(collectWeekEnd)
            ) {
                enqueueSnackbar(t('AnelmaAnimalAnnouncement:1006'), {
                    variant: 'error',
                });
                setCollectWeekStart({ Year: -1, Week: -1 });
            }
        }
    }, [collectWeekStart]);

    // useEffect(() => {
    //     if (announcementData.Id) {
    //         documentApi.downloadFilesByOwnerId(announcementData.Id);
    //     }
    // }, [announcementData.Id]);

    useEffect(() => {
        if (collectWeekEndNotValid(collectWeekEnd, collectWeekStart)) {
            if (collectWeekEndHasValue(collectWeekEnd)) {
                enqueueSnackbar(t('AnelmaAnimalAnnouncement:1007'), {
                    variant: 'error',
                });
                setCollectWeekEnd({ Year: -1, Week: -1 });
            }
        }
    }, [collectWeekEnd]);

    useEffect(() => {
        validateSalesTypes();
    }, [allowedSalesTypes]);

    useEffect(() => {
        validateHoldingSite();
    }, [holdingSiteId, holdingSiteItems]);

    useEffect(() => {
        if (isBelowMdDevice) {
            setAnnouncementNumberGridSize(constructAnnouncementNumberGridSize());
            setCollectWeekGridSize(constructCollectWeekGridSize());
        }
    }, [isBelowMdDevice]);

    useEffect(() => {
        if (props.dataImportedViaMmfi && farmDetails) loadProductionPlans();
    }, [props.dataImportedViaMmfi, farmDetails]);

    useEffect(() => {
        if (!animalAnnouncementCreationViaMmmfiIsValid(canCreateAnimalAnnouncementViaMmfi)) {
            enqueueSnackbar(t('AnelmaBovine:1092'), {
                variant: 'error',
            });
        }
    }, [canCreateAnimalAnnouncementViaMmfi]);

    const loadProductionPlans = () => {
        setIsLoading(true);

        if (farmDetails && farmDetails.Id && productionPlanCompletedParameters)
            productionPlanApi.getPlans(farmDetails.Id).then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaSolmuErrors:10011'), {
                        variant: 'error',
                    });
                    setIsLoading(false);
                    return;
                }
                setCanCreateAnimalAnnouncementViaMmfi(
                    checkCanCreateAnimalAnnouncement(
                        auth,
                        response.Items,
                        productionPlanCompletedParameters
                    )
                );
                setIsLoading(false);
            });
    };

    const resolveSalesTypeAnnotations = (announcementData?: IAnimalAnnouncement) => {
        setSalesTypeAnnotations(
            getDistinctSalesTypeAnnotationMsgs(announcementData).map((x) => t(x))
        );
    };

    const handleAnimalDataChange = (params: string[]) => {
        if (props.animals) {
            setAddAnimalDialogVisible(false);

            const changedAnimals = mapAnimalSummaryToAnnouncementDialog(
                props.animals,
                params as GridRowSelectionModel
            );

            announcementContainsAnimal(changedAnimals);

            setAnnouncementData(
                mapSelectedAnimalsToExistingAnimalAnnouncement(changedAnimals, announcementData)
            );
        }
    };

    const handleSalesTypeUpdate = (response: IFarmAnimal[]) => {
        mapUpdatedSalesTypeToAnnouncementData(response);
    };

    const mapUpdatedSalesTypeToAnnouncementData = (response: IFarmAnimal[]) => {
        if (announcementData) {
            let currentannouncementData: IAnimalAnnouncement = {
                ...announcementData,
            };
            for (const changedAnimal of response) {
                for (const batch of currentannouncementData.Batches) {
                    batch.Animals = batch.Animals.map((x) => ({ ...x }));
                    for (const animal of batch.Animals) {
                        if (changedAnimal.AnimalGUID === animal.AnimalId) {
                            animal.SalesType = changedAnimal.SalesType;

                            const collectWeek = defaultCollectWeek(batch.Animals[0]);
                            if (
                                collectWeek.Start.Year > 0 ||
                                props.mode === DialogModeEnum.Create
                            ) {
                                batch.CollectWeekStart = collectWeek.Start;
                                batch.CollectWeekEnd = collectWeek.End;
                            }
                        }
                    }
                }
            }
            setAnnouncementData(currentannouncementData);
        }
    };

    const removeAnimalFromAnnouncement = (
        animalData: IAnimalAnnouncementBatch,
        animalAnnouncementNumber?: number
    ) => {
        if (!announcementData) return;

        // map the new announcement model from the animal data.
        const newAnnouncement = { ...announcementData };
        newAnnouncement.Batches =
            announcementData?.Batches.filter((x) => x.BatchNumber !== animalData.BatchNumber) || [];

        setAnnouncementData(newAnnouncement);
    };

    const announcementContainsAnimal = async (animals: { EuIdentifier: string }[] | null) => {
        if (!farmDetails || !farmDetails.Id || !animals) return;

        const euIdentifierList = animals.map((animal) => removeDashes(animal.EuIdentifier));

        if (euIdentifierList.length <= 0) return;

        var payload: IAnimalAnnouncementContainsAnimals = {
            farmGuid: farmDetails.Id as GUIDType,
            announcementType: announcementType,
            euIdentifierList: euIdentifierList,
        };

        const data = await animalAnnouncementApi.animalAnnouncementContainsAnimals(payload);
        if (data) {
            const animalContainsData = data as unknown as IAnimalAnnouncementContainsAnimalsResult;

            if (animalContainsData.ResultSet.find((r) => r.IsInAnnouncement)) {
                switch (props.announcementType) {
                    case AnnouncementTypeEnum.Slaughter:
                    case AnnouncementTypeEnum.ManualBovineSlaughter:
                        enqueueSnackbar(t('AnelmaBovine:1096'), {
                            variant: 'warning',
                        });
                        break;
                    case AnnouncementTypeEnum.Intermediation:
                    case AnnouncementTypeEnum.ManualBovineIntermediation:
                        enqueueSnackbar(t('AnelmaBovine:1116'), {
                            variant: 'warning',
                        });
                        break;
                    case AnnouncementTypeEnum.BovinePurchase:
                        break;
                }
            }
        }
    };

    const validateSalesTypes = () => {
        if (!allowedSalesTypes) return setSalesTypesAreValid(true);

        for (let batch of announcementData.Batches) {
            for (let animal of batch.Animals) {
                const validSalesType = allowedSalesTypes.find(
                    (x) => Number(x.code) === Number(animal.SalesType)
                );
                if (!validSalesType) {
                    setSalesTypesAreValid(false);
                    return;
                }
            }
        }
        setSalesTypesAreValid(true);
    };

    const validateHoldingSite = () => {
        // First if avoids error state on loading
        if (farmDetails && holdingSiteId) {
            if (
                holdingSiteId.length > 0 &&
                farmDetails.HoldingSites.findIndex((h) => h.HoldingSiteId === holdingSiteId) != -1
            )
                return setHoldingSiteIsValid(true);

            return setHoldingSiteIsValid(false);
        }
    };

    const additionalActionButtonVisibility = (): boolean => {
        if (
            !batchesHaveValidCollectWeek(announcementData?.Batches ?? [], props.mode) ||
            !auth.canViewBovineBrokerSales ||
            isLoading ||
            (props.readOnly && props.readOnly === true)
        )
            return true;

        if (props.mode === DialogModeEnum.Create && auth.canCreateBovineBrokerSales) return false;
        else if (props.mode === DialogModeEnum.Edit && auth.canModifyBovineBrokerSales)
            return false;

        return true;
    };

    const saveActionButtonVisibility = (): boolean => {
        return additionalActionButtonVisibility()
            ? true
            : farmDetails !== undefined &&
              farmDetails.HoldingSites !== null &&
              farmDetails.HoldingSites.length > 0 &&
              farmDetails.HoldingSites[0].HoldingSiteId === ''
            ? true
            : false;
    };

    const displayGeneralSnackbarError = () => {
        enqueueSnackbar(t('AnelmaGeneral:1030'), {
            variant: 'error',
        });
    };

    const openChainInformationDialog = () => {
        if (readOnlyMode !== true) {
            setChainInfoConfirmDialogVisible(true);
        } else {
            setChainInformationDialogVisible(true);
        }
    };

    const deleteBatches = async () => {
        if (!props.data) return false;

        let ok = true;
        setIsLoading(true);

        for (let batch of props.data.Batches) {
            const isDeleted = !announcementData.Batches.find((b) => b.Id === batch.Id);
            if (!isDeleted) continue;

            const response = await animalAnnouncementApi.deleteAnimalAnnouncementBatch(
                announcementData.Number,
                batch.BatchNumber as unknown as string[]
            );
            if (!response) {
                ok = false;
                enqueueSnackbar(t('#Issue when deleting batch from announcement'), {
                    variant: 'error',
                });
            }
            if (!ok) break;
            props.data.Batches = props.data.Batches.filter((b) => b.Id !== batch.Id);
        }

        setIsLoading(false);
        return ok;
    };

    const commitSalesTypeChanges = () => {
        if (!farmDetails || !farmDetails.Id) return;

        const updates: { [key: string]: IFarmAnimal[] } = {};
        for (let animal of announcementData.Batches.map((b) => b.Animals[0])) {
            const original = props.animals?.find((x) => x.EuIdentifier === animal.EuIdentifier);
            if (!original || original?.SalesType === animal.SalesType) continue;
            original.SalesType = animal.SalesType;

            const key = String(animal.SalesType);
            if (!updates[key]) updates[key] = [];

            updates[key].push({
                AnimalGUID: animal.AnimalId,
                AnimalType: 1,
                ExternalIdentifier: animal.EuIdentifier,
                FarmId: farmDetails.Id,
                SalesType: animal.SalesType,
                ValidFrom: new Date(announcementData.Created),
                ValidTo: null,
            });
        }

        if (props.data) props.data.Batches = announcementData.Batches;

        for (let key in updates) {
            const update = updates[key];
            props.mapUpdatedSalesTypeToAllAnimalsData(update);
        }
    };

    const canSave = () => {
        if (props.mode === DialogModeEnum.Create)
            return (
                isDataValidForSave(
                    props.mode,
                    farmDetails?.Id,
                    producerNumber,
                    announcementData?.Batches,
                    collectWeekStart,
                    collectWeekEnd,
                    null,
                    holdingSiteId
                ) && announcementData.Batches.length > 0
            );
        return true;
    };

    const handleSaveCreate = async (openChainInformation: boolean) => {
        if (
            isDataValidForSave(
                props.mode,
                farmDetails?.Id,
                producerNumber,
                announcementData?.Batches,
                collectWeekStart,
                collectWeekEnd,
                null,
                holdingSiteId
            )
        ) {
            const selectedAnimals = getAnnouncementAnimals(announcementData);
            const bovineInfos = mapBovineInfos(
                selectedAnimals,
                holdingSiteId,
                collectWeekStart,
                collectWeekEnd
            );
            if (bovineInfos.find((_) => isSalesTypeInvalidForSave(_.SalesType))) {
                enqueueSnackbar(t('AnelmaBovine:1133'), {
                    variant: 'error',
                });
                setIsLoading(false);
                return;
            }
            const payload: ICreateAnnouncement = {
                ProducerNumber: Number(producerNumber),
                AnnouncementInfo: announcementData?.Info || '',
                FarmId: farmDetails?.Id as GUIDType,
                BovineInfos: bovineInfos,
            };

            if (payload.BovineInfos.length > 0) {
                setIsLoading(true);
                announcementDataService
                    .createAnnouncementCall(payload, announcementType)
                    .then((response) => {
                        if (!response) {
                            enqueueSnackbar(`${t('AnelmaGeneral:1019')}`, {
                                variant: 'error',
                            });
                            setIsLoading(false);
                            return;
                        }
                        setIsLoading(false);
                        if (hasAnnouncementResponseErrors(response)) {
                            response.Summary.Errors.map((_) => {
                                enqueueSnackbar(`${_.Explanation}`, {
                                    variant: 'error',
                                });
                            });
                            return;
                        } else if (
                            !hasAnnouncementResponseErrors(response) &&
                            openChainInformation &&
                            announcementData
                        ) {
                            commitSalesTypeChanges();
                            props.processAnnouncementSave(
                                response,
                                AnnouncementTypeEnum.SlaughterWithChainInformation,
                                holdingSiteId
                            );
                            announcementData.Id = response.Entity.AnnouncementId;
                            announcementData.Number = Number(response.Entity.AnnouncementNumber);
                            setChainInformationDialogVisible(true);
                        } else if (!hasAnnouncementResponseErrors(response)) {
                            commitSalesTypeChanges();
                            props.processAnnouncementSave(
                                response,
                                announcementType,
                                holdingSiteId
                            );
                            enqueueSnackbar(t('AnelmaGeneral:1018'), {
                                variant: 'success',
                            });
                            props.onClose();
                        }
                    })
                    .catch((error: IApiResultMessage[]) => {
                        if (error && error?.length > 0) {
                            error?.forEach((err) =>
                                enqueueSnackbar(err?.Explanation, {
                                    variant: 'error',
                                })
                            );
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else setIsLoading(false);
        } else displayGeneralSnackbarError();
    };

    const handleSaveUpdate = async (openChainInformation: boolean) => {
        if (
            isDataValidForSave(
                props.mode,
                farmDetails?.Id,
                producerNumber,
                null,
                collectWeekStart,
                collectWeekEnd,
                announcementData?.Number,
                holdingSiteId
            ) &&
            props.data?.Batches &&
            announcementData !== null &&
            batchesHaveValidCollectWeek(props.data?.Batches ?? [], props.mode) &&
            farmDetails &&
            farmDetails?.Id
        ) {
            const payload = mapAnimalAnnouncementUpdate(
                announcementData,
                props.data.Batches,
                producerNumber,
                farmDetails.Id,
                holdingSiteId
            );

            var batchesDeleted = await deleteBatches();
            if (!batchesDeleted) return;

            setIsLoading(true);
            announcementDataService
                .updateAnnouncementCall(payload, announcementType)
                .then((response) => {
                    if (!response) {
                        enqueueSnackbar(`${t('AnelmaGeneral:1019')}`, {
                            variant: 'error',
                        });
                        setIsLoading(false);
                        return;
                    }
                    if (hasAnnouncementResponseErrors(response)) {
                        response.Summary.Errors.map((_) => {
                            enqueueSnackbar(`${_.Explanation}`, {
                                variant: 'error',
                            });
                        });
                        setIsLoading(false);
                        return;
                    }
                    commitSalesTypeChanges();
                    setIsLoading(false);
                    if (payload.Additions.length > 0) {
                        response.Entity.Status = '1';
                    }
                    props.processAnnouncementSave(response, announcementType, holdingSiteId);

                    if (openChainInformation) {
                        setChainInformationDialogVisible(true);
                    } else {
                        enqueueSnackbar(t('AnelmaGeneral:1018'), {
                            variant: 'success',
                        });
                        props.onClose();
                    }
                })
                .catch((error: IApiResultMessage[]) => {
                    if (error) {
                        error.forEach((e) =>
                            enqueueSnackbar(e?.Explanation ?? e, {
                                variant: 'error',
                            })
                        );
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setIsLoading(false);
        }
    };

    const handleSave = (openChainInformation: boolean) => {
        if (!salesTypesAreValid) {
            displayGeneralSnackbarError();
            return;
        }
        if (props.mode === DialogModeEnum.Create) {
            handleSaveCreate(openChainInformation);
        } else if (props.mode === DialogModeEnum.Edit) {
            handleSaveUpdate(openChainInformation);
        }
        // if (preview.file.length > 0)
        //     documentApi.uploadDocuments(preview, DocumentFileTypeEnum.Image);
    };

    const animalAnnouncementCreationViaMmmfiIsValid = (value: boolean | null): boolean => {
        return value === null ? true : value;
    };

    const salesTypeDialogProps: ISalesTypeDialogProps = {
        id: announcementData.Id,
        onClose: () => {
            setAnimalsForDialog(null);
            setSalesTypeDialogVisible(false);
        },
        animals: mapAnimalSummaryAnimalsToIAnimalAnnouncementAnimal(
            animalsForDialog || selectedAnimals
        ),
        allAnimals: null,
        handleSalesTypeUpdate: handleSalesTypeUpdate,
        farmId: isFarmDetailsValidForSalesTypeUpdate(farmDetails),
        farmDetails: farmDetails,
        announcementType: announcementType,
        mode: props.mode,
    };

    const modifyCollectWeekDialogProps: IModifyCollectWeekDialogProps = {
        onClose: () => {
            setCollectWeekDialogVisible(false);
        },
        announcementType: announcementType,
        animals: mapAnimalSummaryAnimalsToIAnimalAnnouncementAnimal(selectedAnimals),
        batches:
            announcementData !== null && props.mode === DialogModeEnum.Edit
                ? announcementData.Batches
                : undefined,
    };

    const dialogActionButtons_intermediation: ADialogButtonsProp = {
        left: [
            {
                label: t('AnelmaGeneral:1009'),
                onClick: () => props.handleDialogClose(),
                type: 'cancel',
            },
            {
                label: t('AnelmaBovine:1049'),
                icon: <Add />,
                onClick: () => setAddAnimalDialogVisible(true),
                type: 'default',
                disabled:
                    additionalActionButtonVisibility() ||
                    !holdingSiteIsValid ||
                    !animalAnnouncementCreationViaMmmfiIsValid(canCreateAnimalAnnouncementViaMmfi),
            },
        ],
        right: [
            {
                onClick: handleSave,
                type: 'save',
                disabled:
                    !salesTypesAreValid ||
                    saveActionButtonVisibility() ||
                    !canSave() ||
                    !holdingSiteIsValid ||
                    !animalAnnouncementCreationViaMmmfiIsValid(canCreateAnimalAnnouncementViaMmfi),
            },
        ],
    };

    const dialogActionButtons_slaughter: ADialogButtonsProp = {
        left: canViewBovineSlaughterAnnouncementSaveAsAdmin(auth)
            ? [
                  {
                      label: t('AnelmaGeneral:1009'),
                      onClick: () => props.handleDialogClose(),
                      type: 'cancel',
                  },
                  {
                      label: t('AnelmaBovine:1049'),
                      icon: <Add />,
                      onClick: () => setAddAnimalDialogVisible(true),
                      type: 'default',
                      disabled:
                          isLoading ||
                          canCreateOrUpdateSlaughterAnnouncementWithoutChainInformation(auth) ||
                          !holdingSiteIsValid ||
                          readOnlyMode ||
                          !animalAnnouncementCreationViaMmmfiIsValid(
                              canCreateAnimalAnnouncementViaMmfi
                          ),
                  },
                  auth.canCreateBovineSlaughterAnnouncement && props.mode === DialogModeEnum.Edit
                      ? {
                            label: readOnlyMode ? t('AnelmaBovine:1158') : t('AnelmaBovine:1050'),
                            icon: readOnlyMode ? <Search /> : <Edit />,
                            style: { marginRight: 'auto', marginLeft: '65px' },
                            onClick: () => openChainInformationDialog(),
                            type: 'default',
                            disabled: props.chainInformationFound
                                ? true
                                : isLoading || !holdingSiteIsValid,
                        }
                      : {
                            // Left in code so it takes space on screen, even if it is hidden and if disable logic is wanted back
                            label: readOnlyMode ? t('AnelmaBovine:1158') : t('AnelmaBovine:1050'),
                            icon: readOnlyMode ? <Search /> : <Edit />,
                            style: {
                                marginRight: 'auto',
                                marginLeft: '65px',
                                visibility: 'hidden',
                            },
                            onClick: () => openChainInformationDialog(),
                            type: 'default',
                            disabled:
                                props.mode === DialogModeEnum.Create || isLoading
                                    ? true
                                    : props.mode === DialogModeEnum.Edit || isLoading
                                    ? true
                                    : false,
                        },
              ]
            : [
                  {
                      label: t('AnelmaGeneral:1009'),
                      onClick: () => props.handleDialogClose(),
                      type: 'cancel',
                  },
                  {
                      label: t('AnelmaBovine:1049'),
                      icon: <Add />,
                      onClick: () => setAddAnimalDialogVisible(true),
                      type: 'default',
                      disabled:
                          readOnlyMode ||
                          !holdingSiteIsValid ||
                          isLoading ||
                          (canCreateSlaughterAnnouncement(auth)
                              ? true
                              : canViewBovineSlaughterAnnouncementDialog(auth)) ||
                          !animalAnnouncementCreationViaMmmfiIsValid(
                              canCreateAnimalAnnouncementViaMmfi
                          ),
                  },
                  props.mode === DialogModeEnum.Edit
                      ? {
                            label: readOnlyMode ? t('AnelmaBovine:1158') : t('AnelmaBovine:1050'),
                            icon: readOnlyMode ? <Search /> : <Edit />,
                            style: { marginRight: 'auto', marginLeft: '65px' },
                            onClick: () => openChainInformationDialog(),
                            type: 'default',
                            disabled: props.chainInformationFound
                                ? true
                                : isLoading || !holdingSiteIsValid,
                        }
                      : {
                            // Left in code so it takes space on screen, even if it is hidden and if disable logic is wanted back
                            label: readOnlyMode ? t('AnelmaBovine:1158') : t('AnelmaBovine:1050'),
                            icon: readOnlyMode ? <Search /> : <Edit />,
                            style: {
                                marginRight: 'auto',
                                marginLeft: '65px',
                                visibility: 'hidden',
                            },
                            onClick: () => openChainInformationDialog(),
                            type: 'default',
                            disabled:
                                props.mode === DialogModeEnum.Create || isLoading
                                    ? true
                                    : props.mode === DialogModeEnum.Edit || isLoading
                                    ? true
                                    : false,
                        },
              ],
        right: canViewBovineSlaughterAnnouncementSaveAsAdmin(auth)
            ? [
                  {
                      onClick: () => {
                          if (validator.invalid) {
                              displayGeneralSnackbarError();
                              return;
                          }
                          handleSave(false);
                      },
                      type: 'save',
                      label: t('AnelmaBovine:1132'),
                      disabled:
                          readOnlyMode ||
                          !salesTypesAreValid ||
                          isLoading ||
                          !canSave() ||
                          saveActionButtonVisibility() ||
                          canCreateOrUpdateSlaughterAnnouncementWithoutChainInformation(auth) ||
                          !animalAnnouncementCreationViaMmmfiIsValid(
                              canCreateAnimalAnnouncementViaMmfi
                          ),
                  },
              ]
            : [
                  {
                      onClick: () => {
                          if (validator.invalid) {
                              displayGeneralSnackbarError();
                              return;
                          }
                          handleSave(true);
                      },
                      type: 'save',
                      label:
                          props.mode === DialogModeEnum.Edit
                              ? t('AnelmaGeneral:1001')
                              : t('AnelmaBovine:1131'),
                      disabled:
                          readOnlyMode ||
                          !salesTypesAreValid ||
                          isLoading ||
                          !canSave() ||
                          saveActionButtonVisibility() ||
                          (canCreateSlaughterAnnouncement(auth)
                              ? true
                              : canViewBovineSlaughterAnnouncementDialog(auth)) ||
                          !animalAnnouncementCreationViaMmmfiIsValid(
                              canCreateAnimalAnnouncementViaMmfi
                          ),
                  },
              ],
    };

    // const filesToSaveHandler = (data: IDocumentAddDialogData) => {
    //     setPreview(data);
    //     setAddDialogVisibility(false);
    //     return Promise.resolve(null);
    // };

    const dialogActionButtons =
        announcementType === AnnouncementTypeEnum.Slaughter
            ? dialogActionButtons_slaughter
            : dialogActionButtons_intermediation;

    const renderHoldingSiteComponent = () => {
        if (
            props.mode === DialogModeEnum.Create &&
            (holdingSiteId === '' || holdingSiteId === null) &&
            holdingSiteItems.length > 0
        ) {
            return (
                <Grid item xs={8} sm={8} md={8} lg={8}>
                    <AAutocomplete
                        id={Strings.AnnouncementHoldingSiteId}
                        items={holdingSiteItems}
                        label={t('AnelmaBovine:1063')}
                        onChange={(v) => setHoldingSiteId(v?.toString() ?? '')}
                        validator={validator}
                        required
                        value={holdingSiteId}
                        error={!holdingSiteIsValid}
                    />
                </Grid>
            );
        } else if (
            props.mode === DialogModeEnum.Create &&
            (holdingSiteId !== '' || holdingSiteId !== null)
        ) {
            return holdingSiteIsValid ? (
                <Grid item xs={8} sm={8} md={8} lg={8}>
                    <ATextInput
                        id={Strings.AnnouncementHoldingSiteId}
                        label={t('AnelmaBovine:1063')}
                        onChange={(v) => false}
                        validator={validator}
                        disabled
                        value={holdingSiteId}
                    />
                </Grid>
            ) : (
                <Grid item xs={8} sm={8} md={8} lg={8}>
                    <AErrorTextInput
                        id={Strings.AnnouncementHoldingSiteId}
                        label={t('AnelmaBovine:1063')}
                        onChange={(v) => false}
                        validator={validator}
                        disabled
                        value={holdingSiteId}
                    />
                </Grid>
            );
        }
        return (
            <Grid item xs={4} sm={4} md={4} lg={4}>
                <ATextInput
                    id={Strings.AnnouncementHoldingSiteId}
                    label={t('AnelmaBovine:1063')}
                    onChange={(v) => false}
                    validator={validator}
                    disabled
                    value={holdingSiteId}
                />
            </Grid>
        );
    };

    const renderCollectWeekSelectionInputs = () => {
        return props.mode === DialogModeEnum.Create && hideCollectWeeks === false ? (
            <>
                <Grid container direction='column'>
                    <ALabel className='anelma-animal-announcement-general-label'>
                        {props.announcementType === AnnouncementTypeEnum.Slaughter ||
                        props.announcementType ===
                            AnnouncementTypeEnum.SlaughterWithChainInformation ||
                        props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter
                            ? `${t('AnelmaBovine:1043')}`
                            : `${t('AnelmaBovine:1164')}`}
                    </ALabel>
                </Grid>
                <Grid
                    item
                    xs={collectWeekGridSize.Xs}
                    sm={collectWeekGridSize.Sm}
                    md={collectWeekGridSize.Md}
                    lg={collectWeekGridSize.Lg}
                >
                    <ADropdown
                        id={Strings.AnnouncementCollectWeekStartNumber}
                        onChange={(v) =>
                            setCollectWeekStart(JSON.parse(v as string) as ICollectWeek)
                        }
                        value={JSON.stringify(
                            collectWeekStart.Week === -1 && collectWeekStart.Year === -1
                                ? { Year: '', Week: '' }
                                : collectWeekStart
                        )}
                        items={mapCollectWeeksForDropdown(collectWeeks)}
                        validator={validator}
                        required
                        error={!collectWeekStartHasValue(collectWeekStart)}
                    />
                </Grid>
                <Grid
                    item
                    xs={collectWeekGridSize.Xs}
                    sm={collectWeekGridSize.Sm}
                    md={collectWeekGridSize.Md}
                    lg={collectWeekGridSize.Lg}
                >
                    <ADropdown
                        id={Strings.AnnouncementCollectWeekEndNumber}
                        onChange={(v) => setCollectWeekEnd(JSON.parse(v as string) as ICollectWeek)}
                        value={JSON.stringify(
                            collectWeekEnd.Week === -1 && collectWeekEnd.Year === -1
                                ? { Year: '', Week: '' }
                                : collectWeekEnd
                        )}
                        items={mapCollectWeeksForDropdown(collectWeeks)}
                        validator={validator}
                        required
                        error={!collectWeekEndHasValue(collectWeekEnd)}
                    />
                </Grid>
            </>
        ) : null;
    };

    return (
        <>
            <ADialogContent size={'xl'} isLoading={isLoading}>
                {salesTypeAnnotations.length > 0 ? (
                    <Annotation Annotations={salesTypeAnnotations} />
                ) : null}
                <Grid container direction='row'>
                    <Grid
                        item
                        xs={announcementNumberGridSize.Xs}
                        sm={announcementNumberGridSize.Sm}
                        md={announcementNumberGridSize.Md}
                        lg={announcementNumberGridSize.Lg}
                    >
                        <ATextInput
                            id={Strings.AnnouncementNumber}
                            label={t('AnelmaBovine:1028')}
                            onChange={(v) => null} // changed from Tiltu only, we cannot change the value
                            validator={validator}
                            disabled
                            value={
                                announcementData && announcementData?.Number > 0
                                    ? `${announcementData.Number}`
                                    : ''
                            }
                        />
                    </Grid>

                    {renderHoldingSiteComponent()}
                    {renderCollectWeekSelectionInputs()}
                    {boundariesLoaded && (
                        <Grid item xs={12} sm={12} md={10} lg={8}>
                            <ATextInput
                                id={Strings.AnnouncementAdditionalInformation}
                                label={t('AnelmaBovine:1039')}
                                onChange={(v) => (announcementData.Info = v)}
                                value={announcementData.Info}
                                validator={validator}
                                maxLength={addInfoMaxLength}
                                minLength={addInfoMinLength}
                                disabled={props.readOnly && props.readOnly === true}
                            />
                        </Grid>
                    )}
                    {/* 
                    <Grid>
                        <ADefaultButton
                            onClick={() => setAddDialogVisibility(true)}
                            icon={<EditIcon />}
                        >
                            Liiteet
                        </ADefaultButton>
                    </Grid> */}
                    {/* 
                    <Grid>
                        <AImageList
                            files={preview.file}
                            cols={5}
                            gap={10}
                            imgMaxHeight={50}
                            imgMaxWidth={50}
                        ></AImageList>
                    </Grid> */}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ paddingLeft: '20px', paddingRight: '20px' }}
                >
                    <AnimalAnnouncementDataGrid
                        announcementType={announcementType}
                        mode={props.mode}
                        initiallySelectedAnimals={getAnnouncementAnimals(announcementData)}
                        animalAnnouncementData={announcementData}
                        allAnimals={props.animals}
                        allowedSalesTypes={allowedSalesTypes}
                        editSalesType={(animalId: string) => {
                            const animal = props.animals?.find((x) => x.Id === animalId);
                            if (!animal) return;
                            setAnimalsForDialog([animal]);
                            setSalesTypeDialogVisible(() => true);
                        }}
                        handleSelectedAnimals={(animalData: IAnimalSummary[]) =>
                            setSelectedAnimals(animalData)
                        }
                        handleAnimalRemoveFromAnnouncement={removeAnimalFromAnnouncement}
                        loading={props.loading}
                        readOnly={readOnlyMode || !holdingSiteIsValid}
                    />
                </Grid>
            </ADialogContent>

            <ADialogActions buttons={dialogActionButtons} />
            <AdditionalDialogActionButtons
                announcementType={announcementType}
                mode={props.mode}
                handleSalesTypeDialogVisibility={(value) => setSalesTypeDialogVisible(value)}
                handleCollectWeekDialogVisibility={(value) => setCollectWeekDialogVisible(value)}
                readOnly={
                    readOnlyMode ||
                    !holdingSiteIsValid ||
                    !animalAnnouncementCreationViaMmmfiIsValid(canCreateAnimalAnnouncementViaMmfi)
                }
                isLoading={isLoading}
            />
            {addAnimalDialogVisible && (
                <AddAnimalDialog
                    onClose={() => setAddAnimalDialogVisible(false)}
                    animals={omitAnimalAnnouncementAnimalsFromSelectableAnimals(
                        announcementData,
                        props.animals || []
                    )}
                    fromDialog={SecondaryDialogTypeEnum.SlaughterOrIntermediationAnnouncement}
                    handleAnimalDataChange={handleAnimalDataChange}
                    holdingSite={holdingSiteId}
                />
            )}
            {/* {addDialogVisibility && (
                <Grid>
                    <DocumentAddDialog
                        config={'images'}
                        data={defaultData.documentDetails()}
                        initialType={null}
                        onClose={() => setAddDialogVisibility(false)}
                        onSave={filesToSaveHandler}
                        validator={new FormValidator()}
                    />
                </Grid>
            )} */}
            {chainInformationDialogVisible && announcementData && (
                <ChainInformationDialog
                    onClose={(cancelled) => {
                        setChainInformationDialogVisible(false);

                        // chain info, in create-mode, is only opened as part of the post-save. close regardless of cancellation because this dialog is in an invalid state after
                        // saving, and incapable of actually committing changes.
                        if (props.mode === DialogModeEnum.Create) props.onClose();
                        else if (!cancelled) props.onClose();
                    }}
                    announcementData={announcementData}
                    announcementId={announcementData.Id}
                    announcementNumber={String(announcementData.Number)}
                    animals={getAnnouncementAnimals(announcementData)}
                    forceReadOnly={readOnlyMode}
                    newAnnouncement={props.mode === DialogModeEnum.Create}
                ></ChainInformationDialog>
            )}
            {salesTypeDialogVisible && <ModifySalesTypeDialog {...salesTypeDialogProps} />}
            {collectWeekDialogVisible && (
                <ModifyCollectWeekDialog {...modifyCollectWeekDialogProps} />
            )}
            {chainInfoConfirmDialogVisible && (
                <MidSaveConfirmDialog
                    text={t('AnelmaAnimalAnnouncement:1008')}
                    setVisible={setChainInfoConfirmDialogVisible}
                    onConfirm={() => handleSave(true)}
                />
            )}
        </>
    );
}
