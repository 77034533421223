// Store
import { useAppSelector } from '../../../../store/hooks';

// Common
import UserAvatar from '../../../../common/UserAvatar';

// Feature
import './openUserMenu.scss';

export interface OpenUserMenuProps {
    mobile: boolean;
    openUserMenu: () => void;
}

export default function OpenUserMenu(props: OpenUserMenuProps) {
    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const userData = useAppSelector((state) => state.userData);

    const getName = () => {
        return userData.data?.FirstName || loginStatus.name;
    };

    return (
        <div className='an-user-menu-open' onClick={() => props.openUserMenu()}>
            {userData.status === 'ready' && (
                <div className='an-user-menu-avatar'>
                    <UserAvatar personId={userData.data?.Id} />
                    {props.mobile ? '' : getName()}
                </div>
            )}
        </div>
    );
}
