// Libraries
import { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// Core
import { ResourceTextApplication } from '../core/resources';
import { baseUrl } from '../core/constants';

// Common
import useStickyState from '../common/utils/useStickyState';
import { ADefaultButton } from '../common/buttons';

// Store
import { loadLoginStatusAsync } from '../store/loginStatusSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';

interface SessionCountDown {
    logout: number;
}

export function SessionNotifier() {
    const loginStatus = useAppSelector((state) => state.loginStatus);
    const configuration = useAppSelector((state) => state.configuration.data);
    const notificationShowsLimit = configuration.sessionNotification.countDownStartInMinutes;
    const debug: boolean = false;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const dispatch = useAppDispatch();
    const loadLoginStatusData = useCallback(() => dispatch(loadLoginStatusAsync()), [
        dispatch,
        loadLoginStatusAsync,
    ]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snackbarKey = 'sessionNotify';
    const notificationLimit = (notificationShowsLimit || 15) * 60 * 1000;

    const timeoutRef = useRef<NodeJS.Timeout>();
    const [initialized, setInitialized] = useState<boolean>(false);

    const localStorageKey = 'an-session-notifier--updated';
    const [sessionNotifierUpdated, setSessionNotifierUpdated] = useStickyState<string | null>(
        null,
        localStorageKey
    );
    const [sessionCountDown, setSessionCountDown] = useState<SessionCountDown | null>(null);

    const loginDataExists = (): boolean => {
        return (
            loginStatus.status === 'ready' &&
            !!loginStatus.data.loginSessionAbsoluteExpiration &&
            !!loginStatus.data.loginSessionExpiration
        );
    };

    const canRefresh = (): boolean => {
        if (!loginDataExists()) return false;
        const end = new Date(loginStatus.data.loginSessionExpiration + 'Z').getTime();
        const absolute = new Date(loginStatus.data.loginSessionAbsoluteExpiration + 'Z').getTime();
        return absolute > end;
    };

    const extendSession = () => {
        loadLoginStatusData();
        setSessionCountDown(null);
        closeSnackbar(snackbarKey);
    };

    const tick = (): void => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = undefined;
            updateCountDown('tick');
            tick();
        }, 1000);
    };

    const restartTick = (): void => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        tick();
    };

    const updateCountDown = (from: string) => {
        if (loginDataExists()) {
            const sessionExpiration = loginStatus.data.loginSessionExpiration;
            const sessionAbsoluteExpiration = loginStatus.data.loginSessionAbsoluteExpiration;
            // 'Z' at the end makes datetime string to parsed as utc time
            const expiration = new Date(sessionExpiration + 'Z');
            const absoluteExpiration = new Date(sessionAbsoluteExpiration + 'Z');
            const now = new Date().getTime();
            const logoutTime = Math.min(expiration.getTime(), absoluteExpiration.getTime());
            
            const logoutDiff = logoutTime - now;
            const logoutCountDown = isNaN(logoutDiff) ? 0 : logoutDiff;

            logDebug('COUNT DOWN UPDATED', from);
            setSessionCountDown({
                logout: logoutCountDown
            });
        } else {
            setSessionCountDown(null);
        }
    };

    const adjustDate = (date: Date): Date => {
        date.setSeconds(date.getSeconds() - 15);
        date.setSeconds(0);
        return date;
    };

    const logDebug = (msg: string, ...args: any[]) => {
        if (debug) console.log(`SN:: ${msg}`, ...args);
    };

    useEffect(() => {
        window.addEventListener('storage', (event: StorageEvent) => {
            if (event.key !== localStorageKey) return;
            logDebug('SESSION EXTENDED FROM ANOTHER TAB');
            extendSession();
        });
    }, []);

    useEffect(() => {
        if (sessionNotifierUpdated === loginStatus.data.loginSessionExpiration) return;
        logDebug('SESSION EXPIRATION UPDATED', loginStatus.data.loginSessionExpiration);
        setSessionNotifierUpdated(loginStatus.data.loginSessionExpiration);
    }, [loginStatus]);

    useEffect(() => {
        if (!loginDataExists()) return;
        if (!initialized) setInitialized(true);
        updateCountDown('login status update');
        restartTick();
    }, [initialized, loginStatus]);

    useEffect(() => {
        if (initialized) {
            logDebug('START TICKING');
            tick();
        }
    }, [initialized]);

    useEffect(() => {
        if (!loginDataExists()) {
            logDebug('NO LOGIN DATA');
            closeSnackbar(snackbarKey);
            return;
        }

        if (sessionCountDown === null) {
            logDebug('NO SESSION COUNT DOWN');
            return;
        }

        if (sessionCountDown.logout <= 0) {
            logDebug('AUTO LOGOUT');
            window.location.href = `${baseUrl}/logout`;
        } else if (sessionCountDown.logout < notificationLimit) {
            logDebug('SHOW NOTIFICATION', canRefresh() ? 'can refresh' : 'can not refresh');
            let sessionEnd = adjustDate(
                new Date(
                    `${
                        canRefresh()
                            ? loginStatus.data.loginSessionExpiration
                            : loginStatus.data.loginSessionAbsoluteExpiration
                    }Z`
                )
            );
            enqueueSnackbar(
                t('AnelmaGeneral:1069').replace('{time}', sessionEnd.toLocaleTimeString()),
                {
                    action: canRefresh() ? (
                        <ADefaultButton onClick={() => extendSession()}>
                            {t('AnelmaGeneral:1070')}
                        </ADefaultButton>
                    ) : undefined,
                    key: snackbarKey,
                    persist: true,
                    preventDuplicate: true,
                    variant: 'warning',
                }
            );
        } else {
            logDebug('HIDE NOTIFICATION');
            closeSnackbar(snackbarKey);
        }
    }, [sessionCountDown]);

    if (debug)
        return (
            <div>
                {!loginDataExists() ? (
                    <p>{loginStatus.data.loggedIn ? 'Refreshing session' : 'User not logged in'}</p>
                ) : (
                    <>
                        <p>
                            Absolute expiration: {loginStatus.data.loginSessionAbsoluteExpiration}{' '}
                            UTC
                        </p>
                        <p>Session Expiration: {loginStatus.data.loginSessionExpiration} UTC</p>
                        <p>
                            {sessionCountDown !== null
                                ? `Notified in ${Math.round(
                                      (sessionCountDown.logout - notificationLimit) / 1000
                                  )} seconds`
                                : ''}
                        </p>
                        <p>
                            {sessionCountDown
                                ? `Logged out in ${Math.round(
                                      sessionCountDown.logout / 1000
                                  )} seconds`
                                : ''}
                        </p>
                    </>
                )}
            </div>
        );
    return <></>;
}
