// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { PriorityHigh } from '@mui/icons-material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AIconButtonBaseProps, ADefaultIconButton } from '../buttons';

export interface APriorityHighIconButtonProps extends AIconButtonBaseProps {}

export default function APriorityHighIconButton(props: APriorityHighIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1075');

    return (
        <ADefaultIconButton
            {...buttonProps}
            icon={<PriorityHigh style={{ color: '#d32f2f' }} />}
            tooltip={tooltip2}
        />
    );
}
