// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import { IFormValidator } from '../core/FormValidator';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogContent,
    ADialogSubHeading,
    ADialogTitle,
    ADialogButtonsProp,
} from '../common/dialog';
import { ASwitch, ATextarea, ATextInput } from '../common/inputs';

// Feature
import { PublicContactPerson, ParamState } from './interfaces';
import { createSnellmanPerson, updateSnellmanPerson } from './publicContactPersonsApi';
import { reverseName } from './helpers';

export interface EditAddSnellmanPersonProps {
    afterAdd: (data: PublicContactPerson) => void;
    afterEdit: (data: PublicContactPerson) => void;
    data: PublicContactPerson;
    onClose: () => void;
    mode: 'create' | 'edit';
    responsibilities: ParamState[];
    validator: IFormValidator;
}

export default function EditAddSnellmanPersonForm(props: EditAddSnellmanPersonProps) {
    const { data, validator } = props;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaPerson']);
    const { enqueueSnackbar } = useSnackbar();
    const showMessage = (message: string, variant: 'success' | 'error') => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contactPerson, setContactPerson] = useState<PublicContactPerson>(data);

    const onSave = () => {
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }

        setIsLoading(true);

        const newData: PublicContactPerson = {
            ...contactPerson,
            AboutMeEN: contactPerson.AboutMeEN,
            AboutMeFI: contactPerson.AboutMeFI,
            AboutMeSE: contactPerson.AboutMeSE,
            JobTitleFI: contactPerson.JobTitleFI,
            JobTitleSE: contactPerson.JobTitleSE,
            JobTitleEN: contactPerson.JobTitleEN,
            Responsibilities: contactPerson.Responsibilities,
        };

        const success = (d: PublicContactPerson) => {
            showMessage(t('AnelmaGeneral:1018'), 'success');
            props.onClose();
            props.mode === 'create' ? props.afterAdd(d) : props.afterEdit(d);
        };

        const fail = () => {
            showMessage(t('AnelmaGeneral:1019'), 'error');
            setIsLoading(false);
        };

        if (props.mode === 'create') {
            createSnellmanPerson(newData).then(
                (response) => success(response.data.Entity),
                () => fail()
            );
        } else {
            updateSnellmanPerson(newData.GUID, newData).then(
                (response) => success(response.data.Entity),
                () => fail()
            );
        }
    };

    const toggleResponsibility = (checked: boolean, code: string) => {
        const resp = [...contactPerson.Responsibilities];
        if (checked) resp.push(code);
        else
            resp.splice(
                resp.findIndex((r) => r === code),
                1
            );
        setContactPerson((_) => {
            _.Responsibilities = resp;
            return { ..._ };
        });
    };

    const hasResponsibility = (code: string): boolean => {
        return contactPerson.Responsibilities.includes(code);
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [
            {
                type: 'save',
                onClick: () => onSave(),
            },
        ],
    };

    return (
        <ADialog open={true}>
            <ADialogTitle>{reverseName(data.Name)}</ADialogTitle>

            <ADialogContent isLoading={isLoading} size='md'>
                <Grid container>
                    <ADialogSubHeading>{t('AnelmaPerson:1036')}</ADialogSubHeading>
                    <Grid item sm={12}>
                        <ATextarea
                            id={'contact-person-job-title-fi'}
                            label={t('AnelmaPerson:1048')}
                            validator={validator}
                            onChange={(e) => {
                                setContactPerson((_) => {
                                    _.JobTitleFI = e;
                                    return { ..._ };
                                });
                            }}
                            value={contactPerson.JobTitleFI}
                            maxLength={300}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ATextarea
                            id={'contact-person-job-title-se'}
                            label={t('AnelmaPerson:1049')}
                            validator={validator}
                            onChange={(e) => {
                                setContactPerson((_) => {
                                    _.JobTitleSE = e;
                                    return { ..._ };
                                });
                            }}
                            value={contactPerson.JobTitleSE}
                            maxLength={300}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ATextarea
                            id={'contact-person-job-title-en'}
                            label={t('AnelmaPerson:1050')}
                            validator={validator}
                            onChange={(e) => {
                                setContactPerson((_) => {
                                    _.JobTitleEN = e;
                                    return { ..._ };
                                });
                            }}
                            value={contactPerson.JobTitleEN}
                            maxLength={300}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item sm={6}>
                        <ATextInput
                            id={'phone'}
                            label={t('AnelmaPerson:1007')}
                            validator={validator}
                            disabled
                            onChange={() => {}}
                            value={contactPerson.PhoneNumber}
                        />
                    </Grid>

                    <Grid item sm={6}>
                        <ATextInput
                            validator={validator}
                            id={'email'}
                            label={t('AnelmaPerson:1008')}
                            disabled
                            onChange={() => {}}
                            value={contactPerson.EmailAddress}
                        />
                    </Grid>
                </Grid>

                <ADialogSubHeading>{t('AnelmaPerson:1040')}</ADialogSubHeading>

                <Grid container>
                    {props.responsibilities.map((item, index) => {
                        return (
                            <Grid
                                item
                                xs={6}
                                key={`snellman-contact-person-responsibilites-${index}`}
                            >
                                <ASwitch
                                    id={'contact-person-responsibilites'}
                                    validator={validator}
                                    key={index}
                                    label={item.text}
                                    checked={hasResponsibility(item.code)}
                                    onChange={(checked) => toggleResponsibility(checked, item.code)}
                                ></ASwitch>
                            </Grid>
                        );
                    })}
                </Grid>

                <ADialogSubHeading>{t('AnelmaPerson:1041')}</ADialogSubHeading>

                <Grid container>
                    <Grid item sm={12}>
                        <ATextarea
                            id={'contact-person-about-me-fi'}
                            label={t('AnelmaPerson:1048')}
                            maxLength={300}
                            validator={props.validator}
                            onChange={(e) => {
                                setContactPerson((_) => {
                                    _.AboutMeFI = e;
                                    return { ..._ };
                                });
                            }}
                            value={contactPerson.AboutMeFI}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <ATextarea
                            id={'contact-person-about-me-se'}
                            label={t('AnelmaPerson:1049')}
                            maxLength={300}
                            validator={props.validator}
                            onChange={(e) => {
                                setContactPerson((_) => {
                                    _.AboutMeSE = e;
                                    return { ..._ };
                                });
                            }}
                            value={contactPerson.AboutMeSE}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <ATextarea
                            id={'contact-person-about-me-en'}
                            label={t('AnelmaPerson:1050')}
                            maxLength={300}
                            validator={props.validator}
                            onChange={(e) => {
                                setContactPerson((_) => {
                                    _.AboutMeEN = e;
                                    return { ..._ };
                                });
                            }}
                            value={contactPerson.AboutMeEN}
                        />
                    </Grid>
                </Grid>
            </ADialogContent>

            <ADialogActions buttons={footerButtons} isLoading={isLoading} />
        </ADialog>
    );
}
