// Libraries
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import TrashIcon from '@mui/icons-material/Delete';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AButtonBaseProps, ADangerButton } from '../../common/buttons';

export interface ADeleteButtonProps extends Omit<AButtonBaseProps, 'children'> {
    children?: ReactNode;
}

export default function ADeleteButton(props: ADeleteButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { children, ...buttonProps } = props;
    const children2 = children ? children : t('AnelmaGeneral:1012');

    return <ADangerButton {...buttonProps} children={children2} icon={<TrashIcon />} />;
}
