import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import animalAnnouncementApi from "../../../api/animalAnnouncementApi";
import { AnnouncementTypeEnum } from "../../../interfaces/enums";
import { IAnimalAnnouncement, ICreateAnnouncement, IUpdateBovineAnnouncement } from "../../../interfaces/IAnnouncement";

export class AnnouncementDataService {

    // /**
    //  *
    //  */
    // constructor(
    //     public announcement: IAnimalAnnouncement
    // ) {
        
    // }

    public createAnnouncementCall = (payload: ICreateAnnouncement, type: AnnouncementTypeEnum) => {
        if (type === AnnouncementTypeEnum.Slaughter || type === AnnouncementTypeEnum.ManualBovineSlaughter)
            return animalAnnouncementApi.createBovineSlaughterAnnouncement(payload);
        else
            return animalAnnouncementApi.createBovineIntermediationAnnouncement(payload);
    }

    public updateAnnouncementCall = (payload: IUpdateBovineAnnouncement, type: AnnouncementTypeEnum) => {
        if (type === AnnouncementTypeEnum.Slaughter || type === AnnouncementTypeEnum.ManualBovineSlaughter)
            return animalAnnouncementApi.updateBovineSlaughterAnnouncement(payload);
        else
            return animalAnnouncementApi.updateBovineIntermediationAnnouncement(payload);
    }
}

export const announcementDataService = new AnnouncementDataService();