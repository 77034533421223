// Libraries
import { useEffect, useState, ReactNode } from 'react';

// MUI
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// Core
import { paramRepository, ResourceTextApplication, ParameterSorting } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import AInputContainer from './AInputContainer';
import ALabel from './ALabel';

interface ADropdownBaseProps {
    error?: boolean;
    id: string;
    label?: React.ReactNode;
    onChange: (value: ADropdownValue) => void;
    required?: boolean;
    style?: React.CSSProperties;
    validator: IFormValidator;
    value: ADropdownValue;
    withoutContainer?: boolean;
    disabled?: boolean;
}

interface ADropdownWithItemsProps extends ADropdownBaseProps {
    items: ADropdownItemProps[];
}

interface ADropdownWithParamProps extends ADropdownBaseProps {
    parameterName: string;
    sortValues?: ParameterSorting;
}

export interface ADropdownItemProps {
    text: string;
    value: SelectValue;
    disabled?: boolean;
}

type SelectValue = number | string;
export type ADropdownValue = SelectValue | null;
export type ADropdownProps = ADropdownWithItemsProps | ADropdownWithParamProps;

export default function ADropdown(props: ADropdownProps) {
    const { id, error, parameterName, required, sortValues, validator, disabled } =
        props as ADropdownWithParamProps;
    const formatValue = (value: ADropdownValue): SelectValue => {
        return value === null ? '' : value;
    };
    const initialItems: ADropdownItemProps[] =
        parameterName && props.value !== null
            ? [
                  {
                      text: '',
                      value: props.value,
                  },
              ]
            : [];

    const [items, setItems] = useState<ADropdownItemProps[]>(
        (props as ADropdownWithItemsProps).items || initialItems
    );
    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<ADropdownValue>(formatValue(props.value));
    const [err, setErr] = useState<boolean>(false);

    useEffect(() => {
        if (!parameterName) return;
        const [groupName, paramName] = parameterName.split(':', 2);
        const group = groupName as ResourceTextApplication;
        paramRepository.load([group]).then(() => {
            const params = paramRepository.resource(group, paramName, sortValues);
            setItems(params.map((p) => ({ text: p.text, value: p.code })));
            if (!params.some((a) => a.code == props.value)) {
                setVal(params[0].code);
                props.onChange(params[0].code);
            }
        });
    }, [parameterName]);

    useEffect(() => {
        setVal(formatValue(props.value));
    }, [props]);

    useEffect(() => {
        setItems((props as ADropdownWithItemsProps).items ?? initialItems);
    }, [(props as ADropdownWithItemsProps).items]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (required && (val === null || val === '')) return false;
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setErr(!isValidAfterTouch());
    }, [error, id, required, touched, val, validator]);

    const formControlStyle: React.CSSProperties = {};
    if (!props.withoutContainer) formControlStyle.width = '100%';

    const aLabel = (text: ReactNode) => (
        <ALabel error={err} required={required}>
            {text}
        </ALabel>
    );

    const control = (
        <FormControl style={formControlStyle} error={err || props.error} variant='standard'>
            {props.label ? aLabel(props.label) : aLabel(<span>&nbsp;</span>)}
            <Select
                data-robot-id={'select-' + props.id}
                fullWidth={!props.withoutContainer}
                onChange={(e) => {
                    const value = e.target.value as ADropdownValue;
                    setTouched(true);
                    setVal(value);
                    props.onChange(value);
                }}
                style={props.style}
                value={val}
                disabled={disabled}
                variant='standard'
                label={props.label}
            >
                {items.map((i, index) => {
                    const { text, value, disabled } = i;
                    return (
                        <MenuItem
                            data-robot-id={'menuitem-' + index}
                            key={index}
                            value={value}
                            disabled={disabled}
                        >
                            {text}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );

    return props.withoutContainer ? control : <AInputContainer>{control}</AInputContainer>;
}
