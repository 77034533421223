import moment from 'moment';

export const parseDateTime = (
    fromDateTime: moment.Moment | null,
    toDateTime: moment.Moment | null
) => {
    if (fromDateTime !== null && toDateTime !== null) {
        return moment(`${fromDateTime.format('YYYY-MM-DD')} ${toDateTime.format('HH:mm:00')}`);
    }
    return null;
};

export const getDateString = (reqDateTime: moment.Moment | null): string => {
    return reqDateTime?.isValid() ? reqDateTime.utc().format('YYYY-MM-DD HH:mm:00') : '';
};
