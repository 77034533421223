// Libraries
import Axios, { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// Core
import { ResourceTextApplication } from '../core/resources';

// Interfaces
import { IApiResult, IApiListResult, IApiResultMessage } from '../interfaces/IGeneral';

export default function ApiResults() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaSolmuErrors']);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const showMessage = (result: IApiResultMessage, level: 'error' | 'warning') => {
            let text = t(`AnelmaSolmuErrors:${result.Code}`);
            if (text === `${result.Code}`) text = result.Explanation || t(`AnelmaSolmuErrors:9999`);
            enqueueSnackbar(text, {
                variant: level,
            });
        };

        const handleResults = (data: IApiResult<unknown> | IApiListResult<unknown>): void => {
            const warnings = data?.Results?.Warnings || [];
            const errors = data?.Results?.Errors || [];
            errors.forEach((error) => showMessage(error, 'error'));
            warnings.forEach((warning) => showMessage(warning, 'warning'));
        };

        Axios.interceptors.response.use(
            (response: AxiosResponse<IApiResult<unknown> | IApiListResult<unknown>>) => {
                handleResults(response.data);
                return response;
            },
            (error) => {
                if (error.config?.throwValidationErrors !== true) {
                    handleResults(error?.response?.data);
                }
                return Promise.reject(error);
            }
        );
    }, []);

    return <></>;
}
