// Libraries
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

//MUI
import { Container, FormControlLabel, FormGroup, Grid, Switch, useMediaQuery } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import { paramRepository } from '../core/resources';
import FormValidator from '../core/FormValidator';
import auth from '../core/authorization';

// Common
import { AAddButton } from '../common/buttons';
import ViewLoader from '../common/ViewLoader';
import { ViewTitle } from '../common/typography';
import { ASwitch } from '../common/inputs';

// Interfaces
import { IContactPerson } from '../interfaces/IBusinessEntities';

// Store
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadProfilePictureAsync } from '../store/profilePictureSlice';

// Feature
import ContactPersonBadge from './ContactPersonBadge';
import ContactPersonCard from './ContactPersonCard';
import EditAddSnellmanPersonForm from './ContactPersonEditDialog';

import DeleteContactPersonDialog from './DeleteContactPersonDialog';
import SnellmanContactPersonSearch from './searchContactPerson/ContactPersonSearch';
import { PublicContactPerson, ParamState } from './interfaces';

// API
import { getContactPersons } from './publicContactPersonsApi';

export default function PublicContactPersons() {
    const configuration = useAppSelector((state) => state.configuration);
    const loginStatus = useAppSelector((state) => state.loginStatus);
    const { enqueueSnackbar } = useSnackbar();

    const [contactPersons, setContactPersons] = useState<PublicContactPerson[]>([]);
    const [responsibilities, setResponsibilitiesParam] = useState<ParamState[]>([]);
    const [responsibilitySwitch, setResponsibilitySwitch] = useState<string[]>([]);
    const [contactPersonForEdit, setContactPersonForEdit] = useState<PublicContactPerson | null>(
        null
    );
    const [personSearchVisible, setPersonSearchVisible] = useState<boolean>(false);
    const [searchContactPersons, setSearchContactPersons] = useState<PublicContactPerson[]>([]);
    const [formMode, setFormMode] = useState<'create' | 'edit'>('edit');
    const [deletePersonGUID, setDeletePersonGUID] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaPerson']);

    const dispatch = useAppDispatch();
    const loadProfilePicture = useCallback(
        (id: string) => dispatch(loadProfilePictureAsync(id)),
        [dispatch]
    );

    useEffect(() => {
        var loggedIn = !!loginStatus.data.loggedIn;
        const personsPromise = new Promise<void>((resolve) => {
            getContactPersons(!!loggedIn).then(
                (data) => {
                    //this state will have main list for mapping and searching
                    setSearchContactPersons(data.Items);
                    //this state is for after search filter is clear; assign back to main list setSearchContactPersons
                    setContactPersons(data.Items);

                    const promises = data.Items.map((i) => loadProfilePicture(i.PersonGUID));
                    Promise.all(promises).then(() => resolve());
                },
                //No data to load -fail safe- load empty
                () => {
                    setContactPersons([]);
                    enqueueSnackbar(t('AnelmaGeneral:1020'), {
                        variant: 'error',
                    });
                }
            );
        });

        //Loading responsibilites mapping values from resource
        const paramPromise = paramRepository.load(['AnelmaPerson']).then(() => {
            setResponsibilitiesParam(
                paramRepository.resource('AnelmaPerson', 'Responsibility').map((resourceParam) => {
                    return {
                        type: false,
                        ...resourceParam,
                    };
                })
            );
        });

        Promise.all([personsPromise, paramPromise]).then(() => setIsLoading(false));
    }, []);

    const getUserData = (id: string) => {
        return contactPersons.find((i) => i.GUID === id) || null;
    };

    const onSelectHandler = (summary: IContactPerson): void => {
        const person: PublicContactPerson = {
            AboutMeEN: '',
            AboutMeFI: '',
            AboutMeSE: '',
            CompanyGUID: summary.PersonId,
            EmailAddress: summary.EmailAddress,
            //db generated new GUID: here setting to something to avoid exception
            GUID: summary.PersonId,
            JobTitleFI: '',
            JobTitleEN: '',
            JobTitleSE: '',
            Name: summary.Name,
            //for now set on BE
            PersonGUID: summary.PersonId,
            PhoneNumber: summary.PhoneNumber,
            PictureUrl: '',
            Responsibilities: [],
        };
        setContactPersonForEdit(person);
        setFormMode('create');
        setPersonSearchVisible(false);
    };

    const xsLayout = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

    useEffect(() => {
        //filter based on tags selected
        var filtered = contactPersons.filter((person) =>
            matchesResponsiblity(person, responsibilitySwitch)
        );
        setSearchContactPersons(filtered);
        if (filtered.length <= 0) {
            setSearchContactPersons(contactPersons);
        }
    }, [responsibilitySwitch]);

    const matchesResponsiblity = (
        contactPerson: PublicContactPerson,
        filterWords: string[]
    ): boolean => {
        const dutyText = contactPerson.Responsibilities.map(
            (duty) => responsibilities.find((i) => i.code === duty)?.text
        );
        var found = false;
        dutyText.forEach((element) => {
            if (!found && filterWords.includes(element as string)) {
                found = true;
            }
        });
        return found;
    };

    const handleFilterWords = (tags: string, checked: boolean) => {
        const tagWord = [...responsibilitySwitch];
        if (checked) tagWord.push(tags);
        else {
            const index = tagWord.findIndex((i) => i === tags);
            tagWord.splice(index, 1);
        }
        setResponsibilitySwitch(tagWord);
    };

    return (
        <Container data-robot-id={'app-body-public-contact-person'}>
            <ViewTitle>{t('AnelmaLayout:1014')}</ViewTitle>
            <Grid container>
                {responsibilities.map((item) => (
                    <Grid item xs={6} sm={6} md={4} lg={4} key={item.code}>
                        <FormGroup sx={{ paddingLeft: '40px', paddingRight: '40px' }}>
                            <ASwitch
                                id={`reason-${item.code}`}
                                label={item.text}
                                checked={responsibilitySwitch.includes(item.code)}
                                onChange={(v) => handleFilterWords(item.text, v)}
                                withoutContainer
                            />
                        </FormGroup>
                    </Grid>
                ))}
            </Grid>

            {!isLoading ? (
                <>
                    {auth.canCreateContactPerson && (
                        <Grid container spacing={0}>
                            <Grid item xs={12} style={{ marginBottom: '20px', textAlign: 'right' }}>
                                <AAddButton
                                    onClick={() => setPersonSearchVisible(true)}
                                    type='action'
                                />
                            </Grid>
                        </Grid>
                    )}

                    <Container>
                        <Grid container>
                            {searchContactPersons.map((contactPerson, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                    {xsLayout ? (
                                        <ContactPersonBadge
                                            data={contactPerson}
                                            responsibilities={responsibilities}
                                        />
                                    ) : (
                                        <ContactPersonCard
                                            culture={
                                                configuration.data.culture.supportedCultures.find(
                                                    (c) => c.selected
                                                )?.value
                                            }
                                            data={contactPerson}
                                            onRemove={() => setDeletePersonGUID(contactPerson.GUID)}
                                            openEdit={() =>
                                                setContactPersonForEdit(
                                                    getUserData(contactPerson.GUID)
                                                )
                                            }
                                            responsibilities={responsibilities}
                                        />
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </>
            ) : (
                <ViewLoader />
            )}

            {personSearchVisible && (
                <SnellmanContactPersonSearch
                    onClose={() => setPersonSearchVisible((value) => !personSearchVisible)}
                    onSelect={onSelectHandler}
                />
            )}

            {deletePersonGUID && (
                <DeleteContactPersonDialog
                    afterDelete={(guid) => {
                        const persons = [...contactPersons];
                        const index = persons.findIndex((p) => p.GUID === guid);
                        persons.splice(index, 1);
                        setContactPersons(persons);
                        setSearchContactPersons(persons);
                    }}
                    id={deletePersonGUID}
                    onClose={() => setDeletePersonGUID(null)}
                />
            )}

            {contactPersonForEdit && (
                <EditAddSnellmanPersonForm
                    afterAdd={() => {
                        setIsLoading(true);
                        getContactPersons(!!loginStatus.data.loggedIn).then((data) => {
                            setContactPersons(data.Items);
                            setSearchContactPersons(data.Items);
                            setIsLoading(false);
                        });
                    }}
                    afterEdit={(p) => {
                        const persons = [...contactPersons];
                        const index = persons.findIndex((p2) => p2.GUID === p.GUID);
                        if (index !== -1) persons[index] = p;
                        setContactPersons(persons);
                        setSearchContactPersons(persons);
                    }}
                    data={contactPersonForEdit}
                    mode={formMode}
                    onClose={() => {
                        setContactPersonForEdit(null);
                        setFormMode('edit');
                    }}
                    responsibilities={responsibilities}
                    validator={new FormValidator()}
                />
            )}
        </Container>
    );
}
