// Api
import ApiBase from './ApiBase';
import { publicApiUrl } from './apiEndpoints';

// Feature
import {
    IEditableContent,
    EditableContentType,
    IPlainContent,
} from '../editableContent/IEditableContent';

class ContentApi extends ApiBase {
    private get rootUrl(): string {
        return `${this.baseUrl}/api/gateway/anelma/content`;
    }

    createContent(content: IEditableContent) {
        return this.post<IEditableContent>(`${this.rootUrl}`, content);
    }

    getContentById(publicSite: boolean, id: string) {
        const url = publicSite ? `${publicApiUrl}/content` : this.rootUrl;
        return this.get<IPlainContent>(`${url}/${id}`);
    }

    getContentByIdForEdit(id: string) {
        const url = this.rootUrl;
        return this.get<IEditableContent>(`${url}/for-edit/${id}`);
    }

    getContentByType(publicSite: boolean, type: EditableContentType) {
        const url = publicSite ? `${publicApiUrl}/content` : this.rootUrl;
        return this.get<IPlainContent>(`${url}/type/${type}`);
    }

    getContentListByType(type: EditableContentType) {
        return this.getList<IEditableContent>(`${this.rootUrl}/list/${type}`);
    }

    updateContent(content: IEditableContent) {
        return this.put<IEditableContent>(`${this.rootUrl}/${content.Id}`, content);
    }
}

export default new ContentApi();
