import { AnimalTypeEnum } from '../../interfaces/enums';
import { IProductionLine } from '../../interfaces/IBusinessEntities';
import { SearchType } from '../../interfaces/ISearchParameters';
import { IParameterExtension } from '../shared/styles/types/subTypes';

export function SearchTypeFromIntToString(
    type: number,
    dictionary: IParameterExtension[],
    personDictionary: IParameterExtension[]
) {
    switch (type) {
        case 1:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '3')?.text ?? ''
            );
        case 2:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '2')?.text ?? ''
            );
        case 3:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '0')?.text ?? ''
            );
        case 4:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '1')?.text ?? ''
            );
        case 5:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '5')?.text ?? ''
            );
        case 6:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '4')?.text ?? ''
            );
        case 7:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '6')?.text ?? ''
            );
        case 8:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '7')?.text ?? ''
            );
        case 9:
            return (
                dictionary
                    .find((s) => s.name === 'CommonSearchCriteria')
                    ?.options.find((o) => o.code === '8')?.text ?? ''
            );
        case 101:
            return (
                personDictionary
                    .find((s) => s.name === 'PersonSearchCriteria')
                    ?.options.find((o) => o.code === '1')?.text ?? ''
            );
        case 102:
            return (
                personDictionary
                    .find((s) => s.name === 'PersonSearchCriteria')
                    ?.options.find((o) => o.code === '2')?.text ?? ''
            );
        case 103:
            return (
                personDictionary
                    .find((s) => s.name === 'PersonSearchCriteria')
                    ?.options.find((o) => o.code === '3')?.text ?? ''
            );
        default:
            return 'Unknown';
    }
}

export function GetProductionLineFromIntList(
    values: IProductionLine[],
    dictionary: IParameterExtension[]
) {
    let productionLineNames: string[] = [];

    values.forEach((line) => {
        const paramToUse = dictionary.find(
            (f) =>
                f.name ===
                (line.Type === AnimalTypeEnum.Bovine ? 'FarmingTypeBovine' : 'FarmingTypePig')
        );
        const params = paramToUse?.options?.filter((p) =>
            line.FarmingTypes.find((f) => p.code === '0' + f)
        );
        params?.forEach((e) => {
            productionLineNames.push(e.text);
        });
    });

    return productionLineNames;
}

export function GetSpeciesFronIntList(values: number[], dictionary: IParameterExtension[]) {
    return values.map(
        (v) =>
            dictionary.find((r) => r.name === 'Species')?.options.find((o) => o.code === '0' + v)
                ?.text ?? ''
    );
}

export function GetValueToShow(
    type: SearchType,
    values: string[],
    dictionary: IParameterExtension[],
    personDictionary: IParameterExtension[]
) {
    const countyFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Regions')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const municipalityFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Municipality')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const returnExpiredOnesFromBoolean = (values: string[]) => {
        return values.map((v) => {
            if (v === 'false')
                return (
                    dictionary
                        .find((r) => r.name === 'FarmStatus')
                        ?.options.find((o) => o.code === '0')?.text ?? ''
                );
            return (
                dictionary.find((r) => r.name === 'FarmStatus')?.options.find((o) => o.code === '1')
                    ?.text ?? ''
            );
        });
    };

    const languageFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Language')?.options.find((o) => o.text === v)
                    ?.text ?? ''
            );
        });
    };

    const speciesFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary
                    .find((r) => r.name === 'Species')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    const feedSupplierFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'FeedSupplier')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const dairyFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Dairy')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const farmingTypeBovineFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary
                    .find((r) => r.name === 'FarmingTypeBovine')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    const personLanguageFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'Language')
                    ?.options.find((o) => o.text === v)?.text ?? ''
            );
        });
    };

    const personTitleFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary.find((r) => r.name === 'Titles')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const personPhoneNumberTypeFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'PhoneNumberTypePerson')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.text ?? ''
            );
        });
    };

    switch (type) {
        case SearchType.County:
            return countyFromValue(values);
        case SearchType.Municipality:
            return municipalityFromValue(values);
        case SearchType.FeedSupplier:
            return feedSupplierFromValue(values);
        case SearchType.Dairy:
            return dairyFromValue(values);
        case SearchType.ReturnExpiredOnes:
            return returnExpiredOnesFromBoolean(values);
        case SearchType.Language:
            return languageFromValue(values);
        case SearchType.Species:
            return speciesFromValue(values);
        case SearchType.FarmingTypeGeneric:
            return farmingTypeBovineFromValue(values);
        case SearchType.PersonLanguage:
            return personLanguageFromValue(values);
        case SearchType.PersonTitle:
            return personTitleFromValue(values);
        case SearchType.PersonPhoneNumberType:
            return personPhoneNumberTypeFromValue(values);
        default:
            return [];
    }
}

export function GetValueForForm(
    type: SearchType,
    values: string[],
    dictionary: IParameterExtension[],
    personDictionary: IParameterExtension[]
) {
    const countyFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Regions')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const municipalityFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Municipality')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const returnExpiredOnesFromBoolean = (values: string[]) => {
        return values.map((v) => {
            if (v === 'false') return '0';
            return '1';
        });
    };

    const languageFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Language')?.options.find((o) => o.text === v)
                    ?.code ?? ''
            );
        });
    };

    const speciesFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary
                    .find((r) => r.name === 'Species')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.code ?? ''
            );
        });
    };

    const feedSupplierFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'FeedSupplier')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const dairyFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Dairy')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const farmingTypeBovineFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary
                    .find((r) => r.name === 'FarmingTypeBovine')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.code ?? ''
            );
        });
    };

    const personLanguageFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'Language')
                    ?.options.find((o) => o.text === v)?.code ?? ''
            );
        });
    };

    const personTitleFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary.find((r) => r.name === 'Titles')?.options.find((o) => o.code === v)
                    ?.text ?? ''
            );
        });
    };

    const personPhoneNumberTypeFromValue = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'PhoneNumberTypePerson')
                    ?.options.find((o) => parseInt(o.code).toString() === v)?.code ?? ''
            );
        });
    };

    switch (type) {
        case SearchType.County:
            return countyFromValue(values);
        case SearchType.Municipality:
            return municipalityFromValue(values);
        case SearchType.FeedSupplier:
            return feedSupplierFromValue(values);
        case SearchType.Dairy:
            return dairyFromValue(values);
        case SearchType.ReturnExpiredOnes:
            return returnExpiredOnesFromBoolean(values);
        case SearchType.Language:
            return languageFromValue(values);
        case SearchType.Species:
            return speciesFromValue(values);
        case SearchType.FarmingTypeGeneric:
            return farmingTypeBovineFromValue(values);
        case SearchType.PersonLanguage:
            return personLanguageFromValue(values);
        case SearchType.PersonTitle:
            return personTitleFromValue(values);
        case SearchType.PersonPhoneNumberType:
            return personPhoneNumberTypeFromValue(values);
        default:
            return [];
    }
}

export function GetValueToSave(
    type: SearchType,
    values: string[],
    dictionary: IParameterExtension[],
    personDictionary: IParameterExtension[]
) {
    const valueFromCounty = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Regions')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const valueFromMunicipality = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Municipality')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const booleanFromReturnExpiredOnes = (values: string[]) => {
        return values.map((v) => {
            if (v === '0') return 'false';
            return 'true';
        });
    };

    const valueFromLanguage = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Language')?.options.find((o) => o.code === v)
                    ?.text ?? '0'
            );
        });
    };

    const valueFromSpecies = (values: string[]) => {
        return values.map((v) => {
            return parseInt(v).toString();
        });
    };

    const valueFromFeedSupplier = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'FeedSupplier')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const valueFromDairy = (values: string[]) => {
        return values.map((v) => {
            return (
                dictionary.find((r) => r.name === 'Dairy')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const farmingTypeBovineFromValue = (values: string[]) => {
        return values.map((v) => {
            return parseInt(v).toString();
        });
    };

    const valueFromPersonLanguage = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary
                    .find((r) => r.name === 'Language')
                    ?.options.find((o) => o.code === v)?.text ?? '0'
            );
        });
    };

    const valueFromPersonTitle = (values: string[]) => {
        return values.map((v) => {
            return (
                personDictionary.find((r) => r.name === 'Titles')?.options.find((o) => o.text === v)
                    ?.code ?? '0'
            );
        });
    };

    const valueFromPersonPhoneNumberType = (values: string[]) => {
        return values.map((v) => {
            return parseInt(v).toString();
        });
    };

    switch (type) {
        case SearchType.County:
            return valueFromCounty(values);
        case SearchType.Municipality:
            return valueFromMunicipality(values);
        case SearchType.FeedSupplier:
            return valueFromFeedSupplier(values);
        case SearchType.Dairy:
            return valueFromDairy(values);
        case SearchType.ReturnExpiredOnes:
            return booleanFromReturnExpiredOnes(values);
        case SearchType.Language:
            return valueFromLanguage(values);
        case SearchType.Species:
            return valueFromSpecies(values);
        case SearchType.FarmingTypeGeneric:
            return farmingTypeBovineFromValue(values);
        case SearchType.PersonLanguage:
            return valueFromPersonLanguage(values);
        case SearchType.PersonTitle:
            return valueFromPersonTitle(values);
        case SearchType.PersonPhoneNumberType:
            return valueFromPersonPhoneNumberType(values);
        default:
            return [];
    }
}
