// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
// Core
import { ParameterValues, paramRepository } from '../../core/resources';
import auth from '../../core/authorization';

// Common
import { gridColumns } from '../../common/dataGrid';
import { TextWithTooltip } from '../../common/typography';

// Interfaces
import { IFarmDetails } from '../../interfaces/IBusinessEntities';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import documentApi from '../../api/documentApi';
import { documentUrl } from '../../api/apiEndpoints';
import companyApi from '../../api/companyApi';

// Feature - Documents
import { IDocumentAddDialogData, DocumentFileTypeEnum } from '../IDocuments';
import DocumentView from './DocumentView';

export default function FarmDocuments() {
    const { t } = useTranslation(['AnelmaLayout', 'AnelmaFarmFiles']);

    const context = useAppSelector((state) => state.context);
    const personNames = useAppSelector((state) => state.personNames.data);

    const [loading, setLoading] = useState<boolean>(true);
    const [types, setTypes] = useState<ParameterValues>([]);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails | null>(null);

    useEffect(() => {
        paramRepository.load(['AnelmaFarmFiles']).then(() => {
            setTypes(paramRepository.resource('AnelmaFarmFiles', 'FarmFileSubject'));
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (context?.data?.currentContext) {
            setLoading(true);
            companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    if (response?.Entity) setFarmDetails(response.Entity as IFarmDetails);
                    else setFarmDetails(null);
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setFarmDetails(null);
        }
    }, [context]);

    const onSave = (data: IDocumentAddDialogData) =>
        documentApi.uploadDocument(data, DocumentFileTypeEnum.FarmDocument);

    return (
        farmDetails && (
            <DocumentView
                additionalColumns={[
                    gridColumns.dateTime(
                        'ValidFrom',
                        t('AnelmaFarmFiles:1006'),
                        'DD.MM.YYYY HH:mm'
                    ),
                    {
                        field: 'Owner',
                        headerName: t('AnelmaFarmFiles:1007'),
                        renderCell: (params) => <TextWithTooltip text={String(params.value)} />,
                        valueGetter: (params) =>
                            personNames.find((_) => _.Owner === params.row.Owner)?.Data || '-',
                        width: 200,
                    },
                    gridColumns.withTooltip({
                        field: 'Type',
                        headerName: t('AnelmaFarmFiles:1008'),
                        valueGetter: (params) =>
                            types.find((type) => `${type.code}` === `${params.value}`)?.text || '-',
                        width: 200,
                    }),
                ]}
                deleteConfirmation={t('AnelmaFarmFiles:1002')}
                enableAddButton={auth.canCreateFarmDocument}
                enableDeleteFile={auth.canDeleteFarmDocument}
                fileType={DocumentFileTypeEnum.FarmDocument}
                onSave={onSave}
                preLoading={loading}
                title={t('AnelmaLayout:1077')}
                types={{
                    label: t('AnelmaFarmFiles:1008'),
                    parameter: 'AnelmaFarmFiles:FarmFileSubject',
                }}
                documentDialogConfig={'farmDocuments'}
                farmId={farmDetails?.Id ?? ''}
            />
        )
    );
}
