import AAddressesInputList, { AAddressesInputListProps } from './inputs/AAddressesInputList';
import AAutocomplete, { AAutocompleteProps, AAutocompleteValue } from './inputs/AAutocomplete';
import ABankAccountInput, { ABankAccountInputProps } from './inputs/ABankAccountInput';
import ABusinessCodeInput, { ABusinessCodeInputProps } from './inputs/ABusinessCodeInput';
import ACheckboxButtons, {
    ACheckboxButtonsProps,
    ACheckboxButtonsValue,
} from './inputs/ACheckboxButtons';
import ADateInput, { ADateInputProps, ADateValue } from './inputs/ADateInput';
import ADropdown, { ADropdownProps, ADropdownItemProps, ADropdownValue } from './inputs/ADropdown';
import AEmailAddressInput, { AEmailAddressInputProps } from './inputs/AEmailAddressInput';
import AErrorTextInput, { AErrorTextInputProps } from './inputs/AErrorTextInput';
import AInputContainer from './inputs/AInputContainer';
import ALabel from './inputs/ALabel';
import ALanguageInput, { ALanguageInputProps } from './inputs/ALanguageInput';
import ALoginNameInput, { ALoginNameInputProps } from './inputs/ALoginNameInput';
import AMultiselect, { AMultiselectProps, AMultiselectValue } from './inputs/AMultiselect';
import ANumberInput, {
    ANumberInputProps,
    ANumberInputMode,
    ANumberInputValue,
} from './inputs/ANumberInput';
import APhoneNumberInput, { APhoneNumberInputProps } from './inputs/APhoneNumberInput';
import APhoneNumbersInputList, {
    APhoneNumbersInputListProps,
} from './inputs/APhoneNumbersInputList';
import ARadioButtons, {
    ARadioButtonsProps,
    ARadioButtonGroupInputValue,
} from './inputs/ARadioButtons';
import ASwitch, { ASwitchProps } from './inputs/ASwitch';
import ATextarea, { ATextareaProps } from './inputs/ATextarea';
import ATextInput, { ATextInputBaseProps, ATextInputProps } from './inputs/ATextInput';
import ATimeInput, { ATimeInputProps, ATimeValue } from './inputs/ATimeInput';
import AZipAndCityInput, { AZipAndCityInputProps } from './inputs/AZipAndCityInput';

export interface AInputItem<T> {
    text: string;
    value: T;
}

export {
    AAddressesInputList,
    AAutocomplete,
    ABankAccountInput,
    ABusinessCodeInput,
    ACheckboxButtons,
    ADateInput,
    ADropdown,
    AEmailAddressInput,
    AErrorTextInput,
    AInputContainer,
    ALabel,
    ALanguageInput,
    ALoginNameInput,
    AMultiselect,
    ANumberInput,
    APhoneNumberInput,
    APhoneNumbersInputList,
    ARadioButtons,
    ASwitch,
    ATextarea,
    ATextInput,
    ATimeInput,
    AZipAndCityInput,
};

export type {
    AAddressesInputListProps,
    AAutocompleteProps,
    AAutocompleteValue,
    ABankAccountInputProps,
    ABusinessCodeInputProps,
    ACheckboxButtonsProps,
    ACheckboxButtonsValue,
    ADateInputProps,
    ADateValue,
    ADropdownValue,
    ADropdownItemProps,
    ADropdownProps,
    AEmailAddressInputProps,
    AErrorTextInputProps,
    ALanguageInputProps,
    ALoginNameInputProps,
    AMultiselectProps,
    AMultiselectValue,
    ANumberInputProps,
    ANumberInputMode,
    ANumberInputValue,
    APhoneNumberInputProps,
    APhoneNumbersInputListProps,
    ARadioButtonsProps,
    ARadioButtonGroupInputValue,
    ASwitchProps,
    ATextareaProps,
    ATextInputBaseProps,
    ATextInputProps,
    ATimeInputProps,
    ATimeValue,
    AZipAndCityInputProps,
};

export const strToIntValue = (value: string | undefined): ANumberInputValue => {
    if (!value) return null;
    const parsed = parseInt(value, 10);
    if (Number.isNaN(parsed)) return null;
    return parsed;
};

export const intToStrValue = (value: ANumberInputValue): string => {
    return value === null ? '' : `${value}`;
};
