// Content
import { EditableContentType } from '../editableContent/IEditableContent';

// Feature
import InfoView from './InfoView';

export default function FeedingInfo() {
    return (        
        <InfoView 
            anelmaInfoContentType={null}
            bovineContentType={EditableContentType.FeedingBovine}
            porkContentType={EditableContentType.FeedingPork}
            sharedContentType={EditableContentType.Feeding}
            title={1043}
            applyContainer={true}
        />      
    );
}
