interface UnsafeHtml {
    __html: string;
}

class Utils {
    wrapHtml(text: string): UnsafeHtml {
        var linkified = "";

        if (text.startsWith('&lt;iframe') && text.endsWith('&gt;&lt;/iframe&gt;')) {
            linkified = text.replace('&lt;', '<').replace('&gt;', '>');
        }

        else {
            linkified = text.replace(/<a href=/gi, '<a target="_blank" href=');
        }

        return {
            __html: linkified,
        };
    }
}

export default new Utils();
