import { Dialog, styled } from '@mui/material';

export interface ADialogProps {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    onClose?: () => void;
    open: boolean;
    disableEnforceFocus?: boolean;
    children?: React.ReactNode;
}

const Wrapper = styled(Dialog)(({ theme }) => ({
    root: {
        '& .MuiDialog-scrollPaper': {
            alignItems: 'flex-start',
        },
        '& .MuiPaper-rounded': {
            borderRadius: '0',
        },
    },
}));

export default function ADialog(props: ADialogProps) {
    const { onClose, ...rest } = props;
    return (
        <Wrapper
            maxWidth='lg'
            onClose={(e, r) => {
                if (r === 'backdropClick') return;
                if (onClose) onClose();
            }}
            {...rest}
        />
    );
}
