import { Typography } from "@mui/material";
import { AEditIconButton } from "../../../common/buttons";

interface IProps {
    title: string;
    readonly?: boolean;
    onEdit?: () => void;
}

export default function SectionTitle(props: IProps){
    return (
    <div style={{display: 'inline-block', width: '100%'}}>
        <Typography variant="h4" style={{float: 'left'}}>{props.title}</Typography>
        {props.onEdit && !props.readonly && (<AEditIconButton 
            onClick={props.onEdit}
            style={{float: 'right', paddingTop: 0}}
        />)}
    </div>
    );
}