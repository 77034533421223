// Libraries
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// MUI
// Core
import { ResourceTextApplication } from '../../core/resources';
import FormValidator from '../../core/FormValidator';

// Common
import { ASearchIconButton } from '../buttons';
import { ADateInput } from '../inputs';

// Interfaces
// Store
// API
// Feature

export interface ISearchCalendarProps {
    handleSort: () => void;
    handleFromDateChange: (fromDate: moment.Moment) => void;
    handleToDateChange: (toDate: moment.Moment) => void;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    className: string;
    childrenText: string;
    fromDateId: string;
    toDateId: string;
}

export default function SearchCalendar(props: ISearchCalendarProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine', 'AnelmaGeneral']);
    const validator = new FormValidator();

    return (
        <Grid container spacing={0} sx={{paddingBottom: '20px'}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                    className={props.className}
                    variant='subtitle1'
                    children={props.childrenText}
                />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3}>
                <ADateInput
                    id={props.fromDateId}
                    label={t('AnelmaGeneral:1090')}
                    onChange={(_) => props.handleFromDateChange(moment(_))}
                    validator={validator}
                    value={props.fromDate.toDate()}
                />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
                <ADateInput
                    id={props.toDateId}
                    label={t('AnelmaGeneral:1091')}
                    onChange={(_) => props.handleToDateChange(moment(_))}
                    validator={validator}
                    value={props.toDate.toDate()}
                />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
                <ASearchIconButton
                    onClick={() => props.handleSort()}
                    type='action'
                    tooltip={t('AnelmaGeneral:1055')}
                    style={{marginTop: '12px'}}
                />
            </Grid>
        </Grid>
    );
}
