import { ATextInput, ATextInputBaseProps } from '../inputs';

export interface AEmailAddressInputProps extends ATextInputBaseProps<string> {}

export default function AEmailAddressInput(props: AEmailAddressInputProps) {
    return (
        <ATextInput
            {...props}
            maxLength={128}
            minLength={5}
            regExp={/^[^@\s]+@[^@\s\.]+\.[^@\.\s]+(\.[^@\.\s]+)?$/}
        />
    );
}
