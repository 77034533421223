// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Container, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

// Core
import auth from '../../../core/authorization';
import { ResourceTextApplication } from '../../../core/resources';
import log from '../../../core/log';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../../common/dialog';

// Interfaces
import {
    IAnimalPayloadItem,
    ICombinedPayloadBatchRow,
    IPayloadSms,
} from '../../../interfaces/IAnimal';

// Store
// API
import ShortMessageApi from '../../../api/shortMessageApi';

// Feature
import {
    AnimalPayloadsSmsDialogSelectionGrid,
    AnimalPayloadsSmsDialogSelectionGridProps,
} from './AnimalPayloadsSmsDialogSelectionGrid';
import {
    AnimalPayloadsSmsDialogSmMessageForm,
    AnimalPayloadsSmsDialogSmMessageFormProps,
} from './AnimalPayloadsSmsDialogSmsMessageForm';

export interface AnimalPayloadsSmsDialogProps {
    payloadData: IAnimalPayloadItem;
    onCloseButtonClicked: () => void;
}

export const AnimalPayloadsSmsDialog = (props: AnimalPayloadsSmsDialogProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaAnimalPayload',
    ]);

    const [announcementSelected, setAnnouncementSelected] = useState<boolean>(false);
    const [isAnnouncementSelectionVisible, setIsAnnouncementSelectionVisible] =
        useState<boolean>(true);
    const [smsMessages, setSmsMessages] = useState<IPayloadSms[]>([]);
    const [selectedCombinedBatches, setSelectedCombinedBatches] = useState<
        ICombinedPayloadBatchRow[]
    >([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const sendSms = () => {
        setIsLoading(true);

        if (smsMessages.length > 0) {
            ShortMessageApi.sendPayloadMessages(smsMessages)
                .then((response) => {
                    if (response) {
                        enqueueSnackbar(
                            `${t('AnelmaGeneral:1160')
                                .replace(
                                    '{success}',
                                    response.Items.filter((f) => f.Success).length.toString()
                                )
                                .replace('{all}', response.Items.length.toString())}`,
                            {
                                variant: 'success',
                            }
                        );
                        setIsLoading(false);
                        props.onCloseButtonClicked();
                    } else {
                        enqueueSnackbar(t('AnelmaGeneral:1020'), {
                            variant: 'error',
                        });
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(t('AnelmaGeneral:1020'), {
                        variant: 'error',
                    });
                    log('error', `Unknown error when trying to 'sendPayloadMessages': ${err}`);
                    setIsLoading(false);
                });
        }
    };

    const onGridRowSelection = (batches: ICombinedPayloadBatchRow[]) => {
        if (batches.length > 0) {
            setAnnouncementSelected(true);
            setSelectedCombinedBatches(batches);
        } else setAnnouncementSelected(false);
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            isAnnouncementSelectionVisible
                ? {
                      onClick: () => {
                          props.onCloseButtonClicked();
                      },
                      type: 'cancel',
                  }
                : {
                      onClick: () => {
                          setIsAnnouncementSelectionVisible(true);
                      },
                      type: 'default',
                      label: t('AnelmaGeneral:1154'),
                      icon: <ArrowBack />,
                  },
        ],
        right: isAnnouncementSelectionVisible
            ? [
                  {
                      disabled: announcementSelected === false,
                      onClick: () => setIsAnnouncementSelectionVisible(false),
                      type: 'with-label',
                      label: t('AnelmaGeneral:1153'),
                      icon: <ArrowForward />,
                  },
              ]
            : auth.canSendSms
            ? [
                  {
                      onClick: () => sendSms(),
                      type: 'send',
                      label: t('AnelmaGeneral:1076'),
                  },
              ]
            : [],
    };

    const animalPayloadsSmsDialogSelectionGridProps: AnimalPayloadsSmsDialogSelectionGridProps = {
        batches: props.payloadData.Batches,
        onGridRowSelection: onGridRowSelection,
        payloadData: props.payloadData,
        loading: isLoading,
    };

    const animalPayloadsSmsDialogSmMessageFormProps: AnimalPayloadsSmsDialogSmMessageFormProps = {
        setMessage: (messages: IPayloadSms[]) => setSmsMessages(messages),
        payload: props.payloadData,
        selectedCombinedBatches: selectedCombinedBatches,
    };

    return (
        <>
            <ADialog open>
                <ADialogTitle>{t('AnelmaAnimalPayload:1031')}</ADialogTitle>
                <ADialogContent size='lg' isLoading={isLoading}>
                    <Container>
                        <Typography variant='h4'>
                            {`${t('AnelmaAnimalPayload:1009')} ${
                                props.payloadData.AnimalPayloadNumber
                            }`}{' '}
                        </Typography>
                        {isAnnouncementSelectionVisible ? (
                            <AnimalPayloadsSmsDialogSelectionGrid
                                {...animalPayloadsSmsDialogSelectionGridProps}
                            />
                        ) : (
                            <AnimalPayloadsSmsDialogSmMessageForm
                                {...animalPayloadsSmsDialogSmMessageFormProps}
                            />
                        )}
                    </Container>
                </ADialogContent>
                <ADialogActions buttons={footerButtons} />
            </ADialog>
        </>
    );
};
