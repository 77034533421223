// Libraries
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

// Core
import {
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../../../core/resources';
import FormValidator from '../../../../core/FormValidator';
import utils from '../../../../core/utils';
import defaultData from '../../../../core/defaultData';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../../../common/dialog';
import {
    ALabel,
    ADropdown,
    AInputContainer,
    ANumberInput,
    ATextInput,
    AAutocomplete,
    AAutocompleteValue,
    AInputItem,
} from '../../../../common/inputs';
import { Context } from '../../../../common/contextSelector/ContextSelector';
import ConfirmDialogClose from '../../../../common/dialog/ConfirmDialogClose';

// Interfaces
import {
    AliasTypeEnum,
    AnnouncementTypeEnum,
    DialogModeEnum,
    FarmingTypeEnum,
} from '../../../../interfaces/enums';
import {
    IAnimalAnnouncement,
    ICollectWeek,
    ICreateBovinePurchaseAnnouncement,
    IUpdateBovinePurchaseAnnouncement,
} from '../../../../interfaces/IAnnouncement';
import { IFarmDetails } from '../../../../interfaces/IBusinessEntities';
import { ITiltuApiResult } from '../../../../interfaces/IGeneral';

// Store
import { useAppSelector } from '../../../../store/hooks';

// API
import companyApi from '../../../../api/companyApi';
import AnimalAnnouncementApi from '../../../../api/animalAnnouncementApi';

// Feature
import {
    generateCollectWeekSequence,
    initializeCollectWeekStart,
    mapCollectWeeksForDropdown,
} from '../../helpers/date';
import { mapParameters } from '../../../shared/helpers/data';
import { Strings } from '../../../shared/constants/strings';
import { getFarmingTypesFromFarmDetails, hasAnnouncementResponseErrors } from '../../helpers/data';
import {
    collectWeekStartNotValid,
    collectWeekStartHasValue,
    collectWeekEndHasValue,
    collectWeekEndNotValid,
} from '../../helpers/collectWeek';
import { getHoldingSiteItems } from '../../helpers/holdingSite';
import {
    constructAnnouncementNumberGridSize,
    constructCollectWeekGridSize,
} from '../../../shared/helpers/gridSize';
import { IGridSize } from '../bovine-slaughter-announcement/BovineSlaughterAnnouncement';
import { holdingSiteHasValue } from '../../../shared/helpers/utils';
import { boundaryRepository } from '../../../../core/boundaries';
import '../../styles/announcementStyles.scss';

export interface IAnnouncementDialogProps {
    data: IAnimalAnnouncement | null;
    onClose: () => void;
    mode: DialogModeEnum;
    processAnnouncementSave: (
        data: ITiltuApiResult<any>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => void;
}

export default function BovinePurchaseAnnouncement(props: IAnnouncementDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBovine',
        'AnelmaGeneral',
        'AnelmaCompany',
        'AnelmaAnimalAnnouncement',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const animalAnnouncementType = AnnouncementTypeEnum.BovinePurchase;
    const context = useAppSelector((state) => state.context);
    const validator = new FormValidator();

    const initializeStartWeek = useCallback(
        () =>
            props.data?.Batches.find((i) => i.CollectWeekStart)?.CollectWeekStart ??
            initializeCollectWeekStart(animalAnnouncementType),
        []
    );

    const [isLoading, setIsLoading] = useState<boolean>();
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();
    const [producerNumber, setProducerNumber] = useState<string>('');
    const [holdingSiteId, setHoldingSiteId] = useState<string>('');
    const [collectWeekStart, setCollectWeekStart] = useState<ICollectWeek>(initializeStartWeek());
    const [collectWeekEnd, setCollectWeekEnd] = useState<ICollectWeek>(
        props.data?.Batches.find((i) => i.CollectWeekEnd)?.CollectWeekEnd ?? {
            Year: -1,
            Week: -1,
        }
    );

    const [collectWeekDay2, setCollectWeekDay2] = useState(
        props.data?.Batches.find((i) => i.CollectWeekDay)?.CollectWeekDay ?? ''
    );
    const [collectWeekDayParams, setCollectWeekDayParams] = useState<ParameterValues>([]);
    const [collectWeeks, setCollectWeeks] = useState<ICollectWeek[]>([]);

    const [announcementInfo, setAnnouncementInfo] = useState(props.data?.Batches[0].BatchInfo);

    const [animalCount, setAnimalCount] = useState<number>(
        (props.data?.Batches.find((i) => i.Amount)?.Amount as number) ?? 0
    );

    const [salesTypeParameterCode, setSalesTypeParameterCode] = useState(
        props.data?.Batches[0].SalesType !== undefined && props.data?.Batches[0].SalesType !== null
            ? props.data?.Batches[0].SalesType
            : -1
    );

    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues>([]);
    const [combinedSalesTypeParams, setCombinedSalesTypeParams] = useState<ParameterValues>([]);
    const [breederBrokerPurchaseParams, setBreederBrokerPurchaseParams] = useState<ParameterValues>(
        []
    );
    const [rearingUnitBrokerPurchaseParams, setRearingUnitBrokerPurchaseParams] =
        useState<ParameterValues>([]);
    const [sucklerCowCattleBrokerPurchaseParams, setSucklerCowCattleBrokerPurchaseParams] =
        useState<ParameterValues>([]);

    const [createBovinePurchaseAnnoucement, setCreateBovinePurchaseAnnoucement] =
        useState<ICreateBovinePurchaseAnnouncement>(defaultData.animalAnnoucementBoviePurchase());
    const [updateBovinePurchaseAnnoucement, setUpdateBovinePurchaseAnnoucement] =
        useState<IUpdateBovinePurchaseAnnouncement>();
    const [holdingSiteItems, setHoldingSiteItems] = useState<AInputItem<AAutocompleteValue>[]>([]);
    const [collectWeekGridSize, setCollectWeekGridSize] = useState<IGridSize>({
        Xs: 2,
        Sm: 2,
        Md: 2,
        Lg: 2,
    });
    const [announcementNumberGridSize, setAnnouncementNumberGridSize] = useState<IGridSize>({
        Xs: 4,
        Sm: 4,
        Md: 4,
        Lg: 4,
    });
    const [confirmationDialogClose, setConfirmationDialogClose] = useState<boolean>(false);

    const [addInfoMinLength, setAddInfoMinLength] = useState<number | undefined>();
    const [addInfoMaxLength, setAddInfoMaxLength] = useState<number | undefined>();
    const [boundariesLoaded, setBoundariesLoaded] = useState<boolean>(false);

    const theme = useTheme();
    const isBelowMdDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        initializeCollectWeeks(initializeStartWeek().Week);
        getCollectWeekDayParams();
        getSalesTypeParameters();

        boundaryRepository.load(['AnelmaAnimalAnnouncement']).then(() => {
            const boundaries = boundaryRepository.resource(
                'AnelmaAnimalAnnouncement',
                'BovinePurchaseAnnouncementInfo'
            );

            if (boundaries) {
                setAddInfoMaxLength(
                    Number.isInteger(boundaries.maxValue)
                        ? (boundaries.maxValue as number)
                        : undefined
                );
                setAddInfoMinLength(
                    Number.isInteger(boundaries.minValue)
                        ? (boundaries.minValue as number)
                        : undefined
                );
            }

            setBoundariesLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (context.data.currentContext) {
            initializeFarmDetails(context.data.currentContext);
        } else setFarmDetails(undefined);
    }, [context]);

    useEffect(() => {
        if (combinedSalesTypeParams.length > 0) {
            setSalesTypeParams(
                combinedSalesTypeParams.filter(
                    (value, idx, self) =>
                        idx ===
                        self.findIndex((t) => t.code === value.code && t.text === value.text)
                )
            );
        }
    }, [combinedSalesTypeParams]);

    useEffect(() => {
        if (farmDetails !== undefined) {
            combineSalesTypeParams(farmDetails);
            setHoldingSiteItems(getHoldingSiteItems(farmDetails));
        } else if (props.mode === DialogModeEnum.Edit && props.data?.HoldingSiteId)
            setHoldingSiteId(props.data.HoldingSiteId);
    }, [farmDetails]);

    useEffect(() => {
        if (collectWeekStartNotValid(collectWeekStart, collectWeekEnd)) {
            if (
                collectWeekStartHasValue(collectWeekStart) &&
                collectWeekEndHasValue(collectWeekEnd)
            ) {
                enqueueSnackbar(t('AnelmaAnimalAnnouncement:1006'), {
                    variant: 'error',
                });
                setCollectWeekStart({ Year: -1, Week: -1 });
            }
        }
    }, [collectWeekStart]);

    useEffect(() => {
        if (collectWeekEndNotValid(collectWeekEnd, collectWeekStart)) {
            if (collectWeekEndHasValue(collectWeekEnd)) {
                enqueueSnackbar(t('AnelmaAnimalAnnouncement:1007'), {
                    variant: 'error',
                });
                setCollectWeekEnd({ Year: -1, Week: -1 });
            }
        }
    }, [collectWeekEnd]);

    useEffect(() => {
        if (isBelowMdDevice) {
            setAnnouncementNumberGridSize(constructAnnouncementNumberGridSize());
            setCollectWeekGridSize(constructCollectWeekGridSize());
        }
    }, [isBelowMdDevice]);

    // Generate collect week sequences for the current and next year
    const initializeCollectWeeks = (from: number) => {
        setCollectWeeks(
            generateCollectWeekSequence(
                from,
                moment().isoWeeksInYear() - from,
                moment().isoWeekYear()
            )
        );
        if (moment().add(12, 'months').year() === moment().add(1, 'y').year()) {
            setCollectWeeks((previousState) => [
                ...previousState,
                ...generateCollectWeekSequence(
                    1,
                    moment().add(12, 'months').isoWeek(),
                    moment().isoWeekYear() + 1
                ),
            ]);
        }
    };

    const getCollectWeekDayParams = () => {
        paramRepository.load(['AnelmaAnimalAnnouncement']).then(() => {
            setCollectWeekDayParams(
                paramRepository.resource('AnelmaAnimalAnnouncement', 'CollectWeekDay')
            );
        });
    };

    const getSalesTypeParameters = () => {
        paramRepository.load(['AnelmaBovine']).then(() => {
            setBreederBrokerPurchaseParams(
                paramRepository.resource('AnelmaBovine', 'BreederBrokerPurchase')
            );
            setRearingUnitBrokerPurchaseParams(
                paramRepository.resource('AnelmaBovine', 'RearingUnitBrokerPurchase')
            );
            setSucklerCowCattleBrokerPurchaseParams(
                paramRepository.resource('AnelmaBovine', 'SucklerCowCattleBrokerPurchase')
            );
        });
    };

    const combineSalesTypeParams = (farmDetails: IFarmDetails) => {
        // •	Kasvattaja = BreederBrokerPurchase ,            3 = kasvattaja       Upbringing
        // •	Välikasvattamo = RearingUnitBrokerPurchase,     4 = välikasvattamo   Transmission
        // •	Lypsykarjatila = N/A,                           2 = lypsykarjatila   Dairy
        // •	Emolehmätila = SucklerCowCattleBrokerPurchase,  1 = emolehmätila     Calving
        // Jos tilalla on useampi tuotantosuunta, listaan yhdistetään tilan tuotantosuuntien parametrit
        const farmingTypesInCurrentFarm = getFarmingTypesFromFarmDetails(
            farmDetails.ProductionLines
        );
        for (const farmingType of farmingTypesInCurrentFarm) {
            switch (farmingType) {
                case FarmingTypeEnum.Calving: // Emolehmätila
                    setCombinedSalesTypeParams((previousState) => [
                        ...previousState,
                        ...sucklerCowCattleBrokerPurchaseParams,
                    ]);
                    break;
                case FarmingTypeEnum.Dairy: // Lypsykarjatila
                    break;
                case FarmingTypeEnum.Upbringing: // Kasvattaja
                    setCombinedSalesTypeParams((previousState) => [
                        ...previousState,
                        ...breederBrokerPurchaseParams,
                    ]);
                    break;
                case FarmingTypeEnum.Transmission: // Välikasvattamo
                    setCombinedSalesTypeParams((previousState) => [
                        ...previousState,
                        ...rearingUnitBrokerPurchaseParams,
                    ]);
                    break;
                default:
                    break;
            }
        }
    };

    const initializeFarmDetails = (context: Context) => {
        setIsLoading(true);
        const companyByContext = companyApi
            .getCompanyByContext(context.context)
            .then((response) => {
                setFarmDetails(response?.Entity as IFarmDetails);
            });
        Promise.all([companyByContext]).then(() => {
            setIsLoading(false);
        });
        let producerId = utils.context.parseFarmContext(context.context)?.producerId;
        if (producerId) setProducerNumber(producerId);
    };

    const handleDialogClose = () => setConfirmationDialogClose(true);

    const canSave = () => {
        if (props.mode === DialogModeEnum.Create)
            return (
                !validator.invalid &&
                holdingSiteHasValue(holdingSiteId) &&
                animalCount > 0 &&
                salesTypeParameterCode !== -1 &&
                collectWeekEndHasValue(collectWeekEnd) &&
                collectWeekStartHasValue(collectWeekStart)
            );
        return true;
    };

    const onSave = (createBovinePurchaseAnnoucement: ICreateBovinePurchaseAnnouncement) => {
        //Drop down validation if not selected for salestype & collectweekday
        validator.setState(
            'announcement-sales-type-dropdown',
            salesTypeParameterCode > 0,
            (valid) => {
                if (!valid) {
                    return;
                }
            }
        );

        if (validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setIsLoading(false);
            return;
        }

        if (props.mode === DialogModeEnum.Create) {
            {
                const newdata: ICreateBovinePurchaseAnnouncement = {
                    ...createBovinePurchaseAnnoucement,
                    ProducerNumber: Number(producerNumber),
                    AnnouncementInfo: (announcementInfo || ' ') as string,
                    FarmId: farmDetails?.Id as string,
                    BovineInfos: [
                        {
                            HoldingSiteId: holdingSiteId,
                            SalesType: salesTypeParameterCode,
                            AnimalCount: animalCount,
                            CollectWeekStart: {
                                Year: collectWeekStart.Year,
                                Week: collectWeekStart.Week,
                            },
                            CollectWeekEnd: {
                                Year: collectWeekEnd.Year,
                                Week: collectWeekEnd.Week,
                            },
                            CollectWeekDay: 3,
                            AddInfo: (announcementInfo || '') as string,
                        },
                    ],
                };
                setIsLoading(true);
                AnimalAnnouncementApi.createBovinePurchaseAnnouncement(newdata)
                    .then((response) => {
                        if (!response) {
                            enqueueSnackbar(`${t('AnelmaGeneral:1019')}`, {
                                variant: 'error',
                            });
                            return;
                        }
                        if (hasAnnouncementResponseErrors(response)) {
                            response.Summary.Errors.map((_) => {
                                enqueueSnackbar(`${_.Explanation}`, {
                                    variant: 'error',
                                });
                            });
                            return;
                        } else if (!hasAnnouncementResponseErrors(response)) {
                            enqueueSnackbar(t('AnelmaGeneral:1018'), {
                                variant: 'success',
                            });
                            props.processAnnouncementSave(
                                response,
                                AnnouncementTypeEnum.BovinePurchase,
                                holdingSiteId
                            );
                        }
                        props.onClose();
                    })
                    .catch((err) => {
                        enqueueSnackbar(t('AnelmaGeneral:1030'), {
                            variant: 'error',
                        });
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        } else if (props.mode === DialogModeEnum.Edit) {
            const editData: IUpdateBovinePurchaseAnnouncement = {
                ...updateBovinePurchaseAnnoucement,
                AnnouncementNumber: Number(props.data?.Number),
                ProducerNumber: farmDetails?.Aliases.find(
                    (i) => i.Type === AliasTypeEnum.ProducerNumber
                )?.Value as unknown as number,
                AnnouncementInfo: announcementInfo || ' ',
                FarmId: farmDetails?.Id as string,
                Additions: [],
                Updates: [
                    {
                        AnnouncementBatchNumber: String(
                            props.data?.Batches.find((i) => i.BatchNumber)?.BatchNumber
                        ),
                        SalesType: salesTypeParameterCode,
                        AnimalCount: animalCount,
                        CollectWeekStart: {
                            Year: collectWeekStart.Year,
                            Week: collectWeekStart.Week,
                        },
                        CollectWeekEnd: {
                            Year: collectWeekEnd.Year,
                            Week: collectWeekEnd.Week,
                        },
                        CollectWeekDay: collectWeekDay2 as number,
                        AddInfo: announcementInfo || ' ',
                    },
                ],
            };

            setUpdateBovinePurchaseAnnoucement(editData);

            setIsLoading(true);
            AnimalAnnouncementApi.updateBovinePurchaseAnnouncement(editData)
                .then((response) => {
                    if (!response) {
                        enqueueSnackbar(`${t('AnelmaGeneral:1019')}`, {
                            variant: 'error',
                        });
                        return;
                    }
                    if (hasAnnouncementResponseErrors(response)) {
                        response.Summary.Errors.map((_) => {
                            enqueueSnackbar(`${_.Explanation}`, {
                                variant: 'error',
                            });
                        });
                        return;
                    } else if (!hasAnnouncementResponseErrors(response)) {
                        enqueueSnackbar(t('AnelmaGeneral:1018'), {
                            variant: 'success',
                        });
                        props.processAnnouncementSave(
                            response,
                            AnnouncementTypeEnum.BovinePurchase,
                            holdingSiteId
                        );
                        props.onClose();
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(t('AnelmaGeneral:1030'), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const dialogActionButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => handleDialogClose(),
            },
        ],
        right: [
            {
                onClick: () => {
                    onSave(createBovinePurchaseAnnoucement);
                },
                type: 'save',
                disabled: isLoading === true || !canSave(),
            },
        ],
    };

    return (
        <ADialog open onClose={props.onClose} disableEnforceFocus>
            {props.mode === DialogModeEnum.Create ? (
                <ADialogTitle>{t('AnelmaBovine:1058')}</ADialogTitle>
            ) : (
                <ADialogTitle>{t('AnelmaBovine:1059')}</ADialogTitle>
            )}

            <ADialogContent size={'lg'} isLoading={isLoading}>
                <Grid container direction='row'>
                    {props.mode === DialogModeEnum.Edit ? (
                        <div style={{ marginBottom: '1.5em' }}>
                            <AInputContainer>
                                <Typography variant='h5'>
                                    {`${t('AnelmaBovine:1052').toUpperCase()} ${
                                        props.data?.Number
                                    }`}
                                </Typography>
                            </AInputContainer>
                        </div>
                    ) : null}
                    <Grid container direction='row'>
                        <Grid
                            item
                            xs={announcementNumberGridSize.Xs}
                            sm={announcementNumberGridSize.Sm}
                            md={announcementNumberGridSize.Md}
                            lg={announcementNumberGridSize.Lg}
                        >
                            <ALabel className='anelma-animal-announcement-general-label'>
                                {t('AnelmaBovine:1165')}
                            </ALabel>
                        </Grid>
                        {props.mode === DialogModeEnum.Create && holdingSiteItems.length > 0 ? (
                            <Grid item xs={8} sm={8} md={8} lg={8}>
                                <AAutocomplete
                                    id={Strings.AnnouncementHoldingSiteId}
                                    items={holdingSiteItems}
                                    label={t('AnelmaBovine:1063')}
                                    onChange={(_) => setHoldingSiteId(_?.toString() ?? '')}
                                    validator={validator}
                                    required
                                    value={holdingSiteId}
                                    error={!(holdingSiteId?.length > 0)}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                <ATextInput
                                    id={Strings.AnnouncementHoldingSiteId}
                                    label={t('AnelmaBovine:1063')}
                                    onChange={(v) => false}
                                    validator={validator}
                                    disabled
                                    value={holdingSiteId}
                                    lengthBoundaryName='AnelmaCompany:HoldingNumberLength'
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={collectWeekGridSize.Xs}
                        sm={collectWeekGridSize.Sm}
                        md={collectWeekGridSize.Md}
                        lg={collectWeekGridSize.Lg}
                    >
                        <ADropdown
                            id={Strings.AnnouncementCollectWeekStartNumber}
                            onChange={(v) =>
                                setCollectWeekStart(JSON.parse(v as string) as ICollectWeek)
                            }
                            value={JSON.stringify(
                                collectWeekStart.Week === -1 && collectWeekStart.Year === -1
                                    ? { Year: '', Week: '' }
                                    : collectWeekStart
                            )}
                            items={mapCollectWeeksForDropdown(collectWeeks)}
                            validator={validator}
                            required
                            error={!collectWeekStartHasValue(collectWeekStart)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={collectWeekGridSize.Xs}
                        sm={collectWeekGridSize.Sm}
                        md={collectWeekGridSize.Md}
                        lg={collectWeekGridSize.Lg}
                    >
                        <ADropdown
                            id={Strings.AnnouncementCollectWeekEndNumber}
                            onChange={(v) =>
                                setCollectWeekEnd(JSON.parse(v as string) as ICollectWeek)
                            }
                            value={JSON.stringify(
                                collectWeekEnd.Week === -1 && collectWeekEnd.Year === -1
                                    ? { Year: '', Week: '' }
                                    : collectWeekEnd
                            )}
                            items={mapCollectWeeksForDropdown(collectWeeks)}
                            validator={validator}
                            error={!collectWeekEndHasValue(collectWeekEnd)}
                        />
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                        <ANumberInput
                            id={Strings.AnnouncementAnimalCount}
                            label={t('AnelmaBovine:1041')}
                            mode='int'
                            onChange={(v) => setAnimalCount(v as number)}
                            value={animalCount === 0 ? null : animalCount}
                            validator={validator}
                            min={1}
                            required
                            error={!(animalCount > 0)}
                        />
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ADropdown
                                label={t('AnelmaBovine:1019')}
                                id={Strings.AnnouncementSalesTypeDropdown}
                                items={mapParameters(salesTypeParams)}
                                onChange={(v) => setSalesTypeParameterCode(v as number)}
                                validator={validator}
                                value={salesTypeParameterCode === -1 ? '' : salesTypeParameterCode}
                                required
                                error={salesTypeParameterCode === -1}
                            />
                        </Grid>
                        {boundariesLoaded && (
                            <Grid item xs={12} sm={12} md={10} lg={6}>
                                <ATextInput
                                    id={Strings.AnnouncementAdditionalInformation}
                                    label={t('AnelmaBovine:1039')}
                                    onChange={(v) => setAnnouncementInfo(v)}
                                    value={announcementInfo as string}
                                    validator={validator}
                                    maxLength={addInfoMaxLength}
                                    minLength={addInfoMinLength}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </ADialogContent>

            <ADialogActions buttons={dialogActionButtons} />

            {confirmationDialogClose && (
                <ConfirmDialogClose
                    confirmation='AnelmaGeneral:1065'
                    onCancel={() => setConfirmationDialogClose(false)}
                    onClose={() => props.onClose()}
                    open={confirmationDialogClose}
                />
            )}
        </ADialog>
    );
}
