// Libraries
import { mergeRight } from 'ramda';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import {
    GridRenderCellParams,
    GridRowParams,
    GridValidRowModel,
    useGridApiRef,
} from '@mui/x-data-grid-pro';

// Core
import { dayFormat } from '../../../core/constants';
import { ParameterValues, ResourceTextApplication } from '../../../core/resources';

// Common
import { ADeleteIconButton } from '../../../common/buttons';
import { ADataGrid, ADataGridActions, AGridColumns, gridColumns } from '../../../common/dataGrid';
import { TextWithTooltip } from '../../../common/typography';

// Interfaces
import { AnnouncementTypeEnum, DialogModeEnum } from '../../../interfaces/enums';
import { IFarmAnimal } from '../../../interfaces/IAnimal';
import {
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
} from '../../../interfaces/IAnnouncement';
import { IFarmDetails } from '../../../interfaces/IBusinessEntities';

// Store
// API
// Feature
import { AnimalAnnouncementCollectWeekField } from '../../announcements/components/data-grid/AnimalAnnouncementCollectWeekField';
import ModifyCollectWeekDialog from '../../announcements/components/general/ModifyCollectWeekDialog';
import ModifySalesTypeDialog from '../../announcements/components/sales-type/ModifySalesTypeDialog';
import {
    birthDateComparator,
    isAnimalAnnouncementBatchRowSelectable,
    isFarmDetailsValidForSalesTypeUpdate,
} from '../../announcements/helpers/data';
import ConfirmDialog from '../../shared/components/confirm/ConfirmDialog';
import { Strings } from '../../shared/constants/strings';
import { IModifyCollectWeekDialogProps, ISalesTypeDialogProps } from '../../shared/types/propTypes';
import moment from 'moment';

export interface IManualAnnouncementAnimalsGridProps {
    announcementId: string;
    announcementAnimals: IAnimalAnnouncementAnimal[];
    allBatches: IAnimalAnnouncementBatch[];
    announcementType: AnnouncementTypeEnum;
    bovineSexParams: ParameterValues;
    dialogMode: DialogModeEnum;
    farmDetails: IFarmDetails | undefined;
    isLoading: boolean;
    isModifyCollectWeekDialogVisible: boolean;
    isReadOnlyMode?: boolean;
    isSalesTypeDialogVisiable: boolean;
    onAnimalAnimalSalesTypeUpdate: (animal: IFarmAnimal[]) => void;
    onAnimalRemoveFromGrid: (animal: IAnimalAnnouncementAnimal) => void;
    onCloseModifyCollectWeekDialog: (cancelled: boolean | undefined) => void;
    onCloseSalesTypeDialog: () => void;
    salesTypeParams: ParameterValues;
    mode: DialogModeEnum;
}

export const ManualAnnouncementAnimalsGrid = (props: IManualAnnouncementAnimalsGridProps) => {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);

    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [selectedAnimal, setSelectedAnimal] = useState<IAnimalAnnouncementAnimal | null>(null);
    const [rows, setRows] = useState<GridValidRowModel[]>([]);
    const [selectedAnimals, setSelectedAnimals] = useState<string[]>([]);

    useEffect(() => {
        props.announcementAnimals.length > 0
            ? setRows(props.announcementAnimals.map((a) => mergeRight(a, { id: a.AnimalId })))
            : setRows([]);
    }, [props.announcementAnimals]);

    const columns: AGridColumns = [
        gridColumns.euIdentifier(t('AnelmaBovine:1005')),
        {
            field: 'BirthDate',
            headerName: t('AnelmaBovine:1011'),
            renderCell: (params) => {
                const birthDate = String(params.value);
                return birthDate ? (
                    <TextWithTooltip text={`${moment(birthDate).format(dayFormat)}`} />
                ) : (
                    ''
                );
            },
            valueGetter: (params) => {
                if (!params.value || params.value === '0001-01-01') return '';

                return new Date(params.value as Date);
            },
            width: 150,
            type: 'date',
        },
        {
            field: 'Sex',
            headerName: t('AnelmaBovine:1048'),
            renderCell: (param) => {
                const value = (param.row as IAnimalAnnouncementAnimal)?.Sex;

                const bovineSexText =
                    props.bovineSexParams.find((p) => Number(p.code) === value)?.text ?? '';
                return <TextWithTooltip text={bovineSexText} />;
            },
        },
        {
            field: 'SalesType',
            headerName: t('AnelmaBovine:1019'),
            renderCell: (param) => {
                const value = (param.row as IAnimalAnnouncementAnimal)?.SalesType;

                const salesTypeText =
                    props.salesTypeParams.find((p) => Number(p.code) === value)?.text ?? '';

                return <TextWithTooltip text={salesTypeText} />;
            },
            width: 150,
        },
        {
            field: 'Week',
            headerName: t('AnelmaBovine:1097'),
            renderCell: (params) => {
                const animalRow = params.row as IAnimalAnnouncementAnimal;
                const batch = getBatchByAnimalId(animalRow?.AnimalId);

                if (!batch && animalRow.CollectWeekStart && animalRow.CollectWeekEnd) {
                    return (
                        <AnimalAnnouncementCollectWeekField
                            collectWeekStart={animalRow.CollectWeekStart}
                            collectWeekEnd={animalRow.CollectWeekEnd}
                            mode={props.mode}
                        />
                    );
                }
                if (!batch) return '';
                return (
                    <AnimalAnnouncementCollectWeekField
                        collectWeekStart={batch.CollectWeekStart}
                        collectWeekEnd={batch.CollectWeekEnd}
                        mode={props.mode}
                    />
                );
            },
            width: 150,
        },
        {
            field: 'BatchInfo',
            headerName: t('AnelmaBovine:1039'),
            renderCell: (param) => {
                return <TextWithTooltip text={(param?.value as string) ?? ''} />;
            },
            valueGetter: (param) => {
                const batch = getBatchByAnimalId(
                    (param.row as IAnimalAnnouncementAnimal)?.AnimalId
                );
                return batch ? batch.BatchInfo : '';
            },
        },
    ];

    const isDeleteDisabled = (params: GridRenderCellParams) => {
        if (props.isReadOnlyMode && props.isReadOnlyMode === true) return true;
        let collectWeek = props.allBatches.find((a) =>
            a.Animals.find((b) => b.AnimalId === params.row.AnimalId)
        )?.CollectWeekStart;
        if (props.mode === 'edit' && collectWeek)
            return !isAnimalAnnouncementBatchRowSelectable(props.announcementType, collectWeek);
    };

    const getBatchByAnimalId = (animalId: string): IAnimalAnnouncementBatch | null => {
        return props.allBatches.find((b) => b.Animals[0]?.AnimalId === animalId) ?? null;
    };

    const getActionTooltipText = (): string => {
        if (props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter)
            return t('AnelmaBovine:1045');
        else if (props.announcementType === AnnouncementTypeEnum.ManualBovineIntermediation)
            return t('AnelmaBovine:1151');
        else return '';
    };

    const actions: ADataGridActions = [];

    actions.push((params) => {
        return (
            <ADeleteIconButton
                key={`${params.id}-delete`}
                onClick={() => {
                    setConfirmDialogVisible(true);
                    const animal = params.row as unknown as IAnimalAnnouncementAnimal;
                    if (animal) {
                        setSelectedAnimal(animal);
                    }
                }}
                tooltip={getActionTooltipText()}
                disabled={isDeleteDisabled(params)}
            />
        );
    });

    const deleteAnimalFromAnnouncement = () => {
        if (selectedAnimal) {
            props.onAnimalRemoveFromGrid(selectedAnimal);
            setSelectedAnimal(null);
            setSelectedAnimals([]);
        }
    };

    const getAnimalsFromIds = (): IAnimalAnnouncementAnimal[] => {
        return props.announcementAnimals.length > 0
            ? props.announcementAnimals.filter((a) =>
                  selectedAnimals.find((sa) => sa === a.AnimalId)
              )
            : [];
    };

    const isBatchSelectable = (params: GridRowParams): boolean => {
        if (props.isReadOnlyMode && props.isReadOnlyMode === true) return false;
        if (
            selectedAnimal?.AnimalId === params.row?.AnimalId &&
            !props.announcementAnimals.find((i) => i.AnimalId === selectedAnimal?.AnimalId)
        ) {
            return false;
        }
        const batch = getBatchByAnimalId(params.row?.AnimalId);
        return batch
            ? props.dialogMode === 'edit'
                ? isAnimalAnnouncementBatchRowSelectable(
                      props.announcementType,
                      batch.CollectWeekStart
                  )
                : true
            : true;
    };

    const salesTypeDialogProps: ISalesTypeDialogProps = {
        id: props.announcementId,
        onClose: () => props.onCloseSalesTypeDialog(),
        animals: getAnimalsFromIds(),
        allAnimals: null,
        handleSalesTypeUpdate: (data: IFarmAnimal[]) => {
            props.onAnimalAnimalSalesTypeUpdate(data);
        },
        farmId: isFarmDetailsValidForSalesTypeUpdate(props.farmDetails),
        farmDetails: props.farmDetails,
        announcementType: props.announcementType,
        mode: props.dialogMode,
    };

    const modifyCollectWeekDialogProps: IModifyCollectWeekDialogProps = {
        onClose: (cancelled: boolean | undefined) => {
            props.onCloseModifyCollectWeekDialog(cancelled);
        },
        announcementType: props.announcementType,
        animals: getAnimalsFromIds(),
        batches: props.allBatches,
    };

    return (
        <>
            <ADataGrid
                disableSelectionOnClick={true}
                actions={actions}
                columns={columns}
                enableFiltering
                loading={props.isLoading}
                onSelectionModelChange={(selectionModel) => {
                    setSelectedAnimals(selectionModel.map((s) => s.toString()));
                }}
                rows={rows}
                isRowSelectable={(params) => isBatchSelectable(params)}
            />

            {confirmDialogVisible && (
                <ConfirmDialog
                    title={'AnelmaGeneral:1142'}
                    content={'AnelmaGeneral:1012'}
                    actionButtonType={Strings.AnnouncementDeleteConfirmActionType}
                    onClose={() => setConfirmDialogVisible(false)}
                    onConfirm={() => deleteAnimalFromAnnouncement()}
                />
            )}

            {props.isSalesTypeDialogVisiable && <ModifySalesTypeDialog {...salesTypeDialogProps} />}
            {props.isModifyCollectWeekDialogVisible && (
                <ModifyCollectWeekDialog {...modifyCollectWeekDialogProps} />
            )}
        </>
    );
};
