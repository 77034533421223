// Core
import { authorizationUrl } from './apiEndpoints';

// Api
import ApiBase from './ApiBase';

// Interfaces
import { Context } from '../common/contextSelector/ContextSelector';
import { IAuthorizationResource } from '../core/authorization';

class AuthorizationApi extends ApiBase {
    getAccessList(context?: string | null) {
        const contextType = context
            ? `?contextType=FarmContext&context=${encodeURIComponent(context)}`
            : '';

        return this.getList<IAuthorizationResource>(
            `${authorizationUrl}/resource/access-list${contextType}`,
            {
                incompatibleResult: true,
            }
        );
    }

    getContextList(searchTerm?: string) {
        const params = searchTerm ? `?displayNameSearchTerm=${encodeURIComponent(searchTerm)}` : '';
        return this.getList<Context>(
            `${authorizationUrl}/context/access-list/FarmContext${params}`,
            {
                incompatibleResult: true,
            }
        );
    }
}

export default new AuthorizationApi();
