// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { equals, isEmpty, isNil, propOr } from 'ramda';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication, paramRepository } from '../core/resources';
import { IFormValidator } from '../core/FormValidator';
import auth from '../core/authorization';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../common/dialog';
import {
    ADateInput,
    ANumberInput,
    ASwitch,
    ATextarea,
    ATimeInput,
    ADateValue,
} from '../common/inputs';
import { AAddButton } from '../common/buttons';

// Interfaces
import {
    IAnnouncementSearch,
    IBulletinItemCulture,
    IBulletinItemDetails,
} from '../interfaces/ICommunication';
import { AnelmaCultureType, EmptyGUID, GUIDType } from '../interfaces/types';
import {
    IContactPersonSummary,
    IContactPersonRow,
    IFarmDetails,
} from '../interfaces/IBusinessEntities';

// API
import searchParametersApi from '../api/searchParametersApi';

// Feature
import { BulletinRequest, bulletinRequestBuilder } from './api/bulletinRequest';
import SearchMessageGroupDialog from '../shortMessageService/search/SearchMessageGroupDialog';
import { IPredefinedSearchFarmResult, IPredefinedSearch } from '../interfaces/ISearchParameters';
import Recipients from '../common/communicationGroup/Recipients';
import companyApi from '../api/companyApi';
import { AliasTypeEnum } from '../interfaces/enums';
import { IParameterExtension } from '../searchParameters/shared/styles/types/subTypes';
import { isUsingPersonsConnectedToTheFarm } from '../searchParameters/Helpers/general';

export interface EditBulletinDialogProps {
    onSave: (message: BulletinRequest) => void;
    data: IBulletinItemDetails | null;
    onClose: () => void;
    title: string;
    validator: IFormValidator;
}

const getMessage = (
    bulletinItem: IBulletinItemDetails | undefined | null,
    culture: AnelmaCultureType
): null | string => {
    const value = bulletinItem?.Message.find((msg) => msg.Culture === culture)?.MessageText;

    return isNil(value) ? null : value;
};

function getDuration(data: IBulletinItemDetails | null): number | null {
    const value: number | null = propOr(null, 'Duration', data);

    if (isNil(value)) return null;
    return value / 1000;
}

export function BulletinForm({ onClose, onSave, data, title, validator }: EditBulletinDialogProps) {
    const { enqueueSnackbar } = useSnackbar();
    const messageFi = getMessage(data, 'fi-FI');
    const messageEn = getMessage(data, 'en-GB');
    const messageSw = getMessage(data, 'sv-SE');
    const [validFromDate, setValidFromDate] = useState<moment.Moment | null>(() => {
        const date = data?.ValidFromDate;
        return date ? moment(`${date}Z`) : moment().startOf('day');
    });
    const [validDueDate, setValidDueDate] = useState<moment.Moment | null>(() => {
        const date = data?.ValidDueDate;
        return date ? moment(`${date}Z`) : moment().endOf('day');
    });
    const [maxFromDate, setMaxFromDate] = useState<Date>();
    const [minDueDate, setMinDueDate] = useState<Date>();
    const [messageSwedish, setMessageSwedish] = useState<string>(messageSw || '');
    const [messageEnglish, setMessageEnglish] = useState<string>(messageEn || '');
    const [messageFinnish, setMessageFinnish] = useState<string>(messageFi || '');
    const [isPublic, setIsPublic] = useState<boolean>(() => propOr(false, 'IsPublic', data));
    const [isInternal, setIsInternal] = useState<boolean>(() => propOr(false, 'IsInternal', data));
    const [duration, setDuration] = useState<number | null>(getDuration(data));
    const [dirty, setDirty] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBulletin',
        'AnelmaGeneral',
        'AnelmaCommunication',
    ]);
    const [searchMsgGroupsDialog, setSearchMsgGroupsDialog] = useState<boolean>(false);
    const [selectedMsgGroups, setSelectedMsgGroups] = useState<IContactPersonSummary[]>(() =>
        propOr([], 'ReceiverUsers', data)
    );
    const [selectedAnnouncementSearches, setSelectedAnnouncementSearches] = useState<
        IAnnouncementSearch[]
    >(() => propOr([], 'AnnouncementSearches', data));
    const [newCommunicationGroupName, setNewCommunicationGroupName] = useState<string>('');

    const [recipientsDialogItems, setRecipientsDialogItems] = useState<
        IPredefinedSearchFarmResult[]
    >([]);
    const [recipientsParams, setRecipientsParams] = useState<IParameterExtension[]>([]);
    const [recipientsPersonParams, setRecipientsPersonParams] = useState<IParameterExtension[]>([]);
    const [recipientsOpen, setRecipientsOpen] = useState<boolean>(false);
    const [thereArePersonsConnectedWithFarms, setThereArePersonsConnectedWithFarms] =
        useState<boolean>(false);
    const [searchGroups, setSearchGroups] = useState<IPredefinedSearch[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        paramRepository.load(['AnelmaCompany']).then(() => {
            let params: IParameterExtension[] = [];
            params.push({
                name: 'FarmingTypeBovine',
                options: paramRepository.resource('AnelmaCompany', 'FarmingTypeBovine'),
            });
            params.push({
                name: 'FarmingTypePig',
                options: paramRepository.resource('AnelmaCompany', 'FarmingTypePig'),
            });
            params.push({
                name: 'Species',
                options: paramRepository.resource('AnelmaCompany', 'Species'),
            });
            setRecipientsParams(params);
        });

        paramRepository.load(['AnelmaGeneral']).then(() => {
            let paramsForPersons: IParameterExtension[] = [];
            paramsForPersons.push({
                name: 'Titles',
                options: paramRepository.resource('AnelmaGeneral', 'CommunicationGroupTitles'),
            });
            setRecipientsPersonParams(paramsForPersons);
        });

        setLoading(true);
        const getGroups = searchParametersApi.getAllPredefinedSearches().then((response) => {
            if (!response) return;
            setSearchGroups(response.Items);

            let isOneOrMoreFromPersonCommunicationGroup: boolean = false;
            if (
                !Array.isArray(data?.AnnouncementSearches) ||
                data?.AnnouncementSearches.length === 0
            ) {
                isOneOrMoreFromPersonCommunicationGroup = true;
            } else {
                const searchArr = data?.AnnouncementSearches ? data?.AnnouncementSearches : [];
                for (const announcementSearch of searchArr) {
                    const selectedCommunicationGroup = response.Items.find(
                        (x) => x.GUID === announcementSearch.SearchGUID
                    );
                    if (
                        isUsingPersonsConnectedToTheFarm(selectedCommunicationGroup?.Parameters) ===
                        true
                    ) {
                        isOneOrMoreFromPersonCommunicationGroup = true;
                    }
                }
            }
            setThereArePersonsConnectedWithFarms(isOneOrMoreFromPersonCommunicationGroup);
        });
        Promise.all([getGroups]).then(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (data) {
            setEdit(true);
        }
    }, []);

    useEffect(() => {
        const valid = validFromDate?.isBefore(validDueDate) || false;
        validator.setState('dates', valid, (valid) => {
            if (!valid) {
                enqueueSnackbar(t('AnelmaBulletin:1026'), {
                    variant: 'error',
                });
            }
        });
    }, [validator, validFromDate, validDueDate, enqueueSnackbar, t]);

    useEffect(() => {
        const valid = !!messageSwedish || !!messageEnglish || !!messageFinnish;
        validator.setState('messages', valid, (valid) => {
            if (!valid) {
                enqueueSnackbar(t('AnelmaBulletin:1027'), {
                    variant: 'error',
                });
            }
        });
    }, [validator, messageSwedish, messageEnglish, messageFinnish, enqueueSnackbar, t]);

    useEffect(() => {
        const valid = isPublic || isInternal;
        validator.setState('visibility', valid, (valid) => {
            if (!valid) {
                enqueueSnackbar(t('AnelmaBulletin:1028'), {
                    variant: 'error',
                });
            }
        });
    }, [validator, isPublic, isInternal, enqueueSnackbar, t]);

    function createPayload(): IBulletinItemDetails {
        return {
            Id: propOr('', 'Id', data),
            ValidFromDate: validFromDate?.format() || '',
            ValidDueDate: validDueDate?.format() || '',
            Message: getMessagesPayload(),
            IsPublic: isPublic,
            IsInternal: isInternal,
            Duration: isNil(duration) ? null : duration * 1000,
            ReceiverUsers: selectedMsgGroups,
            AnnouncementSearches: selectedAnnouncementSearches,
        };
    }

    function getMessagesPayload(): IBulletinItemCulture[] {
        var res: IBulletinItemCulture[] = [];

        if (messageFinnish !== '') res.push({ MessageText: messageFinnish, Culture: 'fi-FI' });

        if (messageSwedish !== '') res.push({ MessageText: messageSwedish, Culture: 'sv-SE' });

        if (messageEnglish !== '') res.push({ MessageText: messageEnglish, Culture: 'en-GB' });

        return res;
    }

    function messageIsValid(): boolean {
        if (validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return false;
        }

        return true;
    }

    function updateFromDate(d: ADateValue, type: 'date' | 'time'): void {
        const newValue =
            d === null || !moment(d).isValid() ? null : updateDate(type, d, validFromDate);
        setValidFromDate(newValue);
        setMinDueDate(newValue?.toDate() || undefined);
    }

    function updateDueDate(d: ADateValue, type: 'date' | 'time'): void {
        const newValue =
            d === null || !moment(d).isValid() ? null : updateDate(type, d, validDueDate);
        setValidDueDate(newValue);
        setMaxFromDate(newValue?.toDate() || undefined);
    }

    function updateDate(type: 'date' | 'time', newDate: Date, oldDate: moment.Moment | null) {
        const newDate2 = moment(newDate);
        if (!oldDate) return newDate2;
        const oldDate2 = moment(oldDate);

        type === 'date'
            ? newDate2.hours(oldDate2.hours()).minutes(oldDate2.minutes())
            : newDate2.year(oldDate2.year()).month(oldDate2.month()).date(oldDate2.date());

        return newDate2;
    }

    const isDirty = useCallback((): boolean => {
        return !(
            equals(validFromDate, propOr('', 'ValidFromDate', data)) &&
            equals(validDueDate, propOr('', 'ValidDueDate', data)) &&
            (equals(messageEnglish, messageEn) || (isNil(messageEn) && isEmpty(messageEnglish))) &&
            (equals(messageSwedish, messageSw) || (isNil(messageSw) && isEmpty(messageSwedish))) &&
            (equals(messageFinnish, messageFi) || (isNil(messageFi) && isEmpty(messageFinnish))) &&
            equals(isPublic, propOr(false, 'IsPublic', data)) &&
            equals(isInternal, propOr(false, 'IsInternal', data)) &&
            equals(duration, propOr(null, 'Duration', data))
        );
    }, [
        validFromDate,
        validDueDate,
        messageEnglish,
        messageSwedish,
        messageFinnish,
        isPublic,
        isInternal,
        duration,
        data,
        messageEn,
        messageFi,
        messageSw,
    ]);

    useEffect(() => {
        setDirty(isDirty());
    }, [dirty, isDirty]);

    useEffect(() => {
        if (selectedMsgGroups) {
            resolveRecipientsItems(selectedMsgGroups, selectedAnnouncementSearches);
        }
    }, [selectedMsgGroups]);

    let predefinedFarms: IPredefinedSearchFarmResult[] = [];
    const resolveRecipientsItems = (
        data: IContactPersonSummary[],
        announcementSearches: IAnnouncementSearch[]
    ) => {
        setLoading(true);

        data.flatMap((_) => {
            predefinedFarms.push({
                FarmGuid: _.OwnerId,
                ProducerId: '',
                FarmId: '',
                Name: '',
                ContactPersons: [],
                RecipientName: _.Name,
                ProductionLines: [],
                UserId: _.Id,
                Roles: _.Roles,
            });
        });

        Promise.all(
            Array.from(new Set(data.map((_) => _.OwnerId))).map((_) =>
                companyApi.getCompanyByGuid(_)
            )
        ).then((companyResult) => {
            companyResult.map((company) => {
                if (company)
                    mapCompanyToPredefinedFarms(company.Entity as IFarmDetails, predefinedFarms);
            });

            setRecipientsDialogItems(predefinedFarms);
            setLoading(false);
        });
    };

    function removeDuplicateFarmGuid(
        inputArray: IPredefinedSearchFarmResult[]
    ): IPredefinedSearchFarmResult[] {
        const uniqueIds = new Set<string>();

        return inputArray.filter((obj) => {
            if (!uniqueIds.has(obj.FarmGuid)) {
                uniqueIds.add(obj.FarmGuid);
                return true;
            }
            return false;
        });
    }

    function removeDuplicateUserGuid(inputArray: IAnnouncementSearch[]): IAnnouncementSearch[] {
        const uniqueIds = new Set<string>();

        return inputArray.filter((obj) => {
            if (!uniqueIds.has(obj.UserGUID)) {
                uniqueIds.add(obj.UserGUID);
                return true;
            }
            return false;
        });
    }

    const mapCompanyToPredefinedFarms = (
        farm: IFarmDetails,
        predefinedFarms: IPredefinedSearchFarmResult[]
    ) => {
        for (const _ of predefinedFarms) {
            if (_.FarmGuid === farm.Id) {
                _.ProducerId =
                    farm.Aliases.find((_) => _.Type === AliasTypeEnum.ProducerNumber)?.Value ?? '';
                _.FarmId = farm.Aliases.find((_) => _.Type === AliasTypeEnum.FarmId)?.Value ?? '';
                _.Name = farm.Names.find((_) => _.IsDefault)?.Value ?? '';
                _.ProductionLines = farm.ProductionLines;
            }
        }
    };

    const resolveReceiverTxt = (recipDialogItems: IPredefinedSearchFarmResult[]) => {
        return loading
            ? ''
            : t('AnelmaBulletin:1032', {
                  RecieverCount: recipDialogItems.length,
              });
    };

    const msgGroupBtnIsVisible = () => {
        return !isPublic && auth.canViewAnelmaCommunicationGroups;
    };

    const bulletinFormBtns: ADialogButtonsProp = {
        left:
            selectedMsgGroups.length > 0 &&
            auth.canViewAnelmaCommunicationGroups &&
            msgGroupBtnIsVisible()
                ? [
                      {
                          onClick: onClose,
                          type: 'cancel',
                      },
                      {
                          type: 'with-label',
                          label: t('AnelmaCommunication:1050'),
                          onClick: () => setRecipientsOpen(true),
                          leftSideText: resolveReceiverTxt(
                              thereArePersonsConnectedWithFarms
                                  ? recipientsDialogItems
                                  : removeDuplicateFarmGuid(recipientsDialogItems)
                          ),
                          disabled: loading,
                      },
                  ]
                : [
                      {
                          onClick: onClose,
                          type: 'cancel',
                      },
                  ],
        right: [
            {
                onClick: () => {
                    const message = createPayload();
                    if (!isDirty()) {
                        onClose();
                    } else if (messageIsValid()) {
                        const announcementSearchWithoutDuplicates: IAnnouncementSearch[] =
                            removeDuplicateUserGuid(
                                message.AnnouncementSearches ? message.AnnouncementSearches : []
                            );
                        message.AnnouncementSearches = announcementSearchWithoutDuplicates;

                        const receiverUsersWithoutDuplicates: IContactPersonSummary[] =
                            removeDuplicateIds(message.ReceiverUsers);
                        message.ReceiverUsers = receiverUsersWithoutDuplicates;
                        onSave(bulletinRequestBuilder(message, data));

                        enqueueSnackbar(t('AnelmaGeneral:1018'), {
                            variant: 'success',
                        });

                        onClose();
                    }
                },
                type: 'save',
            },
        ],
    };

    function removeDuplicateIds(inputArray: IContactPersonSummary[]): IContactPersonSummary[] {
        const uniqueIds = new Set<string>();

        return inputArray.filter((obj) => {
            if (!uniqueIds.has(obj.Id)) {
                uniqueIds.add(obj.Id);
                return true;
            }
            return false;
        });
    }

    const onSearchMessageGroupDialogSelect = (persons: IContactPersonRow[]) => {
        if (persons.length) {
            setThereArePersonsConnectedWithFarms(persons[0].usePersonsConnectedToTheFarm);
            setNewCommunicationGroupName(persons[0].CommunicationGroupName);
            const selectedSearchGUID = persons[0].SearchId;

            const announcementSearches: IAnnouncementSearch[] = [];
            const contactPersonSummary: IContactPersonSummary[] = [];

            if (persons[0].usePersonsConnectedToTheFarm) {
                for (const onePerson of persons) {
                    announcementSearches.push({
                        AnnouncementGUID: EmptyGUID,
                        UserGUID: onePerson.ContactPersonId ? onePerson.ContactPersonId : '',
                        SearchGUID: onePerson.SearchId,
                    });
                }

                const allAnnouncementSearches = [
                    ...selectedAnnouncementSearches,
                    ...announcementSearches,
                ];
                setSelectedAnnouncementSearches(allAnnouncementSearches);

                for (const person of persons) {
                    if (
                        selectedMsgGroups.findIndex((_) => _.Id === person.ContactPersonId) === -1
                    ) {
                        contactPersonSummary.push({
                            Id: person.ContactPersonId ? person.ContactPersonId : '',
                            OwnerId: person.FarmId,
                            Type: 1,
                            Name: person.Name,
                            Address: '',
                            EmailAddress: '',
                            PhoneNumber: person.PhoneNumber,
                            Aliases: [],
                            Logins: [],
                            Roles: person.Roles ? person.Roles : [],
                        });
                    }
                }
                const allPersons = [...selectedMsgGroups, ...contactPersonSummary];
                setSelectedMsgGroups(allPersons);
            } else {
                const farmIdArr: GUIDType[] = [];
                for (const onePerson of persons) {
                    farmIdArr.push(onePerson.FarmId);
                }

                Promise.all(Array.from(farmIdArr.map((x) => companyApi.getCompanyByGuid(x)))).then(
                    (companiesResult) => {
                        for (const company of companiesResult) {
                            const farmGUID = company?.Entity.Id;

                            if (company?.Entity.ContactPersons) {
                                for (const person of company?.Entity.ContactPersons) {
                                    announcementSearches.push({
                                        AnnouncementGUID: EmptyGUID,
                                        UserGUID: person.PersonId,
                                        SearchGUID: selectedSearchGUID,
                                    });

                                    if (
                                        selectedMsgGroups.findIndex(
                                            (_) => _.Id === person.PersonId
                                        ) === -1
                                    ) {
                                        contactPersonSummary.push({
                                            Id: person.PersonId,
                                            OwnerId: farmGUID ? farmGUID : '',
                                            Type: 1,
                                            Name: person.Name,
                                            Address: '',
                                            EmailAddress: '',
                                            PhoneNumber: person.PhoneNumber,
                                            Aliases: [],
                                            Logins: [],
                                            Roles: person.Roles ? person.Roles : [],
                                        });
                                    }
                                }
                            }
                        }

                        const allAnnouncementSearches = [
                            ...selectedAnnouncementSearches,
                            ...announcementSearches,
                        ];
                        setSelectedAnnouncementSearches(allAnnouncementSearches);

                        const allPersons = [...selectedMsgGroups, ...contactPersonSummary];
                        setSelectedMsgGroups(allPersons);
                    }
                );
            }
        }
    };

    return (
        <ADialog open>
            {isNil(title) ? '' : <ADialogTitle>{title}</ADialogTitle>}

            <ADialogContent size='md'>
                <Grid container sx={{ paddingTop: '10px' }}>
                    <Grid item sm={6} xs={12}>
                        <ADateInput
                            id='valid-from-date'
                            label={t('AnelmaBulletin:1006')}
                            maxDate={maxFromDate}
                            onChange={(d) => updateFromDate(d, 'date')}
                            required
                            validator={validator}
                            value={validFromDate?.toDate() || null}
                        />
                        <ATimeInput
                            id='valid-from-time'
                            label={''}
                            onChange={(d) => updateFromDate(d, 'time')}
                            required
                            validator={validator}
                            value={validFromDate?.toDate() || null}
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <ADateInput
                            id='valid-due-date'
                            label={t('AnelmaBulletin:1007')}
                            minDate={minDueDate}
                            onChange={(d) => updateDueDate(d, 'date')}
                            required
                            validator={validator}
                            value={validDueDate?.toDate() || null}
                        />
                        <ATimeInput
                            id='valid-due-time'
                            label={''}
                            onChange={(d) => updateDueDate(d, 'time')}
                            required
                            validator={validator}
                            value={validDueDate?.toDate() || null}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item sm={12}>
                        <ATextarea
                            id='fi'
                            label={t('AnelmaBulletin:1015')}
                            value={messageFinnish}
                            onChange={(v) => setMessageFinnish(v)}
                            validator={validator}
                            lengthBoundaryName='AnelmaBulletin:MessageMaxLength'
                            maxLength={500}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ATextarea
                            id='sv'
                            label={t('AnelmaBulletin:1016')}
                            value={messageSwedish}
                            onChange={(v) => setMessageSwedish(v)}
                            validator={validator}
                            lengthBoundaryName='AnelmaBulletin:MessageMaxLength'
                            maxLength={500}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ATextarea
                            id='en'
                            label={t('AnelmaBulletin:1017')}
                            value={messageEnglish}
                            onChange={(v) => setMessageEnglish(v)}
                            validator={validator}
                            lengthBoundaryName='AnelmaBulletin:MessageMaxLength'
                            maxLength={500}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item sm={12}>
                        <ASwitch
                            checked={isPublic}
                            id='is-public'
                            label={t('AnelmaBulletin:1021')}
                            onChange={(v) => setIsPublic(v)}
                            validator={validator}
                            disabled={edit}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <ASwitch
                            checked={isInternal}
                            id='is-private'
                            label={t('AnelmaBulletin:1020')}
                            onChange={(v) => setIsInternal(v)}
                            validator={validator}
                            disabled={edit}
                        />
                    </Grid>
                </Grid>

                <Grid item sm={12}>
                    <ANumberInput
                        id='duration'
                        label={t('AnelmaBulletin:1019')}
                        min={3}
                        max={10}
                        mode='int'
                        onChange={(v) => {
                            setDuration(v);
                        }}
                        value={duration}
                        validator={validator}
                    />
                </Grid>
                {msgGroupBtnIsVisible() && (
                    <Grid item sm={12}>
                        <AAddButton
                            onClick={() => setSearchMsgGroupsDialog(!searchMsgGroupsDialog)}
                            type='action'
                            style={{ marginLeft: '40px' }}
                        >
                            {t('AnelmaBulletin:1031')}
                        </AAddButton>
                    </Grid>
                )}
            </ADialogContent>

            <ADialogActions buttons={bulletinFormBtns} />

            {searchMsgGroupsDialog && (
                <SearchMessageGroupDialog
                    validator={validator}
                    onSelect={(items) => onSearchMessageGroupDialogSelect(items)}
                    onClose={() => setSearchMsgGroupsDialog(!searchMsgGroupsDialog)}
                />
            )}

            {recipientsOpen && (
                <Recipients
                    onClose={() => setRecipientsOpen(false)}
                    items={
                        thereArePersonsConnectedWithFarms
                            ? recipientsDialogItems
                            : removeDuplicateFarmGuid(recipientsDialogItems)
                    }
                    parameters={recipientsParams}
                    personParameters={recipientsPersonParams}
                    titleParameter={'AnelmaBulletin:1032'}
                    usePersonsConnectedToTheFarm={thereArePersonsConnectedWithFarms}
                    communicationGroups={searchGroups}
                    announcementSearches={
                        data?.AnnouncementSearches ? data.AnnouncementSearches : []
                    }
                    addingNewCommunicationGroupName={newCommunicationGroupName}
                />
            )}
        </ADialog>
    );
}
