import { ProductionPlanAnimalType } from "../../../../interfaces/enums";
import { IPlannedAnimal } from "../../../../interfaces/IProductionPlan";

export class PlannedAnimalMapperBase {
    protected MapToArrayByType(
        a: IPlannedAnimal[],
        type: ProductionPlanAnimalType,
        v: number
        ){
        const target = a.find(x => x.Type === type);
        if(target){
            target.Amount = v;
        }
        else if (v > 0){
            a.push({
                Type: type,
                Amount: v
            });
        }
    };
}