// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// MUI
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

// Interfaces
import { IBreedingContract } from '../interfaces/IBreedingContract';

// Common

import ViewLoader from '../common/ViewLoader';
import { ADataGrid, AGridColumns, gridColumns } from '../common/dataGrid';
import { ViewTitle } from '../common/typography';

// API
import BreedingContractApi from '../api/breedingContractApi';

export interface AnimalBreedingsListViewProps {
    producerNumber: number;
}

export default function AnimalBreedingsListView(props: AnimalBreedingsListViewProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaLayout',
        'AnelmaBreedingContract',
    ]);
    const { producerNumber } = props;

    const columns: AGridColumns = [
        {
            field: 'ContractNumber',
            headerName: t('AnelmaBreedingContract:1003'),
            type: 'string',
            width: 150,
        },
        {
            field: 'ProducerNumber',
            headerName: t('AnelmaBreedingContract:1001'),
            type: 'string',
            width: 150,
        },
        {
            field: 'SaleSumBeforeTaxes',
            headerName: t('AnelmaBreedingContract:1010'),
            type: 'string',
            width: 150,
        },
        {
            field: 'AmountOfAnimals',
            headerName: t('AnelmaBreedingContract:1008'),
            type: 'string',
            width: 150,
        },
        {
            field: 'TransportationNumber',
            headerName: t('AnelmaBreedingContract:1005'),
            type: 'string',
            width: 150,
        },

        gridColumns.dateTime('TransportDate', t('AnelmaBreedingContract:1004'), 'DD.MM.YYYY HH:mm'),

        gridColumns.dateTime(
            'PaymentDeadline',
            t('AnelmaBreedingContract:1006'),
            'DD.MM.YYYY HH:mm'
        ),
        gridColumns.dateTime('SaleDate', t('AnelmaBreedingContract:1007'), 'DD.MM.YYYY HH:mm'),
    ];
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rowsData, setRowsData] = useState<IBreedingContract[]>([]);
    const rows = rowsData.map((i) => mergeRight(i, { id: i.ContractNumber }));

    const { enqueueSnackbar } = useSnackbar();
    const fail = () => {
        enqueueSnackbar(t('AnelmaBreedingContract:1000'), {
            variant: 'info',
        });
    };

    useEffect(() => {
        setIsLoading(true);
        initialData();
    }, [props.producerNumber]);

    const initialData = () => {
        if (producerNumber !== 0) {
            BreedingContractApi.getProducerBreedingContracts(props.producerNumber).then(
                (response) => {
                    if (!response) {
                        return;
                    } else setRowsData(response.Items);
                },
                () => {
                    return;
                }
            );
        }

        setIsLoading(true);
    };
    return (
        <>
            {isLoading ? (
                auth.canViewAnimalBreedings && (
                    <>
                        <Grid container>
                            <ViewTitle>{t('AnelmaLayout:1055')}</ViewTitle>
                        </Grid>
                        <ADataGrid
                            columns={columns}
                            rows={rows.length > 0 ? rows : []}
                            enableFiltering
                            enableRowNumbers
                        ></ADataGrid>
                    </>
                )
            ) : (
                <ViewLoader />
            )}
        </>
    );
}
