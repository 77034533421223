import { ResourceTextApplication } from '../core/resources';
import { useTranslation } from 'react-i18next';

import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../common/dialog';
import { ATextarea } from '../common/inputs';
import FormValidator from '../core/FormValidator';

export interface LogInfoDialogProps {
    onClose: () => any;
    data: string;
}

export default function LogInfoDialog(props: LogInfoDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAudit']);

    return (
        <ADialog open onClose={() => props.onClose()}>
            <ADialogTitle>{t('AnelmaAudit:1061')}</ADialogTitle>

            <ADialogContent size='md'>
                <ATextarea
                    id='text'
                    label=''
                    onChange={() => {}}
                    value={props.data}
                    validator={new FormValidator()}
                />
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onClose(),
                            type: 'cancel',
                        },
                    ],
                    right: [],
                }}
            />
        </ADialog>
    );
}
