// Libraries
import { useEffect, useState } from 'react';

// Core
import { boundaryRepository } from '../../core/boundaries';
import { ResourceBoundaryApplication } from '../../core/resources';

// Common
import { ATextInput, ATextInputProps } from '../../common/inputs';

// Feature
import './textarea.scss';

export interface ATextareaProps extends ATextInputProps {}

export default function ATextarea(props: ATextareaProps) {
    const { lengthBoundaryName, ...textInputProps } = props;
    const value = `${props.value || ''}`;
    const valueLength = value.length || 0;
    (textInputProps as any).multiline = true;

    const [maxLength, setMaxLength] = useState<number | undefined>(props.maxLength);

    useEffect(() => {
        if (!lengthBoundaryName) return;
        const [groupName, valueName] = lengthBoundaryName.split(':', 2);
        const group = groupName as ResourceBoundaryApplication;
        boundaryRepository.load([group]).then(() => {
            const params = boundaryRepository.resource(group, valueName);
            setMaxLength(
                Number.isInteger(params.maxValue) ? (params.maxValue as number) : undefined
            );
        });
    }, [lengthBoundaryName]);

    return (
        <div className='an-textarea-container'>
            <ATextInput {...props} data-robot-id={'a-text-area-text-input-' + textInputProps.id} />
            {maxLength !== undefined ? (
                <div className='an-textarea-counter'>
                    {valueLength}/{maxLength}
                </div>
            ) : undefined}
        </div>
    );
}
