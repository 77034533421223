// Libraries
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
// Core
// Common
// Interfaces
import { IInvoice, IInvoiceProducerInfo } from '../../../interfaces/IInvoice';
import { AliasTypeEnum, RoutePaths } from '../../../interfaces/enums';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import companyApi from '../../../api/companyApi';
import InvoiceApi from '../../../api/invoiceApi';

// Feature
import InvoiceDetailView from '../InvoiceDetailView';
import ViewLoader from '../../../common/ViewLoader';

/** Used to render InvoiceDetailView when clicked notification X when pathname equals RoutePaths.InvoiceDetailView.
 *
 * ***If you find a better way to achieve this then obliterate the component.***
 */
export default function InvoiceDetailViewNotification() {
    const location = useLocation();
    const navigate = useNavigate();

    const context = useAppSelector((state) => state.context);

    const [loading, setLoading] = useState<boolean>(false);
    const [producerInfoContext, setProducerInfoContext] = useState<IInvoiceProducerInfo>();
    const [invoice, setInvoice] = useState<IInvoice>();
    const [invoiceNumber] = useState<number | null>(
        location.state !== null ? location.state.options.invoiceNumber : null
    );

    useEffect(() => {
        if (invoiceNumber) InvoiceApi.getInvoice(invoiceNumber).then((_) => setInvoice(_.Entity));
    }, [invoiceNumber]);

    useEffect(() => {
        const currentContext = context.data.currentContext;
        if (currentContext) {
            setLoading(true);
            companyApi.getCompanyByContext(currentContext.context).then((response) => {
                const producerNumberContext = response?.Entity.Aliases.find(
                    (i) => i.Type === AliasTypeEnum.ProducerNumber
                );
                setProducerInfoContext({
                    ProducerNumber: producerNumberContext?.Value as unknown as number,
                    ProducerName: response?.Entity.Names.find((i) => i.IsDefault)?.Value as string,
                    ProducerYTunnus: response?.Entity.BusinessCode.Value as string,
                    ProducerAddress: response?.Entity.Addresses.find((i) => i.IsDefault)
                        ?.NormalizedAddress as string,
                });
                setLoading(false);
            });
        }
    }, [context]);

    return !loading ? (
        invoice && producerInfoContext ? (
            <InvoiceDetailView
                producerdata={producerInfoContext}
                rowIdData={invoice}
                onClose={() => navigate(RoutePaths.EconomyInvoices)}
            ></InvoiceDetailView>
        ) : null
    ) : (
        <ViewLoader />
    );
}
