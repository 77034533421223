import { Stack } from '@mui/system';
import { CircularProgress } from '@mui/material';

export const CustomLoadingOverlay = () => {
    return (
        <Stack height='100px' alignItems='center' justifyContent='center'>
            <CircularProgress />
        </Stack>
    );
};
