import { useTranslation } from 'react-i18next';
import { ICompanyDetails, IPersonDetails } from '../../interfaces/IBusinessEntities';
import { dayFormat } from '../../core/constants';
import { ResourceTextApplication } from '../../core/resources';
import moment from 'moment';
import APanel from '../../common/APanel';

export interface InactivationInfoFormProps {
    data: IPersonDetails | ICompanyDetails;
}

export function InactivationInfoForm(props: InactivationInfoFormProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaPerson']);

    const effectiveToStr =
        props.data.EffectiveTo != undefined ? moment(props.data.EffectiveTo).format(dayFormat) : '';

    return (
        <APanel
            title={
                props.data.EffectiveTo != undefined
                    ? `${t('AnelmaPerson:1034')} ${effectiveToStr}`
                    : ''
            }
            variant='warning'
        ></APanel>
    );
}
