// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { clone } from 'ramda';

// MUI
// Core
import { ParameterResource, ResourceTextApplication } from '../core/resources';
import utils from '../core/utils';
import auth from '../core/authorization';
import FormValidator from '../core/FormValidator';
import log from '../core/log';
import defaultData from '../core/defaultData';

// Common
import {
    ADialog,
    ADialogButtonsProp,
    ADialogActions,
    ADialogContent,
    ADialogTitle,
} from '../common/dialog';

// Interfaces
import {
    ICompanyDetails,
    ITrafficContractorDetails,
    IFarmDetails,
    IContactPerson,
    ICar,
} from '../interfaces/IBusinessEntities';
import { AliasTypeEnum, CompanyEntityTypeEnum, UserDataEntityTypeEnum } from '../interfaces/enums';
import { FormMode } from '../interfaces/IForm';
import { IApiResult, IUpdatePayload } from '../interfaces/IGeneral';

// Store
// API
// Feature - Access management
import accessManagementApi from '../api/accessManagementApi';

// Feature
import companyApi from '../api/companyApi';
import FarmForm from './FarmForm';
import CompanyForm from './CompanyForm';
import InActivationForm from './InActivationForm';
import updateDataBuilder from './updateDataBuilder';
import ContactPersons, { AccessRoleChanges } from './contactPersons/ContactPersons';
import CompanyCarsView from './cars/CompanyCarsView';
import { isIbanLengthValid } from './helpers/bankAccountHelper';
import { getUsername } from './contactPersons/contactPersons.utils';

export interface CompanyInfoDialogProps {
    mode: FormMode;
    onClose: () => void;
    companyId?: string;
    companyType: CompanyEntityTypeEnum;
    farmResources: ParameterResource;
}

export default function CompanyInfoDialog({
    mode,
    onClose,
    companyId,
    companyType,
    farmResources,
}: CompanyInfoDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany', 'AnelmaGeneral']);
    const { enqueueSnackbar } = useSnackbar();
    const formValidator = new FormValidator();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [details, setDetails] = useState<ICompanyDetails>();
    const [originalDetails, setOriginalDetails] = useState<ICompanyDetails>();
    const [accessRoleChanges, setAccessRoleChanges] = useState<AccessRoleChanges[]>([]);
    const [inactivateFormVisible, setInactivateFormVisible] = useState<boolean>(false);
    const [contactPersons, setContactPersons] = useState<IContactPerson[]>([]);

    useEffect(() => {
        setIsLoading(true);

        if (companyId) loadCompanyData(companyId);
        else setIsLoading(false);
    }, []);

    useEffect(() => {
        if (details && details.BusinessForm?.Type === null) {
            const withoutBusinessForm = { ...details } as Partial<ICompanyDetails>;
            delete withoutBusinessForm.BusinessForm;
            setDetails(withoutBusinessForm as ICompanyDetails);
        }
    }, [details]);

    useEffect(() => {
        if (mode === 'create') initializeDetailsForCreateMode();
    }, [mode]);

    useEffect(() => {
        if (contactPersons.length > 0) initializeContactPersonData();
    }, [contactPersons]);

    const initializeContactPersonData = () => {
        const newAccessRoleChanges = [...accessRoleChanges];
        const promises: any[] = [];
        setIsLoading(true);

        contactPersons.forEach((person) => {
            const username = getUsername(person);

            if (!username) return;
            if (accessRoleChanges.find((i) => i.username === username)) return;

            newAccessRoleChanges.push({
                disabled: true,
                newRoles: [],
                oldRoles: [],
                username,
            });

            promises.push(
                accessManagementApi.getAccessRoles(username).then(
                    (response) => {
                        if (!response) return;

                        const contextAlias =
                            details?.Aliases?.find((i) => i.Type === AliasTypeEnum.Context)
                                ?.Value || '';

                        const adminRoles = companyType === CompanyEntityTypeEnum.Snellman;
                        const index = newAccessRoleChanges.findIndex(
                            (i) => i.username === username
                        );

                        const scopedRoles =
                            response.Entity.contextScopedRoles.find(
                                (c) =>
                                    (c.contextType === UserDataEntityTypeEnum.FarmContext ||
                                        c.contextType === UserDataEntityTypeEnum.CompanyContext) &&
                                    utils.context.farmContextStringsMatch(
                                        contextAlias,
                                        c.contextName
                                    )
                            )?.roles || [];

                        newAccessRoleChanges[index].disabled = false;
                        newAccessRoleChanges[index].newRoles = adminRoles
                            ? [...response.Entity.roles]
                            : [...scopedRoles];
                        newAccessRoleChanges[index].oldRoles = adminRoles
                            ? [...response.Entity.roles]
                            : [...scopedRoles];
                    },
                    () => {
                        setAccessRoleChanges(newAccessRoleChanges);
                        log('error', `User\'s access role was not found.`, { username: username });
                    }
                )
            );
        });

        Promise.all(promises).then(() => setIsLoading(false));
        setAccessRoleChanges(newAccessRoleChanges);
    };

    const initializeDetailsForCreateMode = () => {
        switch (companyType) {
            case CompanyEntityTypeEnum.Farm:
                setDetails(defaultData.farmDetails());
                break;
            case CompanyEntityTypeEnum.TrafficContractor:
                setDetails(defaultData.trafficContractorDetails());
                break;
            default:
                setDetails(defaultData.companyDetails(companyType));
                break;
        }
    };

    const loadCompanyData = (id: string) => {
        const onFail = () => {
            enqueueSnackbar(t('AnelmaGeneral:1020'), {
                variant: 'error',
            });
            setIsLoading(false);
            onClose();
        };

        const companyPromise = companyApi.getCompanyByGuid(id).then((response) => {
            if (!response) {
                onFail();
                return;
            }

            utils.addUniqueIdForArrayItems(response.Entity.Addresses);
            utils.addUniqueIdForArrayItems(response.Entity.EmailAddresses);
            utils.addUniqueIdForArrayItems(response.Entity.PhoneNumbers);
            if (companyType === CompanyEntityTypeEnum.Farm)
                utils.addUniqueIdForArrayItems((response.Entity as IFarmDetails).HoldingSites);
            setDetails(response.Entity);
            setOriginalDetails(clone(response.Entity));
            setContactPersons(response.Entity.ContactPersons);
        });

        Promise.all([companyPromise]).then(() => setIsLoading(false));
    };

    /** Depending on the given dialog mode get the value of dialog title txt by taking use of resource app.
     *
     * Changed on the JIRA ticket http://jira.mtech.fi/browse/AN-2231*/
    const getDialogTitleTxt = () => {
        const dialogInCreateMode = mode === 'create';

        switch (companyType) {
            case CompanyEntityTypeEnum.Farm:
                return dialogInCreateMode ? t(`AnelmaCompany:1075`) : t(`AnelmaCompany:1076`);
            case CompanyEntityTypeEnum.TrafficContractor:
                return dialogInCreateMode ? t(`AnelmaCompany:1096`) : t(`AnelmaCompany:1105`);
            default:
                return dialogInCreateMode ? '' : t(`AnelmaCompany:1098`);
        }
    };

    const invalidAccessRoles = () => {
        for (var i = 0; i < contactPersons.length; i++) {
            if (
                accessRoleChanges.find((x) => x.username === contactPersons[i].Logins[0].Login)
                    ?.newRoles.length === 0
            ) {
                return true;
            }
        }
        return false;
    };

    function onSave() {
        // Enable for debugging:
        // console.log(
        //     'SAVE:',
        //     props.validator.valid,
        //     createDialog ? details : updateDataBuilder(originalDetails, details),
        //     accessRoleChanges
        // );
        // return;

        if (formValidator.invalid && details?.CompanyType !== CompanyEntityTypeEnum.Snellman) {
            // /**AN-769 missing bank-account number*/
            if (
                !isIbanLengthValid(
                    details?.Aliases.find((_) => _.Type === AliasTypeEnum.BankAccount)?.Value ??
                        null
                )
            )
                enqueueSnackbar(t('AnelmaSolmuErrors:1046'), {
                    variant: 'error',
                });
            else
                enqueueSnackbar(t('AnelmaGeneral:1030'), {
                    variant: 'error',
                });
            return;
        }

        if (invalidAccessRoles()) {
            enqueueSnackbar(t('AnelmaCompany:1128'), {
                variant: 'error',
            });
            return;
        }

        // http://jira.mtech.fi/browse/AN-2222
        const updatedDetails = { ...details };
        updatedDetails.ContactPersons = contactPersons;

        const updateData =
            mode === 'create'
                ? null
                : updateDataBuilder(
                      originalDetails as ICompanyDetails,
                      updatedDetails as ICompanyDetails
                  );

        if (
            updateData &&
            updateData.Additions.length === 0 &&
            updateData.Deletions.length === 0 &&
            updateData.Updates.length === 0 &&
            accessRoleChanges.length === 0
        ) {
            enqueueSnackbar(t('AnelmaGeneral:1080'), {
                variant: 'info',
            });
            return;
        }

        setIsLoading(true);
        const onFail = () => {
            enqueueSnackbar(t('AnelmaGeneral:1019'), {
                variant: 'error',
            });
            setIsLoading(false);
        };

        const companyPromise =
            mode === 'create'
                ? companyApi.createCompany(updatedDetails as ICompanyDetails)
                : companyApi.updateCompany(updateData as IUpdatePayload<ICompanyDetails>);

        /**AN-880 */
        let contextType = UserDataEntityTypeEnum.FarmContext;
        if (updatedDetails.CompanyType === CompanyEntityTypeEnum.TrafficContractor)
            contextType = UserDataEntityTypeEnum.CompanyContext;

        // NOTE: On creation we have to wait for the company to be saved, otherwise the context privilege update will fail.
        const accessRolePromise = new Promise<IApiResult<boolean> | null>((resolve, reject) => {
            companyPromise.then((r1) => {
                if (!r1) {
                    resolve(null);
                    reject();
                } else {
                    let company = r1.Entity;

                    if (company)
                        accessManagementApi
                            .updateUsersAccessRoles(
                                accessRoleChanges,
                                contextType,
                                getContextValue(company)
                            )
                            .then((r2) => {
                                resolve(r2);
                                if (!r2) reject();
                            });
                }
            });
        });

        Promise.all([companyPromise, accessRolePromise]).then(
            (response) => {
                if (response.some((r) => r == null)) {
                    onFail();
                    return;
                }

                onClose();
                enqueueSnackbar(t('AnelmaGeneral:1018'), {
                    variant: 'success',
                });
            },
            () => onFail()
        );
    }

    /** Get context value depending on the use case.
     *
     * Moved here and changed some logic when working with the ticket AN-2316
     *
     * @param company ICompanyDetails
     * @returns
     */
    const getContextValue = (company: ICompanyDetails) => {
        if (company.CompanyType === CompanyEntityTypeEnum.Snellman) return undefined;

        switch (mode) {
            case 'create':
                return company.Aliases.find((a) => a.Type === AliasTypeEnum.Context)?.Value || '-';
            case 'modify':
                return (
                    company.Aliases.find((a) => a.Type === AliasTypeEnum.Context)?.Value.split(
                        '---'
                    )[1] || '-'
                );
        }
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => onSave(),
                type: 'save',
                disabled: isLoading,
            },
        ],
    };

    if (mode === 'modify' && auth.canInactivateCompany) {
        //activate button
        if (originalDetails?.Inactivated) {
            footerButtons.left.push({
                onClick: () => {
                    setIsLoading(true);
                    setInactivateFormVisible(true);
                    setIsLoading(false);
                },
                label: t('AnelmaCompany:1037'),
                type: 'default',
            });
        }
        //inactivate button
        else if (!originalDetails?.Inactivated) {
            footerButtons.left.push({
                onClick: () => {
                    setIsLoading(true);
                    setInactivateFormVisible(true);
                    setIsLoading(false);
                },
                label: t('AnelmaCompany:1036'),
                type: 'default',
            });
        }
    }

    // #region http://jira.mtech.fi/browse/AN-2222
    const addContactPerson = (value: IContactPerson) => {
        const data = [...contactPersons];

        if (!data.find((c) => c.PersonId === value.PersonId)) {
            data.push(value);
            setContactPersons(data);
        } else {
            enqueueSnackbar(t('AnelmaCompany:1126'), {
                variant: 'warning',
            });
        }
    };

    const removeContactPerson = (person: IContactPerson) => {
        const data = [...contactPersons];
        const index = data.findIndex((i) => i.PersonId === person.PersonId);
        const wasDefault = data[index].IsDefault;
        data.splice(index, 1);
        if (wasDefault && data.length) data[0].IsDefault = true;
        setContactPersons(data);
    };

    const setDefaultContactPerson = (person: IContactPerson) => {
        const data = [...contactPersons];
        data.forEach((p) => (p.IsDefault = p.PersonId === person.PersonId));
        setContactPersons(data);
    };

    const updateContactPersonRoles = (person: IContactPerson) => {
        const contactPersonToUpdate = contactPersons.findIndex(
            (p) => p.PersonId === person.PersonId
        );

        if (contactPersonToUpdate > -1) {
            const data = [...contactPersons];
            data[contactPersonToUpdate] = person;
            setContactPersons(data);
        }
    };

    const updateContactPerson = (contactPerson: IContactPerson) => {
        const data = [...contactPersons];
        const index = data.findIndex((i) => i.PersonId === contactPerson.PersonId);
        if (index === -1) {
            log('error', 'Contact person not found, failed to update UI.', {
                id: `${contactPerson.Id}`,
                personId: contactPerson.PersonId,
            });
            return;
        }
        const person = data[index];
        person.Address = contactPerson.Address;
        person.EmailAddress = contactPerson.EmailAddress;
        person.Name = contactPerson.Name;
        person.PhoneNumber = contactPerson.PhoneNumber;
        setContactPersons(data);
    };

    const renderContactPersons = () => {
        return (
            <ContactPersons
                addContactPerson={(data) => addContactPerson(data)}
                removeContactPerson={(data) => removeContactPerson(data)}
                setDefaultContactPerson={(data) => setDefaultContactPerson(data)}
                setDialogIsLoading={(data) => setIsLoading(data)}
                updateAccessRoles={(data) => setAccessRoleChanges(data)}
                updateContactPerson={(data) => updateContactPerson(data)}
                updateContactPersonRoles={(data) => updateContactPersonRoles(data)}
                contactPersons={contactPersons}
                companyType={companyType}
                companyAlias={mode === 'create' ? [] : details?.Aliases}
                accessRoles={accessRoleChanges}
            />
        );
    };
    // #endregion

    return (
        <ADialog open onClose={() => onClose()}>
            <ADialogTitle>{getDialogTitleTxt()}</ADialogTitle>

            <ADialogContent isLoading={isLoading} size='md'>
                {details?.CompanyType === CompanyEntityTypeEnum.Farm && (
                    <>
                        <CompanyForm
                            data={details}
                            onChange={(v) => setDetails(v)}
                            validator={formValidator}
                        />
                        {!isLoading && renderContactPersons()}
                        <FarmForm
                            data={details as IFarmDetails}
                            mode={mode}
                            onChange={(v) => setDetails(v)}
                            validator={formValidator}
                            inputValues={farmResources}
                        />
                    </>
                )}

                {details?.CompanyType === CompanyEntityTypeEnum.TrafficContractor && (
                    <>
                        <CompanyForm
                            data={details as ITrafficContractorDetails}
                            onChange={(v) => setDetails(v)}
                            validator={formValidator}
                        />
                        {!isLoading && renderContactPersons()}

                        {auth.canViewCompanyCars && (
                            <CompanyCarsView
                                data={(details as ITrafficContractorDetails).Cars}
                                mode={mode}
                                onChange={(cars: ICar[]) => {
                                    const newCarData = {
                                        ...(details as ITrafficContractorDetails),
                                    };
                                    newCarData.Cars = cars;
                                    setDetails(newCarData);
                                }}
                                onClose={() => onClose()}
                            />
                        )}
                    </>
                )}

                {details?.CompanyType === CompanyEntityTypeEnum.Snellman && (
                    <>
                        <CompanyForm
                            data={details}
                            onChange={(v) => setDetails(v)}
                            validator={formValidator}
                        />
                        {!isLoading && renderContactPersons()}
                    </>
                )}
            </ADialogContent>

            {inactivateFormVisible && details ? (
                <ADialog open onClose={() => onClose()}>
                    <ADialogContent isLoading={false} size='md'>
                        <InActivationForm
                            afterSave={() => loadCompanyData(companyId as string)}
                            data={details}
                            onClose={() => setInactivateFormVisible(false)}
                            validator={new FormValidator()}
                        />
                    </ADialogContent>
                </ADialog>
            ) : (
                ''
            )}

            <ADialogActions buttons={footerButtons} isLoading={isLoading} />
        </ADialog>
    );
}
