// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { CircularProgress, Container, Grid } from '@mui/material';

// Common
import { AAddButton } from '../common/buttons';
import useStickyState from '../common/utils/useStickyState';
import { ViewTitle } from '../common/typography';

// Core
import { ResourceTextApplication } from '../core/resources';
import auth, { IAuthorizationResource } from '../core/authorization';

// Feature
import {
    IAccessRole,
    IAccessRoleMemberList,
    IAccessRolePermissions,
    IAccessResourceMap,
} from './IAccessRightsManagement';
import api from '../api/accessManagementApi';
import ResourcePermissions from './ResourcePermissions';
import RoleSelector from './RoleSelector';
import RoleDialog from './RoleDialog';

export default function AccessRightManagement() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaAccessRights',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoaded, setIsLoaded] = useState<boolean | null>(false);
    const [roles, setRoles] = useState<IAccessRole[]>([]);
    const [resourceMap, setResourceMap] = useState<IAccessResourceMap[]>([]);
    const [resourcePermissions, setResourcePermissions] = useState<IAccessRolePermissions[]>([]);
    const [roleMembers, setRoleMembers] = useState<IAccessRoleMemberList[]>([]);
    const [selectedRoleIds, setSelectedRoleIds] = useStickyState<number[]>(
        [],
        'anelma-selected-access-rights-groups'
    );
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [editedRole, setEditedRole] = useState<IAccessRole | null>(null);

    useEffect(() => {
        api.getRoles().then((rolesData) => {
            if (!rolesData) {
                return;
            }
            setRoles(rolesData.Items);
            const roleNames = rolesData.Items.map((i) => i.name);
            const resourceMapPromise = api.getResourceMap();
            const resourcePermissionsPromise = api.getResourcePermissions(roleNames);
            const roleMembersPromise = api.getRoleMembers(roleNames);
            Promise.all([resourceMapPromise, resourcePermissionsPromise, roleMembersPromise]).then(
                (data) => {
                    setResourceMap(data[0]?.Items || []);
                    setResourcePermissions(data[1]?.Items || []);
                    setRoleMembers(data[2]?.Items || []);
                    setIsLoaded(true);
                },
                () => setIsLoaded(null)
            );
        });
    }, []);

    function updatePermission(roleName: string, resource: IAuthorizationResource) {
        const resourcePermission = resourcePermissions.find((i) => i.role === roleName);
        if (!resourcePermission) return;
        const index = resourcePermission.permissions.findIndex(
            (i) => i.resource === resource.resource
        );
        index === -1
            ? resourcePermission.permissions.push(resource)
            : (resourcePermission.permissions[index] = resource);
        setResourcePermissions([...resourcePermissions]);
    }

    function openAddDialog() {
        setEditedRole(null);
        setDialogIsOpen(true);
    }

    function openEditDialog(role: IAccessRole) {
        setEditedRole(role);
        setDialogIsOpen(true);
    }

    function afterSaveNew(role: IAccessRole) {
        roles.push(role);
        selectedRoleIds.push(role.id as number);
        setRoles([...roles]);
        setSelectedRoleIds([...selectedRoleIds]);
        constructNewResourcePermissions(role.name);
        showSuccessMessage();
    }

    function afterSaveOld(role: IAccessRole) {
        const index = roles.findIndex((i) => i.id === role.id);
        roles[index] = role;
        setRoles([...roles]);
        showSuccessMessage();
    }

    function showSuccessMessage() {
        enqueueSnackbar(t('AnelmaGeneral:1018'), {
            variant: 'success',
        });
    }

    const constructNewResourcePermissions = (newRoleName: string) => {
        setIsLoaded(false);
        Promise.resolve(api.getResourcePermissions([newRoleName])).then(
            (response) => {
                if (response?.Items) setResourcePermissions((_) => _.concat([...response.Items]));
                setIsLoaded(true);
            },
            () => setIsLoaded(null)
        );
    };

    return (
        <>
            <Container data-robot-id={'app-body-management-access-rights'}>
                <ViewTitle>{t('AnelmaLayout:1006')}</ViewTitle>

                <br />

                {isLoaded ? (
                    <>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <RoleSelector
                                    onChange={(v) => setSelectedRoleIds(v)}
                                    selectedRoleIds={selectedRoleIds}
                                    roles={roles}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                {auth.canCreateAccessRightsGroup && (
                                    <AAddButton onClick={() => openAddDialog()} type='action'>
                                        {t('AnelmaAccessRights:1003')}
                                    </AAddButton>
                                )}
                            </Grid>
                        </Grid>

                        <br />
                        <br />

                        <ResourcePermissions
                            onPermissionUpdate={(
                                roleName: string,
                                resource: IAuthorizationResource
                            ) => updatePermission(roleName, resource)}
                            openEdit={(role) => openEditDialog(role)}
                            resourceMap={resourceMap}
                            resourcePermissions={resourcePermissions}
                            roleMembers={roleMembers}
                            roles={roles.filter((i) => selectedRoleIds.includes(i.id as number))}
                        />
                    </>
                ) : (
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
            </Container>

            {dialogIsOpen ? (
                <RoleDialog
                    afterSaveFail={() => {
                        enqueueSnackbar(t('AnelmaGeneral:1019'), {
                            variant: 'error',
                        });
                    }}
                    afterSaveSuccess={(mode, role) =>
                        mode === 'add' ? afterSaveNew(role) : afterSaveOld(role)
                    }
                    data={editedRole}
                    onClose={() => setDialogIsOpen(false)}
                />
            ) : undefined}
        </>
    );
}
