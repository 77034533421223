import { Grid } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { InfoBlock } from "../../../common/typography";
import { dayFormat } from "../../../core/constants";
import { ResourceTextApplication } from "../../../core/resources";
import { AddressTypeEnum, AliasTypeEnum, CompanyNameTypeEnum } from "../../../interfaces/enums";
import { IFarmDetails } from "../../../interfaces/IBusinessEntities";
import { IProductionPlan } from "../../../interfaces/IProductionPlan";

interface IProps {
    data: IProductionPlan;
    farmDetails?: IFarmDetails | null;
}

export default function PlanHeaderInfo({ data, farmDetails }: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCattleProductionPlan']);
    
    const personAndDate = (person?: string, date?: string) => {
        return (<>
            <span style={{float: 'left'}}>{person || ''}</span>
            <span style={{float: 'right'}}>{date ? moment(date).format(dayFormat) : ''}</span>
        </>);
    }

    return (
        <Grid container direction='row' style={{ paddingLeft: 40, paddingRight: 40, paddingTop: '20px', marginBottom: 50 }}>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                    <InfoBlock
                        label={t('AnelmaCattleProductionPlan:1017')}
                        value={farmDetails?.Names?.find(x => x.Type === CompanyNameTypeEnum.Primary)?.Value}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    {data.Creator && (<InfoBlock
                        label={t('AnelmaCattleProductionPlan:1020')}
                        value={personAndDate(data.Creator, data.ValidFrom)}
                    />)}
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                    <InfoBlock
                        label={t('AnelmaCattleProductionPlan:1018')}
                        value={farmDetails?.Addresses?.find(x => x.Type === AddressTypeEnum.Street)?.NormalizedAddress}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    {data.Approver && (<InfoBlock
                        label={t('AnelmaCattleProductionPlan:1021')}
                        value={personAndDate(data.Approver, data.ApprovedAt)}
                    />)}
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                    <InfoBlock
                        label={t('AnelmaCattleProductionPlan:1019')}
                        value={farmDetails?.Aliases?.find(x => x.Type === AliasTypeEnum.ProducerNumber)?.Value}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    {data.LastVisitor && (<InfoBlock
                        label={t('AnelmaCattleProductionPlan:1022')}
                        value={personAndDate(data.LastVisitor, data.LastVisitDate)}
                    />)}
                </Grid>
            </Grid>
    );
}