// Libraries
import { DateLocalizer, Messages, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/sv';
import { TFunction } from 'i18next';

// Core
import { ResourceTextApplication } from '../core/resources';

interface Localization {
    localizer: DateLocalizer;
    messages: Messages;
}

const calendarLocalization = (t: TFunction<ResourceTextApplication[]>): Localization => {
    const localizer = momentLocalizer(moment);

    return {
        localizer,
        messages: {
            agenda: t('AnelmaGeneral:1122'),
            allDay: t('AnelmaGeneral:1123'),
            date: t('AnelmaGeneral:1124'),
            day: t('AnelmaGeneral:1125'),
            event: t('AnelmaGeneral:1126'),
            month: t('AnelmaGeneral:1127'),
            next: '>',
            noEventsInRange: t('AnelmaGeneral:1128'),
            previous: '<',
            showMore: (count) => t('AnelmaGeneral:1129').replace('{count}', `${count}`),
            time: t('AnelmaGeneral:1130'),
            today: t('AnelmaGeneral:1131'),
            tomorrow: t('AnelmaGeneral:1132'),
            week: t('AnelmaGeneral:1133'),
            work_week: '#työviikko', // NOTE: not in use
            yesterday: t('AnelmaGeneral:1134'),
        },
    };
};

export default calendarLocalization;
