// Libraries
import { useState } from 'react';

// Core
import { IFormValidator } from '../../../core/FormValidator';
import auth from '../../../core/authorization';

// Common
import { ADropdown } from '../../../common/inputs';
import { Grid } from '@mui/material';

export interface AnimalPayloadsSearchTypeProps {
    onSearchTypeChange: (type: string) => void;
    selectedSearchType: string;
    validator: IFormValidator;
}

export const AnimalPayloadsSearchType = (props: AnimalPayloadsSearchTypeProps) => {
    const admin = auth.canViewAdminContextSelector;
    const [selectedSearchType, setSelectedSearchType] = useState<string>(props.selectedSearchType);

    return (
        <>
            {admin && (
                <Grid item xs={2} sm={2} md={2} lg={2}>
                    <ADropdown
                        id='search-type'
                        parameterName={'AnelmaAnimalPayload:SearchTerms'}
                        onChange={(v) => {
                            const type = v as string;
                            setSelectedSearchType(type);
                            props.onSearchTypeChange(type);
                        }}
                        required={false}
                        style={{ minWidth: '160px', paddingTop: '7.5px' }}
                        validator={props.validator}
                        value={selectedSearchType}
                        withoutContainer
                    />
                </Grid>
            )}
        </>
    );
};
