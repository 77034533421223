export type AuthorizationScope = 'CREATE' | 'DELETE' | 'GRANT' | 'LIST' | 'REVOKE' | 'UPDATE';

export type AuthorizationResource =
    | 'AnelmaAccounting'
    | 'AnelmaAccountNumber'
    | 'AnelmaAnimalPayload'
    | 'AnelmaBreedingContract'
    | 'AnelmaBovineBrokerPurchase'
    | 'AnelmaBovineBrokerSales'
    | 'AnelmaAccessRights'
    | 'AnelmaAdminContextSelector'
    | 'AnelmaAudit'
    | 'AnelmaBasicFeatures'
    | 'AnelmaBovine'
    | 'AnelmaBovineOccupancyRate'
    | 'AnelmaBulletin'
    | 'AnelmaCalendar'
    | 'AnelmaCommunicationGroups'
    | 'AnelmaCompany'
    | 'AnelmaCompanyAdmin'
    | 'AnelmaCompanyAccountNumber'
    | 'AnelmaCompanyCar'
    | 'AnelmaCompanyPricingInfo'
    | 'AnelmaCompanyTeam'
    | 'AnelmaContentProduction'
    | 'AnelmaContacts'
    | 'AnelmaCloud'
    | 'AnelmaChainInformation'
    | 'AnelmaChainInformationAdmin'
    | 'AnelmaEvents'
    | 'AnelmaEventEnrolment'
    | 'AnelmaFarmerId'
    | 'AnelmaFarmInfo'
    | 'AnelmaFeedback'
    | 'AnelmaFeedbackAdmin'
    | 'AnelmaHerId'
    | 'AnelmaInvoice'
    | 'AnelmaInstructionFiles'
    | 'AnelmaManagement'
    | 'AnelmaMessage'
    | 'AnelmaMessageAdmin'
    | 'AnelmaNewsFiles'
    | 'AnelmaPerson'
    | 'AnelmaPublicContentManagement'
    | 'AnelmaReporting'
    | 'AnelmaResourceTexts'
    | 'AnelmaTextMessage'
    | 'AnelmaFarmVisit'
    | 'AnelmaFarmVisitAdmin'
    | 'AnelmaFarmVisitView'
    | 'AnelmaUnimplemented'
    | 'AnelmaButcheryReport'
    | 'AnelmaProductionReport'
    | 'AnelmaHealthReport'
    | 'AnelmaFinancialReport'
    | 'AnelmaVariables'
    | 'AnelmaVariablesAdmin'
    | 'AnelmaPriceList'
    | 'AnelmaTaxYearSummary'
    | 'AnelmaBovineSlaugterSales'
    | 'AnelmaBovineSlaugterSalesAdmin'
    | 'AnelmaBovineSalesTypeChange'
    | 'AnelmaBovineNotificationList'
    | 'AnelmaProductionPlanCattle'
    | 'AnelmaBovineGrouping'
    | 'AnelmaProductionPlanAdmin'
    | 'AnelmaFarmFiles'
    | 'AnelmaFarmFilesAdmin'
    | 'AnelmaNotifications'
    | 'Mtech Identity - AccessManagement :: Role';

export interface IAuthorizationResource {
    resource: AuthorizationResource;
    scopes: AuthorizationScope[];
}

export class Authorization {
    private accessList: IAuthorizationResource[] = [];

    get accessListForDebugPurposes(): IAuthorizationResource[] {
        return this.accessList;
    }

    updateAccessList(accessList: IAuthorizationResource[]): void {
        this.accessList = accessList;
    }

    get canCreateAccessRightsGroup(): boolean {
        return this.hasAccess('AnelmaAccessRights', 'CREATE');
    }

    get canCreateBulletinItem(): boolean {
        return this.hasAccess('AnelmaBulletin', 'CREATE');
    }

    get canCreateCalendarEvent(): boolean {
        return this.hasAccess('AnelmaCalendar', 'CREATE');
    }

    get canCreateCommunicationGroups(): boolean {
        return this.hasAccess('AnelmaCommunicationGroups', 'CREATE');
    }

    get canCreateCompany(): boolean {
        return this.hasAccess('AnelmaCompanyAdmin', 'CREATE');
    }

    get canCreateCompanyBankAccount(): boolean {
        return this.hasAccess('AnelmaCompanyAccountNumber', 'CREATE');
    }

    get canCreateCompanyCars(): boolean {
        return this.hasAccess('AnelmaCompanyCar', 'CREATE');
    }

    get canCreateCompanyPricing(): boolean {
        return this.hasAccess('AnelmaCompanyPricingInfo', 'CREATE');
    }

    get canCreateGrouping(): boolean {
        return this.hasAccess('AnelmaBovineGrouping', 'CREATE');
    }

    get canCreateChainInformation(): boolean {
        return this.hasAccess('AnelmaChainInformation', 'CREATE');
    }

    get canCreateCompanyTeamAreaCode(): boolean {
        return this.hasAccess('AnelmaCompanyTeam', 'CREATE');
    }

    get canCreateContactPerson(): boolean {
        return this.hasAccess('AnelmaPerson', 'CREATE');
    }

    get canCreateNewsFile(): boolean {
        return this.hasAccess('AnelmaNewsFiles', 'CREATE');
    }

    get canCreateInstructionFile(): boolean {
        return this.hasAccess('AnelmaInstructionFiles', 'CREATE');
    }

    get canCreatePerson(): boolean {
        return this.hasAccess('AnelmaPerson', 'CREATE');
    }

    get canDeleteCalendarEvent(): boolean {
        return this.hasAccess('AnelmaCalendar', 'DELETE');
    }

    get canDeleteCommunicationGroups(): boolean {
        return this.hasAccess('AnelmaCommunicationGroups', 'DELETE');
    }

    get canDeleteCompanyCars(): boolean {
        return this.hasAccess('AnelmaCompanyCar', 'DELETE');
    }

    get canDeleteCompanyPricing(): boolean {
        return this.hasAccess('AnelmaCompanyPricingInfo', 'DELETE');
    }

    get canDeleteCompanyTeamAreaCode(): boolean {
        return this.hasAccess('AnelmaCompanyTeam', 'DELETE');
    }

    get canDeleteGrouping(): boolean {
        return this.hasAccess('AnelmaBovineGrouping', 'DELETE');
    }

    get canDeleteNewsFile(): boolean {
        return this.hasAccess('AnelmaNewsFiles', 'DELETE');
    }

    get canDeleteInstructionFile(): boolean {
        return this.hasAccess('AnelmaInstructionFiles', 'DELETE');
    }

    get canGrantPermissions(): boolean {
        return (
            this.hasAccess('Mtech Identity - AccessManagement :: Role', 'GRANT') &&
            this.hasAccess('AnelmaAccessRights', 'UPDATE')
        );
    }

    get canInactivateCompany(): boolean {
        return this.hasAccess('AnelmaCompanyAdmin', 'DELETE');
    }

    get canInactivatePerson(): boolean {
        return this.hasAccess('AnelmaPerson', 'DELETE');
    }

    get canRevokePermissions(): boolean {
        return (
            this.hasAccess('Mtech Identity - AccessManagement :: Role', 'REVOKE') &&
            this.hasAccess('AnelmaAccessRights', 'DELETE')
        );
    }

    get canUpdateAccessRightsGroup(): boolean {
        return this.hasAccess('AnelmaAccessRights', 'UPDATE');
    }

    get canUpdateBulletinItem(): boolean {
        return this.hasAccess('AnelmaBulletin', 'UPDATE');
    }

    get canUpdateCalendarEvent(): boolean {
        return this.hasAccess('AnelmaCalendar', 'UPDATE');
    }

    get canUpdateCommunicationGroups(): boolean {
        return this.hasAccess('AnelmaCommunicationGroups', 'UPDATE');
    }

    get canUpdateCompany(): boolean {
        return this.hasAccess('AnelmaCompanyAdmin', 'UPDATE');
    }

    get canUpdateCompanyBankAccount(): boolean {
        return this.hasAccess('AnelmaCompanyAccountNumber', 'UPDATE');
    }

    get canUpdateCompanyCars(): boolean {
        return this.hasAccess('AnelmaCompanyCar', 'UPDATE');
    }

    get canUpdateCompanyPricing(): boolean {
        return this.hasAccess('AnelmaCompanyPricingInfo', 'UPDATE');
    }

    get canUpdateCompanyTeamAreaCode(): boolean {
        return this.hasAccess('AnelmaCompanyTeam', 'UPDATE');
    }

    get canUpdateChainInformation(): boolean {
        return this.hasAccess('AnelmaChainInformation', 'UPDATE');
    }

    get canUpdateChainInformationAdmin(): boolean {
        return this.hasAccess('AnelmaChainInformationAdmin', 'UPDATE');
    }

    get canUpdateContactPerson(): boolean {
        return this.hasAccess('AnelmaPerson', 'UPDATE');
    }

    get canUpdateGrouping(): boolean {
        return this.hasAccess('AnelmaBovineGrouping', 'UPDATE');
    }

    get canUpdatePerson(): boolean {
        return this.hasAccess('AnelmaPerson', 'UPDATE');
    }

    get canViewAccessRightsManagement(): boolean {
        return this.hasAccess('AnelmaAccessRights', 'LIST');
    }

    get canViewAdminContextSelector(): boolean {
        return this.hasAccess('AnelmaAdminContextSelector', 'LIST');
    }

    get canViewBovineList(): boolean {
        return this.hasAccess('AnelmaBovine', 'LIST');
    }

    get canViewGrouping(): boolean {
        return this.hasAccess('AnelmaBovineGrouping', 'LIST');
    }

    get canViewProductionPlanList(): boolean {
        return (
            this.hasAccess('AnelmaProductionPlanCattle', 'LIST') ||
            this.hasAccess('AnelmaProductionPlanAdmin', 'LIST')
        );
    }

    get canViewAnimalPayloads(): boolean {
        return this.hasAccess('AnelmaAnimalPayload', 'LIST');
    }

    get canViewAnimalBreedings(): boolean {
        return this.hasAccess('AnelmaBreedingContract', 'LIST');
    }

    get canViewBasicFeatures(): boolean {
        return this.hasAccess('AnelmaBasicFeatures', 'LIST');
    }

    get canViewBulletinItems(): boolean {
        return this.hasAccess('AnelmaBulletin', 'LIST');
    }

    get canViewButcheryReport(): boolean {
        return this.hasAccess('AnelmaButcheryReport', 'LIST');
    }

    get canViewCalendar(): boolean {
        return this.hasAccess('AnelmaCalendar', 'LIST');
    }

    get canViewChainInformation(): boolean {
        return this.hasAccess('AnelmaChainInformation', 'LIST');
    }

    get canViewCloud(): boolean {
        return this.hasAccess('AnelmaCloud', 'LIST');
    }

    get canViewCommunicationGroups(): boolean {
        return this.hasAccess('AnelmaCommunicationGroups', 'LIST');
    }

    get canViewCompaniesAdmin(): boolean {
        return this.hasAccess('AnelmaCompanyAdmin', 'LIST');
    }

    get canViewCompanyBankAccount(): boolean {
        return this.hasAccess('AnelmaCompanyAccountNumber', 'LIST');
    }

    get canViewCompanyCars(): boolean {
        return this.hasAccess('AnelmaCompanyCar', 'LIST');
    }

    get canViewCompanyPricing(): boolean {
        return this.hasAccess('AnelmaCompanyPricingInfo', 'LIST');
    }

    get canViewCompanyTeamAreaCode(): boolean {
        return this.hasAccess('AnelmaCompanyTeam', 'LIST');
    }

    get canViewEventManagement(): boolean {
        return this.hasAccess('AnelmaEvents', 'LIST');
    }

    get canCreateEvent(): boolean {
        return this.hasAccess('AnelmaEvents', 'CREATE');
    }

    get canModifyEvent(): boolean {
        return this.hasAccess('AnelmaEvents', 'UPDATE');
    }

    get canDeleteEvent(): boolean {
        return this.hasAccess('AnelmaEvents', 'DELETE');
    }

    get canCreateEventEnrolment(): boolean {
        return this.hasAccess('AnelmaEventEnrolment', 'CREATE');
    }

    get canModifyEventEnrolment(): boolean {
        return this.hasAccess('AnelmaEventEnrolment', 'UPDATE');
    }

    get canDeleteEventEnrolment(): boolean {
        return this.hasAccess('AnelmaEventEnrolment', 'DELETE');
    }

    get canViewEconomyAccounting(): boolean {
        return this.hasAccess('AnelmaAccounting', 'LIST');
    }

    get canViewEconomyInvoice(): boolean {
        return this.hasAccess('AnelmaInvoice', 'LIST');
    }

    get canViewEconomyPriceList(): boolean {
        return this.hasAccess('AnelmaPriceList', 'LIST');
    }

    get canViewEconomyTaxYearSummary(): boolean {
        return this.hasAccess('AnelmaTaxYearSummary', 'LIST');
    }

    get canViewFarmInfo(): boolean {
        return this.hasAccess('AnelmaFarmInfo', 'LIST');
    }

    get canViewFeedback(): boolean {
        return this.hasAccess('AnelmaFeedback', 'LIST');
    }

    get canCreateFeedback(): boolean {
        return this.hasAccess('AnelmaFeedback', 'CREATE');
    }

    get canModifyFeedback(): boolean {
        return this.hasAccess('AnelmaFeedback', 'UPDATE');
    }

    get canCreateFeedbackAdmin(): boolean {
        return this.hasAccess('AnelmaFeedbackAdmin', 'CREATE');
    }

    get canModifyFeedbackAdmin(): boolean {
        return this.hasAccess('AnelmaFeedbackAdmin', 'UPDATE');
    }

    get canViewAllFeedback(): boolean {
        return this.hasAccess('AnelmaFeedbackAdmin', 'LIST');
    }

    get canViewFeedingInfo(): boolean {
        return this.hasAccess('AnelmaBasicFeatures', 'LIST');
    }

    get canViewFinancialReport(): boolean {
        return this.hasAccess('AnelmaFinancialReport', 'LIST');
    }

    get canViewGMFreeInfo(): boolean {
        return this.hasAccess('AnelmaBasicFeatures', 'LIST');
    }

    get canViewHealthReport(): boolean {
        return this.hasAccess('AnelmaHealthReport', 'LIST');
    }

    get canViewInstructionsAndCode(): boolean {
        return this.hasAccess('AnelmaBasicFeatures', 'LIST');
    }

    get canViewLabInfo(): boolean {
        return this.hasAccess('AnelmaBasicFeatures', 'LIST');
    }

    get canViewLogs(): boolean {
        return this.hasAccess('AnelmaAudit', 'LIST');
    }

    get canViewMessages(): boolean {
        return this.hasAccess('AnelmaMessage', 'LIST');
    }

    get canViewMPInfo(): boolean {
        return this.hasAccess('AnelmaBasicFeatures', 'LIST');
    }

    get canViewProductionReport(): boolean {
        return this.hasAccess('AnelmaProductionReport', 'LIST');
    }

    get canCreateMessage(): boolean {
        return this.hasAccess('AnelmaMessage', 'CREATE');
    }

    get canAddRestrictedEntityToConversation(): boolean {
        return this.hasAccess('AnelmaMessage', 'CREATE');
    }

    get canAddAnyEntityToConversation(): boolean {
        return this.hasAccess('AnelmaMessageAdmin', 'CREATE');
    }

    get canViewAllMessages(): boolean {
        return this.hasAccess('AnelmaMessageAdmin', 'LIST');
    }

    get canViewNewsDocuments(): boolean {
        return this.hasAccess('AnelmaNewsFiles', 'LIST');
    }

    get canViewInfoDocuments(): boolean {
        return this.hasAccess('AnelmaInstructionFiles', 'LIST');
    }

    get canViewMyInfo(): boolean {
        return this.hasAccess('AnelmaBasicFeatures', 'LIST');
    }

    get canViewPersons(): boolean {
        return this.hasAccess('AnelmaPerson', 'LIST');
    }

    get canViewPublicContentManagement(): boolean {
        return this.hasAccess('AnelmaPublicContentManagement', 'LIST');
    }

    get canViewReporting(): boolean {
        return this.hasAccess('AnelmaReporting', 'LIST');
    }

    get canViewSms(): boolean {
        return this.hasAccess('AnelmaTextMessage', 'LIST');
    }

    get canSendSms(): boolean {
        return this.hasAccess('AnelmaTextMessage', 'CREATE');
    }

    get canViewAllFarmVisits(): boolean {
        return this.hasAccess('AnelmaFarmVisitAdmin', 'LIST');
    }

    get canCreateNewFarmVisit(): boolean {
        return this.hasAccess('AnelmaFarmVisitAdmin', 'CREATE');
    }

    get canUpdateFarmVisit(): boolean {
        return this.hasAccess('AnelmaFarmVisitAdmin', 'UPDATE');
    }

    get canDeleteFarmVisit(): boolean {
        return this.hasAccess('AnelmaFarmVisitAdmin', 'DELETE');
    }

    get canViewFarmVisits(): boolean {
        return this.hasAccess('AnelmaFarmVisit', 'LIST');
    }

    get canViewFarmVisitsProducer(): boolean {
        return this.hasAccess('AnelmaFarmVisit', 'LIST');
    }

    get canViewVariables(): boolean {
        return this.hasAccess('AnelmaVariables', 'LIST');
    }

    get canUpdateVariables(): boolean {
        return this.hasAccess('AnelmaVariables', 'UPDATE');
    }

    get canViewVariablesAdmin(): boolean {
        return this.hasAccess('AnelmaVariablesAdmin', 'LIST');
    }

    get canCreateVariablesAdmin(): boolean {
        return this.hasAccess('AnelmaVariablesAdmin', 'CREATE');
    }
    get canUpdateVariablesAdmin(): boolean {
        return this.hasAccess('AnelmaVariablesAdmin', 'UPDATE');
    }
    get canViewAccountNumber(): boolean {
        return this.hasAccess('AnelmaAccountNumber', 'LIST');
    }

    get canViewHerdId(): boolean {
        return this.hasAccess('AnelmaHerId', 'LIST');
    }

    get canViewFarmId(): boolean {
        return this.hasAccess('AnelmaFarmerId', 'LIST');
    }

    get canViewManagementMenu(): boolean {
        return this.hasAccess('AnelmaManagement', 'LIST');
    }

    get canViewContentManagement(): boolean {
        return this.hasAccess('AnelmaContentProduction', 'LIST');
    }

    get canCreateContentManagement(): boolean {
        return this.hasAccess('AnelmaContentProduction', 'CREATE');
    }

    get canModifyContentManagement(): boolean {
        return this.hasAccess('AnelmaContentProduction', 'UPDATE');
    }

    get canViewAnelmaBovineBrokerPurchase(): boolean {
        return this.hasAccess('AnelmaBovineBrokerPurchase', 'LIST');
    }

    get canCreateAnelmaBovineBrokerPurchase(): boolean {
        return this.hasAccess('AnelmaBovineBrokerPurchase', 'CREATE');
    }

    get canUpdateAnelmaBovineBrokerPurchase(): boolean {
        return this.hasAccess('AnelmaBovineBrokerPurchase', 'UPDATE');
    }

    get canDeleteAnelmaBovineBrokerPurchase(): boolean {
        return this.hasAccess('AnelmaBovineBrokerPurchase', 'DELETE');
    }

    get canViewBovineSlaughterAnnouncementDialog(): boolean {
        return this.hasAccess('AnelmaBovineSlaugterSales', 'LIST');
    }

    get canCreateBovineSlaughterAnnouncement(): boolean {
        return this.hasAccess('AnelmaBovineSlaugterSales', 'CREATE');
    }

    get canUpdateBovineSlaughterAnnouncement(): boolean {
        return this.hasAccess('AnelmaBovineSlaugterSales', 'UPDATE');
    }

    get canDeleteBovineSlaughterAnnouncement(): boolean {
        return this.hasAccess('AnelmaBovineSlaugterSales', 'DELETE');
    }

    get canViewBovineSlaughterAnnouncementDialogAdmin(): boolean {
        return this.hasAccess('AnelmaBovineSlaugterSalesAdmin', 'LIST');
    }

    get canCreateBovineSlaughterAnnouncementAdmin(): boolean {
        return this.hasAccess('AnelmaBovineSlaugterSalesAdmin', 'CREATE');
    }

    get canUpdateBovineSlaughterAnnouncementAdmin(): boolean {
        return this.hasAccess('AnelmaBovineSlaugterSalesAdmin', 'UPDATE');
    }

    get canDeleteBovineSlaughterAnnouncementAdmin(): boolean {
        return this.hasAccess('AnelmaBovineSlaugterSalesAdmin', 'DELETE');
    }

    get canUpdateAnimalSalesType(): boolean {
        return this.hasAccess('AnelmaBovineSalesTypeChange', 'UPDATE');
    }

    get canViewBovineBrokerSales(): boolean {
        return this.hasAccess('AnelmaBovineBrokerSales', 'LIST');
    }

    get canCreateBovineBrokerSales(): boolean {
        return this.hasAccess('AnelmaBovineBrokerSales', 'CREATE');
    }

    get canModifyBovineBrokerSales(): boolean {
        return this.hasAccess('AnelmaBovineBrokerSales', 'UPDATE');
    }

    get canDeleteBovineBrokerSales(): boolean {
        return this.hasAccess('AnelmaBovineBrokerSales', 'DELETE');
    }

    get canViewAnelmaAnimalAnnouncementsList(): boolean {
        return this.hasAccess('AnelmaBovineNotificationList', 'LIST');
    }

    get canViewFillingStatistic(): boolean {
        return this.hasAccess('AnelmaBovineOccupancyRate', 'LIST');
    }

    get canViewAnelmaCommunicationGroups(): boolean {
        return this.hasAccess('AnelmaCommunicationGroups', 'LIST');
    }

    get canViewFarmDocuments(): boolean {
        return this.hasAccess('AnelmaFarmFiles', 'LIST');
    }

    get canCreateFarmDocument(): boolean {
        return this.hasAccess('AnelmaFarmFiles', 'CREATE');
    }

    get canDeleteFarmDocument(): boolean {
        return this.hasAccess('AnelmaFarmFilesAdmin', 'DELETE');
    }

    get canViewNotificationsList(): boolean {
        return this.hasAccess('AnelmaNotifications', 'LIST');
    }

    get canDeleteNotifications(): boolean {
        return this.hasAccess('AnelmaNotifications', 'DELETE');
    }

    hasAccess(resource: AuthorizationResource, scope: AuthorizationScope): boolean {
        const scopes = this.accessList.find((i) => i.resource === resource)?.scopes || [];
        return scopes.includes(scope);
    }
}

export default new Authorization();
