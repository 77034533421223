// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight, isNil } from 'ramda';
import { useSnackbar } from 'notistack';

// MUI
import { Container } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../../core/resources';
import Utils from '../../../core/utils';
import DateUtils from '../../../core/utils.date';

// Common
import { ADataGrid, AGridColumns } from '../../../common/dataGrid';
import { TextWithTooltip } from '../../../common/typography';

// Interfaces
import {
    IAnimalPayloadItem,
    IAnnouncementInfo,
    IBatch,
    ICombinedPayloadBatchRow,
    ICombinedSalesType,
    IGetPayloadMessage,
    IPayloadMessageResult,
} from '../../../interfaces/IAnimal';
import { EmptyGUID } from '../../../interfaces/types';

// Store
// API
import ShortMessageApi from '../../../api/shortMessageApi';

export interface AnimalPayloadsSmsDialogSelectionGridProps {
    batches: IBatch[];
    onGridRowSelection: (selectedBatches: ICombinedPayloadBatchRow[]) => void;
    payloadData: IAnimalPayloadItem;
    loading: boolean;
}

export const AnimalPayloadsSmsDialogSelectionGrid = (
    props: AnimalPayloadsSmsDialogSelectionGridProps
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaAnimalPayload',
    ]);

    const [loading, setLoading] = useState<boolean>(props.loading);
    const [deliveryStateParams, setDeliveryStateParams] = useState<ParameterValues | null>(null);
    const [latestPayloadMessages, setLatestPayloadMessages] = useState<IPayloadMessageResult[]>([]);
    const [paramsInit, setParamsInit] = useState<boolean>(false);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues | null>(null);
    const [selectedCombinedRows, setSelectedCombinedRows] = useState<ICombinedPayloadBatchRow[]>(
        []
    );
    const [combinedRows, setCombinedRows] = useState<ICombinedPayloadBatchRow[]>([]);

    useEffect(() => {
        const promises = [
            paramRepository.load(['AnelmaAnimalAnnouncement', 'AnelmaAnimalPayload', 'AnelmaSMS']),
        ];
        Promise.all(promises).then(
            () => {
                setParamsInit(true);
            },
            () => {
                setParamsInit(false);
                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                    variant: 'error',
                });
            }
        );
    }, []);

    useEffect(() => {
        if (paramsInit) {
            const salesTypes = paramRepository.resource('AnelmaAnimalPayload', 'SalesType');

            if (salesTypes) setSalesTypeParams(salesTypes);

            const deliveryStates = paramRepository.resource('AnelmaSMS', 'DeliveryStates');

            if (deliveryStates) setDeliveryStateParams(deliveryStates);
        }
    }, [paramsInit]);

    useEffect(() => {
        if (props.batches && props.payloadData) {
            setLoading(true);

            const messagesToGet = props.batches
                .map((b) => {
                    return {
                        AnnouncementNumber: b.AnnouncementInfo.AnnouncementNumber,
                        FarmGUID: b.CompanyInfo.Guid,
                        PayloadNumber: props.payloadData.AnimalPayloadNumber,
                    } as IGetPayloadMessage;
                })
                .filter((f) => !Utils.isGuidEmpty(f.FarmGUID));

            ShortMessageApi.getPayloadMessages(messagesToGet).then((response) => {
                if (response?.Items) {
                    setLatestPayloadMessages(response.Items);
                    setLoading(false);
                }
            });

            const tempRows: ICombinedPayloadBatchRow[] = [];

            props.batches.forEach((batch, index) => {
                if (
                    !tempRows.find(
                        (r) =>
                            r.CompanyInfo?.Guid === batch.CompanyInfo?.Guid &&
                            !Utils.isGuidEmpty(r.CompanyInfo?.Guid)
                    )
                ) {
                    const batches = props.batches.filter(
                        (b) => b.CompanyInfo?.Guid === batch.CompanyInfo?.Guid
                    );

                    if (!batches) return;

                    const uniqueSalesTypes: number[] = [];

                    batches.forEach((b) => {
                        if (!uniqueSalesTypes.find((u) => u === b.SalesType)) {
                            uniqueSalesTypes.push(b.SalesType);
                        }
                    });

                    const combinedAnimals: ICombinedSalesType[] = [];
                    for (let salesType of uniqueSalesTypes) {
                        const batchesWithSalesType: IBatch[] = batches.filter(
                            (batch) => batch.SalesType === salesType
                        );

                        const announcements: IAnnouncementInfo[] = [];
                        batchesWithSalesType.forEach((batch) => {
                            if (
                                !announcements.find(
                                    (b) =>
                                        b.AnnouncementNumber ===
                                        batch.AnnouncementInfo.AnnouncementNumber
                                )
                            ) {
                                announcements.push({ ...batch.AnnouncementInfo });
                            }
                        });

                        combinedAnimals.push({
                            AnimalCount: batchesWithSalesType.reduce(
                                (prev, current) => prev + current.AnimalCount,
                                0
                            ),
                            SalesType: salesType,
                            Announcements: announcements,
                        });
                    }

                    tempRows.push({
                        CompanyInfo: batch.CompanyInfo,
                        CombinedSalesTypes: combinedAnimals,
                        PlannedTime: batch.PlannedTime,
                        RowIndex: index,
                    });
                }
            });

            setCombinedRows(tempRows);
        }
    }, [props.batches]);

    useEffect(() => {
        props.onGridRowSelection(selectedCombinedRows);
    }, [selectedCombinedRows]);

    const columns: AGridColumns = [
        {
            field: 'CompanyName',
            headerName: t('AnelmaAnimalPayload:1034'),
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            valueGetter: (params) => {
                const companyInfo = (params.row as IBatch).CompanyInfo;

                return companyInfo?.Name ?? '';
            },
            width: 200,
        },
        {
            field: 'PhoneNumber',
            headerName: t('AnelmaAnimalPayload:1007'),
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            valueGetter: (params) => {
                const companyInfo = (params.row as IBatch).CompanyInfo;

                return isNil(companyInfo?.PhoneNumbers) ? '' : companyInfo?.PhoneNumbers[0];
            },
            width: 200,
        },
        {
            field: 'AnimalCount',
            headerName: t('AnelmaAnimalPayload:1008'),
            valueGetter: (params) => {
                const data = (params.row as ICombinedPayloadBatchRow)?.CombinedSalesTypes;

                if (data.length > 0) {
                    return data.reduce((prev, current) => prev + current.AnimalCount, 0) ?? 0;
                }

                return 0;
            },
        },
        {
            field: 'PlannedTime',
            headerName: t('AnelmaAnimalPayload:1030'),
        },
        {
            field: 'SmsReceived',
            headerName: t('AnelmaAnimalPayload:1006'),
            renderCell: (params) => {
                if (!params.formattedValue) return '';

                const value = DateUtils.formatUtcString(
                    params.formattedValue as string,
                    'DD.MM.YYYY HH:mm'
                );

                return <TextWithTooltip text={value} />;
            },
            valueGetter: (params) => {
                const rowData = params.row as ICombinedPayloadBatchRow;
                const payloadNumber = props.payloadData.AnimalPayloadNumber;
                const guid = rowData?.CompanyInfo?.Guid;

                if (!payloadNumber || !guid) return '';

                for (const st of rowData.CombinedSalesTypes) {
                    const announcements = st.Announcements.map((a) => a.AnnouncementNumber);

                    const found = latestPayloadMessages.find(
                        (l) =>
                            announcements.find((a) => a === l.AnnouncementNumber) &&
                            l.PayloadNumber === payloadNumber &&
                            l.FarmGUID === guid
                    );

                    if (found) return found.MessageDeliveryTime;
                }

                return '';
            },
            width: 150,
        },
        {
            field: 'SmsState',
            headerName: t('AnelmaAnimalPayload:1043'),
            renderCell: (params) => {
                if (!params.formattedValue) return '';

                return <TextWithTooltip text={params.formattedValue as string} />;
            },
            valueGetter: (params) => {
                const rowData = params.row as ICombinedPayloadBatchRow;
                const payloadNumber = props.payloadData.AnimalPayloadNumber;
                const guid = rowData?.CompanyInfo?.Guid;

                if (!payloadNumber || !guid) return '';

                for (const st of rowData.CombinedSalesTypes) {
                    const announcements = st.Announcements.map((a) => a.AnnouncementNumber);

                    const found = latestPayloadMessages.find(
                        (l) =>
                            announcements.find((a) => a === l.AnnouncementNumber) &&
                            l.PayloadNumber === payloadNumber &&
                            l.FarmGUID === guid
                    );

                    if (found) {
                        return (
                            deliveryStateParams?.find(
                                (p) => Number(p.code) === Number(found.MessageDeliveryStateType)
                            )?.text ?? ''
                        );
                    }
                }

                return '';
            },
            width: 150,
        },
    ];

    const dataRows = combinedRows.map((i) => mergeRight(i, { id: i.RowIndex }));

    return (
        <Container disableGutters>
            <ADataGrid
                onSelectionModelChange={(params) =>
                    setSelectedCombinedRows(
                        dataRows
                            .filter((row) => (params as number[]).indexOf(row.RowIndex) > -1)
                            .filter((company) => company.CompanyInfo.Guid !== EmptyGUID)
                    )
                }
                columns={columns}
                enableRowNumbers
                rows={!dataRows ? [] : dataRows}
                loading={loading}
            />
        </Container>
    );
};
