// Interfaces
import { CalendarEvent } from '../interfaces/calendar/CalendarEvent';
import { IUpdatePayload } from '../interfaces/IGeneral';

const update = (
    oldData: any,
    newData: any,
    prop: 'AllDay' | 'DueDate' | 'FromDate' | 'Title' | 'Content' | 'Type',
    data: IUpdatePayload<CalendarEvent>
): void => {
    if (oldData[prop] === newData[prop]) return;
    data.Updates.push({
        Field: prop,
        NewData: newData[prop],
        OldData: oldData[prop],
    });
};

const updateNullable = (
    oldData: any,
    newData: any,
    prop: 'Content',
    data: IUpdatePayload<CalendarEvent>
): void => {
    if (oldData[prop] === newData[prop]) return;
    if (!oldData[prop]) data.Additions.push({ Field: prop, Data: newData[prop] });
    else if (!newData[prop]) data.Deletions.push({ Field: prop, Data: oldData[prop] });
    else update(oldData, newData, prop, data);
};

const updateDataBuilder = (
    oldData: CalendarEvent,
    newData: CalendarEvent
): IUpdatePayload<CalendarEvent> => {
    const data: IUpdatePayload<CalendarEvent> = {
        Additions: [],
        Deletions: [],
        Entity: oldData,
        Updates: [],
    };

    update(oldData, newData, 'AllDay', data);
    update(oldData, newData, 'DueDate', data);
    update(oldData, newData, 'FromDate', data);
    update(oldData, newData, 'Title', data);
    update(oldData, newData, 'Type', data);
    updateNullable(oldData, newData, 'Content', data);

    return data;
};

export default updateDataBuilder;
