// Libraries
import 'date-fns';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { fi } from 'date-fns/locale';

// MUI
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Core
import { IFormValidator } from '../../core/FormValidator';

// Store
// Common
import { AInputContainer } from '../../common/inputs';

const StyledKeyboardDatePicker = styled(DatePicker)(({ theme }) => ({
    width: '100%',
    '& label': {
        color: '#0a4c31',
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderBottom: '1px solid',
        borderRadius: '0',
        borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    zIndex: 0,
}));

export interface ADateInputProps {
    error?: boolean;
    disabled?: boolean;
    id: string;
    label?: string;
    maxDate?: Date;
    minDate?: Date;
    onChange: (value: ADateValue) => any;
    required?: boolean;
    validator: IFormValidator;
    value: ADateValue;
    withoutContainer?: boolean;
}

export type ADateValue = Date | null;

export default forwardRef(function ADateInput(props: ADateInputProps, ref: ForwardedRef<any>) {
    const {
        error,
        id,
        onChange,
        required,
        minDate,
        maxDate,
        validator,
        value,
        withoutContainer,
        ...propsForDatePicker
    } = props;

    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<ADateValue>(value);
    const [err, setErr] = useState<boolean>(false);

    useEffect(() => {
        setVal(value);
    }, [value]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (required && val === null) return false;
            if (maxDate != undefined && value != null && value > maxDate) {
                return false;
            }
            if (minDate != undefined && value != null && value < minDate) {
                return false;
            }
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setErr(!isValidAfterTouch());
    }, [error, id, required, touched, val, validator]);

    const datePickerProps: DatePickerProps<Date> = propsForDatePicker;
    datePickerProps.onChange = (d) => {
        setTouched(true);
        setVal(d);
        onChange(d);
    };
    datePickerProps.value = val;

    const input = (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
            <StyledKeyboardDatePicker
                onChange={(d: unknown) => {
                    setTouched(true);
                    setVal(d as ADateValue);
                    onChange(d as ADateValue);
                }}
                value={val}
                format='dd.MM.yyyy'
                label={props.label}
                disabled={props.disabled}
                minDate={props.minDate}
                maxDate={props.maxDate}
            ></StyledKeyboardDatePicker>
        </LocalizationProvider>
    );

    return withoutContainer ? input : <AInputContainer>{input}</AInputContainer>;
});
