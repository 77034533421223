// Libraries
import { useEffect, useState } from 'react';

// MUI
import { Grid, Typography } from '@mui/material';
import { MoreHoriz, Warning } from '@mui/icons-material';

// Core
// Common
// Interfaces
// Store
// API
// Feature - Animal Carousel
import { ACarouselAdditionalBoxInfo } from './ACarousel';
import './carousel.scss';

export interface ACarouselDropdownItem {
    code: number;
    count: number;
    isDefaultSelection?: boolean;
    text: string;
}

export interface ACarouselItemProps {
    additionalIcon: ACarouselAdditionalBoxInfo[];
    currentlySelectedCarouselId: number;
    dropdownOptions: ACarouselDropdownItem[];
    index: number;
    additionalText: string;
    isDropdownOpen: boolean;
    onDropdownToggle: (v: number | null) => void;
    onItemSelection: (itemIndex: number, selectionIndex: number) => void;
    onDropdownSelection: (boxChoice: number, selected: boolean) => void;
    slaughterAnnouncementCount: number;
    intermediationAnnouncementCount: number;
    bovinePurchaseAnnouncementCount: number;
    slaughterAnnouncementAnimalCount: number;
    intermediationAnnouncementAnimalCount: number;
    bovinePurchaseAnnouncementAnimalCount: number;
}

export default function ACarouselItem(props: ACarouselItemProps) {
    const [topText, setTopText] = useState<string>('');
    const [bottomText, setBottomText] = useState<string>('');
    const [defaultItem, setDefaultItem] = useState<ACarouselDropdownItem | null>(null);
    const [optionToUpdate, setOptionToUpdate] = useState<ACarouselDropdownItem | null>(null);
    const [hoveredDropdown, setHoveredDropdown] = useState<number | null>(null);
    const [hoveredMainOption, setHoveredMainOption] = useState<boolean>(false);

    useEffect(() => {
        const defaultValue = props.dropdownOptions.find((f) => f.isDefaultSelection);

        if (defaultValue && defaultItem === null) {
            setDefaultItem(defaultValue);
        }
    }, [props.dropdownOptions]);

    useEffect(() => {
        if (defaultItem != null) {
            setTopText(defaultItem.count + '');
            setBottomText(defaultItem.text);
        }
    }, [defaultItem]);

    useEffect(() => {
        if (optionToUpdate != null) {
            props.onItemSelection(props.index, optionToUpdate.code);
            setOptionToUpdate(null);
        }
    }, [optionToUpdate]);

    // Don't understand the logical reasoning behind this function. But decided to change the function name to avoid ambiguity http://jira.mtech.fi/browse/AN-1309
    const handleItemClick = (option: ACarouselDropdownItem | null) => {
        if (option != null) setOptionToUpdate(option);
        if (option !== defaultItem) props.onDropdownToggle(props.index);
        props.onDropdownSelection(props.index, true);
    };

    const getDropdownText = (item: ACarouselDropdownItem): string => {
        let text = item.text;

        if (item.text.includes('{1}')) {
            if (item.code === 17) {
                text = text.replace('{0}', props.slaughterAnnouncementCount.toString());
                text = text.replace('{1}', props.slaughterAnnouncementAnimalCount.toString());
            } else if (item.code === 22) {
                text = text.replace('{0}', props.intermediationAnnouncementCount.toString());
                text = text.replace('{1}', props.intermediationAnnouncementAnimalCount.toString());
            } else if (item.code === 23) {
                text = text.replace('{0}', props.bovinePurchaseAnnouncementCount.toString());
                text = text.replace('{1}', props.bovinePurchaseAnnouncementAnimalCount.toString());
            } else {
                text = text.replace('{0}', item.count.toString());
                text = text.replace('{1}', item.count.toString());
            }
        } else if (item.text.includes('{0}')) text = text.replace('{0}', item.count.toString());

        return text;
    };

    const hasOnlyDefaultOption = (): boolean => {
        if (props.dropdownOptions.length > 1) return false;

        return true;
    };

    const getIconForBox = (icon: string) => {
        switch (icon) {
            case 'missingPurchaseDate':
                return (
                    // No time for tooltip
                    // <Tooltip title={'Animals in waiting room do not have purchace data from BovineRegister'}>
                    <Warning style={{ color: 'red' }} />
                    // </Tooltip>
                );
            default:
                return;
        }
    };

    const getCarouselItemClassName = () => {
        if (defaultItem !== null && defaultItem.code === props.currentlySelectedCarouselId)
            return 'anelma-carousel-item-selected';
        return 'anelma-carousel-item';
    };

    return (
        <>
            <Grid className={getCarouselItemClassName()} container>
                <Grid
                    item
                    className={hoveredMainOption ? 'hovered-main-option' : ''}
                    xs={9}
                    style={{ marginLeft: '20px', marginTop: '10px', marginBottom: '10px' }}
                    onClick={() => handleItemClick(defaultItem as ACarouselDropdownItem)}
                    onMouseLeave={() => setHoveredMainOption(false)}
                    onMouseEnter={() => setHoveredMainOption(true)}
                >
                    <Grid item>
                        <Typography variant='h6'>{topText.toUpperCase()}</Typography>
                    </Grid>

                    <Grid
                        item
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography variant='h6' style={{ wordBreak: 'break-all' }}>
                            {bottomText.toUpperCase()}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography variant='h6'>{props.additionalText}</Typography>
                    </Grid>
                </Grid>
                {props.dropdownOptions && props.dropdownOptions.length > 0 && (
                    <Grid
                        id={"carouselItem_"+props.index+ "_DropdownToggle"}
                        item
                        xs={1}
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'column-reverse',
                        }}
                        onClick={() => props.onDropdownToggle(props.index)}
                    >
                        {!hasOnlyDefaultOption() && (
                            <MoreHoriz className={'hovered-dropdown-icon'} />
                        )}
                        {props.additionalIcon &&
                            props.additionalIcon.map((i) => getIconForBox(i.iconInfo))}
                    </Grid>
                )}
            </Grid>
            {props.isDropdownOpen ? (
                <Grid className={'anelma-carousel-item-drop-down'} container>
                    <Grid item style={{ marginLeft: '5px', marginRight: '5px' }} xs={12}>
                        {props.dropdownOptions.map((option) =>
                            !option.isDefaultSelection ? (
                                <Grid
                                id={"carouselItem_"+props.index+ "_dropdownOptionsCode_"+option.code}
                                    className={
                                        option.code === hoveredDropdown ? 'hovered-dropdown' : ''
                                    }
                                    container
                                    onClick={() => handleItemClick(option)}
                                    onMouseLeave={() => setHoveredDropdown(null)}
                                    onMouseEnter={() => setHoveredDropdown(option.code)}
                                    style={{ paddingTop: 4 }}
                                >
                                    <Grid item xs={9}>
                                        <Typography variant='h6'>
                                            {getDropdownText(option)}
                                        </Typography>
                                    </Grid>
                                    <br />
                                </Grid>
                            ) : (
                                <></>
                            )
                        )}
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
}
