import ApiBase from './ApiBase';

import { ISystemLogEvent } from '../interfaces/ILog';
import { auditTrailUrl } from '../api/apiEndpoints';

class SystemLogApi extends ApiBase {
    getSeqEvents(correlationId: string, fromDatetime: string) {
        return this.getList<ISystemLogEvent>(
            `${auditTrailUrl}/SeqEvents?CorrelationId=${correlationId}&FromDateTime=${fromDatetime}`
        );
    }
}

export default new SystemLogApi();
