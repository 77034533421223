// Libraries
import { useCallback, useState, useEffect, Key } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import CircularProgress from '@mui/material/CircularProgress';

// Store
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadPersonNameAsync } from '../store/personNameSlice';

export interface PersonNameProps {
    key?: Key;
    personId: string;
}

type NameStatus = 'loading' | 'loaded' | 'set';

export default function PersonName(props: PersonNameProps) {
    const { t } = useTranslation(['AnelmaGeneral']);

    const personNames = useAppSelector((state) => state.personNames.data);

    const dispatch = useAppDispatch();
    const loadName = useCallback((id: string) => dispatch(loadPersonNameAsync(id)), [dispatch]);

    const [status, setStatus] = useState<NameStatus>('loading');
    const [name, setName] = useState<string>('');

    useEffect(() => {
        setStatus('loading');

        loadName(props.personId).then(() => {
            setStatus('loaded');
        });
    }, [props.personId]);

    useEffect(() => {
        if (status !== 'loaded') return;

        const data = personNames.find((i) => i.Owner === props.personId);

        setName(data?.Data || t('AnelmaGeneral:1163'));
        setStatus('set');
    }, [props.personId, personNames, status]);

    if (status !== 'set') return <CircularProgress key={props.key} size={'1em'} disableShrink />;

    return <span key={props.key}>{name}</span>;
}
