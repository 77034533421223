import { IFarmVisit } from '../../interfaces/IFarmVisit';

export const mapFarmVisit = (farmVisit: IFarmVisit, farmVisits: IFarmVisit[], idx: number) => {
    farmVisits[idx].Id = farmVisit.Id;
    farmVisits[idx].FarmGUID = farmVisit.FarmGUID;
    farmVisits[idx].FromTime = farmVisit.FromTime;
    farmVisits[idx].ToTime = farmVisit.ToTime;
    farmVisits[idx].Notes = farmVisit.Notes;
    farmVisits[idx].Flags = farmVisit.Flags;
    farmVisits[idx].ContactId = farmVisit.ContactId;
    farmVisits[idx].Subject = farmVisit.Subject;
    farmVisit.Attachments?.forEach((_) => farmVisits[idx].Attachments?.push(_));

    return farmVisits;
};
