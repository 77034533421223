import { useEffect, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

import AInputContainer from './AInputContainer';
import { IFormValidator } from '../../core/FormValidator';

export interface ASwitchProps {
    checked?: boolean;
    error?: boolean;
    id: string;
    label?: string;
    disabled?: boolean;
    onChange: (value: boolean) => any;
    required?: boolean;
    validator?: IFormValidator;
    withoutContainer?: boolean;
}

export default function ASwitch(props: ASwitchProps) {
    const {
        checked,
        error,
        id,
        onChange,
        required,
        validator,
        withoutContainer,
        ...propsForSwitch
    } = props;
    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<boolean>(!!checked);

    useEffect(() => {
        setVal(!!checked);
    }, [checked]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (required) return val;
            return true;
        };

        validator?.setState(id, isValid(), () => setTouched(true));
    }, [error, id, required, touched, val, validator]);

    const switchProps = propsForSwitch as SwitchProps;
    switchProps.checked = val;
    switchProps.color = 'primary';
    switchProps.onChange = (e) => {
        const value = e.target.checked;
        setTouched(true);
        setVal(value);
        onChange(value);
    };

    const swi = props.label ? (
        <FormControlLabel control={<Switch {...switchProps} />} label={props.label} />
    ) : (
        <Switch {...switchProps} />
    );

    return withoutContainer ? swi : <AInputContainer>{swi}</AInputContainer>;
}
