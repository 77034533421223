// Libraries
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// MUI
// Core
import { ResourceTextApplication } from '../../../../core/resources';

// Common
import { ADialog, ADialogTitle } from '../../../../common/dialog';

import ConfirmDialogClose from '../../../../common/dialog/ConfirmDialogClose';

// Interfaces
import { AnnouncementTypeEnum, DialogModeEnum } from '../../../../interfaces/enums';
import { IAnimalAnnouncement } from '../../../../interfaces/IAnnouncement';
import {
    IAnimalSummary,
    IAnimalSummaryExtraInfo,
    IFarmAnimal,
} from '../../../../interfaces/IAnimal';
import { ITiltuApiResult } from '../../../../interfaces/IGeneral';

// Store
// API
// Feature
import CloseDialogButton from '../general/CloseDialogButton';
import AnimalAnnouncement from '../AnimalAnnouncement';
import '../../../shared/styles/sharedStyles.scss';

export interface IAnnouncementDialogProps {
    data: IAnimalAnnouncement | null;
    onClose: () => void;
    mode: DialogModeEnum;
    selectedAnimals: IAnimalSummary[] | null;
    selectedHoldingSites?: string[];
    animals: IAnimalSummaryExtraInfo[] | null;
    processAnnouncementSave: (
        data: ITiltuApiResult<any>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => void;
    mapUpdatedSalesTypeToAllAnimalsData: (data: IFarmAnimal[]) => void;
    loading: boolean;
    readOnly?: boolean;
    chainInformationFound?: boolean;
    dataImportedViaMmfi?: boolean;
}

export default function BovineIntermediationAnnouncement(props: IAnnouncementDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine', 'AnelmaGeneral']);

    const [confirmationDialogClose, setConfirmationDialogClose] = useState<boolean>(false);

    const handleDialogClose = () => {
        if (props.readOnly === true) {
            props.onClose();
        } else {
            setConfirmationDialogClose(true);
        }
    };

    return (
        <ADialog open onClose={props.onClose} disableEnforceFocus>
            <ADialogTitle>
                {props.mode === DialogModeEnum.Create
                    ? t('AnelmaBovine:1021')
                    : t('AnelmaBovine:1057')}
                <CloseDialogButton
                    className='animal-announcement-close-button-float-right'
                    onClick={handleDialogClose}
                />
            </ADialogTitle>
            <AnimalAnnouncement
                data-robot-id='animal-intermediation-announcement'
                announcementType={AnnouncementTypeEnum.Intermediation}
                {...props}
                handleDialogClose={handleDialogClose}
            />
            {confirmationDialogClose && (
                <ConfirmDialogClose
                    data-robot-id='animal-intermediation-confirm-close'
                    confirmation='AnelmaGeneral:1065'
                    onCancel={() => setConfirmationDialogClose(false)}
                    onClose={() => props.onClose()}
                    open={confirmationDialogClose}
                />
            )}
        </ADialog>
    );
}
