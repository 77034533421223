// Libraries
import React, { useEffect } from 'react';

// MUI
import { Box, Collapse, IconButton, Table, TableCell, TableRow, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Interfaces
import { ICQRSEventBase } from '../../interfaces/ILog';

export interface ICQRSEventBaseRow extends ICQRSEventBase {
    id: string;
    Command: string;
    Field: string;
    OldData: string;
    NewData: string;
    EntityType: string;
    Name: string;
    Timestamp: string;
    Body: JSX.Element;
}

const TableRowWrapper = styled(TableRow)(({ theme }) => ({
    width: '100%',
    '& > *': {
        borderBottom: 'unset',
    },
}));

export default function CollapsibleRow(props: { row: ICQRSEventBaseRow; onClick: () => {} }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(false);
    }, [row.CorrelationId]);

    return (
        <React.Fragment>
            <TableRowWrapper
                onClick={(e) => {
                    if (!e.defaultPrevented) setOpen(!open);
                }}
            >
                <TableCell>
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={(e) => {
                            e.preventDefault();
                            setOpen(!open);
                        }}
                    >
                        {row.Body ? (
                            open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )
                        ) : (
                            <div />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component='th' scope='row'>
                    {row.Author}
                </TableCell>
                <TableCell>{row.Command}</TableCell>
                <TableCell>{row.Field}</TableCell>
                <TableCell>
                    {' '}
                    {row.EntityType}&nbsp;{row.Name}{' '}
                </TableCell>
                <TableCell> {row.Timestamp} </TableCell>
            </TableRowWrapper>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Table size='small'>{row.Body}</Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
