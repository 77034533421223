// Libraries
import fileDownload from 'js-file-download';

// Interfaces
import { GUIDType } from '../interfaces/types';
import {
    IBasicDocument,
    IBasicDocumentInfo,
    IDocumentAddDialogData,
    DocumentFileTypeEnum,
} from '../documents/IDocuments';
import { IApiResult } from '../interfaces/IGeneral';

// Api
import { documentUrl } from './apiEndpoints';
import ApiBase from './ApiBase';

class DocumentApi extends ApiBase {
    deleteDocument(id: GUIDType) {
        return this.delete<IBasicDocumentInfo>(`${documentUrl}/delete-file/${id}`, {
            incompatibleResult: true,
        });
    }

    downloadFile(fileType: DocumentFileTypeEnum, id: GUIDType) {
        return new Promise<IApiResult<Blob> | null>((resolve, reject) => {
            this.get<Blob>(`${documentUrl}/download-file/${fileType}/${id}`, {
                responseType: 'blob',
                incompatibleResult: true,
            }).then((response) => {
                if (!response) {
                    resolve(null);
                    reject();
                    return;
                }
                let fileName: string = response.Headers['content-disposition']
                    ?.split('filename=')[1]
                    ?.split(';')[0]
                    .replaceAll('"', '');

                fileDownload(response.Entity, fileName.trim());

                resolve(response);
            });
        });
    }

    getFiles(fileType: DocumentFileTypeEnum, ids: GUIDType[]) {
        return this.postList<IBasicDocument>(
            `${documentUrl}/download-files/${fileType}`,
            { ids },
            { incompatibleResult: true }
        );
    }

    getFileList(fileType: DocumentFileTypeEnum, farmId?: GUIDType) {
        if (farmId)
            return this.getList<IBasicDocumentInfo>(
                `${documentUrl}/file-list/${fileType}/${farmId}`,
                {
                    incompatibleResult: true,
                }
            );
        return this.getList<IBasicDocumentInfo>(`${documentUrl}/file-list/${fileType}`, {
            incompatibleResult: true,
        });
    }

    uploadDocument(data: IDocumentAddDialogData, fileType: DocumentFileTypeEnum) {
        const formData = this.createFormData(data, fileType);
        return this.post<IBasicDocumentInfo>(`${documentUrl}/upload-file`, formData, {
            incompatibleResult: true,
        });
    }

    uploadDocuments(data: IDocumentAddDialogData, fileType: DocumentFileTypeEnum) {
        let files = this.createFormData(data, fileType, true);
        var payload = JSON.stringify({ items: files });
        return this.post<IBasicDocumentInfo[]>(
            `${documentUrl}/upload-multiple-files`,
            files,

            {
                incompatibleResult: true,
            }
        );
    }

    downloadFilesByOwnerId(id: GUIDType) {
        console.log(id);
        return this.getList<IBasicDocumentInfo>(`${documentUrl}/download-by-ownerid/${id}`);
    }

    private createFormData(
        data: IDocumentAddDialogData,
        fileType: DocumentFileTypeEnum,
        multi?: boolean
    ) {
        const formData = new FormData();

        if (multi === true) {
            for (let i = 0; i < data.file.length; i++) {
                formData.append(`[${i}].Id`, '00000000-0000-0000-0000-000000000000'); // Generated in API
                formData.append(`[${i}].DisplayName`, data.displayName);
                formData.append(`[${i}].Owner`, data.owner);
                formData.append(`[${i}].ValidFrom`, data.validFrom);
                formData.append(`[${i}].ValidDue`, data.validDue);
                formData.append(`[${i}].FileType`, `${fileType}`);
                formData.append(`[${i}].Type`, `${data.type}`);
                formData.append(`[${i}].FileName`, data.file[i].name);
                formData.append(`[${i}].FormFile`, data.file[i]);
                if (data.farmId) formData.append(`[${i}].FarmId`, data.farmId);
            }
            return formData;
        } else {
            formData.append('Id', '00000000-0000-0000-0000-000000000000'); // Generated in API
            formData.append('FileName', data.file[0].name);
            formData.append('DisplayName', data.displayName);
            formData.append('Owner', data.owner);
            formData.append('ValidFrom', data.validFrom);
            formData.append('ValidDue', data.validDue);
            formData.append('FileType', `${fileType}`);
            formData.append('Type', `${data.type}`);
            formData.append('FormFile', data.file[0]);

            if (data.farmId) formData.append('FarmId', data.farmId);

            return formData;
        }
    }
}

export default new DocumentApi();
