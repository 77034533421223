// Libraries
import { mergeRight } from 'ramda';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../../core/resources';
import auth from '../../../core/authorization';

// Common
import { ADataGrid, gridColumns, AGridColumns } from '../../../common/dataGrid';
import { AEditIconButton, ADeleteIconButton } from '../../../common/buttons';

// Feature
import ConfirmDialog from '../dialog/ConfirmDialog';
import { ISearchParameterRow } from '../../shared/styles/types/subTypes';

export interface SearchParametersProperties {
    data: ISearchParameterRow[];
    openEdit: (row: ISearchParameterRow) => any;
    delete: (row: ISearchParameterRow) => any;
}

export default function SearchParameters(props: SearchParametersProperties): JSX.Element {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCommunication']);

    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<ISearchParameterRow | null>(null);

    const columns: AGridColumns = [
        gridColumns.withTooltip({
            field: 'name',
            headerName: t('AnelmaCommunication:1032'),
            width: 250,
        }),
        gridColumns.withTooltip({
            field: 'filters',
            flex: 100,
            headerName: t('AnelmaCommunication:1034'),
            width: 850,
        }),
    ];

    const deleteRow = (row: ISearchParameterRow) => {
        setDialogData(row);
        setConfirmDialogVisible(true);
    };

    return (
        <>
            <ADataGrid
                actions={[
                    (params) => {
                        const row = params.row as unknown as ISearchParameterRow;
                        return (
                            auth.canUpdateCommunicationGroups && (
                                <AEditIconButton
                                    key={`edit-${row.guid}+${row.name}`}
                                    onClick={() => {
                                        props.openEdit(row);
                                    }}
                                    tooltip={t('AnelmaCommunication:1031')}
                                />
                            )
                        );
                    },
                    (params) => {
                        const row = params.row as unknown as ISearchParameterRow;
                        return (
                            auth.canDeleteCommunicationGroups && (
                                <ADeleteIconButton
                                    key={`delete-${row.guid}`}
                                    onClick={() => {
                                        deleteRow(row);
                                    }}
                                    tooltip={t('AnelmaCommunication:1030')}
                                />
                            )
                        );
                    },
                ]}
                columns={columns}
                enableFiltering
                enableRowNumbers
                rows={props.data.map((i) => mergeRight(i, { id: i.guid }))}
            />

            {confirmDialogVisible && (
                <ConfirmDialog
                    name={dialogData?.name ?? ''}
                    content={'AnelmaCommunication:1029'}
                    title={'AnelmaGeneral:1142'}
                    deleteRow={() => {
                        if (dialogData != null) props.delete(dialogData);
                    }}
                    onClose={() => {
                        setDialogData(null);
                        setConfirmDialogVisible(false);
                    }}
                />
            )}
        </>
    );
}
