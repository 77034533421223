import React, { PropsWithChildren } from 'react';

// MUI
import { Grid } from '@mui/material';

// Core

// Common

export default function HeaderContainer({
    align,
    children,
}: PropsWithChildren<{ align?: 'left' | 'center' | 'right' }>) {
    const itemCSS: React.CSSProperties = {
        height: '60px',
        lineHeight: '20px',
    };

    if (align) itemCSS.textAlign = align;

    return (
        <Grid container alignItems='center' style={{ height: '80px' }}>
            <Grid item xs={12} style={itemCSS}>
                {children}
            </Grid>
        </Grid>
    );
}
