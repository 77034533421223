// Libraries
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';

// MUI
// Core
// Common
import ViewLoader from './common/ViewLoader';

// Interfaces
// Store
import store from './store/store';

// API
// Feature
import App from './App';
import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    // <StrictMode> EditorJS is not working with strict mode. Discovered on http://jira.mtech.fi/browse/AN-1979 and by some fella over https://github.com/Jungwoo-An/react-editor-js/issues/228
    <Provider store={store}>
        <CookiesProvider>
            <Suspense fallback={<ViewLoader />}>
                <SnackbarProvider
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    maxSnack={5}
                >
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SnackbarProvider>
            </Suspense>
        </CookiesProvider>
    </Provider>
    // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
