// Libraries
import moment from 'moment';

// Core
import log from '../../../core/log';

// Interfaces
import { AnnouncementTypeEnum } from '../../../interfaces/enums';
import { ICollectWeek } from '../../../interfaces/IAnnouncement';

// Feature
import { UnreachableSwitchError } from '../errors/UnreachableSwitchError';

export const defaultCollectWeekInference = (
    accouncementType: AnnouncementTypeEnum,
    weekday: number
): ICollectWeek => {
    const now = moment();
    switch (accouncementType) {
        case AnnouncementTypeEnum.Slaughter:
        case AnnouncementTypeEnum.ManualBovineSlaughter:
            if (now.isoWeekday() === weekday) return getWeekAndYear(now, 1);
            return getWeekAndYear(now, 1);
        case AnnouncementTypeEnum.Intermediation:
        case AnnouncementTypeEnum.ManualBovineIntermediation:
            if (now.isoWeekday() <= weekday) return getWeekAndYear(now, 0);
            return getWeekAndYear(now, 1);
        case AnnouncementTypeEnum.BovinePurchase:
            if (now.isoWeekday() <= 1) return getWeekAndYear(now, 0);
            return getWeekAndYear(now, 1);
        default:
            log(
                'error',
                `Error with switch-type 'AnnouncementTypeEnum'. ${new UnreachableSwitchError().errorMessage(
                    accouncementType
                )}`
            );
            return { Year: -1, Week: -1 };
    }
};

const getWeekAndYear = (date: moment.Moment, weeksToAdd: number): ICollectWeek => {
    const targetWeek = date.add(weeksToAdd, 'week');
    return {
        Year: targetWeek.year(),
        Week: targetWeek.isoWeek().valueOf(),
    };
};

export const generateCollectWeekSequence = (
    start: number,
    end: number,
    year: number
): ICollectWeek[] => {
    let collectWeeks: ICollectWeek[] = [];
    for (let i = start; i <= start + end; i++) {
        collectWeeks.push({ Year: year, Week: i });
    }
    return collectWeeks;
};

export const initializeCollectWeekStart = (type: AnnouncementTypeEnum): ICollectWeek => {
    return type === AnnouncementTypeEnum.Slaughter ||
        type === AnnouncementTypeEnum.ManualBovineSlaughter
        ? defaultCollectWeekInference(type, 1)
        : defaultCollectWeekInference(type, 4);
};

export const mapCollectWeeksForDropdown = (collectWeeks: ICollectWeek[]) => {
    const dropdownCollectWeeks = collectWeeks.map((_) => ({
        text: String(_.Week),
        value: JSON.stringify(_),
    }));
    return dropdownCollectWeeks;
};
