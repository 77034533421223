// Libraries
// MUI
// Core
// Common
import { TextWithTooltip } from '../../../../common/typography';

// Interfaces
import { ICollectWeek } from '../../../../interfaces/IAnnouncement';
import { DialogModeEnum } from '../../../../interfaces/enums';

// Store
// API
// Feature - Animal Announcement
import { collectWeekString } from '../../helpers/collectWeek';
import '../../styles/announcementStyles.scss'

export interface IAnimalAnnouncementCollectWeekFieldProps {
    collectWeekStart: ICollectWeek | null;
    collectWeekEnd: ICollectWeek | null;
    mode: DialogModeEnum;
}

export const AnimalAnnouncementCollectWeekField = (
    props: IAnimalAnnouncementCollectWeekFieldProps
) => {
    const displayCollectWeekBackgroundFilling = (): boolean => {
        if (
            (!props.collectWeekStart ||
                props.collectWeekStart.Year === -1 ||
                props.collectWeekStart.Week === -1) &&
            props.mode === DialogModeEnum.Edit
        )
            return true;

        return false;
    };

    const getCollectWeekStringValue = (): string => {
        return (
            collectWeekString(
                props.collectWeekStart ?? undefined,
                props.collectWeekEnd ?? undefined
            ) ?? ''
        );
    };

    return (
        <>
            {displayCollectWeekBackgroundFilling() ? (
                <div className='anelma-animal-announcement-general-background-light-red' />
            ) : (
                <TextWithTooltip text={getCollectWeekStringValue()} />
            )}
        </>
    );
};
