import './inputContainer.scss';

export interface AInputContainerProps {
    children?: React.ReactNode;
}

export default function AInputContainer(props: AInputContainerProps) {
    return (
        <div id='input-container' className='an-input-container'>
            {props.children}
        </div>
    );
}
