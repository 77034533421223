import axios from 'axios';
import { sendDirectMessageUrl } from '../../api/apiEndpoints';
import { IApiListResult, IApiResult } from '../../interfaces/IGeneral';
import { IUpdatePayload } from '../../interfaces/IGeneral';
import { IMessages } from '../ISms';

export function sendDirectMessage(payload?: any) {
    return axios
        .post<IApiListResult<IMessages>>(`${sendDirectMessageUrl}/SendDirectMessage`, payload)
        .then((d) => d.data);
}

export function getMessagesList(from: string, to: string) {
    return axios
        .get<IApiListResult<IMessages>>(`${sendDirectMessageUrl}/ListSentMessages/from/${from}/to/${to}`)
        .then((d) => d.data);
}

export function updateMessage(guid: string, payload: IUpdatePayload<IMessages>) {
    return axios
        .patch<IApiResult<IMessages>>(`${sendDirectMessageUrl}/${guid}`, payload)
        .then((d) => d.data);
}

export function cancelMessage(guid: string) {
    return axios
        .delete<IApiResult<IMessages>>(`${sendDirectMessageUrl}/${guid}`)
        .then((d) => d.data);
}
