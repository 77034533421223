// Libraries
import { Key } from 'react';

// MUI
import { Tooltip, Typography } from '@mui/material';

export interface TextWithTooltipProps {
    text: string;
    className?: string | undefined;
}

export function TextWithTooltip(props: TextWithTooltipProps) {
    return (
        <Tooltip title={props.text}>
            <Typography noWrap variant='body2' className={props.className}>
                {props.text}
            </Typography>
        </Tooltip>
    );
}

export interface TextsWithTooltipProps {
    texts: string[];
    className?: string | undefined;
}

export function TextsWithTooltip(props: TextsWithTooltipProps) {
    return (
        <Tooltip
            title={
                <ul style={{ paddingLeft: 10 }}>
                    {props.texts.map((i, idx) => (
                        <li key={idx}>{i}</li>
                    ))}
                </ul>
            }
        >
            <Typography variant='body2' className={props.className}>
                {props.texts.join(', ')}
            </Typography>
        </Tooltip>
    );
}

export interface ViewTitleProps {
    children: React.ReactNode;
}

export function ViewTitle(props: ViewTitleProps) {
    return (
        <Typography {...props} style={{ fontSize: '2.5rem', marginBottom: '20px' }} variant='h2' />
    );
}

export interface InfoBlockProps {
    elementKey?: Key;
    label: React.ReactNode;
    value: React.ReactNode;
}

export function InfoBlock(props: InfoBlockProps) {
    return (
        <div
            key={props.elementKey ?? `info-block-${props.label}-${props.value}`}
            style={{ margin: '10px 0' }}
            data-robot-id={'info-block-' + props.label}
        >
            <Typography variant='h5'>{props.label}</Typography>
            {props.value || '-'}
        </div>
    );
}
