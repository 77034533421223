// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import ATextArea from '../../common/inputs/ATextarea';
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../common/dialog';

// Interfaces
import { IPricing } from '../../interfaces/IBusinessEntities';

export interface CompanyPricingDialogProps {
    pricing: IPricing;
    onClose: () => void;
    onSave: (data: IPricing) => void;
    validator: IFormValidator;
}

export default function CompanyPricingDialog(props: CompanyPricingDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany', 'AnelmaGeneral']);
    const { enqueueSnackbar } = useSnackbar();

    const [pricingData, setPricingData] = useState<IPricing>(props.pricing);

    const handleOnChange = (v: string) => {
        const newData = { ...pricingData };
        newData.Text = v;
        setPricingData(newData);
    };

    const onPricingSave = () => {
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }
        props.onSave(pricingData);
    };

    return (
        <ADialog onClose={props.onClose} open={true}>
            <ADialogTitle>{t('AnelmaCompany:1092')}</ADialogTitle>

            <ADialogContent isLoading={false} size='sm'>
                {
                    <Grid container>
                        <Grid item sm={12}>
                            <ATextArea
                                id='pricing'
                                label=''
                                maxLength={500}
                                onChange={(v) => handleOnChange(v)}
                                required
                                validator={props.validator}
                                value={pricingData.Text}
                            />
                        </Grid>
                    </Grid>
                }
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: props.onClose,
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => onPricingSave(),
                            type: 'ok',
                        },
                    ],
                }}
                isLoading={false}
            />
        </ADialog>
    );
}
