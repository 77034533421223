// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';

// Store
import { useAppSelector } from '../../store/hooks';

// Api
import personApi from '../../api/personApi';

// Core
import { paramRepository, ParameterValues, ResourceTextApplication } from '../../core/resources';

// Common
import { InfoBlock, ViewTitle } from '../../common/typography';
import ViewLoader from '../../common/ViewLoader';

// Interfaces
import { IPersonRelation, IPersonSummaryExtended } from '../../interfaces/IBusinessEntities';
import { CompanyEntityTypeEnum } from '../../interfaces/enums';

// Feature
import PhoneNumbers from '../PhoneNumbers';
import Language from '../Language';

export default function MyInfo() {
    const userData = useAppSelector((state) => state.userData);
    const { data } = userData;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaPerson', 'AnelmaLayout']);

    const [relations, setRelations] = useState<IPersonRelation[] | null>(null);
    const [farmTitles, setFarmTitles] = useState<ParameterValues>([]);
    const [logisticsTitles, setLogisticsTitles] = useState<ParameterValues>([]);
    const [snellmanTitles, setSnellmanTitles] = useState<ParameterValues>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const p1 = paramRepository.load(['AnelmaGeneral']);

        const p2 = personApi.getPersons('Login', getLogin(), false, true);

        Promise.all([p1, p2]).then((responses) => {
            setFarmTitles(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
            setLogisticsTitles(paramRepository.resource('AnelmaGeneral', 'LogisticsTitles'));
            setSnellmanTitles(paramRepository.resource('AnelmaGeneral', 'SnellmanTitles'));

            if (!responses[1]) {
                setRelations([]);
            } else {
                const rel: IPersonRelation[] = [];
                (responses[1].Items as IPersonSummaryExtended[]).forEach((i) =>
                    rel.push(...i.Relations)
                );
                rel.sort((a, b) => (a.CompanyName < b.CompanyName ? -1 : 1));
                setRelations(rel);
            }

            setLoading(false);
        });
    }, []);

    const getName = () => {
        const name: string[] = [];
        if (data?.FirstName) name.push(data.FirstName);
        if (data?.LastName) name.push(data.LastName);
        return name.join(' ');
    };

    const getLogin = () => {
        return data?.Logins[0]?.Login || '';
    };

    const getEmail = () => {
        return data?.EmailAddresses.find((i) => i.IsDefault)?.Value;
    };

    const getAddress = () => {
        return data?.Addresses.find((i) => i.IsDefault)?.NormalizedAddress;
    };

    function getRoles(companyType: CompanyEntityTypeEnum, roles: string[]): string {
        let titles: ParameterValues | null = null;
        if (companyType === CompanyEntityTypeEnum.Farm) titles = farmTitles;
        else if (companyType === CompanyEntityTypeEnum.TrafficContractor) titles = logisticsTitles;
        else if (companyType === CompanyEntityTypeEnum.Snellman) titles = snellmanTitles;

        return titles ? roles.map((r) => titles?.find((o) => o.code == r)?.text).join(', ') : '';
    }

    const formatRelation = (rel: IPersonRelation) => {
        const result = [rel.CompanyName];
        const roles = getRoles(rel.CompanyType, rel.Roles);
        if (roles) result.push(`(${roles})`);
        return result.join(' ');
    };
    const xsLayout = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

    return (
        <Container data-robot-id = {"app-body-my-info"}>
            <ViewTitle>{t('AnelmaLayout:1030')}</ViewTitle>

            {loading ? (
                <ViewLoader />
            ) : (
                <Grid container>
                    <Grid item md={6} sm={12}>
                        <Typography variant='h3'>{t('AnelmaPerson:1046')}</Typography>
                        {xsLayout ? (
                            <>
                                <InfoBlock label={t('AnelmaPerson:1021')} value={getName()} />
                                <InfoBlock label={t('AnelmaPerson:1004')} value={getLogin()} />
                                <InfoBlock label={t('AnelmaPerson:1008')} value={getEmail()} />
                                <Language
                                    label={t('AnelmaPerson:1009')}
                                    languageCode={data?.LanguageShortCode || ''}
                                />
                                <PhoneNumbers
                                    data={data?.PhoneNumbers || []}
                                    types={paramRepository.resource(
                                        'AnelmaGeneral',
                                        'PhoneNumberTypePerson'
                                    )}
                                />
                            </>
                        ) : (
                            <>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <InfoBlock
                                            label={t('AnelmaPerson:1021')}
                                            value={getName()}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <InfoBlock
                                            label={t('AnelmaPerson:1004')}
                                            value={getLogin()}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item sm={6}>
                                        <InfoBlock
                                            label={t('AnelmaPerson:1008')}
                                            value={getEmail()}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Language
                                            label={t('AnelmaPerson:1009')}
                                            languageCode={data?.LanguageShortCode || ''}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <PhoneNumbers
                                            data={data?.PhoneNumbers || []}
                                            types={paramRepository.resource(
                                                'AnelmaGeneral',
                                                'PhoneNumberTypePerson'
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        <Grid container>
                            <Grid item sm={12}>
                                <InfoBlock label={t('AnelmaGeneral:1006')} value={getAddress()} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={6}>
                        <Typography variant='h3'>{t('AnelmaPerson:1047')}</Typography>

                        {relations &&
                            relations.map((i, key) => (
                                <InfoBlock key={key} label='' value={formatRelation(i)} />
                            ))}
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}
