interface ContextSgments {
    cattleId: string;
    farmId: string;
    name: string;
    producerNumber: string;
}

class ContextUtils {
    parseContextString(context: string): ContextSgments {
        const parts = context.split(':::');
        const farmId = parts[0] || '';
        const cattleId = parts[1] || '';
        const producerNumber = parts[2] || '';
        const name = parts[3] || '';

        return {
            cattleId,
            farmId,
            name,
            producerNumber,
        };
    }
}

export default new ContextUtils();
