// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import ADialogActions from './ADialogActions';
import ADialog from './ADialog';
import ADialogContent from './ADialogContent';
import ADialogTitle from './ADialogTitle';

// Interfaces
// Store
// API
// Styles
import './confirmDialogStyle.scss';

export interface IConfirmDialogCloseProps {
    confirmation: string;
    onCancel: () => void;
    onClose: () => void;
    open: boolean;
}

export default function ConfirmDialogClose(props: IConfirmDialogCloseProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    return (
        <ADialog open={props.open} data-robot-id = "confirm-close-dialog">
            <ADialogTitle>{t('AnelmaGeneral:1174')}</ADialogTitle>

            <ADialogContent size='sm'>
                <Typography className='confirm-dialog-content-typography' variant='body1'>
                    {t(props.confirmation)}
                </Typography>
            </ADialogContent>

            <ADialogActions data-robot-id="dialog-action-buttons"
                buttons={{
                    left: [
                        {
                            onClick: () => props.onCancel(),
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => {
                                props.onClose();
                            },
                            type: 'ok',
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
