import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ANumberInput, ASwitch, ATextarea } from "../../../common/inputs";
import { IFormValidator } from "../../../core/FormValidator";
import { ResourceTextApplication } from "../../../core/resources";
import { IProductionPlan } from "../../../interfaces/IProductionPlan";

interface IProps {
    data: IProductionPlan;
    readonly?: boolean;
    validator: IFormValidator;
}

export default function Environment({ data, readonly, validator }: IProps){
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaLayout', 'AnelmaCattleProductionPlan']);
    
    const [additionalInfo, setAdditionalInfo] = useState<string>(data.AdditionalInfo);

    return (
        <Grid container direction='row' style={{ paddingLeft: 40, paddingTop: '20px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h4">{t('AnelmaLayout:1072')}</Typography>
                <ANumberInput 
                    id={'farm-yield'}
                    label={t('AnelmaCattleProductionPlan:1043')}
                    validator={validator}
                    disabled={readonly}
                    onChange={(value) => {
                        data.YearlyLawnCropAmount = value || 0;
                    }}
                    value={data.YearlyLawnCropAmount ? data.YearlyLawnCropAmount : 0}
                    mode={'int'}
                    min={0}
                />
                <ASwitch 
                    id={'committed-to-environment'}
                    label={t('AnelmaCattleProductionPlan:1044')}
                    checked={data.CommittedToEnviromentalSupportConditions}
                    validator={validator}
                    disabled={readonly}
                    onChange={(checked) => {
                        data.CommittedToEnviromentalSupportConditions = checked;
                    }}
                />
                <ASwitch 
                    id={'is-biogas-producer'}
                    label={t('AnelmaCattleProductionPlan:1045')}
                    checked={data.IsBiogasProducer}
                    validator={validator}
                    disabled={readonly}
                    onChange={(checked) => {
                        data.IsBiogasProducer = checked;
                    }}
                />
                <ASwitch 
                    id={'is-solar-power-producer'}
                    label={t('AnelmaCattleProductionPlan:1046')}
                    checked={data.IsSolarPowerProducer}
                    validator={validator}
                    disabled={readonly}
                    onChange={(checked) => {
                        data.IsSolarPowerProducer = checked;
                    }}
                />
                <ASwitch 
                    id={'is-wind-power-producer'}
                    label={t('AnelmaCattleProductionPlan:1047')}
                    checked={data.IsWindPowerProducer}
                    validator={validator}
                    disabled={readonly}
                    onChange={(checked) => {
                        data.IsWindPowerProducer = checked;
                    }}
                />
                <ATextarea
                    id={'add-info'}
                    label={t('AnelmaCattleProductionPlan:1048')}
                    validator={validator}
                    disabled={readonly}
                    onChange={(value) => {
                        data.AdditionalInfo = value;
                        setAdditionalInfo(value);
                    }}
                    value={additionalInfo}
                    maxLength={400}
                />
            </Grid>
        </Grid>
    );
}