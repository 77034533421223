// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';

// MUI
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    styled,
} from '@mui/material';

// Core
import { ParameterValues, ResourceTextApplication, paramRepository } from '../../../core/resources';
import FormValidator from '../../../core/FormValidator';
import { dayFormat } from '../../../core/constants';
import authorization from '../../../core/authorization';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../../common/dialog';
import { ADateInput, AMultiselect, ASwitch, ATextarea, ATextInput } from '../../../common/inputs';

// Interfaces
import {
    IAnimalSymptoms,
    IChainInformationAnimal,
    IChainInformationData,
    IChainInformationUpdateData,
    IAnimalDiseases,
    IAnimalInputValue,
    IAnimalSymptomsToSave,
    ILoadedSymptoms,
} from '../IChainInformationDialog';
import { IAnimalAnnouncement, IAnimalAnnouncementAnimal } from '../../../interfaces/IAnnouncement';
import { GUIDType } from '../../../interfaces/types';

// Store
import contextUtils from '../../../core/utils.context';
import { useAppSelector } from '../../../store/hooks';

// API
import chainInformationApi from '../../../api/chainInformationApi';

const GreenRadio = styled(Radio)(({ theme }) => ({
    colorSecondary: {
        color: '#rgba(0, 0, 0, 0.54)',
        '&$checked': {
            color: '#02584B',
        },
    },
    checked: {},
}));

const GreenCheckBox = styled(Checkbox)(({ theme }) => ({
    root: {
        color: '#02584B',
        '&$checked': {
            color: '#02584B',
        },
    },
    checked: {},
}));

export interface IChainInformationDialogProps {
    onClose: (cancelled?: boolean) => any;
    announcementData: IAnimalAnnouncement | null;
    announcementId: GUIDType;
    announcementNumber: string;
    animals: IAnimalAnnouncementAnimal[] | null;
    companyName?: string;
    producerNumber?: string;
    forceReadOnly?: boolean;
    newAnnouncement?: boolean;
}

export default function ChainInformationDialog(props: IChainInformationDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaChainInformation']);
    const context = useAppSelector((state) => state.context);
    const user = useAppSelector((state) => state.loginStatus);
    const parsedContext = contextUtils.parseFarmContext(context.data.currentContext?.context || '');
    const [symptoms, setSymptoms] = useState<ParameterValues>([]);
    const [diseases, setDiseases] = useState<ParameterValues>([]);

    const validator = new FormValidator();
    const auth = authorization;

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setIsLoading(true);
        paramRepository
            .load([
                'AnelmaGeneral',
                'AnelmaChainInformation',
                'AnelmaLayout',
                'AnelmaAnimalPayload',
            ])
            .then(() => {
                setSymptoms(paramRepository.resource('AnelmaChainInformation', 'BovineSymptoms'));
                setDiseases(paramRepository.resource('AnelmaChainInformation', 'BovineDisease'));
                const salesTypes = paramRepository.resource('AnelmaAnimalPayload', 'SalesType');

                if (salesTypes) setSalesTypeParams(salesTypes);
                setIsLoading(false);
            });
    }, []);

    const [isLoading, setIsLoading] = useState<boolean>();
    const [validationState, setValidationState] = useState<any>({
        healthInformation: true,
        gmFree: true,
    });
    const [medicatedFeed, setMedicatedFeed] = useState<boolean | null>(null);
    const [healthInformation, setHealthInformation] = useState<boolean | null>(null);
    const [authorityRegulations, setAuthorityRegulations] = useState<boolean | null>(null);
    const [foreignObject, setForeignObject] = useState<boolean | null>(null);
    const [gmFree, setGmFree] = useState<boolean | null>(null);
    const [medicalAccounting, setMedicalAccounting] = useState<boolean | null>(false);
    const [waitingTimeOver, setWaitingTimeOver] = useState<boolean | null>(false);
    const [nasevaInfo, setNasevaInfo] = useState<boolean>(false);
    const [slaughterHouseInfo, setSlaughterHouseInfo] = useState<boolean>(false);
    const [medicationInfo, setMedicationInfo] = useState<string>('');
    const [anomalies, setAnomalies] = useState<string>('');
    const [regulations, setRegulations] = useState<string>('');
    const [samplingOrder, setSamplingOrder] = useState<string>('');
    const [vetName, setVetName] = useState<string>('');
    const [additionalInfo, setAdditionalInfo] = useState<string>('');
    const [foreignObjectInfo, setForeignObjectInfo] = useState<string>('');
    const [signature, setSignature] = useState<{ signatory: string; signatureDate: moment.Moment }>(
        {
            signatory: `${user.data.firstName}  ${user.data.lastName}`,
            signatureDate: moment(),
        }
    );
    const [animalSymptoms, setAnimalSymptoms] = useState<IAnimalSymptoms[]>([]);
    const [otherSymptom, setOtherSymptom] = useState<string>('');
    const [animalSymptomCodes, setAnimalSymptomCodes] = useState<string[]>([]);
    const [animalDiseaseCodes, setAnimalDiseaseCodes] = useState<string[]>([]);
    const [animalDiseases, setAnimalDiseases] = useState<IAnimalDiseases[]>([]);
    const [otherDisease, setOtherDisease] = useState<string>('');
    const [medicatedAnimals, setMedicatedAnimals] = useState<string[]>([]);
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [formConfirmation, setFormConfirmation] = useState<boolean>(false);
    const [mode, setMode] = useState<string>('add');
    const [chainInformationId, setChainInformationId] = useState<GUIDType>('');
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues | null>(null);
    const [announcementNumber, setAnnouncementNumber] = useState<string>('');

    const [loadedSymptoms, setLoadedSymptoms] = useState<ILoadedSymptoms[]>([]);

    const [readOnly, setReadOnly] = useState<boolean>(false);

    const [animals, setAnimals] = useState<IAnimalAnnouncementAnimal[]>([]);
    const [announcementId] = useState<GUIDType>(
        props.announcementId || props.announcementData?.Id || ''
    );
    const [animalsInput, setAnimalsInput] = useState<IAnimalInputValue[]>([]);

    useEffect(() => {
        if (props.forceReadOnly && props.forceReadOnly === true) setReadOnly(true);

        if (
            auth.canUpdateChainInformationAdmin ||
            (auth.canCreateChainInformation && props.announcementNumber === '')
        )
            setReadOnly(true);
    }, [props.forceReadOnly]);

    useEffect(() => {
        let tempSymptoms: IAnimalSymptoms[] = [];
        let animalsForArray: string[] = [];
        for (let ind = 0; ind < loadedSymptoms.length; ind++) {
            animalsForArray = [];
            let tempObject = loadedSymptoms.find((s) => s.Code === loadedSymptoms[ind].Code);
            let anis = tempObject?.AnimalIds.map((a) => animals.find((ani) => ani.AnimalId === a));
            if (anis && anis.length > 0) {
                for (let animal of anis) {
                    const animalFound = animalsInput.find(
                        (g) => g.text === animal?.EuIdentifier
                    )?.value;
                    if (animalFound) {
                        animalsForArray.push(animalFound.toString());
                    }
                }
            }
            let include = tempSymptoms.find((t) => t.code.includes(loadedSymptoms[ind].Code));
            if (!include) {
                tempSymptoms.push({ animals: animalsForArray, code: loadedSymptoms[ind].Code });
            }
        }
        setAnimalSymptoms(tempSymptoms);
    }, [loadedSymptoms]);

    useEffect(() => {
        if (props.animals) {
            let anArr: IAnimalAnnouncementAnimal[] = [];

            for (let i = 0; i < props.animals.length; i++) {
                let an: IAnimalAnnouncementAnimal = {
                    AnimalId: props.animals[i].AnimalId,
                    BirthDate: props.animals[i].BirthDate
                        ? props.animals[i].BirthDate.toString()
                        : '',
                    Breed: props.animals[i].Breed,
                    EuIdentifier: props.animals[i].EuIdentifier,
                    SalesType: props.animals[i].SalesType,
                    Sex: props.animals[i].Sex,
                };
                anArr.push(an);
            }
            setAnimals(anArr);
        } else if (props.announcementData) {
            setAnimals(props.announcementData.Batches.map((_) => _.Animals[0]));
        } else {
            setAnimals([]);
        }
    }, [props.animals, props.announcementData]);

    useEffect(() => {
        if (animals.length > 0) {
            let tempAnimals = animals.map((a, i) => {
                return {
                    text: a.EuIdentifier,
                    value: i + 1,
                } as IAnimalInputValue;
            });
            setAnimalsInput(tempAnimals);
        }
    }, [animals]);

    useEffect(() => {
        if (props.announcementData && props.announcementData.Number != 0) {
            setAnnouncementNumber(props.announcementData.Number.toString());
        } else {
            setAnnouncementNumber(props.announcementNumber);
        }
    }, [props]);

    useEffect(() => {
        setIsLoading(true);
        chainInformationApi.loadChainInformation(announcementId).then((response) => {
            if (
                response?.Entity?.ChainInformationId === '00000000-0000-0000-0000-000000000000' &&
                !auth.canCreateChainInformation
            ) {
                setReadOnly(true);
            }
            if (response !== null) {
                if (response.Entity.ChainInformationId !== '00000000-0000-0000-0000-000000000000') {
                    setAdditionalInfo(response.Entity.AdditionalInfo);
                    setAnomalies(response.Entity.Anomalies);
                    setAuthorityRegulations(response.Entity.AuthorityRegulations);
                    setHealthInformation(response.Entity.HealthInformation);
                    setForeignObject(response.Entity.ForeignObject);
                    setForeignObjectInfo(response.Entity.ForeignObjectInfo);
                    setFormConfirmation(response.Entity.FormConfirmation);
                    setGmFree(response.Entity.GmFree);
                    setMedicalAccounting(response.Entity.MedicalAccounting);
                    setMedicatedFeed(response.Entity.MedicatedFeed);
                    setMedicationInfo(response.Entity.MedicationInfo);
                    setNasevaInfo(response.Entity.NasevaInfo);
                    setRegulations(response.Entity.Regulations);
                    setSlaughterHouseInfo(response.Entity.SlaughterHouseInfo);
                    setVetName(response.Entity.VetName);
                    setWaitingTimeOver(response.Entity.WaitingTimeOver);
                    setMedicatedAnimals(response.Entity.Medications);
                    setAnimalDiseaseCodes(response.Entity.Diseases.map((d) => d.Code));
                    setAnimalDiseases(response.Entity.Diseases);
                    setAnimalSymptomCodes(response.Entity.Symptoms.map((s) => s.Code));
                    setOtherSymptom(
                        response.Entity.Symptoms.find((i) => i.Info.length > 0)?.Info || ''
                    );
                    setOtherDisease(
                        response.Entity.Diseases.find((i) => i.Info.length > 0)?.Info || ''
                    );
                    setChainInformationId(response.Entity.ChainInformationId);

                    setSignature({
                        signatory: response.Entity.Signatory || signature.signatory,
                        signatureDate: response.Entity.SignatureDate
                            ? moment(response.Entity.SignatureDate)
                            : signature.signatureDate,
                    });

                    setAnimalSymptoms([]);
                    setLoadedSymptoms(response.Entity.Symptoms);
                    setConfirmation(response.Entity.Signature);
                    setSamplingOrder(response.Entity.SamplingOrder);
                    setMode('edit');
                    if (!auth.canUpdateChainInformation) {
                        setReadOnly(true);
                    }
                }
            }
        });
        setIsLoading(false);
    }, [announcementId]);

    const getDiseaseInfo = (code: string) => {
        if (code === '0') {
            return otherDisease;
        } else {
            return '';
        }
    };

    const getSymptomInfo = (code: string) => {
        if (code === '0') {
            return otherSymptom;
        } else {
            return '';
        }
    };

    const radioButtonValidation = () => {
        if (medicatedFeed === true && waitingTimeOver === false) {
            return false;
        }

        if (
            medicatedFeed != null &&
            healthInformation != null &&
            medicalAccounting != null &&
            authorityRegulations != null &&
            foreignObject != null &&
            gmFree != null &&
            confirmation != false
        ) {
            return true;
        } else {
            return false;
        }
    };

    const validateHealthInformation = (forceCheck?: boolean): boolean => {
        if (validationState.healthInformation && !forceCheck) return true;
        if (
            healthInformation && // Check if ANY health information has been given.
            ((animalSymptomCodes.length <= 0 &&
                animalDiseaseCodes.length <= 0 &&
                !slaughterHouseInfo &&
                !nasevaInfo &&
                (!anomalies || anomalies.length <= 0) &&
                (!samplingOrder || samplingOrder.length <= 0)) ||
                // Check that animals are set for animal-specific health information.
                (animalSymptomCodes.length > 0 && animalSymptoms.length <= 0) ||
                (animalDiseaseCodes.length > 0 && animalDiseaseCodes.length <= 0))
        ) {
            setValidationState((ps: any) => ({ ...ps, healthInformation: false }));
            return false;
        } else setValidationState((ps: any) => ({ ...ps, healthInformation: true }));
        return true;
    };
    useEffect(() => {
        validateHealthInformation();
    }, [
        healthInformation,
        animalSymptoms,
        animalSymptomCodes,
        otherSymptom,
        animalDiseases,
        animalDiseaseCodes,
        otherDisease,
        slaughterHouseInfo,
        nasevaInfo,
        anomalies,
        samplingOrder,
    ]);

    const validateGMFree = (forceCheck?: boolean): boolean => {
        if (validationState.gmFree && !forceCheck) return true;

        if (gmFree && (!additionalInfo || additionalInfo.length <= 0)) {
            setValidationState((ps: any) => ({ ...ps, gmFree: false }));
            return false;
        } else setValidationState((ps: any) => ({ ...ps, gmFree: true }));

        return true;
    };
    useEffect(() => {
        validateGMFree();
    }, [gmFree, additionalInfo]);

    const dataIsValid = () => {
        let valid = true;
        if (validator.invalid || radioButtonValidation() == false) {
            valid = false;
        }

        valid = validateHealthInformation(true) ? valid : false;
        valid = validateGMFree(true) ? valid : false;

        return valid;
    };

    const prepareSave = () => {
        if (
            (medicatedAnimals.length === 0 && medicatedFeed === true) ||
            (medicatedFeed === true && waitingTimeOver === false)
        ) {
            enqueueSnackbar(t('AnelmaChainInformation:1056'), {
                variant: 'error',
            });
            return;
        }

        if (!dataIsValid() && !auth.canUpdateChainInformationAdmin) {
            enqueueSnackbar(t('AnelmaGeneral:1169'), {
                variant: 'error',
            });
            return;
        }

        let animalDiseasesToSave: IAnimalDiseases[] = [];

        for (let i = 0; i < animalDiseases.length; i++) {
            let obj: IAnimalDiseases = {
                Code: animalDiseases[i].Code,
                DiscoveryDate: moment(animalDiseases[i].DiscoveryDate).format(dayFormat).toString(),
                Info: getDiseaseInfo(animalDiseases[i].Code),
            };
            animalDiseasesToSave.push(obj);
        }

        let animalSymptomsToSave: IAnimalSymptomsToSave[] = [];

        for (let ind = 0; ind < animalSymptoms.length; ind++) {
            let newSymptom: IAnimalSymptomsToSave = {
                Code: animalSymptoms[ind].code,
                AnimalIds: animalDataMatcher(
                    animalsInput.map((an) => {
                        let str = '';
                        for (let index = 0; index < animalSymptoms[ind].animals.length; index++) {
                            if (
                                animalSymptoms[ind].animals[index].toString() ===
                                an.value.toString()
                            ) {
                                str = an.text;
                            }
                        }
                        return str;
                    })
                ),
                Info: getSymptomInfo(animalSymptoms[ind].code),
            };

            animalSymptomsToSave.push(newSymptom);
        }

        let confirmationToSave = false;
        if (auth.canUpdateChainInformationAdmin) {
            confirmationToSave = formConfirmation;

            if (chainInformationId) {
                chainInformationApi
                    .saveFormConfirmation(confirmationToSave, chainInformationId)
                    .then(() => {
                        props.onClose();
                    });
            } else {
                enqueueSnackbar(t('AnelmaChainInformation:1055'), {
                    variant: 'error',
                });
                props.onClose();
            }
        } else {
            if (mode === 'edit') {
                const newSignature = {
                    signatory: `${user.data.firstName}  ${user.data.lastName}`,
                    signatureDate: moment(),
                };

                let formData: IChainInformationUpdateData = {
                    ChainInformationId: chainInformationId,
                    AnnouncementId: announcementId,
                    MedicatedFeed: medicatedFeed,
                    HealthInformation: healthInformation,
                    AuthorityRegulations: authorityRegulations,
                    ForeignObject: foreignObject,
                    GmFree: gmFree,
                    MedicalAccounting: medicalAccounting,
                    WaitingTimeOver: waitingTimeOver,
                    NasevaInfo: nasevaInfo,
                    SlaughterHouseInfo: slaughterHouseInfo,
                    MedicationInfo: medicationInfo,
                    Anomalies: anomalies,
                    Regulations: regulations,
                    SamplingOrder: samplingOrder,
                    VetName: vetName,
                    AdditionalInfo: additionalInfo,
                    ForeignObjectInfo: foreignObjectInfo,
                    AnimalSymptoms: animalSymptomsToSave,
                    OtherSymptom: otherSymptom,
                    AnimalDiseases: animalDiseasesToSave,
                    OtherDisease: otherDisease,
                    MedicatedAnimals: medicatedAnimals,
                    FormConfirmation: confirmationToSave,
                    Signature: confirmation,
                    Signatory: newSignature.signatory,
                    SignatureDate: newSignature.signatureDate.format('YYYY-MM-DDTHH:mm:ss'),
                };

                chainInformationApi
                    .updateChainInformation(formData, chainInformationId)
                    .then(() => {
                        enqueueSnackbar(t('AnelmaGeneral:1018'), {
                            variant: 'success',
                        });
                        props.onClose();
                    });
            } else {
                let formData: IChainInformationData = {
                    AnnouncementId: announcementId,
                    MedicatedFeed: medicatedFeed,
                    HealthInformation: healthInformation,
                    AuthorityRegulations: authorityRegulations,
                    ForeignObject: foreignObject,
                    GmFree: gmFree,
                    MedicalAccounting: medicalAccounting,
                    WaitingTimeOver: waitingTimeOver,
                    NasevaInfo: nasevaInfo,
                    SlaughterHouseInfo: slaughterHouseInfo,
                    MedicationInfo: medicationInfo,
                    Anomalies: anomalies,
                    Regulations: regulations,
                    SamplingOrder: samplingOrder,
                    VetName: vetName,
                    AdditionalInfo: additionalInfo,
                    ForeignObjectInfo: foreignObjectInfo,
                    AnimalSymptoms: animalSymptomsToSave,
                    OtherSymptom: otherSymptom,
                    AnimalDiseases: animalDiseasesToSave,
                    OtherDisease: otherDisease,
                    MedicatedAnimals: medicatedAnimals,
                    FormConfirmation: formConfirmation,
                    Signature: confirmation,
                    Signatory: signature.signatory,
                    SignatureDate: signature.signatureDate.format('YYYY-MM-DDTHH:mm:ss'),
                };
                chainInformationApi.createChainInformation(formData).then((response) => {
                    enqueueSnackbar(t('AnelmaGeneral:1018'), {
                        variant: 'success',
                    });
                    props.onClose();
                });
            }
        }
    };

    const animalDataMatcher = (euIdentifiers: string[]) => {
        let ret: string[] = [];
        for (let i = 0; i < euIdentifiers.length; i++) {
            let animalId = animals.find((a) => a.EuIdentifier === euIdentifiers[i])?.AnimalId;
            if (animalId !== undefined && euIdentifiers[i].length > 0) {
                ret.push(animalId);
            }
        }
        return ret;
    };

    const getAnimalsBySymptom = (code: string): number[] => {
        let ret: number[] = [];
        if (animalSymptoms.length > 0) {
            ret =
                animalSymptoms
                    .find((_, i) => Number(_.code) === Number(code))
                    ?.animals.map((a) => Number(a)) || [];
        }
        return ret;
    };

    //Handlers

    const handleAnimalSymptoms = (code: string, value: string[]) => {
        let newSymptom: IAnimalSymptoms = {
            code: code,
            animals: value,
        };
        if (animalSymptoms.find((_, i) => Number(_.code) === Number(newSymptom.code))) {
            let newArray = [...animalSymptoms].filter((a) => Number(a.code) !== Number(code));
            newArray.push(newSymptom);

            setAnimalSymptoms([...newArray]);
        } else {
            setAnimalSymptoms((previousState) => [...previousState, newSymptom]);
        }
    };

    const handleAnimalSymptomCodes = (code: string) => {
        if (animalSymptomCodes.includes(code)) {
            handleAnimalSymptoms(code, []);
            setAnimalSymptomCodes((previousState) => [
                ...previousState.filter((_, i) => _ !== code),
            ]);
        } else {
            setAnimalSymptomCodes((previousState) => [...previousState, code]);
        }
    };

    const handleAnimalDiseases = (code: string, value: Date) => {
        let newDisease: IAnimalDiseases = {
            Code: code,
            DiscoveryDate: value.toString(),
            Info: '',
        };
        if (animalDiseases.find((_, i) => _.Code === newDisease.Code)) {
            let oldArr = [...animalDiseases];
            let index = oldArr.findIndex((_, i) => _.Code === newDisease.Code);
            let date1 = moment(animalDiseases.find((d) => d.Code === code)?.DiscoveryDate).format(
                dayFormat
            );
            let date2 = moment(value).format(dayFormat);

            if (date1 === date2) {
                oldArr.splice(index, 1);
            } else {
                oldArr.splice(index, 1, newDisease);
            }
            setAnimalDiseases(oldArr);
        } else {
            setAnimalDiseases((previousState) => [...previousState, newDisease]);
        }
    };

    const handleAnimalDiseaseCodes = (code: string) => {
        if (animalDiseaseCodes.includes(code)) {
            setAnimalDiseaseCodes((previousState) => [
                ...previousState.filter((_, i) => _ !== code),
            ]);
        } else {
            setAnimalDiseaseCodes((previousState) => [...previousState, code]);
        }
    };

    const handleAnimalMedication = (animal: IChainInformationAnimal) => {
        if (medicatedAnimals.includes(animal.guid)) {
            setMedicatedAnimals((previousState) => [
                ...previousState.filter((_, i) => _ !== animal.guid),
            ]);
        } else {
            setMedicatedAnimals((previousState) => [...previousState, animal.guid]);
        }
    };

    const handleAnimalSalesTypes = (animals: IAnimalAnnouncementAnimal[]) => {
        let ret = '';
        var salesArray: string[] = [];
        for (let i = 0; i < animals.length; i++) {
            var sales =
                salesTypeParams?.find((s) => s.code.toString() === animals[i].SalesType.toString())
                    ?.text || '';

            salesArray.push(sales);
        }

        for (let index = 0; index < salesArray.length; index++) {
            var item = salesArray.toString().match(salesArray[index]);

            let types = salesArray.filter((s) => s === salesArray[index]);

            if (item !== null)
                if (item.length > 0) {
                    if (!ret.includes(' ' + types[0] + ' ')) {
                        ret = ret + ' ' + types[0].toString() + ' ' + types.length.toString();
                    }
                }
        }

        return ret;
    };

    const saveButtonDisabled = () => {
        if (auth.canUpdateChainInformationAdmin) {
            return false;
        } else if (readOnly) {
            return true;
        }
    };

    // UI Elements

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
                disabled: props.newAnnouncement === true,
            },
        ],
        right: [
            {
                onClick: () => prepareSave(),
                type: 'save',
                disabled: saveButtonDisabled(),
            },
        ],
    };

    return (
        <>
            {isLoading === false && (
                <ADialog open onClose={props.onClose}>
                    <ADialogTitle>{t('AnelmaChainInformation:1000')}</ADialogTitle>

                    <ADialogContent size='xl'>
                        <Grid container>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <ATextInput
                                    id={'AnnouncementNumber'}
                                    label={t('AnelmaChainInformation:1001')}
                                    onChange={(v) => null}
                                    validator={validator}
                                    disabled
                                    value={announcementNumber}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <ATextInput
                                    id={'AnnouncementAnimals'}
                                    label={t('AnelmaChainInformation:1002')}
                                    onChange={(v) => null}
                                    validator={validator}
                                    disabled
                                    value={handleAnimalSalesTypes(animals)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <ATextInput
                                    id={'slaughter-house'}
                                    label={t('AnelmaChainInformation:1003')}
                                    onChange={(v) => null}
                                    validator={validator}
                                    disabled
                                    value={t('AnelmaChainInformation:1004')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <ATextInput
                                    id={'announcement-animals'}
                                    label={t('AnelmaChainInformation:1005')}
                                    onChange={(v) => null}
                                    validator={validator}
                                    disabled
                                    value={t('AnelmaChainInformation:1006')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <ATextInput
                                    id={'producer-number'}
                                    label={t('AnelmaChainInformation:1007')}
                                    onChange={(v) => null}
                                    validator={validator}
                                    disabled
                                    value={props.producerNumber || parsedContext?.producerId || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <ATextInput
                                    id={'company-name'}
                                    label={t('AnelmaChainInformation:1008')}
                                    onChange={(v) => null}
                                    validator={validator}
                                    disabled
                                    value={props.companyName || parsedContext?.customerName || ''}
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                            <Grid container>
                                <Grid item>
                                    <h5>{t('AnelmaChainInformation:1009')}</h5>
                                </Grid>
                                {animals.map((item, index) => (
                                    <Grid item sm={12}>
                                        <FormControlLabel
                                            disabled={readOnly}
                                            checked={medicatedAnimals.includes(item.AnimalId)}
                                            onChange={() =>
                                                handleAnimalMedication({
                                                    euIdentifier: item.EuIdentifier,
                                                    guid: item.AnimalId,
                                                })
                                            }
                                            control={<GreenCheckBox />}
                                            label={item.EuIdentifier}
                                            labelPlacement='start'
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <h5>{t('AnelmaChainInformation:1012')}</h5>
                                    <RadioGroup>
                                        <FormControlLabel
                                            disabled={readOnly}
                                            checked={medicatedFeed === false}
                                            onChange={() => {
                                                setMedicatedFeed(false);
                                                setMedicalAccounting(false);
                                                setMedicationInfo('');
                                                setWaitingTimeOver(false);
                                            }}
                                            control={<GreenRadio />}
                                            label={t('AnelmaChainInformation:1013')}
                                            labelPlacement='end'
                                        />
                                        <FormControlLabel
                                            disabled={readOnly}
                                            checked={medicatedFeed === true}
                                            onChange={() => setMedicatedFeed(true)}
                                            control={<GreenRadio />}
                                            label={t('AnelmaChainInformation:1014')}
                                            labelPlacement='end'
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {medicatedFeed === true && (
                                <Grid container>
                                    <Grid item>
                                        <FormControlLabel
                                            disabled={readOnly}
                                            value={medicalAccounting}
                                            checked={medicalAccounting === true}
                                            onChange={() =>
                                                setMedicalAccounting(!medicalAccounting)
                                            }
                                            control={<GreenCheckBox />}
                                            label={t('AnelmaChainInformation:1015')}
                                            labelPlacement='end'
                                        />
                                        <ATextInput
                                            disabled={readOnly}
                                            id='medication-info'
                                            label={t('AnelmaChainInformation:1016')}
                                            onChange={(v) => setMedicationInfo(v)}
                                            required={!medicalAccounting || false}
                                            validator={validator}
                                            value={medicationInfo}
                                            lengthBoundaryName='AnelmaChainInformation:TextFieldLength'
                                        />
                                        <FormControlLabel
                                            disabled={readOnly}
                                            value={waitingTimeOver}
                                            checked={waitingTimeOver === true}
                                            onChange={() => {
                                                setWaitingTimeOver(!waitingTimeOver);
                                            }}
                                            control={<GreenCheckBox />}
                                            label={t('AnelmaChainInformation:1017')}
                                            labelPlacement='end'
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container>
                                <Grid item>
                                    <FormControl>
                                        <h5>{t('AnelmaChainInformation:1018')}</h5>
                                        <RadioGroup>
                                            <FormControlLabel
                                                disabled={readOnly}
                                                checked={healthInformation === false}
                                                onChange={() => {
                                                    setHealthInformation(false);
                                                    setAnimalDiseaseCodes([]);
                                                    setAnimalDiseases([]);
                                                    setAnimalSymptomCodes([]);
                                                    setAnimalSymptoms([]);
                                                    setSlaughterHouseInfo(false);
                                                    setOtherDisease('');
                                                    setOtherSymptom('');
                                                    setNasevaInfo(false);
                                                    setAnomalies('');
                                                    setSamplingOrder('');
                                                }}
                                                control={<GreenRadio />}
                                                label={t('AnelmaChainInformation:1013')}
                                                labelPlacement='end'
                                            />
                                            <FormControlLabel
                                                disabled={readOnly}
                                                style={{
                                                    color: validationState.healthInformation
                                                        ? 'inherit'
                                                        : 'red',
                                                }}
                                                checked={healthInformation === true}
                                                onChange={() => setHealthInformation(true)}
                                                control={<GreenRadio />}
                                                label={t('AnelmaChainInformation:1019')}
                                                labelPlacement='end'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {healthInformation === true && (
                                <Grid container>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item>
                                                <Typography>
                                                    {t('AnelmaChainInformation:1020')}
                                                </Typography>
                                            </Grid>
                                            {symptoms.length > 0 &&
                                                symptoms.map((item, index) => (
                                                    <Grid
                                                        container
                                                        style={{ justifyContent: 'space-between' }}
                                                    >
                                                        <Grid item>
                                                            {index === 0 && (
                                                                <h5>
                                                                    {t(
                                                                        'AnelmaChainInformation:1021'
                                                                    )}
                                                                </h5>
                                                            )}
                                                            <FormControlLabel
                                                                disabled={readOnly}
                                                                key={index}
                                                                value={animalSymptomCodes.includes(
                                                                    item.code
                                                                )}
                                                                onChange={() =>
                                                                    handleAnimalSymptomCodes(
                                                                        item.code
                                                                    )
                                                                }
                                                                checked={animalSymptomCodes.includes(
                                                                    item.code
                                                                )}
                                                                control={<GreenCheckBox />}
                                                                label={item.text}
                                                                labelPlacement='end'
                                                            />
                                                        </Grid>
                                                        {animalSymptomCodes.includes(item.code) && (
                                                            <Grid item sm={6}>
                                                                {index === 0 && (
                                                                    <h5>
                                                                        {t(
                                                                            'AnelmaChainInformation:1010'
                                                                        )}
                                                                    </h5>
                                                                )}
                                                                <AMultiselect
                                                                    disabled={readOnly}
                                                                    getOptionLabel={(
                                                                        o: IAnimalInputValue
                                                                    ) => o.text}
                                                                    id='animal-symptoms'
                                                                    items={animalsInput}
                                                                    keyProp='value'
                                                                    label={t(
                                                                        'AnelmaChainInformation:1057'
                                                                    )}
                                                                    onChange={(v) =>
                                                                        handleAnimalSymptoms(
                                                                            item.code,
                                                                            v as string[]
                                                                        )
                                                                    }
                                                                    required
                                                                    validator={validator}
                                                                    value={getAnimalsBySymptom(
                                                                        item.code
                                                                    )}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                ))}
                                            {animalSymptomCodes.includes('0') && (
                                                <Grid item sm={12}>
                                                    <ATextInput
                                                        disabled={readOnly}
                                                        id='other-symptom'
                                                        label={t('AnelmaChainInformation:1046')}
                                                        lengthBoundaryName='AnelmaChainInformation:TextFieldLength'
                                                        onChange={(v) => setOtherSymptom(v)}
                                                        required
                                                        validator={validator}
                                                        value={otherSymptom}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid container>
                                            <Grid item>
                                                <h5>{t('AnelmaChainInformation:1022')}</h5>
                                            </Grid>
                                            {diseases.length > 0 &&
                                                diseases.map((item, index) => (
                                                    <Grid
                                                        container
                                                        style={{ justifyContent: 'space-between' }}
                                                    >
                                                        <Grid item>
                                                            <FormControlLabel
                                                                disabled={readOnly}
                                                                key={index}
                                                                value={animalDiseaseCodes.includes(
                                                                    item.code
                                                                )}
                                                                checked={animalDiseaseCodes.includes(
                                                                    item.code
                                                                )}
                                                                onChange={() => {
                                                                    handleAnimalDiseaseCodes(
                                                                        item.code
                                                                    );
                                                                    handleAnimalDiseases(
                                                                        item.code,
                                                                        moment().toDate()
                                                                    );
                                                                }}
                                                                control={<GreenCheckBox />}
                                                                label={item.text}
                                                                labelPlacement='end'
                                                            />
                                                        </Grid>
                                                        {animalDiseaseCodes.includes(item.code) && (
                                                            <Grid item sm={6}>
                                                                <ADateInput
                                                                    disabled={readOnly}
                                                                    id=''
                                                                    label={t(
                                                                        'AnelmaChainInformation:1024'
                                                                    )}
                                                                    onChange={(d) =>
                                                                        handleAnimalDiseases(
                                                                            item.code,
                                                                            moment(d).toDate()
                                                                        )
                                                                    }
                                                                    value={moment(
                                                                        animalDiseases.find(
                                                                            (_, i) =>
                                                                                _.Code === item.code
                                                                        )?.DiscoveryDate
                                                                    ).toDate()}
                                                                    validator={validator}
                                                                    required
                                                                ></ADateInput>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                ))}
                                            {animalDiseaseCodes.includes('0') && (
                                                <Grid item sm={12}>
                                                    <ATextInput
                                                        disabled={readOnly}
                                                        id='other-disease'
                                                        label={t('AnelmaChainInformation:1047')}
                                                        lengthBoundaryName='AnelmaChainInformation:TextFieldLength'
                                                        onChange={(v) => setOtherDisease(v)}
                                                        required
                                                        validator={validator}
                                                        value={otherDisease}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid container style={{ marginTop: '20px' }}>
                                            <Grid item sm={12}>
                                                <FormControlLabel
                                                    disabled={readOnly}
                                                    value={slaughterHouseInfo}
                                                    onChange={() =>
                                                        setSlaughterHouseInfo(!slaughterHouseInfo)
                                                    }
                                                    control={<GreenCheckBox />}
                                                    checked={slaughterHouseInfo === true}
                                                    label={t('AnelmaChainInformation:1023')}
                                                    labelPlacement='end'
                                                />
                                            </Grid>

                                            <Grid item sm={12}>
                                                <FormControlLabel
                                                    disabled={readOnly}
                                                    value={nasevaInfo}
                                                    onChange={() => setNasevaInfo(!nasevaInfo)}
                                                    checked={nasevaInfo === true}
                                                    control={<GreenCheckBox />}
                                                    label={t('AnelmaChainInformation:1053')}
                                                    labelPlacement='end'
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container style={{ marginTop: '20px' }}>
                                            <Grid item sm={12}>
                                                <Typography>
                                                    {t('AnelmaChainInformation:1026')}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <ATextInput
                                                    disabled={readOnly}
                                                    id='anomalies'
                                                    label={t('AnelmaChainInformation:1020')}
                                                    lengthBoundaryName='AnelmaChainInformation:TextFieldLength'
                                                    onChange={(v) => setAnomalies(v)}
                                                    validator={validator}
                                                    value={anomalies}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: '20px' }}>
                                            <Grid item sm={12}>
                                                <Typography>
                                                    {t('AnelmaChainInformation:1027')}
                                                </Typography>
                                            </Grid>

                                            <Grid item sm={12}>
                                                <ATextInput
                                                    disabled={readOnly}
                                                    id='sampling-order'
                                                    label={t('AnelmaChainInformation:1028')}
                                                    lengthBoundaryName='AnelmaChainInformation:TextFieldLength'
                                                    onChange={(v) => setSamplingOrder(v)}
                                                    validator={validator}
                                                    value={samplingOrder}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container>
                                <Grid item>
                                    <FormControl>
                                        <h5>{t('AnelmaChainInformation:1029')}</h5>
                                        <RadioGroup>
                                            <FormControlLabel
                                                disabled={readOnly}
                                                checked={authorityRegulations === false}
                                                onChange={() => {
                                                    setAuthorityRegulations(false);
                                                    setRegulations('');
                                                }}
                                                control={<GreenRadio />}
                                                label={t('AnelmaChainInformation:1013')}
                                                labelPlacement='end'
                                            />
                                            <FormControlLabel
                                                disabled={readOnly}
                                                checked={authorityRegulations === true}
                                                onChange={() => setAuthorityRegulations(true)}
                                                control={<GreenRadio />}
                                                label={t('AnelmaChainInformation:1030')}
                                                labelPlacement='end'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12}>
                                    {authorityRegulations === true && (
                                        <ATextInput
                                            disabled={readOnly}
                                            id='regulations'
                                            label={t('AnelmaChainInformation:1031')}
                                            lengthBoundaryName='AnelmaChainInformation:TextFieldLength'
                                            onChange={(v) => setRegulations(v)}
                                            validator={validator}
                                            required={authorityRegulations === true}
                                            value={regulations}
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item>
                                    <FormControl>
                                        <h5>{t('AnelmaChainInformation:1048')}</h5>
                                        <RadioGroup>
                                            <FormControlLabel
                                                disabled={readOnly}
                                                checked={foreignObject === false}
                                                onChange={() => {
                                                    setForeignObject(false);
                                                    setForeignObjectInfo('');
                                                }}
                                                control={<GreenRadio />}
                                                label={t('AnelmaChainInformation:1013')}
                                                labelPlacement='end'
                                            />
                                            <FormControlLabel
                                                disabled={readOnly}
                                                checked={foreignObject === true}
                                                onChange={() => {
                                                    setForeignObject(true);
                                                }}
                                                control={<GreenRadio />}
                                                label={t('AnelmaChainInformation:1032')}
                                                labelPlacement='end'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12}>
                                    {foreignObject === true && (
                                        <ATextInput
                                            disabled={readOnly}
                                            id='foreign-object'
                                            label={t('AnelmaChainInformation:1033')}
                                            onChange={(v) => setForeignObjectInfo(v)}
                                            lengthBoundaryName='AnelmaChainInformation:TextFieldLength'
                                            validator={validator}
                                            required={foreignObject === true}
                                            value={foreignObjectInfo}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item>
                                    <h5>{t('AnelmaChainInformation:1034')}</h5>
                                    <ATextInput
                                        disabled={readOnly}
                                        id='vet-name'
                                        label={t('AnelmaChainInformation:1035')}
                                        lengthBoundaryName='AnelmaChainInformation:TextFieldLength'
                                        onChange={(v) => setVetName(v)}
                                        required
                                        validator={validator}
                                        value={vetName}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item>
                                    <FormControl>
                                        <h5>{t('AnelmaChainInformation:1036')}</h5>
                                        <RadioGroup>
                                            <FormControlLabel
                                                disabled={readOnly}
                                                checked={gmFree === false}
                                                onChange={() => {
                                                    setGmFree(false);
                                                }}
                                                control={<GreenRadio />}
                                                label={t('AnelmaChainInformation:1037')}
                                                labelPlacement='end'
                                            />
                                            <FormControlLabel
                                                disabled={readOnly}
                                                style={{
                                                    color: validationState.gmFree
                                                        ? 'inherit'
                                                        : 'red',
                                                }}
                                                checked={gmFree === true}
                                                onChange={() => setGmFree(true)}
                                                control={<GreenRadio />}
                                                label={t('AnelmaChainInformation:1038')}
                                                labelPlacement='end'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item sm={12}>
                                    <h5>{t('AnelmaChainInformation:1039')}</h5>
                                </Grid>
                                <Grid item sm={12}>
                                    <ATextarea
                                        disabled={readOnly}
                                        id='additional-info'
                                        label={''}
                                        onChange={(v) => setAdditionalInfo(v)}
                                        validator={validator}
                                        required={gmFree || false}
                                        value={additionalInfo}
                                        lengthBoundaryName='AnelmaChainInformation:AdditionalInfoLength'
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <h5>{t('AnelmaChainInformation:1040')}</h5>

                                <FormControlLabel
                                    disabled={readOnly}
                                    value={confirmation}
                                    onChange={() => setConfirmation(!confirmation)}
                                    control={<GreenCheckBox />}
                                    checked={confirmation === true}
                                    label={t('AnelmaChainInformation:1041')}
                                    labelPlacement='end'
                                />
                            </Grid>

                            <Grid container>
                                <Grid item>
                                    <ADateInput
                                        id='save-date'
                                        label={t('AnelmaChainInformation:1043')}
                                        onChange={() => {}}
                                        disabled
                                        value={signature.signatureDate.toDate()}
                                        validator={validator}
                                        withoutContainer
                                    ></ADateInput>
                                </Grid>
                                <Grid item>
                                    <ATextInput
                                        id='signature'
                                        label={t('AnelmaChainInformation:1044')}
                                        onChange={() => {}}
                                        disabled
                                        value={signature.signatory}
                                        validator={validator}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ textAlign: 'center', marginTop: '30px' }}>
                            {auth.canUpdateChainInformationAdmin && (
                                <ASwitch
                                    checked={formConfirmation}
                                    id='form-confirmation'
                                    label={t('AnelmaChainInformation:1045')}
                                    onChange={(v) => setFormConfirmation(v)}
                                    validator={validator}
                                ></ASwitch>
                            )}
                        </Grid>
                    </ADialogContent>
                    <ADialogActions buttons={footerActionButtons}></ADialogActions>
                </ADialog>
            )}
        </>
    );
}
