import { useEffect, useState } from 'react';
// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
//Core
import { ResourceTextApplication, paramRepository, ParameterValues } from '../../core/resources';
// Interfaces
import { IPaymentAnimals } from '../../interfaces/IAccounting';
import { Type } from './AccountingDetailView';

// Common
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';
import { TextsWithTooltip, TextWithTooltip, ViewTitle } from '../../common/typography';
import InvoiceMathHelper from '../invoices/InvoiceMathHelper';

export interface PaymentAnimalsGridProps {
    data: IPaymentAnimals[];
    type: number;
}

export default function PaymentAnimalsDetailGrid(props: PaymentAnimalsGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccounting', 'AnelmaLayout']);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues>([]);
    const [breedShortParams, setBreedShortParams] = useState<ParameterValues>([]);

    enum ColumnsWidth {
        minSize = 80,
        midSize = 120,
        maxSize = 150,
    }
    const rowsPaymentAnimals = props.data.map((i) => mergeRight(i, { id: i.AnimalIdentifier }));
    const columnsPaymentAnimals: AGridColumns = [
        gridColumns.singleSelectText(
            'AnimalIdentifier',
            props.data,
            t('AnelmaAccounting:1052'),
            ColumnsWidth.maxSize
        ),
        {
            field: 'SaleType',
            sortable: false,
            headerName: t('AnelmaAccounting:1053'),
            description: t('AnelmaAccounting:1094'),
            type: 'string',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                let foundParam = salesTypeParams.find(
                    (p) => Number(p.code) === Number(params.value)
                );
                return <TextWithTooltip text={foundParam?.text ?? '-'} />;
            },
        },

        {
            field: 'Weight',
            sortable: false,
            headerName: t('AnelmaAccounting:1054'),
            description: t('AnelmaAccounting:1100'),
            type: 'number',
            width: ColumnsWidth.minSize + 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'BasePrice',
            sortable: false,
            headerName: t('AnelmaAccounting:1055'),
            description: t('AnelmaAccounting:1089'),
            width: ColumnsWidth.minSize + 20,
            type: 'number',
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },

        {
            field: 'DailyGrowth',
            sortable: false,
            headerName: t('AnelmaAccounting:1056'),
            description: t('AnelmaAccounting:1090'),
            type: 'number',
            width: ColumnsWidth.minSize + 30,
        },
        {
            field: 'DailyGrowthAllowance',
            sortable: false,
            headerName: t('AnelmaAccounting:1057'),
            description: t('AnelmaAccounting:1058'),
            type: 'number',
            width: ColumnsWidth.midSize - 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'QualityClass',
            sortable: false,
            headerName: t('AnelmaAccounting:1059'),
            type: 'number',
            width: ColumnsWidth.minSize,
            align: 'left',
            headerAlign: 'left'
        },

        {
            field: 'QualityAffectOnPrice',
            sortable: false,
            headerName: t('AnelmaAccounting:1060'),
            description: t('AnelmaAccounting:1061'),
            type: 'number',
            width: ColumnsWidth.midSize - 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },

        {
            field: 'FatClass',
            sortable: false,
            headerName: t('AnelmaAccounting:1062'),
            type: 'string',
            width: ColumnsWidth.minSize,
        },
        {
            field: 'FatClassAffectOnPrice',
            sortable: false,
            headerName: t('AnelmaAccounting:1063'),
            description: t('AnelmaAccounting:1064'),
            type: 'number',
            width: ColumnsWidth.minSize + 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'Breed',
            sortable: false,
            headerName: t('AnelmaAccounting:1065'),
            type: 'number',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                let foundvalue = breedShortParams.find(
                    (p) => Number(p.code) == Number(params.value)
                );
                return foundvalue?.text ?? '';
            },
        },

        {
            field: 'BreedAllowance',
            sortable: false,
            headerName: t('AnelmaAccounting:1066'),
            description: t('AnelmaAccounting:1091'),
            type: 'number',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'OtherAllowancesSum',
            sortable: false,
            headerName: t('AnelmaAccounting:1067'),
            type: 'number',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'ItemPriceSum',
            sortable: false,
            headerName: t('AnelmaAccounting:1069'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'PriceBeforeTaxes',
            sortable: false,
            headerName: t('AnelmaAccounting:1070'),
            description: t('AnelmaAccounting:1103'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'RemovalSumBeforeTaxes',
            sortable: false,
            headerName: t('AnelmaAccounting:1097'),
            description: t('AnelmaAccounting:1098'),
            type: 'number',
            width: ColumnsWidth.midSize,
            valueGetter: (params) => {
                return params.value !== '0'
                    ? InvoiceMathHelper.thousandLeading(params.value as number)
                    : '';
            },
        },
    ];

    const columnsPaymentAnimalsType: AGridColumns = [
        gridColumns.singleSelectText(
            'AnimalIdentifier',
            props.data,
            t('AnelmaAccounting:1052'),
            ColumnsWidth.maxSize
        ),
        {
            field: 'SaleType',
            sortable: false,
            headerName: t('AnelmaAccounting:1053'),
            description: t('AnelmaAccounting:1094'),
            type: 'string',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                const foundParam = salesTypeParams.find(
                    (p) => Number(p.code) === Number(params.value)
                );
                return <TextWithTooltip text={foundParam?.text ?? '-'} />;
            },
        },

        {
            field: 'Weight',
            sortable: false,
            headerName: t('AnelmaAccounting:1095'),
            description: t('AnelmaAccounting:1096'),
            type: 'number',
            width: ColumnsWidth.minSize + 20,
            renderCell: (params) => {
                return params.value as number;
            },
        },
        {
            field: 'BasePrice',
            sortable: false,
            headerName: t('AnelmaAccounting:1055'),
            description: t('AnelmaAccounting:1089'),
            width: ColumnsWidth.minSize + 20,
            type: 'number',
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },

        {
            field: 'DailyGrowth',
            sortable: false,
            headerName: t('AnelmaAccounting:1056'),
            description: t('AnelmaAccounting:1090'),
            type: 'number',
            width: ColumnsWidth.minSize + 30,
        },
        {
            field: 'AdditionalKilos',
            sortable: false,
            headerName: t('AnelmaAccounting:1092'),
            description: t('AnelmaAccounting:1092'),
            type: 'number',
            width: ColumnsWidth.minSize + 10,
        },
        {
            field: 'AdditionalKiloPrice',
            sortable: false,
            headerName: t('AnelmaAccounting:1038'),
            type: 'number',
            width: ColumnsWidth.midSize - 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'AdditionalKilosAllowance',
            sortable: false,
            headerName: t('AnelmaAccounting:1093'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'QualityClass',
            sortable: false,
            headerName: t('AnelmaAccounting:1059'),
            type: 'string',
            width: ColumnsWidth.minSize,
        },
        {
            field: 'QualityAffectOnPrice',
            sortable: false,
            headerName: t('AnelmaAccounting:1060'),
            description: t('AnelmaAccounting:1061'),
            type: 'number',
            width: ColumnsWidth.minSize + 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'Breed',
            sortable: false,
            headerName: t('AnelmaAccounting:1065'),
            type: 'string',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                let foundvalue = breedShortParams.find(
                    (p) => Number(p.code) == Number(params.value)
                );
                return foundvalue?.text ?? '';
            },
        },
        {
            field: 'BreedAllowance',
            sortable: false,
            headerName: t('AnelmaAccounting:1066'),
            description: t('AnelmaAccounting:1091'),
            type: 'number',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'OtherAllowancesSum',
            sortable: false,
            headerName: t('AnelmaAccounting:1067'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'PriceBeforeTaxes',
            sortable: false,
            headerName: t('AnelmaAccounting:1070'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
    ];

    useEffect(() => {
        paramRepository
            .load(['AnelmaAccounting', 'AnelmaAnimalPayload', 'AnelmaInvoice'])
            .then(() => {
                setSalesTypeParams(paramRepository.resource('AnelmaAnimalPayload', 'SalesType'));

                //*Experimenting Note* if required to return additional properties with Parametes e.g. boolean flag etc
                setBreedShortParams(
                    paramRepository.resource('AnelmaInvoice', 'BreedShort').map((resourceParam) => {
                        return {
                            ...resourceParam,
                            //some experimenting stuff
                            type: 'breedshort',
                            additionalInfo: [{ show: false, sort: true }],
                        };
                    })
                );
            });
    }, []);

    /**
     * Disable sorting on columns done differently here
     * https://mui.com/components/data-grid/sorting/#disable-the-sorting
     * <DataGrid columns={[...columns, { field: 'AnimalIdentifier', sortable: false }]} />
     */
    return (
        <div>
            {props.type === Type.BovinePurchase && (
                <ADataGrid
                    columns={columnsPaymentAnimalsType}
                    rows={rowsPaymentAnimals.length > 0 ? rowsPaymentAnimals : []}
                ></ADataGrid>
            )}

            {props.type !== Type.BovinePurchase && (
                <ADataGrid
                    columns={columnsPaymentAnimals}
                    rows={rowsPaymentAnimals.length > 0 ? rowsPaymentAnimals : []}
                ></ADataGrid>
            )}
        </div>
    );
}
