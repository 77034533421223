// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// MUI
import { Divider, Popover } from '@mui/material';
import { styled } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

// Common
import { ViewTitle } from '../common/typography';
import { ALabel } from '../common/inputs';
import { ConfirmDeletionDialog } from '../common/dialog';

// Interfaces
import { INotification, NotificationTypeEnum } from '../interfaces/INotification';

// Store
// API
// Feature - Notifications
import NotificationItem from './notificationItem/NotificationItem';

const StyledPopover = styled(Popover)(({ theme }) => ({
    overflowY: 'auto',
    '.MuiPopover-paper': {
        height: '600px',
        width: '300px',
    },
}));

interface NotificationsListProps {
    anchor: Element;
    restoreAnchor: () => void;
    data: INotification[];
    setRead: (data: INotification[]) => void;
    deleteNotification: (data: INotification) => void;
    deleteNotifications: () => void;
}

/** Display notifications in a popover. User has to have AnelmaNotifications:LIST access right in place.
 *
 * @props anchor: Element
 * @props restoreAnchor: () => void
 * @props data: INotification[]
 * @props setRead: (data: INotification[]) => void
 * @props deleteNotification: (data: INotification) => void
 * @props deleteNotifications: () => void
 */

export default function NotificationList(props: NotificationsListProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaLayout',
        'AnelmaNotifications',
    ]);

    const popoverOpen = Boolean(props.anchor);

    const [confirmDeleteDialogVisible, setConfirmDeleteDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        if (popoverOpen) setNotificationsRead();
    }, []);

    const setNotificationsRead = () => {
        setTimeout(() => {
            const data = props.data.filter((_) => _.ReadDateTime === null).map((_) => _);
            if (data.length > 0) props.setRead(data);
        }, 2000);
    };

    const getDeleteLabelStyling = () => {
        return props.data.length === 0
            ? { color: '#0000FF' }
            : { color: '#0000FF', cursor: 'pointer' };
    };

    /** Sort by date but prod. plan notifications are always first no matter what.
     *
     * More info on custom comparators: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
     *
     * @param a INotification
     * @param b INotification
     */
    const notificationSort = (a: INotification, b: INotification) => {
        if (a.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear) return -1;
        else if (b.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear) return 1;
        else return new Date(b.Created).valueOf() - new Date(a.Created).valueOf();
    };

    return (
        <>
            <StyledPopover
                anchorEl={props.anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={popoverOpen}
                onClose={props.restoreAnchor}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock={false}
            >
                <div style={{ margin: '5px 5px 0 5px' }}>
                    <ViewTitle>{t('AnelmaLayout:1080')}</ViewTitle>
                    {auth.canDeleteNotifications && (
                        <ALabel
                            onClick={() =>
                                props.data.length > 0 ? setConfirmDeleteDialogVisible(true) : null
                            }
                            sx={getDeleteLabelStyling()}
                            disabled={props.data.length === 0}
                        >
                            {t('AnelmaNotifications:1000')}
                        </ALabel>
                    )}

                    <Divider />

                    <NotificationItem
                        data={[...props.data].sort(notificationSort)}
                        deleteNotification={props.deleteNotification}
                        close={props.restoreAnchor}
                    />
                </div>
            </StyledPopover>

            <ConfirmDeletionDialog
                open={confirmDeleteDialogVisible}
                onClose={() => setConfirmDeleteDialogVisible(false)}
                onDelete={() => props.deleteNotifications()}
                confirmation={t('AnelmaGeneral:1142')}
            />
        </>
    );
}
