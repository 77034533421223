import { ParameterValues, paramRepository } from "../../../core/resources";
import { AnnouncementTypeEnum, FarmingTypeEnum } from "../../../interfaces/enums";
import { IFarmDetails } from "../../../interfaces/IBusinessEntities";
import { getFarmingTypesFromFarmDetails } from "./data";

export interface ISalesTypeParams {
    BreederBroker: ParameterValues;
    RearingUnitBroker: ParameterValues;
    DairyCattleBroker: ParameterValues;
    SucklerCowCattleBroker: ParameterValues;
    BreederSlaughter: ParameterValues;
    RearingUnitSlaughter: ParameterValues;
    DairyCattleSlaughter: ParameterValues;
    SucklerCowCattleSlaughter: ParameterValues;
}

export function getAllowedAnnouncementSalesTypes(
    farmDetails: IFarmDetails, 
    announcementType?: AnnouncementTypeEnum
): Promise<ParameterValues> {
    return getSalesTypeParameters().then(params => {
        return salesTypeParamsForFarmAndAnnouncementType(
            farmDetails,
            params,
            announcementType
        );
    })
}

export function getSalesTypeParameters(): Promise<ISalesTypeParams>{
    return paramRepository.load(['AnelmaBovine']).then(() => {
        return {
            BreederBroker: paramRepository.resource('AnelmaBovine', 'BreederBrokerSale'),
            RearingUnitBroker: paramRepository.resource('AnelmaBovine', 'RearingUnitBrokerSale'),
            DairyCattleBroker: paramRepository.resource('AnelmaBovine', 'DairyCattleBrokerSale'),
            SucklerCowCattleBroker: paramRepository.resource('AnelmaBovine', 'SucklerCowCattleBrokerSale'),
            BreederSlaughter: paramRepository.resource('AnelmaBovine', 'BreederSlaughterSale'),
            RearingUnitSlaughter: paramRepository.resource('AnelmaBovine', 'RearingUnitSlaughterSale'),
            DairyCattleSlaughter: paramRepository.resource('AnelmaBovine', 'DairyCattleSlaughterSale'),
            SucklerCowCattleSlaughter: paramRepository.resource('AnelmaBovine', 'SucklerCowCattleSlaughterSale')
        } as ISalesTypeParams;
    });
}

export function salesTypeParamsForFarmAndAnnouncementType(
    farmDetails: IFarmDetails,
    params: ISalesTypeParams,
    announcementType?: AnnouncementTypeEnum,
): ParameterValues {
    const parameterValues: ParameterValues = [];

    const add = (values: ParameterValues) => {
        for(let v of values){
            if(!parameterValues.find(x => x.code == v.code))
                parameterValues.push(v);
        }
    }

    // Jos tilalla on useampi tuotantosuunta, listaan yhdistetään tilan tuotantosuuntien parametrit
    const farmingTypesInCurrentFarm = getFarmingTypesFromFarmDetails(
        farmDetails.ProductionLines
    );

    for (const farmingType of farmingTypesInCurrentFarm) {
        if (announcementType === AnnouncementTypeEnum.Slaughter ||
            announcementType === AnnouncementTypeEnum.ManualBovineSlaughter) {
            switch (farmingType) {
                case FarmingTypeEnum.Calving: // Emolehmätila
                    add(params.SucklerCowCattleSlaughter);
                    break;
                case FarmingTypeEnum.Dairy: // Lypsykarjatila
                    add(params.DairyCattleSlaughter);
                    break;
                case FarmingTypeEnum.Upbringing: // Kasvattaja
                    add(params.BreederSlaughter);
                    break;
                case FarmingTypeEnum.Transmission: // Välikasvattamo
                    add(params.RearingUnitSlaughter);
                    break;
                default:
                    break;
            }
        } else if (
            announcementType === AnnouncementTypeEnum.Intermediation ||
            announcementType === AnnouncementTypeEnum.ManualBovineIntermediation) {
            switch (farmingType) {
                case FarmingTypeEnum.Calving: // Emolehmätila
                    add(params.SucklerCowCattleBroker);
                    break;
                case FarmingTypeEnum.Dairy: // Lypsykarjatila
                    add(params.DairyCattleBroker);
                    break;
                case FarmingTypeEnum.Upbringing: // Kasvattaja
                    add(params.BreederBroker);
                    break;
                case FarmingTypeEnum.Transmission: // Välikasvattamo
                    add(params.RearingUnitBroker);
                    break;
                default:
                    break;
            }
        } else if (announcementType === undefined) {
            switch (farmingType) {
                case FarmingTypeEnum.Calving: // Emolehmätila
                    add([
                        ...params.SucklerCowCattleSlaughter,
                        ...params.SucklerCowCattleBroker,
                    ]);
                    break;
                case FarmingTypeEnum.Dairy: // Lypsykarjatila
                    add([
                        ...params.DairyCattleSlaughter,
                        ...params.DairyCattleBroker,
                    ]);
                    break;
                case FarmingTypeEnum.Upbringing: // Kasvattaja
                    add([
                        ...params.BreederSlaughter,
                        ...params.BreederBroker,
                    ]);
                    break;
                case FarmingTypeEnum.Transmission: // Välikasvattamo
                    add([
                        ...params.RearingUnitSlaughter,
                        ...params.RearingUnitBroker,
                    ]);
                    break;
                default:
                    break;
            }
        }
    }
    return parameterValues;
}