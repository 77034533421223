import { IManualAnimal } from '../../../interfaces/IAnimal';
import {
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
    IBovineAnnouncementBatchResult,
    IBovineInfo,
    ICollectWeek,
    ICreateAnnouncement,
} from '../../../interfaces/IAnnouncement';
import { getBirthDate } from '../../announcements/helpers/animal';
import { getQuickCowCollectWeeks } from '../../announcements/helpers/collectWeek';
import { removeDashes } from '../../announcements/helpers/euIdentifier';

export const mapCreateAnimalAnnouncementData = (
    producerNumber: number,
    info: string,
    farmId: string,
    animals: IAnimalAnnouncementAnimal[],
    holdingSiteId: string,
    collectWeekStart: ICollectWeek,
    collectWeekEnd: ICollectWeek,
    collectWeekDay: number
): ICreateAnnouncement => {
    return {
        ProducerNumber: producerNumber,
        AnnouncementInfo: info,
        FarmId: farmId,
        BovineInfos: mapAnnouncementAnimalsToBovineInfos(
            animals,
            holdingSiteId,
            collectWeekStart,
            collectWeekEnd,
            collectWeekDay
        ),
    } as ICreateAnnouncement;
};

export const mapAnnouncementAnimalsToBovineInfos = (
    animals: IAnimalAnnouncementAnimal[],
    holdingSiteId: string,
    collectWeekStart: ICollectWeek,
    collectWeekEnd: ICollectWeek,
    collectWeekDay: number
): IBovineInfo[] => {
    let bovineInfos: IBovineInfo[] = [];
    const quickCowCollectWeeks = getQuickCowCollectWeeks();

    for (let animal of animals) {
        let collectWeekStartToUse = collectWeekStart;
        let collectWeekEndToUse = collectWeekEnd;

        if (animal.SalesType === 28) {
            collectWeekStartToUse = quickCowCollectWeeks.Start;
            collectWeekEndToUse = quickCowCollectWeeks.End;
        }

        bovineInfos.push({
            AnimalId: '00000000-0000-0000-0000-000000000000', // overwrite UI-generated animalid
            EuIdentifier: removeDashes(animal.EuIdentifier),
            HoldingSiteId: holdingSiteId,
            SalesType: animal.SalesType,
            Breed: animal.Breed,
            Sex: animal.Sex,
            BirthDate: getBirthDate(animal.BirthDate),
            CollectWeekStart: {
                Year: collectWeekStartToUse.Year,
                Week: collectWeekStartToUse.Week,
            },
            CollectWeekEnd: { Year: collectWeekEndToUse.Year, Week: collectWeekEndToUse.Week },
            CollectWeekDay: collectWeekDay,
            AddInfo: '',
        } as IBovineInfo);
    }

    return bovineInfos;
};

export const mapToManualAnimal = (
    animal: IAnimalAnnouncementAnimal,
    holdingSiteId: string,
    farmGuid: string
): IManualAnimal => {
    return {
        euIdentifier: animal.EuIdentifier,
        holdingSite: holdingSiteId,
        farmGUID: farmGuid,
        birthDate: getBirthDate(animal.BirthDate),
        sex: animal.Sex,
        salesType: animal.SalesType,
        breed: animal.Breed,
    } as IManualAnimal;
};

export const mapAnnouncementBatchToAnnouncementAnimals = (
    batches: IAnimalAnnouncementBatch[]
): IAnimalAnnouncementAnimal[] => {
    let animals: IAnimalAnnouncementAnimal[] = [];
    for (let batch of batches) {
        for (let animal of batch.Animals) {
            animals.push(animal);
        }
    }
    return animals;
};

const mapBatchResultToAnimalAnnouncementAnimal = (
    batch: IBovineAnnouncementBatchResult
) => {
    return {
        AnimalId: batch.AnimalId,
        EuIdentifier: batch.EUIdentifier,
        Sex: batch.Sex,
        Breed: batch.Breed,
        BirthDate: batch.BirthDate,
        SalesType: batch.SalesType,
    } as IAnimalAnnouncementAnimal;
};

export const mapAnnouncementSaveResultBatchesToAnnouncementAnimals = (
    batches: IBovineAnnouncementBatchResult[]
) => {
    return batches.map((b) => mapBatchResultToAnimalAnnouncementAnimal(b));
};

