// Libraries
import { ReactNode } from 'react';

// MUI
import { styled, Button } from '@mui/material';

// Common
import { AButtonBaseProps } from '../../common/buttons';

export interface AActionButtonProps extends AButtonBaseProps {
    icon?: ReactNode;
}

const StyledButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(90deg, #0a4c31 0%, #237a57 100%)',
    borderRadius: '100px',
    textTransform: 'none',
    border: 'none',
}));

export default function AActionButton(props: AActionButtonProps) {
    const { icon, ...buttonProps } = props;

    return <StyledButton {...buttonProps} startIcon={icon} variant='contained' />;
}
