// Libraries
import moment from 'moment';

//Interface
import { IEvent } from '../interfaces/IEvent';
import { IFarmVisit } from '../interfaces/IFarmVisit';
import { CalendarEventTypes } from '../interfaces/enums';

//Feature
import dateUtils from '../core/utils.date';
import utils from '../core/utils';
import { CalendarEvent, ExtendedCalendarEvent } from '../interfaces/calendar/CalendarEvent';

interface EventMapperOptions {
    labels: {
        farmVisitTitle: string;
        anelmaEventTitle: string;
        anelmaRegistrationTitle: string;
    };
}

class EventMapper {
    private options: EventMapperOptions = {
        labels: {
            farmVisitTitle: '',
            anelmaEventTitle: '',
            anelmaRegistrationTitle: '',
        },
    };

    init(options: EventMapperOptions): void {
        this.options = options;
    }

    isAllDayFarmVisit(from: string, to: string): boolean {
        if (moment(from).isBefore(moment(to))) return false;
        return true;
    }

    animalPayloadToEvent(fullData: CalendarEvent): ExtendedCalendarEvent {
        return fullData.Type === CalendarEventTypes.PickupTransMissionAnimal
            ? {
                  allDay: fullData.AllDay,
                  end: moment(fullData.DueDate).toDate(),
                  fullData: fullData,
                  start: moment(fullData.FromDate).toDate(),
                  title: fullData.Title,
                  type: CalendarEventTypes.PickupTransMissionAnimal,
                  tags: fullData.Tags || [],
              }
            : {
                  allDay: fullData.AllDay,
                  end: moment(fullData.DueDate).toDate(),
                  fullData: fullData,
                  start: moment(fullData.FromDate).toDate(),
                  title: fullData.Title,
                  type: CalendarEventTypes.PickupSlaughterAnimal,
                  tags: fullData.Tags || [],
              };
    }

    calendarOwnBookingToEvent(fullData: CalendarEvent): ExtendedCalendarEvent {
        return {
            allDay: fullData.AllDay,
            end: moment(fullData.DueDate).toDate(),
            fullData: fullData,
            start: moment(fullData.FromDate).toDate(),
            title: fullData.Title,
            type: CalendarEventTypes.OwnBookings,
            tags: fullData.Tags || [],
        };
    }

    anelmaEventToEvent(eventData: IEvent): ExtendedCalendarEvent {
        return {
            allDay: true,
            end: moment(eventData.EventValidDue).toDate(),
            fullData: eventData,
            start: moment(eventData.EventValidFrom).toDate(),
            title: `${this.options.labels.anelmaEventTitle}: ${eventData.HeaderFI}`,
            type: CalendarEventTypes.Event,
            tags: eventData.Tags || [],
        };
    }

    anelmaEventToEventRegistration(eventData: IEvent): ExtendedCalendarEvent {
        return {
            allDay: true,
            end: moment(eventData.RegistrationValidDue).toDate(),
            fullData: eventData,
            start: moment(eventData.RegistrationValidFrom).toDate(),

            title: `${this.options.labels.anelmaRegistrationTitle}: ${eventData.HeaderFI}`,
            type: CalendarEventTypes.EventRegistration,
            tags: eventData.Tags || [],
        };
    }
    farmVisitToEvent(eventData: IFarmVisit): ExtendedCalendarEvent {
        return {
            allDay: this.isAllDayFarmVisit(eventData.FromTime, eventData.ToTime),
            end: utils.date.utcStringToLocalMoment(eventData.ToTime)?.toDate(),
            fullData: eventData,
            start: utils.date.utcStringToLocalMoment(eventData.FromTime)?.toDate(),
            title: `${this.options.labels.farmVisitTitle}: ${dateUtils.formatUtcString(
                eventData.FromTime,
                'HH:mm'
            )} ->`,
            type: CalendarEventTypes.FarmVisit,
            tags: [],
        };
    }
}

export default new EventMapper();
