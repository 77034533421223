// Libraries
import { useEffect, useState } from 'react';

// MUI
import { Container } from '@mui/material';

// Store
import { useAppSelector } from '../../../../store/hooks';

// Interfaces
import { IBulletinItem } from '../../../../interfaces/ICommunication';
import { IFarmDetails } from '../../../../interfaces/IBusinessEntities';

// Api
import { getUserAnnouncements } from '../../../../bulletin/bulletinApi';
import companyApi from '../../../../api/companyApi';

// Feature
import './bulletinBar.scss';
import { Context } from '../../../contextSelector/ContextSelector';

export default function BulletinBar() {
    const loginStatus = useAppSelector((state) => state.loginStatus);
    const context = useAppSelector((state) => state.context);
    const userData = useAppSelector((state) => state.userData.data);

    const [items, setItems] = useState<IBulletinItem[]>([]);
    const [index, setIndex] = useState<number>(-1);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();

    useEffect(() => {
        if (context.data.currentContext) initializeFarmDetails(context.data.currentContext);
    }, [context]);

    useEffect(() => {
        if (loginStatus.data.loggedIn) {
            if (farmDetails)
                getUserAnnouncements(farmDetails.Id, userData.Id).then((response) =>
                    setItems(response)
                );
        } else getUserAnnouncements().then((response) => setItems(response));
    }, [farmDetails]);

    useEffect(() => {
        if (items.length > 0) nextItem();
    }, [items]);

    useEffect(() => {
        if (index !== -1)
             if (items.length > index) setTimeout(() => nextItem(), items[index].Duration || 5000);
    }, [index]);

    const initializeFarmDetails = (context: Context) => {
        companyApi.getCompanyByContext(context.context).then((response) => {
            response ? setFarmDetails(response.Entity as IFarmDetails) : setFarmDetails(undefined);
        });
    };

    const nextItem = () => {
        let nextIndex = index + 1;
        if (items.length === nextIndex) nextIndex = 0;
        if (items.length < index) nextIndex = -1;
        setIndex(nextIndex);
    };

    return (
        <>
            {items.length > index && index !== -1 ? (
                <div className='an-header-bulletin'>
                    <Container className='an-header-bulletin-content'>
                        {items[index].Message}
                    </Container>
                </div>
            ) : undefined}
        </>
    );
}
