// Libraries
import { Grid } from '@mui/material';

// MUI
// Core
import FormValidator from '../../../../../../core/FormValidator';

// Common
import { ARadioButtons } from '../../../../../../common/inputs';

// Interfaces
// Store
// API
// Feature
import { AnimalAnnouncementsListViewStrings } from '../../strings/AnimalAnnouncementsListViewStrings';

export interface IControlButtonsProps {
    handleSelectedAnimalAnnouncementTypeChange: (selectedAnimalAnnouncementType: string) => void;
    selectedAnimalAnnouncementType: string;
}

export default function ControlButtons(props: IControlButtonsProps) {
    const validator = new FormValidator();

    return (
        <Grid container spacing={0} sx={{paddingBottom: '20px'}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <ARadioButtons
                    id={AnimalAnnouncementsListViewStrings.ControlButtonsRadio}
                    parameterName={`AnelmaBovine:${AnimalAnnouncementsListViewStrings.AnimalAnnouncementListViewBtns}`}
                    onChange={(v) => props.handleSelectedAnimalAnnouncementTypeChange(String(v))}
                    validator={validator}
                    value={props.selectedAnimalAnnouncementType}
                    sortValues={'default'}
                    withoutContainer
                />
            </Grid>
        </Grid>
    );
}
