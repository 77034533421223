// Interfaces
import { AInputItem, AAutocompleteValue } from '../../../common/inputs';
import { DialogModeEnum } from '../../../interfaces/enums';
import { IAnimalSummary } from '../../../interfaces/IAnimal';
import { IFarmDetails } from '../../../interfaces/IBusinessEntities';

export const initializeHoldingSiteId = (
    mode: DialogModeEnum,
    animalListSelectedAnimals: IAnimalSummary[] | null,
    farmDetails: IFarmDetails | undefined,
    holdingSiteId: string | undefined
): string => {
    if (
        farmDetails === undefined &&
        mode === DialogModeEnum.Create &&
        animalListSelectedAnimals !== null &&
        animalListSelectedAnimals.length > 0
    ) {
        return animalListSelectedAnimals[0].HoldingSiteId;
    } else if (holdingSiteId) {
        return holdingSiteId;
    } else {
        return '';
    }
};

export const getHoldingSiteItems = (
    farmDetails: IFarmDetails | undefined
): AInputItem<AAutocompleteValue>[] => {
    return (
        farmDetails?.HoldingSites?.map((h) => {
            return {
                text: h.HoldingSiteId,
                value: h.HoldingSiteId,
            };
        }) ?? []
    );
};
