// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// MUI
import { Grid } from '@mui/material';

//Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

// Interfaces
import { IFarmDetails } from '../interfaces/IBusinessEntities';
import { IFarmVisit, IFarmVisitor } from '../interfaces/IFarmVisit';
import { DialogModeEnum } from '../interfaces/enums';

// Store
// Common
import { AAddButton, AEditIconButton, ADeleteIconButton } from '../common/buttons';
import ViewLoader from '../common/ViewLoader';
import { ADataGrid, AGridColumns, gridColumns } from '../common/dataGrid';
import { TextWithTooltip, ViewTitle } from '../common/typography';

// API
// Feature
import CreateFarmVisitDialog from './CreateFarmVisitDialog';
import DeleteFarmVisitDialog from './DeleteFarmVisitDialog';
import './styles/farmVisitStyles.scss';

export interface MyFarmVisitProps {
    rootTitle?: boolean;
    showAddButton: boolean;
    farmDetails: IFarmDetails | undefined;
    data: IFarmVisit;
    onClose: () => void;
    columns: AGridColumns;
    allFarmVisitData?: IFarmVisit[];
    visitors: IFarmVisitor[];
    handleMapFarmVisit: Function;
    deleteFarmVisitFromList: (id: number) => void;
    myFarmVisits: IFarmVisit[];
    loading?: boolean;
}

export default function MyFarmVisit(props: MyFarmVisitProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaFarmVisit',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);

    const { data, showAddButton, farmDetails, visitors } = props;
    const [isLoading] = useState<boolean>(props.loading !== undefined ? props.loading : false);
    const [formMode, setFormMode] = useState<DialogModeEnum>(DialogModeEnum.Edit);
    const [createFormVisible, setCreateFormVisible] = useState<boolean>(false);
    const [deleteFormVisible, setDeleteFormVisible] = useState<boolean>(false);
    const [farmVisit, setFarmVisit] = useState<IFarmVisit>(data);
    const [subjects, setSubjects] = useState<ParameterValues>([]);

    useEffect(() => {
        paramRepository.load(['AnelmaFarmVisit']).then(() => {
            setSubjects(paramRepository.resource('AnelmaFarmVisit', 'FarmVisitSubject'));
        });
    }, []);

    const columns: AGridColumns = [
        gridColumns.dateTime('FromTime', t('AnelmaGeneral:1155'), 'DD.MM.YYYY HH:mm'),
        gridColumns.withTooltip({
            field: 'ProducerNumber',
            headerName: t('AnelmaFarmVisit:1007'),
            width: 110,
        }),
        gridColumns.withTooltip({
            field: 'CompanyName',
            headerName: t('AnelmaFarmVisit:1008'),
            width: 175,
        }),
        {
            field: 'ContactId',
            headerName: t('AnelmaFarmVisit:1009'),
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
            valueGetter: (params) => {
                return (
                    visitors.find((visitor) => visitor.Id === params.row.ContactId)?.FullName || '-'
                );
            },
            width: 175,
        },
        {
            field: 'Subject',
            headerName: t('AnelmaFarmVisit:1022'),
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
            valueGetter: (params) => {
                return subjects.find((_) => _.code === params.value?.toString())?.text;
            },
            width: 125,
        },
        gridColumns.withTooltip({
            field: 'Notes',
            headerName: t('AnelmaFarmVisit:1023'),
            width: 250,
        }),
    ];

    const handleFarmVisitDialogVisibility = (
        data: IFarmVisit,
        mode: DialogModeEnum,
        visible: boolean
    ) => {
        switch (mode) {
            case DialogModeEnum.Create:
                setFarmVisit(data);
                setFormMode(DialogModeEnum.Create);
                setCreateFormVisible(visible);
                if (!visible) props.onClose();
                break;
            case DialogModeEnum.Edit:
                setFarmVisit(data);
                setFormMode(DialogModeEnum.Edit);
                setCreateFormVisible(visible);
                break;
            default:
                break;
        }
    };

    const openDeleteForm = (data: IFarmVisit) => {
        setFarmVisit(data);
        setDeleteFormVisible(true);
    };

    const getRows = () => {
        return props.myFarmVisits
            .map((i) => mergeRight(i, { id: i.Id }))
            .sort((a, b) => new Date(b.FromTime).valueOf() - new Date(a.FromTime).valueOf());
    };

    return (
        <div data-robot-id={'my-farm-visit'}>
            {!isLoading ? (
                auth.canViewAllFarmVisits || auth.canViewFarmVisits ? (
                    <>
                        <Grid container>
                            {props.rootTitle && <ViewTitle>{t('AnelmaLayout:1029')}</ViewTitle>}
                            {showAddButton && auth.canCreateNewFarmVisit && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: 'right', paddingRight: '50px' }}
                                    >
                                        <AAddButton
                                            type='action'
                                            onClick={() =>
                                                handleFarmVisitDialogVisibility(
                                                    data,
                                                    DialogModeEnum.Create,
                                                    true
                                                )
                                            }
                                        >
                                            {t('AnelmaFarmVisit:1010')}
                                        </AAddButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {!showAddButton &&
                        props.allFarmVisitData !== undefined &&
                        props.allFarmVisitData.length > 0 ? (
                            <ADataGrid
                                actions={[
                                    (params) => {
                                        const row = params.row as unknown as any;
                                        return auth.canUpdateFarmVisit ||
                                            auth.canViewFarmVisitsProducer ? (
                                            <AEditIconButton
                                                key={`edit-farm-visit-${params.id}`}
                                                onClick={() =>
                                                    handleFarmVisitDialogVisibility(
                                                        row,
                                                        DialogModeEnum.Edit,
                                                        true
                                                    )
                                                }
                                            />
                                        ) : null;
                                    },
                                    (params) => {
                                        const row = params.row as unknown as any;
                                        return (
                                            auth.canDeleteFarmVisit && (
                                                <ADeleteIconButton
                                                    key={`delete-farm-visit-${params.id}`}
                                                    onClick={() => openDeleteForm(row)}
                                                    tooltip={t('AnelmaGeneral:1012')}
                                                />
                                            )
                                        );
                                    },
                                ]}
                                columns={props.columns}
                                rows={props.allFarmVisitData}
                                enableFiltering
                                enableRowNumbers
                                className={'anelma-farm-visit-data-grid'}
                                enablePagination
                            ></ADataGrid>
                        ) : (
                            <ADataGrid
                                actions={[
                                    (params) => {
                                        const row = params.row as unknown as any;
                                        return auth.canUpdateFarmVisit ||
                                            auth.canViewFarmVisitsProducer ? (
                                            <AEditIconButton
                                                key={`edit-farm-visit-${params.id}`}
                                                onClick={() =>
                                                    handleFarmVisitDialogVisibility(
                                                        row,
                                                        DialogModeEnum.Edit,
                                                        true
                                                    )
                                                }
                                            />
                                        ) : null;
                                    },
                                ]}
                                columns={columns}
                                loading={isLoading}
                                rows={getRows()}
                                enableFiltering
                                enableRowNumbers
                                enablePagination
                            ></ADataGrid>
                        )}

                        {createFormVisible && (
                            <CreateFarmVisitDialog
                                mode={formMode}
                                farmDetails={farmDetails}
                                data={farmVisit}
                                onClose={() =>
                                    handleFarmVisitDialogVisibility(
                                        farmVisit,
                                        DialogModeEnum.Create,
                                        false
                                    )
                                }
                                handleMapFarmVisit={props.handleMapFarmVisit}
                            />
                        )}
                        {deleteFormVisible && (
                            <DeleteFarmVisitDialog
                                id={farmVisit.Id}
                                afterDelete={props.deleteFarmVisitFromList}
                                onClose={() => setDeleteFormVisible(false)}
                            ></DeleteFarmVisitDialog>
                        )}
                    </>
                ) : null
            ) : (
                <ViewLoader />
            )}
        </div>
    );
}
