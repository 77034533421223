// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Typography } from '@mui/material';

// Core
import { paramRepository, ResourceTextApplication } from '../../core/resources';

// Common
import { InfoBlock } from '../../common/typography';

// Interfaces
import { AnimalTypeEnum } from '../../interfaces/enums';

// Feature
import { FarmInfoSectionProps } from './FarmInfo';

interface ProductionLineProps extends FarmInfoSectionProps {
    type: AnimalTypeEnum;
}

export default function ProductionLine(props: ProductionLineProps) {
    const { data, getParamText } = props;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    const title = props.type === AnimalTypeEnum.Bovine ? 1023 : 1027;
    const farmingTypes =
        props.type === AnimalTypeEnum.Bovine
            ? paramRepository.resource('AnelmaCompany', 'FarmingTypeBovine')
            : paramRepository.resource('AnelmaCompany', 'FarmingTypePig');
    const productionLines = data.ProductionLines.find((i) => i.Type === props.type)?.FarmingTypes;
    const holdingSites = data.HoldingSites.filter((i) => i.Type === props.type);

    if (!productionLines?.length && !holdingSites.length) return <></>;

    return (
        <div data-robot-id = {"production-line-"+ props.type}>
            {props.bovineProduction && props.porkProduction && (
                <Typography variant='h4'>{t(`AnelmaCompany:${title}`)}</Typography>
            )}
            <div data-robot-id = {"info-block-production-line-"+ props.type}>
            <InfoBlock                
                label={t('AnelmaCompany:1024')}
                value={productionLines
                    ?.map((i) => getParamText(farmingTypes, `00${i}`.substr(-2)))
                    .join(', ')}
            />
            </div>
            <Typography variant='h5'>{t('AnelmaCompany:1103')}</Typography>
            {holdingSites.length ? (
                <Grid container>
                    {holdingSites.map((i, key) => (
                        <Grid item sm={12} key={key} data-robot-id = {"production-line-"+ props.type+ "-holding-site-"+ key}>
                            {i.HoldingSiteId}, {i.Address.Street}, {i.Address.Zip} {i.Address.City}
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <InfoBlock label='' value='' />
            )}
        </div>
    );
}
