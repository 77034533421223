import { AnelmaReportingBtnGroupNameEnum, AnelmaReportTypeEnum } from '../interfaces/enums';
import ReportView from './ReportView';

export interface IFinancialReportProps {
    endQlikSession: (eligible: boolean) => void;
}

export default function FinancialReport(props: IFinancialReportProps) {
    return (        
        <ReportView
            title={1079}
            btnGroupParameterName={AnelmaReportingBtnGroupNameEnum.FinancialReport}
            type={AnelmaReportTypeEnum.FinancialReport}
            endQlikSession={props.endQlikSession}
        />      
    );
}
