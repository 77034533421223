import { RoutePaths } from '../../../../interfaces/enums';
import { MainMenuItemType } from '../mainMenu/IMainMenu';

export const publicMenuItems: MainMenuItemType[] = [
    {
        itemGroups: [
            {
                headerCode: null,
                items: [
                    {
                        href: RoutePaths.PublicMPBovine,
                        public: true,
                        textCode: 1039,
                    },
                ],
            },
            {
                headerCode: null,
                items: [
                    {
                        href: RoutePaths.PublicMPPork,
                        public: true,
                        textCode: 1040,
                    },
                ],
            },
        ],
        textCode: 1037,
    },
    {
        href: RoutePaths.GeneralContacts,
        textCode: 1014,
    },
    {
        href: 'https://www.figen.fi/',
        externalLink: true,
        textCode: 1036,
    },
];
