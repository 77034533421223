// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import { DocumentFileTypeEnum, IDocumentAddDialogData } from '../IDocuments';

// Core
import auth from '../../core/authorization';
import { paramRepository, ParameterValues } from '../../core/resources';

// Common
import { gridColumns } from '../../common/dataGrid';

// Feature
import DocumentView from './DocumentView';

// Api
import api from '../../api/documentApi';
import { documentUrl } from '../../api/apiEndpoints';

export default function InfoDocuments() {
    const { t } = useTranslation(['AnelmaLayout', 'AnelmaInstructions']);

    const [loading, setLoading] = useState<boolean>(true);
    const [types, setTypes] = useState<ParameterValues>([]);

    useEffect(() => {
        paramRepository.load(['AnelmaInstructions']).then(() => {
            const params = paramRepository.resource('AnelmaInstructions', 'InstructionArea');
            setTypes(params);
            setLoading(false);
        });
    }, []);

    const onSave = (data: IDocumentAddDialogData) => {
        return api.uploadDocument(data, DocumentFileTypeEnum.InfoDocument);
    };

    return (       
        <DocumentView
            additionalColumns={[
                gridColumns.dateTime('ValidFrom', t('AnelmaInstructions:1008'), 'DD.MM.YYYY HH:mm'),
                gridColumns.withTooltip({
                    field: 'Type',
                    headerName: t('AnelmaInstructions:1009'),
                    valueGetter: (params) => {
                        const param = types.find((type) => `${type.code}` === `${params.value}`);
                        return param?.text || '-';
                    },
                    width: 200,
                }),
            ]}
            copyLinkUrl={`${documentUrl}/download-file/${DocumentFileTypeEnum.InfoDocument}`}
            deleteConfirmation={t('AnelmaInstructions:1005')}
            enableAddButton={auth.canCreateInstructionFile}
            enableDeleteFile={auth.canDeleteInstructionFile}
            fileType={DocumentFileTypeEnum.InfoDocument}
            onSave={onSave}
            preLoading={loading}
            title={t('AnelmaLayout:1049')}
            types={{
                label: t('AnelmaInstructions:1011'),
                parameter: 'AnelmaInstructions:InstructionArea',
            }}
            documentDialogConfig={'infoDocuments'}
        />        
    );
}
