// Libraries
// MUI
import { Box, Grid, Paper, styled } from '@mui/material';

// Core
import auth from '../../core/authorization';

// Common

// Interfaces
import { INotification } from '../../interfaces/INotification';

// Store
// API
// Feature - Notifications
import NotificationItemType from './NotificationItemType';
import NotificationItemContent from './NotificationItemContent';
import NotificationItemDelete from './NotificationItemDelete';
import NotificationItemCreatedDate from './NotificationItemCreatedDate';

const StyledNotificationBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '290px',
    height: '60px',
    marginTop: '10px',
}));

const StyledContentGrid = styled(Grid)(({ theme }) => ({
    fontSize: 'small',
    fontWeight: 600,
    overflowWrap: 'break-word',
    height: '60px',
}));

interface INotificationItemProps {
    data: INotification[];
    deleteNotification: (data: INotification) => void;
    close: () => void;
}

/** Display notification's related type icon, content and date. Delete component abstraction included.
 *
 * @props data: INotification[]
 * @props deleteNotification: (data: INotification) => void
 * @props close: () => void
 */

export default function NotificationItem(props: INotificationItemProps) {
    const applyIsReadStyling = (isRead: string | null) => {
        return isRead
            ? { width: '290px', height: '60px' }
            : { width: '290px', height: '60px', backgroundColor: '#e8f1ef' };
    };

    return (
        <>
            {props.data.map((_) => {
                return (
                    <StyledNotificationBox key={_.NotificationId}>
                        <Paper elevation={1} sx={{ width: 'inherit' }}>
                            <Grid container sx={applyIsReadStyling(_.ReadDateTime)}>
                                <NotificationItemType type={_.TypeId} />

                                <StyledContentGrid
                                    item
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    key={_.NotificationId}
                                >
                                    <NotificationItemContent
                                        notification={_}
                                        closeParent={props.close}
                                        deleteNotification={props.deleteNotification}
                                    />
                                    <NotificationItemCreatedDate sentDate={_.Created} />
                                </StyledContentGrid>

                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    {auth.canDeleteNotifications && (
                                        <NotificationItemDelete
                                            notification={_}
                                            deleteNotification={props.deleteNotification}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </StyledNotificationBox>
                );
            })}
        </>
    );
}
