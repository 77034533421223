// MUI
import { ListItem, ListItemText, ButtonGroup, Button } from '@mui/material';

// Core
import { baseUrl } from '../../../../core/constants';

// Store
import { useAppSelector } from '../../../../store/hooks';

export default function CultureSelector() {
    const configuration = useAppSelector((state) => state.configuration.data);

    const changeCulture = (culture: string) => {
        const returnUrl = encodeURI(window.location.href);
        window.location.href = `${baseUrl}${configuration.culture.changeCultureUrl}?culture=${culture}&returnUrl=${returnUrl}`;
    };

    return (
        <ListItem>
            <ListItemText style={{ textAlign: 'center' }}>
                <ButtonGroup variant='text'>
                    {configuration.culture.supportedCultures.map((culture, index) => (
                        <Button
                            key={index}
                            disabled={culture.selected}
                            onClick={() => changeCulture(culture.value)}
                        >
                            {culture.text}
                        </Button>
                    ))}
                </ButtonGroup>
            </ListItemText>
        </ListItem>
    );
}
