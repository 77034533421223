// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Done } from '@mui/icons-material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AIconButtonBaseProps, ADefaultIconButton } from '../buttons';

export interface ADoneIconButtonProps extends AIconButtonBaseProps {}

export default function ADoneIconButton(props: ADoneIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1075');

    return (
        <ADefaultIconButton
            {...buttonProps}
            icon={<Done style={{ color: '#02584B' }} />}
            tooltip={tooltip2}
        />
    );
}
