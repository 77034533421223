// Libraries
import axios from 'axios';

// Core
import { communicationUrl, publicApiUrl, userCommunicationUrl } from '../api/apiEndpoints';
import log from '../core/log';

// Interfaces
import { IBulletinItem, IBulletinItemDetails } from '../interfaces/ICommunication';
import { IApiResult, IApiListResult } from '../interfaces/IGeneral';

// Bulletin
import { BulletinRequest } from './api/bulletinRequest';

export function getUserAnnouncements(
    farmId?: string | null,
    userId?: string | null
): Promise<IBulletinItem[]> {
    const errorMsg = 'Failed to retrieve bulletin messages.';
    if (farmId && userId) {
        return axios
            .get<IApiListResult<IBulletinItem>>(`${userCommunicationUrl}/${farmId}/${userId}`)
            .then(
                (r) => r.data.Items,
                () => {
                    log('error', errorMsg);
                    return [];
                }
            );
    }
    return axios.get<IApiListResult<IBulletinItem>>(`${publicApiUrl}/userannouncements`).then(
        (r) => r.data.Items,
        () => {
            log('error', errorMsg);
            return [];
        }
    );
}

export function getCommunications(): Promise<IBulletinItemDetails[]> {
    return axios
        .get<IApiListResult<IBulletinItemDetails>>(communicationUrl)
        .then((r) => r.data.Items);
}

export function postUpdate(message: BulletinRequest): Promise<IBulletinItemDetails> {
    return axios
        .post<IApiResult<IBulletinItemDetails>>(`${communicationUrl}/${message.Entity.Id}`, message)
        .then((r) => r.data.Entity);
}

export function postAdd(message: BulletinRequest): Promise<IBulletinItemDetails> {
    return axios
        .post<IApiResult<IBulletinItemDetails>>(communicationUrl, message.Entity)
        .then((r) => r.data.Entity);
}
