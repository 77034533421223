export interface IFarmContext {
    cattleId: string;
    context: string;
    customerName: string;
    farmId: string;
    producerId: string;
}

class ContextUtils {
    farmContextStringsMatch(a: string, b: string) {
        const aParts = a.split('---');
        const bParts = b.split('---');

        if (aParts.length > 2 || bParts.length > 2) return false;

        const aContext = aParts.length === 2 ? aParts[1] : aParts[0];
        const bContext = bParts.length === 2 ? bParts[1] : bParts[0];

        if (!aContext || !bContext) return false;

        return aContext === bContext;
    }

    parseFarmContext(contextString: string): IFarmContext | null {
        const parts = contextString.split('---');

        const context = parts.length === 2 ? parts[1] : parts[0];

        if (!context) return null;
        const parts2 = context.split(':::');

        // farm context: {farmId}:::{cattleId}:::{producerId}:::{customer-name}
        if (parts2.length === 4)
            return {
                cattleId: parts2[1],
                context: context,
                customerName: parts2[3],
                farmId: parts2[0],
                producerId: parts2[2],
            };
        // Don't know what this is... car operator placeholder?
        else
            return {
                cattleId: parts2[1],
                context: context,
                customerName: parts2[2],
                farmId: parts2[0],
                producerId: parts2[2],
            };
    }
}

export default new ContextUtils();
