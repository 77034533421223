// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
import moment from 'moment';

// MUI
import { GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro';

// Core
import auth from '../../../../core/authorization';
import {
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../../../core/resources';
import { dayFormat } from '../../../../core/constants';

// Common
import { ADataGrid } from '../../../../common/dataGrid';
import { ADeleteIconButton } from '../../../../common/buttons';
import { AGridColumns, gridColumns } from '../../../../common/dataGrid';
import { TextWithTooltip } from '../../../../common/typography';

// Interfaces
import { AnnouncementTypeEnum, DialogModeEnum } from '../../../../interfaces/enums';
import {
    IAnimalAnnouncement,
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
    ICollectWeek,
} from '../../../../interfaces/IAnnouncement';
import { IAnimalSummary, IAnimalSummaryExtraInfo } from '../../../../interfaces/IAnimal';

// Feature
import AnimalAnnouncementSalesTypeField from './AnimalAnnouncementSalesTypeField';
import ConfirmDialog from '../../../shared/components/confirm/ConfirmDialog';
import {
    birthDateComparator,
    euIdentifierComparator,
    isAnimalAnnouncementBatchRowSelectable,
    mapAnimalSummaryToAnnouncementDialog,
    mapIAnimalAnnouncementBatchToAnnouncementDialog,
} from '../../helpers/data';
import { AnimalAnnouncementCollectWeekField } from './AnimalAnnouncementCollectWeekField';

export interface IAnimalAnnouncementDataGridProps {
    announcementType: AnnouncementTypeEnum;
    mode: DialogModeEnum;
    animalAnnouncementData: IAnimalAnnouncement | null;
    initiallySelectedAnimals: IAnimalAnnouncementAnimal[] | null;
    allAnimals: IAnimalSummaryExtraInfo[] | null;
    allowedSalesTypes: ParameterValues | null;
    editSalesType: (animalId: string) => void;
    handleSelectedAnimals: (animalData: IAnimalSummary[]) => void;
    handleAnimalRemoveFromAnnouncement: (
        animalData: IAnimalAnnouncementBatch,
        animalAnnouncementNumber?: number
    ) => void;
    loading: boolean;
    readOnly?: boolean;
}

export default function AnimalAnnouncementDataGrid(props: IAnimalAnnouncementDataGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBovine',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);
    const dataGridApiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bovineSexParams, setBovineSexParams] = useState<ParameterValues>([]);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [initiallySelectedAnimals, setInitiallySelectedAnimals] = useState<
        IAnimalAnnouncementAnimal[] | null
    >([]);
    const [initialAnimalAnnouncementData, setInitialAnimalAnnouncementData] =
        useState<IAnimalAnnouncement | null>(props.animalAnnouncementData);
    const [selectedAnimals, setSelectedAnimals] = useState<IAnimalSummary[]>([]);
    const [animalBatchToRemoveFromGrid, setAnimalBatchToRemoveFromGrid] =
        useState<IAnimalAnnouncementBatch | null>(null);

    const bovineSlaughterAnnouncementColumns: AGridColumns = [
        {
            field: 'EuIdentifier',
            headerName: t('AnelmaBovine:1005'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return params.row.Animals.map((_: IAnimalAnnouncementAnimal) => {
                    return _.EuIdentifier;
                });
            },
            width: 150,
            sortable: true,
            sortComparator: (first, second) => euIdentifierComparator(first, second),
        },
        {
            field: 'BirthDate',
            headerName: t('AnelmaBovine:1011'),
            renderCell: (params) => {
                const birthDate = String(params.value);
                return birthDate ? (
                    <TextWithTooltip text={`${moment(birthDate).format(dayFormat)}`} />
                ) : (
                    ''
                );
            },
            valueGetter: (params) => {
                return params.row.Animals.map((_: IAnimalAnnouncementAnimal) => {
                    return _.BirthDate;
                });
            },
            width: 160,
            sortable: true,
            sortComparator: (first, second) => birthDateComparator(first, second),
        },
        {
            field: 'Sex',
            headerName: t('AnelmaBovine:1048'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return bovineSexParams.find((param) => {
                    if (param.code === String(params.row.Animals[0].Sex)) {
                        return param.text;
                    }
                })?.text;
            },
            width: 100,
        },
        {
            field: 'SalesType',
            headerName: `${t('AnelmaBovine:1019')} *`,
            renderCell: (params) => {
                return params.value !== undefined && params.value !== null ? (
                    <AnimalAnnouncementSalesTypeField
                        id={params.row.id}
                        salesType={Number(params.value)}
                        animalAnnouncementType={AnnouncementTypeEnum.Slaughter}
                        allowedSalesTypes={props.allowedSalesTypes}
                        editSalesType={() => {
                            props.editSalesType(params.row.id);
                        }}
                    />
                ) : (
                    ''
                );
            },
            valueGetter: (params) => {
                return params.row.Animals.slice(0, 1).map(
                    (_: IAnimalAnnouncementAnimal) => _.SalesType
                );
            },
            width: 160,
        },
        {
            field: 'Week',
            headerName: `${t('AnelmaBovine:1097')} *`,
            renderCell: (params) => {
                return (
                    <AnimalAnnouncementCollectWeekField
                        collectWeekStart={params.row.CollectWeekStart}
                        collectWeekEnd={params.row.CollectWeekEnd}
                        mode={props.mode}
                    />
                );
            },
            width: 160,
        },
        {
            field: 'BatchStatus',
            headerName: t('AnelmaBovine:1035'),
            renderCell: (params: GridRenderCellParams) => {
                return params.value !== null && params.value !== undefined ? (
                    <TextWithTooltip
                        text={String(params.api.getCellValue(params.id, params.field))}
                    />
                ) : (
                    ''
                );
            },
            width: 160,
        },
        gridColumns.singleSelectText(
            props.mode === DialogModeEnum.Create ? 'Info' : 'BatchInfo',
            [],
            t('AnelmaBovine:1039'),
            140
        ),
    ];

    const bovineIntermediationAnnouncementColumns: AGridColumns = [
        {
            field: 'EuIdentifier',
            headerName: t('AnelmaBovine:1005'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return (
                    params.row.Animals?.map((_: IAnimalAnnouncementAnimal) => {
                        return _.EuIdentifier;
                    }) ?? params.row.EuIdentifier
                );
            },
            width: 150,
            sortable: true,
            sortComparator: (first, second) => euIdentifierComparator(first, second),
        },
        {
            field: 'BirthDate',
            headerName: t('AnelmaBovine:1011'),
            renderCell: (params) => {
                return (
                    <TextWithTooltip text={`${moment(String(params.value)).format(dayFormat)}`} />
                );
            },
            valueGetter: (params) => {
                return (
                    params.row.Animals?.map((_: IAnimalAnnouncementAnimal) => {
                        return _.BirthDate;
                    }) ?? params.row.BirthDate
                );
            },
            width: 160,
            sortable: true,
            sortComparator: (first, second) => birthDateComparator(first, second),
        },
        {
            field: 'Sex',
            headerName: t('AnelmaBovine:1048'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return bovineSexParams.find((param) => {
                    if (param.code === String(params.row.Animals[0].Sex)) {
                        return param.text;
                    }
                })?.text;
            },
            width: 100,
        },
        {
            field: 'SalesType',
            headerName: `${t('AnelmaBovine:1019')} *`,
            renderCell: (params) => {
                return params.value !== undefined && params.value !== null ? (
                    <AnimalAnnouncementSalesTypeField
                        id={params.row.id}
                        salesType={Number(params.value)}
                        animalAnnouncementType={AnnouncementTypeEnum.Intermediation}
                        allowedSalesTypes={props.allowedSalesTypes}
                        editSalesType={() => {
                            props.editSalesType(params.row.id);
                        }}
                    />
                ) : (
                    ''
                );
            },
            valueGetter: (params) => {
                return params.row.Animals.slice(0, 1).map(
                    (_: IAnimalAnnouncementAnimal) => _.SalesType
                );
            },
            width: 160,
        },
        {
            field: 'Week',
            headerName: `${t('AnelmaBovine:1097')} *`,
            renderCell: (params) => {
                return (
                    <AnimalAnnouncementCollectWeekField
                        collectWeekStart={params.row.CollectWeekStart}
                        collectWeekEnd={params.row.CollectWeekEnd}
                        mode={props.mode}
                    />
                );
            },
            width: 160,
        },
        {
            field: 'BatchStatus',
            headerName: t('AnelmaBovine:1035'),
            renderCell: (params: GridRenderCellParams) => {
                return params.value !== null && params.value !== undefined ? (
                    <TextWithTooltip
                        text={String(params.api.getCellValue(params.id, params.field))}
                    />
                ) : (
                    ''
                );
            },
            width: 160,
        },
        gridColumns.singleSelectText('BatchInfo', [], t('AnelmaBovine:1039'), 140),
    ];

    const isRemoveAnimalDisabled = (params: GridRenderCellParams): boolean => {
        let rowSelectable = isAnimalAnnouncementBatchRowSelectable(
            props.announcementType,
            params.row.CollectWeekStart as ICollectWeek,
            params.row.BatchStatus
        );

        if (props.readOnly && props.readOnly === true) return true;
        if (
            props.announcementType === AnnouncementTypeEnum.Intermediation &&
            props.mode === 'edit'
        ) {
            return !auth.canModifyBovineBrokerSales || !rowSelectable;
        }
        if (props.mode === 'edit') return !rowSelectable;

        return false;
    };

    const getColumns = (): AGridColumns => {
        if (props.announcementType === AnnouncementTypeEnum.Slaughter)
            return bovineSlaughterAnnouncementColumns;
        else if (props.announcementType === AnnouncementTypeEnum.Intermediation)
            return bovineIntermediationAnnouncementColumns;
        else return [];
    };

    useEffect(() => {
        paramRepository.load(['AnelmaBovine']).then(() => {
            setBovineSexParams(paramRepository.resource('AnelmaBovine', 'BovineSex'));
        });
    }, []);

    useEffect(() => {
        props.handleSelectedAnimals(selectedAnimals);
    }, [selectedAnimals]);

    useEffect(() => {
        setInitiallySelectedAnimals(props.initiallySelectedAnimals);
    }, [props.initiallySelectedAnimals]);

    useEffect(() => {
        setInitialAnimalAnnouncementData(props.animalAnnouncementData);
    }, [props.animalAnnouncementData]);

    useEffect(() => {
        setIsLoading(props.loading);
    }, [props.loading]);

    const setAnimalDataForDataGridRows = () => {
        if (initialAnimalAnnouncementData !== null) {
            return initialAnimalAnnouncementData.Batches.filter((a) => a.Animals.length > 0).map(
                (_) => mergeRight(_, { id: _.Animals[0].AnimalId })
            );
        } else if (initiallySelectedAnimals !== null && props.mode === DialogModeEnum.Create) {
            return initiallySelectedAnimals.map((_) => mergeRight(_, { id: _.AnimalId }));
        }
        return [];
    };

    const getActionTooltipText = (): string => {
        if (props.announcementType === AnnouncementTypeEnum.Slaughter)
            return t('AnelmaBovine:1045');
        else if (props.announcementType === AnnouncementTypeEnum.Intermediation)
            return t('AnelmaBovine:1045');
        else return '';
    };

    const handleAnimalRemoveConfirmed = () => {
        if (!animalBatchToRemoveFromGrid) return;
        if (props.animalAnnouncementData) {
            props.handleAnimalRemoveFromAnnouncement(
                animalBatchToRemoveFromGrid,
                props.animalAnnouncementData.Number
            );
        } else {
            props.handleAnimalRemoveFromAnnouncement(animalBatchToRemoveFromGrid);
        }
        setAnimalBatchToRemoveFromGrid(null);
    };

    const isRowSelectable = (params: GridRowParams): boolean => {
        const tempAnimal = params.row as IAnimalAnnouncementBatch;

        if (
            animalBatchToRemoveFromGrid?.Animals[0].AnimalId === tempAnimal?.Animals[0]?.AnimalId &&
            !initialAnimalAnnouncementData?.Batches.find(
                (i) => i.Animals[0].AnimalId === tempAnimal.Animals[0].AnimalId
            )
        ) {
            return false;
        }
        if (props.readOnly && props.readOnly === true) return false;
        if (props.mode === 'edit')
            return isAnimalAnnouncementBatchRowSelectable(
                props.announcementType,
                params.row.CollectWeekStart as ICollectWeek,
                params.row.BatchStatus
            );

        return true;
    };

    return (
        <>
            <ADataGrid
                disableSelectionOnClick={true}
                actions={[
                    (params: GridRenderCellParams) => {
                        return (
                            <ADeleteIconButton
                                key={`${params.id}-delete`}
                                onClick={() => {
                                    setConfirmDialogVisible(true);

                                    const animal = params.row as IAnimalAnnouncementBatch;

                                    if (animal) {
                                        setAnimalBatchToRemoveFromGrid(animal ?? null);
                                    }
                                }}
                                tooltip={getActionTooltipText()}
                                disabled={isRemoveAnimalDisabled(params)}
                            />
                        );
                    },
                ]}
                columns={getColumns()}
                rows={setAnimalDataForDataGridRows()}
                onSelectionModelChange={(selectionModel) =>
                    setSelectedAnimals(
                        props.mode === DialogModeEnum.Create
                            ? mapAnimalSummaryToAnnouncementDialog(
                                  props.allAnimals as IAnimalSummary[],
                                  selectionModel
                              )
                            : props.mode === DialogModeEnum.Edit &&
                              initialAnimalAnnouncementData !== null
                            ? mapAnimalSummaryToAnnouncementDialog(
                                  mapIAnimalAnnouncementBatchToAnnouncementDialog(
                                      initialAnimalAnnouncementData.Batches
                                  ),
                                  selectionModel
                              )
                            : []
                    )
                }
                enableFiltering
                loading={isLoading}
                isRowSelectable={(params) => isRowSelectable(params)}
                apiRef={dataGridApiRef}
            />

            {confirmDialogVisible && (
                <ConfirmDialog
                    title={'AnelmaGeneral:1142'}
                    content={'AnelmaBovine:1062'}
                    actionButtonType={'delete'}
                    onClose={() => {
                        setConfirmDialogVisible(false);
                    }}
                    onConfirm={handleAnimalRemoveConfirmed}
                />
            )}
        </>
    );
}
