// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../core/resources';

// Common
import {
    ADataGrid,
    ADataGridActions,
    gridColumns,
    AGridColumns,
    gridUtils,
} from '../../common/dataGrid';

// Interfaces
import {
    IPersonRelation,
    IPersonSummary,
    IPersonSummaryExtended,
} from '../../interfaces/IBusinessEntities';
import { CompanyEntityTypeEnum } from '../../interfaces/enums';

export interface PersonSearchResultProps {
    actionColumn?: ADataGridActions;
    data: IPersonSummary[];
    enableFiltering: boolean;
    isLoading: boolean;
    showMoreInfo: boolean;
}

export default function PersonSearchResult(props: PersonSearchResultProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaPerson',
        'AnelmaAccessRights',
        'AnelmaGeneral',
    ]);
    const [farmTitles, setFarmTitles] = useState<ParameterValues>([]);
    const [logisticsTitles, setLogisticsTitles] = useState<ParameterValues>([]);
    const [snellmanTitles, setSnellmanTitles] = useState<ParameterValues>([]);
    const [rows, setRows] = useState<IPersonSummaryExtended[]>([]);
    const [columns, setColumns] = useState<AGridColumns>([]);
    const columnWidthM: number = 200;

    useEffect(() => setColumns(getColumns(props.showMoreInfo)), [props]);

    useEffect(() => {
        paramRepository.load(['AnelmaGeneral']).then(() => {
            setFarmTitles(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
            setLogisticsTitles(paramRepository.resource('AnelmaGeneral', 'LogisticsTitles'));
            setSnellmanTitles(paramRepository.resource('AnelmaGeneral', 'SnellmanTitles'));
        });
    }, []);

    useEffect(() => {
        let rows: IPersonSummaryExtended[] = [];

        props.data.forEach((i) => {
            if (props.showMoreInfo && (i as IPersonSummaryExtended).Relations?.length) {
                var extended = i as IPersonSummaryExtended;
                extended.Relations.forEach((r) => {
                    rows.push({
                        ...i,
                        ...r,
                        Relations: [r],
                    });
                });
            } else {
                rows.push({
                    ...i,
                    AccessGroups: [],
                    CompanyName: '',
                    CompanyProducerNumber: '',
                    Relations: [],
                    Roles: [],
                });
            }
        });

        rows = gridUtils.addGridId(rows);

        setRows(rows);
    }, [props.data]);

    function getRoles(relations: IPersonRelation[]): string[] {
        if (relations.length == 0) return [''];
        return relations.map((r) => {
            let titles: ParameterValues | null = null;
            if (r.CompanyType === CompanyEntityTypeEnum.Farm) titles = farmTitles;
            else if (r.CompanyType === CompanyEntityTypeEnum.TrafficContractor)
                titles = logisticsTitles;
            else if (r.CompanyType === CompanyEntityTypeEnum.Snellman) titles = snellmanTitles;

            return titles
                ? r.Roles.map((r) => titles?.find((o) => o.code == r)?.text).join(', ')
                : '';
        });
    }

    function getAccessGroups(relations: IPersonRelation[]): string[] {
        if (relations.length == 0) return [''];
        return relations.map((r) =>
            r.AccessGroups.length > 0
                ? r.AccessGroups.map((groupCode) => t('AnelmaAccessRights:' + groupCode)).join(', ')
                : ''
        );
    }

    const getColumns: (showMoreColumns: boolean) => AGridColumns = (showMoreColumns: boolean) => {
        var ret: AGridColumns = [
            gridColumns.personName(t('AnelmaPerson:1021')),
            gridColumns.phoneNumber(t('AnelmaPerson:1007')),
            gridColumns.emailAddress(t('AnelmaPerson:1008')),
            gridColumns.address(t('AnelmaGeneral:1006')),
        ];

        if (showMoreColumns)
            ret = ret.concat([
                gridColumns.login(t('AnelmaPerson:1004')),
                gridColumns.withTooltip({
                    field: 'Roles',
                    headerName: t('AnelmaPerson:1036'),
                    valueGetter: (params) => {
                        return getRoles(
                            params.api.getCellValue(params.id, 'Relations') as IPersonRelation[]
                        ).join(', ');
                    },
                    width: columnWidthM,
                }),
                gridColumns.withTooltip({
                    field: 'AccessGroups',
                    headerName: t('AnelmaPerson:1022'),
                    valueGetter: (params) => {
                        return getAccessGroups(
                            params.api.getCellValue(params.id, 'Relations') as IPersonRelation[]
                        ).join(', ');
                    },
                    width: columnWidthM,
                }),
                gridColumns.producerNumber(t('AnelmaPerson:1045'), 'CompanyProducerNumber'),
                gridColumns.companyName(t('AnelmaPerson:1037'), 'CompanyName'),
            ]);

        return ret;
    };

    return (
        <ADataGrid
            actions={props.actionColumn}
            columns={columns}
            enableFiltering={props.enableFiltering}
            enableRowNumbers
            loading={props.isLoading}
            stickyHeader
            rows={rows}
        />
    );
}
