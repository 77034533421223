import axios from 'axios';
import { bovineVariablesUrl } from './apiEndpoints';
import { IApiListResult, IApiResult } from '../interfaces/IGeneral';

import { IBovineVariables, IBovineVariablesPayload } from '../interfaces/IBovineVariables';
import ApiBase from './ApiBase';

class BovineVariablesApi extends ApiBase {
    getBovineVariables(farmId: string) {
        return axios
            .get<IApiResult<IBovineVariables>>(`${bovineVariablesUrl}/${farmId}`)
            .then((d) => d.data);
    }

    createOrUpdateBovineVariables(payload: IBovineVariablesPayload) {
        return axios
            .post<IApiResult<IBovineVariables>>(
                `${bovineVariablesUrl}/`,
                payload
            )
            .then((d) => d.data);
    }
}

export default new BovineVariablesApi();
