// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';

// MUI
// Core
import { ResourceTextApplication } from '../../../core/resources';

// Common
import ViewLoader from '../../../common/ViewLoader';
import { Context } from '../../../common/contextSelector/ContextSelector';

// Interfaces
import {
    IFarmSupplier,
    IProductionPlan,
    IProductionPlanNotificationLocationData,
} from '../../../interfaces/IProductionPlan';
import { IFarmDetails } from '../../../interfaces/IBusinessEntities';
import { RoutePaths } from '../../../interfaces/enums';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import productionPlanApi from '../../../api/productionPlanApi';
import companyApi from '../../../api/companyApi';

// Feature - Production plan
import ProductionPlan from '../ProductionPlan';

/** Used to render ProductionPlan when clicked notification X when pathname equals RoutePaths.AnimalsProductionPlans.
 *
 * ***If you find a better way to achieve this then obliterate the component.***
 * @returns
 */
export default function ProductionPlanNotification() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaSolmuErrors']);
    const location = useLocation();
    const navigate = useNavigate();

    const context = useAppSelector((state) => state.context);

    const [loading, setLoading] = useState<boolean>(false);
    const [template, setTemplate] = useState<IProductionPlan | undefined | null>(null);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();
    const [locationData] = useState<IProductionPlanNotificationLocationData>(
        initializeLocationData()
    );

    useEffect(() => {
        if (context.data.currentContext) initializeFarmDetails(context.data.currentContext);
    }, [context]);

    useEffect(() => {
        if (locationData.DisplayProductionPlanFormInstantly) createNewPlan();
    }, [farmDetails]);

    function initializeLocationData() {
        return location.state !== null
            ? location.state.options
            : { DisplayProductionPlanFormInstantly: false };
    }

    const initializeFarmDetails = (context: Context) => {
        setLoading(true);
        const companyByContext = companyApi
            .getCompanyByContext(context.context)
            .then((response) => {
                setFarmDetails(response?.Entity as IFarmDetails);
            });
        Promise.all([companyByContext]).then(() => setLoading(false));
    };

    const createNewPlan = () => {
        if (farmDetails && farmDetails.Id) {
            const nextYear = moment().year() + 1;
            setLoading(true);
            productionPlanApi.getTemplate(farmDetails.Id, nextYear).then((response) => {
                setLoading(false);
                if (!response) {
                    enqueueSnackbar(t('AnelmaSolmuErrors:10011'), {
                        variant: 'error',
                    });
                    return;
                }
                setTemplate(response.Entity);
            });
        }
    };

    return (
        <>
            {!loading && farmDetails && template ? (
                <ProductionPlan
                    data={template}
                    access={{ IsAdmin: false, CanUpdate: false, CanCreate: true }}
                    farmDetails={farmDetails}
                    readonly={false}
                    onClose={() => navigate(RoutePaths.AnimalsProductionPlans)}
                    handleSellerFarmsChange={(_: IFarmSupplier[]) => false}
                    handleBuyCalfsChange={(_: boolean) => false}
                    changeProductionEndYear={(_: number) => false}
                    handleNoSlaughterBovineSoldChange={(_: boolean) => false}
                    handleChangeNoTransmissionBovineSoldChange={(_: boolean) => false}
                />
            ) : (
                <ViewLoader />
            )}
        </>
    );
}
