// Libraries
// MUI
import { Container, Typography } from '@mui/material';

// Core
// Common
// Interfaces
// Store
// API
// Messaging
// Feature

export default function PageNotFound() {
    return (
        <Container data-robot-id={'app-body-page-not-found'}>
            <Typography variant='h2'>404 - Sivua ei löytynyt</Typography>
        </Container>
    );
}
