import axios from 'axios';
import { accessManagementUrl, personsUrl } from './apiEndpoints';
import { baseUrl } from '../core/constants';
import { IPersonDetails, IPersonSummary } from '../interfaces/IBusinessEntities';
import { IUpdatePayload } from '../interfaces/IGeneral';
import { GUIDType } from '../interfaces/types';

import ApiBase from './ApiBase';

class PersonApi extends ApiBase {
    activatePerson(guid: GUIDType) {
        return this.post<unknown>(`${personsUrl}/activate/${guid}`, null);
    }

    createNewPerson(data: IPersonDetails) {
        return this.post<IPersonDetails>(personsUrl, data);
    }

    getPersonByGuid(guid: GUIDType) {
        return this.get<IPersonDetails>(`${personsUrl}/${guid}`);
    }

    getPersons(
        searchType: string,
        searchTerm: string,
        returnExpired: boolean,
        showMoreInfo: boolean
    ) {
        return this.getList<IPersonSummary>(
            `${personsUrl}?${searchType}=${searchTerm}&&ReturnExpiredOnes=${returnExpired}&&showMoreInfo=${showMoreInfo}`
        );
    }

    getPersonByGUID(personId: GUIDType) {
        return this.get<IPersonDetails>(`${personsUrl}/${personId}`);
    }

    inactivatePerson(guid: GUIDType) {
        return this.post<IPersonDetails>(`${personsUrl}/inactivate/${guid}`, null);
    }

    updatePerson(data: IUpdatePayload<IPersonDetails>) {
        return this.post<IPersonDetails>(`${personsUrl}/${data.Entity.Id}`, data);
    }
}

export default new PersonApi();

export function sendPasswordResetLinkEmail(login: string, languageCode: string): Promise<void> {
    var acceptLanguageHeader: string = 'fi';

    if (languageCode === 'FI') acceptLanguageHeader = 'fi';
    if (languageCode === 'SE') acceptLanguageHeader = 'sv-SE';
    if (languageCode === 'EN') acceptLanguageHeader = 'en-GB';

    const config = {
        headers: {
            'X-Accept-Language-Override': acceptLanguageHeader,
        },
        params: {
            returnUrl: baseUrl,
        },
    };

    return new Promise((resolve, reject) => {
        axios
            .patch<void>(
                `${accessManagementUrl}/user/${login}/send-password-reset-link`,
                undefined,
                config
            )
            .then(
                (d) => {
                    resolve();
                },
                (r) => {
                    reject();
                }
            );
    });
}
