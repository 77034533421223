import { IUpdatePayload, NamedField, FieldUpdate } from '../interfaces/IGeneral';
import {
    ICarCapacity,
    ICompanyDetails,
    ITrafficContractorDetails,
    IFarmDetails,
    IContactPersonRole,
} from '../interfaces/IBusinessEntities';
import { AnimalTypeEnum, CompanyEntityTypeEnum } from '../interfaces/enums';

export default function buildUpdatePayload(
    oldData: ICompanyDetails,
    newData: ICompanyDetails
): IUpdatePayload<ICompanyDetails> {
    const result: IUpdatePayload<ICompanyDetails> = {
        Additions: [],
        Deletions: [],
        Entity: oldData,
        Updates: [],
    };
    handleCompanyData(oldData, newData, result);
    handleFarmData(
        oldData as IFarmDetails,
        newData as IFarmDetails,
        result as IUpdatePayload<IFarmDetails>
    );
    handleTrafficContractorData(
        oldData as ITrafficContractorDetails,
        newData as ITrafficContractorDetails,
        result as IUpdatePayload<ITrafficContractorDetails>
    );

    postHandleContactPerson(result);

    return result;
}

const addressProps = [
    'City',
    'CountryShortCode',
    'IsDefault',
    'NormalizedAddress',
    'Street',
    'Type',
    'Zip',
];
const phoneNumberProps = ['IsDefault', 'NormalizedNumber', 'Type'];

function handleCompanyData(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    addresses(oldData, newData, result);
    aliases(oldData, newData, result);
    businessCode(oldData, newData, result);
    businessForm(oldData, newData, result);
    contactPersons(oldData, newData, result);
    county(oldData, newData, result);
    emailAddresses(oldData, newData, result);
    languageShortCode(oldData, newData, result);
    municipalityCode(oldData, newData, result);
    names(oldData, newData, result);
    phoneNumbers(oldData, newData, result);
    roles(oldData, newData, result);
}

function handleFarmData(
    oldData: IFarmDetails,
    newData: IFarmDetails,
    result: IUpdatePayload<IFarmDetails>
) {
    if (oldData.CompanyType !== CompanyEntityTypeEnum.Farm) return;
    holdingSites(oldData, newData, result);
    cattleId(oldData, newData, result);
    pricing(oldData, newData, result);
    productionLines(oldData, newData, result);
    teamAreaCode(oldData, newData, result);
    feedSupplier(oldData, newData, result);
    dairy(oldData, newData, result);
}

function handleTrafficContractorData(
    oldData: ITrafficContractorDetails,
    newData: ITrafficContractorDetails,
    result: IUpdatePayload<ITrafficContractorDetails>
) {
    if (oldData.CompanyType !== CompanyEntityTypeEnum.TrafficContractor) return;
    cars(oldData, newData, result);
}

function addresses(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValueListWithId(
        '_i',
        'Addresses',
        result,
        oldData.Addresses,
        newData.Addresses,
        addressProps
    );
}

function aliases(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValueListWithId('Type', 'Aliases', result, oldData.Aliases, newData.Aliases, ['Value']);
}

function businessCode(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValue(
        'BusinessCode',
        result,
        oldData.BusinessCode,
        oldData.BusinessCode.Value,
        newData.BusinessCode,
        newData.BusinessCode.Value
    );
}

function businessForm(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValue(
        'BusinessForm',
        result,
        oldData.BusinessForm,
        oldData.BusinessForm.Type,
        newData.BusinessForm,
        newData.BusinessForm.Type
    );
}

function cars(
    oldData: ITrafficContractorDetails,
    newData: ITrafficContractorDetails,
    result: IUpdatePayload<ITrafficContractorDetails>
) {
    const field = 'Cars';
    const newIds = newData.Cars.map((n) => n.Id);

    const additions = newData.Cars.filter((n) => !n.Id).map((n) => {
        const addition: NamedField = {
            Data: n,
            Field: field,
        };
        return addition;
    });
    result.Additions.push(...additions);

    const deletions = oldData.Cars.filter((o) => !newIds.includes(o.Id)).map((o) => {
        const deletion: NamedField = {
            Data: o,
            Field: field,
        };
        return deletion;
    });
    result.Deletions.push(...deletions);

    oldData.Cars.forEach((o) => {
        const n = newData.Cars.find((n2) => n2.Id === o.Id);
        if (!n) return;
        if (
            objectValuesAreEqual(o, n, ['Number', 'Licence']) &&
            objectValuesAreEqual(o.Capacities, n.Capacities, ['Capacity', 'Type']) &&
            objectValuesAreEqual(o.Address, n.Address, addressProps) &&
            objectValuesAreEqual(o.PhoneNumber, n.PhoneNumber, phoneNumberProps) &&
            carCapacitiesAreEqual(o.Capacities, n.Capacities)
        )
            return;
        const update: FieldUpdate = {
            Field: field,
            NewData: n,
            OldData: o,
        };
        result.Updates.push(update);
    });
}

function carCapacitiesAreEqual(oldData: ICarCapacity[], newData: ICarCapacity[]): boolean {
    for (let i = 0; i < oldData.length; i++) {
        if (!newData.find((d) => oldData[i].Type === d.Type)) return false;
    }
    for (let i = 0; i < newData.length; i++) {
        const n = newData[i];
        const o = oldData.find((d) => newData[i].Type === d.Type);
        // NOTE: UI adds null values for capacity types that do not exist in the db.
        if (!o && n.Capacity !== null) return false;
        if (o && o.Capacity !== n.Capacity) return false;
    }
    return true;
}

function contactPersons(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValueListWithId(
        'Id',
        'ContactPersons',
        result,
        oldData.ContactPersons,
        newData.ContactPersons,
        ['IsDefault']
    );
}

function county(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValue('County', result, oldData.County, oldData.County, newData.County, newData.County);
}

function emailAddresses(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValueListWithId(
        '_i',
        'EmailAddresses',
        result,
        oldData.EmailAddresses,
        newData.EmailAddresses,
        ['IsDefault', 'Value']
    );
}

function languageShortCode(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValue(
        'LanguageShortCode',
        result,
        oldData.LanguageShortCode,
        oldData.LanguageShortCode,
        newData.LanguageShortCode,
        newData.LanguageShortCode
    );
}

function cattleId(
    oldData: IFarmDetails,
    newData: IFarmDetails,
    result: IUpdatePayload<IFarmDetails>
) {
    handleValue(
        'CattleId',
        result,
        oldData.CattleId,
        oldData.CattleId,
        newData.CattleId,
        newData.CattleId
    );
}

function municipalityCode(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValue(
        'MunicipalityCode',
        result,
        oldData.MunicipalityCode,
        oldData.MunicipalityCode,
        newData.MunicipalityCode,
        newData.MunicipalityCode
    );
}

function names(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValueListWithId('Type', 'Names', result, oldData.Names, newData.Names, ['Value']);
}

function phoneNumbers(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    handleValueListWithId(
        '_i',
        'PhoneNumbers',
        result,
        oldData.PhoneNumbers,
        newData.PhoneNumbers,
        phoneNumberProps
    );
}

function pricing(
    oldData: IFarmDetails,
    newData: IFarmDetails,
    result: IUpdatePayload<IFarmDetails>
) {
    handleValueListWithId('Id', 'Pricing', result, oldData.Pricing, newData.Pricing, ['Text']);
}

function productionLines(
    oldData: IFarmDetails,
    newData: IFarmDetails,
    result: IUpdatePayload<IFarmDetails>
) {
    const field = 'ProductionLines';
    [AnimalTypeEnum.Bovine, AnimalTypeEnum.Pork].forEach((type) => {
        const oldValue = oldData.ProductionLines.find((i) => i.Type === type);
        const newValue = newData.ProductionLines.find((i) => i.Type === type);
        if (!oldValue && newValue) {
            result.Additions.push({
                Field: field,
                Data: newValue,
            });
        } else if (oldValue && (!newValue || newValue?.FarmingTypes.length === 0)) {
            result.Deletions.push({
                Field: field,
                Data: oldValue,
            });
        } else if (oldValue && newValue) {
            if (oldValue.FarmingTypes.sort().join() === newValue.FarmingTypes.sort().join()) return;
            result.Updates.push({
                Field: field,
                OldData: oldValue,
                NewData: newValue,
            });
        }
    });
}

function teamAreaCode(
    oldData: IFarmDetails,
    newData: IFarmDetails,
    result: IUpdatePayload<IFarmDetails>
) {
    handleValue(
        'TeamAreaCode',
        result,
        oldData.TeamAreaCode,
        oldData.TeamAreaCode,
        newData.TeamAreaCode,
        newData.TeamAreaCode
    );
}

function feedSupplier(
    oldData: IFarmDetails,
    newData: IFarmDetails,
    result: IUpdatePayload<IFarmDetails>
) {
    const additions: NamedField[] = [],
        deletions: NamedField[] = [];

    oldData.FeedSuppliers.forEach((supplier) => {
        if (!newData.FeedSuppliers.includes(supplier))
            deletions.push({ Field: 'FeedSuppliers', Data: supplier });
    });

    newData.FeedSuppliers.forEach((supplier) => {
        if (!oldData.FeedSuppliers.includes(supplier))
            additions.push({ Field: 'FeedSuppliers', Data: supplier });
    });

    if (Array.isArray(additions) && additions.length > 0) result.Additions.push(...additions);
    if (Array.isArray(deletions) && deletions.length > 0) result.Deletions.push(...deletions);
}

function dairy(oldData: IFarmDetails, newData: IFarmDetails, result: IUpdatePayload<IFarmDetails>) {
    const additions: NamedField[] = [],
        deletions: NamedField[] = [];

    oldData.Dairy.forEach((dairy) => {
        if (!newData.Dairy.includes(dairy)) deletions.push({ Field: 'Dairy', Data: dairy });
    });

    newData.Dairy.forEach((dairy) => {
        if (!oldData.Dairy.includes(dairy)) additions.push({ Field: 'Dairy', Data: dairy });
    });

    if (Array.isArray(additions) && additions.length > 0) result.Additions.push(...additions);
    if (Array.isArray(deletions) && deletions.length > 0) result.Deletions.push(...deletions);
}

function roles(
    oldData: ICompanyDetails,
    newData: ICompanyDetails,
    result: IUpdatePayload<ICompanyDetails>
) {
    const additions: NamedField[] = [],
        deletions: NamedField[] = [];

    oldData.ContactPersons.forEach((oldPerson) => {
        const newPerson = newData.ContactPersons.find((np) => np.Id === oldPerson.Id);

        if (!newPerson || JSON.stringify(oldPerson.Roles) === JSON.stringify(newPerson.Roles))
            return;

        newPerson.Roles.forEach((role) => {
            if (oldPerson.Roles.findIndex((r) => r.Type === role.Type) === -1)
                additions.push({
                    Data: role,
                    Field: 'Roles',
                } as NamedField);
        });
        oldPerson.Roles.forEach((role: IContactPersonRole) => {
            if (newPerson.Roles.findIndex((r) => r.Type === role.Type) === -1)
                deletions.push({
                    Data: {
                        ContactHash: oldPerson.Hash,
                        ContactPersonId: oldPerson.PersonId,
                        Type: role.Type,
                    } as IContactPersonRole,
                    Field: 'Roles',
                } as NamedField);
        });
    });

    if (Array.isArray(additions) && additions.length > 0) result.Additions.push(...additions);
    if (Array.isArray(deletions) && deletions.length > 0) result.Deletions.push(...deletions);
}

function holdingSites(
    oldData: IFarmDetails,
    newData: IFarmDetails,
    result: IUpdatePayload<IFarmDetails>
) {
    const field = 'HoldingSites';
    const oldIds = oldData.HoldingSites.map((o: any) => o._i as string);
    const newIds = newData.HoldingSites.map((n: any) => n._i as string);

    const additions = newData.HoldingSites.filter((n) => isAddition(n, oldIds, '_i')).map((n) => {
        const addition: NamedField = {
            Data: n,
            Field: field,
        };
        return addition;
    });
    result.Additions.push(...additions);

    const deletions = oldData.HoldingSites.filter((o: any) => !newIds.includes(o._i)).map((o) => {
        const deletion: NamedField = {
            Data: o,
            Field: field,
        };
        return deletion;
    });
    result.Deletions.push(...deletions);

    oldData.HoldingSites.forEach((o) => {
        const n = newData.HoldingSites.find((n2) => (n2 as any)._i === (o as any)._i);
        if (!n) return;
        if (
            objectValuesAreEqual(o, n, ['EntryCode', 'HoldingSiteId']) &&
            objectValuesAreEqual(o.Address, n.Address, addressProps)
        )
            return;
        const update: FieldUpdate = {
            Field: field,
            NewData: n,
            OldData: o,
        };
        result.Updates.push(update);
    });
}

type ValueType = number | string | null;

function handleValue(
    field: string,
    result: IUpdatePayload<ICompanyDetails>,
    oldData: any,
    oldValue: ValueType,
    newData: any,
    newValue: ValueType
) {
    if (
        (oldValue === undefined || oldValue === null) &&
        newValue !== undefined &&
        newValue !== null
    ) {
        result.Additions.push({ Field: field, Data: newData });
    } else if (
        oldValue !== undefined &&
        oldValue !== null &&
        (newValue === undefined || newValue === null)
    ) {
        result.Deletions.push({ Field: field, Data: oldData });
    } else if (oldValue !== newValue) {
        result.Updates.push({ Field: field, OldData: oldData, NewData: newData });
    }
}

function handleValueListWithId(
    idProp: string,
    field: string,
    result: IUpdatePayload<ICompanyDetails>,
    oldData: any[],
    newData: any[],
    props: string[]
) {
    const oldIds = oldData.map((o: any) => o[idProp] as string);
    const newIds = newData.map((n: any) => n[idProp] as string);

    const additions = newData
        .filter((n: any) => isAddition(n, oldIds, idProp))
        .map((n) => {
            const addition: NamedField = {
                Data: n,
                Field: field,
            };
            return addition;
        });
    result.Additions.push(...additions);

    const deletions = oldData
        .filter((o: any) => !newIds.includes(o[idProp]))
        .map((o) => {
            const deletion: NamedField = {
                Data: o,
                Field: field,
            };
            return deletion;
        });
    result.Deletions.push(...deletions);

    oldData.forEach((o) => {
        const n = newData.find((n2: any) => n2[idProp] === (o as any)[idProp]);
        if (!n) return;
        if (objectValuesAreEqual(o, n, props)) return;
        const update: FieldUpdate = {
            Field: field,
            NewData: n,
            OldData: o,
        };
        result.Updates.push(update);
    });
}

function isAddition(newItem: any, oldIds: any[], idProp: string) {
    return !newItem[idProp] || !oldIds.includes(newItem[idProp]);
}

function objectValuesAreEqual(oldData: any, newData: any, props: string[]): boolean {
    for (const i in props) {
        if (oldData[props[i]] !== newData[props[i]]) return false;
    }
    return true;
}

function postHandleContactPerson(data: IUpdatePayload<ICompanyDetails>) {
    var newData = { ...data };
    for (var addition of data.Additions) {
        if ((addition.Field === 'ContactPersons' && addition.Data['id']) || addition.Data['Id']) {
            delete addition.Data['id'];
            delete addition.Data['Id'];
        }
    }
    for (var deletion of data.Deletions) {
        if ((deletion.Field === 'ContactPersons' && deletion.Data['id']) || deletion.Data['Id']) {
            delete deletion.Data['id'];
            delete deletion.Data['Id'];
        }
    }

    return newData;
}
