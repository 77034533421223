// Libraries
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';

// MUI
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Badge, styled } from '@mui/material';

// Core
import { ParameterValues, ResourceTextApplication, paramRepository } from '../core/resources';
import log from '../core/log';
import utils from '../core/utils';

// Common
import { Context } from '../common/contextSelector/ContextSelector';

// Interfaces
import {
    INotification,
    NotificationTypeEnum,
    RelatedEntityRole,
} from '../interfaces/INotification';
import { ICompanyDetails, IFarmDetails } from '../interfaces/IBusinessEntities';
import { IFarmVisit, IFarmVisitorContactInformation } from '../interfaces/IFarmVisit';
import { AliasTypeEnum } from '../interfaces/enums';
import { IProductionPlanSummary } from '../interfaces/IProductionPlan';
import { IConversation, IConversationResult } from '../interfaces/ICommunication';

// Store
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadPersonNamesAsync } from '../store/personNameSlice';

// API
import notificationApi from '../api/notificationApi';
import companyApi from '../api/companyApi';
import FarmVisitAPI from '../api/farmVisitApi';
import { snellmanContactPersons } from '../publicContactPersons/publicContactPersonsApi';
import productionPlanApi from '../api/productionPlanApi';
import communicationApi from '../api/communicationApi';
import personApi from '../api/personApi';

// Feature - Notifications
import NotificationList from './NotificationList';
import connection from './hub/AnelmaNotificationHubConnection';
import { mapPayload } from './helpers/mapToModifyNotificationsPayload';
import { getModifiedNotificationsWhenSetRead } from './helpers/modifyNotifications';
import { currentUtcAheadOrSame } from './helpers/dateHelpers';
import { combineProductionLines } from './helpers/companyNotificationHelpers';
import { getConstructedProdPlanNotifications } from './helpers/productionPlanNotificationHelper';
import { constructFarmVisitNotificationResourceTxt } from './helpers/farmVisitNotificationHelper';
import {
    getConstructedMessageNotification,
    getUniqueParticipants,
} from './helpers/messageNotificationHelper';
import { canCreate } from '../animals/production-plan/helpers/ProductionPlanAccessRights';

const StyledNotificationIconWrapper = styled('div')(({ theme }) => ({
    svg: {
        fill: '#fff',
        marginLeft: '1em',
        transform: 'scale(1.25)',
    },
}));

export default function NotificationRoot() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaNotifications',
    ]);

    const userAndFarmDataInitiallyLoaded = useRef(false);

    const userAndFarmRef = useRef({
        userId: '',
        farmId: '',
    });

    const userData = useAppSelector((state) => state.userData.data);
    const context = useAppSelector((state) => state.context);
    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const personNames = useAppSelector((state) => state.personNames.data);
    const dispatch = useAppDispatch();

    const loadNames = useCallback(
        (ids: string[]) => dispatch(loadPersonNamesAsync(ids)),
        [dispatch]
    );

    const [anchor, setAnchor] = useState<Element | null>(null);
    const [farmingTypes, setFarmingTypes] = useState<ParameterValues>([]);
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();
    const [farmVisits, setFarmVisits] = useState<IFarmVisit[]>([]);
    const [farmVisitContacts, setFarmVisitContacts] = useState<IFarmVisitorContactInformation[]>(
        []
    );
    const [receivedNotification, setReceivedNotification] = useState<INotification>();
    const [companies, setCompanies] = useState<ICompanyDetails[]>([]);
    const [productionPlanCompleted, setProductionPlanCompleted] = useState<ParameterValues>([]);
    const [productionPlans, setProductionPlans] = useState<IProductionPlanSummary[]>([]);
    const [userConversations, setUserConversations] = useState<IConversationResult[]>([]);
    const [conversations, setConversations] = useState<IConversation[]>([]);
    const [messageNotifications, setMessageNotifications] = useState<INotification[]>([]);

    useEffect(() => {
        openHubConnectionAsync();
        initializeParameters();

        // Cleanup the connection when the component unmounts
        return () => {
            closeHubConnectionAsync();
        };
    }, []);

    useEffect(() => {
        if (connection) receiveNotification();
    }, [connection]);

    useEffect(() => {
        if (context.data.currentContext) {
            setNotifications([]);
            initializeFarmDetails(context.data.currentContext);
            getContactPersons();
        }
    }, [context]);

    /** Side effect to call getNotifications when the component mounts and when the context changes. UserData.Id is not loaded fully on initial mount of NotificationRoot so it's also marked as a dependency array.
     *
     * Remarks: This side effect is fairly complex. There exists an issue where React doesn't batch userData + farmDetails state updates,
     * most likely due to the fact that other is redux state and other react state, so to achieve consistent getNotifications function call this is how the side effect has to be.
     *
     * JIRA ticket number: AN-2286
     */
    useEffect(() => {
        if (
            userAndFarmRef.current.userId.length > 0 &&
            userAndFarmRef.current.farmId !== farmDetails?.Id
        )
            getNotifications();

        if (userData.Id && farmDetails?.Id) {
            userAndFarmRef.current = {
                userId: userData.Id,
                farmId: farmDetails.Id,
            };

            if (!userAndFarmDataInitiallyLoaded.current) getNotifications();

            userAndFarmDataInitiallyLoaded.current = true;
        }
    }, [userData, farmDetails]);

    useEffect(() => {
        if (notifications.length > 0 && farmVisits.length > 0)
            processFarmVisitNotificationRemoval();
    }, [notifications]);

    /** Side effect which triggers when notification is received via SignalR channel
     */
    useEffect(() => {
        if (receivedNotification !== undefined) processReceivedNotification(receivedNotification);
    }, [receivedNotification]);

    useEffect(() => {
        if (personNames.length > 0 && messageNotifications.length > 0)
            processMessageNotifications(messageNotifications, userConversations);
    }, [personNames, messageNotifications]);

    /** Initialize all of the needed parameters within the Mtech resource API */
    const initializeParameters = () => {
        paramRepository.load(['AnelmaCompany', 'AnelmaBovine', 'AnelmaCommunication']).then(() => {
            const farmingTypesBovine = paramRepository.resource(
                'AnelmaCompany',
                'FarmingTypeBovine'
            );
            const farmingTypesPig = paramRepository.resource('AnelmaCompany', 'FarmingTypePig');

            setFarmingTypes([
                ...farmingTypesBovine.map((_) => {
                    return { code: `FarmingTypeBovine:${_.code}`, text: _.text };
                }),
                ...farmingTypesPig.map((_) => {
                    return { code: `FarmingTypePig:${_.code}`, text: _.text };
                }),
            ]);
            setProductionPlanCompleted(
                paramRepository.resource('AnelmaBovine', 'ProductionPlanCompleted')
            );
        });
    };

    const initializeFarmDetails = (context: Context) => {
        companyApi
            .getCompanyByContext(context.context)
            .then((response) => setFarmDetails(response?.Entity as IFarmDetails));
    };

    const openHubConnectionAsync = async () => {
        try {
            await connection.start();
        } catch (err) {
            log('error', `SignalR connection error: ${err}`);
        }
    };

    const closeHubConnectionAsync = async () => {
        try {
            await connection.stop();
        } catch (err) {
            log('error', `SignalR connection error: ${err}`);
        }
    };

    const receiveNotification = () => {
        connection.on('ReceiveNotification', (message) =>
            setReceivedNotification(JSON.parse(message) as INotification)
        );
    };

    const initializeReceivedNotificationState = () => {
        setReceivedNotification(undefined);
    };

    /** Check if provided farm id is in fact in the userData.CompanyRelations data.
     * If so that means it's safe to display the notification to certain user.
     *
     * Defaults to false.
     *
     * @param farmId string
     */
    const hasCompanyRelationMatch = async (farmId: string) => {
        let returnValue: boolean = false;

        await companyApi.getCompanyByGuid(farmId).then((_) => {
            if (!_) {
                enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                    variant: 'error',
                });
                return;
            }

            const result = userData.CompanyRelations.find(
                (relation) =>
                    relation.CompanyProducerNumber ===
                    _.Entity.Aliases.find((alias) => alias.Type === AliasTypeEnum.ProducerNumber)
                        ?.Value
            );

            if (result !== undefined && result.CompanyName.length > 0) returnValue = true;
        });

        return returnValue;
    };

    /**Set notifications to state. In case there are special rules that needs processing handle state set by type
     *
     * @param data INotification
     */
    const processReceivedNotification = async (data: INotification) => {
        switch (data.TypeId) {
            case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm:
            case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson:
                break;
            case NotificationTypeEnum.NewInvoice:
            case NotificationTypeEnum.NewCreditReport:
            case NotificationTypeEnum.NewCreditNote:
                if (await hasCompanyRelationMatch(data.FarmId))
                    handleReceivedInvoiceNotification(data);
                break;
            case NotificationTypeEnum.NewCompany:
                handleReceivedCompanyNotification(data);
                break;
            case NotificationTypeEnum.CreateProductionPlanForNextYear:
                if (await hasCompanyRelationMatch(data.FarmId))
                    setProductionPlanNotifications([data]);
                break;
            case NotificationTypeEnum.NewMessageInNewConversation:
            case NotificationTypeEnum.NewMessageInOldConversation:
                setReceivedMessageNotification(data);
                break;
            default:
                setReceivedNotificationToState(data);
                break;
        }

        initializeReceivedNotificationState();
    };

    /** Set received notification (via SignalR) to state while preserving production plan notification at first index, when one is present in the state currently.
     *
     * @param data INotification
     */
    const setReceivedNotificationToState = (data: INotification) => {
        setNotifications((previousState) => {
            const prodPlanNotification = previousState.findIndex(
                (item) => item.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear
            );

            if (prodPlanNotification !== -1)
                return [previousState[prodPlanNotification], data, ...previousState.slice(2)];

            return [data, ...previousState];
        });
    };

    const getNotifications = () => {
        if (userData.Id && farmDetails?.Id) {
            const userId = userData.Id;
            const farmId = farmDetails.Id;
            notificationApi
                .getNotificationList(userId, farmId, userData.IsSnellmanUser ?? false)
                .then((_) => {
                    if (_ === null) {
                        enqueueSnackbar(t('AnelmaNotifications:1015'), {
                            variant: 'error',
                        });
                        return;
                    }
                    handleSetNotifications(_.Items, farmId);
                });
        } else {
            enqueueSnackbar(t('AnelmaNotifications:1017'), {
                variant: 'error',
            });
        }
    };

    /** Set notifications. Every type is set differently as there are some specific data loading and ruling etc.
     *
     * @param notifications INotification[]
     * @param farmId string
     */
    const handleSetNotifications = (notifications: INotification[], farmId: string) => {
        // invoice notifications can be set here
        if (!userData.IsSnellmanUser)
            setNotifications(
                notifications.filter((_) => _.TypeId === 7 || _.TypeId === 8 || _.TypeId === 9)
            );

        handleFarmVisitNotifications(
            farmId,
            notifications.filter((_) => _.TypeId === 12 || _.TypeId === 13)
        );

        if (userData.IsSnellmanUser)
            handleCompanyNotifications(notifications.filter((_) => _.TypeId === 14));
        if (!userData.IsSnellmanUser)
            handleProductionPlanNotifications(notifications.filter((_) => _.TypeId === 1));

        handleMessageNotifications(notifications.filter((_) => _.TypeId === 2 || _.TypeId === 3));
    };

    const setRead = (data: INotification[]) => {
        notificationApi.modifyNotifications(mapPayload(data, 'setRead', userData.Id)).then((_) => {
            if (_ === null) {
                enqueueSnackbar(t('AnelmaNotifications:1016'), {
                    variant: 'error',
                });
                return;
            }

            setNotifications(getModifiedNotificationsWhenSetRead([...notifications], _.Items));
        });
    };

    /** Delete single notification. In case production plan notification and the notification currently in state, display error message.
     *
     * @param data INotification
     */
    const deleteNotification = (data: INotification) => {
        if (
            data.TypeId === NotificationTypeEnum.CreateProductionPlanForNextYear &&
            notifications.find((_) => _.NotificationId === data.NotificationId)
        ) {
            enqueueSnackbar(t('AnelmaNotifications:1020'), {
                variant: 'error',
            });
        } else {
            notificationApi
                .modifyNotifications(mapPayload([data], 'setDeleted', userData.Id))
                .then((_) => {
                    if (_ === null) {
                        enqueueSnackbar(t('AnelmaGeneral:1145'), {
                            variant: 'error',
                        });
                        return;
                    }
                    setNotifications([
                        ...notifications.filter((_) => _.NotificationId !== data.NotificationId),
                    ]);
                });
        }
    };

    /** Delete all notifications that are in the state. Is used only when user clicks AnelmaNotifications:1000 NotificationList component. */
    const deleteAllNotifications = () => setNotificationsAsDeleted();

    /** Delete noticications that are given as parameter (=some of the notifications). */
    const deleteNotifications = (data: INotification[]) => setNotificationsAsDeleted(data);

    const setNotificationsAsDeleted = (data?: INotification[]) => {
        const notificationsToPurge =
            data !== undefined && data.length > 0
                ? data
                : notifications.filter(
                      (_) => _.TypeId !== NotificationTypeEnum.CreateProductionPlanForNextYear
                  );

        if (notificationsToPurge.length > 0)
            notificationApi
                .modifyNotifications(mapPayload(notificationsToPurge, 'setDeleted', userData.Id))
                .then((_) => {
                    if (_ === null) {
                        enqueueSnackbar(t('AnelmaGeneral:1145'), {
                            variant: 'error',
                        });
                        return;
                    }
                    setNotifications(
                        notifications.filter((n) => !notificationsToPurge.includes(n))
                    );
                });
        else displayNothingToDeleteWarning();
    };

    const getUnreadNotifications = () => {
        return notifications.filter((_) => _.ReadDateTime === null).length;
    };

    const handleReceivedFarmVisitNotification = (data: INotification) => {
        const farmVisitId = data.NumbericKey;

        if (farmVisitId && farmVisits.find((_) => _.Id === farmVisitId) === undefined) {
            FarmVisitAPI.getFarmVisitById(farmVisitId).then((_) => {
                const farmVisit = _.Entity;
                setFarmVisits((previousState) => [farmVisit, ...previousState]);
                applyRulingForFarmVisitNotification(data, farmVisit, true);
            });
        }
    };

    const getContactPersons = () => {
        const contacts: IFarmVisitorContactInformation[] = [];
        snellmanContactPersons(!!loginStatus.loggedIn).then((data) => {
            if (!data) {
                enqueueSnackbar(t('AnelmaSolmuErrors:9999'), {
                    variant: 'error',
                });
                return;
            }
            data.Items.forEach((person) => {
                contacts.push({
                    Id: person.Id,
                    FullName: person.Name,
                    PersonId: person.PersonId,
                });
            });
            setFarmVisitContacts(contacts);
        });
    };

    /** For Snellman user get all farm visits and producer users only the ones that the selected farm has. */
    const handleFarmVisitNotifications = (farmId: string, notifications: INotification[]) => {
        if (userData.IsSnellmanUser) {
            FarmVisitAPI.getAllFarmVisits().then((_) => {
                setFarmVisits(_.Items);
                processFarmVisitNotifications(notifications, _.Items);
            });
        } else {
            FarmVisitAPI.getFarmVisitsByFarmId(farmId).then((_) => {
                setFarmVisits(_.Items);
                processFarmVisitNotifications(notifications, _.Items);
            });
        }
    };

    const processFarmVisitNotifications = (data: INotification[], farmVisits: IFarmVisit[]) => {
        for (const _ of data) {
            if (
                _.TypeId === NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm ||
                _.TypeId === NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson
            ) {
                const farmVisit = farmVisits.find((visit) => visit.Id === _.NumbericKey);
                if (farmVisit) applyRulingForFarmVisitNotification(_, farmVisit);
            }
        }
    };

    /** Check ruling for farm visit notification and later on the chain, set state accordingly
     *
     *  @param notification INotification, notification data to be processed
     *  @param farmVisit IFarmVisit, target farm visit
     *  @param viaSignalR boolean, when true, notifications are set so that the latest received notification via SignalR connection is put on top of the notification list as it's always the latest notification created
     */
    const applyRulingForFarmVisitNotification = (
        notification: INotification,
        farmVisit: IFarmVisit,
        viaSignalR: boolean = false
    ) => {
        if (
            notification.TypeId === NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm &&
            userData.IsSnellmanUser === false
        ) {
            if (currentUtcAheadOrSame(farmVisit.FromTime)) {
                if (
                    farmVisitContacts.find((c) => c.Id === farmVisit.ContactId)?.PersonId !==
                    userData.Id
                ) {
                    notification.FarmVisitResourceTxt = constructFarmVisitNotificationResourceTxt(
                        notification.TypeId,
                        t('AnelmaNotifications:1012'),
                        utils.date.utcStringToLocalMoment(farmVisit.FromTime),
                        farmVisitContacts.find((contact) => contact.Id === farmVisit.ContactId)
                            ?.FullName
                    );

                    setUpdatedFarmVisitNotifications(farmVisit, notification, viaSignalR);
                }
            }
        } else if (
            notification.TypeId === NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson
        ) {
            if (currentUtcAheadOrSame(farmVisit.FromTime)) {
                if (
                    farmVisitContacts.find((c) => c.Id === farmVisit.ContactId)?.PersonId ===
                    userData.Id
                ) {
                    notification.FarmVisitResourceTxt = constructFarmVisitNotificationResourceTxt(
                        notification.TypeId,
                        t('AnelmaNotifications:1013'),
                        utils.date.utcStringToLocalMoment(farmVisit.FromTime),
                        undefined,
                        farmVisit.CompanyName
                    );

                    setUpdatedFarmVisitNotifications(farmVisit, notification, viaSignalR);
                }
            }
        }
    };

    const setUpdatedFarmVisitNotifications = (
        farmVisit: IFarmVisit,
        notification: INotification,
        viaSignalR: boolean
    ) => {
        const idx = notifications.findIndex((_) => _.NumbericKey === farmVisit.Id);

        if (idx !== -1) {
            const updatedNotifications = [...notifications];
            updatedNotifications[idx] = notification;
            setNotifications(updatedNotifications);
        } else {
            if (viaSignalR) setReceivedNotificationToState(notification);
            else setNotifications((previousState) => [...previousState, notification]);
        }
    };

    /** User hasn't deleted farm visits within the wanted timespan => set those farm visit notifications deleted */
    const processFarmVisitNotificationRemoval = () => {
        const removableNotifications: INotification[] = [];

        notifications.forEach((n) => {
            if (
                (n.TypeId === NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm ||
                    n.TypeId === NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson) &&
                n.NumbericKey &&
                n.DeletedDateTime === null
            ) {
                const visit = farmVisits.find((_) => _.Id === n.NumbericKey);
                if (visit && moment().isAfter(visit.FromTime)) removableNotifications.push(n);
            }
        });

        if (removableNotifications.length > 0) deleteNotifications(removableNotifications);
    };

    /** Set invoice notification to state when logged in user isn't snellman user.
     * @param data INotification
     */
    const handleReceivedInvoiceNotification = (data: INotification) => {
        const invoiceId = data.NumbericKey;
        if (invoiceId && userData.IsSnellmanUser === false) setReceivedNotificationToState(data);
    };

    /** By company ids get related company data and process notifications
     * @param notifications INotification[]
     */
    const handleCompanyNotifications = (notifications: INotification[]) => {
        const ids = notifications.map((_) => _.UuidKey as string);

        if (ids && ids.length > 0) {
            companyApi.getCompaniesByIdList(ids).then((_) => {
                if (!_) {
                    enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                        variant: 'error',
                    });
                    return;
                }
                const companiesResult = _.Items;

                setCompanies(companiesResult.filter((_) => !companies.includes(_)));
                processCompanyNotifications(notifications, companiesResult);
            });
        }
    };

    /** Process fetched company notifications and set them to state with the constructed resource text
     * @param data INotification[]
     * @param companies ICompanyDetails[]
     */
    const processCompanyNotifications = (
        data: INotification[],
        companyDetails: ICompanyDetails[]
    ) => {
        for (const notification of data) {
            const notificationCompany = companyDetails.find((_) => _.Id === notification.UuidKey);
            // proceed when company data found and notification is not in the state already
            if (
                notificationCompany &&
                notifications.find((_) => _.NotificationId === notification.NotificationId) ===
                    undefined
            ) {
                setCompanyNotifications(notification, notificationCompany);
            }
        }
    };

    /** Set company notification to state when logged in user is snellman user. Set via SignalR received notification as the first respected value
     * @param data INotification
     */
    const handleReceivedCompanyNotification = (data: INotification) => {
        const companyId = data.UuidKey;
        if (companyId && userData.IsSnellmanUser) {
            const companyData = companies.find((_) => _.Id === companyId);

            if (companyData) setCompanyNotifications(data, companyData);
            else {
                companyApi.getCompanyByGuid(companyId).then((_) => {
                    if (!_) {
                        enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                            variant: 'error',
                        });
                        return;
                    }
                    const company = _.Entity;

                    const idx = companies.findIndex((c) => c.Id === company.Id);
                    if (idx === -1) setCompanies((previousState) => [...previousState, company]);

                    setCompanyNotifications(data, company);
                });
            }
        }
    };

    /** To set company type notifications and do some client side processing i.e. add the notification message.
     * @param notification INotification Retrieved notification.
     * @param company ICompanyDetails Fetched company details. The Notification API doesn't know anything about the company data other than the id.
     */
    const setCompanyNotifications = (notification: INotification, company: ICompanyDetails) => {
        if (notification && company) {
            const farm = company as IFarmDetails;
            const prodLines = farm.ProductionLines.flatMap((_) => _.FarmingTypes);

            if (prodLines) {
                notification.CompanyData = {
                    CompanyResourceTxt: t('AnelmaNotifications:1014')
                        .replace(
                            '{CompanyName}',
                            company.Names.find((_) => _.IsDefault)?.Value ?? ''
                        )
                        .replace(
                            '{FarmingType}',
                            combineProductionLines(prodLines, farmingTypes).join(', ')
                        ),
                    FarmId: farm.Aliases.find((i) => i.Type === AliasTypeEnum.FarmId)?.Value ?? '',
                };
                setReceivedNotificationToState(notification);
            }
        }
    };

    /** Get production plans and further process prod plan notifications.
     *
     * Some ruling which feels a bit tedious and might seem unclear.
     *
     * @param notifications INotification[]
     */
    const handleProductionPlanNotifications = (notifications: INotification[]) => {
        if (farmDetails && farmDetails.Id)
            productionPlanApi.getPlans(farmDetails.Id).then((_) => {
                if (!_) {
                    enqueueSnackbar(t('AnelmaSolmuErrors:10011'), {
                        variant: 'error',
                    });
                    return;
                }
                setProductionPlans(_.Items);
                processSetProductionPlanNotifications(_.Items, notifications);
            });
    };

    /** When current year prod plan confirmation date missing --> set prod plan notification(s) to state. Otherwise check need for production plan notification or straightaway delete production plan notification(s).
     *
     * @param prodPlans IProductionPlanSummary[]
     * @param notifications INotification[]
     */
    const processSetProductionPlanNotifications = (
        prodPlans: IProductionPlanSummary[],
        notifications: INotification[]
    ) => {
        if (
            notifications.length > 0 &&
            !prodPlans.find((_) => moment(_.ConfirmationDate).year() === moment().year())
        )
            setProductionPlanNotifications(notifications);
        else
            notifications.length === 0
                ? checkProductionPlanNotificationValidity()
                : deleteProductionPlanNotifications(notifications);
    };

    /** Set production plan notifications and construct the actual notification txt.
     *
     * A bit tedious because the notification API cannot know anything about the notification object data.
     *
     * @param notifications INotification[]
     */
    const setProductionPlanNotifications = (notifications: INotification[]) => {
        if (canCreate())
            setNotifications((previousState) => [
                ...getConstructedProdPlanNotifications(
                    t('AnelmaNotifications:1002'),
                    notifications,
                    productionPlanCompleted[0].code
                ),
                ...previousState,
            ]);
    };

    /** Check if there is a need for production plan notification via Anelma API.
     *
     *  In case yes: the API sends payload to the notification API.
     *
     *  In case not: do nothing.
     */
    const checkProductionPlanNotificationValidity = () => {
        const farmId = farmDetails?.Id;
        if (farmId && canCreate())
            productionPlanApi.productionPlanValidFor(farmId, 2).then((_) => {
                if (!_) {
                    enqueueSnackbar(t('AnelmaSolmuErrors:1000'), {
                        variant: 'error',
                    });
                    return;
                }
            });
    };

    /** In some cases production plan notification(s) can be deleted. There shouldn't be multiple ones in the state, but just to be sure do proceed approriately.
     *
     * NOTE: This functionality is mentioned unclearly in the design document. In case changes are needed consult designer.
     *
     * @param notifications
     */
    const deleteProductionPlanNotifications = (prodPlanNotifications: INotification[]) => {
        prodPlanNotifications.length > 1
            ? deleteNotifications(prodPlanNotifications)
            : deleteNotification(prodPlanNotifications[0]);
    };

    /** Set single received notification to the state. Get person full name and construct actual message notification txt with helper method.
     *
     * As always, data management is a bit tedious due to the high level of data fragmentation (person data is held in solmu, business data in anelma etc.)
     * @param notification INotification
     */
    const setReceivedMessageNotification = (notification: INotification) => {
        const conversationId = notification.UuidKey;
        if (conversationId) {
            communicationApi
                .retrieveConversations([conversationId])
                .then((conversationResponse) => {
                    if (!conversationResponse) {
                        enqueueSnackbar(t('AnelmaCommunication:1105'), { variant: 'error' });
                        return;
                    }
                    const conversationResult = conversationResponse.Items;
                    setConversations(conversationResult);

                    if (notification.RelatedEntities) {
                        const receiverId = notification.RelatedEntities.find(
                            (relatedEntity) =>
                                relatedEntity.Role === RelatedEntityRole.MessageReceiver &&
                                relatedEntity.Id === userData.Id
                        )?.Id;
                        if (receiverId)
                            personApi
                                .getPersonByGUID(
                                    notification.RelatedEntities.find(
                                        (_) => _.Role === RelatedEntityRole.MessageSender
                                    )?.Id as string // can cast straightaway as api returns always the sender id
                                )
                                .then((personResponse) => {
                                    if (!personResponse) {
                                        enqueueSnackbar(t('AnelmaGeneral:1020'), {
                                            variant: 'error',
                                        });
                                        return;
                                    }
                                    setReceivedNotificationToState(
                                        getConstructedMessageNotification(
                                            t,
                                            notification,
                                            `${personResponse.Entity.LastName} ${personResponse.Entity.FirstName}`,
                                            conversationResult[0].Title
                                        )
                                    );
                                });
                    }
                });
        }
    };

    const handleMessageNotifications = (notifications: INotification[]) => {
        if (userData.Id)
            communicationApi.getUserConversationList(userData.Id).then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaCommunication:1105'), { variant: 'error' });
                    return;
                }
                const conversationItems = response.Items;
                setUserConversations(conversationItems);

                loadNames(
                    getUniqueParticipants(conversationItems.flatMap((c) => c.ParticipantList))
                ).then(() => {
                    setMessageNotifications(notifications);
                });
            });
    };

    const processMessageNotifications = (
        notifications: INotification[],
        conversations: IConversationResult[]
    ) => {
        let constructedMessageNotifications: INotification[] = [];

        for (const notification of notifications) {
            const conversation = conversations.find(
                (_) => _.ConversationId === notification.UuidKey
            );
            if (conversation)
                constructedMessageNotifications.push(
                    getConstructedMessageNotification(
                        t,
                        notification,
                        personNames.find(
                            (p) =>
                                p.Owner ===
                                notification.RelatedEntities?.find(
                                    (r) => r.Role === RelatedEntityRole.MessageSender
                                )?.Id
                        )?.Data ?? '',
                        conversation.Title
                    )
                );
        }
        setNotifications((previousState) => [...previousState, ...constructedMessageNotifications]);
        setMessageNotifications([]);
    };

    const displayNothingToDeleteWarning = () => {
        enqueueSnackbar(t('AnelmaNotifications:1021'), {
            variant: 'warning',
        });
    };

    return (
        <>
            <StyledNotificationIconWrapper onClick={(event) => setAnchor(event.currentTarget)}>
                <Badge
                    badgeContent={getUnreadNotifications()}
                    color='error'
                    showZero
                    sx={{ cursor: 'pointer' }}
                >
                    <NotificationsNoneIcon />
                </Badge>
            </StyledNotificationIconWrapper>

            {anchor && (
                <NotificationList
                    anchor={anchor}
                    restoreAnchor={() => setAnchor(null)}
                    data={notifications}
                    setRead={(_: INotification[]) => setRead(_)}
                    deleteNotification={(_: INotification) => deleteNotification(_)}
                    deleteNotifications={() => deleteAllNotifications()}
                />
            )}
        </>
    );
}
