// Libraries
import { useState, useEffect } from 'react';

// Core
import auth from '../core/authorization';
import DefaultData from '../core/defaultData';
import FormValidator from '../core/FormValidator';

// Common
import { ADataGridActions } from '../common/dataGrid';
import { AEditIconButton } from '../common/buttons';

// Interfaces
import { IPersonDetails, IPersonSummary } from '../interfaces/IBusinessEntities';

// Feature
import PersonFormDialog, { EditPersonFormProps } from './PersonFormDialog';
import personApi from '../api/personApi';
import PersonSearchResult from './search/PersonSearchResult';

export interface PersonsProps {
    data: IPersonSummary[];
    isInactive: boolean;
    isLoading: boolean;
    showMoreInfo: boolean;
}

export function PersonsView(props: PersonsProps) {
    const [showPersonDialog, setShowPersonDialog] = useState<boolean>(false);
    const [selectedPersonId, setSelectedPersonId] = useState<string>('');
    const [personToEditData, setPersonToEditData] = useState<IPersonDetails>(
        DefaultData.personDetails()
    );
    const [editIsLoading, setEditIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (selectedPersonId !== '') {
            setEditIsLoading(true);
            personApi.getPersonByGuid(selectedPersonId).then((response) => {
                if (!response) return;

                setPersonToEditData(response.Entity);
                setEditIsLoading(false);
            });
        }
    }, [selectedPersonId]);

    const closePersonDialog = () => {
        setShowPersonDialog(false);
        setSelectedPersonId('');
        setPersonToEditData(DefaultData.personDetails());
    };

    const editPersonProps: EditPersonFormProps = {
        data: personToEditData,
        isInactive: props.isInactive,
        onClose: closePersonDialog,
        mode: 'modify',
        validator: new FormValidator(),
        fromCompanyModal: false,
    };

    const actionButton: ADataGridActions | undefined = auth.canUpdatePerson
        ? [
              (params) => {
                  const row = params.row as unknown as IPersonSummary;
                  return (
                      <AEditIconButton
                          onClick={() => {
                              setShowPersonDialog(true);
                              if (row.Id.length == 37)
                                  setSelectedPersonId(row.Id.substring(0, row.Id.length - 1));
                              else setSelectedPersonId(row.Id);
                          }}
                      />
                  );
              },
          ]
        : undefined;

    return (
        <>
            <PersonSearchResult
                actionColumn={actionButton}
                data={props.data}
                enableFiltering
                isLoading={props.isLoading}
                showMoreInfo={props.showMoreInfo}
            />

            {showPersonDialog &&
            !editIsLoading &&
            personToEditData !== DefaultData.personDetails() &&
            personToEditData != undefined ? (
                <PersonFormDialog {...editPersonProps} />
            ) : (
                ''
            )}
        </>
    );
}
