// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// MUI
import { Container } from '@mui/material';

// Core
import { ResourceTextApplication, paramRepository, ParameterValues } from '../../core/resources';

// Interfaces
import { RoutePaths } from '../../interfaces/enums';

// Feature
import './footer.scss';

export default function Footer() {
    const { t } = useTranslation<ResourceTextApplication>('AnelmaLayout');

    const [info, setInfo] = useState<ParameterValues>([]);

    useEffect(() => {
        paramRepository
            .load(['AnelmaLayout'])
            .then(() => setInfo(paramRepository.resource('AnelmaLayout', 'FooterContactInfo')));
    }, []);

    return (
        <footer className='an-footer'  data-robot-id = {"app-footer"}>
            <Container>
                <div className='an-footer-logo'></div>

                <div className='an-footer-text'>{t('AnelmaLayout:1033')}</div>

                <div className='an-footer-links'>
                    <a href='https://www.snellman.fi/fi' rel='noreferrer' target='_blank'>
                        Snellman
                    </a>
                    <a href='https://www.snellmangroup.fi' rel='noreferrer' target='_blank'>
                        Snellman&#8209;konserni
                    </a>
                    <a href='https://www.figen.fi' rel='noreferrer' target='_blank'>
                        Figen
                    </a>
                    <a href='https://www.ett.fi' rel='noreferrer' target='_blank'>
                        ETT
                    </a>
                    <a href='https://www.sikava.fi' rel='noreferrer' target='_blank'>
                        Sikava
                    </a>
                    <a href='https://www.naseva.fi' rel='noreferrer' target='_blank'>
                        Naseva
                    </a>
                </div>

                <div className='an-footer-contact'>
                    <ul>
                        {info.map((i, index) => {
                            let content = <>{i.text}</>;

                            if (i.code === 'email')
                                content = <a href={`mailto:${i.text}`}>{i.text}</a>;
                            else if (i.code === 'privacyStatement')
                                content = <Link to={RoutePaths.PrivacyStatement}>{i.text}</Link>;

                            return (
                                <li key={index} className={`an-footer-contact-${i.code}`}>
                                    {content}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Container>
        </footer>
    );
}
