// Libraries
import { useEffect, useState } from 'react';

// Core
import { paramRepository } from '../core/resources';

// Common
import { InfoBlock } from '../common/typography';

interface LanguageProps {
    label: string;
    languageCode: string;
}

export default function Language(props: LanguageProps) {
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        paramRepository.load(['AnelmaGeneral']).then(() => {
            const param = paramRepository.resource('AnelmaGeneral', 'CultureLanguage');
            const paramValue = param.find((i) => i.code === props.languageCode);
            setValue(paramValue?.text || props.languageCode || '');
        });
    }, []);

    return <InfoBlock label={props.label} value={value} />;
}
