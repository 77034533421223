// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// MUI
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

// Core
import { ResourceTextApplication } from '../../../core/resources';

// Common
import { ADataGrid, AGridColumns, gridColumns } from '../../../common/dataGrid';

// Interfaces
import IContactPersonRecipient from '../interfaces/IContactPersonRecipient';
// API

// Communication feature
import { ISearchResult } from './SearchRecipientsDialog';

export interface ISearchRecipientsResultProps {
    rowData: ISearchResult[] | IContactPersonRecipient[];
    handleOnSelectedRecipientChange: (params: GridRowSelectionModel) => void;
}

export default function SearchRecipientsResult(props: ISearchRecipientsResultProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
    ]);

    const handleCheckBoxChange = (selectionModel: GridRowSelectionModel) => {
        props.handleOnSelectedRecipientChange(selectionModel);
    };

    const initializeColumns = () => {
        const columns: AGridColumns = [
            gridColumns.withTooltip({
                field: 'CompanyName',
                headerName: t('AnelmaCommunication:1070'),
                width: 250,
            }),
            gridColumns.withTooltip({
                field: 'Name',
                headerName: t('AnelmaCommunication:1071'),
                width: 150,
            }),
            gridColumns.withTooltip({
                field: 'Title',
                headerName: t('AnelmaCommunication:1072'),
                width: 150,
            }),
        ];

        return columns;
    };

    return (
        <ADataGrid
            columns={initializeColumns()}
            enableRowNumbers
            rows={props.rowData.map((item: any) => mergeRight(item, { id: item.Id }))}
            onSelectionModelChange={(selectionModel) => handleCheckBoxChange(selectionModel)}
            enablePagination
        />
    );
}
