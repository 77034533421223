import ApiBase from './ApiBase';
import { reportingUrl } from './apiEndpoints';

export interface IGroups {
    ProductionReport?: string;
    HealthReport?: string;
    FinancialReport?: string;
    ButcheryReport?: string;
    TaxYearSummary?: string;
}

class ReportingApi extends ApiBase {
    readonly webIntegrationId = '6Kjem-e8vLkPfbv1iHwqROeZFxafkCCg';

    getToken(payload: IGroups) {
        return this.post<any>(`${reportingUrl}/qlik-sense-token`, payload, {
            incompatibleResult: true,
        });
    }
}

export default new ReportingApi();
