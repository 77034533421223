import { ProductionPlanAnimalType } from "../../../../interfaces/enums";
import { 
    IAnimalCapacity, IProductionPlanAnimalCounts 
} from "../../../../interfaces/IProductionPlan";

type AnimalCountWithField = IProductionPlanAnimalCounts & {
    field: keyof { Capacity: number, Animals: number }
}

class Mapper {

    private MapToArrayByType(
        a: IAnimalCapacity[],
        x: AnimalCountWithField,
        type: ProductionPlanAnimalType,
        v: number
    ) {
        let target = a.find(x => x.AnimalType === type);
        if (!target && v > 0){
            target = {
                AnimalType: type,
                Capacity: 0,
                Animals: 0,
                IsPlanned: true
            };
            a.push(target);
        }
        if (!target) return;
        if (target[x.field] !== v) {
            if (v === undefined || v === null) {
                v = 0;
            }
            target[x.field] = v;
            target.IsPlanned = true;
        }
    }

    ToDataGrid(a: IAnimalCapacity[]): AnimalCountWithField[] {
        return [
            {
                field: 'Capacity',
                Dairy: a.find(x => x.AnimalType === ProductionPlanAnimalType.DairyCow)?.Capacity || 0,
                Breeding: a.find(x => x.AnimalType === ProductionPlanAnimalType.BreedingCow)?.Capacity || 0,
                Bulls: a.find(x => x.AnimalType === ProductionPlanAnimalType.Bull)?.Capacity || 0,
                Heifers: a.find(x => x.AnimalType === ProductionPlanAnimalType.Heifer)?.Capacity || 0,
                Calves: a.find(x => x.AnimalType === ProductionPlanAnimalType.Calf)?.Capacity || 0,
            },
            {
                field: 'Animals',
                Dairy: a.find(x => x.AnimalType === ProductionPlanAnimalType.DairyCow)?.Animals || 0,
                Breeding: a.find(x => x.AnimalType === ProductionPlanAnimalType.BreedingCow)?.Animals || 0,
                Bulls: a.find(x => x.AnimalType === ProductionPlanAnimalType.Bull)?.Animals || 0,
                Heifers: a.find(x => x.AnimalType === ProductionPlanAnimalType.Heifer)?.Animals || 0,
                Calves: a.find(x => x.AnimalType === ProductionPlanAnimalType.Calf)?.Animals || 0,
            }
        ];
    }

    ToArray(a: IAnimalCapacity[], x: AnimalCountWithField) {
        this.MapToArrayByType(a, x, ProductionPlanAnimalType.DairyCow, x.Dairy);
        this.MapToArrayByType(a, x, ProductionPlanAnimalType.BreedingCow, x.Breeding);
        this.MapToArrayByType(a, x, ProductionPlanAnimalType.Bull, x.Bulls);
        this.MapToArrayByType(a, x, ProductionPlanAnimalType.Heifer, x.Heifers);
        this.MapToArrayByType(a, x, ProductionPlanAnimalType.Calf, x.Calves);
    }
}

export default new Mapper();