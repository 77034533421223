// Libraries
import { useEffect, useState } from 'react';

// Core
import {
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../../../core/resources';

// Common
import { TextWithTooltip } from '../../../../common/typography';

// Feature
import { AnnouncementTypeEnum } from '../../../../interfaces/enums';
import { useTranslation } from 'react-i18next';
import AWarningIconButton from '../../../../common/buttons/AWarningIconButton';
import '../../styles/announcementStyles.scss'

export interface IAnimalAnnouncementSalesTypeFieldProps {
    id: string;
    salesType: number;
    animalAnnouncementType: AnnouncementTypeEnum;
    allowedSalesTypes: ParameterValues | null;
    editSalesType: () => void;
}

export default function AnimalAnnouncementSalesTypeField(
    props: IAnimalAnnouncementSalesTypeFieldProps
) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);

    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues>([]);
    const [salesTypeIsValid, setSalesTypeIsValid] = useState<boolean>(true);

    useEffect(() => {
        paramRepository.load(['AnelmaBovine']).then(() => {
            setSalesTypeParams(paramRepository.resource('AnelmaBovine', 'SalesType'));
        });
    }, []);

    useEffect(() => {
        if (!props.allowedSalesTypes) return setSalesTypeIsValid(true);
        if (!props.allowedSalesTypes.find((x) => Number(x.code) === props.salesType)) {
            return setSalesTypeIsValid(false);
        }
        return setSalesTypeIsValid(true);
    }, [props.salesType, props.allowedSalesTypes]);

    const displaySalesTypeBackgroundFilling = (): boolean => {
        if (
            (props.animalAnnouncementType === AnnouncementTypeEnum.Slaughter &&
                props.salesType === 1) ||
            (props.animalAnnouncementType === AnnouncementTypeEnum.Slaughter &&
                props.salesType === 0) ||
            (props.animalAnnouncementType === AnnouncementTypeEnum.Intermediation &&
                props.salesType === 1) ||
            (props.animalAnnouncementType === AnnouncementTypeEnum.Intermediation &&
                props.salesType === 0)
        )
            return true;
        return false;
    };

    const invalidSalesTypeTooltipText = () => {
        if (
            props.animalAnnouncementType === AnnouncementTypeEnum.Slaughter ||
            props.animalAnnouncementType === AnnouncementTypeEnum.ManualBovineSlaughter
        )
            return t('AnelmaBovine:1148');

        if (
            props.animalAnnouncementType === AnnouncementTypeEnum.Intermediation ||
            props.animalAnnouncementType === AnnouncementTypeEnum.ManualBovineIntermediation
        )
            return t('AnelmaBovine:1150');

        return 'invalid';
    };

    const displayEmptyString = (): boolean => {
        if (props.salesType === 1 || props.salesType === 0) return true;
        return false;
    };

    return (
        <>
            {displaySalesTypeBackgroundFilling() ? (
                <div className='anelma-animal-announcement-general-background-light-red'> </div>
            ) : displayEmptyString() ? (
                ''
            ) : (
                <>
                    <TextWithTooltip
                        text={String(
                            salesTypeParams.find((_) => {
                                if (Number(_.code) === props.salesType) return _.text;
                            })?.text
                        )}
                    />
                    {!salesTypeIsValid && (
                        <AWarningIconButton
                            data-robot-id='animal-announcement-sales-type-warning-button'
                            onClick={props.editSalesType}
                            tooltip={invalidSalesTypeTooltipText()}
                        />
                    )}
                </>
            )}
        </>
    );
}
