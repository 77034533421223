// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../core/resources';
import FormValidator from '../../core/FormValidator';

// Common
import { ATextInput } from '../../common/inputs';
import { ASearchIconButton } from '../../common/buttons';

// Interfaces
import { IContactPerson } from '../../interfaces/IBusinessEntities';

// Feature
import { searchContactPerson } from '../publicContactPersonsApi';

export interface ContactPersonSearchFormProps {
    afterSearch: (data: IContactPerson[]) => void;
    beforeSearch: () => void;
    showInactive: boolean;
    showMoreInfo: boolean;
}

export default function ContactPersonSearchForm(props: ContactPersonSearchFormProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaPerson']);
    const validator = new FormValidator();

    const [searchTerm, setSearchTerm] = useState<string>('');

    const search = () => {
        if (searchTerm === null || searchTerm.trim() === '') return;
        props.beforeSearch();
        searchContactPerson('Name', searchTerm).then((data) => props.afterSearch(data.Items));
    };

    return (
        <>
            <ATextInput
                id='search'
                label={t('AnelmaPerson:1020')}
                onChange={(v) => setSearchTerm(v)}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') search();
                }}
                style={{ marginLeft: '10px', marginRight: '10px', width: '300px' }}
                validator={validator}
                value={searchTerm}
                withoutContainer
            />

            <ASearchIconButton
                onClick={() => search()}
                style={{ marginTop: '12px' }}
                type='action'
            />
        </>
    );
}
