// Libraries
import { useState } from 'react';

// Core
import { IFormValidator } from '../../core/FormValidator';

// Common
import { ADropdown } from '../../common/inputs';

export interface CompanySearchType {
    onSearchTypeChange: (type: string) => void;
    seletedSearchType: string;
    validator: IFormValidator;
}

export default function CompanySearchType(props: CompanySearchType) {
    const [seletedSearchType, setSelectedSearchType] = useState<string>(props.seletedSearchType);

    return (
        <ADropdown
            id='search-type'
            parameterName={'AnelmaCompany:CompanySearchType'}
            onChange={(v) => {
                const type = v as string;
                setSelectedSearchType(type);
                props.onSearchTypeChange(type);
            }}
            required={false}
            style={{ minWidth: '160px' }}
            validator={props.validator}
            value={seletedSearchType}
            withoutContainer
        />
    );
}
