// MUI
import { DialogActions } from '@mui/material';

// Common
import {
    AButtonBaseProps,
    ACancelButton,
    ADeleteButton,
    ADefaultButton,
    AOkButton,
    ASaveButton,
    ASendButton,
} from '../../common/buttons';
import { CSSProperties } from 'react';

export interface ADialogActionsProps {
    buttons: ADialogButtonsProp;
    isLoading?: boolean;
    hasBorder?: boolean;
}

export interface ADialogButtonsProp {
    left: ADialogButtonProp[];
    right: ADialogButtonProp[];
}

export interface ADialogButtonProp {
    classes?: string[];
    disabled?: boolean;
    enabledWhileLoading?: boolean;
    icon?: JSX.Element;
    label?: string;
    onClick: Function;
    type: 'cancel' | 'default' | 'delete' | 'ok' | 'save' | 'send' | 'with-label';
    style?: CSSProperties;
    leftSideText?: string;
}

function ADialogActions(props: ADialogActionsProps) {
    const { buttons, isLoading, ...other } = props;
    const leftButtons = buttons?.left || [];
    const rightButtons = buttons?.right || [];
    const button = (btn: ADialogButtonProp, key: string, lastOnTheLeft: boolean = false) => {
        const buttonProps: AButtonBaseProps = {
            children: btn.label,
            className: btn.classes ? btn.classes.join(' ') : '',
            disabled:
                btn.disabled || (btn.type !== 'cancel' && !btn.enabledWhileLoading && isLoading),
            key: key,
            onClick: () => btn.onClick(),
            style: btn.style ?? {},
        };
        if (lastOnTheLeft && buttonProps.style) buttonProps.style = { marginRight: 'auto' };

        if (btn.type === 'cancel') return <ACancelButton {...buttonProps} data-robot-id="cancel-button"/>;
        if (btn.type === 'delete') return <ADeleteButton {...buttonProps} data-robot-id="delete-button" />;
        if (btn.type === 'ok') return <AOkButton {...buttonProps} data-robot-id="ok-button"/>;
        if (btn.type === 'save') return <ASaveButton {...buttonProps}  data-robot-id="save-button"/>;
        if (btn.type === 'send') return <ASendButton {...buttonProps} type='action' data-robot-id="send-button"/>;
        if (btn.type === 'with-label')
            return (   
                <>
                    {' '}
                    <label style={{ marginLeft: 'auto', color: 'green' }}>
                        {' '}
                        {btn.leftSideText ?? ''}
                    </label>{' '}
                    <ADefaultButton {...buttonProps} icon={btn.icon} />{' '}
                </>            
            );
        return <ADefaultButton {...buttonProps} icon={btn.icon} />;
    };

    return (       
        <DialogActions
            {...other}
            style={
                props.hasBorder === undefined || props.hasBorder === true
                    ? { borderTop: '1px solid #0a4c31' }
                    : {}
            }
        >
            {leftButtons.map((btn, index) =>
                button(btn, `l-${index}`, index === leftButtons.length - 1)
            )}
            {rightButtons.map((btn, index) => button(btn, `r-${index}`))}
        </DialogActions>        
    );
}

export default ADialogActions;
