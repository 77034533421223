import { Context } from './ContextSelector';
import SelectedContext from './SelectedContext';

interface SingleContextProps {
    context: Context;
}

export default function SingleContext(props: SingleContextProps) {
    return (
        <div className='an-context-selector--single'>
            <SelectedContext context={props.context} />
        </div>
    );
}
