import { useEffect, useState, Dispatch, SetStateAction } from 'react';

// https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/
export default function useStickyState<T>(
    defaultValue: T,
    key: string
): [T, Dispatch<SetStateAction<T>>] {
    const [value, setValue] = useState<T>(() => {
        return <T>getStickyState(defaultValue, key);
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}

export const getStickyState = <T>(defaultValue: T, key: string): T => {
    const stickyValue = localStorage.getItem(key);
    if (stickyValue === null) return defaultValue;
    try {
        return JSON.parse(stickyValue);
    } catch {
        return defaultValue;
    }
};
