import { animalListUrl, animalUrl } from './apiEndpoints';
import { IAnimalSummary, ICarouselItem, IFillingCapacityStatistic, IFilteredAnimalList } from '../interfaces/IAnimal';

import ApiBase from './ApiBase';
import { GUIDType } from '../interfaces/types';

class AnimalListApi extends ApiBase {
    getAnimal = (animalGuid: GUIDType) => {
        return this.get<IAnimalSummary>(`${animalUrl}/${animalGuid}`);
    };

    getAnimals = (farmGuid: GUIDType) => {
        return this.getList<IAnimalSummary>(`${animalUrl}?FarmGUID=${farmGuid}`);
    };

    getCarouselDataByGUID(guid: GUIDType) {
        return this.getList<ICarouselItem>(`${animalUrl}/carousel/${guid}`);
    }

    getFilteredAnimals = (farmGuid: GUIDType, animalType: Number = 1) => {
        return this.getList<IFilteredAnimalList>(
            `${animalListUrl}?FarmGUID=${farmGuid}&AnimalType=${animalType}`
        );
    };

    getFillingStatistic = (farmGuid: GUIDType) => {
        return this.get<IFillingCapacityStatistic>(`${animalListUrl}/FillingStatistics/${farmGuid}`);
    };
}

export default new AnimalListApi();
