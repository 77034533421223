// Libraries
// MUI
// Core
// Common
import ViewLoader from '../common/ViewLoader';

// Interfaces
import { InfoSections, InfoSectionsGmFree, InfoSectionType } from '../interfaces/enums';

// Store
// API
// Messaging
// Feature
import RenderContent from '../editableContent/RenderContent';

export interface ISectionProps {
    section: InfoSections | InfoSectionsGmFree;
    sectionType: InfoSectionType;
    shared: string | undefined;
    bovine: string | undefined;
    pork: string | undefined;
    anelmaInfo: string | undefined;
}

export default function Section(props: ISectionProps) {
    return (
        <>
            {props.section === InfoSections.general && (
                <>
                    {props.shared !== undefined ? (
                        <RenderContent publicSite content={props.shared} />
                    ) : (
                        <ViewLoader />
                    )}
                    <br />
                </>
            )}

            {props.section === '1' && (
                <>
                    {props.shared !== undefined ? (
                        <RenderContent publicSite content={props.shared} />
                    ) : (
                        <ViewLoader />
                    )}
                    <br />
                </>
            )}

            {props.section === InfoSections.bovine && (
                <>
                    {props.bovine !== undefined ? (
                        <RenderContent publicSite content={props.bovine} />
                    ) : (
                        <ViewLoader />
                    )}

                    <br />
                </>
            )}

            {props.section === '2' && (
                <>
                    {props.bovine !== undefined ? (
                        <RenderContent publicSite content={props.bovine} />
                    ) : (
                        <ViewLoader />
                    )}
                    <br />
                </>
            )}

            {props.section === InfoSections.pork && (
                <>
                    {props.pork !== undefined ? (
                        <RenderContent publicSite content={props.pork} />
                    ) : (
                        <ViewLoader />
                    )}

                    <br />
                </>
            )}

            {props.section === '3' && (
                <>
                    {props.pork !== undefined ? (
                        <RenderContent publicSite content={props.pork} />
                    ) : (
                        <ViewLoader />
                    )}
                    <br />
                </>
            )}

            {props.section === InfoSections.anelmaInfo && (
                <>
                    {props.anelmaInfo !== undefined ? (
                        <RenderContent publicSite content={props.anelmaInfo} />
                    ) : (
                        <ViewLoader />
                    )}

                    <br />
                </>
            )}
        </>
    );
}
