//React
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// MUI
import { Container } from '@mui/material';

// Libraries
//Store
import { useAppSelector } from '../../store/hooks';

// Common
import ViewLoader from '../../common/ViewLoader';

// Core
import auth from '../../core/authorization';

//API
import companyApi from '../../api/companyApi';
//Feature
import InvoicesListView, { InvoicesListViewProps } from './InvoicesListView';
//Interface
import { AliasTypeEnum } from '../../interfaces/enums';

export default function Invoices() {
    const location = useLocation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [producerNumberContext, setProducerNumberContext] = useState<number>(0);
    const [nameContext, setNameContext] = useState<string>('');
    const [yTunnusContext, setYTunnusContext] = useState<string>('');
    const [addressContext, setAddressContext] = useState<string>('');

    const invoiceListViewProps: InvoicesListViewProps = {
        producerNumber: producerNumberContext,
        producerName: nameContext,
        producerTunnus: yTunnusContext,
        producerAddress: addressContext,
        isLoading: isLoading,
        preSelectedInvoiceNumber:
            location.state !== null ? location.state.options.invoiceNumber : null,
    };

    const context = useAppSelector((state) => state.context);
    useEffect(() => {
        if (context.data.currentContext) {
            setIsLoading(true);
            companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    const producerNumberContext = response?.Entity.Aliases.find(
                        (i) => i.Type === AliasTypeEnum.ProducerNumber
                    );
                    setProducerNumberContext(producerNumberContext?.Value as unknown as number);

                    setNameContext(
                        response?.Entity.Names.find((i) => i.IsDefault)?.Value as string
                    );
                    setYTunnusContext(response?.Entity.BusinessCode.Value as string);
                    setAddressContext(
                        response?.Entity.Addresses.find((i) => i.IsDefault)
                            ?.NormalizedAddress as string
                    );
                    setIsLoading(false);
                });
        } else setProducerNumberContext(0);
    }, [context]);

    return (
        <Container data-robot-id={'app-body-economy-invoices'}>
            {!isLoading ? (
                auth.canViewEconomyInvoice && <InvoicesListView {...invoiceListViewProps} />
            ) : (
                <ViewLoader />
            )}
        </Container>
    );
}
