// Libraries
import { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';
import auth from '../../core/authorization';
import FormValidator from '../../core/FormValidator';

// Common
import { ASwitch } from '../../common/inputs';
import { AAddButton } from '../../common/buttons';

// Interfaces
import { IPersonSummary } from '../../interfaces/IBusinessEntities';

// Feature
import PersonSearchForm from './PersonSearchForm';

export interface PersonSearchBarProps {
    afterSearch: (newPersonsList: IPersonSummary[]) => void;
    beforeSearch: () => void;
    openAddDialog: () => void;
    onShowInactiveChange: (v: boolean) => void;
    onShowMoreInfoChange: (v: boolean) => void;
}

export default function PersonSearchBar(props: PersonSearchBarProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaPerson']);
    const validator = new FormValidator();

    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

    useEffect(() => props.onShowInactiveChange(showInactive), [showInactive]);
    useEffect(() => props.onShowMoreInfoChange(showMoreInfo), [showMoreInfo]);

    const afterSearch = (data: IPersonSummary[]) => {
        props.afterSearch(data);
    };

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <PersonSearchForm
                        afterSearch={afterSearch}
                        beforeSearch={props.beforeSearch}
                        showInactive={showInactive}
                        showMoreInfo={showMoreInfo}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} style={{ textAlign: 'right' }}>
                    {auth.canCreatePerson && (
                        <AAddButton
                            onClick={() => props.openAddDialog()}
                            style={{ marginTop: '12px' }}
                            type='action'
                        >
                            {t('AnelmaPerson:1015')}
                        </AAddButton>
                    )}
                </Grid>
            </Grid>

            <Grid container justifyContent='flex-end'>
                <Grid item>
                    <ASwitch
                        checked={showMoreInfo}
                        id='show-more-info'
                        label={t('AnelmaPerson:1043')}
                        onChange={() => setShowMoreInfo(!showMoreInfo)}
                        validator={validator}
                        withoutContainer
                    />
                </Grid>
                <Grid item>
                    <ASwitch
                        checked={showInactive}
                        id='show-inactive'
                        label={t('AnelmaPerson:1044')}
                        onChange={() => setShowInactive(!showInactive)}
                        validator={validator}
                        withoutContainer
                    />
                </Grid>
            </Grid>
        </form>
    );
}
