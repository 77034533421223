// Libraries
import { useEffect, useState } from 'react';

// MUI
import { Container } from '@mui/material';

// Core
// Common
// Interfaces
import {
    IAnimalPayloadItem,
    ICombinedPayloadBatchRow,
    IPayloadSms,
} from '../../../interfaces/IAnimal';

// Store
// API
// Feature
import { AnimalPayloadsSmsMessageElement } from './AnimaPayloadsSmsMessageElement';

export interface AnimalPayloadsSmsDialogSmMessageFormProps {
    setMessage: (messages: IPayloadSms[]) => void;
    payload: IAnimalPayloadItem;
    selectedCombinedBatches: ICombinedPayloadBatchRow[];
}

export const AnimalPayloadsSmsDialogSmMessageForm = (
    props: AnimalPayloadsSmsDialogSmMessageFormProps
) => {
    const [smsMessages, setSmsMessages] = useState<IPayloadSms[]>([]);

    useEffect(() => {
        props.setMessage(smsMessages);
    }, [smsMessages]);

    const onSetMessage = (message: IPayloadSms) => {
        setSmsMessages((prevState) => {
            if (prevState.find((p) => p.MessageId === message.MessageId)) {
                let newState = [...prevState.filter((f) => f.MessageId !== message.MessageId)];
                newState.push(message);
                return newState;
            }

            return prevState.concat(message);
        });
    };

    return (
        <Container>
            {props.selectedCombinedBatches.map((b, i) => (
                <AnimalPayloadsSmsMessageElement
                    key={b.CompanyInfo.Guid}
                    batch={b}
                    id={i}
                    setMessage={(message) => onSetMessage(message)}
                    payload={props.payload}
                />
            ))}
        </Container>
    );
};
