import { ParameterValues } from '../../core/resources';
import { FarmingTypeEnum } from '../../interfaces/enums';

/** By farming types of a company provide an array of production lines
 * @param prodLines FarmingTypeEnum[] prodcution lines
 * @param farmingTypes ParameterValues
 */
export const combineProductionLines = (
    prodLines: FarmingTypeEnum[],
    farmingTypes: ParameterValues
) => {
    const combinedProdLines: string[] = [];

    farmingTypes.forEach((_) => {
        switch (_.code) {
            case 'FarmingTypeBovine:01':
                if (prodLines.find((l) => l === FarmingTypeEnum.Calving))
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypeBovine:02':
                if (prodLines.find((l) => l === FarmingTypeEnum.Dairy))
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypeBovine:03':
                if (prodLines.find((l) => l === FarmingTypeEnum.Upbringing))
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypeBovine:04':
                if (prodLines.find((l) => l === FarmingTypeEnum.Transmission))
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypePig:01':
                if (prodLines.find((l) => l === FarmingTypeEnum.PigletProduction))
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypePig:02':
                if (prodLines.find((l) => l === FarmingTypeEnum.CombinationProduction))
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypePig:03':
                if (prodLines.find((l) => l === FarmingTypeEnum.Piggery))
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypePig:04':
                if (prodLines.find((l) => l === FarmingTypeEnum.Processing))
                    combinedProdLines.push(_.text);
                break;
        }
    });

    return combinedProdLines;
};
