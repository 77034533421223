// Content
import { EditableContentType } from '../editableContent/IEditableContent';

// Feature
import InfoView from './InfoView';

export default function InstructiosAndCode() {
    return (        
        <InfoView
            anelmaInfoContentType={EditableContentType.InstructionsAnelmaInfo}
            bovineContentType={EditableContentType.InstructionsBovine}
            porkContentType={EditableContentType.InstructionsPork}
            sharedContentType={EditableContentType.Instructions}
            title={1041}
            applyContainer={true}
        />      
    );
}
