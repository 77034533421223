// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Typography } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import { paramRepository, ParameterValues } from '../core/resources';

// Common
import APanel from '../common/APanel';

// Interfaces
import { IInactivationDetails } from '../interfaces/IBusinessEntities';
import { CompanyEntityTypeEnum } from '../interfaces/enums';

// Store
// API
// Feature

export interface ICompanyInactivationInfoProps {
    companyType: CompanyEntityTypeEnum;
    effectiveTo: string | null;
    inactivationDetails: IInactivationDetails | null;
}

export default function CompanyInactivationInfo({
    companyType,
    effectiveTo,
    inactivationDetails,
}: ICompanyInactivationInfoProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);
    const [morereasonOptions, setMoreReasonOptions] = useState<ParameterValues>([]);
    const [reasonOptions, setReasonOptions] = useState<ParameterValues>([]);
    const [isLoading] = useState<boolean>(false);

    useEffect(() => {
        paramRepository.load(['AnelmaCompany']).then(() => {
            setReasonOptions(paramRepository.resource('AnelmaCompany', 'InactiveReason'));
            setMoreReasonOptions(paramRepository.resource('AnelmaCompany', 'InactiveAddReasons'));
        });
    }, [isLoading]);

    const getDetails = () => {
        if (
            Object.values(morereasonOptions).every((o) => o !== null) &&
            companyType == CompanyEntityTypeEnum.Farm
        ) {
            return (
                <APanel
                    title={
                        effectiveTo
                            ? t('AnelmaCompany:1111') +
                              ' ' +
                              new Date(effectiveTo).toLocaleString('fi-FI')
                            : undefined
                    }
                    variant='warning'
                >
                    <Grid container>
                        {effectiveTo && (
                            <Grid item sm={6}>
                                <Typography variant='h6'>{t('AnelmaCompany:1119')}</Typography>
                                {inactivationDetails?.InactivationTypeID &&
                                    reasonOptions.find(
                                        (i) =>
                                            parseInt(i.code) ===
                                            inactivationDetails?.InactivationTypeID
                                    )?.text}
                            </Grid>
                        )}

                        {effectiveTo && (
                            <Grid item sm={6}>
                                <Typography variant='h6'>{t('AnelmaCompany:1048')}</Typography>
                                {inactivationDetails?.InactivationReason?.map(
                                    (items) =>
                                        morereasonOptions.find((i) => parseInt(i.code) === items)
                                            ?.text
                                ).join(', ')}
                            </Grid>
                        )}
                    </Grid>

                    <Grid container style={{ marginTop: '20px' }}>
                        <Grid item>
                            <Typography variant='h6'>{t('AnelmaCompany:1049')}</Typography>
                            {inactivationDetails?.Info}
                        </Grid>
                    </Grid>
                </APanel>
            );
        }
        //Traffic contractor only date visible
        else if (companyType == CompanyEntityTypeEnum.TrafficContractor) {
            return (
                <APanel
                    title={
                        effectiveTo
                            ? t('AnelmaCompany:1111') +
                              ' ' +
                              new Date(effectiveTo).toLocaleString('fi-FI')
                            : undefined
                    }
                    variant='warning'
                ></APanel>
            );
        }
    };

    return (
        <>
            <div>{getDetails()}</div>
        </>
    );
}
