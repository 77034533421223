import { useState, useEffect } from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Container, Grid } from '@mui/material';

import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../common/dialog';

// Core
import { ResourceTextApplication, paramRepository, ParameterValues } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

import dateUtils from '../../core/utils.date';

// Interfaces
import {
    IInvoice,
    IInvoiceDetails,
    IInvoiceProducerInfo,
    ILinkedCreditInvoices,
    IRows,
    ITransportations,
    PaymentMethod,
} from '../../interfaces/IInvoice';
import { animalInvoiceTypeEnums } from './InvoiceDetailsGrid';

// Common
import { InfoBlock } from '../../common/typography';
import AnelmaLogo from '../../common/layout/snellman-logo.png';

// API
import InvoiceApi from '../../api/invoiceApi';

// Feature
import InvoiceMathHelper from './InvoiceMathHelper';
import InvoiceDetailsGrid from './InvoiceDetailsGrid';
import LinkedCreditInvoices from './LinkedCreditInvoices';
import './InvoiceDetailView.scss';

export interface InvoiceDetailViewProps {
    producerdata?: IInvoiceProducerInfo;
    rowIdData: IInvoice;
    onClose: () => void;
    validator?: IFormValidator;
}

export default function InvoiceDetailView(props: InvoiceDetailViewProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaInvoice',
        'AnelmaLayout',
        'AnelmaCompany',
    ]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentTermsParams, setPaymentTermsParams] = useState<ParameterValues>([]);
    const [paymentMethodParams, setPaymentMethodParams] = useState<ParameterValues>([]);

    const { rowIdData, producerdata } = props;

    const [rowsData, setRowsData] = useState<IInvoiceDetails[]>([]);

    const [rowsSupplementData, setRowsSupplementData] = useState<IRows[]>([]);
    const [linkedCreditData, setLinkedCreditData] = useState<ILinkedCreditInvoices[]>([]);

    const invoiceDetailData = () => {
        setIsLoading(true);
        InvoiceApi.getInvoice(props.rowIdData.InvoiceNumber).then((response) => {
            setRowsData([response.Entity]);
            setRowsSupplementData(response.Entity.Rows);

            switch (response.Entity.InvoiceAnimalType) {
                case animalInvoiceTypeEnums.Bovine:
                    setLinkedCreditData(response.Entity.LinkedCreditInvoices);
                    setIsLoading(false);
                    break;
                case animalInvoiceTypeEnums.PigBatch:
                    setLinkedCreditData(response.Entity.LinkedCreditInvoices);
                    setIsLoading(false);
                    break;
                default:
                    setLinkedCreditData([]);
                    setIsLoading(false);
                    break;
            }
        });
    };

    useEffect(() => {
        if (rowsSupplementData.length === 0) invoiceDetailData();

        paramRepository.load(['AnelmaInvoice']).then(() => {
            setPaymentTermsParams(paramRepository.resource('AnelmaInvoice', 'PaymentTerms'));
            setPaymentMethodParams(paramRepository.resource('AnelmaInvoice', 'PaymentMethod'));
        });
    }, []);

    const getVAT = (): string => {
        let vatPercentage = rowsData.find((i) => i.VATRate)?.VATRate;
        let vatAmout = rowsData.find((i) => i.VATAmount)?.VATAmount;
        return (
            InvoiceMathHelper.roundThousandLeading(vatPercentage as number) +
            '%' +
            ' / ' +
            InvoiceMathHelper.roundThousandLeading(vatAmout as number)
        );
    };

    const getNameAddress = (): JSX.Element[] => {
        return [producerdata?.ProducerName, producerdata?.ProducerAddress].map((str, index) => (
            <div key={index}>{str}</div>
        ));
    };

    const getPaymentTerms = (): string => {
        const paymentTermsData = rowsData.find((i) => i.PaymentTerm)?.PaymentTerm;
        const foundParam = paymentTermsParams.find(
            (p) => Number(p.code) === Number(paymentTermsData)
        )?.text;
        /** if no relevant PaymentTerm found
         *  load default PaymentTerms value of 1
         * Keräilypvm+14 p*/
        return foundParam ?? (paymentTermsParams.find((p) => p.code === '1')?.text as string);
    };

    const getOPBank = (): JSX.Element => {
        return (
            <Grid item sm={6} style={{ paddingLeft: '50px' }}>
                <div key={'op-bank'}> {t('AnelmaInvoice:1029')} </div>
                <div key={'op-number'}>{t('AnelmaInvoice:1030')}</div>
                <div key={'op-swift'}>{t('AnelmaInvoice:1031')}</div>
            </Grid>
        );
    };
    const getDanskeBank = (): JSX.Element => {
        return (
            <Grid item sm={6}>
                <div key={'danske-bank'}>{t('AnelmaInvoice:1032')} </div>
                <div key={'danske-number'}>{t('AnelmaInvoice:1033')} </div>
                <div key={'danske-swift'}>{t('AnelmaInvoice:1034')} </div>
            </Grid>
        );
    };

    const getDialogTitle = (rowsData: IInvoiceDetails[]): string => {
        const paymentMethodData = rowsData.find((f) => f.PaymentMethod)?.PaymentMethod;
        const foundParam = paymentMethodParams.find(
            (f) => Number(f.code) === Number(paymentMethodData)
        )?.text;
        return (
            foundParam ??
            (paymentMethodParams.find((f) => f.code === String(PaymentMethod.Invoice))
                ?.text as string)
        );
    };
    /**
     * Transportation[] containing Tosite and Kuljetuspäivä
     */
    const recepitTransportations: ITransportations[] = [];
    rowsData.forEach((element) => {
        element.Transportations.forEach((e, index) => {
            recepitTransportations.push({
                id: index,
                TransportDate: e.TransportDate,
                Receip: e.Receip,
            } as any);
        });
    });

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [],
    };

    return (
        <ADialog open={true} onClose={() => props.onClose()}>
            <ADialogTitle>{getDialogTitle(rowsData)}</ADialogTitle>

            <ADialogContent isLoading={isLoading} size='xl' className={'invoice-to-print'}>
                <Grid container style={{ overflow: 'hidden' }}>
                    <Grid item sm={6} style={{ paddingLeft: '50px' }}>
                        <InfoBlock label={t('AnelmaInvoice:1039')} value />

                        {getNameAddress()}

                        <InfoBlock
                            label={t('AnelmaInvoice:1041')}
                            value={producerdata?.ProducerYTunnus}
                        />
                        <InfoBlock
                            label={t('AnelmaInvoice:1005')}
                            value={producerdata?.ProducerNumber}
                        />
                    </Grid>

                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <InfoBlock
                                    label={t('AnelmaInvoice:1004')}
                                    value={rowIdData.InvoiceNumber}
                                />
                                <InfoBlock
                                    label={t('AnelmaInvoice:1024')}
                                    value={dateUtils.formatUtcString(
                                        rowsData.find((i) => i.InvoicingDate)?.InvoicingDate,
                                        'DD.MM.YYYY'
                                    )}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <img src={AnelmaLogo} className={'invoice-logo'} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <InfoBlock
                                    label={t('AnelmaInvoice:1017')}
                                    value={recepitTransportations.map((e, index) => {
                                        return <div key={index}>{e.Receip} </div>;
                                    })}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <InfoBlock
                                    label={t('AnelmaInvoice:1015')}
                                    value={recepitTransportations.map((e, index) => {
                                        return (
                                            <div key={index}>
                                                {dateUtils.formatUtcString(
                                                    e.TransportDate,
                                                    'DD.MM.YYYY'
                                                )}
                                            </div>
                                        );
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Container>
                    <Grid item md={12}>
                        {!isLoading && (
                            <InvoiceDetailsGrid
                                data={rowsSupplementData.length > 0 ? rowsSupplementData : []}
                                animalInvoiceType={
                                    (rowsData.find((e) => e.InvoiceAnimalType)
                                        ?.InvoiceAnimalType as number) ?? 0
                                }
                            />
                        )}
                    </Grid>
                </Container>
                <Grid container>
                    <Grid item md={12} style={{ paddingLeft: '50px' }}>
                        <InfoBlock label={t('AnelmaInvoice:1023')} value />
                    </Grid>

                    {getOPBank()}

                    {getDanskeBank()}

                    <Grid item md={6} style={{ paddingLeft: '50px' }}>
                        <InfoBlock
                            label={t('AnelmaInvoice:1028')}
                            value={t('AnelmaInvoice:1038')}
                        />

                        <InfoBlock label={t('AnelmaInvoice:1027')} value={getPaymentTerms()} />
                        <InfoBlock
                            label={t('AnelmaInvoice:1025')}
                            value={
                                InvoiceMathHelper.roundThousandLeading(
                                    rowsData.find((i) => i.InterestRate)?.InterestRate as number
                                ) + '%'
                            }
                        />
                        <InfoBlock
                            label={t('AnelmaInvoice:1026')}
                            value={
                                InvoiceMathHelper.roundThousandLeading(
                                    rowsData.find((i) => i.DelayInterestRate)
                                        ?.DelayInterestRate as number
                                ) + '%'
                            }
                        />
                    </Grid>
                    <Grid item md={6}>
                        <InfoBlock
                            label={t('AnelmaInvoice:1016')}
                            value={
                                rowsData.find((i) => i.ReferenceNumber != '0')?.ReferenceNumber ??
                                '-'
                            }
                        />
                        <InfoBlock
                            label={t('AnelmaInvoice:1001')}
                            value={dateUtils.formatUtcString(
                                rowIdData.InvoicePaymentDeadline as string,
                                'DD.MM.YYYY'
                            )}
                        />
                        <InfoBlock label={t('AnelmaInvoice:1012') + '  / €'} value={getVAT()} />
                        <InfoBlock
                            label={t('AnelmaInvoice:1022')}
                            value={InvoiceMathHelper.roundThousandLeading(
                                rowsData.find((i) => i.PriceBeforeTaxes)?.PriceBeforeTaxes as number
                            )}
                        />
                        <InfoBlock
                            label={t('AnelmaInvoice:1003')}
                            value={InvoiceMathHelper.roundThousandLeading(
                                rowsData.find((i) => i.PriceAfterTaxes)?.PriceAfterTaxes as number
                            )}
                        />
                    </Grid>
                </Grid>

                <Container>
                    {!isLoading && linkedCreditData.length > 0 && (
                        <Grid container>
                            <Grid item md={12}>
                                <LinkedCreditInvoices data={linkedCreditData} />
                            </Grid>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                <InfoBlock
                                    label={t('AnelmaInvoice:1002')}
                                    value={InvoiceMathHelper.roundThousandLeading(
                                        (rowsData.find((i) => i.UnpaidSum != 0)
                                            ?.UnpaidSum as number) ?? 0
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Container>
            </ADialogContent>
            <ADialogActions buttons={footerButtons} isLoading={isLoading} />
        </ADialog>
    );
}
