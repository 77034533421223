export class Strings {
    static readonly AnnouncementNumber = 'announcement-number';
    static readonly AnnouncementHoldingSiteId = 'slaughter-holding-site-id';
    static readonly AnnouncementCollectWeekStartNumber = 'announcement-collect-week-start-number';
    static readonly AnnouncementCollectWeekEndNumber = 'announcement-collect-week-end-number';
    static readonly AnnouncementCollectStartDetails = 'announcement-collect-start-details';
    static readonly AnnouncementCollectEndDetails = 'announcement-collect-end-details';
    static readonly AnnouncementAdditionalInformation = 'announcement-additional-information';
    static readonly AnnouncementEUIdentifier = 'announcement-animal-eu-identifier';
    static readonly AnnouncementAnimalBirthday = 'announcement-animal-birthday';
    static readonly AnnouncementAnimalSex = 'announcement-animal-sex';
    static readonly AnnouncementSalesTypeDropdown = 'announcement-sales-type-dropdown';
    static readonly AnnouncementAnimalCount = 'announcement-animal-count';
    static readonly AnnouncementListGridContainer =
        'anelma-animals-announcement-list-grid-container';
    static readonly AnnouncementDeleteConfirmActionType = 'delete';

    // Other
    static readonly AnnouncementBatchString: string = 'IlmEra,';
}
