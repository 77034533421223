// Libraries
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import { Tooltip } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';
import auth from '../../core/authorization';

// Common
// Interfaces
import { INotification, NotificationTypeEnum } from '../../interfaces/INotification';
import { RoutePaths } from '../../interfaces/enums';
import { ICompanyFarmId } from '../../interfaces/IBusinessEntities';
import { IProductionPlanNotificationLocationData } from '../../interfaces/IProductionPlan';
import { IConversationNotificationData } from '../../interfaces/ICommunication';

// Store
// API
// Feature - Production plan
import { canCreate } from '../../animals/production-plan/helpers/ProductionPlanAccessRights';
import { IFarmVisitNotificationLocationData } from '../../farmVisit/IFarmVisitNotificationLocationData';

const StyledContentDiv = styled('div')(({ theme }) => ({
    height: '34px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
}));

interface INotificationItemContentProps {
    notification: INotification;
    closeParent: () => void;
    deleteNotification: (data: INotification) => void;
}

/** Display notification's content and when content is clicked do action according to the content's type
 *
 * @props notification: INotification
 * @props closeParent: () => void
 * @props deleteNotification: (id: string) => void
 */

export default function NotificationItemContent({
    notification,
    closeParent,
    deleteNotification,
}: INotificationItemContentProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaNotifications']);

    const navigate = useNavigate();
    const currentLocation = useLocation();

    /** Handle specific cases of resource text. Sometimes there are none.
     *
     * @returns
     */
    const renderContent = () => {
        switch (notification.TypeId) {
            case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm:
            case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson:
                return notification.FarmVisitResourceTxt;
            case NotificationTypeEnum.NewCompany:
                return notification.CompanyData?.CompanyResourceTxt;
            case NotificationTypeEnum.CreateProductionPlanForNextYear:
                return notification.ProductionPlanResourceTxt;
            case NotificationTypeEnum.NewMessageInNewConversation:
            case NotificationTypeEnum.NewMessageInOldConversation:
                return notification.ConversationResourceTxt;
            default:
                return t(
                    `${notification.ApplicationResource.ApplicationCode}:${notification.ApplicationResource.ResourceTextCode}`
                );
        }
    };

    const routeToNotificationOrigin = () => {
        switch (notification.TypeId) {
            case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm:
            case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson:
                handleRoutingToOrigin(
                    RoutePaths.FarmVisitDialogEdit,
                    RoutePaths.FarmVisitDialogEdit,
                    {
                        FarmVisitId: notification.NumbericKey,
                        FarmId: notification.FarmId,
                    } as IFarmVisitNotificationLocationData,
                    auth.canViewFarmInfo
                );
                break;
            case NotificationTypeEnum.NewInvoice:
            case NotificationTypeEnum.NewCreditReport:
            case NotificationTypeEnum.NewCreditNote:
                handleRoutingToOrigin(
                    RoutePaths.EconomyInvoices,
                    RoutePaths.InvoiceDetailView,
                    {
                        invoiceNumber: notification.NumbericKey,
                    },
                    auth.canViewEconomyInvoice
                );
                break;
            case NotificationTypeEnum.NewCompany:
                handleRoutingToOrigin(
                    RoutePaths.GeneralFarmInfo,
                    RoutePaths.GeneralFarmInfo,
                    {
                        Id: notification.UuidKey,
                        FarmId: notification.CompanyData?.FarmId,
                    } as ICompanyFarmId,
                    auth.canViewFarmInfo
                );
                break;

            case NotificationTypeEnum.CreateProductionPlanForNextYear:
                handleRoutingToOrigin(
                    RoutePaths.AnimalsProductionPlans,
                    RoutePaths.ProductionPlanDialogRoot,
                    {
                        DisplayProductionPlanFormInstantly: true,
                    } as IProductionPlanNotificationLocationData,
                    canCreate()
                );
                break;
            case NotificationTypeEnum.NewMessageInNewConversation:
            case NotificationTypeEnum.NewMessageInOldConversation:
                handleRoutingToOrigin(
                    RoutePaths.GeneralMessaging,
                    RoutePaths.ConversationDialogEdit,
                    {
                        ConversationId: notification.UuidKey,
                    } as IConversationNotificationData,
                    auth.canViewMessages
                );
                break;
            default:
                alert(`#missing implementation for type ${notification.TypeId}`);
                break;
        }
    };

    /** Call routeTo with location that user is routed to
     *
     * When current location is the same as primaryTargetLocation there is no need to check if the user has access to the resource. That's been checked elsewhere already.
     * @param primaryTargetLocation RoutePaths. Path where the user is primarily routed to. Keep in mind that the user can already be located at the primary loc. If so, there has to be an alternative loc. to render the wanted result.
     * @param altTargetLocation RoutePaths. Path where the user is alternatively routed to.
     * @param options any. Options for useNavigate hook. NOTE! If you find a way to type this easily then please do so.
     * @param hasAccess boolean. Checked when called if an user has access to the resource in given as a parameter.
     */
    const handleRoutingToOrigin = (
        primaryTargetLocation: RoutePaths,
        altTargetLocation: RoutePaths,
        options: any,
        hasAccess: boolean
    ) => {
        if (currentLocation.pathname === primaryTargetLocation) routeTo(altTargetLocation, options);
        else {
            if (hasAccess) routeTo(primaryTargetLocation, options);
        }
    };

    const routeTo = (path: RoutePaths, options: any) => {
        navigate(path, {
            state: {
                options,
            },
        });

        // Check out the design doc. for further information
        if (
            notification.TypeId === NotificationTypeEnum.NewMessageInNewConversation ||
            notification.TypeId === NotificationTypeEnum.NewMessageInOldConversation
        )
            deleteNotification(notification);

        closeParent();
    };

    return (
        <Tooltip title={renderContent()} placement='top'>
            <StyledContentDiv onClick={() => routeToNotificationOrigin()}>
                {renderContent()}
            </StyledContentDiv>
        </Tooltip>
    );
}
