// MUI
import { CircularProgress, styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
    alignItems: 'center',
    background: 'rgba(255, 255, 255, .5)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
}));

export default function ADialogLoader() {
    return (
        <Wrapper>
            <CircularProgress />
        </Wrapper>
    );
}
