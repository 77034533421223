// Libraries
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../core/resources';
import FormValidator from '../core/FormValidator';

// Common
import { AMultiselect } from '../common/inputs';

// Feature
import { IAccessRole } from './IAccessRightsManagement';

export interface RoleSelectorProps {
    onChange: (selectedRoleIds: number[]) => any;
    selectedRoleIds: number[];
    roles: IAccessRole[];
}

export default function RoleSelector(props: RoleSelectorProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccessRights']);

    function roleName(role: IAccessRole): string {
        return t(`AnelmaAccessRights:${role.textCode}`);
    }

    function selectedRoles() {
        return props.roles
            .filter((i) => props.selectedRoleIds.includes(i.id as number))
            .map((i) => i.id);
    }

    return (
        <>
            <AMultiselect
                getOptionLabel={(role) => roleName(role)}
                id='selected-roles'
                items={props.roles}
                keyProp='id'
                label={t('AnelmaAccessRights:1001')}
                onChange={(ids) => {
                    props.onChange(ids as number[]);
                }}
                validator={new FormValidator()}
                value={selectedRoles()}
                withoutContainer
            />
        </>
    );
}
