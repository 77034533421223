// Libraries
import moment from 'moment';
import { TFunction } from 'i18next';

// Core
import log from '../../core/log';
import utils from '../../core/utils';

// Feature
import { IEditableContent } from '../IEditableContent';

class ContentUtils {
    currentContent(contents: IEditableContent[]) {
        const now = moment();
        const validContents = contents.filter((i) => {
            if (!i.Created) return false;
            if (!i.From && !i.Due) return true;
            const from = utils.date.utcStringToLocalMoment(i.From);
            if (from && now.isBefore(from)) return false;
            const due = utils.date.utcStringToLocalMoment(i.Due);
            if (due && now.isAfter(due)) return false;
            return true;
        });

        if (!validContents.length) return null;

        validContents
            .sort((a, b) => {
                const aFrom = utils.date.utcStringToLocalMoment(a.From);
                const bFrom = utils.date.utcStringToLocalMoment(b.From);

                if (aFrom?.isSame(bFrom)) {
                    const aCreation = utils.date.utcStringToLocalMoment(a.Created);
                    const bCreation = utils.date.utcStringToLocalMoment(b.Created);

                    if (aCreation?.isSame(bCreation)) return 0;
                    return aCreation?.isBefore(bCreation) ? -1 : 1;
                }

                return aFrom?.isBefore(bFrom) ? -1 : 1;
            })
            .reverse();

        return validContents[0];
    }

    contentValidity(
        t: TFunction<string[]>,
        content: IEditableContent,
        contents: IEditableContent[]
    ) {
        const current = this.currentContent(contents);

        if (current && current.Id === content.Id) return t('AnelmaContent:1025');

        const now = moment();
        const from = utils.date.utcStringToLocalMoment(content.From);
        const due = utils.date.utcStringToLocalMoment(content.Due);

        if (!content.Created) return t('AnelmaContent:1026');
        if (due && now.isAfter(moment(due))) return t('AnelmaContent:1027');
        if (from && now.isBefore(moment(from))) return t('AnelmaContent:1028');
        return t('AnelmaContent:1029');
    }

    parseOutputData(data: string | undefined) {
        try {
            return data ? JSON.parse(data) : undefined;
        } catch (e) {
            log('error', 'Failed to parse content data for wysiwyg.');
        }

        return undefined;
    }
}

export default new ContentUtils();
