// Libraries
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// MUI
import { Typography, ListItem, ListItemText } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import utils from '../core/utils';

// Common
import ContentLoader from '../common/ContentLoader';

// Interface
import { RoutePaths } from '../interfaces/enums';
import { ExtendedCalendarEvent } from '../interfaces/calendar/CalendarEvent';

// Store
import { useAppSelector } from '../store/hooks';

// API
import companyApi from '../api/companyApi';

// Feature
import calendarEventStorage, { CalendarRange } from './CalendarEventStorage';
import './agenda.scss';

interface AgendaDate {
    title: string;
    events: ExtendedCalendarEvent[];
}

export interface AgendaProps {
    closeUserMenu: () => void;
}

export default function Agenda(props: AgendaProps) {
    const context = useAppSelector((state) => state.context);

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [events, setEvents] = useState<AgendaDate[]>([]);
    const [events2, setEvents2] = useState<ExtendedCalendarEvent[]>([]);

    const [start] = useState<moment.Moment>(moment().startOf('day'));
    const [end] = useState<moment.Moment>(moment().add(14, 'days').endOf('day'));
    const [viewRange, setViewRange] = useState<CalendarRange>({
        companyId: '',
        end,
        producerNumber: '',
        start,
    });
    const [farmEntityId, setFarmEntityId] = useState<string>('');

    useEffect(() => {
        const con = context.data.currentContext?.context;

        if (con) {
            const prodId = utils.context.parseFarmContext(con)?.producerId || '';

            setIsLoading(true);

            companyApi.getCompanyByContext(con).then((response) => {
                if (!response) {
                    setViewRange({
                        ...viewRange,
                        companyId: '',
                        producerNumber: prodId,
                    });
                } else {
                    setViewRange({
                        ...viewRange,
                        companyId: response.Entity.Id || '',
                        producerNumber: prodId,
                    });
                    setFarmEntityId(response.Entity.Id || '');
                }

                setIsLoading(false);
            });
        } else {
            setViewRange({ ...viewRange, companyId: '', producerNumber: '' });
        }
    }, [context]);

    useEffect(() => {
        setIsLoading(true);
        if (farmEntityId) {
            calendarEventStorage.load(farmEntityId, viewRange, false).then((data) => {
                const usedData = data.filter((event) => {
                    const eventStart = moment(event.start);
                    const eventEnd = moment(event.end);
                    if (eventStart.isAfter(end)) return false;
                    if (eventEnd.isBefore(start)) return false;
                    return true;
                });
    
                usedData.sort((a, b) => ((a.start as Date) < (b.start as Date) ? -1 : 1));
    
                const agendaData: AgendaDate[] = [];
                const prevDate = '';
                usedData.forEach((e) => {
                    const date = moment(e.start).format('DD.MM.YYYY');
                    if (date !== prevDate) agendaData.push({ title: date, events: [e] });
                    else agendaData[agendaData.length - 1].events.push(e);
                });
    
                setEvents(agendaData);
                setEvents2(usedData);
                setIsLoading(false);
            });
        }
    }, [viewRange, farmEntityId]);

    const renderDate = (date: moment.Moment, e: ExtendedCalendarEvent) => {
        if (e.allDay) return t('AnelmaGeneral:1123');

        const eventStart = moment(e.start);
        const eventEnd = moment(e.end);

        if (eventStart.isBefore(date, 'day') && eventEnd.isAfter(date, 'day'))
            return t('AnelmaGeneral:1123');

        const startTime = eventStart.format('HH:mm');

        if (eventEnd.isAfter(date, 'day')) return `${startTime} - `;

        const endTime = eventEnd.format('HH:mm');

        if (eventStart.isBefore(date, 'day')) return `- ${endTime}`;

        return `${startTime} - ${endTime}`;
    };

    const renderDates = () => {
        const dates: React.ReactNode[] = [];
        const date = start.clone();
        while (date.isSameOrBefore(end)) {
            const evs = events2.filter((e) => {
                const eventStart = moment(e.start).startOf('day');
                const eventEnd = moment(e.end).endOf('day');
                if (date.isBefore(eventStart)) return false;
                if (date.isAfter(eventEnd)) return false;
                return true;
            });

            if (evs.length > 0) {
                dates.push(
                    <>
                        <ListItem>
                            <Typography variant='h5'>{date.format('dd DD.MM.YYYY')}</Typography>
                        </ListItem>

                        {evs.map((e, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={e.title} secondary={renderDate(date, e)} />
                            </ListItem>
                        ))}
                    </>
                );
            }

            date.add(1, 'day');
        }

        return (
            <>
                {dates.map((d, index) => (
                    <div key={index}>{d}</div>
                ))}
            </>
        );
    };

    return (
        <div className='user-menu-agenda'>
            <ListItem>
                <Typography variant='h3'>{t('AnelmaGeneral:1122')}</Typography>
            </ListItem>

            <ListItem>
                <Link to={RoutePaths.GeneralCalendar} onClick={() => props.closeUserMenu()}>
                    {t('AnelmaGeneral:1144')}
                </Link>
            </ListItem>

            {isLoading && (
                <ListItem>
                    <ContentLoader text={t('AnelmaGeneral:1141')} />
                </ListItem>
            )}

            {events.length === 0 && !isLoading && <ListItem>{t('AnelmaGeneral:1128')}</ListItem>}

            {renderDates()}
        </div>
    );
}
