// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import EditIcon from '@mui/icons-material/Edit';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AIconButtonBaseProps, ADefaultIconButton } from '../../common/buttons';

export interface AEditIconButtonProps extends AIconButtonBaseProps {}

export default function AEditIconButton(props: AEditIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1075');

    return <ADefaultIconButton {...buttonProps} icon={<EditIcon />} tooltip={tooltip2} />;
}
