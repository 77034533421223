import personApi from '../api/personApi';
import { CompanyEntityTypeEnum } from '../interfaces/enums';
import { IPersonRelation, IPersonSummaryExtended } from '../interfaces/IBusinessEntities';

/** Get person related data. First item in the tuple indicates if user is a Snellman user and second is about person's company relations.
 *
 * Function behaviour changed a bit when working on ticket AN-1071.
 * @param loginName
 * @returns [Promise<boolean>, IPersonRelation[]]
 */
export const fetchPersonRelations = async (
    loginName: string
): Promise<[boolean, IPersonRelation[]]> => {
    const returnValue = await personApi.getPersons('Login', loginName, false, true);

    const relations: IPersonRelation[] = [];
    (returnValue?.Items as IPersonSummaryExtended[]).forEach((i) => relations.push(...i.Relations));

    const userCompanyTypes: CompanyEntityTypeEnum[] = [];
    relations.forEach((j) => userCompanyTypes.push(j.CompanyType));

    const companyTypeCondition = (k: CompanyEntityTypeEnum) => k === CompanyEntityTypeEnum.Snellman;

    return [userCompanyTypes.some(companyTypeCondition), relations];
};
