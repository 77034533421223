// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

//
import { ResourceTextApplication } from '../../../core/resources';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../../common/dialog';

export interface ConfirmDeletionDialogProps {
    confirmation: string;
    onClose: () => void;
    onConfirmation: () => void;
    open: boolean;
}

export default function ConfirmAddRecipientDialog(props: ConfirmDeletionDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    return (
        <ADialog open={props.open}>
            <ADialogTitle>{t('AnelmaCommunication:1080')}</ADialogTitle>

            <ADialogContent size='sm'>
                <Typography variant='body1' style={{ padding: '30px 60px', textAlign: 'center' }}>
                    {props.confirmation}
                </Typography>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onClose(),
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => {
                                props.onConfirmation();
                                props.onClose();
                            },
                            type: 'save',
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
