// Libraries
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

// Interfaces
import { IBasicDocument, IDocumentAddDialogData } from '../documents/IDocuments';
import { GUIDType } from '../interfaces/types';

// Api
import api from '../api/profilePictureApi';

interface ProfilePictureState {
    data: IBasicDocument[];
}

const initialState: ProfilePictureState = {
    data: [],
};

function loadData(state: Draft<ProfilePictureState>, action: PayloadAction<IBasicDocument>) {
    const index = state.data.findIndex((i) => i.Owner === action.payload.Owner);
    const newData = [...state.data];

    if (index === -1) newData.push(action.payload);
    else newData[index] = action.payload;

    state.data = newData;
}

const profilePictureSlice = createSlice({
    name: 'profilePictures',
    initialState,
    reducers: {
        load: loadData,
    },
});

const { load } = profilePictureSlice.actions;

export const loadProfilePictureAsync = (id: GUIDType) => {
    return async (dispatch: any) => {
        try {
            await api.getProfilePicture(id).then((response) => {
                if (response?.Entity) dispatch(load(response.Entity));
            });
        } catch (err) {
            console.error(err);
        }
    };
};

export const updateProfilePictureAsync = (data: IDocumentAddDialogData) => {
    return async (dispatch: any) => {
        try {
            const promise = api.uploadProfilePicture(data);

            promise.then((response) => {
                if (response?.Entity) dispatch(load(response.Entity));
            });

            return promise;
        } catch (err) {
            console.error(err);

            return null;
        }
    };
};

export default profilePictureSlice.reducer;
