// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication, SystemText, SystemTextCulture } from '../core/resources';
import { IFormValidator } from '../core/FormValidator';

// Common
import { ADropdown, ADropdownItemProps, ADropdownValue, ATextInput } from '../common/inputs';
import {CompanyEntityTypeEnum} from '../interfaces/enums'

export interface AccessRightsGroupFormProps {
    data: SystemText;
    RoleGroup: CompanyEntityTypeEnum;
    onChange: (data: SystemText) => any;
    onRoleGroupChange: (data: CompanyEntityTypeEnum) => any;
    validator: IFormValidator;
}

export default function AccessRightsGroupForm(props: AccessRightsGroupFormProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccessRights']);


    function getGroupRoleParameters() : ADropdownItemProps[] {
        return [ 
            {
            text: t('AnelmaAccessRights:1244'),
            value: CompanyEntityTypeEnum.Snellman
        },
        {
            text: t('AnelmaAccessRights:1246'),
            value: CompanyEntityTypeEnum.Farm
        },
        {
            text: t('AnelmaAccessRights:1247'),
            value: CompanyEntityTypeEnum.TrafficContractor
        },
    ]; 
    }

    function updateCulture(culture: SystemTextCulture, value: string) {
        const { data } = props;
        const index = data.cultures.findIndex((i) => i.culture === culture);
        if (index === -1) data.cultures.push({ culture, value });
        else data.cultures[index].value = value;
        props.onChange(data);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <ATextInput
                        id='fi'
                        label={t('AnelmaAccessRights:1049')}
                        onChange={(v) => updateCulture('fi', v)}
                        required
                        validator={props.validator}
                        value={props.data.cultures.find((i) => i.culture === 'fi')?.value || ''}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ATextInput
                        id='sv'
                        label={t('AnelmaAccessRights:1050')}
                        onChange={(v) => updateCulture('sv-SE', v)}
                        required
                        validator={props.validator}
                        value={props.data.cultures.find((i) => i.culture === 'sv-SE')?.value || ''}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ATextInput
                        id='en'
                        label={t('AnelmaAccessRights:1051')}
                        onChange={(v) => updateCulture('en-GB', v)}
                        required
                        validator={props.validator}
                        value={props.data.cultures.find((i) => i.culture === 'en-GB')?.value || ''}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <ADropdown
                        label={t('AnelmaAccessRights:1248')}
                        id='role-group-dropdown'
                        items={getGroupRoleParameters()}
                        onChange={(_) => {
                            const x = _ as ADropdownValue;
                            props.onRoleGroupChange(x as number as CompanyEntityTypeEnum)}}
                        value={props.RoleGroup}
                        validator={props.validator}
                        required
                    ></ADropdown>
                </Grid>
            </Grid>
        </>
    );
}

