// Libraries
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import moment from 'moment';

// MUI
import { Container } from '@mui/material';

// Common
import { AOkButton } from '../buttons';
import './cookieNotification.scss';

export default function CookieNotification() {
    const { t } = useTranslation('AnelmaGeneral');

    const consentCookieName = 'an-cookie-consent';
    const [cookies, setCookies] = useCookies([consentCookieName]);

    if (cookies[consentCookieName]) return <></>;

    return (
        <div className='an-cookie-notification' data-robot-id = {"app-coockie-notification"}>
            <Container>
                <span>{t('AnelmaGeneral:1165')}</span>

                <br />

                <AOkButton
                    onClick={() =>
                        setCookies(consentCookieName, true, {
                            expires: moment().add(1, 'year').toDate(),
                            sameSite: 'strict',
                            secure: true,
                        })
                    }
                    size='large'
                >
                    {t('AnelmaGeneral:1166')}
                </AOkButton>
            </Container>
        </div>
    );
}
