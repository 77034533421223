export const removeDashes = (euIdentifier: string): string => {
    return euIdentifier.replace(/_|-/g, '');
};

export const removeDashFromEuIdentifier = (euIdentifier: string) => {
    return euIdentifier.replace('-', '');
};

export const addDashToEuIdentifier = (euIdentifier: string) => {
    let lastLetter: string = euIdentifier.slice(-1);
    return euIdentifier.substring(0, euIdentifier.length - 1) + '-'.concat(lastLetter);
};
