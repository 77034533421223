// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { ParameterValues, ResourceTextApplication } from '../core/resources';

// Common
import { InfoBlock } from '../common/typography';

// Interfaces
import { IPhoneNumber } from '../interfaces/IBusinessEntities';

// Feature
import utils from './utils';

interface PhoneNumbersProps {
    data: IPhoneNumber[];
    types: ParameterValues;
}

export default function PhoneNumbers(props: PhoneNumbersProps) {
    const { data, types } = props;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const defaultPhoneNumber = data.find((i) => i.IsDefault);
    const otherNumbers = data.filter((i) => !i.IsDefault);

    return (
        <>
            <Grid container>
                <Grid item sm={6}>
                    <InfoBlock
                        label={t('AnelmaGeneral:1015')}
                        value={defaultPhoneNumber?.NormalizedNumber}
                    />
                </Grid>
                {otherNumbers.map((i, key) => (
                    //AN-1145 Phone type is 52 not found in PhoneNumberType parameters- KM
                    <Grid key={key} item sm={6}>
                        <InfoBlock
                            label={utils.getParamText(types, i.Type)}
                            value={i.NormalizedNumber}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
