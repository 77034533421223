// Libraries
import { ForwardedRef, forwardRef, useEffect, useState, useCallback, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { StandardTextFieldProps, Autocomplete, Typography, Box } from '@mui/material';

// Core
import { paramRepository, ResourceTextApplication, ParameterSorting } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import { AInputContainer, AInputItem } from '../../common/inputs';
import { StyledTextField } from './ATextInput';

interface AMultiselectBaseProps {
    error?: boolean;
    id: string;
    label?: string;
    onChange: (value: AMultiselectValue[]) => any;
    required?: boolean;
    validator: IFormValidator;
    value: AMultiselectValue[];
    withoutContainer?: boolean;
    disabled?: boolean;
    customRenderOption?: ICustomRenderOption;
}

export interface ICustomRenderOption {
    render: boolean;
    feature: 'Messaging'; // Add more if needed and separate to interface
}

interface AMultiselectWithItemsProps extends AMultiselectBaseProps {
    getOptionLabel: (option: any) => string;
    items: any[];
    keyProp?: string;
    disabled?: boolean;
}

interface AMultiselectWithParamProps extends AMultiselectBaseProps {
    parameterName: string;
    sortValues?: ParameterSorting;
}

export type AMultiselectValue = number | string | null;
export type AMultiselectProps = AMultiselectWithItemsProps | AMultiselectWithParamProps;

export default forwardRef(function AMultiselect(props: AMultiselectProps, ref: ForwardedRef<any>) {
    const {
        error,
        id,
        parameterName,
        required,
        sortValues,
        validator,
        disabled,
        customRenderOption,
    } = props as AMultiselectWithParamProps;
    const { keyProp } = props as AMultiselectWithItemsProps;
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const [options, setOptions] = useState<AInputItem<AMultiselectValue>[]>(
        (props as AMultiselectWithItemsProps).items || []
    );
    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<AInputItem<AMultiselectValue>[]>([]);
    const [err, setErr] = useState<boolean>(false);

    const getValueFromItem = useCallback(
        (v2: any): AMultiselectValue => {
            if (parameterName) return v2.value;
            return keyProp ? (v2 as any)[keyProp] : v2;
        },
        [keyProp, parameterName]
    );

    useEffect(() => {
        if (!parameterName) return;
        const [groupName, paramName] = parameterName.split(':', 2);
        const group = groupName as ResourceTextApplication;
        paramRepository.load([group]).then(() => {
            const params = paramRepository.resource(group, paramName, sortValues);
            setOptions(params.map((p) => ({ text: p.text, value: p.code })));
        });
    }, [parameterName]);

    useEffect(() => {
        const value: AInputItem<AMultiselectValue>[] = options.filter((o) =>
            props.value.includes(getValueFromItem(o))
        );
        setVal(value);
    }, [getValueFromItem, options, props]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (required && val.length === 0) return false;
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setErr(!isValidAfterTouch());
    }, [error, id, required, touched, val, validator]);

    useEffect(() => {
        setOptions((props as AMultiselectWithItemsProps).items || []);
    }, [(props as AMultiselectWithItemsProps).items]);

    const getOptionLabel = (o: AInputItem<AMultiselectValue>) => {
        const p = props as AMultiselectWithItemsProps;
        if (p.getOptionLabel) return p.getOptionLabel(o);
        return o.text || '';
    };

    const textFieldProps: StandardTextFieldProps = {
        error: err,
        label: props.label || '',
        required: props.required,
        value: '',
        variant: 'standard',
    };

    const renderOptions = (
        renderOptionProps: HTMLAttributes<HTMLLIElement>,
        option: AInputItem<AMultiselectValue>
    ) => {
        switch (customRenderOption?.feature) {
            case 'Messaging':
                return renderRecipientOptions(renderOptionProps, option);
        }
    };

    /** Used via prop 'recipientsCustomRenderOption' from component CreateConversationDialog. JIRA ticker number: AN-2383.
     * @param renderOptionProps HTMLAttributes<HTMLLIElement>
     * @param option AInputItem<AMultiselectValue>
     * @returns JSX.Element
     */
    const renderRecipientOptions = (
        renderOptionProps: HTMLAttributes<HTMLLIElement>,
        option: AInputItem<AMultiselectValue>
    ) => {
        const options: string[] = option.text.split(','); // [0]: name, [1]: title/email depending on case

        return (
            <Typography {...renderOptionProps} sx={{ fontWeight: 'bold' }}>
                {options[0]}
                <Box display={'inline'} fontWeight={'normal'}>
                    {', '}
                    {options[1]}
                </Box>
            </Typography>
        );
    };

    return customRenderOption ? (
        <Autocomplete
            clearText={t('AnelmaGeneral:1066')}
            closeText={t('AnelmaGeneral:1067')}
            getOptionLabel={getOptionLabel}
            multiple
            onChange={(e, v) => {
                setTouched(true);
                setVal(v);
                props.onChange(v.map((v2) => getValueFromItem(v2)));
            }}
            openText={t('AnelmaGeneral:1068')}
            options={options}
            disabled={disabled}
            renderInput={(p) => {
                if (props.withoutContainer) return <StyledTextField {...p} {...textFieldProps} />;
                return (
                    <AInputContainer>
                        <StyledTextField {...p} {...textFieldProps} />
                    </AInputContainer>
                );
            }}
            renderOption={renderOptions}
            value={val}
        />
    ) : (
        <Autocomplete
            clearText={t('AnelmaGeneral:1066')}
            closeText={t('AnelmaGeneral:1067')}
            getOptionLabel={getOptionLabel}
            multiple
            onChange={(e, v) => {
                setTouched(true);
                setVal(v);
                props.onChange(v.map((v2) => getValueFromItem(v2)));
            }}
            openText={t('AnelmaGeneral:1068')}
            options={options}
            disabled={disabled}
            renderInput={(p) => {
                if (props.withoutContainer) return <StyledTextField {...p} {...textFieldProps} />;
                return (
                    <AInputContainer>
                        <StyledTextField {...p} {...textFieldProps} />
                    </AInputContainer>
                );
            }}
            value={val}
        />
    );
});
