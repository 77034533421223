// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import FindInPage from '@mui/icons-material/FindInPage';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AActionIconButton, ADefaultIconButton, AIconButtonBaseProps } from '../buttons';

export interface AFindInPageIconButtonProps extends AIconButtonBaseProps {
    variant?: 'action' | 'default';
}

export default function AFindInPageIconButton(props: AFindInPageIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaInvoice']);
    const { tooltip, variant, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaInvoice:1049');

    return <ADefaultIconButton {...buttonProps} icon={<FindInPage />} tooltip={tooltip2} />;
}
