// Libraries
import { ReactNode } from 'react';

// MUI
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

// Common
import { AButtonBaseProps } from '../../common/buttons';

const StyledButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(90deg, #8F0B0B 0%, #BA0D0D 100%)',
    borderRadius: '100px',
    textTransform: 'none',
}));

export interface ADangerButtonProps extends AButtonBaseProps {
    icon?: ReactNode;
}

export default function ADangerButton(props: ADangerButtonProps) {
    const { icon, ...buttonProps } = props;

    return <StyledButton {...buttonProps} color='secondary' startIcon={icon} variant='contained' />;
}
