// Libraries
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../common/dialog';

// Core
import { ResourceTextApplication, SystemText, systemTextApi } from '../core/resources';
import FormValidator from '../core/FormValidator';

// Feature
import { IAccessRole } from './IAccessRightsManagement';
import accessManagementApi from '../api/accessManagementApi';
import RoleForm from './RoleForm';
import { CompanyEntityTypeEnum } from '../interfaces/enums';

export interface RoleDialogProps {
    afterSaveFail: () => any;
    afterSaveSuccess: (mode: 'add' | 'edit', role: IAccessRole) => any;
    data: IAccessRole | null;
    onClose: () => any;
}

export default function RoleDialog(props: RoleDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaAccessRights',
        'AnelmaGeneral',
    ]);

    const rolePrefix = 'Snellman Anelma - ';
    const addDialog = props.data === null;
    const dialogTitle = addDialog ? 1003 : 1010;
    const validator = new FormValidator();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(!addDialog);
    const [roleTexts, setRoleTexts] = useState<SystemText>({
        additionalInfo: 'Snellman Anelma access role',
        application: 'AnelmaAccessRights',
        code: null,
        cultures: [
            { culture: 'fi', value: '' },
            { culture: 'sv-SE', value: '' },
            { culture: 'en-GB', value: '' },
        ],
    });
    const [roleGroup, setRoleGroup] = useState<CompanyEntityTypeEnum>(CompanyEntityTypeEnum.Farm);
    const [originalRoleGroup, setOriginalroleGroup] = useState<CompanyEntityTypeEnum>(
        CompanyEntityTypeEnum.Farm
    );

    function onRoleSave() {
        if (validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }

        setIsLoading(true);

        const onFail = () => {
            setIsLoading(false);
            props.afterSaveFail();
        };

        systemTextApi.save(roleTexts).then(
            (textResponse) => {
                const descPrefix =
                    addDialog ||
                    props.data?.description.substring(0, rolePrefix.length) === rolePrefix
                        ? rolePrefix
                        : '';
                const namePrefix =
                    addDialog || props.data?.name.substring(0, rolePrefix.length) === rolePrefix
                        ? rolePrefix
                        : '';
                const role2: IAccessRole = {
                    description: `${descPrefix}${
                        textResponse.cultures.find((i) => i.culture === 'fi')?.value
                    }`,
                    id: props.data?.id || null,
                    name: `${namePrefix}${
                        textResponse.cultures.find((i) => i.culture === 'en-GB')?.value
                    }`,
                    textCode: textResponse.code as number,
                };
                i18next.reloadResources(null, ['AnelmaAccessRights']).then(
                    () => {
                        const promise = addDialog
                            ? accessManagementApi
                                  .createNewRole(role2)
                                  .then(async (roleResponse) => {
                                      await accessManagementApi.attachRole(roleGroup, role2);
                                      return roleResponse;
                                  })
                            : accessManagementApi.updateRole(role2).then(async (roleResponse) => {
                                  if (roleGroup !== originalRoleGroup) {
                                      await accessManagementApi.detachRole(
                                          originalRoleGroup,
                                          role2
                                      );
                                      await accessManagementApi.attachRole(roleGroup, role2);
                                  }
                                  return roleResponse;
                              });
                        promise.then(
                            (roleResponse) => {
                                if (!roleResponse) return;

                                const savedRole = roleResponse.Entity;
                                props.afterSaveSuccess(addDialog ? 'add' : 'edit', savedRole);
                                props.onClose();
                            },
                            () => onFail()
                        );
                    },
                    () => onFail()
                );
            },
            () => onFail()
        );
    }

    useEffect(() => {
        if (!addDialog) {
            const promise = systemTextApi
                .get('AnelmaAccessRights', props.data?.textCode as number)
                .then((data) => {
                    setRoleTexts(data);
                });
            const promise2 = accessManagementApi
                .getRoleGroup(props.data?.name as string)
                .then((data) => {
                    setOriginalroleGroup(data);
                    setRoleGroup(data);
                });

            Promise.all([promise, promise2]).then(() => {
                setIsLoading(false);
            });
        }
    }, [addDialog, props]);

    return (
        <>
            {
                <ADialog onClose={() => props.onClose()} open={true}>
                    <ADialogTitle>{t(`AnelmaAccessRights:${dialogTitle}`)}</ADialogTitle>

                    <ADialogContent isLoading={isLoading} size='sm'>
                        <RoleForm
                            data={roleTexts}
                            RoleGroup={roleGroup}
                            onChange={(v) => setRoleTexts(v)}
                            onRoleGroupChange={(v) => setRoleGroup(v)}
                            validator={validator}
                        />
                    </ADialogContent>

                    <ADialogActions
                        buttons={{
                            left: [
                                {
                                    onClick: () => props.onClose(),
                                    type: 'cancel',
                                },
                            ],
                            right: [
                                {
                                    onClick: () => onRoleSave(),
                                    type: 'save',
                                },
                            ],
                        }}
                        isLoading={isLoading}
                    />
                </ADialog>
            }
        </>
    );
}
