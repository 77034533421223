// Libraries
import { ReactNode } from 'react';

// MUI
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material';

// Common
import { AIconButtonBaseProps } from '../../common/buttons';

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '100px',
    color: '#b60e1a',
    minWidth: '36px',
    padding: '6px',
}));

export interface ADangerIconButtonProps extends AIconButtonBaseProps {
    icon: ReactNode;
}

export default function ADangerIconButton(props: ADangerIconButtonProps) {
    const { icon, tooltip, ...buttonProps } = props;

    return (
        <Tooltip title={tooltip || ''}>
            <span>
                <StyledButton {...buttonProps} variant='text'>
                    {icon}
                </StyledButton>
            </span>
        </Tooltip>
    );
}
