import { mergeRight } from 'ramda';

// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { ADataGrid, AGridColumns, gridColumns } from '../common/dataGrid';

// Core
import { ResourceTextApplication } from '../core/resources';

// Interfaces
import { ISystemLogEvent } from '../interfaces/ILog';

// Feature

export interface ISystemLogMessages {
    logData: ISystemLogEvent[];
}

export default function SystemLogMessages(props: ISystemLogMessages) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAudit', 'AnelmaGeneral']);

    const initializeColumns = () => {
        const columns: AGridColumns = [
            gridColumns.withTooltip({
                field: 'Level',
                headerName: t('AnelmaAudit:1064'),
                width: 120,
            }),
            gridColumns.withTooltip({
                field: 'Application',
                headerName: t('AnelmaAudit:1065'),
                width: 300,
            }),
            gridColumns.withTooltip({
                field: 'Message',
                headerName: t('AnelmaAudit:1066'),
                width: 400,
            }),
            gridColumns.withTooltip({
                field: 'HttpStatusCode',
                headerName: t('AnelmaAudit:1067'),
                width: 100,
            }),
            gridColumns.dateTime('Timestamp', t('AnelmaGeneral:1130'), 'DD.MM.YYYY HH:mm'),
        ];

        return columns;
    };

    return (
        <>
            <ADataGrid
                enableFiltering
                columns={initializeColumns()}
                rows={props.logData.map((logRow) =>
                    mergeRight(logRow, {
                        id: logRow.Timestamp,
                        // This line is here because gridColumns.dateTime cannot handle the case if provided datetime already contains zulu character
                        Timestamp: logRow.Timestamp.replace('Z', ''),
                    })
                )}
            />
        </>
    );
}
