// Libraries
// MUI
import { Grid, styled } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { GridProps } from '@mui/system';

// Core
import utils from '../../core/utils';

// Common
// Interfaces
// Store
// API
// Feature

export interface IAnnotationProps {
    Annotations: string[];
}

const Wrapper = styled(Grid)<GridProps>(({ theme }) => ({
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#FFD580',
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '10px',
}));

export default function Annotation(props: IAnnotationProps) {
    return props.Annotations ? (
        <Grid key={`${utils.generateUniqueId()}-${new Date()}`} container>
            {props.Annotations.map((annotation) => {
                return (
                    <Wrapper item xs={12} sm={12} md={12} lg={12}>
                        <p key={utils.generateUniqueId()}>
                            <WarningIcon
                                sx={{
                                    color: '#cc6a0e',
                                    verticalAlign: 'middle',
                                    display: 'inline-block',
                                }}
                            />
                            {annotation}
                        </p>
                    </Wrapper>
                );
            })}
        </Grid>
    ) : null;
}
