// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../../core/resources';
import auth from '../../../core/authorization';

// Common
import ViewLoader from '../../../common/ViewLoader';

// Interfaces
import {
    IConversation,
    IConversationNotificationData,
    IConversationResult,
} from '../../../interfaces/ICommunication';
import { RoutePaths } from '../../../interfaces/enums';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import { getContactPersons } from '../../../publicContactPersons/publicContactPersonsApi';
import companyApi from '../../../api/companyApi';
import communicationApi from '../../../api/communicationApi';

// Feature - Communication - Messaging
import ConversationDialog from '../inbox/ConversationDialog';
import IContactPersonRecipient from '../../general/interfaces/IContactPersonRecipient';
import {
    getSnellmanContactPersonTitle,
    resolveContactPersonRole,
} from '../../general/helpers/helperFunctions';

/** Used to render ConversationDialog when clicked notification X when pathname equals RoutePaths.ConversationDialogEdit.
 *
 * ***If you find a better way to achieve this then obliterate the component.***
 */
export default function ConversationDialogNotification() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const context = useAppSelector((state) => state.context);
    const configuration = useAppSelector((state) => state.configuration);
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [snellmanContactPersons, setSnellmanContactPersons] = useState<IContactPersonRecipient[]>(
        []
    );
    const [personCompanyContactPersons, setPersonCompanyContactPersons] = useState<
        IContactPersonRecipient[]
    >([]);
    const [farmCompanyTitles, setFarmCompanyTitles] = useState<ParameterValues>([]);
    const [logisticsCompanyTitles, setLogisticsCompanyTitles] = useState<ParameterValues>([]);
    const [snellmanCompanyTitles, setSnellmanCompanyTitles] = useState<ParameterValues>([]);
    const [conversation, setConversation] = useState<IConversationResult | null>(null);
    const [conversationNotificationData] = useState<IConversationNotificationData | null>(
        getConversationLocationData()
    );

    useEffect(() => {
        paramRepository.load(['AnelmaCommunication', 'AnelmaGeneral']).then(() => {
            setFarmCompanyTitles(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
            setLogisticsCompanyTitles(paramRepository.resource('AnelmaGeneral', 'LogisticsTitles'));
            setSnellmanCompanyTitles(paramRepository.resource('AnelmaGeneral', 'SnellmanTitles'));
        });
    }, []);

    useEffect(() => {
        if (context.data.currentContext)
            getContactPersonsForCompany(context.data.currentContext.context);
        if (auth.canAddRestrictedEntityToConversation) getSnellmanContactPersons();
    }, [context]);

    useEffect(() => {
        if (conversationNotificationData)
            getConversation(conversationNotificationData.ConversationId);
    }, [conversationNotificationData]);

    const getConversation = (conversationId: string) => {
        setLoading(true);
        communicationApi.retrieveConversations([conversationId]).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaCommunication:1161'), { variant: 'error' });
                setLoading(false);
                return;
            }
            // Only one result therefore this is perfectly fine
            setConversation(mapToIConversationResult(response.Items[0]));
            setLoading(false);
        });
    };

    const mapToIConversationResult = (conversation: IConversation): IConversationResult => {
        return {
            ConversationId: conversation.ConversationId,
            ParticipantList: conversation.Participants, // !!! API DOESNT RETURN THESE AT THE MOMENT
            Topic: conversation.Topic,
            Title: conversation.Title,
            CreatedDateTime: conversation.CreatedDateTime,
            WasRead: true,
        };
    };

    const getSnellmanContactPersons = async () => {
        setLoading(true);
        await getContactPersons(!!loginStatus.loggedIn).then(
            (data) => {
                setSnellmanContactPersons(
                    data.Items.map((item) => ({
                        Id: item.PersonGUID,
                        Name: item.Name,
                        EmailAddress: item.EmailAddress,
                        CompanyId: item.CompanyGUID,
                        Title: getSnellmanContactPersonTitle(
                            item,
                            configuration.data.culture.supportedCultures.find((c) => c.selected)
                                ?.value
                        ),
                        ProfilePicture: item.PictureUrl,
                        CompanyName: 'Snellmanin Lihanjalostus Oy',
                    }))
                );
                setLoading(false);
            },
            () => {
                enqueueSnackbar(t('AnelmaCommunication:1109'), {
                    variant: 'error',
                });
                setLoading(false);
            }
        );
    };

    const getContactPersonsForCompany = (context: string) => {
        setLoading(true);
        companyApi.getAllCompanyContactPersons(context).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaCommunication:1109'), {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            setPersonCompanyContactPersons(
                response.Items.map((item) => ({
                    Id: item.PersonId,
                    Name: item.Name,
                    EmailAddress: item.EmailAddress,
                    CompanyId: '',
                    Title: resolveContactPersonRole(
                        6, // at this point it's fine to use farm, but when the logistics/traffic contractor feature is developed probably the person's company type changes aswell
                        item.Roles[0].Type,
                        farmCompanyTitles,
                        logisticsCompanyTitles,
                        snellmanCompanyTitles
                    ),
                    ProfilePicture: '',
                    CompanyName: '',
                }))
            );
            setLoading(false);
        });
    };

    function getConversationLocationData() {
        return location.state !== null && location.state.options.ConversationId
            ? ({
                  ConversationId: location.state.options.ConversationId,
              } as IConversationNotificationData)
            : null;
    }

    return (
        <>
            {!loading && conversation ? (
                <ConversationDialog
                    onClose={() => navigate(RoutePaths.GeneralMessaging)}
                    personCompanyContactPersons={personCompanyContactPersons}
                    snellmanContactPersons={snellmanContactPersons}
                    conversation={conversation}
                />
            ) : (
                <ViewLoader />
            )}
        </>
    );
}
