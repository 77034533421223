// Libraries
// MUI
// Core
// Common
// Interfaces
import { IProductionPlan, IProductionPlanSummary } from '../interfaces/IProductionPlan';
import { ProductionPlanNotificationQueryParamOptions } from '../interfaces/enums';
import { IValidity } from '../interfaces/IValidity';

// Store
// API
import ApiBase from './ApiBase';
import { productionPlanUrl } from './apiEndpoints';

// Feature

class ProductionPlanApi extends ApiBase {
    getTemplate(farmGuid: string, targetYear: number) {
        return this.get<IProductionPlan>(`${productionPlanUrl}/template/${farmGuid}/${targetYear}`);
    }

    getPlan(planId: string) {
        return this.get<IProductionPlan>(`${productionPlanUrl}/${planId}`);
    }

    getPlans(farmGuid: string) {
        return this.getList<IProductionPlanSummary>(`${productionPlanUrl}/list/${farmGuid}`);
    }

    getAllPlans() {
        return this.getList<IProductionPlanSummary>(`${productionPlanUrl}/list/all`);
    }

    /** Check production plan validity for a known farm. In case not valid send a notification and return boolean value.
     *  Purposely to be only used via front-end and only from Notification related functionality.
     *
     * @param farmId Farm entity id
     * @param options ProductionPlanNotificationQueryParamOptions enum
     * @returns IValidity
     */
    productionPlanValidFor(farmId: string, options: ProductionPlanNotificationQueryParamOptions) {
        return this.get<IValidity>(`${productionPlanUrl}/valid-for/${farmId}/${options}`);
    }

    createPlan(plan: IProductionPlan) {
        return this.postToTiltu<IProductionPlan>(`${productionPlanUrl}`, plan);
    }

    updatePlan(plan: IProductionPlan) {
        return this.putToTiltu<IProductionPlan>(`${productionPlanUrl}/${plan.Id}`, plan);
    }

    deletePlan(planId: string) {
        return this.deleteToTiltu(`${productionPlanUrl}/${planId}`);
    }
}

export default new ProductionPlanApi();
