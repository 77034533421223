// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// Store
import { useAppSelector } from '../store/hooks';

// MUI
import { Container } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';
import log from '../core/log';

// Interface
import defaultData from '../core/defaultData';
import { IFarmVisit, IFarmVisitor } from '../interfaces/IFarmVisit';

// Common
import ViewLoader from '../common/ViewLoader';
import { AGridColumns, gridColumns } from '../common/dataGrid';
import { TextWithTooltip } from '../common/typography';

// API
import FarmVisitAPI from '../api/farmVisitApi';
import { snellmanContactPersons } from '../publicContactPersons/publicContactPersonsApi';

// Feature
import MyFarmVisit from './MyFarmVisit';
import { mapFarmVisit } from './helpers/data';

export default function FarmVisit() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaFarmVisit',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);
    const context = useAppSelector((state) => state.context);
    const loginStatus = useAppSelector((state) => state.loginStatus.data);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [visitors, setVisitors] = useState<IFarmVisitor[]>([]);
    const [farmVisits, setFarmVisits] = useState<IFarmVisit[]>([]);
    const [subjects, setSubjects] = useState<ParameterValues>([]);

    useEffect(() => {
        fetchFarmVisitData();
        fetchSnellmanContactPersons();

        paramRepository.load(['AnelmaFarmVisit']).then(() => {
            setSubjects(paramRepository.resource('AnelmaFarmVisit', 'FarmVisitSubject'));
        });
    }, []);

    const columns: AGridColumns = [
        gridColumns.dateTime('FromTime', t('AnelmaGeneral:1155'), 'DD.MM.YYYY HH:mm'),
        gridColumns.withTooltip({
            field: 'ProducerNumber',
            headerName: t('AnelmaFarmVisit:1007'),
            width: 110,
        }),
        gridColumns.withTooltip({
            field: 'CompanyName',
            headerName: t('AnelmaFarmVisit:1008'),
            width: 175,
        }),
        {
            field: 'ContactId',
            headerName: t('AnelmaFarmVisit:1009'),
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
            valueGetter: (params) => {
                return (
                    visitors.find((visitor) => visitor.Id === params.row.ContactId)?.FullName || '-'
                );
            },
            width: 175,
        },
        {
            field: 'Subject',
            headerName: t('AnelmaFarmVisit:1022'),
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
            valueGetter: (params) => {
                return subjects.find((_) => _.code === params.value?.toString())?.text;
            },
            width: 125,
        },
        gridColumns.withTooltip({
            field: 'Notes',
            headerName: t('AnelmaFarmVisit:1023'),
            width: 250,
        }),
    ];

    const fetchFarmVisitData = () => {
        setIsLoading(true);
        FarmVisitAPI.getAllFarmVisits().then((response) => {
            if (!response) {
                setIsLoading(false);
                log('info', `'getAllFarmVisits' response returned no results.`);
                return;
            }
            setFarmVisits(response.Items);
            setIsLoading(false);
        });
    };

    const fetchSnellmanContactPersons = () => {
        const visitorCollection: IFarmVisitor[] = [];
        snellmanContactPersons(!!loginStatus.loggedIn).then((data) => {
            if (!data) {
                setIsLoading(false);
                log('info', `'snellmanContactPersons' response returned no results.`);
                return;
            }
            data.Items.forEach((person) => {
                visitorCollection.push({
                    Id: person.Id,
                    FullName: person.Name,
                });
            });
            setVisitors(visitorCollection);
            setIsLoading(false);
        });
    };

    const mapFarmVisitToAllFarmVisitData = (farmVisit: IFarmVisit) => {
        const idx = farmVisits.findIndex((_) => _.Id === farmVisit.Id);

        if (idx !== -1) {
            const updatedState = [...farmVisits];
            if (farmVisit.Attachments && farmVisit.Attachments.length > 0)
                setFarmVisits(mapFarmVisit(farmVisit, updatedState, idx));
            else {
                farmVisit.CompanyName = updatedState[idx].CompanyName;
                farmVisit.ProducerNumber = updatedState[idx].ProducerNumber;
                updatedState[idx] = farmVisit;
                setFarmVisits(updatedState);
            }
        } else setFarmVisits((prev) => [...prev, farmVisit]);
    };

    const deleteFarmVisitFromList = (id: number) => {
        const removeDeletedFarmVisit = [...farmVisits];
        const index = removeDeletedFarmVisit.findIndex((p) => p.Id === id);
        removeDeletedFarmVisit.splice(index, 1);
        console.log(removeDeletedFarmVisit);
        setFarmVisits(removeDeletedFarmVisit);
    };

    return (
        <>
            <Container data-robot-id={'app-body-farm-visit'}>
                {!isLoading ? (
                    auth.canViewFarmInfo && (
                        <MyFarmVisit
                            {...{
                                rootTitle: true,
                                showAddButton: false,
                                farmDetails: undefined,
                                data: defaultData.farmVisit(),
                                onClose: Function,
                                columns: columns,
                                allFarmVisitData: farmVisits
                                    .map((i) => mergeRight(i, { id: i.Id }))
                                    .sort(
                                        (a, b) =>
                                            new Date(b.FromTime).valueOf() -
                                            new Date(a.FromTime).valueOf()
                                    ),
                                visitors: visitors,
                                handleMapFarmVisit: mapFarmVisitToAllFarmVisitData,
                                deleteFarmVisitFromList: deleteFarmVisitFromList,
                                myFarmVisits: [],
                            }}
                        />
                    )
                ) : (
                    <ViewLoader />
                )}
            </Container>
        </>
    );
}
