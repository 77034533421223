import { ButtonGroup, ButtonGroupProps } from '@mui/material';
import { styled } from '@mui/material';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    root: {
        '& button': {
            borderColor: 'transparent',
        },
    },
}));

export default function ADataGridButtonGroup(props: ButtonGroupProps) {
    return <StyledButtonGroup {...props} data-robot-id='data-grid-button-group' />;
}
