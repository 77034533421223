// Libraries
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Core
import auth from '../../../../core/authorization';

// Feature
import { ISubMenuItem, ISubMenuItemLink, ISubMenuItemPlaceholder } from '../mainMenu/IMainMenu';
import { RoutePaths } from '../../../../interfaces/enums';
import utils from '../../../../core/utils';

export interface SubMenuItemProps {
    closeSubMenu: () => void;
    item: ISubMenuItem;
}

export default function SubMenuItem(props: SubMenuItemProps) {
    const { t } = useTranslation('AnelmaLayout');

    const { closeSubMenu, item } = props;

    const link = item as ISubMenuItemLink;

    const hasAccessToResource = () => {
        switch (link.href) {
            case RoutePaths.ReportingButchery.valueOf():
                return auth.canViewButcheryReport ? true : false;
            case RoutePaths.ReportingProduction.valueOf():
                return auth.canViewProductionReport ? true : false;
            case RoutePaths.ReportingHealth.valueOf():
                return auth.canViewHealthReport ? true : false;
            case RoutePaths.ReportingFinancial.valueOf():
                return auth.canViewFinancialReport ? true : false;
            case RoutePaths.EconomyTaxYearSummary.valueOf():
                return auth.canViewEconomyTaxYearSummary ? true : false;
            default:
                return true;
        }
    };;

    if (link.href) {
        const text = t(`AnelmaLayout:${link.textCode}`)

        if (link.externalLink)
            return (
                <a href={link.href} target='_blank'>
                    {text}
                </a>
            );

        return hasAccessToResource() ? (
            <Link
                key={`${link.href}${link.textCode.toString()}`}
                to={link.href}
                onClick={() => closeSubMenu()}
            >
                {text}
            </Link>
        ) : null;
    }

    const placeholder = item as ISubMenuItemPlaceholder;

    return (
        <span className='an-sub-menu-item-placeholder' key={utils.generateUniqueId()}>
            {placeholder.text}
        </span>
    );
}
