// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../core/resources';
import utils from '../core/utils';
import log from '../core/log';

// Common
import { ADataGrid } from '../common/dataGrid';
import { ADialogSubHeading } from '../common/dialog';

// Api
import accessManagementApi from '../api/accessManagementApi';

// Feature
import { IAccessRole } from '../accessRightsManagement/IAccessRightsManagement';
import { UsernameAccessRoles } from '../companies/contactPersons/ContactPersons';

export interface PersonsAccessRolesProps {
    login: string;
}

interface AccessRoleGridData {
    companyName: string;
    id: number;
    roleName: string;
}

export default function PersonsAccessRoles(props: PersonsAccessRolesProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccessRights', 'AnelmaPerson']);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [accessRoles, setAccessRoles] = useState<IAccessRole[]>();
    const [userAccessRoles, setUserAccessRoles] = useState<UsernameAccessRoles>();
    const [data, setData] = useState<AccessRoleGridData[]>([]);

    useEffect(() => {
        accessManagementApi.getRoles().then((data) => {
            if (!data) return;

            setAccessRoles(data.Items);
        });
        accessManagementApi.getAccessRoles(props.login).then(
            (response) => {
                if (!response) return;
                setUserAccessRoles(response.Entity);
            },
            () => {
                setIsLoading(false);
                log('error', `Failed to retrieve user\'s access roles.`, {
                    username: props.login || '',
                });
            }
        );
    }, []);

    useEffect(() => {
        if (!accessRoles) return;
        if (!userAccessRoles) return;

        let id = 0;
        const data: AccessRoleGridData[] = [];
        data.push(
            ...userAccessRoles.roles.map((roleName) => {
                const role = accessRoles.find((i) => i.name === roleName);
                return {
                    companyName: '',
                    id: id++,
                    roleName: role ? t(`AnelmaAccessRights:${role.textCode}`) : roleName,
                };
            })
        );

        userAccessRoles.contextScopedRoles.forEach((contextRoles) => {
            let context = utils.context.parseFarmContext(contextRoles.contextName as string);
            data.push(
                ...contextRoles.roles.map((roleName) => {
                    const role = accessRoles.find((i) => i.name === roleName);
                    return {
                        companyName: context?.customerName || '***',
                        id: id++,
                        roleName: role ? t(`AnelmaAccessRights:${role.textCode}`) : roleName,
                    };
                })
            );
        });

        data.sort((a, b) => {
            if (a.companyName === b.companyName) {
                return a.roleName < b.roleName ? -1 : 1;
            }
            return a.companyName < b.companyName ? -1 : 1;
        });

        setData(data);
        setIsLoading(false);
    }, [accessRoles, userAccessRoles]);

    return (
        <>
            <ADialogSubHeading>{t('AnelmaAccessRights:1000')}</ADialogSubHeading>

            <ADataGrid
                columns={[
                    {
                        field: 'roleName',
                        flex: 1,
                        headerName: t('AnelmaPerson:1022'),
                        type: 'string',
                    },
                    {
                        field: 'companyName',
                        flex: 1,
                        headerName: t('AnelmaAccessRights:1107'),
                        type: 'string',
                    },
                ]}
                loading={isLoading}
                rows={data}
            />
        </>
    );
}
