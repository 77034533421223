// Libraries
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// Core
import { baseUrl } from '../core/constants';

// Interfaces
import { IApiListResult, IApiResult, ITiltuApiResult, IApiResultMessage } from '../interfaces/IGeneral';

interface ApiOptions {
    credentials?: 'include';
    headers?: {
        [key: string]: string;
    };
    incompatibleResult?: boolean;
    responseType?: 'blob';
    throwValidationErrors?: boolean;
    timeout?: number;
}
interface AxiosRequestConfigExtended extends AxiosRequestConfig {
    throwValidationErrors?: boolean;
}
export default abstract class ApiBase {

    protected get baseUrl(): string {
        return baseUrl;
    }

    protected delete<T>(url: string, apiOptions?: ApiOptions): Promise<IApiResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiResult(axios.delete<T>(url, config))
            : this.wrap(axios.delete<IApiResult<T>>(url, config));

        return promise;
    }

    protected deleteList<T>(
        url: string,
        apiOptions?: ApiOptions
    ): Promise<IApiListResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiListResult(axios.delete<T>(url, config))
            : this.wrap(axios.delete<IApiListResult<T>>(url, config));

        return promise;
    }

    protected get<T>(url: string, apiOptions?: ApiOptions): Promise<IApiResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiResult(axios.get<T>(url, config))
            : this.wrap(axios.get<IApiResult<T>>(url, config));

        return promise;
    }

    protected getList<T>(url: string, apiOptions?: ApiOptions): Promise<IApiListResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiListResult(axios.get<T>(url, config))
            : this.wrap(axios.get<IApiListResult<T>>(url, config));

        return promise;
    }

    protected patch<T>(
        url: string,
        payload: any,
        apiOptions?: ApiOptions
    ): Promise<IApiResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiResult(axios.patch<T>(url, payload, config))
            : this.wrap(axios.patch<IApiResult<T>>(url, payload, config));

        return promise;
    }

    protected patchList<T>(
        url: string,
        payload: any,
        apiOptions?: ApiOptions
    ): Promise<IApiListResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiListResult(axios.patch<T>(url, payload, config))
            : this.wrap(axios.patch<IApiListResult<T>>(url, payload, config));

        return promise;
    }

    protected post<T>(
        url: string,
        payload: any,
        apiOptions?: ApiOptions
    ): Promise<IApiResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiResult(axios.post<T>(url, payload, config))
            : this.wrap(axios.post<IApiResult<T>>(url, payload, config));

        return promise;
    }

    protected postToTiltu<T>(
        url: string,
        payload: any,
        apiOptions?: ApiOptions
    ): Promise<ITiltuApiResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        return this.wrap(axios.post<ITiltuApiResult<T>>(url, payload, config));
    }

    protected postList<T>(
        url: string,
        payload: any,
        apiOptions?: ApiOptions
    ): Promise<IApiListResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiListResult(axios.post<T>(url, payload, config))
            : this.wrap(axios.post<IApiListResult<T>>(url, payload, config));

        return promise;
    }

    protected put<T>(
        url: string,
        payload: any,
        apiOptions?: ApiOptions
    ): Promise<IApiResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiResult(axios.put<T>(url, payload, config))
            : this.wrap(axios.put<IApiResult<T>>(url, payload, config));

        return promise;
    }

    protected putToTiltu<T>(
        url: string,
        payload: any,
        apiOptions?: ApiOptions
    ): Promise<ITiltuApiResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        return this.wrap(axios.put<ITiltuApiResult<T>>(url, payload, config));
    }

    protected deleteToTiltu<T>(
        url: string,
        apiOptions?: ApiOptions
    ): Promise<ITiltuApiResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        return this.wrap(axios.delete<ITiltuApiResult<T>>(url, config));
    }

    protected putList<T>(
        url: string,
        payload: any,
        apiOptions?: ApiOptions
    ): Promise<IApiListResult<T> | null> {
        const config = this.buildConfigs(apiOptions);
        const promise = apiOptions?.incompatibleResult
            ? this.wrapIncompatibleApiListResult(axios.put<T>(url, payload, config))
            : this.wrap(axios.put<IApiListResult<T>>(url, payload, config));

        return promise;
    }

    protected mapListToSEntity<T>(result: IApiListResult<T>, index: number): IApiResult<T> {
        const { Items, PageInfo, ...base } = result;
        return {
            ...base,
            Entity: Items[index],
        };
    }

    protected mapEntityToList<T>(result: IApiResult<T>): IApiListResult<T> {
        const { Entity, ...base } = result;
        return {
            ...base,
            Items: [Entity],
            PageInfo: {
                HasNextPage: false,
                HasPreviousPage: false,
                PageNumber: 1,
                PageSize: 1,
                TotalPages: 1,
            },
        };
    }

    private buildConfigs(apiOptions?: ApiOptions): AxiosRequestConfigExtended {
        const config: AxiosRequestConfigExtended = {...apiOptions};
        if (apiOptions?.credentials) config.withCredentials = apiOptions.credentials === 'include';

        if (apiOptions?.headers) {
            config.headers = {};

            for (const name in apiOptions.headers) {
                config.headers[name] = apiOptions.headers[name];
            }
        }
        if (apiOptions?.throwValidationErrors) {
            config.throwValidationErrors = apiOptions.throwValidationErrors;
        }

        if (apiOptions?.responseType) config.responseType = apiOptions.responseType;

        return config;
    }

    private wrap<T>(axiosPromise: Promise<AxiosResponse<T>>): Promise<T | null> {
        return new Promise<T | null>((resolve, reject) => {
            axiosPromise.then(
                (response) => {
                    (response.data as any).Headers = response.headers;
                    resolve(response.data);
                },
                (err) => {
                    if (err.config?.throwValidationErrors === true && err.response.status == 400) {
                        this.ProcessValidationError(err, reject);
                    }
                    else if (err?.code === 'ECONNABORTED' || err?.response.status === 499) {
                        reject('timeout');
                    }
                    else {
                        resolve(null);
                        reject();
                    }
                }
            );
        });
    }

    private ProcessValidationError(err: any, reject: (reason?: any) => void) {
        if (err.response.data?.errors) {
            const t = Object.entries(err.response.data.errors).map(([propertyName, errors]) => {

                const propertynameErrors = (errors as string[]).map(error => {
                    const errorMessage: IApiResultMessage = {
                        Code: -1,
                        Explanation: error,
                        PropertyName: propertyName
                    };
                    return errorMessage;
                });
                return propertynameErrors;
            }).flatMap(_ => _);

            reject(t);
        } else {
            reject(err.response.data?.Results.Errors);
        }
    }

    private wrapIncompatibleApiResult<T>(
        axiosPromise: Promise<AxiosResponse<T>>
    ): Promise<IApiResult<T> | null> {
        return new Promise<IApiResult<T> | null>((resolve, reject) => {
            axiosPromise.then(
                (response) => {
                    const result: IApiResult<T> = {
                        Entity: response.data,
                        Headers: response.headers,
                        Results: {
                            Errors: [],
                            HttpStatus: response.status,
                            Title: '',
                            Type: '',
                            Warnings: [],
                        },
                        Type: 0,
                    };
                    resolve(result);
                },
                (err) => {
                    if (err.config?.throwValidationErrors === true && err.response.status == 400) {
                        this.ProcessValidationError(err, reject);
                    }
                    else {
                        resolve(null);
                        reject();
                    }
                }
            );
        });
    }

    private wrapIncompatibleApiListResult<T>(
        axiosPromise: Promise<AxiosResponse<T>>
    ): Promise<IApiListResult<T> | null> {
        return new Promise<IApiListResult<T> | null>((resolve, reject) => {
            axiosPromise.then(
                (response) => {
                    const data = response.data as any;

                    if (!Array.isArray(data)) {
                        resolve(null);
                        reject();
                        return;
                    }

                    const items: T[] = data;
                    const result: IApiListResult<T> = {
                        Headers: response.headers,
                        Items: items,
                        PageInfo: {
                            HasNextPage: false,
                            HasPreviousPage: false,
                            PageNumber: 1,
                            PageSize: items.length,
                            TotalPages: 1,
                        },
                        Results: {
                            Errors: [],
                            HttpStatus: response.status,
                            Title: '',
                            Type: '',
                            Warnings: [],
                        },
                        Type: 0,
                    };
                    resolve(result);
                },
                (err) => {
                    if (err.config?.throwValidationErrors === true && err.response.status == 400) {
                        this.ProcessValidationError(err, reject);
                    }
                    else {
                        resolve(null);
                        reject();
                    }
                }
            );
        });
    }
}
