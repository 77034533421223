// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import CloseIcon from '@mui/icons-material/Close';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADefaultIconButton, AIconButtonBaseProps } from '../../common/buttons';

export interface ACloseIconButtonProps extends AIconButtonBaseProps {}

export default function ACloseIconButton(props: ACloseIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...actionButtonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1067');

    return <ADefaultIconButton {...actionButtonProps} icon={<CloseIcon />} tooltip={tooltip2} />;
}
