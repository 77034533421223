// Libraries
import moment from 'moment';

// MUI
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

// Core
import utils from '../../../core/utils';
import dateUtils from '../../../core/utils.date';

// Interfaces
import { IAnimalSummary, IAnimalSummaryExtraInfo } from '../../../interfaces/IAnimal';
import {
    IAnimalAnnouncement,
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
    IBovineInfo,
    ICollectWeek,
    IEditBovineAnnouncementBovineInfo,
    IUpdateBovineAnnouncement,
    IUpdateBovineAnnouncementBovineInfo,
} from '../../../interfaces/IAnnouncement';
import {
    AnimalTypeEnum,
    AnnouncementTypeEnum,
    FarmingTypeEnum,
    SalesType,
} from '../../../interfaces/enums';
import { IFarmDetails, IProductionLine } from '../../../interfaces/IBusinessEntities';
import { ITiltuApiResult } from '../../../interfaces/IGeneral';

// Other helpers
import { removeDashes } from './euIdentifier';
import { defaultCollectWeek, getQuickCowCollectWeeks } from './collectWeek';
import { getBirthDate } from './animal';
import { EmptyGUID } from '../../../interfaces/types';

export const mapAnimalSummaryToAnnouncementDialog = (
    data: IAnimalSummary[],
    selectionModel: GridRowSelectionModel
): IAnimalSummary[] => {
    if (!data) return [];

    return data.filter((animal) => {
        if (selectionModel.includes(animal.Id)) return animal;
    });
};

export const mapIAnimalAnnouncementBatchToAnnouncementDialog = (
    batches: IAnimalAnnouncementBatch[]
): IAnimalSummary[] => {
    let animalSummaries: IAnimalSummary[] = [];
    for (const batch of batches) {
        for (const animal of batch.Animals) {
            let animalSummary: IAnimalSummary = {
                AlternativeAnimalDetails: [],
                AnimalBreedType: animal.Breed,
                AnimalSexType: animal.Sex,
                BirthDate: moment(animal.BirthDate).toDate(),
                BirthFarmId: '',
                Breed: animal.Breed,
                CrossBreed: '',
                DateOfPurchase: '',
                DateOfSale: new Date(),
                Id: animal.AnimalId,
                IsAlaCarteParent: '',
                EarNumber: '',
                EuIdentifier: animal.EuIdentifier,
                Name: '',
                SalesType: animal.SalesType,
                Sex: getSexFromSalesType(animal.Sex, animal.SalesType),
                UsageType: '',
                VirtualWaitingRoomState: 0,
                FarmId: '',
                HoldingSiteId: '',
                MatureForSlaughterDate: null,
                TransmissionAgeDate: null,
                Flags: 0,
            };
            animalSummaries.push(animalSummary);
        }
    }
    return animalSummaries;
};

export const mapAnimalAnnouncementAnimalsToIAnimalSummaryExtraInfo = (
    data: IAnimalAnnouncement[],
    allAnimalsData: IAnimalSummaryExtraInfo[] = []
): IAnimalSummaryExtraInfo[] => {
    let animals: IAnimalSummaryExtraInfo[] = [];
    for (const item of data) {
        for (const batch of item.Batches) {
            for (const animal of batch.Animals) {
                const foundInAllAnimals = allAnimalsData.find((a) => a.Id === animal.AnimalId);
                const mappedAnimalSummary: IAnimalSummaryExtraInfo = {
                    AlternativeAnimalDetails: [],
                    AgeMonths: utils.calculateAgeInMonths(
                        moment(),
                        moment(animal.BirthDate),
                        30.33
                    ),
                    Eu4:
                        animal.EuIdentifier?.length > 0
                            ? animal.EuIdentifier.substring(
                                  animal.EuIdentifier.length - 6,
                                  animal.EuIdentifier.length - 2
                              )
                            : null,
                    AnimalBreedType: animal.Breed,
                    AnimalSexType: animal.Sex,
                    BirthDate: dateUtils.utcStringToLocalDate(animal.BirthDate) as Date,
                    BirthFarmId: foundInAllAnimals?.BirthFarmId ?? EmptyGUID,
                    Breed: animal.Breed,
                    CrossBreed: foundInAllAnimals?.CrossBreed ?? '',
                    DateOfPurchase: foundInAllAnimals?.DateOfPurchase ?? '',
                    DateOfSale: foundInAllAnimals?.DateOfSale ?? new Date(0),
                    Id: animal.AnimalId,
                    IsAlaCarteParent: foundInAllAnimals?.IsAlaCarteParent ?? '',
                    EarNumber: foundInAllAnimals?.EarNumber ?? '',
                    EuIdentifier: animal.EuIdentifier,
                    Name: foundInAllAnimals?.Name ?? '',
                    SalesType: animal.SalesType,
                    Sex: getSexFromSalesType(animal.Sex, animal.SalesType),
                    VirtualWaitingRoomState: 0,
                    FarmId: foundInAllAnimals?.FarmId ?? EmptyGUID,
                    HoldingSiteId: item.HoldingSiteId,
                    UsageType: foundInAllAnimals?.UsageType ?? '',
                    MatureForSlaughterDate: foundInAllAnimals?.MatureForSlaughterDate ?? null,
                    TransmissionAgeDate: foundInAllAnimals?.TransmissionAgeDate ?? null,
                    Flags: foundInAllAnimals?.Flags ?? 0,
                };
                animals.push(mappedAnimalSummary);
            }
        }
    }
    return animals;
};

export const mapAnimalsToAnimalAnnouncement = (
    animalData: IAnimalSummary[] | IAnimalAnnouncementAnimal[]
): IAnimalAnnouncement => {
    let announcement: IAnimalAnnouncement = {
        Id: '',
        Number: 0,
        Type: 0,
        Created: '',
        HoldingSiteId: '',
        Info: '',
        Status: '',
        Batches: [],
    };
    let batchId = -1;
    for (const animal of animalData) {
        const collectWeek = defaultCollectWeek(animal);
        const batch: IAnimalAnnouncementBatch = {
            Id: '',
            BatchNumber: batchId--,
            BatchStatus: '',
            CollectWeekStart: collectWeek.Start,
            CollectWeekEnd: collectWeek.End,
            CollectWeekDay: 0,
            SalesType: 0,
            Amount: 0,
            BatchInfo: '',
            Animals: [],
        };

        if ('Id' in animal) batch.Animals.push(mapAnimalSummaryToIAnimalAnnouncementAnimal(animal));
        else batch.Animals.push(animal);

        announcement.Batches.push(batch);
    }
    return announcement;
};

const mapAnimalSummaryToIAnimalAnnouncementAnimal = (
    animal: IAnimalSummary
): IAnimalAnnouncementAnimal => {
    return {
        AnimalId: animal.Id,
        EuIdentifier: animal.EuIdentifier,
        Sex: getSexFromSalesType(animal.Sex, animal.SalesType),
        Breed: animal.Breed,
        BirthDate: animal.BirthDate?.toString(),
        SalesType: animal.SalesType,
    };
};

export const mapAnimalSummaryAnimalsToIAnimalAnnouncementAnimal = (animals: IAnimalSummary[]) => {
    let data: IAnimalAnnouncementAnimal[] = [];
    for (const animal of animals) {
        let animalAnnouncementAnimal: IAnimalAnnouncementAnimal = {
            AnimalId: animal.Id,
            EuIdentifier: animal.EuIdentifier,
            Sex: getSexFromSalesType(animal.Sex, animal.SalesType),
            Breed: animal.Breed,
            BirthDate: animal.BirthDate?.toString(),
            SalesType: animal.SalesType,
        };
        data.push(animalAnnouncementAnimal);
    }
    return data;
};

export const mapSelectedAnimalsToExistingAnimalAnnouncement = (
    animalData: IAnimalSummary[],
    announcementData: IAnimalAnnouncement,
    collectWeek?: ICollectWeek
): IAnimalAnnouncement => {
    let announcement: IAnimalAnnouncement = {
        Id: announcementData.Id,
        Number: announcementData.Number,
        Type: announcementData.Type,
        Created: announcementData.Created,
        HoldingSiteId: announcementData.HoldingSiteId,
        Info: announcementData.Info,
        Status: announcementData.Status,
        Batches: mapIAnimalAnnouncementBatchesToExistingAnimalAnnouncement(
            announcementData.Batches
        ),
    };
    for (const animal of animalData) {
        if (announcement.Batches.find((b) => b.Animals.find((a) => a.AnimalId === animal.Id)))
            continue;

        const animalCollectWeek =
            animal.SalesType === SalesType.Pikalehmä
                ? defaultCollectWeek(animal)
                : {
                      Start: collectWeek,
                      End: collectWeek,
                  };

        if (animalCollectWeek.Start?.Week === 0) animalCollectWeek.Start = undefined;
        if (animalCollectWeek.End?.Week === 0) animalCollectWeek.End = undefined;

        let newBatch: IAnimalAnnouncementBatch = {
            Id: '',
            BatchNumber: Date.now() + Math.random(),
            BatchStatus: '',
            CollectWeekStart: animalCollectWeek?.Start || { Year: -1, Week: -1 },
            CollectWeekEnd: animalCollectWeek?.End || { Year: -1, Week: -1 },
            CollectWeekDay: 0,
            SalesType: animal.SalesType,
            Amount: 0,
            BatchInfo: '',
            Animals: [],
        };
        newBatch.Animals.push(mapAnimalSummaryToIAnimalAnnouncementAnimal(animal));
        announcement.Batches.push(newBatch);
    }
    return announcement;
};

const mapIAnimalAnnouncementBatchesToExistingAnimalAnnouncement = (
    batches: IAnimalAnnouncementBatch[]
) => {
    let mappedBatches: IAnimalAnnouncementBatch[] = [];
    for (const batch of batches) {
        let mappedBatch: IAnimalAnnouncementBatch = {
            Id: batch.Id,
            BatchNumber: batch.BatchNumber,
            BatchStatus: batch.BatchStatus,
            CollectWeekStart: {
                Year: batch.CollectWeekStart.Year,
                Week: batch.CollectWeekStart.Week,
            },
            CollectWeekEnd: { Year: batch.CollectWeekEnd.Year, Week: batch.CollectWeekEnd.Week },
            CollectWeekDay: batch.CollectWeekDay,
            SalesType: batch.SalesType,
            Amount: batch.Amount,
            Animals: batch.Animals,
            BatchInfo: batch.BatchInfo,
        };
        mappedBatches.push(mappedBatch);
    }
    return mappedBatches;
};

export const mapBovineSlaughterAnnouncementBatchesToIAnimalAnnouncementAnimal = (
    batches: IAnimalAnnouncementBatch[]
): IAnimalAnnouncementAnimal[] => {
    let mappedAnimals: IAnimalAnnouncementAnimal[] = [];
    for (const batch of batches) {
        for (const animal of batch.Animals) {
            let mappedAnimal: IAnimalAnnouncementAnimal = {
                AnimalId: animal.AnimalId,
                EuIdentifier: animal.EuIdentifier,
                Sex: getSexFromSalesType(animal.Sex, animal.SalesType),
                Breed: animal.Breed,
                BirthDate: animal.BirthDate,
                SalesType: animal.SalesType,
            };
            mappedAnimals.push(mappedAnimal);
        }
    }
    return mappedAnimals;
};

export const omitBovinePurchaseAnnouncements = (
    animalAnnouncements: IAnimalAnnouncement[]
): IAnimalAnnouncement[] => {
    let copiedArray = animalAnnouncements;
    return copiedArray.reduce((prev: IAnimalAnnouncement[], current) => {
        if (current.Type !== AnnouncementTypeEnum.BovinePurchase) {
            prev.push(current);
        }
        return prev;
    }, []);
};

export const calculateAmountOfAnimals = (batches: IAnimalAnnouncementBatch[]) => {
    let count = 0;
    for (const batch of batches) {
        count += batch.Animals.length;
    }
    return count;
};

export const calculateOrderedAnimalCount = (batches: IAnimalAnnouncementBatch[]) => {
    let orderedAnimalCount = 0;
    for (const batch of batches) {
        orderedAnimalCount += batch.Amount;
    }
    return orderedAnimalCount;
};

export const getAnnouncementAnimals = (
    announcement: IAnimalAnnouncement | undefined | null
): IAnimalAnnouncementAnimal[] => {
    return (
        announcement?.Batches.flatMap((x) => x.Animals)
            .filter((x) => 'EuIdentifier' in x)
            .map((x) => x as any as IAnimalAnnouncementAnimal) || []
    );
};

export const mapBovineInfos = (
    animals: IAnimalAnnouncementAnimal[],
    holdingSiteId: string,
    collectWeekStart: ICollectWeek,
    collectWeekEnd: ICollectWeek
): IBovineInfo[] => {
    if (animals?.length > 0) {
        const quickCowCollectWeeks = getQuickCowCollectWeeks();

        return animals.map((animal) => {
            let collectWeekStartToUse = collectWeekStart;
            let collectWeekEndToUse = collectWeekEnd;

            if (animal.SalesType === 28) {
                collectWeekStartToUse = quickCowCollectWeeks.Start;
                collectWeekEndToUse = quickCowCollectWeeks.End;
            }

            return {
                AnimalId: animal.AnimalId,
                EuIdentifier: removeDashes(animal.EuIdentifier),
                HoldingSiteId: holdingSiteId,
                SalesType: animal.SalesType,
                Breed: animal.Breed,
                Sex: getSexFromSalesType(animal.Sex, animal.SalesType),
                BirthDate: getBirthDate(animal.BirthDate),
                CollectWeekStart: {
                    Year: collectWeekStartToUse.Year,
                    Week: collectWeekStartToUse.Week,
                },
                CollectWeekEnd: {
                    Year: collectWeekEndToUse.Year,
                    Week: collectWeekEndToUse.Week,
                },
                CollectWeekDay: 1, // Not in the UI
                AddInfo: '',
            } as IBovineInfo;
        });
    }

    return [];
};

export const mapAnimalAnnouncementUpdate = (
    announcement: IAnimalAnnouncement,
    batches: IAnimalAnnouncementBatch[],
    producerNumber: string | number,
    farmId: string,
    holdingSiteId: string
): IUpdateBovineAnnouncement => {
    const manual =
        announcement.Type === AnnouncementTypeEnum.ManualBovineIntermediation ||
        announcement.Type === AnnouncementTypeEnum.ManualBovineSlaughter;

    return {
        AnnouncementNumber: Number(announcement?.Number),
        ProducerNumber: Number(producerNumber),
        AnnouncementInfo: announcement.Info ?? ' ',
        Additions: constructAnnouncementAdditions(
            batches,
            { ...announcement },
            holdingSiteId,
            manual
        ),
        Updates: constructAnnouncementUpdates(batches, { ...announcement }, holdingSiteId),
        FarmId: farmId,
    };
};

export const constructAnnouncementAdditions = (
    batches: IAnimalAnnouncementBatch[],
    announcement: IAnimalAnnouncement,
    holdingSiteId: string,
    manual: boolean
): IEditBovineAnnouncementBovineInfo[] => {
    const additions: IEditBovineAnnouncementBovineInfo[] = [];
    for (const originalBatch of announcement.Batches.filter(
        (batch) => !batches.map((oldBatch) => oldBatch.BatchNumber).includes(batch.BatchNumber)
    )) {
        const addition = constructBovineAnnouncementBovineInfoUpdate(originalBatch, holdingSiteId);
        if (manual) {
            addition.AnimalId = '00000000-0000-0000-0000-000000000000'; // overwrite UI-generated animalid
        }
        additions.push(addition);
    }
    return additions;
};

export const constructAnnouncementUpdates = (
    batches: IAnimalAnnouncementBatch[],
    announcement: IAnimalAnnouncement,
    holdingSiteId: string
): IUpdateBovineAnnouncementBovineInfo[] => {
    const updates: IUpdateBovineAnnouncementBovineInfo[] = [];
    for (const originalBatch of announcement.Batches.filter((batch) =>
        batches.map((oldBatch) => oldBatch.BatchNumber).includes(batch.BatchNumber)
    )) {
        if (originalBatch.Animals.length === 0) continue;
        const update = constructBovineAnnouncementBovineInfoUpdate(
            originalBatch,
            holdingSiteId
        ) as IUpdateBovineAnnouncementBovineInfo;
        update.AnnouncementBatchNumber = originalBatch.BatchNumber?.toString() ?? '1';
        update.CollectWeekDay = originalBatch.CollectWeekDay; // Not in the UI
        updates.push(update);
    }
    return updates;
};

const constructBovineAnnouncementBovineInfoUpdate = (
    originalBatch: IAnimalAnnouncementBatch,
    holdingSiteId: string
): IEditBovineAnnouncementBovineInfo => {
    const batch = {
        AnimalId: originalBatch.Animals[0].AnimalId,
        EUIdentifier: removeDashes(originalBatch.Animals[0].EuIdentifier),
        HoldingSiteId: holdingSiteId,
        SalesType: originalBatch.Animals[0].SalesType,
        Breed: originalBatch.Animals[0].Breed,
        Sex: getSexFromSalesType(originalBatch.Animals[0].Sex, originalBatch.Animals[0].SalesType),
        BirthDate: getBirthDate(originalBatch.Animals[0].BirthDate),
        CollectWeekStart: originalBatch.CollectWeekStart,
        CollectWeekEnd: originalBatch.CollectWeekEnd,
        CollectWeekDay: 1, // Not in the UI
        AddInfo: originalBatch.BatchInfo?.length > 0 ? originalBatch.BatchInfo : '',
    };
    return batch;
};

export function getSexFromSalesType(sex: number, salesType: SalesType): number {
    if (sex == 9) {
        //unknown
        switch (salesType) {
            case SalesType.Jalostuskarju:
            case SalesType.Panssarikarju:
            case SalesType.Salakarju:
            case SalesType.Karju:
            case SalesType.Sonni:
            case SalesType.RistTaiRotusonni:
            case SalesType.LimSonniAlaCarte:
            case SalesType.MUsiitoskarju:
            case SalesType.PihSovas75prossa:
            case SalesType.LimSoVasAlaCarte:
            case SalesType.Sonnivasikka:
            case SalesType.Ternisonnivas:
            case SalesType.VälikasvSoVas:
            case SalesType.MASiitoskarju:
                return 0;
            case SalesType.Emakko:
            case SalesType.NyljettyEmakko:
            case SalesType.Hieho:
            case SalesType.Lehmä:
            case SalesType.Nuorilehmä:
            case SalesType.Pikalehmä:
            case SalesType.LimHiehoAlaCarte:
            case SalesType.PihLevas75prossa:
            case SalesType.LimLeVasAlaCarte:
            case SalesType.Lehmävasikka:
            case SalesType.Ternilehmävas:
            case SalesType.VälikasvLeVas:
            case SalesType.KantavaSiitoshieho:
            case SalesType.MASiitosensikko:
            case SalesType.Siitoshieho:
            case SalesType.Siitoslehmä:
            case SalesType.MATiineSiitosensikko:
                return 1;
            default:
                return 9;
        }
    }
    return sex;
}

export const isBovineInfosLengthValid = (length: number) => {
    if (length > 0) return true;
    return false;
};

export const isFarmDetailsValidForSalesTypeUpdate = (
    farmDetails: IFarmDetails | undefined | null
) => {
    return farmDetails && farmDetails.Id !== null && farmDetails.Id !== undefined
        ? farmDetails.Id
        : '';
};

export const getFarmingTypesFromFarmDetails = (
    productionLines: IProductionLine[]
): FarmingTypeEnum[] => {
    let farmingTypesInCurrentFarm: FarmingTypeEnum[] = [];
    for (const productionLine of productionLines) {
        if (productionLine.Type === AnimalTypeEnum.Bovine) {
            for (const farmingType of productionLine.FarmingTypes) {
                farmingTypesInCurrentFarm.push(farmingType);
            }
        }
    }
    return farmingTypesInCurrentFarm;
};

export const hasAnnouncementResponseErrors = (response: ITiltuApiResult<any>): boolean => {
    if (response.Summary.Errors.length > 0) return true;
    return false;
};

export const handleAnimalAnnouncementDeletionRestrictions = (
    data: IAnimalAnnouncement,
    snellmanUser: boolean
): boolean => {
    if (data.Status === '1') return true;
    if (areAllBatchesStatusesLo(data)) return true;

    if (data.Number === 0 && snellmanUser) return false;

    return isAnimalAnnouncementUpdateRestricted(data, true);
};

export const isAnimalAnnouncementUpdateRestricted = (
    data: IAnimalAnnouncement,
    isDeletionRestriction?: boolean
): boolean => {
    if (data?.Number === 0) return true;
    if (areAllBatchesStatusesLo(data)) return true;
    isDeletionRestriction = isDeletionRestriction !== undefined ? isDeletionRestriction : false;
    if (canUpdateSlaughterAnnouncement(data.Type, data.Batches, isDeletionRestriction)) {
        return false;
    } else if (
        canUpdateIntermediationAnnouncement(data.Type, data.Batches, isDeletionRestriction)
    ) {
        return false;
    }

    return true;
};

const canUpdateSlaughterAnnouncement = (
    type: number,
    batches: IAnimalAnnouncementBatch[],
    isDeletionRestriction: boolean
): boolean => {
    if (
        type === AnnouncementTypeEnum.Slaughter ||
        type === AnnouncementTypeEnum.ManualBovineSlaughter
    )
        if (areUpdateRulesValid(batches, isDeletionRestriction, 1)) return true;
    return false;
};

const canUpdateIntermediationAnnouncement = (
    type: number,
    batches: IAnimalAnnouncementBatch[],
    isDeletionRestriction: boolean
): boolean => {
    switch (type) {
        case AnnouncementTypeEnum.Intermediation:
        case AnnouncementTypeEnum.ManualBovineIntermediation:
            if (areUpdateRulesValid(batches, isDeletionRestriction, 4)) return true;
            break;
        case AnnouncementTypeEnum.BovinePurchase:
            return true;
        default:
            return false;
    }
    return false;
};

// valid true, invalid false
export const areUpdateRulesValid = (
    batches: IAnimalAnnouncementBatch[],
    isDeletionRestriction: boolean,
    restrictionDay: number
): boolean => {
    // Check uniqueness by CollectWeekStart.Week property
    const uniqueBatchesByCollectWeekStart = Array.from(
        new Map(batches.map((_) => [_.CollectWeekStart['Week'], _])).values()
    );
    const collectWeekStartValues = uniqueBatchesByCollectWeekStart.map((_) => _.CollectWeekStart);

    if (collectWeekStartValues.length === 1) {
        if (isRestrictionDayAhead(restrictionDay))
            return restrictionDayAheadValidator(collectWeekStartValues[0]);
        else return restrictionDayPastValidator(collectWeekStartValues[0]);
    } else if (collectWeekStartValues.length > 1) {
        if (isRestrictionDayAhead(restrictionDay)) {
            if (
                isDeletionRestriction &&
                collectWeekStartValues.find((_) =>
                    isCurrentYearCollectWeekStartInValid(_.Year, _.Week)
                )
            )
                return false;
            else if (
                collectWeekStartValues.some((_) =>
                    isCurrentYearCollectWeekStartPrecedingWeekValid(_.Year, _.Week)
                )
            )
                return true;
            else if (
                collectWeekStartValues.some((_) =>
                    isCurrentYearCollectWeekStartValid(_.Year, _.Week)
                )
            )
                return true;
            else if (isSomeCollectWeekNextYear(collectWeekStartValues)) return true;
        } else {
            if (
                isDeletionRestriction &&
                collectWeekStartValues.find(
                    (_) => _.Year === moment().year() && _.Week - 1 <= moment().isoWeek()
                )
            )
                return false;
            else if (
                collectWeekStartValues.some((_) =>
                    isCurrentYearCollectWeekStartValid(_.Year, _.Week)
                )
            )
                return true;
            else if (isSomeCollectWeekNextYear(collectWeekStartValues)) return true;
        }
    }
    return false;
};

const restrictionDayAheadValidator = (start: ICollectWeek) => {
    if (isCurrentYearCollectWeekStartValid(start.Year, start.Week)) return true;
    else if (isCurrentYearCollectWeekStartPrecedingWeekValid(start.Year, start.Week)) return true;
    else if (isCurrentYearCollectWeekStartInValid(start.Year, start.Week)) return false;
    else if (isCollectWeekNextYear(start.Year)) return true;
    return false;
};

const restrictionDayPastValidator = (start: ICollectWeek) => {
    if (isCurrentYearCollectWeekStartValid(start.Year, start.Week)) return true;
    else if (start.Year === moment().year() && start.Week - 1 === moment().isoWeek()) return false;
    else if (isCollectWeekNextYear(start.Year)) return true;
    return false;
};

const isRestrictionDayAhead = (restrictionDay: number): boolean => {
    if (moment().isoWeekday() <= restrictionDay) return true;
    return false;
};

const isCurrentYearCollectWeekStartValid = (year: number, week: number): boolean => {
    return year === moment().year() && week - 1 > moment().isoWeek() ? true : false;
};

const isCurrentYearCollectWeekStartPrecedingWeekValid = (year: number, week: number): boolean => {
    return year === moment().year() && week - 1 === moment().isoWeek();
};

const isCurrentYearCollectWeekStartInValid = (year: number, week: number): boolean => {
    return year === moment().year() && week - 1 < moment().isoWeek() ? true : false;
};

const isCollectWeekNextYear = (year: number) => {
    if (year === moment().year() + 1) return true;
    return false;
};

const isSomeCollectWeekNextYear = (collectWeekStartValues: ICollectWeek[]) => {
    if (collectWeekStartValues.some((_) => _.Year === moment().year() + 1)) return true;
    return false;
};

export const getUniqueCollectWeeks = (batches: IAnimalAnnouncementBatch[]) => {
    const batchCollectWeeks: string[] = [];

    batches.sort((a, b) => {
        if (a.CollectWeekStart.Year < b.CollectWeekStart.Year) {
            if (a.CollectWeekStart.Week < b.CollectWeekStart.Week) return -1;
            else if (a.CollectWeekStart.Week > b.CollectWeekStart.Week) return -1;
        } else if (a.CollectWeekStart.Year === b.CollectWeekStart.Year) {
            if (a.CollectWeekStart.Week < b.CollectWeekStart.Week) return -1;
            if (a.CollectWeekStart.Week > b.CollectWeekStart.Week) return 1;
        }
        return 0;
    });

    batches.forEach((batch) => {
        const start = Object.entries(batch.CollectWeekStart).map((batch) => batch[1]);
        const end = Object.entries(batch.CollectWeekEnd).map((batch) => batch[1]);
        if (start[1] === end[1]) batchCollectWeeks.push(start[1]);
        else batchCollectWeeks.push(`${start[1]} - ${end[1]}`);
    });
    return batchCollectWeeks.filter(
        (currentValue, idx, self) => self.indexOf(currentValue) === idx
    );
};

export const collectWeekComparator = (collectWeek1: string, collectWeek2: string) => {
    return Number(collectWeek1.substring(0, 2)) - Number(collectWeek2.substring(0, 2));
};

export const birthDateComparator = (a: any, b: any) => {
    if ((a as any[])[0] === null) return -1;
    if ((b as any[])[0] === null) return 1;

    let dateA = new Date(a as Date);
    let dateB = new Date(b as Date);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    return 0;
};

export const euIdentifierComparator = (a: any, b: any) => {
    if (a !== null && a !== undefined && b !== null && b !== undefined) {
        if (a < b) return -1;
        if (a > b) return 1;
    }
    return 0;
};

export const isAnimalAnnouncementBatchRowSelectable = (
    type: AnnouncementTypeEnum,
    collectWeekStart: ICollectWeek,
    batchStatus?: string
): boolean => {
    // If the animal is just added

    if (batchStatus === 'LO') return false;
    if (collectWeekStart?.Week === -1 && collectWeekStart.Year === -1) return true;

    if (
        type === AnnouncementTypeEnum.Slaughter ||
        type === AnnouncementTypeEnum.ManualBovineSlaughter
    ) {
        if (isRestrictionDayAhead(1)) return restrictionDayAheadValidator(collectWeekStart);
        return restrictionDayPastValidator(collectWeekStart);
    } else if (
        type === AnnouncementTypeEnum.Intermediation ||
        type === AnnouncementTypeEnum.ManualBovineIntermediation ||
        type === AnnouncementTypeEnum.BovinePurchase
    ) {
        if (isRestrictionDayAhead(4)) return restrictionDayAheadValidator(collectWeekStart);
        return restrictionDayPastValidator(collectWeekStart);
    }
    return false;
};

const areAllBatchesStatusesLo = (data: IAnimalAnnouncement) => {
    if (data.Batches.find((i) => i.BatchStatus === 'LO') && data.Status === 'LO') {
        return true;
    }
    return data.Status === 'LO';
};
