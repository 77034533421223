// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI

// core
import auth, { IAuthorizationResource } from '../core/authorization';
import { ResourceTextApplication } from '../core/resources';

// Common
import {
    ADataGrid,
    ADataGridHeaderContainer,
    ADataGridButtonGroup,
    AGridColumns,
    AGridColumn,
} from '../common/dataGrid';
import { ACheckboxIconButton, AEditIconButton } from '../common/buttons';

// Feature
import {
    IAccessRole,
    IAccessRoleMemberList,
    IAccessRolePermissions,
    IAccessResourceMap,
} from './IAccessRightsManagement';
import accessManagementApi from '../api/accessManagementApi';
import RoleMembersDialog, { RoleMembersDialogProps } from './RoleMembersDialog';
import { GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid-pro';

export interface ResourcePermissionProps {
    onPermissionUpdate: (roleName: string, resource: IAuthorizationResource) => any;
    openEdit: (role: IAccessRole) => any;
    resourceMap: IAccessResourceMap[];
    resourcePermissions: IAccessRolePermissions[];
    roleMembers: IAccessRoleMemberList[];
    roles: IAccessRole[];
}

export default function ResourcePermissions(props: ResourcePermissionProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccessRights']);

    const [data, setData] = useState<GridRowsProp>([]);
    const [dialogData, setDialogData] = useState<RoleMembersDialogProps | null>();

    useEffect(() => {
        const renderResourceCellText = (map: any, row: any, key: string): void => {
            row[key] = map[key] ? t(`AnelmaAccessRights:${map[key]}`) : '***';
        };

        const createData = (
            resourceMap: IAccessResourceMap[],
            roles: IAccessRole[],
            resourcePermissions: IAccessRolePermissions[]
        ): GridRowsProp => {
            const data: GridRowsProp = resourceMap.map((map, index) => {
                const row: any = {
                    ...map,
                    id: index,
                };

                renderResourceCellText(map, row, 'parentResourceSetNameTextCode');
                renderResourceCellText(map, row, 'resourceSetNameTextCode');
                renderResourceCellText(map, row, 'resourceNameTextCode');
                renderResourceCellText(map, row, 'resourceScopeTextCode');

                roles.forEach((role) => {
                    const permissions = resourcePermissions.find(
                        (i) => i.role === role.name
                    )?.permissions;
                    const granted = !!permissions?.find(
                        (p) =>
                            p.resource === row.resourceName && p.scopes.includes(row.resourceScope)
                    );
                    row[`role-${role.id}`] = granted;
                });

                return row;
            });

            return [...data];
        };

        setData(createData(props.resourceMap, props.roles, props.resourcePermissions));
    }, [props.resourceMap, props.roles, props.resourcePermissions, t]);

    function updatePermissionState(grant: boolean, roleName: string, map: IAccessResourceMap) {
        const resource: IAuthorizationResource = {
            resource: map.resourceName,
            scopes: [map.resourceScope],
        };
        const promise = grant
            ? accessManagementApi.grantPermission(roleName, resource)
            : accessManagementApi.revokePermission(roleName, resource);
        promise.then((response) => {
            if (!response) return;
            props.onPermissionUpdate(roleName, response.Entity);
        });
    }

    const renderResourceCell = (p: GridRenderCellParams, prop: string) => {
        const row = p.row as any;
        const text = p.value;
        const tooltip = `${text}\n(${row[prop]})`;
        return <span title={tooltip}>{text?.toString()}</span>;
    };

    const renderCheckboxTooltip = (row: any) => {
        return (
            <>
                <br />- {row.parentResourceSetNameTextCode} ({row.parentResourceSetName})
                <br />- {row.resourceSetNameTextCode} ({row.resourceSetName})
                <br />- {row.resourceNameTextCode} ({row.resourceName})
                <br />- {row.resourceScopeTextCode} ({row.resourceScope})
            </>
        );
    };

    const renderRoleColumnHeader = (role: IAccessRole) => {
        return role.textCode ? t(`AnelmaAccessRights:${role.textCode}`) : `**${role.name}`;
    };

    const getUnigueOptions = (prop: string) => {
        const values = data.map((d) => d[prop]);
        const uniques = values.filter((v, i, s) => s.indexOf(v) === i);
        uniques.sort();
        return uniques;
    };

    function createColumns(): AGridColumns {
        const columns: AGridColumns = [
            {
                field: 'parentResourceSetNameTextCode',
                headerName: t('AnelmaAccessRights:1004'),
                renderCell: (p) => renderResourceCell(p, 'parentResourceSetName'),
                type: 'singleSelect',
                valueOptions: getUnigueOptions('parentResourceSetNameTextCode'),
                width: 100,
            },
            {
                field: 'resourceSetNameTextCode',
                headerName: t('AnelmaAccessRights:1005'),
                renderCell: (p) => renderResourceCell(p, 'resourceSetName'),
                type: 'singleSelect',
                valueOptions: getUnigueOptions('resourceSetNameTextCode'),
                width: 160,
            },
            {
                field: 'resourceNameTextCode',
                headerName: t('AnelmaAccessRights:1006'),
                renderCell: (p) => renderResourceCell(p, 'resourceName'),
                type: 'singleSelect',
                valueOptions: getUnigueOptions('resourceNameTextCode'),
                width: 180,
            },
            {
                field: 'resourceScopeTextCode',
                headerName: t('AnelmaAccessRights:1007'),
                renderCell: (p) => renderResourceCell(p, 'resourceScope'),
                type: 'singleSelect',
                valueOptions: getUnigueOptions('resourceScopeTextCode'),
                width: 100,
            },
        ];
        const roleColumns = props.roles.map((role) => {
            const roleMembers = props.roleMembers.find((i) => i.roleName === role.name);
            const members = [...(roleMembers?.userMembers || [])];
            roleMembers?.contextScopedMembers.forEach((c) => members.push(...c.userMembers));
            const def: AGridColumn = {
                field: `role-${role.id}`,
                headerName: renderRoleColumnHeader(role),
                type: 'boolean',
                renderCell: (p) => {
                    const row = p.row as any as IAccessResourceMap;
                    const granted = p.value as boolean;
                    const canChange = granted
                        ? auth.canRevokePermissions
                        : auth.canGrantPermissions;
                    const tooltip = renderCheckboxTooltip(row);
                    return (
                        <ADataGridButtonGroup>
                            <ACheckboxIconButton
                                checked={granted}
                                checkedTooltip={
                                    <>
                                        {t('AnelmaAccessRights:1125')}
                                        {tooltip}
                                    </>
                                }
                                disabled={!canChange}
                                onClick={(checked, e) => {
                                    e.stopPropagation();
                                    updatePermissionState(checked, role.name, row);
                                }}
                                uncheckedTooltip={
                                    <>
                                        {t('AnelmaAccessRights:1126')}
                                        {tooltip}
                                    </>
                                }
                            />
                        </ADataGridButtonGroup>
                    );
                },
                renderHeader: (p) => {
                    return (
                        <ADataGridHeaderContainer align='center'>
                            {auth.canUpdateAccessRightsGroup && (
                                <AEditIconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.openEdit(role);
                                    }}
                                />
                            )}
                            {p.colDef.headerName}
                            <br />
                            <span
                                title={t('AnelmaAccessRights:1116')}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDialogData({
                                        onClose: () => setDialogData(null),
                                        role,
                                        roleMembers,
                                    });
                                }}
                            >
                                ({members.length})
                            </span>
                        </ADataGridHeaderContainer>
                    );
                },
                width: 200,
            };
            return def;
        });
        roleColumns.sort((a, b) => {
            if (a.headerName === b.headerName) return 0;
            if (!a.headerName) return 1;
            if (!b.headerName) return -1;
            return a.headerName < b.headerName ? -1 : 1;
        });
        columns.push(...roleColumns);
        return columns;
    }

    return (
        <>
            <ADataGrid
                columns={createColumns()}
                enableFiltering
                enableRowNumbers
                stickyHeader
                rows={data}
            />

            {dialogData && <RoleMembersDialog {...dialogData} />}
        </>
    );
}
