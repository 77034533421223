// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import moment from 'moment';

// MUI
import { Grid } from '@mui/material';

// Core
import { IFormValidator } from '../core/FormValidator';
import { ResourceTextApplication } from '../core/resources';

// Common
import { AAddButton } from '../common/buttons';
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../common/dialog';
import {
    ADateInput,
    AInputContainer,
    ASwitch,
    ATextarea,
    ATextInput,
    ATimeInput,
} from '../common/inputs';

// Interfaces
import { IContactPersonRow, IContactPersonSummary } from '../interfaces/IBusinessEntities';

// Store
// API
import { sendDirectMessage, updateMessage } from './api/APIShortMessage';

// Feature - SMS
import SearchMessageGroupDialog from './search/SearchMessageGroupDialog';
import { IMessages } from './ISms';
import { mapMessagesDataToRequest } from './SendMessageUpdateRequest';
import { SMS } from './constants/SMS';

export interface SendMessageFormDialogProps {
    data: IMessages;
    onClose: () => void;
    mode: 'create' | 'edit';
    validator: IFormValidator;
}

export default function SendMessageFormDialog(props: SendMessageFormDialogProps) {
    const { data, validator } = props;

    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCompany',
        'AnelmaCommunication',
        'AnelmaGeneral',
        'AnelmaLayout',
        'AnelmaSMS',
    ]);
    const { enqueueSnackbar } = useSnackbar();
    const showMessage = (message: string, variant: 'success' | 'error') => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const [selectedNumbers, setSelectedNumbers] = useState<IContactPersonRow[]>([]);

    const [receiverInput, setReceiverInput] = useState<string[]>(data.Receiver);

    const [contentInput, setContentInput] = useState<string>(data.Content);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [companySearchDialog, setCompanySearchDialog] = useState<boolean>(false);
    const [scheduleMessage, setScheduleMessage] = useState<boolean>(false);
    const [requestDateTime, setRequestDateTime] = useState<moment.Moment | null>(() =>
        data.RequestedDeliveryDate ? moment(data.RequestedDeliveryDate + 'Z') : null
    );

    const [scheduleDate, setScheduleDate] = useState<moment.Moment | null>();

    const [scheduleTime, setScheduleTime] = useState<moment.Moment | null>();

    const onSend = () => {
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }
        setIsLoading(true);

        //send to api
        if (props.mode === 'edit') {
            const updates = mapMessagesDataToRequest(data, editPayload);
            updateMessage(updates.Entity.Guid, updates).then(
                (response) => {
                    if (response.Results.HttpStatus === 202) {
                        showMessage(t('AnelmaGeneral:1018'), 'success');
                    } else {
                        showMessage(t('AnelmaCommunication:1030'), 'error');
                    }
                    props.onClose();
                },
                (fail) => {
                    setIsLoading(false);
                    showMessage(t('AnelmaCommunication:1059'), 'error');
                    props.onClose();
                }
            );
        } else if (props.mode === 'create') {
            //if no reciever selected prevent to make API call
            if (selectedNumbers.length === 0 || !Array.isArray(selectedNumbers)) {
                setIsLoading(false);
                return showMessage(t('AnelmaSMS:1001'), 'error');
            }

            sendDirectMessage(payload).then(
                (response) => {
                    if (response.Results.HttpStatus === 202) {
                        showMessage(t('AnelmaGeneral:1018'), 'success');
                    } else {
                        showMessage(t('AnelmaCommunication:1030'), 'error');
                    }

                    props.onClose();
                },
                (fail) => {
                    setIsLoading(false);
                    showMessage(t('AnelmaCommunication:1059'), 'error');
                    props.onClose();
                }
            );
        }
    };

    const checkValidDate = (reqDateTime: moment.Moment | null) => {
        let d = scheduleDate?.format('YYYY-MM-DD');
        let t = scheduleTime?.format('HH:mm:ss');
        reqDateTime = moment(d + ' ' + t);
        if (reqDateTime?.isValid()) return reqDateTime.utc().format('YYYY-MM-DD HH:mm:00');
        return '';
    };

    //create payload
    const payload: IMessages = {
        ...data,
        Receivers: selectedNumbers.map((i) => ({
            EntityId: i.FarmId,
            ReceiverId: i.ContactPersonId ? i.ContactPersonId : '',
            ReceiverPhoneNumber: i.PhoneNumber,
        })),
        Content: contentInput,
        RequestedDeliveryDate: checkValidDate(requestDateTime),
    };

    const editPayload: IMessages = {
        ...data,
        Receiver: receiverInput,
        Content: contentInput,
        RequestedDeliveryDate: checkValidDate(requestDateTime),
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [
            {
                type: 'send',
                onClick: () => onSend(),
            },
        ],
    };

    return (
        <div>
            <ADialog open onClose={() => props.onClose()}>
                <ADialogTitle>{t('AnelmaCommunication:1062')}</ADialogTitle>
                <ADialogContent isLoading={isLoading} size='lg'>
                    <Grid container>
                        <Grid item sm={6}>
                            <ATextInput
                                disabled
                                id={'receiver'}
                                label={t('AnelmaCommunication:1063')}
                                validator={validator}
                                onChange={(e) => {}}
                                value={selectedNumbers.length.toString()}
                            ></ATextInput>
                            <Grid container>
                                <Grid item xs={12}>
                                    <AInputContainer>
                                        <>
                                            <AAddButton
                                                type='action'
                                                onClick={() => {
                                                    setCompanySearchDialog(!companySearchDialog);
                                                }}
                                            >
                                                {t('AnelmaCommunication:1067')}
                                            </AAddButton>
                                        </>
                                    </AInputContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            <ASwitch
                                id={'schedule'}
                                label={t('AnelmaCommunication:1064')}
                                checked={scheduleMessage}
                                onChange={(i) => {
                                    setScheduleMessage(!scheduleMessage);
                                }}
                                validator={props.validator}
                            ></ASwitch>

                            <ADateInput
                                disabled={!scheduleMessage}
                                id='valid-from-date'
                                label={t('AnelmaCommunication:1065')}
                                onChange={(d) => {
                                    setScheduleDate(moment(d));
                                }}
                                validator={validator}
                                value={scheduleDate?.toDate() || null}
                            />

                            <ATimeInput
                                disabled={!scheduleMessage}
                                id='valid-from-time'
                                label={t('AnelmaCommunication:1066')}
                                onChange={(t) => {
                                    setScheduleTime(moment(t));
                                }}
                                validator={validator}
                                value={scheduleTime?.toDate() || null}
                            />
                        </Grid>
                    </Grid>

                    <Grid item sm={12}>
                        <ATextarea
                            id={'content'}
                            label={t('AnelmaCommunication:1069')}
                            minLength={SMS.ContentMinimumLength}
                            maxLength={SMS.ContentMaximumLength}
                            validator={validator}
                            required
                            onChange={(e) => {
                                setContentInput(e);
                            }}
                            value={contentInput}
                        />
                    </Grid>
                </ADialogContent>
                <ADialogActions buttons={footerButtons} isLoading={isLoading} />
            </ADialog>

            {companySearchDialog && (
                <SearchMessageGroupDialog
                    validator={validator}
                    onSelect={(items) => {
                        setSelectedNumbers([...selectedNumbers, ...items]);
                    }}
                    onClose={() => {
                        setCompanySearchDialog(!companySearchDialog);
                    }}
                />
            )}
        </div>
    );
}
