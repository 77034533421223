// Libraries
// MUI
// Core
// Common
// Interfaces
// Store
// API
// Feature - Report

interface IReportProps {
    reportUrl: string;
}

export default function Report(props: IReportProps) {
    return (
        <div style={{ paddingTop: '20px' }}>
            <iframe
                src={props.reportUrl}
                style={{ height: '1000px', width: '100%', border: 0 }}
                title='Qlik Sense'
            ></iframe>
        </div>
    );
}
