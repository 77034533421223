// Libraries
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { clone } from 'ramda';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { IFormValidator } from '../core/FormValidator';
import { ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

// Interfaces
import { CalendarEvent } from '../interfaces/calendar/CalendarEvent';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
    ConfirmDeletionDialog,
} from '../common/dialog';
import { ADateInput, ADateValue, ASwitch, ATextarea, ATimeInput } from '../common/inputs';

// API
import api from '../api/calendarApi';

// Feature
import updateDataBuilder from './updateDataBuilder';

export type CalendarEventDialogMode = 'create' | 'modify';

export interface CalendarEventDialogProps {
    afterRemove: (event: CalendarEvent) => void;
    afterSave: (event: CalendarEvent) => void;
    event: CalendarEvent;
    mode: CalendarEventDialogMode;
    onClose: () => void;
    validator: IFormValidator;
    farmEntityId: string;
}

export default function CalendarEventDialog(props: CalendarEventDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaCalendar']);
    const { enqueueSnackbar } = useSnackbar();
    const originalData = clone(props.event);

    const [showConfirmDeletion, setShowConfirmDeletion] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allDay, setAllDay] = useState<boolean>(props.event.AllDay);
    const [content, setContent] = useState<string>(props.event.Content);
    const [dueDate, setDueDate] = useState<moment.Moment>(() => {
        const date = props.event.DueDate;
        return date ? moment(`${date}`) : moment().endOf('day');
    });
    const [fromDate, setFromDate] = useState<moment.Moment>(() => {
        const date = props.event.FromDate;
        return date ? moment(`${date}`) : moment().startOf('day');
    });
    const [title, setTitle] = useState<string>(props.event.Title);

    useEffect(() => {
        const valid = fromDate?.isBefore(dueDate) || false;
        props.validator.setState('dates', valid, (valid) => {
            if (!valid) {
                enqueueSnackbar(t('AnelmaCalendar:1002'), {
                    variant: 'error',
                });
            }
        });
    }, [fromDate, dueDate, enqueueSnackbar, props.validator, t]);

    const updateFromDate = (d: ADateValue, type: 'date' | 'time'): void => {
        if (d === null || !moment(d).isValid()) return;
        const newValue = updateDate(type, d, fromDate);
        setFromDate(newValue);
    };

    const updateDueDate = (d: ADateValue, type: 'date' | 'time'): void => {
        if (d === null || !moment(d).isValid()) return;
        const newValue = updateDate(type, d, dueDate);
        setDueDate(newValue);
    };

    const updateDate = (type: 'date' | 'time', newDate: Date, oldDate: moment.Moment | null) => {
        const newDate2 = moment(newDate);
        if (!oldDate) return newDate2;
        const oldDate2 = moment(oldDate);
        type === 'date'
            ? newDate2.hours(oldDate2.hours()).minutes(oldDate2.minutes())
            : newDate2.year(oldDate2.year()).month(oldDate2.month()).date(oldDate2.date());
        return newDate2;
    };

    const save = () => {
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }

        const data: CalendarEvent = {
            AllDay: allDay,
            Content: content,
            Deleted: props.event.Deleted,
            DueDate: dueDate.format('YYYY-MM-DDTHH:mm:ss'),
            FromDate: fromDate.format('YYYY-MM-DDTHH:mm:ss'),
            Id: props.event.Id,
            Title: title,
            Tags: props.event.Tags,
            Type: props.event.Type,
            FarmId: props.farmEntityId,
        };

        setIsLoading(true);

        const promise =
            props.mode === 'create'
                ? api.createCalendarEvent(data)
                : api.updateCalendarEvent(updateDataBuilder(originalData, data));

        const fail = () => {
            enqueueSnackbar(t('AnelmaGeneral:1019'), {
                variant: 'error',
            });
            setIsLoading(false);
        };

        promise.then(
            (response) => {
                if (response === null) fail();
                else {
                    props.afterSave(response.Entity);
                    props.onClose();
                }
            },
            () => fail()
        );
    };

    const remove = () => {
        setIsLoading(true);

        const fail = () => {
            enqueueSnackbar(t('AnelmaGeneral:1145'), {
                variant: 'error',
            });
            setIsLoading(false);
        };

        api.deleteCalendarEvent(props.event.Id).then(
            (response) => {
                if (response === null || !response.Entity.Deleted) fail();
                else {
                    props.afterRemove(props.event);
                    props.onClose();
                }
            },
            () => fail()
        );
    };

    const buttons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => save(),
                type: 'save',
            },
        ],
    };

    if (auth.canDeleteCalendarEvent && props.mode === 'modify') {
        buttons.left.push({
            onClick: () => setShowConfirmDeletion(true),
            type: 'delete',
        });
    }

    return (
        <>
            <ADialog open={true}>
                <ADialogTitle>
                    {props.mode === 'create' ? t('AnelmaGeneral:1135') : t('AnelmaGeneral:1140')}
                </ADialogTitle>

                <ADialogContent isLoading={isLoading} size='md'>
                    <Grid container>
                        <Grid item xs={12}>
                            <ATextarea
                                id='title'
                                label={t('AnelmaGeneral:1136')}
                                lengthBoundaryName='AnelmaCalendar:TitleLength'
                                onChange={(v) => setTitle(v)}
                                required
                                validator={props.validator}
                                value={title}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <ATextarea
                                id='content'
                                label={t('AnelmaGeneral:1137')}
                                lengthBoundaryName='AnelmaCalendar:ContentLength'
                                onChange={(v) => setContent(v)}
                                validator={props.validator}
                                value={content}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <ADateInput
                                id='from-date'
                                label={t('AnelmaGeneral:1138')}
                                onChange={(v) => updateFromDate(v, 'date')}
                                required
                                validator={props.validator}
                                value={fromDate.toDate()}
                            />

                            {!allDay && (
                                <ATimeInput
                                    id='from-time'
                                    onChange={(v) => updateFromDate(v, 'time')}
                                    required={!allDay}
                                    validator={props.validator}
                                    value={fromDate.toDate()}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ADateInput
                                id='due-date'
                                label={t('AnelmaGeneral:1139')}
                                onChange={(v) => updateDueDate(v, 'date')}
                                required
                                validator={props.validator}
                                value={dueDate.toDate()}
                            />

                            {!allDay && (
                                <ATimeInput
                                    id='due-time'
                                    onChange={(v) => updateDueDate(v, 'time')}
                                    required={!allDay}
                                    validator={props.validator}
                                    value={dueDate.toDate()}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <ASwitch
                                checked={allDay}
                                id='all-day'
                                label={t('AnelmaGeneral:1123')}
                                onChange={(v) => setAllDay(v)}
                                validator={props.validator}
                            />
                        </Grid>
                    </Grid>
                </ADialogContent>

                <ADialogActions buttons={buttons} isLoading={isLoading} />
            </ADialog>

            <ConfirmDeletionDialog
                open={showConfirmDeletion}
                onClose={() => setShowConfirmDeletion(false)}
                onDelete={() => remove()}
                confirmation={t('AnelmaGeneral:1143')}
            />
        </>
    );
}
