// Libraries
import { useTranslation } from 'react-i18next';

// Core
import { baseUrl } from '../../../../core/constants';
import { ResourceTextApplication } from '../../../../core/resources';

// Feature
import './loginLink.scss';
import UserAvatar from '../../../UserAvatar';

interface LoginLinkProps {
    tinyHeader: boolean;
}

export default function LoginLink(props: LoginLinkProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaLayout']);

    const redirectToLogin = () => {
        window.location.href = `${baseUrl}/login`;
    };

    return (
        <div className='an-header-login-link' onClick={() => redirectToLogin()} data-robot-id="header-login-link">
            {t('AnelmaLayout:1034')}
            {!props.tinyHeader && <UserAvatar />}
        </div>
    );
}
