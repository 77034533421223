import { useState, useEffect } from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// MUI
import { Grid } from '@mui/material';

//Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../core/resources';
import auth from '../../core/authorization';

// Interfaces
import { IInvoice, IInvoiceProducerInfo, PaymentMethod } from '../../interfaces/IInvoice';

// Common
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';
import { ViewTitle } from '../../common/typography';
import { ADefaultIconButton, AFindInPageIconButton } from '../../common/buttons';

// API
import InvoiceApi from '../../api/invoiceApi';

//Feature
import InvoiceDetailView from './InvoiceDetailView';
import InvoiceMathHelper from './InvoiceMathHelper';
import { PictureAsPdf } from '@mui/icons-material';
import printingApi from '../../api/printingApi';
import { useSnackbar } from 'notistack';

export interface InvoicesListViewProps {
    producerNumber: number;
    producerName: string;
    producerTunnus: string;
    producerAddress: string;
    isLoading: boolean;
    preSelectedInvoiceNumber: number | null;
}

export default function InvoicesListView(props: InvoicesListViewProps) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaLayout',
        'AnelmaInvoice',
    ]);
    const [paymentMethodParams, setPaymentMethodParams] = useState<ParameterValues>([]);

    const { producerNumber, producerAddress, producerName, producerTunnus } = props;
    const [invoiceDetailVisible, setInvoiceDetailVisible] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<IInvoice>();
    const [preSelectedInvoiceNumber, setPreSelectedInvoiceNumber] = useState<number | null>(
        props.preSelectedInvoiceNumber
    );

    const producerInfoContext: IInvoiceProducerInfo = {
        ProducerAddress: producerAddress,
        ProducerName: producerName,
        ProducerYTunnus: producerTunnus,
        ProducerNumber: producerNumber,
    };

    const columns: AGridColumns = [
        {
            field: 'InvoiceNumber',
            headerName: t('AnelmaInvoice:1004'),
            description: t('AnelmaInvoice:1004'),
            type: 'string',
            width: 120,
        },
        {
            field: 'PaymentMethod',
            headerName: t('AnelmaInvoice:1066'),
            description: t('AnelmaInvoice:1066'),
            type: 'string',
            width: 160,
            renderCell: (params) => {
                const foundParam = paymentMethodParams.find(
                    (f) => Number(f.code) === Number(params.value)
                )?.text;
                return (
                    foundParam ??
                    (paymentMethodParams.find((f) => f.code === String(PaymentMethod.Invoice))
                        ?.text as string)
                );
            },
        },
        {
            field: 'InvoiceSum',
            headerName: t('AnelmaInvoice:1003'),
            description: t('AnelmaInvoice:1003'),
            type: 'number',
            width: 160,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'UnpaidSum',
            headerName: t('AnelmaInvoice:1002'),
            description: t('AnelmaInvoice:1002'),
            type: 'number',
            width: 150,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        gridColumns.dateTime('InvoicePaymentDeadline', t('AnelmaInvoice:1001'), 'DD.MM.YYYY'),
        gridColumns.dateTime('InvoicingDate', t('AnelmaInvoice:1024'), 'DD.MM.YYYY'),
    ];

    const [isLoading, setIsLoading] = useState<boolean>(props.isLoading);
    const [rowsData, setRowsData] = useState<IInvoice[]>([]);
    const rows = rowsData.map((i) => mergeRight(i, { id: i.InvoiceNumber }));

    useEffect(() => {
        paramRepository.load(['AnelmaInvoice']).then(() => {
            setPaymentMethodParams(paramRepository.resource('AnelmaInvoice', 'PaymentMethod'));
        });
    }, []);

    useEffect(() => {
        //api call with producer number assign in props
        producerInvoicesData();
    }, [props.producerNumber]);

    useEffect(() => {
        if (preSelectedInvoiceNumber !== null && rowsData.length > 0) {
            const invoice = rowsData.find((_) => _.InvoiceNumber === preSelectedInvoiceNumber);
            if (invoice) handleInvoiceDialog(invoice);
        }
    }, [preSelectedInvoiceNumber, rowsData]);

    const producerInvoicesData = () => {
        if (props.producerNumber !== 0) {
            setIsLoading(true);
            InvoiceApi.getProducerInvoice(props.producerNumber).then(
                (response) => {
                    if (response.Items.length === 0) {
                        setIsLoading(false);
                        return;
                    }

                    setRowsData(
                        response.Items.sort(
                            (a, b) =>
                                new Date(b.InvoicingDate).valueOf() -
                                new Date(a.InvoicingDate).valueOf()
                        )
                    );
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                    return;
                }
            );
        }
    };

    const handleInvoiceDialog = (item: IInvoice) => {
        setInvoiceDetailVisible(!invoiceDetailVisible);
        setSelectedRow(item);
    };

    const getDataGridActions = (id: number, invoice: IInvoice) => {
        return (
            auth.canViewEconomyInvoice && (
                <AFindInPageIconButton
                    key={`${id}-view`}
                    onClick={() => {
                        handleInvoiceDialog(invoice);
                    }}
                />
            )
        );
    };

    const onInvoiceDetailsViewClose = () => {
        setInvoiceDetailVisible(!invoiceDetailVisible);
        setPreSelectedInvoiceNumber(null);
    };

    const getPrintInvoiceAction = (invoiceNumber: number) => {
        return (
            auth.canViewEconomyInvoice && (
                <ADefaultIconButton
                    icon={<PictureAsPdf />}
                    onClick={() => {
                        printingApi.printInvoice(invoiceNumber).then((response) => {
                            if (!response) {
                                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                                    variant: 'error',
                                });
                            }
                        });
                    }}
                    tooltip=""
                />
            )
        );
    }

    return (
        <>
            {auth.canViewEconomyInvoice && (
                <>
                    <Grid container>
                        <ViewTitle>{t('AnelmaLayout:1057')}</ViewTitle>
                    </Grid>

                    <ADataGrid
                        actions={[
                            (params) =>
                                getDataGridActions(
                                    params.id as number,
                                    params.row as unknown as IInvoice
                                ),
                            (params) =>
                                getPrintInvoiceAction(
                                    (params.row as unknown as IInvoice).InvoiceNumber
                                ),
                        ]}
                        columns={columns}
                        rows={rows.length ? rows : []}
                        enableFiltering
                        enableRowNumbers
                        enablePagination
                        loading={isLoading}
                    ></ADataGrid>
                </>
            )}

            {invoiceDetailVisible && (
                <InvoiceDetailView
                    producerdata={producerInfoContext}
                    rowIdData={selectedRow as IInvoice}
                    onClose={() => onInvoiceDetailsViewClose()}
                ></InvoiceDetailView>
            )}
        </>
    );
}
