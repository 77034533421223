// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
//Common
//Core
import { ResourceTextApplication } from '../../core/resources';
//Interface
import { ILinkedCreditInvoices } from '../../interfaces/IInvoice';

// MUI
import {
    Container,
    Typography,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
} from '@mui/material';
//Feature
import InvoiceMathHelper from './InvoiceMathHelper';
import dateUtils from '../../core/utils.date';

export interface LinkedCreditInvoicesProps {
    data: ILinkedCreditInvoices[];
}

export default function LinkedCreditInvoices(props: LinkedCreditInvoicesProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaInvoice']);

    const rowsData = props.data
        .map((i) => mergeRight(i, { id: i.InvoiceNumber }))
        .sort((a, b) => (a.InvoicingDate < b.InvoicingDate ? 1 : -1));

    return (
        <div>
            {props.data && (
                <Container>
                    <TableContainer
                        key={'credit-link'}
                        component={Paper}
                        style={{ maxWidth: '600px' }}
                    >
                        <Typography variant='h5'>{t('AnelmaInvoice:1065')}</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>{t('AnelmaInvoice:1004')} </TableCell>
                                    <TableCell align='left'>{t('AnelmaInvoice:1024')} </TableCell>
                                    <TableCell align='right'>{t('AnelmaInvoice:1048')} </TableCell>
                                    <TableCell align='right'>{t('AnelmaInvoice:1011')} </TableCell>
                                    <TableCell align='right'>{t('AnelmaInvoice:1014')} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align='left'> {row.InvoiceNumber}</TableCell>
                                        <TableCell align='left'>
                                            {dateUtils.formatUtcString(
                                                row.InvoicingDate as string,
                                                'DD.MM.YYYY'
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {InvoiceMathHelper.roundThousandLeading(
                                                row.PriceBeforeTaxes
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {InvoiceMathHelper.roundThousandLeading(row.VATAmount)}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {InvoiceMathHelper.roundThousandLeading(
                                                row.PriceAfterTaxes
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            )}
        </div>
    );
}
