// Libraries
import { useTranslation } from 'react-i18next';
import { ReactComponentElement, ReactNode } from 'react';

// MUI
import GroupIcon from '@mui/icons-material/Group';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AIconButtonBaseProps, ADefaultIconButton, AButtonBaseProps } from '../../common/buttons';
import { Link } from 'react-router-dom';

export interface AGroupIconButtonProps extends AIconButtonBaseProps {}

export default function AGroupIconButton(props: AGroupIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1075');

    return <ADefaultIconButton {...buttonProps} icon={<GroupIcon />} tooltip={tooltip2} />;
}
