// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI
import { Grid, useMediaQuery, useTheme } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../../core/resources';
import FormValidator from '../../../core/FormValidator';
import utils from '../../../core/utils';
import auth from '../../../core/authorization';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../../common/dialog';
import {
    ALabel,
    ATextInput,
    ADropdown,
    AInputContainer,
    ARadioButtons,
    ADateInput,
    AAutocomplete,
    AAutocompleteValue,
    AInputItem,
} from '../../../common/inputs';
import { AAddButton, ADefaultButton } from '../../../common/buttons';
import AEUIdentifierInput from '../../../common/inputs/AEUIdentifierInput';
import ConfirmDialogClose from '../../../common/dialog/ConfirmDialogClose';

// Interfaces
import { IAlias, IFarmDetails } from '../../../interfaces/IBusinessEntities';
import {
    AliasTypeEnum,
    AnnouncementTypeEnum,
    DialogModeEnum,
    FarmingTypeEnum,
    SalesType,
} from '../../../interfaces/enums';
import { IAnimalSummaryExtraInfo, IFarmAnimal } from '../../../interfaces/IAnimal';
import { ITiltuApiResult } from '../../../interfaces/IGeneral';
import {
    IAnimalAnnouncement,
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementContainsAnimals,
    IAnimalAnnouncementContainsAnimalsResult,
    ICollectWeek,
    ICreateAnnouncement,
    IUpdateAnnouncementResult,
    IUpdateBovineAnnouncement,
} from '../../../interfaces/IAnnouncement';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import companyApi from '../../../api/companyApi';
import animalAnnouncementApi from '../../../api/animalAnnouncementApi';

// Feature
import {
    initializeCollectWeekStart,
    mapCollectWeeksForDropdown,
    generateCollectWeekSequence,
} from '../../announcements/helpers/date';
import {
    getFarmingTypesFromFarmDetails,
    hasAnnouncementResponseErrors,
    mapAnimalAnnouncementUpdate,
    mapAnimalsToAnimalAnnouncement,
} from '../../announcements/helpers/data';

import { mapParameters } from '../../shared/helpers/data';
import { Strings } from '../../shared/constants/strings';
import {
    IManualAnnouncementAnimalsGridProps,
    ManualAnnouncementAnimalsGrid,
} from './ManualAnnouncementAnimalsGrid';
import {
    mapAnnouncementBatchToAnnouncementAnimals,
    mapCreateAnimalAnnouncementData,
} from '../helpers/data';
import { removeDashes } from '../../announcements/helpers/euIdentifier';
import ChainInformationDialog from '../../chain-information/components/ChainInformationDialog';
import {
    collectWeekStartNotValid,
    collectWeekStartHasValue,
    collectWeekEndHasValue,
    collectWeekEndNotValid,
    getQuickCowCollectWeeks,
    batchesHaveValidCollectWeek,
    defaultCollectWeek,
} from '../../announcements/helpers/collectWeek';
import { Edit, Search } from '@mui/icons-material';
import { canViewBovineSlaughterAnnouncementSaveAsAdmin } from '../../announcements/components/bovine-slaughter-announcement/helpers/bovineSlaughterAnnouncementAccessRights';
import Annotation from '../../../common/annotations/Annotation';
import { getDistinctSalesTypeAnnotationMsgs } from '../../announcements/helpers/salesTypeAnnotation';
import { getHoldingSiteItems } from '../../announcements/helpers/holdingSite';
import { IGridSize } from '../../announcements/components/bovine-slaughter-announcement/BovineSlaughterAnnouncement';
import {
    constructAnnouncementNumberGridSize,
    constructCollectWeekGridSize,
} from '../../shared/helpers/gridSize';
import { holdingSiteHasValue, isReadOnlyMode } from '../../shared/helpers/utils';
import CloseDialogButton, {
    ICloseDialogProps,
} from '../../announcements/components/general/CloseDialogButton';
import {
    isDataValidForCreate,
    isDataValidForSave,
} from '../../shared/validators/bovineAnnouncementValidators';
import { boundaryRepository } from '../../../core/boundaries';
import { hasAnimalDataExpressCow } from '../../announcements/helpers/animal';
import '../../announcements/styles/announcementStyles.scss';
import '../../shared/styles/sharedStyles.scss';

export interface IManualAnnouncementDialogProps {
    announcementData: IAnimalAnnouncement | null;
    announcementType: AnnouncementTypeEnum;
    mode: DialogModeEnum;
    onClose: () => void;
    processAnnouncementSave: (
        data: ITiltuApiResult<any>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => void;
    mapUpdatedSalesTypeToAllAnimalsData: (data: IFarmAnimal[]) => void;
    animals: IAnimalSummaryExtraInfo[] | null;
    withChainInformation: boolean;
    readOnly?: boolean;
    chainInformationFound?: boolean;
}

export default function ManualAnnouncementDialog(props: IManualAnnouncementDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBovine',
        'AnelmaGeneral',
        'AnelmaCompany',
        'AnelmaAnimalAnnouncement',
    ]);

    const context = useAppSelector((state) => state.context);
    const validator = new FormValidator();
    const { enqueueSnackbar } = useSnackbar();

    const [animalsAddedAndNotSaved, setAnimalsAddedAndNotSaved] = useState<string[]>([]);
    const [announcementData] = useState<IAnimalAnnouncement | null>(
        props.announcementData ? JSON.parse(JSON.stringify(props.announcementData)) : null
    );
    const [announcementAnimals, setAnnouncementAnimals] = useState<IAnimalAnnouncementAnimal[]>([]);
    const [announcementAnimalsForChainInfo, setAnnouncementAnimalsForChainInfo] = useState<
        IAnimalAnnouncementAnimal[]
    >([]);
    const [announcementId, setAnnouncementId] = useState<string | null>(null);
    const [announcementNumber, setAnnouncementNumber] = useState<string | null>(
        props.announcementData?.Number?.toString() ?? null
    );
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isSalesTypeDialogVisible, setIsSalesTypeDialogVisible] = useState<boolean>(false);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();
    const [selectedHoldingSiteId, setSelectedHoldingSiteId] = useState<string>('');
    const [holdingSiteItems, setHoldingSiteItems] = useState<AInputItem<AAutocompleteValue>[]>([]);
    const [salesTypeAnnotations, setSalesTypeAnnotations] = useState<string[]>([]);
    const [collectDateStart, setCollectDateStart] = useState<ICollectWeek>({ Year: -1, Week: -1 });
    const [collectDateEnd, setCollectDateEnd] = useState<ICollectWeek>({ Year: -1, Week: -1 });
    const [collectWeeks, setCollectWeeks] = useState<ICollectWeek[]>([]);
    const [additionalInformation, setAdditionalInformation] = useState<string>('');
    const [salesTypeParameterCode, setSalesTypeParameterCode] = useState<number>(-1);
    const [euIdentifier, setEuIdentifier] = useState<string>('');
    const [selectedSex, setSelectedSex] = useState<number>(-1);
    const [birthDate, setBirthDate] = useState<moment.Moment | null>(null);
    const [bovineSexParams, setBovineSexParams] = useState<ParameterValues>([]);
    const [chainInformationDialogVisible, setChainInformationDialogVisible] =
        useState<boolean>(false);
    const [isModifyCollectWeekDialogVisible, setIsModifyCollectWeekDialogVisible] =
        useState<boolean>(false);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues>([]);
    const [combinedSalesTypeParams, setCombinedSalesTypeParams] = useState<ParameterValues>([]);
    const [breederBrokerSaleParams, setBreederBrokerSaleParams] = useState<ParameterValues>([]);
    const [rearingUnitBrokerSaleParams, setRearingUnitBrokerSaleParams] = useState<ParameterValues>(
        []
    );
    const [dairyCattleBrokerSaleParams, setDairyCattleBrokerSaleParams] = useState<ParameterValues>(
        []
    );
    const [sucklerCowCattleBrokerSaleParams, setSucklerCowCattleBrokerSaleParams] =
        useState<ParameterValues>([]);
    const [breederSlaughterSaleParams, setBreederSlaughterSaleParams] = useState<ParameterValues>(
        []
    );
    const [rearingUnitSlaughterSaleParams, setRearingUnitSlaughterSaleParams] =
        useState<ParameterValues>([]);
    const [dairyCattleSlaughterSaleParams, setDairyCattleSlaughterSaleParams] =
        useState<ParameterValues>([]);
    const [sucklerCowCattleSlaughterSaleParams, setSucklerCowCattleSlaughterSaleParams] =
        useState<ParameterValues>([]);
    const [collectWeekGridSize, setCollectWeekGridSize] = useState<IGridSize>({
        Xs: 2,
        Sm: 2,
        Md: 2,
        Lg: 2,
    });
    const [announcementNumberGridSize, setAnnouncementNumberGridSize] = useState<IGridSize>({
        Xs: 4,
        Sm: 4,
        Md: 4,
        Lg: 4,
    });
    const [readOnlyMode] = useState<boolean>(isReadOnlyMode(props.mode, props.readOnly ?? false));
    const [confirmationDialogClose, setConfirmationDialogClose] = useState<boolean>(false);

    const [addInfoMinLength, setAddInfoMinLength] = useState<number | undefined>();
    const [addInfoMaxLength, setAddInfoMaxLength] = useState<number | undefined>();
    const [boundariesLoaded, setBoundariesLoaded] = useState<boolean>(false);
    const [hideCollectWeeks, setHideCollectWeeks] = useState<boolean>(false);

    const theme = useTheme();
    const isBelowMdDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const start = initializeCollectWeekStart(props.announcementType);
        initializeCollectWeeks(start.Week);
        initializeParameters();
        setCollectDateStart(start);
        setCollectDateEnd({ Year: start.Year, Week: start.Week + 1 });

        boundaryRepository.load(['AnelmaAnimalAnnouncement']).then(() => {
            const boundaries = boundaryRepository.resource(
                'AnelmaAnimalAnnouncement',
                'BovinePurchaseAnnouncementInfo'
            );

            if (boundaries) {
                setAddInfoMaxLength(
                    Number.isInteger(boundaries.maxValue)
                        ? (boundaries.maxValue as number)
                        : undefined
                );
                setAddInfoMinLength(
                    Number.isInteger(boundaries.minValue)
                        ? (boundaries.minValue as number)
                        : undefined
                );
            }
            setBoundariesLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (farmDetails !== undefined) combineSalesTypeParams(farmDetails);
    }, [
        farmDetails,
        breederBrokerSaleParams,
        rearingUnitBrokerSaleParams,
        dairyCattleBrokerSaleParams,
        sucklerCowCattleBrokerSaleParams,
        breederSlaughterSaleParams,
        rearingUnitSlaughterSaleParams,
        dairyCattleSlaughterSaleParams,
        sucklerCowCattleSlaughterSaleParams,
    ]);

    useEffect(() => {
        if (combinedSalesTypeParams.length > 0) {
            setSalesTypeParams(
                combinedSalesTypeParams.filter(
                    (value, idx, self) =>
                        idx ===
                        self.findIndex((t) => t.code === value.code && t.text === value.text)
                )
            );
        }
    }, [combinedSalesTypeParams]);

    useEffect(() => {
        resolveSalesTypeAnnotations();
        if (announcementAnimals !== null && announcementAnimals.length > 0)
            setHideCollectWeeks(
                hasAnimalDataExpressCow(props.announcementType, announcementAnimals)
            );
    }, [announcementAnimals]);

    useEffect(() => {
        if (collectWeekStartNotValid(collectDateStart, collectDateEnd)) {
            if (
                collectWeekStartHasValue(collectDateStart) &&
                collectWeekEndHasValue(collectDateEnd)
            ) {
                enqueueSnackbar(t('AnelmaAnimalAnnouncement:1006'), {
                    variant: 'error',
                });
                setCollectDateStart({ Year: -1, Week: -1 });
            }
        }
    }, [collectDateStart]);

    useEffect(() => {
        if (collectWeekEndNotValid(collectDateEnd, collectDateStart)) {
            if (collectWeekEndHasValue(collectDateEnd)) {
                enqueueSnackbar(t('AnelmaAnimalAnnouncement:1007'), {
                    variant: 'error',
                });
                setCollectDateEnd({ Year: -1, Week: -1 });
            }
        }
    }, [collectDateEnd]);

    useEffect(() => {
        if (isBelowMdDevice) {
            setAnnouncementNumberGridSize(constructAnnouncementNumberGridSize());
            setCollectWeekGridSize(constructCollectWeekGridSize());
        }
    }, [isBelowMdDevice]);

    const combineSalesTypeParams = (farmDetails: IFarmDetails) => {
        // Jos tilalla on useampi tuotantosuunta, listaan yhdistetään tilan tuotantosuuntien parametrit
        const farmingTypesInCurrentFarm = getFarmingTypesFromFarmDetails(
            farmDetails.ProductionLines
        );

        for (const farmingType of farmingTypesInCurrentFarm) {
            if (props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter) {
                switch (farmingType) {
                    case FarmingTypeEnum.Calving: // Emolehmätila
                        setCombinedSalesTypeParams((previousState) => [
                            ...previousState,
                            ...sucklerCowCattleSlaughterSaleParams,
                        ]);
                        break;
                    case FarmingTypeEnum.Dairy: // Lypsykarjatila
                        setCombinedSalesTypeParams((previousState) => [
                            ...previousState,
                            ...dairyCattleSlaughterSaleParams,
                        ]);
                        break;
                    case FarmingTypeEnum.Upbringing: // Kasvattaja
                        setCombinedSalesTypeParams((previousState) => [
                            ...previousState,
                            ...breederSlaughterSaleParams,
                        ]);
                        break;
                    case FarmingTypeEnum.Transmission: // Välikasvattamo
                        setCombinedSalesTypeParams((previousState) => [
                            ...previousState,
                            ...rearingUnitSlaughterSaleParams,
                        ]);
                        break;
                    default:
                        break;
                }
            } else if (props.announcementType === AnnouncementTypeEnum.ManualBovineIntermediation) {
                switch (farmingType) {
                    case FarmingTypeEnum.Calving: // Emolehmätila
                        setCombinedSalesTypeParams((previousState) => [
                            ...previousState,
                            ...sucklerCowCattleBrokerSaleParams,
                        ]);
                        break;
                    case FarmingTypeEnum.Dairy: // Lypsykarjatila
                        setCombinedSalesTypeParams((previousState) => [
                            ...previousState,
                            ...dairyCattleBrokerSaleParams,
                        ]);
                        break;
                    case FarmingTypeEnum.Upbringing: // Kasvattaja
                        setCombinedSalesTypeParams((previousState) => [
                            ...previousState,
                            ...breederBrokerSaleParams,
                        ]);
                        break;
                    case FarmingTypeEnum.Transmission: // Välikasvattamo
                        setCombinedSalesTypeParams((previousState) => [
                            ...previousState,
                            ...rearingUnitBrokerSaleParams,
                        ]);
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const resolveSalesTypeAnnotations = () => {
        setSalesTypeAnnotations(
            getDistinctSalesTypeAnnotationMsgs(
                announcementData,
                !announcementData ? announcementAnimals : null
            ).map((x) => t(x))
        );
    };


    const commitSalesTypeChanges = () => {
        if (!farmDetails || !farmDetails.Id) return;

        const updates: { [key: string]: IFarmAnimal[] } = {};
        for (let animal of announcementAnimals) {
            const original = props.animals?.find((x) => x.EuIdentifier === animal.EuIdentifier);
            if (!original || original?.SalesType === animal.SalesType) continue;
            const key = String(animal.SalesType);
            if (!updates[key])
                updates[key] = [];

            updates[key].push({
                AnimalGUID: original.Id,
                AnimalType: 1,
                ExternalIdentifier: animal.EuIdentifier,
                FarmId: farmDetails.Id,
                SalesType: animal.SalesType,
                ValidFrom: new Date(),
                ValidTo: null
            });
        }

        for (let key in updates) {
            const update = updates[key];
            props.mapUpdatedSalesTypeToAllAnimalsData(update);
        }
    };


    useEffect(() => {
        if (announcementData) {
            setSelectedHoldingSiteId(announcementData.HoldingSiteId);
            setAdditionalInformation(announcementData.Info);
            setAnnouncementAnimals(
                mapAnnouncementBatchToAnnouncementAnimals(announcementData.Batches)
            );
            setAnnouncementAnimalsForChainInfo(
                mapAnnouncementBatchToAnnouncementAnimals(announcementData.Batches)
            );
            setAnnouncementId(announcementData.Id);

            const starts = announcementData.Batches.map((b) => b.CollectWeekStart).sort(
                (a, b) => a.Week - b.Week
            )[0];
            const ends = announcementData.Batches.map((b) => b.CollectWeekEnd).sort(
                (a, b) => a.Week - b.Week
            )[0];

            if (starts) setCollectDateStart({ Year: starts.Year, Week: starts.Week });
            if (ends) setCollectDateEnd({ Year: ends.Year, Week: ends.Week });

            resolveSalesTypeAnnotations();
        }
    }, [announcementData]);

    useEffect(() => {
        if (
            (props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter &&
                context.data.currentContext) ||
            (props.announcementType === AnnouncementTypeEnum.ManualBovineIntermediation &&
                context.data.currentContext)
        ) {
            setIsLoading(true);

            const companyByContext = companyApi
                .getCompanyByContext(context.data.currentContext.context)
                .then((response) => {
                    setFarmDetails(response?.Entity as IFarmDetails);
                });
            Promise.all([companyByContext]).then(() => {
                setIsLoading(false);
            });
        } else setFarmDetails(undefined);
    }, [context]);

    useEffect(() => {
        if (farmDetails) {
            setHoldingSiteItems(getHoldingSiteItems(farmDetails));
        }
    }, [farmDetails]);

    // Generate collect week sequences for the current and next year
    const initializeCollectWeeks = (from: number) => {
        setCollectWeeks(
            generateCollectWeekSequence(
                from,
                moment().isoWeeksInYear() - from,
                moment().isoWeekYear()
            )
        );
        if (moment().add(12, 'months').year() === moment().add(1, 'y').year()) {
            setCollectWeeks((previousState) => [
                ...previousState,
                ...generateCollectWeekSequence(
                    1,
                    moment().add(12, 'months').isoWeek(),
                    moment().isoWeekYear() + 1
                ),
            ]);
        }
    };

    const closeDialogButtonProps: ICloseDialogProps = {
        className: 'animal-announcement-close-button-float-right',
        onClick: () => handleDialogClose(),
    };

    const closeIcon = (): JSX.Element => {
        return <CloseDialogButton {...closeDialogButtonProps} />;
    };

    const initializeParameters = () => {
        paramRepository.load(['AnelmaBovine']).then(() => {
            setBovineSexParams(paramRepository.resource('AnelmaBovine', 'BovineSex'));
            setBreederBrokerSaleParams(
                paramRepository.resource('AnelmaBovine', 'BreederBrokerSale')
            );
            setRearingUnitBrokerSaleParams(
                paramRepository.resource('AnelmaBovine', 'RearingUnitBrokerSale')
            );
            setDairyCattleBrokerSaleParams(
                paramRepository.resource('AnelmaBovine', 'DairyCattleBrokerSale')
            );
            setSucklerCowCattleBrokerSaleParams(
                paramRepository.resource('AnelmaBovine', 'SucklerCowCattleBrokerSale')
            );
            setBreederSlaughterSaleParams(
                paramRepository.resource('AnelmaBovine', 'BreederSlaughterSale')
            );
            setRearingUnitSlaughterSaleParams(
                paramRepository.resource('AnelmaBovine', 'RearingUnitSlaughterSale')
            );
            setDairyCattleSlaughterSaleParams(
                paramRepository.resource('AnelmaBovine', 'DairyCattleSlaughterSale')
            );
            setSucklerCowCattleSlaughterSaleParams(
                paramRepository.resource('AnelmaBovine', 'SucklerCowCattleSlaughterSale')
            );
        });
    };

    const deleteBatches = async () => {
        if (!props.announcementData) return false;
        const data = props.announcementData;

        let ok = true;
        setIsLoading(true);

        for (let animal of mapAnnouncementBatchToAnnouncementAnimals(data.Batches)) {
            const isDeleted = !announcementAnimals.find(
                (a) => a.EuIdentifier === animal.EuIdentifier
            );
            if (!isDeleted) continue;

            const batch = data?.Batches.find((b) =>
                b.Animals.find((ba) => ba.AnimalId === animal.AnimalId)
            );

            if (batch && announcementData) {
                const response = await animalAnnouncementApi.deleteAnimalAnnouncementBatch(
                    announcementData.Number,
                    batch.BatchNumber as unknown as string[]
                );
                if (!response) {
                    ok = false;
                    enqueueSnackbar(t('#Issue when deleting batch from announcement'), {
                        variant: 'error',
                    });
                    return ok;
                }
                data.Batches = data.Batches.filter((b) => b.Id !== batch.Id);
                announcementData.Batches = announcementData.Batches.filter(
                    (b) => b.Id !== batch.Id
                );
            }
        }

        return ok;
    };

    const removeAnimalFromAnnouncement = (animal: IAnimalAnnouncementAnimal) => {
        setAnnouncementAnimals([
            ...announcementAnimals.filter((a) => a.EuIdentifier !== animal.EuIdentifier),
        ]);
    };

    const updateAnimalSalesType = (data: IFarmAnimal[]) => {
        let tempAnimals = [...announcementAnimals];
        let additionalSaveNeeded: boolean = false;
        // here we're always updating one animal at times
        for (let changedAnimal of data) {
            let foundAnimal = tempAnimals.find((a) => a.AnimalId === changedAnimal.AnimalGUID);
            let batch = announcementData?.Batches.find((b) => b.Animals[0].AnimalId === changedAnimal.AnimalGUID);

            if (foundAnimal) {
                foundAnimal.SalesType = changedAnimal.SalesType;
                additionalSaveNeeded = true;
            }

            if (batch && foundAnimal?.SalesType === 28) {
                const quickCowCollectWeeks = getQuickCowCollectWeeks();
                batch.CollectWeekStart = quickCowCollectWeeks.Start;
                batch.CollectWeekEnd = quickCowCollectWeeks.End;
            }
        }

        setAnnouncementAnimals(tempAnimals);
    };

    const animalsDataGridProps: IManualAnnouncementAnimalsGridProps = {
        announcementId: announcementData?.Id ?? '',
        announcementAnimals: announcementAnimals,
        allBatches: announcementData?.Batches ?? [],
        announcementType: props.announcementType,
        bovineSexParams: bovineSexParams,
        dialogMode: props.mode,
        farmDetails: farmDetails,
        isLoading: false,
        isModifyCollectWeekDialogVisible: isModifyCollectWeekDialogVisible,
        isSalesTypeDialogVisiable: isSalesTypeDialogVisible,
        isReadOnlyMode: readOnlyMode,
        onAnimalAnimalSalesTypeUpdate: (data: IFarmAnimal[]) => {
            updateAnimalSalesType(data);
        },
        onAnimalRemoveFromGrid: (animal: IAnimalAnnouncementAnimal) => {
            removeAnimalFromAnnouncement(animal);
        },
        onCloseSalesTypeDialog: () => {
            setIsSalesTypeDialogVisible(false);
        },
        onCloseModifyCollectWeekDialog: (cancelled) => {
            setIsModifyCollectWeekDialogVisible(false);
        },
        salesTypeParams: salesTypeParams,
        mode: props.mode,
    };

    const handleAddAnimal = () => {
        if (validateAnimalToAdd()) {
            let tempAnimals = [...announcementAnimals];

            let animalToAdd: IAnimalAnnouncementAnimal = {
                AnimalId: utils.generateUniqueId(),
                EuIdentifier: euIdentifier,
                Sex: Number(selectedSex),
                Breed: 0,
                BirthDate: birthDate?.toString() ?? '',
                SalesType: salesTypeParameterCode,
            };
            const collectWeek = defaultCollectWeek(animalToAdd);
            if (collectWeek) {
                animalToAdd.CollectWeekStart = collectWeek.Start;
                animalToAdd.CollectWeekEnd = collectWeek.End;
            }

            setIsLoading(true);

            animalAnnouncementApi
                .animalAnnouncementContainsAnimals({
                    farmGuid: (farmDetails as IFarmDetails).Id as string,
                    announcementType: props.announcementType,
                    euIdentifierList: [removeDashes(euIdentifier)],
                } as IAnimalAnnouncementContainsAnimals)
                .then((response) => {
                    const animalContainsData =
                        response as unknown as IAnimalAnnouncementContainsAnimalsResult;

                    if (animalContainsData.ResultSet.find((r) => r.IsInAnnouncement)) {
                        enqueueSnackbar(
                            t(
                                `AnelmaBovine:${
                                    props.announcementType ===
                                    AnnouncementTypeEnum.ManualBovineSlaughter
                                        ? 1096
                                        : 1116
                                }`
                            ),
                            {
                                variant: 'warning',
                            }
                        );
                    }

                    tempAnimals.push(animalToAdd);

                    setAnnouncementAnimals(tempAnimals);
                    setSalesTypeParameterCode(-1);
                    setEuIdentifier('');
                    setSelectedSex(-1);
                    setBirthDate(null);
                    if (announcementData) {
                        announcementData.Batches = [
                            ...announcementData.Batches,
                            ...mapAnimalsToAnimalAnnouncement([animalToAdd]).Batches.map((b) => {
                                if (animalToAdd.SalesType === SalesType.Pikalehmä) return b;
                                b.CollectWeekStart = { Year: -1, Week: -1 };
                                b.CollectWeekEnd = { Year: -1, Week: -1 };
                                return b;
                            }),
                        ];
                    }

                    const tempAnimalsAdded = [...animalsAddedAndNotSaved];
                    tempAnimalsAdded.push(animalToAdd.EuIdentifier);
                    setAnimalsAddedAndNotSaved(tempAnimalsAdded);
                })
                .catch((err) => {})
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const showSuccessMessage = () => {
        enqueueSnackbar(t('AnelmaGeneral:1018'), {
            variant: 'success',
        });
    };

    const euIdentifierIsValid = (): boolean => euIdentifier.length === 16;

    const validateAnimalToAdd = (): boolean => {
        if (
            !selectedHoldingSiteId ||
            selectedHoldingSiteId === '' ||
            !euIdentifierIsValid() ||
            !selectedSex ||
            isNaN(Number(selectedSex)) ||
            Number(selectedSex) < 0 ||
            !salesTypeParameterCode ||
            isNaN(salesTypeParameterCode) ||
            salesTypeParameterCode < 0 ||
            announcementAnimals.find((a) => a.EuIdentifier === euIdentifier) != null
        )
            return false;

        return true;
    };

    const handleSave = async (withChainInfo: boolean = false, close: boolean = true) => {
        const producerNumber = Number(
            (farmDetails?.Aliases?.find((a) => a.Type === AliasTypeEnum.ProducerNumber) as IAlias)
                .Value
        );

        setIsLoading(true);

        if (props.mode === DialogModeEnum.Create) {
            if (
                isDataValidForCreate(
                    farmDetails?.Id,
                    String(producerNumber),
                    announcementAnimals,
                    collectDateStart,
                    collectDateEnd,
                    selectedHoldingSiteId
                )
            ) {
                const createPayload = mapCreateAnimalAnnouncementData(
                    Number(producerNumber),
                    additionalInformation,
                    (farmDetails as IFarmDetails).Id as string,
                    announcementAnimals,
                    selectedHoldingSiteId,
                    collectDateStart,
                    collectDateEnd,
                    1
                );
                if (props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter) {
                    createSlaughterAnnouncement(createPayload, withChainInfo);
                } else if (
                    props.announcementType === AnnouncementTypeEnum.ManualBovineIntermediation
                ) {
                    createIntermediateAnnouncement(createPayload);
                }
            } else {
                enqueueSnackbar(`${t('AnelmaGeneral:1030')}`, {
                    variant: 'error',
                });
                setIsLoading(false);
                return;
            }
        } else if (props.mode === DialogModeEnum.Edit) {
            if (
                isDataValidForSave(
                    props.mode,
                    farmDetails?.Id,
                    String(producerNumber),
                    announcementData?.Batches,
                    collectDateStart,
                    collectDateEnd,
                    announcementData?.Number,
                    selectedHoldingSiteId
                ) &&
                announcementData &&
                farmDetails?.Id &&
                props.announcementData
            ) {
                var ok = await deleteBatches();
                setIsLoading(false);
                if (!ok) return;

                announcementData.Info = additionalInformation;
                const payload = mapAnimalAnnouncementUpdate(
                    announcementData,
                    props.announcementData.Batches,
                    producerNumber,
                    farmDetails.Id,
                    selectedHoldingSiteId
                );

                if (props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter) {
                    let filteredLoad: IUpdateBovineAnnouncement = payload;
                    for (let i = 0; i < filteredLoad.Additions.length; i++) {
                        if (
                            filteredLoad.Updates.find(
                                (a) => a.EUIdentifier === filteredLoad.Additions[i].EUIdentifier
                            )
                        ) {
                            filteredLoad.Additions = filteredLoad.Additions.filter(
                                (f) => f.EUIdentifier !== filteredLoad.Additions[i].EUIdentifier
                            );
                        }
                    }

                    updateSlaughterAnnouncement(filteredLoad, close, withChainInfo);
                } else if (
                    props.announcementType === AnnouncementTypeEnum.ManualBovineIntermediation
                ) {
                    updateIntermeditionAnnouncement(payload, close);
                }
            } else {
                enqueueSnackbar(`${t('AnelmaGeneral:1030')}`, {
                    variant: 'error',
                });
                setIsLoading(false);
                return;
            }
        }
    };

    const createSlaughterAnnouncement = (
        payload: ICreateAnnouncement,
        withChainInfo: boolean = false
    ) => {
        setIsLoading(true);

        animalAnnouncementApi
            .createManualBovineSlaughterAnnouncement(payload)
            .then((response) => {
                if (!response) {
                    enqueueSnackbar(`${t('AnelmaGeneral:1019')}`, {
                        variant: 'error',
                    });
                    setIsLoading(false);
                    return;
                }

                if (hasAnnouncementResponseErrors(response)) {
                    response.Summary.Errors.map((_) => {
                        enqueueSnackbar(`${_.Explanation}`, {
                            variant: 'error',
                        });
                    });
                    setIsLoading(false);
                    return;
                }
                commitSalesTypeChanges();
                let tempAnimalsCI: IAnimalAnnouncementAnimal[] = [];
                for (var i = 0; i < response.Entity.Batches.length; i++) {
                    tempAnimalsCI.push({
                        AnimalId: response.Entity.Batches[i].AnimalId,
                        BirthDate: response.Entity.Batches[i].BirthDate,
                        Breed: response.Entity.Batches[i].Breed,
                        EuIdentifier: response.Entity.Batches[i].EUIdentifier,
                        SalesType: response.Entity.Batches[i].SalesType,
                        Sex: response.Entity.Batches[i].Sex,
                    });
                }
                setAnnouncementAnimalsForChainInfo(tempAnimalsCI);

                showSuccessMessage();

                const newAnnouncement = response.Entity;
                if (newAnnouncement) {
                    props.processAnnouncementSave(
                        response,
                        AnnouncementTypeEnum.ManualBovineSlaughter,
                        selectedHoldingSiteId
                    );

                    handleNewAnnouncementSave(newAnnouncement, withChainInfo);
                }
            })
            .catch((err) => {
                showErrors(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const showErrors = (errors: any) => {
        if (errors?.length > 0) {
            for (let err of errors) {
                if (err.Explanation) {
                    enqueueSnackbar(err.Explanation, {
                        variant: 'error',
                    });
                }
            }
        }
    };

    const createIntermediateAnnouncement = (payload: ICreateAnnouncement) => {
        setIsLoading(true);

        animalAnnouncementApi
            .createManualBovineIntermediationAnnouncement(payload)
            .then((response) => {
                if (!response) {
                    enqueueSnackbar(`${t('AnelmaGeneral:1019')}`, {
                        variant: 'error',
                    });
                    setIsLoading(false);
                    return;
                }

                if (hasAnnouncementResponseErrors(response)) {
                    response.Summary.Errors.map((_) => {
                        enqueueSnackbar(`${_.Explanation}`, {
                            variant: 'error',
                        });
                    });
                    setIsLoading(false);
                    return;
                }
                commitSalesTypeChanges();
                showSuccessMessage();

                const newAnnouncement = response.Entity;

                if (newAnnouncement) {
                    props.processAnnouncementSave(
                        response,
                        AnnouncementTypeEnum.ManualBovineIntermediation,
                        selectedHoldingSiteId
                    );

                    handleNewAnnouncementSave(newAnnouncement);
                }
            })
            .catch((err) => {
                showErrors(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateSlaughterAnnouncement = (
        payload: IUpdateBovineAnnouncement,
        close: boolean = true,
        withChainInfo: boolean
    ) => {
        setIsLoading(true);

        animalAnnouncementApi
            .updateManualBovineSlaughterAnnouncement(payload)
            .then((response) => {
                if (response) {
                    if (hasAnnouncementResponseErrors(response)) {
                        response.Summary.Errors.map((_) => {
                            enqueueSnackbar(`${_.Explanation}`, {
                                variant: 'error',
                            });
                        });
                        return;
                    }
                    showSuccessMessage();

                    const addedNewAnimals = animalsAddedAndNotSaved.filter((a) =>
                        announcementAnimals.find((aa) => aa.EuIdentifier === a)
                    );

                    if (payload.Additions.length > 0) {
                        response.Entity.Status = '1';
                    }
                    props.processAnnouncementSave(
                        response,
                        AnnouncementTypeEnum.ManualBovineSlaughter,
                        selectedHoldingSiteId
                    );

                    if (
                        animalsAddedAndNotSaved.length > 0 &&
                        addedNewAnimals?.length > 0 &&
                        withChainInfo
                    ) {
                        let animals: IAnimalAnnouncementAnimal[] = [];
                        for (let i = 0; i < response.Entity.Batches.length; i++) {
                            let animal: IAnimalAnnouncementAnimal = {
                                AnimalId: response.Entity.Batches[i].AnimalId,
                                BirthDate: response.Entity.Batches[i].BirthDate,
                                Breed: response.Entity.Batches[i].Breed,
                                EuIdentifier: response.Entity.Batches[i].EUIdentifier,
                                SalesType: response.Entity.Batches[i].SalesType,
                                Sex: response.Entity.Batches[i].Sex,
                            };
                            animals.push(animal);
                        }
                        setAnnouncementAnimalsForChainInfo(animals);
                        setChainInformationDialogVisible(true);
                    } else props.onClose();
                } else {
                    // showGeneralError('');
                }
            })
            .catch((err) => {
                showErrors(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateIntermeditionAnnouncement = (
        payload: IUpdateBovineAnnouncement,
        close: boolean = true
    ) => {
        setIsLoading(true);

        animalAnnouncementApi
            .updateManualBovineIntermediationAnnouncement(payload)
            .then((response) => {
                if (response) {
                    if (hasAnnouncementResponseErrors(response)) {
                        response.Summary.Errors.map((_) => {
                            enqueueSnackbar(`${_.Explanation}`, {
                                variant: 'error',
                            });
                        });
                        return;
                    }
                    showSuccessMessage();
                    if (payload.Additions.length > 0) {
                        response.Entity.Status = '1';
                    }
                    props.processAnnouncementSave(
                        response,
                        AnnouncementTypeEnum.ManualBovineIntermediation,
                        selectedHoldingSiteId
                    );

                    if (close) props.onClose();
                } else {
                    // showGeneralError('');
                }
            })
            .catch((err) => {
                showErrors(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleNewAnnouncementSave = (
        announcement: IUpdateAnnouncementResult,
        withChainInformation: boolean = false
    ) => {
        setAnnouncementNumber(String(announcement.AnnouncementNumber));
        setAnnouncementId(announcement.AnnouncementId);

        if (
            withChainInformation &&
            props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter
        ) {
            setChainInformationDialogVisible(true);
        } else {
            props.onClose();
        }
    };

    const canSave = (): boolean => {
        const isHoldingSiteValid = () => holdingSiteHasValue(selectedHoldingSiteId);
        const isCollectionWeekValid = () =>
            collectWeekEndHasValue(collectDateEnd) &&
            collectWeekStartHasValue(collectDateStart) &&
            batchesHaveValidCollectWeek(announcementData?.Batches ?? [], props.mode);
        const isValid = validator.valid;

        return (
            isValid &&
            announcementAnimals.length > 0 &&
            isHoldingSiteValid() &&
            isCollectionWeekValid()
        );
    };

    const handleDialogClose = () => {
        if (props.readOnly === true) {
            props.onClose();
        } else {
            setConfirmationDialogClose(true);
        }
    };

    let footerSlaughterActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => handleDialogClose(),
                type: 'cancel',
            },
        ],
        right: canViewBovineSlaughterAnnouncementSaveAsAdmin(auth)
            ? [
                  {
                      onClick: handleSave,
                      type: 'save',
                      label: t('AnelmaBovine:1132'),
                      disabled: isLoading || readOnlyMode || !canSave(),
                  },
              ]
            : [
                  {
                      onClick: () => handleSave(true),
                      type: 'save',
                      label: t('AnelmaBovine:1131'),
                      disabled: isLoading || readOnlyMode || !canSave(),
                  },
              ],
    };

    if (props.mode === DialogModeEnum.Edit) {
        footerSlaughterActionButtons.left.push({
            label: readOnlyMode ? t('AnelmaBovine:1158') : t('AnelmaBovine:1050'),
            icon: readOnlyMode ? <Search /> : <Edit />,
            style: { margin: 'auto' },
            onClick: () => setChainInformationDialogVisible(true),
            type: 'default',
            disabled: props.chainInformationFound ? true : false,
        });
    }

    const footerBrokerSaleActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => handleDialogClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: handleSave,
                type: 'save',
                disabled: isLoading || readOnlyMode || !canSave(),
            },
        ],
    };

    const renderHoldingSiteComponent = () => {
        if (
            props.mode === DialogModeEnum.Create &&
            (selectedHoldingSiteId === '' || selectedHoldingSiteId === null) &&
            holdingSiteItems.length > 0
        ) {
            return (
                <Grid item xs={8} sm={8} md={8} lg={8}>
                    <AAutocomplete
                        id={Strings.AnnouncementHoldingSiteId}
                        items={holdingSiteItems}
                        label={t('AnelmaBovine:1063')}
                        onChange={(v) => setSelectedHoldingSiteId(v?.toString() ?? '')}
                        validator={validator}
                        required
                        value={selectedHoldingSiteId}
                        error={!holdingSiteHasValue(selectedHoldingSiteId)}
                    />
                </Grid>
            );
        } else if (
            props.mode === DialogModeEnum.Create &&
            (selectedHoldingSiteId !== '' || selectedHoldingSiteId !== null)
        ) {
            return (
                <Grid item xs={8} sm={8} md={8} lg={8}>
                    <ATextInput
                        id={Strings.AnnouncementHoldingSiteId}
                        label={t('AnelmaBovine:1063')}
                        onChange={(v) => false}
                        validator={validator}
                        disabled
                        value={selectedHoldingSiteId}
                        lengthBoundaryName='AnelmaCompany:HoldingNumberLength'
                    />
                </Grid>
            );
        }
        return (
            <Grid item xs={4} sm={4} md={4} lg={4}>
                <ATextInput
                    id={Strings.AnnouncementHoldingSiteId}
                    label={t('AnelmaBovine:1063')}
                    onChange={(v) => false}
                    validator={validator}
                    disabled
                    value={selectedHoldingSiteId}
                    lengthBoundaryName='AnelmaCompany:HoldingNumberLength'
                />
            </Grid>
        );
    };

    const renderCollectWeekSelectionInputs = () => {
        return props.mode === DialogModeEnum.Create && hideCollectWeeks === false ? (
            <>
                <Grid container direction='column'>
                    <ALabel className='anelma-animal-announcement-general-label'>
                        {props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter
                            ? `${t('AnelmaBovine:1043')}`
                            : `${t('AnelmaBovine:1164')}`}
                    </ALabel>
                </Grid>
                <Grid
                    item
                    xs={collectWeekGridSize.Xs}
                    sm={collectWeekGridSize.Sm}
                    md={collectWeekGridSize.Md}
                    lg={collectWeekGridSize.Lg}
                >
                    <ADropdown
                        id={Strings.AnnouncementCollectWeekStartNumber}
                        onChange={(v) =>
                            setCollectDateStart(JSON.parse(v as string) as ICollectWeek)
                        }
                        value={JSON.stringify(
                            collectDateStart.Week === -1 && collectDateStart.Year === -1
                                ? { Year: '', Week: '' }
                                : collectDateStart
                        )}
                        items={mapCollectWeeksForDropdown(collectWeeks)}
                        validator={validator}
                        required
                        disabled={readOnlyMode}
                        error={!collectWeekStartHasValue(collectDateStart)}
                    />
                </Grid>
                <Grid
                    item
                    xs={collectWeekGridSize.Xs}
                    sm={collectWeekGridSize.Sm}
                    md={collectWeekGridSize.Md}
                    lg={collectWeekGridSize.Lg}
                >
                    <ADropdown
                        id={Strings.AnnouncementCollectWeekEndNumber}
                        onChange={(v) => setCollectDateEnd(JSON.parse(v as string) as ICollectWeek)}
                        value={JSON.stringify(
                            collectDateEnd.Week === -1 && collectDateEnd.Year === -1
                                ? { Year: '', Week: '' }
                                : collectDateEnd
                        )}
                        items={mapCollectWeeksForDropdown(collectWeeks)}
                        validator={validator}
                        required
                        disabled={readOnlyMode}
                        error={!collectWeekEndHasValue(collectDateEnd)}
                    />
                </Grid>
            </>
        ) : null;
    };

    return (
        <ADialog open onClose={props.onClose} disableEnforceFocus>
            {props.mode === DialogModeEnum.Create ? (
                <ADialogTitle>
                    {props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter
                        ? t('AnelmaBovine:1020')
                        : t('AnelmaBovine:1021')}
                    {closeIcon()}
                </ADialogTitle>
            ) : (
                <ADialogTitle>
                    {props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter
                        ? t('AnelmaBovine:1044')
                        : t('AnelmaBovine:1057')}
                    {closeIcon()}
                </ADialogTitle>
            )}

            <ADialogContent size={'lg'} isLoading={isLoading}>
                {salesTypeAnnotations.length > 0 ? (
                    <Annotation Annotations={salesTypeAnnotations} />
                ) : null}
                <Grid container direction='row'>
                    {props.mode === DialogModeEnum.Create ? (
                        // According to planning, should be empty when creating
                        <Grid
                            item
                            xs={announcementNumberGridSize.Xs}
                            sm={announcementNumberGridSize.Sm}
                            md={announcementNumberGridSize.Md}
                            lg={announcementNumberGridSize.Lg}
                        >
                            <ATextInput
                                id={Strings.AnnouncementNumber}
                                label={t('AnelmaBovine:1028')}
                                onChange={(v) => null} // changed from Tiltu only, we cannot change the value
                                validator={validator}
                                disabled
                                value={announcementNumber ?? ''}
                            />
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={announcementNumberGridSize.Xs}
                            sm={announcementNumberGridSize.Sm}
                            md={announcementNumberGridSize.Md}
                            lg={announcementNumberGridSize.Lg}
                        >
                            <ATextInput
                                id={Strings.AnnouncementNumber}
                                label={t('AnelmaBovine:1028')}
                                onChange={(v) => null} // changed from Tiltu only, we cannot change the value
                                validator={validator}
                                disabled
                                value={announcementNumber ?? ''}
                            />
                        </Grid>
                    )}
                    {renderHoldingSiteComponent()}
                    {renderCollectWeekSelectionInputs()}
                    {boundariesLoaded && (
                        <Grid item xs={12} sm={12} md={10} lg={8}>
                            <ATextInput
                                id={Strings.AnnouncementAdditionalInformation}
                                label={t('AnelmaBovine:1039')}
                                onChange={(v) => setAdditionalInformation(v)}
                                value={additionalInformation}
                                validator={validator}
                                maxLength={addInfoMaxLength}
                                minLength={addInfoMinLength}
                                disabled={readOnlyMode}
                            />
                        </Grid>
                    )}

                    <Grid
                        container
                        className='anelma-animal-announcement-add-animal-txt-placeholder'
                    >
                        <ALabel>{t('AnelmaBovine:1053')}</ALabel>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <AEUIdentifierInput
                                id={Strings.AnnouncementEUIdentifier}
                                forceValidEmpty={true}
                                label={t('AnelmaBovine:1005')}
                                onChange={(v) => setEuIdentifier(v.toLocaleUpperCase())}
                                value={euIdentifier}
                                validator={validator}
                                required
                                disabled={readOnlyMode}
                                error={
                                    announcementAnimals.find(
                                        (a) => a.EuIdentifier === euIdentifier
                                    ) != null
                                }
                            />
                        </Grid>

                        {props.announcementType ===
                            AnnouncementTypeEnum.ManualBovineIntermediation && (
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <ADateInput
                                    id={Strings.AnnouncementAnimalBirthday}
                                    label={t('AnelmaBovine:1011')}
                                    onChange={(d) => {
                                        if (moment(d).isValid()) setBirthDate(moment(d));
                                        else setBirthDate(null);
                                    }}
                                    validator={validator}
                                    value={birthDate?.toDate() || null}
                                    disabled={readOnlyMode}
                                    maxDate={new Date()}
                                />
                            </Grid>
                        )}

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ARadioButtons
                                id={Strings.AnnouncementAnimalSex}
                                label={t('AnelmaBovine:1009')}
                                items={mapParameters(bovineSexParams)}
                                onChange={(v) => setSelectedSex(v as number)}
                                validator={validator}
                                value={selectedSex > -1 ? selectedSex : null}
                                disabled={readOnlyMode}
                                error={selectedSex === -1 && euIdentifier.length === 16}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ADropdown
                                id={Strings.AnnouncementSalesTypeDropdown}
                                label={t('AnelmaBovine:1019')}
                                items={mapParameters(salesTypeParams)}
                                onChange={(v) => setSalesTypeParameterCode(Number(v))}
                                validator={validator}
                                value={salesTypeParameterCode === -1 ? '' : salesTypeParameterCode}
                                disabled={readOnlyMode}
                                error={salesTypeParameterCode === -1 && euIdentifier.length === 16}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{ marginTop: '10px' }}>
                            <AInputContainer>
                                <AAddButton
                                    type={'default'}
                                    onClick={handleAddAnimal}
                                    children={t('AnelmaBovine:1054')}
                                    disabled={!validateAnimalToAdd()}
                                />
                            </AInputContainer>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction='row'>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ManualAnnouncementAnimalsGrid {...animalsDataGridProps} />
                    </Grid>
                </Grid>
            </ADialogContent>

            <ADialogActions
                buttons={
                    props.announcementType === AnnouncementTypeEnum.ManualBovineSlaughter
                        ? footerSlaughterActionButtons
                        : footerBrokerSaleActionButtons
                }
            />
            <div
                style={{
                    flex: '0 0 auto',
                    display: 'flex',
                    padding: '8px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <ADefaultButton
                    onClick={() => setIsSalesTypeDialogVisible(true)}
                    children={t('AnelmaBovine:1098')}
                    disabled={isLoading || readOnlyMode}
                />
                <ADefaultButton
                    onClick={() => setIsModifyCollectWeekDialogVisible(true)}
                    children={t('AnelmaBovine:1099')}
                    disabled={isLoading || props.mode === DialogModeEnum.Create || readOnlyMode}
                />
            </div>

            {chainInformationDialogVisible && announcementNumber && announcementId && (
                <ChainInformationDialog
                    onClose={() => props.onClose()}
                    announcementData={announcementData}
                    announcementId={announcementId}
                    announcementNumber={announcementNumber}
                    animals={announcementAnimalsForChainInfo}
                    forceReadOnly={props.readOnly}
                    newAnnouncement={props.mode === DialogModeEnum.Create}
                />
            )}

            {confirmationDialogClose && (
                <ConfirmDialogClose
                    confirmation='AnelmaGeneral:1065'
                    onCancel={() => setConfirmationDialogClose(false)}
                    onClose={() => props.onClose()}
                    open={confirmationDialogClose}
                />
            )}
        </ADialog>
    );
}
