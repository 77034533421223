// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../../core/resources';
import FormValidator from '../../../core/FormValidator';

// Common
import { ADialog, ADialogContent, ADialogTitle, ADialogActions } from '../../../common/dialog';
import { AInputItem, AMultiselect } from '../../../common/inputs';

// Interfaces
import { GUIDType } from '../../../interfaces/types';

// Communication - general
import { capitalizeFirstLetter } from '../helpers/helperFunctions';

export interface IAddUserToConversationDialogProps {
    onClose: () => void;
    onUpdate: <T extends string[]>(tail: T) => void;
    open: boolean;
    producerRecipientOptions: AInputItem<string>[];
}

export default function AddUserToConversationDialog(props: IAddUserToConversationDialogProps) {
    const [producerRecipients, setProcuderRecipients] = useState<GUIDType[]>([]);

    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
        'AnelmaLayout',
    ]);
    const validator = new FormValidator();

    return (
        <ADialog open={props.open}>
            <ADialogTitle>{t('AnelmaCommunication:1123')}</ADialogTitle>

            <ADialogContent size='sm'>
                <Grid container direction='row'>
                    <Grid item sm={12}>
                        <AMultiselect
                            label={capitalizeFirstLetter(t('AnelmaCommunication:1073'))}
                            getOptionLabel={(o) => o.text}
                            id='recipient'
                            items={props.producerRecipientOptions}
                            keyProp={'value'}
                            onChange={(v) => setProcuderRecipients(v as string[])}
                            required
                            validator={validator}
                            value={producerRecipients}
                        ></AMultiselect>
                    </Grid>
                </Grid>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => {
                                props.onClose();
                                setProcuderRecipients([]);
                            },
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => {
                                props.onUpdate(producerRecipients);
                                props.onClose();
                                setProcuderRecipients([]);
                            },
                            type: 'save',
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
