import { RoutePaths } from '../../../../interfaces/enums';
import { IMainMenuItem } from '../mainMenu/IMainMenu';

export const animalInfo: IMainMenuItem = {
    itemGroups: [
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.AnimalsList,
                    resource: 'AnelmaBovine',
                    scope: 'LIST',
                    textCode: 1010,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.AnimalAnnouncements,
                    resource: 'AnelmaBovineNotificationList',
                    scope: 'LIST',
                    textCode: 1078,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.AnimalsPayloads,
                    resource: 'AnelmaAnimalPayload',
                    scope: 'LIST',
                    textCode: 1028,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.AnimalsProductionPlans,
                    resource: 'AnelmaBovine',
                    scope: 'LIST',
                    textCode: 1075,
                },
            ],
        },
        // {
        //     headerCode: null,
        //     items: [
        //         {
        //             href: RoutePaths.AnimalBreeding,
        //             resource: 'AnelmaBreedingContract',
        //             scope: 'LIST',
        //             textCode: 1055,

        //             // resource: 'AnelmaUnimplemented',
        //             // scope: 'LIST',
        //             // text: '#Kasvatussopimukset',
        //         },
        //     ],
        // },
    ],
    textCode: 1027,
};
