// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../../common/dialog';

// Core
import { ResourceTextApplication } from '../../../core/resources';

export interface AreYouSureDialogProps {
    name: string;
    content: string;
    title: string;
    onClose: () => any;
    deleteRow: () => any;
}

export default function ConfirmDialog(props: AreYouSureDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
    ]);

    const buttons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                label: t('AnelmaGeneral:1034'),
                onClick: () => {
                    props.onClose();
                },
            },
        ],
        right: [
            {
                type: 'ok',
                label: t('AnelmaGeneral:1033'),
                onClick: () => {
                    props.deleteRow();
                    props.onClose();
                },
            },
        ],
    };

    return (
        <ADialog open onClose={() => props.onClose()}>
            <ADialogTitle>{t(props.title)}</ADialogTitle>
            <ADialogContent size='md'>
                <Typography variant={'body1'} style={{ padding: '30px 60px', textAlign: 'center' }}>
                    {t(props.content).replace('{0}', props.name)}
                </Typography>
            </ADialogContent>

            <ADialogActions buttons={buttons} />
        </ADialog>
    );
}
