export enum IVariableTypeEnum {
    Undefined = 0,
    Slaughter = 1, //Teurasmuuttuja
    Purchase = 2, //Välitysmuuttuja
}

export enum IVariableGroupBovineEnum {
    Bull = 1, //Sonnit
    Heifer = 2, //Hiehot
}

export interface IBovineVariables {
    FarmGUID: string;
    VariableGroup: IVariableGroup[];
}

export interface IVariableGroup {
    Modificator: string;
    ModificationDate: string;
    VariableType: number;
    VariablesList: IVariablesList[];
}

export interface IVariablesList {
    Variable: number;
    Item: IItem[];
}

export interface IItem {
    VariableGroupBovine: number;
    VariableValue: number;
}


export interface IBovineVariablesPayload {
    FarmGUID: string;
    VariableType: number;
    VariablesList: IVariablesList[];
}
