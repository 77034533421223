// Libraries
import { mergeRight } from 'ramda';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../core/resources';
import utils from '../../core/utils';

// Common
import { ADataGrid, AGridColumns, gridColumns } from '../dataGrid';
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../dialog';
import { TextsWithTooltip, TextWithTooltip } from '../typography';

// Interfaces
import { IPredefinedSearch, IPredefinedSearchFarmResult } from '../../interfaces/ISearchParameters';
import { IProductionLine } from '../../interfaces/IBusinessEntities';
import { IAnnouncementSearch } from '../../interfaces/ICommunication';

// Feature
import {
    GetProductionLineFromIntList,
    GetSpeciesFronIntList,
} from '../../searchParameters/Helpers/Parsers';
import { IParameterExtension } from '../../searchParameters/shared/styles/types/subTypes';

interface IProps {
    onClose: () => any;
    items: IPredefinedSearchFarmResult[];
    parameters: IParameterExtension[];
    personParameters: IParameterExtension[];
    titleParameter: 'AnelmaCommunication:1033' | 'AnelmaBulletin:1032' | 'AnelmaCommunication:1150';
    usePersonsConnectedToTheFarm: boolean;
    communicationGroups: IPredefinedSearch[];
    announcementSearches: IAnnouncementSearch[];
    addingNewCommunicationGroupName: string;
}

export default function Recipients({
    onClose,
    items,
    parameters,
    personParameters,
    titleParameter,
    usePersonsConnectedToTheFarm,
    communicationGroups,
    announcementSearches,
    addingNewCommunicationGroupName,
}: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
    ]);

    const buttons: ADialogButtonsProp = {
        left: [
            {
                type: 'default',
                label: t('AnelmaGeneral:1009'),
                onClick: onClose,
            },
        ],
        right: [],
    };

    const getCommunicationGroupName = (
        oneItem: IPredefinedSearchFarmResult,
        announcementSearches: IAnnouncementSearch[],
        communicationGroups: IPredefinedSearch[]
    ) => {
        let searchGUID: string | undefined = '';
        let communicationGroupName: string | undefined = '';
        let communicationGroupGUID: string = '';

        searchGUID = announcementSearches.find((e) => e.UserGUID === oneItem.UserId)?.SearchGUID;
        if (searchGUID) {
            communicationGroupGUID = searchGUID;
        } else {
            communicationGroupGUID = '';
        }
        if (communicationGroups.length > 0) {
            communicationGroupName = communicationGroups.find(
                (e) => e.GUID === communicationGroupGUID
            )?.Name;
        } else {
            communicationGroupName = '';
        }
        return communicationGroupName;
    };

    let outItems: IPredefinedSearchFarmResult[] = [];

    const itemsForRows = (
        items: IPredefinedSearchFarmResult[],
        personParameters: IParameterExtension[]
    ) => {
        const titleOptions = personParameters.find((i) => i.name === 'Titles')?.options;

        switch (titleParameter) {
            case 'AnelmaCommunication:1033':
            case 'AnelmaCommunication:1150':
                for (const oneItem of items) {
                    for (const onePerson of oneItem.ContactPersons) {
                        const roles: string[] = [];
                        for (const oneRole of onePerson.Roles) {
                            const roleCode = oneRole.Type.toString();
                            const roleText = titleOptions?.find((i) => i.code === roleCode)?.text;
                            roles.push(roleText as string);
                        }

                        const names = { RecipientName: onePerson.Name, RecipientTitle: roles };

                        if (usePersonsConnectedToTheFarm) outItems.push({ ...oneItem, ...names });
                    }
                    if (!usePersonsConnectedToTheFarm) outItems.push(oneItem);
                }
                break;

            case 'AnelmaBulletin:1032':
                for (const oneItem of items) {
                    const communicationGroupNameFromDB = getCommunicationGroupName(
                        oneItem,
                        announcementSearches,
                        communicationGroups
                    );

                    if (usePersonsConnectedToTheFarm) {
                        const roles: string[] = [];
                        if (oneItem.Roles) {
                            for (const oneRole of oneItem.Roles) {
                                const roleCode = oneRole.Type.toString();
                                const roleText = titleOptions?.find(
                                    (i) => i.code === roleCode
                                )?.text;
                                roles.push(roleText as string);
                            }
                        }
                        outItems.push({
                            RecipientName: oneItem.RecipientName,
                            FarmGuid: oneItem.FarmGuid,
                            ProducerId: oneItem.ProducerId,
                            FarmId: '',
                            Name: oneItem.Name,
                            ContactPersons: [],
                            ProductionLines: oneItem.ProductionLines,
                            RecipientTitle: roles,
                            CommunicationGroup: communicationGroupNameFromDB
                                ? communicationGroupNameFromDB
                                : addingNewCommunicationGroupName,
                        });
                    } else {
                        outItems.push({
                            FarmGuid: oneItem.FarmGuid,
                            ProducerId: oneItem.ProducerId,
                            FarmId: '',
                            Name: oneItem.Name,
                            ContactPersons: [],
                            ProductionLines: oneItem.ProductionLines,
                            CommunicationGroup: communicationGroupNameFromDB
                                ? communicationGroupNameFromDB
                                : addingNewCommunicationGroupName,
                        });
                    }
                }
                break;
        }
    };

    const getTitle = () => {
        itemsForRows(items, personParameters);
        switch (titleParameter) {
            case 'AnelmaCommunication:1033':
                return (
                    <ADialogTitle>
                        {t(titleParameter).replace('{count}', items.length.toString())}
                    </ADialogTitle>
                );
            case 'AnelmaCommunication:1150':
                return (
                    <ADialogTitle>
                        {t(titleParameter).replace('{count}', outItems.length.toString())}
                    </ADialogTitle>
                );
            case 'AnelmaBulletin:1032':
                return (
                    <ADialogTitle>
                        {t(titleParameter, {
                            RecieverCount: outItems.length.toString(),
                        })}
                    </ADialogTitle>
                );
        }
    };

    const resolveColumns = () => {
        const columns: AGridColumns = [];

        if (
            titleParameter === 'AnelmaBulletin:1032' ||
            titleParameter === 'AnelmaCommunication:1150'
        ) {
            if (usePersonsConnectedToTheFarm) {
                columns.push(
                    gridColumns.withTooltip({
                        field: 'RecipientName',
                        headerName: t('AnelmaCommunication:1148'),
                        width: 150,
                    }),
                    {
                        field: 'RecipientTitle',
                        headerName: t('AnelmaCommunication:1149'),
                        renderCell: (params) => {
                            return <TextsWithTooltip texts={params.value as string[]} />;
                        },
                        width: 150,
                    }
                );
            }
        }

        columns.push(
            gridColumns.producerNumber(t('AnelmaCommunication:1078'), 'ProducerId', 100),
            {
                field: 'Name',
                headerName: t('AnelmaCommunication:1047'),
                renderCell: (params) => <TextWithTooltip text={params.value as string} />,
                width: 200,
            },
            {
                field: 'FarmingTypes',
                headerName: t('AnelmaCommunication:1048'),
                renderCell: (params) => {
                    return <TextsWithTooltip texts={params.value as string[]} />;
                },
                valueGetter: (params) => {
                    return GetProductionLineFromIntList(params.row.ProductionLines, parameters);
                },
                width: 200,
            }
        );

        if (titleParameter === 'AnelmaBulletin:1032') {
            columns.push({
                field: 'CommunicationGroup',
                headerName: t('AnelmaCommunication:1036'),
                renderCell: (params) => {
                    return <TextWithTooltip text={params.row.CommunicationGroup as string} />;
                },
                width: 200,
            });
        }

        columns.push({
            field: 'Type',
            headerName: t('AnelmaCommunication:1079'),
            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params) =>
                params.row.ProductionLines.map((_: IProductionLine) =>
                    GetSpeciesFronIntList([_.Type], parameters)
                ),
            width: 150,
        });

        return columns;
    };

    return (
        <ADialog open onClose={onClose} disableEnforceFocus>
            {getTitle()}

            <ADialogContent size={usePersonsConnectedToTheFarm ? 'xl' : 'lg'}>
                <ADataGrid
                    columns={resolveColumns()}
                    enableFiltering
                    enableRowNumbers
                    rows={outItems.map((_) => mergeRight(_, { id: utils.generateUniqueId() }))}
                />
            </ADialogContent>

            <ADialogActions buttons={buttons} />
        </ADialog>
    );
}
