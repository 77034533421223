// MUI
import { styled } from '@mui/material';

export interface ICommunicationInputContainerProps {
    children: JSX.Element;
}

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    marginBottom: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
}));

export default function CommunicationInputContainer(props: ICommunicationInputContainerProps) {
    return <Wrapper>{props.children}</Wrapper>;
}
