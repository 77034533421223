export const isFromDateValid = (from: moment.Moment | null): boolean => {
    return from ? true : false;
}

export const isToDateValid = (to: moment.Moment | null): boolean => {
    return to ? true : false;
}

export const isNoteValueValid = (notes: string): boolean => {
    return notes && notes !== '' ? true : false;
}

export const isSubjectValid = (subject: number): boolean => {
    return subject && subject > 0 ? true : false;
}

export const isContactPersonValid = (contactPerson: number | null): boolean => {
    return contactPerson ? true : false;
}