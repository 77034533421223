// MUI
import { Container, Grid } from '@mui/material';

// Content
import RenderContent from '../RenderContent';
import { EditableContentType } from '../IEditableContent';

export interface PublicContentViewProps {
    type: EditableContentType;
}

export default function PublicContentView(props: PublicContentViewProps) {
    return (
        <Container style={{ marginTop: '60px' }} data-robot-id = {"app-body-"+props.type}>
            <Grid container>
                <Grid item xs={12}>
                    <RenderContent displayLoader publicSite type={props.type} />
                </Grid>
            </Grid>
        </Container>
    );
}
