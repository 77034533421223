// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADefaultIconButton, AIconButtonBaseProps } from '../../common/buttons';

export interface ADownloadIconButtonProps extends AIconButtonBaseProps {}

export default function ADownloadIconButton(props: ADownloadIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1077');

    return <ADefaultIconButton {...buttonProps} icon={<GetAppOutlinedIcon />} tooltip={tooltip2} />;
}
