// Libraries
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import PersonAdd from '@mui/icons-material/PersonAdd';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AActionIconButton, ADefaultIconButton, AIconButtonBaseProps } from '../buttons';

export interface AAddPersonIconButtonProps extends AIconButtonBaseProps {
    children?: ReactNode;
    type?: 'action' | 'default';
}

export default function AAddPersonIconButton(props: AAddPersonIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { children, type, ...personButtonProps } = props;
    const children2 = children ? children : t('AnelmaGeneral:1072');

    if (type === 'action')
        return (
            <AActionIconButton
                {...personButtonProps}
                icon={<PersonAdd fontSize='large' />}
                tooltip={children2}
            />
        );
    return (
        <ADefaultIconButton
            {...personButtonProps}
            data-robot-id="add-person-button"
            icon={<PersonAdd fontSize='large' />}
            tooltip={children2}
        />
    );
}
