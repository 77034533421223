// Libraries
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Feature
import { Context } from './ContextSelector';

interface SelectedContextProps {
    context?: Context | null;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function SelectedContext(props: SelectedContextProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const classes = ['an-context-selector--selected'];
    const changeable = !!props.onClick;

    if (changeable) classes.push('an-context-selector--selected-changeable');

    return (
        <div  className={classes.join(' ')} onClick={props.onClick}>
            <span className='an-context-selector--selected-name'  data-robot-id= {"selected-context-selector-name"} >
                
                {props.context?.displayName || t('AnelmaGeneral:1149')}
            </span>
            {changeable ? <ArrowDropDownIcon data-robot-id = "arrow-dropdown-icon"/> : undefined}
        </div>
    );
}
