import { configureStore } from '@reduxjs/toolkit';
import authorizationReducer from './authorizationSlice';
import bulletinReducer from '../bulletin/bulletinSlice';
import configurationReducer from './configurationSlice';
import contextReducer from './contextSlice';
import loginStatusReducer from './loginStatusSlice';
import personNameReducer from './personNameSlice';
import profilePictureReducer from './profilePictureSlice';
import userDataReducer from './userDataSlice';
import { interceptor } from '../core/axios-interceptors';

const store = configureStore({
    reducer: {
        authorization: authorizationReducer,
        bulletinMessages: bulletinReducer,
        configuration: configurationReducer,
        context: contextReducer,
        loginStatus: loginStatusReducer,
        personNames: personNameReducer,
        profilePictures: profilePictureReducer,
        userData: userDataReducer,
    },
});
interceptor(store);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
