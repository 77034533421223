// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { Grid } from '@mui/material';
import { ResourceTextApplication } from '../../core/resources';
import FormValidator from '../../core/FormValidator';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../common/dialog';
import { ASwitch } from '../../common/inputs';

// Access management
import { IAccessRole } from '../../accessRightsManagement/IAccessRightsManagement';

export interface AccessRightsDialogProps {
    accessRoles: IAccessRole[];
    onClose: () => void;
    onUpdate: (roles: string[]) => void;
    roles: string[];
}

export default function AccessRolesDialog(props: AccessRightsDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaAccessRights',
        'AnelmaCompany',
        'AnelmaGeneral',
    ]);
    const validator = new FormValidator();

    const [selectedRoles, setSelectedRoles] = useState<string[]>([...props.roles]);

    const updateSelectedList = (role: string, checked: boolean) => {
        const selected = [...selectedRoles];
        if (checked) selected.push(role);
        else {
            const index = selected.findIndex((i) => i === role);
            selected.splice(index, 1);
        }
        setSelectedRoles(selected);
    };

    return (
        <ADialog open={true}>
            <ADialogTitle>{t('AnelmaCompany:1045')}</ADialogTitle>

            <ADialogContent size='md'>
                <Grid container>
                    {props.accessRoles.map((role, index) => {
                        return (
                            <Grid item sm={6} key={index}>
                                <ASwitch
                                    checked={selectedRoles.includes(role.name)}
                                    id={`role-${index}`}
                                    label={t(`AnelmaAccessRights:${role.textCode}`)}
                                    onChange={(v) => updateSelectedList(role.name, v)}
                                    validator={validator}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onClose(),
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => {
                                props.onUpdate(selectedRoles);
                                props.onClose();
                            },
                            type: 'ok',
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
