// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// MUI
import { Container, Grid } from '@mui/material';

// Store
import { useAppSelector } from '../../store/hooks';

// Core
import { paramRepository, ParameterValues, ResourceTextApplication } from '../../core/resources';
import log from '../../core/log';

// Common
import { ViewTitle } from '../../common/typography';
import ViewLoader from '../../common/ViewLoader';
import { contextControl } from '../../common/contextSelector/ContextSelector';
import contextUtils from '../../common/contextSelector/contextUtils';

// Interfaces
import defaultData from '../../core/defaultData';
import { ICompanyFarmId, IFarmDetails } from '../../interfaces/IBusinessEntities';
import { AliasTypeEnum, AnimalTypeEnum } from '../../interfaces/enums';
import { IFarmVisit, IFarmVisitor } from '../../interfaces/IFarmVisit';
import { GUIDType } from '../../interfaces/types';

// Api
import companyApi from '../../api/companyApi';
import { snellmanContactPersons } from '../../publicContactPersons/publicContactPersonsApi';
import FarmVisitAPI from '../../api/farmVisitApi';

// Feature
import BasicInfo from './BasicInfo';
import CoopInfo from './CoopInfo';
import PersonelInfo from './PersonelInfo';
import ProductionInfo from './ProductionInfo';
import MyFarmVisit from '../../farmVisit/MyFarmVisit';
import SlaughterVariablesView, {
    SlaughterVariablesViewProps,
} from '../../slaughterVariables/SlaughterVariablesView';
import { mapFarmVisit } from '../../farmVisit/helpers/data';
import { IFarmVisitNotificationLocationData } from '../../farmVisit/IFarmVisitNotificationLocationData';
import { isNotNil } from 'ramda';
import { enqueueSnackbar } from 'notistack';

export interface FarmInfoSectionProps {
    bovineProduction: boolean;
    data: IFarmDetails;
    getParamText: (param: ParameterValues, code: number | string | null) => string;
    porkProduction: boolean;
}

export default function FarmInfo() {
    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const context = useAppSelector((state) => state.context);
    const location = useLocation();

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany', 'AnelmaLayout']);

    const [data, setData] = useState<IFarmDetails | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [farmVisit, setFarmVisit] = useState<IFarmVisit>(defaultData.farmVisit());
    const [myFarmVisits, setMyFarmVisits] = useState<IFarmVisit[]>([]);
    const [visitors, setVisitors] = useState<IFarmVisitor[]>([]);
    const [farmVisitLocationData, setFarmVisitLocationData] =
        useState<IFarmVisitNotificationLocationData | null>(initializeFarmVisitLocationData());
    const [companyLocationData] = useState<ICompanyFarmId | null>(initializeCompanyLocationData());

    const sectionProps: FarmInfoSectionProps | null = data
        ? {
              bovineProduction:
                  !!data?.ProductionLines.find((i) => i.Type === AnimalTypeEnum.Bovine)
                      ?.FarmingTypes.length || false,
              data,
              getParamText,
              porkProduction:
                  !!data?.ProductionLines.find((i) => i.Type === AnimalTypeEnum.Pork)?.FarmingTypes
                      .length || false,
          }
        : null;

    const slaughterVariableViewProps: SlaughterVariablesViewProps | null = data
        ? {
              farmId: data?.Id ?? '',
              farmInfo: data,
              adminValues: false,
          }
        : null;

    useEffect(() => {
        if (context.data.currentContext)
            initializeComponentData(context.data.currentContext.context);
        else setData(null);
    }, [context]);

    useEffect(() => {
        if (farmVisitLocationData) handleContextChange(farmVisitLocationData);
    }, [farmVisitLocationData]);

    useEffect(() => {
        if (companyLocationData) handleRoutingFromCompanyNotification(companyLocationData);
    }, [companyLocationData]);

    const initializeComponentData = (ctx: string) => {
        setLoading(true);

        companyApi.getCompanyByContext(ctx).then((response) => {
            const myFarmVisit: IFarmVisit = {
                ...farmVisit,
                FarmGUID: response?.Entity?.Id as GUIDType,
            };
            setLoading(true);
            setFarmVisit(myFarmVisit);
            setData(response?.Entity as IFarmDetails);

            Promise.all([
                fetchSnellmanContactPersons(),
                initialFarmVisitFetch(response?.Entity.Id ?? ''),
                paramRepository.load(['AnelmaCompany', 'AnelmaGeneral']),
            ]).then(() => {
                setLoading(false);
            });
        });
    };

    const initialFarmVisitFetch = (farmId: string) => {
        return FarmVisitAPI.getFarmVisitsByFarmId(farmId).then((response) => {
            setMyFarmVisits(response.Items);
        });
    };

    const fetchSnellmanContactPersons = () => {
        const visitorCollection: IFarmVisitor[] = [];
        return snellmanContactPersons(!!loginStatus.loggedIn).then((data) => {
            if (!data) {
                log('info', `'snellmanContactPersons' response returned no results.`);
                return;
            }
            data.Items.forEach((person) => {
                visitorCollection.push({
                    Id: person.Id,
                    FullName: person.Name,
                });
            });
            setVisitors(visitorCollection);
        });
    };

    /** Get company details and possibly change context selector's context when tilatunnus in the current selected context differs from farm visit notification's
     * @param locationData
     */
    const handleContextChange = (locationData: IFarmVisitNotificationLocationData) => {
        companyApi.getCompanyByGuid(locationData.FarmId).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                    variant: 'error',
                });
                return;
            }

            // Not farm's uuid but tilatunnus (tiltu or whatever)
            const farmId = response.Entity.Aliases.find(
                (x) => x.Type === AliasTypeEnum.FarmId
            )?.Value;

            if (
                isNotNil(farmId) &&
                context.data.currentContext &&
                contextUtils.parseContextString(context.data.currentContext.context).farmId !==
                    farmId
            )
                contextControl.setFarm(farmId);
        });
    };

    const handleRoutingFromCompanyNotification = (data: ICompanyFarmId) => {
        if (
            context.data.currentContext &&
            contextUtils.parseContextString(context.data.currentContext.context).farmId !==
                data.FarmId
        )
            contextControl.setFarm(data.FarmId);
    };

    function initializeFarmVisitLocationData() {
        return location.state !== null ? location.state : null;
    }

    function initializeCompanyLocationData() {
        return isNotNil(location.state) &&
            location.state?.options?.Id &&
            location.state?.options?.FarmId
            ? ({
                  Id: location.state.options.Id,
                  FarmId: location.state.options.FarmId,
              } as ICompanyFarmId)
            : null;
    }

    function getParamText(param: ParameterValues, code: number | string | null): string {
        if (!code) return '';
        return param.find((i) => i.code === `${code}`)?.text || `${code}`;
    }

    const mapFarmVisitToAllFarmVisitData = (farmVisit: IFarmVisit) => {
        const idx = myFarmVisits.findIndex((_) => _.Id === farmVisit.Id);

        if (idx !== -1) {
            const updatedState = [...myFarmVisits];
            if (farmVisit.Attachments && farmVisit.Attachments.length > 0)
                setMyFarmVisits(mapFarmVisit(farmVisit, updatedState, idx));
            else {
                farmVisit.CompanyName = updatedState[idx].CompanyName;
                farmVisit.ProducerNumber = updatedState[idx].ProducerNumber;
                updatedState[idx] = farmVisit;
                setMyFarmVisits(updatedState);
            }
        } else setMyFarmVisits((prev) => [...prev, farmVisit]);
    };

    const onClose = () => {
        setFarmVisitLocationData(null);
        setFarmVisit(defaultData.farmVisit());
    };

    return (
        <Container data-robot-id={'app-body-farminfo'}>
            <ViewTitle>{t('AnelmaLayout:1026')}</ViewTitle>

            {loading ? (
                <ViewLoader />
            ) : (
                <>
                    {sectionProps ? (
                        <>
                            <Grid container>
                                <Grid item md={6}>
                                    <BasicInfo {...sectionProps} />
                                </Grid>
                                <Grid item md={6}>
                                    <PersonelInfo {...sectionProps} />
                                    <br />
                                    <br />
                                    <ProductionInfo {...sectionProps} />
                                    <br />
                                    <br />
                                    <CoopInfo {...sectionProps} />
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Grid item md={12}>
                                <MyFarmVisit
                                    showAddButton={true}
                                    farmDetails={data ? data : undefined} // At least temporary
                                    data={farmVisit}
                                    onClose={() => onClose()}
                                    columns={[]}
                                    visitors={visitors}
                                    handleMapFarmVisit={mapFarmVisitToAllFarmVisitData}
                                    deleteFarmVisitFromList={() => {}}
                                    myFarmVisits={myFarmVisits}
                                    loading={loading}
                                />
                            </Grid>
                            <br />
                            <br />
                            <Grid item md={12}>
                                {slaughterVariableViewProps && (
                                    <SlaughterVariablesView {...slaughterVariableViewProps} />
                                )}
                            </Grid>
                        </>
                    ) : (
                        t('AnelmaCompany:1124')
                    )}
                </>
            )}
        </Container>
    );
}
