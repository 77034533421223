export enum RoutePaths {
    AnimalAnnouncements = '/animals/animal-announcements-list-view',
    AnimalsList = '/animals/list',
    AnimalsProductionPlans = '/animals/productionplans',
    AnimalsPayloads = '/animals/payloads',
    AnimalBreeding = '/animal/breedings',
    DocumentsInfo = '/documents/info',
    DocumentsNews = '/documents/news',
    EconomyAccountings = '/economy/accountings',
    EconomyInvoices = '/economy/invoices',
    EconomyPriceList = '/economy/pricelist',
    EconomyTaxYearSummary = '/economy/tax-year-summary',
    FarmDocuments = '/farm-files',
    FarmVisitDialogEdit = '/farm-visit/edit',
    GeneralCalendar = '/calendar',
    GeneralContacts = '/contacts',
    GeneralDashboard = '/dashboard',
    GeneralFarmInfo = '/farm-info',
    GeneralFarmVisits = '/farm-visit',
    GeneralFeedback = '/feedback',
    GeneralMessaging = '/messages',
    GeneralMyInfo = '/my-info',
    InfoFeeding = '/info/feeding',
    InfoShop = '/info/shop',
    InfoInstructionsAndCode = '/info/instructions',
    InfoLab = '/info/lab',
    InfoMP = '/info/mp',
    InvoiceDetailView = '/invoice',
    ManagementAccessRights = '/management/access-rights',
    ManagementBulletin = '/management/bulletin',
    ManagementCompanySearch = '/management/company-search',
    ManagementContentEditor = '/management/content',
    ManagementEvents = '/management/events',
    ManagementEventAttendees = '/management/event-attendees',
    ManagementLogs = '/management/logs',
    ManagementPersonSearch = '/management/person-search',
    ManagementSearchParameters = '/management/search-parameters',
    ManagementShortMessageService = '/management/sms',
    ManagementSlaughterVariables = '/management/slaughter-variables',
    ConversationDialogEdit = '/messages/conversation/edit',
    PrivacyStatement = '/privacy-statement',
    PublicMPBovine = '/mp/bovine',
    PublicMPPork = '/mp/pork',
    ProductionPlanDialogRoot = '/production-plan/create',
    ReportingButchery = '/reporting/butchery',
    ReportingFinancial = '/reporting/financial',
    ReportingHealth = '/reporting/health',
    ReportingProduction = '/reporting/production',
    ResourceManagement = '/Resources/Management',
    Root = '/',
}

export enum AddressTypeEnum {
    HoldingSite = 3,
    Postal = 2,
    Street = 1,
}

export enum AliasTypeEnum {
    BankAccount = 7,
    Context = 9,
    FarmId = 3,
    ProducerNumber = 6,
}

export enum AnimalFilterTypeEnum {
    WaitingRoom = 0,
    Cow = 1,
    Heifer = 2,
    Bull = 3,
    Sales = 4,
    Calvings = 5,
    Naseva = 6,
    Deceased = 7,
    All = 99,
}

export enum AnimalTypeEnum {
    Unknown = 0,
    Bovine = 1,
    Pork = 2,
}

export enum ProductionPlanAnimalType {
    Unknown = 0,
    DairyCow, // Lypsylehmät
    BreedingCow, // Emolehmät
    Bull, // Sonnit
    Heifer, // Hiehot
    Calf, // Vasikat
    FirstMilkBullCalf, // Ternivasikat sonnit
    FirstMilkCowCalf, // Ternivasikat lehmät
    TransmissionBullCalf, // Välitysvasikat sonnit
    TransmissionCowCalf, // Välitysvasikat lehmät
    IntergrowBullCalf, // Välikasvatetut vasikat sonnit
    IntergrowCowCalf, // Välikasvatetut vasikat lehmät
    BeefBullCalf, // Pihvivasikat sonnit
    BeefCowCalf, // Pihvivasikat lehmät
    BeefCow, // Teurasnauta-lehmä
    BeefBull, // Teurasnauta-sonni
    BeefHeifer, // Teurasnauta-hieho
}

export enum AnnouncementTypeEnum {
    Slaughter = 1,
    Intermediation = 2,
    BovinePurchase = 3,
    ManualBovineSlaughter = 4,
    ManualBovineIntermediation = 5,
    SlaughterWithChainInformation = 6,
}

export enum BusinessCodeTypeEnum {
    Company = 1,
}

export enum BusinessFormTypeEnum {
    CommercialName = 3,
    Corporation = 1,
    FarmingConsortium = 5,
    GeneralPartnership = 4,
    LimitedPartnership = 2,
}

export enum CarCapacityTypeEnum {
    Bovines = 1,
    Calves = 2,
    Piglets = 4,
    Pigs = 3,
}

export enum AnnouncementListDataGridColumnType {
    Default = 1,
    BovineIntermediation = 2,
    BovinePurchase = 3,
}

export enum UserDataEntityTypeEnum {
    FarmContext = 'FarmContext',
    CompanyContext = 'CompanyContext',
}

export enum CarouselBoxTypeEnum {
    WaitingStatus = 1,
    Cow = 2,
    Heifer = 3,
    Bull = 4,
    SaleAnnouncement = 5,
    CalfOrder = 6,
    AllAnimals = 7,
    Deceased = 8,
}

export enum FarmingTypeEnum {
    Calving = 1,
    CombinationProduction = 6,
    Dairy = 2,
    Piggery = 7,
    PigletProduction = 5,
    Processing = 8,
    Transmission = 4,
    Upbringing = 3,
}

export enum CompanyEntityTypeEnum {
    Farm = 6,
    Snellman = 10,
    TrafficContractor = 7,
    Unknown = 0,
}

export enum CompanyNameTypeEnum {
    Primary = 1,
}

export enum ContactPersonTypeEnum {
    Unknown = 0,
}

export enum ContactPersonRoleTypeEnum {
    Unknown = 0,
}

export enum PhoneNumberTypeEnum {
    CompanyPayloadAndCollection = 54,
    CompanyOffice = 50,
    CompanySMS = 51,
    PersonHome = 52,
    PersonWork = 53,
}

export enum BovineFarmingType {
    Unknown = 0,
    Beef = 1,
    Dairy = 2,
    Breeding = 3,
    Transmission = 4,
}

export enum DialogModeEnum {
    Create = 'create',
    Edit = 'edit',
}

export enum CollectWeekDayEnum {
    WholeWeek = 1,
    EarlyWeek = 2,
    EndOfWeek = 3,
    Monday = 4,
    Tuesday = 5,
    Wednesday = 6,
    Thursday = 7,
    Friday = 8,
    Saturday = 9,
    Sunday = 10,
}

export enum AnelmaReportTypeEnum {
    FinancialReport = 'FinancialReport',
    ProductionReport = 'ProductionReport',
    HealthReport = 'HealthReport',
    SlaughterReport = 'SlaughterReport',
    TaxYearSummaryReport = 'TaxYearSummaryReport',
}

export enum AnelmaReportingBtnGroupNameEnum {
    FinancialReport = 'FinancialReportRadioBtnGroup',
    ProductionReport = 'ProductionReportRadioBtnGroup',
    HealthReport = 'HealthReportRadioBtnGroup',
    SlaughterReport = 'SlaughterReportBtnGroup',
}

export enum AnelmaEnvironment {
    Development = 'development',
    Test = 'test',
    Production = 'production',
}

export enum InfoSectionType {
    InfoSection = 1,
    InfoSectionsGmFree = 2,
}

export enum InfoSections {
    general = 'general',
    bovine = 'bovine',
    pork = 'pork',
    anelmaInfo = 'anelmainfo', //Params -> AnelmaInstructions:InfoSections code anelmainfo
}

export enum InfoSectionsGmFree {
    general = '1',
    bovine = '2',
    pork = '3',
}

export enum RoleFilterEnum {
    InternalOnly = 0,
    ProducerOnly = 1,
    All = 99,
}

export enum ProductionPlanType {
    Draft = 1,
    Completed,
    Approved,
}

export enum ProductionLineType {
    Bovine = 1,
    Pork,
}

export enum OrganicProductionState {
    None = 0,
    FieldsOnly,
    FieldsAndBovine,
    TransitionPhase,
}

export enum ProductionPlanQuestionType {
    Unknown = 0,
    Boolean,
    Options,
    Text,
}

export enum AnimalListButtonsEnum {
    Groups = 1,
    NewSlaughterAnnouncement = 2,
    NewIntermediationAnnouncement = 3,
    NewPurchaseAnnouncement = 4,
    SalesType = 5,
    BovineRegisterLink = 6,
    CarcassNet = 7,
    ManualAnnouncements = 8,
}

export enum SalesType {
    Undefined = 0,
    IsoLihasika = 10,
    Lihasika = 11,
    Emakko = 12,
    Jalostuskarju = 13,
    Panssarikarju = 14,
    Salakarju = 15,
    Karju = 16,
    NyljettySika = 17,
    NyljettyEmakko = 18,
    Pikkuvasikka = 20,
    Ruohovasikka = 21,
    Mulli = 22,
    Sonni = 23,
    Hieho = 24,
    Lehmä = 25,
    RistTaiRotusonni = 26,
    Nuorilehmä = 27,
    Pikalehmä = 28,
    Karitsa = 30,
    Lammas = 31,
    PorsasOmaSiirto = 32,
    PikkuporsasOmaSiirto = 33,
    LimSonniAlaCarte = 34,
    LimHiehoAlaCarte = 35,
    KoeporsasKarju = 40,
    KoeporsasImisä = 41,
    Pikkuporsas = 45,
    Hirvi = 50,
    Hevonen = 51,
    MUsiitoskarju = 52,
    PihSovas75prossa = 53,
    PihLevas75prossa = 54,
    LimSoVasAlaCarte = 55,
    LimLeVasAlaCarte = 56,
    Välitysporsas = 60,
    Sonnivasikka = 61,
    Lehmävasikka = 62,
    Ternisonnivas = 63,
    Ternilehmävas = 64,
    VälikasvSoVas = 65,
    VälikasvLeVas = 66,
    KantavaSiitoshieho = 67,
    VälitysporsasSek = 68,
    Koeporsas = 69,
    MASiitoskarju = 70,
    MASiitosensikko = 71,
    Siitossonni = 72,
    Siitoshieho = 73,
    MASiitosporsas = 74,
    Siitoslehmä = 75,
    MATiineSiitosensikko = 76,
}

// Copied from parameter resource AnelmaCalendar:CalendarEventTypes, using from couple of TS classes so cannot use t function hooks in there
export enum CalendarEventTypes {
    PickupSlaughterAnimal = 1,
    PickupTransMissionAnimal = 2,
    FarmVisit = 3,
    Event = 4,
    EventRegistration = 5,
    OwnBookings = 6,
    Other = 7,
    ReligiousHolidays = 8,
}

export enum ReportStatus {
    Loading = 'loading',
    Ready = 'ready',
    Failed = 'failed',
}

export enum EventDialogMode {
    Save = 'save',
    Edit = 'edit',
    Delete = 'delete',
}

export enum ProductionPlanNotificationQueryParamOptions {
    ProductionPlanCompleted = 1,
    ProductionPlanStart = 2,
}

/** Emulates AnelmaCommunication:CommunicationSubject parameter. Please, make any changes also to Snellman.Messaging.API.Domain.Enums.Common.CommunicationSubjectEnum */
export enum CommunicationSubjectEnum {
    AccountingAndInvoicing = 1,
    FeedbackAboutAnelma = 2,
    ProductionMonitoring = 3,
    CompensationRequest = 4,
    AnimalAnnouncement = 5,
    SlaughterInformation = 6,
    TransportProducer = 7,
    Reporting = 8,
    Other = 9,
    TransportOperator = 10,
}

/** Emulates AnelmaCommunication:CommunicationStatus. Please, make any changes also to Snellman.Messaging.API.Domain.Enums.Common.CommunicationStatusEnum */
export enum CommunicationStatusEnum {
    Waiting = 1,
    Processing = 2,
    Processed = 3,
    Paid = 4,
}

/** Enumeration that emulates participant's type. Please, make any changes also to Snellman.Messaging.API.Domain.Enums.Feedback.FeedbackParticipantRoleEnum  */
export enum FeedbackParticipantRoleEnum {
    Handler = 1,
    Participant = 2,
    OriginalSender = 3,
}

/** Enumeration that emulates read type. Please, make any changes also to Snellman.Messaging.API.Domain.Enums.Common.ReadTypeEnum */
export enum ReadTypeEnum {
    NotRead = 1,
    Read = 2,
}

/** Enumeration that emulates feedback command type. Please, manke any changes also to Snellman.Messaging.API.Domain.Enums.Feedback.FeedbackCommandTypeEnum */
export enum FeedbackCommandEnum {
    CreateFeedbackProducer = 1,
    CreateFeedbackAdmin = 2,
}
