// Libraries
import { useEffect, useState } from 'react';

// MUI
import { Container, Grid } from '@mui/material';

// Store
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateCurrentContext } from '../../store/contextSlice';

// Core
import auth from '../../core/authorization';
import utils from '../../core/utils';
import { updateCurrentContextToRequestHeaders } from '../../core/axios-interceptors';

// Api
import api from '../../api/authorizationApi';

// Feature
import NoContexts from './NoContexts';
import SingleContext from './SingleContext';
import UserContextSelector from './UserContextSelector';
import AdminContextSelector from './AdminContextSelector';
import contextUtils from './contextUtils';
import './contextSelector.scss';

export interface IContextSelectorProps {
    displayContextSelector: boolean;
}

export interface Context {
    context: string;
    displayName: string;
    scopes: string[];
}

export interface ContextDetails {
    displayName: string;
    id: number;
    name: string;
    scopes: string[];
    segments: string[];
    type: 'FarmContext';
}

export interface ContextData {
    contexts: Context[];
    selectedContext: Context | null;
    status: ContextDataStatus;
}

type ContextDataStatus = 'initial' | 'loading' | 'ready';

export const contextControl = {
    setFarm: (farmId: string): Promise<void> => {
        return Promise.resolve();
    },
};

export default function ContextSelector({ displayContextSelector }: IContextSelectorProps) {
    const storageKey = 'selected-context';
    const loginStatus = useAppSelector((state) => state.loginStatus);
    const loggedOut = !loginStatus.data.loggedIn;
    const admin = auth.canViewAdminContextSelector;
    const dispatch = useAppDispatch();

    const [contextData, setContextData] = useState<ContextData>({
        contexts: [],
        selectedContext: null,
        status: 'initial',
    });

    const setContexts = (contexts: Context[]) => {
        setContextData({ ...contextData, contexts });
    };

    const setSelectedContext = (selectedContext: Context | null) => {
        setContextData({ ...contextData, selectedContext });
    };

    useEffect(() => {
        if (loggedOut || contextData.status === 'ready') return;

        const setStatus = (state: ContextDataStatus) => {
            setContextData({ ...contextData, status: state });
        };

        const storedContext = utils.getFromSessionStorage<string>(storageKey);

        if (admin) {
            if (storedContext) {
                if (contextData.status === 'initial') {
                    setStatus('loading');

                    const context = contextUtils.parseContextString(storedContext);

                    api.getContextList(context.name).then((response) => {
                        if (!response) {
                            setStatus('ready');
                        } else {
                            const context = response.Items.find((i) => i.context === storedContext);
                            setContextData({
                                contexts: context ? [context] : [],
                                selectedContext: context || null,
                                status: 'ready',
                            });
                        }
                    });
                }
            } else setStatus('ready');
        } else {
            if (contextData.status === 'initial') {
                setStatus('loading');

                api.getContextList().then((response) => {
                    const contexts = response?.Items || [];
                    let selectedContext: Context | null = null;

                    if (storedContext) {
                        const selected = contexts.find((c) => c.context === storedContext);
                        if (selected) selectedContext = selected;
                    }

                    if (!selectedContext && contexts.length) selectedContext = contexts[0];

                    setContextData({
                        contexts,
                        selectedContext,
                        status: 'ready',
                    });
                });
            }
        }
    }, [contextData, admin, loggedOut, setContextData]);

    useEffect(() => {
        if (contextData.status !== 'ready') return;

        dispatch(updateCurrentContext(contextData.selectedContext));

        if (contextData.selectedContext)
            utils.setToSessionStorage(storageKey, contextData.selectedContext.context);

        updateCurrentContextToRequestHeaders(
            contextData.selectedContext?.context || '',
            'FarmContext'
        );
    }, [contextData.selectedContext, contextData.status, dispatch]);

    if (loggedOut || contextData.status !== 'ready') return <></>;

    contextControl.setFarm = (farmId: string) => {
        return api.getContextList('').then((response) => {
            if (response && response.Items && response.Items.length > 0) {
                for (let context of response.Items.filter((x) => x.context.indexOf(farmId) >= 0)) {
                    if (contextUtils.parseContextString(context.context).farmId === farmId) {
                        setContextData({
                            contexts: [context],
                            selectedContext: context,
                            status: 'ready',
                        });
                        break;
                    }
                }
            }
        });
    };

    return (
        <Container sx={{ display: displayContextSelector ? 'block' : 'none' }}>
            <Grid container>
                <Grid item xs='auto' style={{ marginLeft: 'auto' }}>
                    <div className='an-context-selector'>
                        {admin ? (
                            <AdminContextSelector
                                contextData={contextData}
                                setContexts={(c) => setContexts(c)}
                                setSelectedContext={(context) => setSelectedContext(context)}
                            />
                        ) : (
                            <>
                                {contextData.contexts.length === 0 && <NoContexts />}
                                {contextData.contexts.length === 1 &&
                                    contextData.selectedContext && (
                                        <SingleContext context={contextData.selectedContext} />
                                    )}
                                {contextData.contexts.length > 1 && (
                                    <UserContextSelector
                                        contextData={contextData}
                                        setSelectedContext={(context) =>
                                            setSelectedContext(context)
                                        }
                                    />
                                )}
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}
