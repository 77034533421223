// MUI
import { useMediaQuery } from '@mui/material';
import { Breakpoint, useTheme } from '@mui/material/styles';

const useLayout = (key: number | Breakpoint, from: 'down' | 'up') => {
    const theme = useTheme();
    const query = from === 'down' ? theme.breakpoints.down(key) : theme.breakpoints.up(key);
    return useMediaQuery(query);
};

export default useLayout;
