import { baseUrl } from '../core/constants';

// External APIs
export const accessManagementUrl = `${baseUrl}/api/gateway/access-management`;
export const authorizationUrl = `${baseUrl}/api/gateway/authorization`;

// Anelma API
export const accountingUrl = `${baseUrl}/api/gateway/anelma/accounting`;
export const animalUrl = `${baseUrl}/api/gateway/anelma/animal`;
export const animalListUrl = `${baseUrl}/api/gateway/anelma/AnimalList`;
export const animalPayloadsUrl = `${baseUrl}/api/gateway/anelma/animal-payloads`;
export const auditTrailUrl = `${baseUrl}/api/gateway/anelma/Log`;
export const bovineUrl = `${baseUrl}/api/gateway/anelma/bovine-register`;
export const breedingContractUrl = `${baseUrl}/api/gateway/anelma/BreedingContract`;
export const bovineVariablesUrl = `${baseUrl}/api/gateway/anelma/BovineVariables`;
export const calendarEventsUrl = `${baseUrl}/api/gateway/anelma/calendar/events`;
export const communicationUpdateUrl = `${baseUrl}/api/gateway/anelma/communication`;
export const communicationUrl = `${baseUrl}/api/gateway/anelma/communication`;
export const companiesUrl = `${baseUrl}/api/gateway/anelma/Company`;
export const documentUrl = `${baseUrl}/api/gateway/anelma/document`;
export const eventsUrl = `${baseUrl}/api/gateway/anelma/Event/`;
export const farmVisitUrl = `${baseUrl}/api/gateway/anelma/FarmVisit`;
export const invoiceUrl = `${baseUrl}/api/gateway/anelma/Invoice`;
export const nasevaUrl = `${baseUrl}/api/gateway/anelma/Naseva`;
export const personsUrl = `${baseUrl}/api/gateway/anelma/Person`;
export const printingUrl = `${baseUrl}/api/gateway/anelma/Printing`;
export const publicContactPersonUrl = `${baseUrl}/api/gateway/anelma/ContactPerson`;
export const reportingUrl = `${baseUrl}/api/gateway/anelma/reporting`;
export const searchParametersUrl = `${baseUrl}/api/gateway/anelma/SearchParameters`;
export const sendDirectMessageUrl = `${baseUrl}/api/gateway/anelma/sms`;
export const sendSupportEmailUrl = `${baseUrl}/api/gateway/anelma/Messaging`;
export const userCommunicationUrl = `${baseUrl}/api/gateway/anelma/communication/userannouncements`;
export const animalAnnouncementUrl = `${baseUrl}/api/gateway/anelma/animal-announcements`;
export const animalAnnouncementImportUrl = `${baseUrl}/api/gateway/anelma/animal-announcement-imports`;
export const utilityUrl = `${baseUrl}/api/gateway/anelma/Utility`;
export const groupUrl = `${baseUrl}/api/gateway/anelma/AnimalGroups`;
export const chainInformationUrl = `${baseUrl}/api/gateway/anelma/ChainInformation`;
export const productionPlanUrl = `${baseUrl}/api/gateway/anelma/ProductionPlan`;
export const notificationsUrl = `${baseUrl}/api/gateway/Notifications`;

// Public API
export const publicApiUrl = `${baseUrl}/api/gateway/anelma/public`;

// Anelma UI
export const clientResourceUrl = `${baseUrl}/api/bootstrapper/client-resources`;
export const communicationApi = `${baseUrl}/api/gateway/Messaging`;
export const configurationUrl = `${baseUrl}/api/bootstrapper/configuration`;
export const loginStatusUrl = `${baseUrl}/api/bootstrapper/login-status`;
export const systemResourcesUrl = `${baseUrl}/api/resources`;
export const feedbackApiUrl = `${baseUrl}/api/gateway/Messaging/Feedback`;
