import { Paper, Typography } from '@mui/material';

import './panel.scss';

export interface APanelProps {
    children?: React.ReactNode;
    title?: React.ReactNode;
    variant?: 'default' | 'warning' | 'error';
}

export default function APanel(props: APanelProps) {
    const variant = !props.variant || props.variant === 'default' ? '' : `an-${props.variant}`;
    return (
        <Paper className={`an-panel ${variant}`}>
            {props.title && (
                <div className='an-panel-title'>
                    <Typography variant='h5'>{props.title}</Typography>
                </div>
            )}
            {props.children && <div className='an-panel-content'>{props.children}</div>}
        </Paper>
    );
}
