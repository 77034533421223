// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import moment from 'moment';

// MUI
import { Grid, styled } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../../core/resources';
import FormValidator from '../../../core/FormValidator';
import { boundaryRepository } from '../../../core/boundaries';
import auth from '../../../core/authorization';

// Common
import { ASearchIconButton } from '../../../common/buttons';
import { ADateInput, ATextInput } from '../../../common/inputs';
import contextUtils from '../../../common/contextSelector/contextUtils';

// Interfaces
import { IAnimalPayloadItem } from '../../../interfaces/IAnimal';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import AnimalPayloadsApi from '../../../api/animalPayloadsApi';

// Feature
import { AnimalPayloadsSearchType, AnimalPayloadsSearchTypeProps } from './AnimalPayloadSearchType';
import { SearchObj } from '../AnimalPayloadsViewGrid';

export interface AnimalPayloadsSearchBarProps {
    beforeSearch: (searchTermType: SearchObj) => void;
    animalPayloadsOnChange: (paylaodItems: IAnimalPayloadItem[]) => void;
    afterSearch: () => void;
}

const StyledSearchInput = styled(ATextInput)(({ theme }) => ({
    marginLeft: '10px',
    marginRight: '10px',
    width: '300px',
    paddingTop: '7.5px',
}));

export const AnimalPayloadsSearchBar = (props: AnimalPayloadsSearchBarProps) => {
    const context = useAppSelector((state) => state.context);
    const admin = auth.canViewAdminContextSelector;

    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaAnimalPayload',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchType, setSearchType] = useState<string>('');
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const [defaultDateMaxValue, setDefaultDateMaxValue] = useState<number | null>(null);
    const [defaultDateMinValue, setDefaultDateMinValue] = useState<number | null>(null);

    useEffect(() => {
        if (!admin && context?.data?.currentContext) {
            setSearchType('ProducerNumber');
            const prodNumber = contextUtils.parseContextString(
                context.data.currentContext?.context
            )?.producerNumber;

            if (prodNumber) {
                setSearchTerm(prodNumber);
            } else {
                console.error('#cant set producerNumber');
            }
        }
    }, [admin, context]);

    useEffect(() => {
        boundaryRepository.load(['AnelmaAnimalPayload']).then(() => {
            const boundaries = boundaryRepository.resource(
                'AnelmaAnimalPayload',
                'DefaultSearchDateSpan'
            );

            if (boundaries) {
                setDefaultDateMaxValue(
                    Number.isInteger(boundaries.maxValue) ? (boundaries.maxValue as number) : null
                );
                setDefaultDateMinValue(
                    Number.isInteger(boundaries.minValue) ? (boundaries.minValue as number) : null
                );
            }
        });
    }, []);

    useEffect(() => {
        if (defaultDateMaxValue != null && toDate === null) {
            setToDate(moment().add(defaultDateMaxValue, 'day'));
        }
        if (defaultDateMinValue != null && fromDate === null) {
            setFromDate(moment().add(defaultDateMinValue, 'day'));
        }
    }, [defaultDateMaxValue, defaultDateMinValue]);

    const search = () => {
        if (!searchType || !searchTerm) return;

        const containsNonNumericCharacters = (input: string): boolean => {
            for (const char of input) {
                if (char < '0' || char > '9') {
                    return true;
                }
            }
            return false;
        };

        const fromDateIsLaterThanToDate = (
            fromDate: moment.Moment | null,
            toDate: moment.Moment | null
        ): boolean => {
            if (fromDate && toDate) {
                const fromDateWithoutTime = fromDate.startOf('day');
                const toDateWithoutTime = toDate.startOf('day');

                if (
                    fromDateWithoutTime.isAfter(toDateWithoutTime) ||
                    fromDateWithoutTime.isSame(toDateWithoutTime)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        };

        let animalPayloadSearchValidation = true;
        switch (searchType) {
            case 'PayloadNumber':
                if (
                    searchTerm.length <= 1 ||
                    searchTerm.length >= 7 ||
                    containsNonNumericCharacters(searchTerm)
                ) {
                    animalPayloadSearchValidation = false;
                }
                break;
            case 'ProducerNumber':
                if (
                    searchTerm.length <= 1 ||
                    searchTerm.length >= 7 ||
                    containsNonNumericCharacters(searchTerm) ||
                    fromDateIsLaterThanToDate(fromDate, toDate)
                ) {
                    animalPayloadSearchValidation = false;
                }
                break;
            case 'CompanyName':
                if (fromDateIsLaterThanToDate(fromDate, toDate)) {
                    animalPayloadSearchValidation = false;
                }
                break;
        }
        if (animalPayloadSearchValidation === false) {
            props.animalPayloadsOnChange([]);
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }

        const producerNumber = searchType === 'ProducerNumber' ? Number(searchTerm) : null;
        const payloadNumber = searchType === 'PayloadNumber' ? Number(searchTerm) : 0;

        props.beforeSearch({ searchTerm: searchTerm, searthType: searchType } as SearchObj);

        switch (searchType) {
            case 'ProducerNumber':
                AnimalPayloadsApi.getAnimalPayloads(
                    producerNumber,
                    null,
                    fromDate?.format('yyyy-MM-DD'),
                    toDate?.format('yyyy-MM-DD')
                )
                    .then((response) => {
                        if (response?.Items.length) {
                            props.animalPayloadsOnChange(response?.Items as IAnimalPayloadItem[]);
                        } else {
                            props.animalPayloadsOnChange([]);
                            enqueueSnackbar(t('AnelmaGeneral:1151'), {
                                variant: 'error',
                            });
                        }
                        props.afterSearch();
                    })
                    .catch((err) => {
                        enqueueSnackbar(t('AnelmaGeneral:1020'), {
                            variant: 'error',
                        });
                    })
                    .finally(() => props.afterSearch());
                break;
            case 'PayloadNumber':
                AnimalPayloadsApi.getAnimalPayloadByPayloadNumber(
                    payloadNumber,
                    auth.canViewAdminContextSelector
                )
                    .then((response) => {
                        if (response?.Entity) {
                            props.animalPayloadsOnChange([response.Entity as IAnimalPayloadItem]);
                        } else {
                            props.animalPayloadsOnChange([]);
                            enqueueSnackbar(t('AnelmaGeneral:1151'), {
                                variant: 'error',
                            });
                        }
                    })
                    .catch((err) => {
                        enqueueSnackbar(t('AnelmaGeneral:1020'), {
                            variant: 'error',
                        });
                    })
                    .finally(() => props.afterSearch());
                break;
            case 'CompanyName':
                AnimalPayloadsApi.getAnimalPayloadsByCompanyName(
                    searchTerm,
                    fromDate?.format('yyyy-MM-DD'),
                    toDate?.format('yyyy-MM-DD')
                )
                    .then((response) => {
                        if (response?.Items.length) {
                            props.animalPayloadsOnChange(response?.Items as IAnimalPayloadItem[]);
                        } else {
                            props.animalPayloadsOnChange([]);
                            enqueueSnackbar(t('AnelmaGeneral:1151'), {
                                variant: 'error',
                            });
                        }
                        props.afterSearch();
                    })
                    .catch((err) => {
                        enqueueSnackbar(t('AnelmaGeneral:1020'), {
                            variant: 'error',
                        });
                    })
                    .finally(() => props.afterSearch());
        }
    };

    const animalPayloadsSearchTypeProps: AnimalPayloadsSearchTypeProps = {
        onSearchTypeChange: (selectedType) => setSearchType(selectedType),
        selectedSearchType: searchType,
        validator: new FormValidator(),
    };

    return (
        <Grid
            container
            spacing={0}
            style={{ paddingBottom: '40px' }}
            columns={{ xs: 4, sm: 8, md: 12 }}
        >
            <AnimalPayloadsSearchType {...animalPayloadsSearchTypeProps} />

            {admin && (
                <>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <StyledSearchInput
                            id='search'
                            label={t('AnelmaGeneral:1055')}
                            onChange={(v) => setSearchTerm(v)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') search();
                            }}
                            validator={new FormValidator()}
                            value={searchTerm}
                            withoutContainer
                        />
                    </Grid>
                </>
            )}

            {searchType !== 'PayloadNumber' ? (
                <>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                        <ADateInput
                            id='valid-from'
                            label={t('AnelmaGeneral:1090')}
                            onChange={(d) => {
                                if (moment(d).isValid()) setFromDate(moment(d));
                                else setFromDate(null);
                            }}
                            value={fromDate?.toDate() || null}
                            validator={new FormValidator()}
                            withoutContainer
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                        <ADateInput
                            id='valid-to'
                            label={t('AnelmaGeneral:1091')}
                            onChange={(d) => {
                                if (moment(d).isValid()) setToDate(moment(d));
                                else setToDate(null);
                            }}
                            value={toDate?.toDate() || null}
                            validator={new FormValidator()}
                            withoutContainer
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} style={{ paddingLeft: '40px' }}>
                        <ASearchIconButton
                            onClick={() => search()}
                            style={{ marginTop: '12px' }}
                            tooltip={t('AnelmaGeneral:1055')}
                            type='action'
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                        <ASearchIconButton
                            onClick={() => search()}
                            style={{ marginTop: '12px' }}
                            tooltip={t('AnelmaGeneral:1055')}
                            type='action'
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
