// Libraries
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Container, Grid } from '@mui/material';

// Core
import FormValidator from '../../core/FormValidator';
import defaultData from '../../core/defaultData';

// Common
import {
    ADataGrid,
    ADataGridActions,
    gridColumns,
    gridUtils,
    AGridColumns,
} from '../../common/dataGrid';
import { ViewTitle } from '../../common/typography';
import {
    AAddButton,
    ADownloadIconButton,
    ACopyLinkIconButton,
    ADeleteIconButton,
} from '../../common/buttons';
import { ConfirmDeletionDialog } from '../../common/dialog';

// Api
import api from '../../api/documentApi';
import documentApi from '../../api/documentApi';

// Interfaces
import { IBasicDocumentInfo, DocumentFileTypeEnum, IDocumentAddDialogData } from '../IDocuments';

// Store
import { useAppDispatch } from '../../store/hooks';
import { loadPersonNamesAsync } from '../../store/personNameSlice';

// Documents
import DocumentAddDialog, { DocumentAddDialogTypes } from '../addDialog/DocumentAddDialog';
import { DocumentDialogConfigName } from '../addDialog/documentAddDialogConfigs';
import { GUIDType } from '../../interfaces/types';

export interface DocumentViewProps {
    additionalColumns: AGridColumns;
    copyLinkUrl?: string;
    deleteConfirmation: string;
    enableAddButton?: boolean;
    enableDeleteFile?: boolean;
    fileType: DocumentFileTypeEnum;
    onSave: (data: IDocumentAddDialogData) => Promise<any>;
    preLoading?: boolean;
    title: string;
    types?: DocumentAddDialogTypes;
    documentDialogConfig: DocumentDialogConfigName;
    farmId?: GUIDType;
}

export default function DocumentView(props: DocumentViewProps) {
    const { t } = useTranslation(['AnelmaDocument', 'AnelmaGeneral']);
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useAppDispatch();
    const loadNames = useCallback(
        (ids: string[]) => dispatch(loadPersonNamesAsync(ids)),
        [dispatch]
    );

    const [data, setData] = useState<IBasicDocumentInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [addDialogVisibility, setAddDialogVisibility] = useState<boolean>(false);
    const [deleteFileData, setDeleteFileData] = useState<IBasicDocumentInfo>();

    const loadFiles = () => {
        setLoading(true);
        api.getFileList(props.fileType, props.farmId).then((response) => {
            if (!response) {
                setLoading(false);
                return;
            }

            response.Items.sort((a, b) => {
                if (a.ValidFrom === b.ValidFrom) return 0;
                return a.ValidFrom < b.ValidFrom ? 1 : -1;
            });

            if (props.farmId)
                setData(
                    gridUtils.copyGridId(response.Items.filter((_) => _.FarmId === props.farmId))
                );
            else setData(gridUtils.copyGridId(response.Items));
            setLoading(false);
        });
    };

    useEffect(() => {
        loadFiles();
    }, []);

    useEffect(() => {
        loadFiles();
    }, [props.farmId]);

    useEffect(() => {
        if (props.documentDialogConfig === 'farmDocuments') getPersonNames();
    }, [data]);

    const actions: ADataGridActions = [];

    if (props.copyLinkUrl) {
        actions.push((params) => {
            const row = params.row as IBasicDocumentInfo;
            return (
                <ACopyLinkIconButton
                    key='link'
                    onClick={() => {
                        navigator.clipboard.writeText(`${props.copyLinkUrl}/${row.Id}`);
                        enqueueSnackbar(t('AnelmaDocument:1025'), {
                            variant: 'success',
                        });
                    }}
                    tooltip={t('AnelmaGeneral:1162')}
                />
            );
        });
    }

    actions.push((params) => {
        const row = params.row as IBasicDocumentInfo;
        return (
            <ADownloadIconButton
                key='download'
                onClick={() => api.downloadFile(props.fileType, row.Id)}
                tooltip={t('AnelmaDocument:1008')}
            />
        );
    });

    if (props.enableDeleteFile) {
        actions.push((params) => {
            const row = params.row as IBasicDocumentInfo;
            return <ADeleteIconButton key='delete' onClick={() => setDeleteFileData(row)} />;
        });
    }

    const getPersonNames = () => {
        setLoading(true);
        loadNames(data.map((_) => _.Owner)).then(() => setLoading(false));
    };

    return (
        <Container>
            <ViewTitle>{props.title}</ViewTitle>

            {props.enableAddButton && (
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <AAddButton
                            disabled={loading || props.preLoading}
                            onClick={() => setAddDialogVisibility(true)}
                            type='action'
                        >
                            {t('AnelmaDocument:1010')}
                        </AAddButton>
                    </Grid>
                </Grid>
            )}

            <ADataGrid
                actions={actions}
                columns={[
                    gridColumns.withTooltip({
                        field: 'DisplayName',
                        headerName: t('AnelmaDocument:1003'),
                        width: 250,
                    }),
                    gridColumns.withTooltip({
                        field: 'FileExtension',
                        headerName: t('AnelmaDocument:1023'),
                        width: 170,
                    }),
                    ...props.additionalColumns,
                ]}
                enableFiltering
                enableRowNumbers
                loading={loading || props.preLoading}
                rows={data}
            />

            {addDialogVisibility && (
                <DocumentAddDialog
                    afterSave={() => loadFiles()}
                    config={props.documentDialogConfig}
                    data={defaultData.documentDetails()}
                    initialType={null}
                    onClose={() => setAddDialogVisibility(false)}
                    onSave={props.onSave}
                    types={props.types}
                    validator={new FormValidator()}
                    farmId={props.farmId}
                />
            )}

            <ConfirmDeletionDialog
                confirmation={props.deleteConfirmation}
                onClose={() => setDeleteFileData(undefined)}
                onDelete={() => {
                    if (!deleteFileData) return;
                    documentApi.deleteDocument(deleteFileData.Id).then(() => loadFiles());
                }}
                open={!!deleteFileData}
            />
        </Container>
    );
}
