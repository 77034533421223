// Core
import { chainInformationUrl } from './apiEndpoints';
import { GUIDType } from '../interfaces/types';

import ApiBase from './ApiBase';
import {
    IChainInformationData,
    IChainInformationResult,
} from '../animals/chain-information/IChainInformationDialog';

class ChainInformationApi extends ApiBase {
    createChainInformation(payload: IChainInformationData) {
        return this.post<IChainInformationData>(
            `${chainInformationUrl}/StoreChainInformation`,
            payload
        );
    }

    updateChainInformation(payload: IChainInformationData, id: GUIDType) {
        return this.patch<IChainInformationData>(
            `${chainInformationUrl}/UpdateChainInformation/${id}`,
            payload
        );
    }

    loadChainInformation(id: GUIDType) {
        return this.get<IChainInformationResult>(
            `${chainInformationUrl}/GetChainInformation/${id}`
        );
    }

    loadMultipleChainInformations(ids: GUIDType[]) {
        return this.postList<IChainInformationResult>(
            `${chainInformationUrl}/GetMultipleChainInformations`,
            ids
        );
    }

    deleteChainInformation(id: GUIDType) {
        return this.delete<void>(`${chainInformationUrl}/DeleteChainInformation/${id}`);
    }

    saveFormConfirmation(confirmation: boolean, chainInformationId: GUIDType) {
        var payload = {
            ChainInformationId: chainInformationId,
            FormConfirmation: confirmation,
        };
        return this.post<void>(`${chainInformationUrl}/SaveFormConfirmation`, payload);
    }
}

export default new ChainInformationApi();
