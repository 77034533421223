import { propOr, reduce, keys, equals, append, without } from 'ramda';
import { IMessages } from './ISms';
import { FieldUpdate, IUpdatePayload, NamedField } from '../interfaces/IGeneral';

const getMessagesUpdate = (newEntity: IMessages, originalEntity: IMessages): FieldUpdate[] => {
    const fields = without(['Guid', 'Sender', 'Flags'], keys(newEntity));
    return reduce(
        (a: FieldUpdate[], key: string) => {
            // gets current and original values for a certain key
            const value = propOr(undefined, key, newEntity);
            const origValue = propOr(undefined, key, originalEntity);

            if (value === undefined || origValue === undefined || equals(value, origValue))
                return a;

            return append(
                {
                    Field: key,
                    NewData: value,
                    OldData: origValue,
                },
                a
            );
        },
        [],
        fields
    );
};

export const mapMessagesDataToRequest = (
    OldData: IMessages,
    NewData: IMessages
): IUpdatePayload<IMessages> => {
    let updates = getMessagesUpdate(NewData, OldData);
    let additions: NamedField[] = [];
    let deletions: NamedField[] = [];
    return {
        Entity: OldData,
        Additions: additions,
        Deletions: deletions,
        Updates: updates,
    };
};
