// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// Core
import { ResourceTextApplication } from '../../core/resources';
import defaultData from '../../core/defaultData';
import FormValidator from '../../core/FormValidator';
import auth from '../../core/authorization';

// Common
import { AEditIconButton, ADeleteIconButton, AAddButton } from '../../common/buttons';
import { AInputContainer } from '../../common/inputs';
import { ADialogSubHeading } from '../../common/dialog';
import {
    ADataGrid,
    gridColumns,
    AGridColumns,
    AGridColumn,
    ADataGridActions,
} from '../../common/dataGrid';

// Interfaces
import { ICar } from '../../interfaces/IBusinessEntities';
import { CarCapacityTypeEnum } from '../../interfaces/enums';
import { FormMode } from '../../interfaces/IForm';

// Feature
import CompanyCarsForm from './CompanyCarsForm';

export interface CompanyCarsProps {
    data: ICar[];
    mode: FormMode;
    onChange: (data: ICar[]) => any;
    onClose: Function;
}

export default function CompanyCarsView(props: CompanyCarsProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);
    const [addVehicleFormVisible, setVehicleFormVisible] = useState(false);
    const [cardata, setCarData] = useState<ICar>(defaultData.car());
    const [formMode, setFormMode] = useState<'create' | 'edit'>('create');
    const [editIndex, setEditIndex] = useState<number>(-1);
    const validator = new FormValidator();

    const onEdit = (data: ICar, index: number) => {
        props.data[index] = data;
        props.onChange([...props.data]);
    };

    const onAdd = (data: ICar) => {
        const newCarData = [...props.data];
        newCarData.push(data);
        props.onChange(newCarData);
    };

    const openAddVehicleFormDialog = () => {
        setEditIndex(-1);
        setFormMode('create');
        setCarData(defaultData.car());
        setVehicleFormVisible(true);
    };

    const openEditAddVehicleFormDialog = (data: ICar, i: number) => {
        setEditIndex(i);
        const newCarData = data;
        setFormMode('edit');
        setCarData(newCarData);
        setVehicleFormVisible(true);
    };

    const handleCarRemove = (index: number) => {
        const newCarData = [...props.data];
        newCarData.splice(index, 1);
        props.onChange(newCarData);
    };

    const companyDataRows = props.data
        ? props.data.map((i, index) => mergeRight(i, { id: index }))
        : [];

    const capacityColumn = (
        field: string,
        headerName: string,
        type: CarCapacityTypeEnum
    ): AGridColumn => {
        return {
            field,
            headerName,
            type: 'number',
            valueGetter: (params) => {
                const value = params.row as any as ICar;
                return value?.Capacities.find((r) => r.Type === type)?.Capacity;
            },
        };
    };

    const columns: AGridColumns = [
        { field: 'Number', headerName: t('AnelmaCompany:1057'), type: 'number' },
        { field: 'Licence', headerName: t('AnelmaCompany:1058') },
        gridColumns.phoneNumber(t('AnelmaCompany:1069')),
        capacityColumn('Bovines', t('AnelmaCompany:1061'), CarCapacityTypeEnum.Bovines),
        capacityColumn('Calves', t('AnelmaCompany:1063'), CarCapacityTypeEnum.Calves),
        capacityColumn('Pigs', t('AnelmaCompany:1062'), CarCapacityTypeEnum.Pigs),
        capacityColumn('Piglets', t('AnelmaCompany:1064'), CarCapacityTypeEnum.Piglets),
    ];

    const canEditCars =
        (props.mode === 'create' && auth.canCreateCompanyCars) ||
        (props.mode === 'modify' && auth.canUpdateCompanyCars);
    const canDeleteCars =
        (props.mode === 'create' && auth.canCreateCompanyCars) ||
        (props.mode === 'modify' && auth.canDeleteCompanyCars);
    const actions: ADataGridActions = [];

    if (auth.canCreateCompanyCars || canEditCars || canDeleteCars) {
        actions.push(
            (params) => {
                const row = params.row as unknown as ICar;
                const canEditThis = canEditCars || row.Id === null;
                return (
                    <>
                        {canEditThis && (
                            <AEditIconButton
                                onClick={() => {
                                    openEditAddVehicleFormDialog(row, params.id as number);
                                }}
                            />
                        )}
                    </>
                );
            },
            (params) => {
                const row = params.row as unknown as ICar;
                const canDeleteThis = canDeleteCars || row.Id === null;
                return (
                    <>
                        {canDeleteThis && (
                            <ADeleteIconButton
                                onClick={() => {
                                    handleCarRemove(params.id as number);
                                }}
                                tooltip={t('AnelmaCompany:1066')}
                            />
                        )}
                    </>
                );
            }
        );
    }
    return (
        <>
            <ADialogSubHeading>{t('AnelmaCompany:1055')}</ADialogSubHeading>

            <ADataGrid actions={actions} columns={columns} rows={companyDataRows} />

            {auth.canCreateCompanyCars && (
                <AInputContainer>
                    <div style={{ textAlign: 'right' }}>
                        <AAddButton onClick={openAddVehicleFormDialog}>
                            {t('AnelmaCompany:1065')}
                        </AAddButton>
                    </div>
                </AInputContainer>
            )}

            {addVehicleFormVisible ? (
                <CompanyCarsForm
                    data={cardata}
                    onClose={() => setVehicleFormVisible(false)}
                    mode={formMode}
                    index={editIndex}
                    onEdit={onEdit}
                    onAdd={onAdd}
                    validator={validator}
                />
            ) : (
                ''
            )}
        </>
    );
}
