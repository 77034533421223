// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Container } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';

// Core
import { dayFormat } from '../../../core/constants';
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../../core/resources';

// Common
import { ADeleteIconButton, AEditIconButton, ASearchIconButton } from '../../../common/buttons';
import { gridColumns } from '../../../common/dataGrid';
import ADataGrid, { AGridColumn } from '../../../common/dataGrid/ADataGrid';
import { ConfirmDeletionDialog } from '../../../common/dialog';

// Interfaces
import {
    IProductionPlanUserAccess,
    IProductionPlanSummary,
} from '../../../interfaces/IProductionPlan';

// Store
// API
// Feature
import { canDelete } from '../helpers/ProductionPlanAccessRights';
import IsReadOnlyPlan from '../helpers/ProductionPlanIsReadOnly';

interface IProps {
    data: IProductionPlanSummary[];
    access: IProductionPlanUserAccess;
    isLoading: boolean;
    onPlanOpen: (item: IProductionPlanSummary, readonly: boolean) => void;
    onPlanDelete: (item: IProductionPlanSummary) => void;
}

// used for testing
const deletionAllowed = false;

export default function ProductionPlanSummaryDataGrid(props: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCattleProductionPlan',
    ]);

    const renderCell = (params: GridCellParams) => {
        return params.value?.toString() ?? '';
    };

    const [rows, setRows] = useState<any[]>([]);
    const [productionLines, setProductionLines] = useState<ParameterValues>([]);
    const [municipalityCodes, setMunicipalityCodes] = useState<ParameterValues>([]);
    const [confirmDeletePlan, setConfirmDeletePlan] = useState<boolean>(false);
    const [planToDelete, setPlanToDelete] = useState<IProductionPlanSummary | null>(null);

    const columns: AGridColumn[] = [
        {
            field: 'ProducerNumber',
            headerName: t('AnelmaCattleProductionPlan:1019'),
            renderCell: renderCell,
            width: 140,
        },
        {
            field: 'ProducerName',
            headerName: t('AnelmaCattleProductionPlan:1017'),
            renderCell: renderCell,
            width: 160,
        },
        {
            field: 'MunicipalityCode',
            headerName: t('AnelmaCattleProductionPlan:1060'),
            renderCell: renderCell,
            valueGetter: (params) => {
                return (
                    municipalityCodes.find((_) => Number(_.code) === params.row.MunicipalityCode)
                        ?.text || ''
                );
            },
            width: 100,
        },
        {
            field: 'TargetYear',
            headerName: t('AnelmaCattleProductionPlan:1053'),
            renderCell: renderCell,
            width: 100,
        },
        {
            field: 'ProductionLine',
            headerName: t('AnelmaCattleProductionPlan:1054'),
            renderCell: renderCell,
            valueGetter: (params) => {
                const row = params.row as IProductionPlanSummary;
                return (
                    productionLines.find((x) => Number(x.code) === row.ProductionLine)?.text || ''
                );
            },
            width: 160,
        },
        gridColumns.dateTime('ConfirmationDate', t('AnelmaCattleProductionPlan:1055'), dayFormat, 130),
        {
            field: 'Confirmer',
            headerName: t('AnelmaCattleProductionPlan:1061'),
            renderCell: renderCell,
            width: 100,
            sortable: false,
        },
        gridColumns.dateTime('ApproveDate', t('AnelmaCattleProductionPlan:1056'), dayFormat, 180),
        {
            field: 'Approver',
            headerName: t('AnelmaCattleProductionPlan:1057'),
            renderCell: renderCell,
            width: 100,
            sortable: false,
        },
    ];

    useEffect(() => {
        let rowId = 0;
        setRows(
            props.data.map((summary) => {
                return {
                    id: rowId++,
                    ...summary,
                };
            })
        );
    }, [props.data]);

    useEffect(() => {
        paramRepository.load(['AnelmaCattleProductionPlan', 'AnelmaGeneral']).then(() => {
            setProductionLines(
                paramRepository.resource('AnelmaCattleProductionPlan', 'ProductionLine')
            );
            setMunicipalityCodes(paramRepository.resource('AnelmaGeneral', 'Municipality'));
        });
    }, []);

    const handleDeletePlan = (_: IProductionPlanSummary) => {
        setConfirmDeletePlan(true);
        setPlanToDelete(_);
    }

    return (
        <Container>
            <ADataGrid
                loading={props.isLoading}
                actions={[
                    (params) => {
                        return IsReadOnlyPlan(
                            params.row as IProductionPlanSummary,
                            props.access
                        ) ? (
                            <ASearchIconButton
                                key={`${params.id}-open`}
                                onClick={() => {
                                    props.onPlanOpen(params.row as IProductionPlanSummary, true);
                                }}
                                tooltip={t('AnelmaGeneral:1068')}
                            />
                        ) : (
                            <AEditIconButton
                                key={`${params.id}-edit`}
                                onClick={() => {
                                    props.onPlanOpen(params.row as IProductionPlanSummary, false);
                                }}
                                tooltip={t('AnelmaGeneral:1075')}
                            />
                        );
                    },
                    (params) => {
                        if (canDelete()) {
                            return (
                                <ADeleteIconButton
                                    key={`${params.id}-delete`}
                                    onClick={() =>
                                        handleDeletePlan(params.row as IProductionPlanSummary)
                                    }
                                />
                            );
                        }
                    },
                ]}
                enableFiltering={props.access.IsAdmin}
                enablePagination
                columns={columns}
                rows={rows}
            />

            <ConfirmDeletionDialog
                confirmation={t('AnelmaGeneral:1142')}
                open={confirmDeletePlan}
                onClose={() => setConfirmDeletePlan(false)}
                onDelete={() => {
                    planToDelete && props.onPlanDelete(planToDelete);
                    setPlanToDelete(null);
                }}
            />
        </Container>
    );
}
