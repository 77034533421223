import { useEffect } from 'react';
// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
//Core
import { ResourceTextApplication, paramRepository } from '../../core/resources';
// Interfaces
import { IPaymentRemovals } from '../../interfaces/IAccounting';

// Common
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';
import InvoiceMathHelper from '../invoices/InvoiceMathHelper';
import { TextWithTooltip } from '../../common/typography';

export interface PaymentRemovalsGridProps {
    data: IPaymentRemovals[];
}

export default function PaymentRemovalsDetailGrid(props: PaymentRemovalsGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccounting', 'AnelmaLayout']);
    enum ColumnsWidth {
        minSize = 80,
        midSize = 120,
        maxSize = 150,
    }

    const rowsPaymentRemoval = props.data.map((val, i) => mergeRight(val, { id: i }));

    const columnsPaymentRemoval: AGridColumns = [
        {
            field: 'InvoiceNumber',
            sortable: false,
            type: 'string',
            headerName: t('AnelmaAccounting:1034'),
            width: ColumnsWidth.maxSize,
        },
        gridColumns.dateTime(
            'InvoiceDate',
            t('AnelmaAccounting:1035'),
            'DD.MM.YYYY',
            ColumnsWidth.midSize
        ),
        {
            field: 'ItemName',
            sortable: false,
            type: 'string',
            headerName: t('AnelmaAccounting:1036'),
            width: ColumnsWidth.maxSize,
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
        },

        {
            field: 'Amount',
            sortable: false,
            type: 'number',
            headerName: t('AnelmaAccounting:1037'),
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                return (params.value as number)?.toLocaleString('fi');
            },
        },

        {
            field: 'ItemPriceBeforeTaxes',
            sortable: false,
            headerName: t('AnelmaAccounting:1038'),
            description: t('AnelmaAccounting:1039'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'PriceBeforeTaxes',
            sortable: false,
            headerName: t('AnelmaAccounting:1030'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'VATAmount',
            sortable: false,
            headerName: t('AnelmaAccounting:1010'),
            type: 'number',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'VATRate',
            sortable: false,
            headerName: t('AnelmaAccounting:1031'),
            type: 'number',
            width: ColumnsWidth.minSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'PriceAfterTaxes',
            sortable: false,
            headerName: t('AnelmaAccounting:1032'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
    ];

    useEffect(() => {
        paramRepository.load(['AnelmaAccounting']).then(() => {});
    }, []);

    return (
        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <ADataGrid
                columns={columnsPaymentRemoval}
                rows={rowsPaymentRemoval.length > 0 ? rowsPaymentRemoval : []}
            ></ADataGrid>
        </div>
    );
}
