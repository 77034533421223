import { AGridColumn } from '../../../common/dataGrid';
import { FarmingTypeEnum, ProductionPlanAnimalType } from '../../../interfaces/enums';
import {
    IProductionPlanAccess,
    IFarmTrend,
    IPlannedAnimal,
    IAnimalCapacity,
} from '../../../interfaces/IProductionPlan';
import AnimalCountMapper from '../helpers/data-grid-mappers/AnimalCounts';
import IntermedSalesMapper from '../helpers/data-grid-mappers/IntermediationSales';
import SlaughterSalesMapper from '../helpers/data-grid-mappers/SlaughterSales';

export interface IProductionPlanDataGrid {
    columns: AGridColumn[];
    rows: any[];
}

export function BuildAnimalCapacities(
    access: IProductionPlanAccess,
    AnimalCapacities: IAnimalCapacity[],
    edit?: boolean
): IProductionPlanDataGrid {
    const mapped = AnimalCountMapper.ToDataGrid(AnimalCapacities);

    var calving = access.ProductionDirection.findIndex((x) => x === FarmingTypeEnum.Calving) != -1;
    var dairy = access.ProductionDirection.findIndex((x) => x === FarmingTypeEnum.Dairy) != -1;
    var upbringing = access.ProductionDirection.findIndex((x) => x === FarmingTypeEnum.Upbringing) != -1;
    var transmission = access.ProductionDirection.findIndex((x) => x === FarmingTypeEnum.Transmission) != -1;

    var hasBreedingAnimals = AnimalCapacities.findIndex((x) => x.AnimalType === ProductionPlanAnimalType.BreedingCow && (x.Animals > 0 || x.Capacity > 0)) != -1;
    var hasDairyAnimals = AnimalCapacities.findIndex((x) => x.AnimalType === ProductionPlanAnimalType.DairyCow && (x.Animals > 0 || x.Capacity > 0)) != -1;
    var hasBulls = AnimalCapacities.findIndex((x) => x.AnimalType === ProductionPlanAnimalType.Bull && (x.Animals > 0 || x.Capacity > 0)) != -1;
    var hasHeifers = AnimalCapacities.findIndex((x) => x.AnimalType === ProductionPlanAnimalType.Heifer && (x.Animals > 0 || x.Capacity > 0)) != -1;
    var hasCalfs = AnimalCapacities.findIndex((x) => x.AnimalType === ProductionPlanAnimalType.Calf && (x.Animals > 0 || x.Capacity > 0)) != -1;

    const rows = [];
    const columns = [
        {
            field: 'title',
            headerName: ' ',
            width: 100,
        },
    ];

    rows.push({
        title: 'AnelmaCattleProductionPlan:1001',
        collection: 'PlannedCapacities',
        ...mapped.find((x) => x.field === 'Capacity'),
    });
    rows.push({
        title: 'AnelmaCattleProductionPlan:1000',
        collection: 'ActualCapacities',
        ...mapped.find((x) => x.field === 'Animals'),
    });

    if (calving || hasBreedingAnimals) {
        columns.push({
            field: 'Breeding',
            headerName: 'AnelmaCattleProductionPlan:1003',
            width: 100,
        });
    }

    if (dairy || hasDairyAnimals) {
        columns.push({
            field: 'Dairy',
            headerName: 'AnelmaCattleProductionPlan:1002',
            width: 80,
        });
    }

    if ((upbringing || hasBulls)
        ||
        (edit && (calving || dairy))) {
        columns.push({
            field: 'Bulls',
            headerName: 'AnelmaCattleProductionPlan:1004',
            width: 100,
        });
    }

    if ((upbringing || hasHeifers)
        ||
        (edit && (calving || dairy))) {
        columns.push({
            field: 'Heifers',
            headerName: 'AnelmaCattleProductionPlan:1005',
            width: 100,
        });
    }

    if ((upbringing || transmission || hasCalfs)
        ||
        (edit && (calving || dairy))){
        columns.push({
            field: 'Calves',
            headerName: 'AnelmaCattleProductionPlan:1006',
            width: 130,
        });
    }

    return {
        columns: columns,
        rows: mapRows(...rows),
    };
}

export function BuildIntermediationAnimalSales(
    access: IProductionPlanAccess,
    PreviousTransmissionAnimalsSold: IPlannedAnimal[],
    PlannedTransmissionAnimalsSold: IPlannedAnimal[],
    all?: boolean
): IProductionPlanDataGrid {
    var columns: AGridColumn[] = [
        {
            field: 'title',
            headerName: ' ',
            width: 120,
        },
    ];

    // AN-2080 - For Dairy only farm there is a static list of fields we want to show
    if (
        access.ProductionDirection.findIndex((d) => d === FarmingTypeEnum.Dairy) !== -1 &&
        access.ProductionDirection.findIndex((d) => d !== FarmingTypeEnum.Dairy) === -1
    ) {
        columns = columns.concat(dairyColumns);
    } else {
        if (all || access.HasDairyAnimals) {
            columns = columns.concat(dairyColumns);
        }

        if (all || access.ProductionDirection.find((x) => x === FarmingTypeEnum.Transmission)) {
            columns = columns.concat(transmissionColumns);
        }

        if (all || access.HasBreedingAnimals) {
            columns = columns.concat(breedingColumns);
        }
    }

    // AN-2113 - Need to also show all the fields that have planned/last 12kk animals
    //Transmission
    if (PlannedTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.IntergrowBullCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.IntergrowBullCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'VkBreederCalfBulls')) {
            columns.push(transmissionColumns[0]);
        }
    }
    if (PlannedTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.IntergrowCowCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.IntergrowCowCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'VkBreederCalfCows')) {
            columns.push(transmissionColumns[1]);
        }
    }
    //Breeding
    if (PlannedTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.BeefBullCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.BeefBullCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'BeefCalfBulls')) {
            columns.push(breedingColumns[0]);
        }
    }
    if (PlannedTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.BeefCowCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.BeefCowCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'BeefCalfCows')) {
            columns.push(breedingColumns[1]);
        }
    }
    //dairy
    if (PlannedTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.FirstMilkBullCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.FirstMilkBullCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'ColostrumCalfBulls')) {
            columns.push(dairyColumns[0]);
        }
    }
    if (PlannedTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.FirstMilkCowCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.FirstMilkCowCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'ColostrumCalfCows')) {
            columns.push(dairyColumns[1]);
        }
    }
    if (PlannedTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.TransmissionBullCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.TransmissionBullCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'IntermediationBulls')) {
            columns.push(dairyColumns[2]);
        }
    }
    if (PlannedTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.TransmissionCowCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsSold.find((x) => x.Type === ProductionPlanAnimalType.TransmissionCowCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'IntermediationCows')) {
            columns.push(dairyColumns[3]);
        }
    }

    return {
        columns: columns,
        rows: mapRows(
            {
                title: 'AnelmaCattleProductionPlan:1007',
                collection: 'PreviousTransmissionAnimalsSold',
                ...IntermedSalesMapper.ToDataGrid(PreviousTransmissionAnimalsSold),
            },
            {
                title: 'AnelmaCattleProductionPlan:1008',
                collection: 'PlannedTransmissionAnimalsSold',
                ...IntermedSalesMapper.ToDataGrid(PlannedTransmissionAnimalsSold),
            }
        ),
    };
}

export function BuildIntermediationAnimalRequirements(
    access: IProductionPlanAccess,
    PreviousTransmissionAnimalsNeeded: IPlannedAnimal[],
    PlannedTransmissionAnimalsNeeded: IPlannedAnimal[],
    all?: boolean
): IProductionPlanDataGrid {
    var columns: AGridColumn[] = [
        {
            field: 'title',
            headerName: ' ',
            width: 120,
        },
    ];

    if (
        all ||
        access.ProductionDirection.find(
            (x) =>
                x === FarmingTypeEnum.Transmission ||
                x === FarmingTypeEnum.Dairy ||
                x === FarmingTypeEnum.Upbringing
        )
    ) {
        columns.push(dairyColumns[0], dairyColumns[1]);
    }

    if (
        all ||
        access.ProductionDirection.find((x) => x === FarmingTypeEnum.Dairy) ||
        access.ProductionDirection.find((x) => x === FarmingTypeEnum.Upbringing)
    ) {
        columns.push(dairyColumns[2], dairyColumns[3]);
        columns = columns.concat(transmissionColumns);
        columns = columns.concat(breedingColumns);
    }

    // AN-2113 - Need to also show all the fields that have planned/last 12kk animals
    //Transmission
    if (PlannedTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.IntergrowBullCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.IntergrowBullCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'VkBreederCalfBulls')) {
            columns.push(transmissionColumns[0]);
        }
    }
    if (PlannedTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.IntergrowCowCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.IntergrowCowCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'VkBreederCalfCows')) {
            columns.push(transmissionColumns[1]);
        }
    }
    //Breeding
    if (PlannedTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.BeefBullCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.BeefBullCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'BeefCalfBulls')) {
            columns.push(breedingColumns[0]);
        }
    }
    if (PlannedTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.BeefCowCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.BeefCowCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'BeefCalfCows')) {
            columns.push(breedingColumns[1]);
        }
    }
    //dairy
    if (PlannedTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.FirstMilkBullCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.FirstMilkBullCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'ColostrumCalfBulls')) {
            columns.push(dairyColumns[0]);
        }
    }
    if (PlannedTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.FirstMilkCowCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.FirstMilkCowCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'ColostrumCalfCows')) {
            columns.push(dairyColumns[1]);
        }
    }
    if (PlannedTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.TransmissionBullCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.TransmissionBullCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'IntermediationBulls')) {
            columns.push(dairyColumns[2]);
        }
    }
    if (PlannedTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.TransmissionCowCalf && x.Amount > 0) ||
        PreviousTransmissionAnimalsNeeded.find((x) => x.Type === ProductionPlanAnimalType.TransmissionCowCalf && x.Amount > 0)) {
        if (!columns.find((x) => x.field === 'IntermediationCows')) {
            columns.push(dairyColumns[3]);
        }
    }

    return {
        columns: columns,
        rows: mapRows(
            {
                title: 'AnelmaCattleProductionPlan:1007',
                collection: 'PreviousTransmissionAnimalsNeeded',
                ...IntermedSalesMapper.ToDataGrid(PreviousTransmissionAnimalsNeeded),
            },
            {
                title: 'AnelmaCattleProductionPlan:1008',
                collection: 'PlannedTransmissionAnimalsNeeded',
                ...IntermedSalesMapper.ToDataGrid(PlannedTransmissionAnimalsNeeded),
            }
        ),
    };
}

export function BuildSlaughterAnimals(
    access: IProductionPlanAccess,
    PreviousSlaughterBovineSold: IPlannedAnimal[],
    PlannedSlaughterBovineSold: IPlannedAnimal[],
    all?: boolean
): IProductionPlanDataGrid {

    var columns: AGridColumn[] = [
        {
            field: 'title',
            headerName: ' ',
            width: 120,
        }
    ];

    if (
        all || access.ProductionDirection.find(
            (x) =>
                x === FarmingTypeEnum.Calving ||
                x === FarmingTypeEnum.Dairy
            )) {
        columns = columns.concat(slaughterColumns);
    }

    if (access.ProductionDirection.find((x) => x === FarmingTypeEnum.Upbringing)) {
        if (!columns.find((x) => x.field === 'Bulls')) {
            columns.push(slaughterColumns[1]);
        }
        if (!columns.find((x) => x.field === 'Heifers')) {
            columns.push(slaughterColumns[2]);
        }
    }

    return {
        columns: columns,
        rows: mapRows(
            {
                title: 'AnelmaCattleProductionPlan:1007',
                collection: 'PreviousSlaughterBovineSold',
                ...SlaughterSalesMapper.ToDataGrid(PreviousSlaughterBovineSold),
            },
            {
                title: 'AnelmaCattleProductionPlan:1008',
                collection: 'PlannedSlaughterBovineSold',
                ...SlaughterSalesMapper.ToDataGrid(PlannedSlaughterBovineSold),
            }
        )
    };
}

export function BuildFarmTrends(
    access: IProductionPlanAccess,
    farmTrends: IFarmTrend[],
    trendYears: number[],
    all?: boolean
): IProductionPlanDataGrid {
    const mapRow = (title: string, field: keyof IFarmTrend) => {
        let row: any = {
            field: field,
            title: title,
        };
        for (let year of trendYears) {
            const trend = farmTrends.find((x) => x.Year === year);
            if (trend) row[`${year}`] = trend[field];
            else row[`${year}`] = 0;
        }
        return row;
    };

    const rows = [mapRow('AnelmaCattleProductionPlan:1039', 'PlannedTotalAnimalAmount')];

    if (all || access.HasDairyAnimals)
        rows.push(mapRow('AnelmaCattleProductionPlan:1002', 'PlannedDairyCowAmount'));

    if (all || access.HasBreedingAnimals)
        rows.push(mapRow('AnelmaCattleProductionPlan:1003', 'PlannedBreedingCowAmount'));

    if (all || access.ProductionDirection.find((x) => x === FarmingTypeEnum.Upbringing))
        rows.push(
            mapRow('AnelmaCattleProductionPlan:1040', 'PlannedBeefBullAmount'),
            mapRow('AnelmaCattleProductionPlan:1041', 'PlannedBeefHeiferAmount')
        );
    if (all || access.ProductionDirection.find((x) => x === FarmingTypeEnum.Transmission))
        rows.push(mapRow('AnelmaCattleProductionPlan:1042', 'PlannedTransmissionCalfAmount'));

    return {
        columns: [
            {
                field: 'title',
                headerName: 'AnelmaCattleProductionPlan:1038',
                width: 210,
            },
            ...trendYears.map((year) => {
                return {
                    field: `${year}`,
                    headerName: `${year}`,
                    width: 80,
                };
            }),
        ],
        rows: mapRows(...rows),
    };
}

// add required id property to rows.
function mapRows(...items: any[]): any[] {
    let id = 0;
    return items.map((x) => {
        return { id: id++, ...x };
    });
}

const dairyColumns: AGridColumn[] = [
    {
        field: 'ColostrumCalfBulls',
        headerName: 'AnelmaCattleProductionPlan:1009',
        width: 100,
    },
    {
        field: 'ColostrumCalfCows',
        headerName: 'AnelmaCattleProductionPlan:1010',
        width: 100,
    },
    {
        field: 'IntermediationBulls',
        headerName: 'AnelmaCattleProductionPlan:1011',
        width: 100,
    },
    {
        field: 'IntermediationCows',
        headerName: 'AnelmaCattleProductionPlan:1012',
        width: 100,
    },
];

const transmissionColumns: AGridColumn[] = [
    {
        field: 'VkBreederCalfBulls',
        headerName: 'AnelmaCattleProductionPlan:1013',
        width: 120,
    },
    {
        field: 'VkBreederCalfCows',
        headerName: 'AnelmaCattleProductionPlan:1014',
        width: 120,
    }
];
const breedingColumns: AGridColumn[] = [
    {
        field: 'BeefCalfBulls',
        headerName: 'AnelmaCattleProductionPlan:1015',
        width: 120,
    },
    {
        field: 'BeefCalfCows',
        headerName: 'AnelmaCattleProductionPlan:1016',
        width: 120,
    }
];

const slaughterColumns: AGridColumn[] = [
    {
        field: 'Cows',
        headerName: 'AnelmaCattleProductionPlan:1002',
        width: 80
    },
    {
        field: 'Bulls',
        headerName: 'AnelmaCattleProductionPlan:1004',
        width: 100
    },
    {
        field: 'Heifers',
        headerName: 'AnelmaCattleProductionPlan:1005',
        width: 100
    }
]
