// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../common/dialog';

// Styles
import './confirmDialogStyle.scss';

export interface ConfirmDeletionDialogProps {
    confirmation: string;
    onClose: () => void;
    onDelete: () => void;
    open: boolean;
}

export default function ConfirmDeletionDialog(props: ConfirmDeletionDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    return (
        <ADialog open={props.open} data-robot-id = "confirm-deletion-dialog">
            <ADialogTitle>{t('AnelmaGeneral:1142')}</ADialogTitle>

            <ADialogContent size='sm'>
                <Typography className='confirm-dialog-content-typography' variant='body1'>
                    {props.confirmation}
                </Typography>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onClose(),
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => {
                                props.onDelete();
                                props.onClose();
                            },
                            type: 'delete',
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
