// React
import { useState } from 'react';

// MUI
import { Container } from '@mui/material';

// Libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// Common
import { ViewTitle } from '../../common/typography';

// Core
import { ResourceTextApplication } from '../../core/resources';
import auth from '../../core/authorization';

// API
import animalPayloadsApi from '../../api/animalPayloadsApi';

// Interface
import { IAnimalPayloadItem } from '../../interfaces/IAnimal';

// Feature
import { AnimalPayloadsBatchForm } from './AnimalPayloadsBatchForm';
import {
    AnimalPayloadsViewGrid,
    AnimalPayloadsViewGridProps,
    SearchObj,
} from './AnimalPayloadsViewGrid';
import {
    AnimalPayloadsSearchBar,
    AnimalPayloadsSearchBarProps,
} from './search/AnimalPayloadsSearchBar';

export default function AnimalPayloads() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaAnimalPayload',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [batchDialogIsVisible, setBatchDialogIsVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [payloadData, setPayloadData] = useState<IAnimalPayloadItem[]>([]);
    const [selectedPayloadData, setSelectedPayloadData] = useState<IAnimalPayloadItem | null>(null);
    const [selectedSearchObj, setSelectedSearchObj] = useState<SearchObj | null>(null);

    const onPayloadNumberClick = (payloadNumber: number) => {
        if (!payloadNumber) return;

        setIsLoading(true);

        animalPayloadsApi
            .getAnimalPayloadByPayloadNumber(payloadNumber, auth.canViewAdminContextSelector)
            .then((response) => {
                if (response?.Entity) {
                    setIsLoading(false);
                    setSelectedPayloadData(response.Entity);
                } else {
                    enqueueSnackbar(t('AnelmaGeneral:1020'), {
                        variant: 'error',
                    });
                    setIsLoading(false);
                    setSelectedPayloadData(null);
                    return;
                }
            });
    };

    const animalPayloadsSearchBarProps: AnimalPayloadsSearchBarProps = {
        beforeSearch: (searchTermType) => {
            setIsLoading(true);
            setSelectedSearchObj(searchTermType);
        },
        animalPayloadsOnChange: (animalPayloads) => setPayloadData(animalPayloads),
        afterSearch: () => setIsLoading(false),
    };

    const animalPayloadsViewGridProps: AnimalPayloadsViewGridProps = {
        isLoading: isLoading,
        onPayloadClick: onPayloadNumberClick,
        payloadData: !payloadData || payloadData.length === 0 ? [] : payloadData,
        searchObj: selectedSearchObj,
    };

    return (
        <Container id='anelma-animals-animal-payloads-container'>
            <ViewTitle>{t('AnelmaAnimalPayload:1000')}</ViewTitle>

            {selectedPayloadData !== null && (
                <AnimalPayloadsBatchForm
                    payloadData={selectedPayloadData}
                    onClose={() => setSelectedPayloadData(null)}
                    isLoading={isLoading}
                />
            )}

            <AnimalPayloadsSearchBar {...animalPayloadsSearchBarProps} />

            <AnimalPayloadsViewGrid {...animalPayloadsViewGridProps} />
        </Container>
    );
}
