// Libraries
import moment from 'moment';

// Core
import { yearMonthDateFormat } from '../../../core/constants';
import { AnnouncementTypeEnum, SalesType } from '../../../interfaces/enums';

// Interfaces
import { IAnimalSummaryExtraInfo } from '../../../interfaces/IAnimal';
import { IAnimalAnnouncement, IAnimalAnnouncementAnimal } from '../../../interfaces/IAnnouncement';

export const omitAnimalAnnouncementAnimalsFromSelectableAnimals = (
    data: IAnimalAnnouncement,
    animals: IAnimalSummaryExtraInfo[]
): IAnimalSummaryExtraInfo[] => {
    let announcementAnimals: IAnimalAnnouncementAnimal[] = [];
    for (const batch of data.Batches) {
        for (const animal of batch.Animals) {
            announcementAnimals.push(animal);
        }
    }
    let filtered = animals.filter(
        (a) => !announcementAnimals.map((b) => b.AnimalId).includes(a.Id)
    );
    return filtered;
};

export const getAnimalCount = (animalAnnouncement: IAnimalAnnouncement[]): number => {
    let count: number = 0;
    for (const announcement of animalAnnouncement) {
        for (const batch of announcement.Batches) {
            count += batch.Animals.length;
        }
    }
    return count;
};

export const getBovinePurchaseAnimalCount = (animalAnnouncement: IAnimalAnnouncement[]) => {
    let count: number = 0;
    for (const announcement of animalAnnouncement) {
        for (const batch of announcement.Batches) {
            count += batch.Amount;
        }
    }
    return count;
};

/** Return empty string in case birthDate value is empty string, null or undefined. Otherwise return string formatted to 'YYYY-MM-DD' with moment.js */
export const getBirthDate = (birthDate: string) => {
    return birthDate === '' || birthDate === null || birthDate === undefined
        ? ''
        : moment(birthDate).format(yearMonthDateFormat);
};

/**
 * Return true when type is slaughter or slaughter related and one of the batches has express cow in it, return false otherwise.
 * @param type animal announcement's type
 * @param data animal announcement
 * @returns boolean
 */
export const hasExpressCow = (type: AnnouncementTypeEnum, data: IAnimalAnnouncement): boolean => {
    return data.Batches.length > 0 &&
        (type === AnnouncementTypeEnum.Slaughter ||
            type === AnnouncementTypeEnum.SlaughterWithChainInformation ||
            type === AnnouncementTypeEnum.ManualBovineSlaughter)
        ? data.Batches.every((_) => _.Animals.every((a) => a.SalesType === SalesType.Pikalehmä))
        : false;
};

export const hasAnimalDataExpressCow = (
    type: AnnouncementTypeEnum | null,
    animals: IAnimalAnnouncementAnimal[]
): boolean => {
    return type === AnnouncementTypeEnum.Slaughter ||
        type === AnnouncementTypeEnum.SlaughterWithChainInformation ||
        type === AnnouncementTypeEnum.ManualBovineSlaughter
        ? animals.every((_) => _.SalesType === SalesType.Pikalehmä)
        : false;
};