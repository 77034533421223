export interface IInvoiceProducerInfo {
    ProducerNumber: number;
    ProducerName: string;
    ProducerYTunnus: string;
    ProducerAddress: string;
}

export interface IInvoice {
    ProducerNumber: number;
    InvoiceNumber: number;
    InvoiceSum: number;
    UnpaidSum: number;
    InvoicePaymentDeadline: string;
    InvoicingDate: string;
    PaymentMethod: number;
}

export interface IInvoiceDetails extends IInvoice {
    ReferenceNumber: string;
    InvoiceAnimalType: number;
    InvoicingDate: string;
    FarmGUID: string;
    AmountOfAnimals: number;
    AmountSum: number;
    InterestRate: number;
    DelayInterestRate: number;
    PriceBeforeTaxes: number;
    VATRate: number;
    VATAmount: number;
    PriceAfterTaxes: number;
    PaymentTerm: string;
    Transportations: ITransportations[];
    Rows: IRows[];
    AdditionalOrLowerKilos: number;
    QualityClass: string;
    AnimalPriceBeforeTaxes: number;
    PriceListBasePrice: number;
    LinkedCreditInvoices: ILinkedCreditInvoices[];
}

export interface ITransportations {
    TransportDate: string;
    Receip: number;
}

export interface IRows {
    RowNumber: number;
    ExternalIdentifier: string;
    InvoiceAnimalType: number;
    ProductCode: number;
    Breed: number;
    Amount: number;
    Weight: number;
    PriceListBasePrice: number;
    ItemPriceBeforeTaxes: number;
    PriceBeforeTaxes: number;
    VATRate: number;
    VATAmount: string;
    PriceAfterTaxes: number;
    ItemPriceDetails: IItemPriceDetails[];
}

export interface IItemPriceDetails {
    Name: string;
    Amount: number;
    PriceBeforeTaxes: number;
}

export interface ILinkedCreditInvoices {
    InvoiceNumber: string;
    InvoicingDate: string;
    PriceBeforeTaxes: number;
    VATAmount: number;
    PriceAfterTaxes: number;
}

export enum PaymentMethod {
    Invoice = 2,
    CreditReport = 3,
}
