// Libraries
import { ReactNode } from 'react';

// MUI
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

// Common
import { AButtonBaseProps } from '../../common/buttons';

const StyledButton = styled(Button)(({ theme }) => ({
    root: {
        background: 'linear-gradient(90deg, #d3d3d3 0%, #e3e3e3 100%)',
        borderBottom: '4px solid #0a4c31',
        borderRadius: '100px',
        paddingBottom: '2px',
        textTransform: 'none',
    },
}));

export interface ASelectedButtonProps extends AButtonBaseProps {
    icon?: ReactNode;
}

export default function ASelectedButton(props: ASelectedButtonProps) {
    const { icon, ...buttonProps } = props;

    return <StyledButton {...buttonProps} color='primary' startIcon={icon} variant='contained' />;
}
