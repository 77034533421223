// MUI
import { Container, Grid } from '@mui/material';

// Store
import { useAppSelector } from '../../store/hooks';

// Content
import RenderContent from '../RenderContent';
import { EditableContentType } from '../IEditableContent';

export interface ContentViewProps {
    type: EditableContentType;
}

export default function ContentView(props: ContentViewProps) {
    const loginStatus = useAppSelector((state) => state.loginStatus);

    if (loginStatus.data.loggedIn === null) return <></>;

    return (
        <Container data-robot-id={'app-body-' + props.type}>
            <Grid container>
                <Grid item xs={12}>
                    <RenderContent
                        displayLoader
                        publicSite={!loginStatus.data.loggedIn}
                        type={props.type}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
