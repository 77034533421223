import { NotificationTypeEnum } from '../../interfaces/INotification';

export const constructFarmVisitNotificationResourceTxt = (
    type: NotificationTypeEnum,
    resourceTxt: string,
    from: moment.Moment | null,
    contactName?: string,
    companyName?: string
) => {
    switch (type) {
        case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToFarm:
            return resourceTxt
                .replace('{SnellmanPerson}', contactName ?? '')
                .replace('{Date}', from?.format('DD.MM.YYYY') ?? '')
                .replace('{Time}', from?.format('HH:mm') ?? '');
        case NotificationTypeEnum.NewFarmVisitTwoDaysBeforeStartDateToPerson:
            return resourceTxt
                .replace('{CompanyName}', companyName ?? '')
                .replace('{Date}', from?.format('DD.MM.YYYY') ?? '')
                .replace('{Time}', from?.format('HH:mm') ?? '');
        default:
            return '';
    }
};
