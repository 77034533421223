// Libraries
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import SendIcon from '@mui/icons-material/Send';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AActionButton, ADefaultButton, AButtonBaseProps } from '../../common/buttons';

export interface ASendButtonProps extends Omit<AButtonBaseProps, 'children'> {
    children?: ReactNode;
    type?: 'action' | 'default';
}

export default function ASendButton(props: ASendButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { children, type, ...actionButtonProps } = props;
    const children2 = children ? children : t('AnelmaGeneral:1076');

    if (type === 'action')
        return <AActionButton {...actionButtonProps} children={children2} icon={<SendIcon />} />;
    return <ADefaultButton {...actionButtonProps} children={children2} icon={<SendIcon />} />;
}
