import axios from 'axios';
import { breedingContractUrl } from './apiEndpoints';
import { IApiListResult, IApiResult } from '../interfaces/IGeneral';

import { IBreedingContract, IBreedingContractDetails } from '../interfaces/IBreedingContract';
import ApiBase from './ApiBase';

class BreedingContractApi extends ApiBase {
    getProducerBreedingContracts(producerNumber: number) {
        return axios
            .get<IApiListResult<IBreedingContract>>(
                `${breedingContractUrl}?ProducerNumber=${producerNumber}`
            )
            .then((d) => d.data);
    }

    getProducerBreedingContractDetails(breedingContractNumber: number) {
        return axios
            .get<IApiListResult<IBreedingContractDetails>>(
                `${breedingContractUrl}/${breedingContractNumber}`
            )
            .then((d) => d.data);
    }
}

export default new BreedingContractApi();
