// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// MUI
import { Grid } from '@mui/material';

// Core
import FormValidator from '../../../core/FormValidator';
import { dayFormat } from '../../../core/constants';
import auth from '../../../core/authorization';
import {
    ParameterValues,
    ParameterValueWithLang,
    paramRepository,
    ResourceTextApplication,
} from '../../../core/resources';
import Utils from '../../../core/utils';

// Common
import ViewLoader from '../../../common/ViewLoader';
import { ALabel, ATextarea } from '../../../common/inputs';

// Interfaces
import {
    IAnimalPayloadItem,
    ICombinedPayloadBatchRow,
    ICombinedSalesType,
    IPayloadSms,
} from '../../../interfaces/IAnimal';

// Store
// API
// Feature - SMS
import { IReceiver } from '../../../shortMessageService/ISms';
import { SMS } from '../../../shortMessageService/constants/SMS';
import log from '../../../core/log';
import { enqueueSnackbar } from 'notistack';
import { getParameterValues } from './smsHelpers/parameterHelper';

export interface AnimalPayloadsSmsMessageElementProps {
    batch: ICombinedPayloadBatchRow;
    id: number;
    setMessage: (message: IPayloadSms) => void;
    payload: IAnimalPayloadItem;
}

export const AnimalPayloadsSmsMessageElement = ({
    batch,
    id,
    setMessage,
    payload,
}: AnimalPayloadsSmsMessageElementProps) => {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaAnimalPayload',
    ]);

    const [loading, setLoading] = useState<boolean>(false);
    const [smsMessage, setSmsMessage] = useState<string>();
    const [smsTemplateInit, setSmsTemplateInit] = useState<boolean>(false);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValueWithLang[]>();
    const [smsTemplateParams, setSmsTemplateParams] = useState<ParameterValueWithLang[]>();
    const [weekDayParams, setWeekDayParams] = useState<ParameterValueWithLang[]>();
    const [salesTypeBovineParams, setSalesTypeBovineParams] = useState<ParameterValues>();

    useEffect(() => {
        initializeParameters();
    }, []);

    useEffect(() => {
        if (
            weekDayParams &&
            salesTypeParams &&
            salesTypeBovineParams &&
            batch !== null &&
            payload !== null
        ) {
            const announcement = batch.CombinedSalesTypes[0]?.Announcements[0] ?? null;

            if (!smsTemplateInit) {
                initializeMsg(getSmsTemplateByAnnouncementType(announcement.AnnouncementType));
                setSmsTemplateInit(true);
            } else {
                const companyInfo = batch.CompanyInfo ?? payload.CompanyInfo ?? null;

                if (
                    announcement === null ||
                    companyInfo === null ||
                    companyInfo?.PhoneNumbers?.length === 0 ||
                    Utils.isGuidEmpty(companyInfo.Guid)
                )
                    return;

                const receivers: IReceiver[] = companyInfo?.PhoneNumbers?.map((p) => {
                    return {
                        EntityId: companyInfo.Guid ?? '',
                        ReceiverPhoneNumber: p,
                    } as IReceiver;
                });
                setMessage({
                    AnnouncementNumber: announcement.AnnouncementNumber,
                    Content: smsMessage,
                    MessageId: id + '', // Probably a template id?
                    PayloadNumber: payload.AnimalPayloadNumber,
                    Receivers: receivers,
                } as IPayloadSms);
            }
        }
    }, [weekDayParams, salesTypeParams, salesTypeBovineParams, smsTemplateParams, smsMessage]);

    const initializeParameters = () => {
        setLoading(true);

        const parameterPromises = [
            paramRepository
                .loadAllLanguages([
                    'AnelmaCommunication',
                    'AnelmaAnimalAnnouncement',
                    'AnelmaAnimalPayload',
                ])
                .then((response) =>
                    response.parameterGroups.forEach((group) => {
                        switch (group.id as string) {
                            case 'AnelmaCommunication':
                                setSmsTemplateParams(
                                    getParameterValues(response, 'AnimalPayloadSMSTemplates')
                                );
                                break;

                            case 'AnelmaAnimalAnnouncement':
                                setWeekDayParams(getParameterValues(response, 'CollectWeekDay'));
                                break;
                            case 'AnelmaAnimalPayload':
                                setSalesTypeParams(getParameterValues(response, 'SalesType'));
                                break;
                        }
                    })
                ),
            setSalesTypeBovineParams(
                paramRepository.resource('AnelmaAnimalPayload', 'SalesTypeBovine')
            ),
        ];

        Promise.all(parameterPromises).then(
            () => {
                setLoading(false);
            },
            () => {
                setLoading(false);
                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                    variant: 'error',
                });
            }
        );
    };

    const initializeMsg = (smsTemplate?: string) => {
        if (!smsTemplate) return;

        const replacements: any = {
            '{dayofweek}': () => {
                const weekDay = weekDayParams?.find(
                    (p) =>
                        Number(p.code) === payload.CollectWeekDay &&
                        p.langCode === batch.CompanyInfo.LanguageShortCode
                );
                return weekDay ? weekDay.text : '';
            },
            '{collectiondate}': () => moment(payload.CollectDay).format(dayFormat),
            '{collectiontimeperiod}': () => {
                const timeToUse = isOfSalesType(batch.CombinedSalesTypes, salesTypeBovineParams)
                    ? getTimePeriodForSms(batch.PlannedTime)
                    : batch?.PlannedTime;
                return timeToUse ?? '';
            },
            '{salestypeanimalcount}': () => getSalesTypesString(),
            '{carnumber}': () => payload.CarNumber.toString() ?? '',
            '{carphonenumber}': () => payload.PhoneNumber?.NormalizedNumber ?? '',
        };

        const message = Object.keys(replacements).reduce((currentTemplate, key) => {
            return currentTemplate.includes(key)
                ? currentTemplate.replace(key, replacements[key]())
                : currentTemplate;
        }, smsTemplate);

        setSmsMessage(message);
    };

    const getSmsTemplateByAnnouncementType = (type: number) => {
        if (smsTemplateParams) {
            switch (type) {
                case 1:
                    return smsTemplateParams?.find(
                        (p) =>
                            Number(p.code) === 2 &&
                            p.langCode === batch.CompanyInfo.LanguageShortCode
                    )?.text;
                case 2:
                    return smsTemplateParams?.find(
                        (p) =>
                            Number(p.code) === 0 &&
                            p.langCode === batch.CompanyInfo.LanguageShortCode
                    )?.text;
                case 3:
                    return smsTemplateParams?.find(
                        (p) =>
                            Number(p.code) === 1 &&
                            p.langCode === batch.CompanyInfo.LanguageShortCode
                    )?.text;
                default:
                    log('error', 'Announcement type was not found', {
                        type: type.toString(),
                    });
                    return '';
            }
        }

        return '';
    };

    const isOfSalesType = (
        salesTypes: ICombinedSalesType[],
        paramArray: ParameterValues | undefined
    ): boolean => {
        if (paramArray?.find((p) => salesTypes.find((st) => st.SalesType === Number(p.code))))
            return true;

        return false;
    };

    const getSalesTypesString = (): string => {
        return batch.CombinedSalesTypes.map((st) => {
            const paramFound = salesTypeParams?.find(
                (p) =>
                    Number(p.code) === st.SalesType &&
                    batch.CompanyInfo.LanguageShortCode === p.langCode
            );

            return paramFound ? `${paramFound?.text ?? ''} ${st.AnimalCount}` : '';
        }).join(' ');
    };

    const getTimePeriodForSms = (plannedTime: string) => {
        if (plannedTime.length === 4) {
            let hours = Number(plannedTime.substring(0, 2));
            let minutes = Number(plannedTime.substring(2, 4));

            if (isNaN(hours) || isNaN(minutes)) return plannedTime;

            const startTime = moment()
                .hours(hours)
                .minutes(minutes)
                .seconds(0)
                .add(-30, 'minutes')
                .format('HHmm');
            const endTime = moment()
                .hours(hours)
                .minutes(minutes)
                .seconds(0)
                .add(30, 'minutes')
                .format('HHmm');

            return `${startTime}-${endTime}`;
        }
    };

    return (
        <Grid container style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <Grid item xs={12}>
                <ALabel>{t('AnelmaAnimalPayload:1037')}</ALabel>
                {batch.CompanyInfo.Name}
            </Grid>
            <Grid item xs={12}>
                {!loading ? (
                    smsTemplateInit && (
                        <ATextarea
                            disabled={auth.canSendSms === false}
                            id={'animal-payloads-sms-message-textarea-input-' + id}
                            label={t('AnelmaAnimalPayload:1038')}
                            minLength={SMS.ContentMinimumLength}
                            maxLength={SMS.ContentMaximumLength}
                            onChange={setSmsMessage}
                            style={{ marginLeft: '-40px', marginTop: '10px' }}
                            validator={new FormValidator()}
                            value={smsMessage ?? ''}
                        />
                    )
                ) : (
                    <ViewLoader />
                )}
            </Grid>
        </Grid>
    );
};
