// Linraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import auth from '../core/authorization';
import FormValidator from '../core/FormValidator';

// Common
import { ADataGrid, ADataGridActions } from '../common/dataGrid';
import { AAddButton, AEditIconButton } from '../common/buttons';

// Interfaces
import { IBulletinItemDetails } from '../interfaces/ICommunication';

// Feature
import { BulletinRequest } from './api/bulletinRequest';
import { BulletinForm } from './BulletinForm';
import { createColumnDefs } from './BulletinColumnBuilder';
import { BulletinViewData, IBulletinGridItem } from './bulletinInterfaces';

export type BulletinEvent = 'ADD' | 'UPDATE';

export interface BulletinProps {
    data: IBulletinItemDetails[];
    isLoading: boolean;
    onSave: (e: BulletinEvent, m: BulletinRequest) => void;
}

export function BulletinView({ data, isLoading, onSave }: BulletinProps) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<IBulletinItemDetails | null>(null);
    const [event, setEvent] = useState<BulletinEvent>('UPDATE');
    const [bulletinData, setBulletinData] = useState<BulletinViewData[]>(
        data.map((a) => ({ id: a.Id, ...a }))
    );
    const [gridData, setGridData] = useState<IBulletinGridItem[]>([]);

    const { t } = useTranslation('AnelmaBulletin');
    const handleOpen = (e: BulletinEvent, m: IBulletinItemDetails | null) => {
        return () => {
            const data = bulletinData.find((b) => b.Id === m?.Id) || null;
            setOpen(true);
            setMessage(data);
            setEvent(e);
        };
    };

    useEffect(() => {
        setBulletinData(data.map((a) => ({ id: a.Id, ...a })));
    }, [data]);

    useEffect(() => {
        const getVisibility = (data: BulletinViewData): string => {
            if (data.IsInternal && data.IsPublic) return t('AnelmaBulletin:1000');
            else if (data.IsPublic) return t('AnelmaBulletin:1001');
            else if (data.IsInternal) return t('AnelmaBulletin:1002');
            return '-';
        };

        const sortedData = bulletinData.slice();
        sortedData.sort((a, b) => {
            if (a.ValidFromDate !== b.ValidFromDate)
                return a.ValidFromDate < b.ValidFromDate ? -1 : 1;
            if (a.ValidDueDate !== b.ValidDueDate) return a.ValidDueDate < b.ValidDueDate ? -1 : 1;
            return 0;
        });

        const data: IBulletinGridItem[] = [];
        let group = 1;
        sortedData.forEach((b) => {
            b.Message.forEach((m) => {
                data.push({
                    Culture: m.Culture,
                    Duration: b.Duration,
                    Group: group,
                    id: `${b.Id}-${m.Culture}`,
                    Id: b.Id,
                    MessageText: m.MessageText,
                    ValidDueDate: b.ValidDueDate,
                    ValidFromDate: b.ValidFromDate,
                    Visibility: getVisibility(b),
                });
            });
            group++;
        });

        data.sort((a, b) => {
            if (a.Group !== b.Group) return a.Group < b.Group ? 1 : -1;
            return a.Culture < b.Culture ? -1 : 1;
        });

        setGridData(data);
    }, [bulletinData, t]);

    function save(event: BulletinEvent): (m: BulletinRequest) => void {
        return (m) => onSave(event, m);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const actions: ADataGridActions = [];
    if (auth.canUpdateBulletinItem) {
        actions.push((params) => {
            const row = params.row as unknown as IBulletinItemDetails;
            return (
                <AEditIconButton
                    key='edit'
                    onClick={handleOpen('UPDATE', row)}
                    tooltip={t(`AnelmaBulletin:1013`)}
                />
            );
        });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container justifyContent='flex-end' style={{ marginBottom: '20px' }}>
                    <Grid item>
                        {auth.canCreateBulletinItem && (
                            <AAddButton onClick={handleOpen('ADD', null)} type='action'>
                                {t('AnelmaBulletin:1005')}
                            </AAddButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <div style={{ width: '100%' }}>
                <ADataGrid
                    actions={actions}
                    columns={createColumnDefs(gridData, t)}
                    enableFiltering
                    loading={isLoading}
                    rows={gridData}
                    enablePagination
                />
            </div>

            {open ? (
                <BulletinForm
                    data={message}
                    onSave={save(event)}
                    onClose={handleClose}
                    title={t(`AnelmaBulletin:${event === 'ADD' ? 1005 : 1013}`)}
                    validator={new FormValidator()}
                />
            ) : (
                ''
            )}
        </Grid>
    );
}
