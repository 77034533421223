// Libraries
import { ReactNode } from 'react';

// MUI
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

// Common
import { AButtonBaseProps } from '../../common/buttons';

const StyledButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(90deg, #d3d3d3 0%, #e3e3e3 100%)',
    borderRadius: '100px',
    textTransform: 'none',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.87)'
}));

export interface ADefaultButtonProps extends AButtonBaseProps {
    icon?: ReactNode;
}

export default function ADefaultButton(props: ADefaultButtonProps) {
    const { icon, ...buttonProps } = props;

    return <StyledButton {...buttonProps} startIcon={icon} variant='contained' />;
}
