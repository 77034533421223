// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Interfaces
import { AnimalTypeEnum } from '../../interfaces/enums';

// Feature
import { FarmInfoSectionProps } from './FarmInfo';
import ProductionLine from './ProductionLine';

export default function ProductionInfo(props: FarmInfoSectionProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    return (
        <div data-robot-id = {"production-info"}>
            <Typography variant='h3'>{t('AnelmaCompany:1040')}</Typography>

            <ProductionLine {...props} type={AnimalTypeEnum.Bovine} />

            <ProductionLine {...props} type={AnimalTypeEnum.Pork} />
        </div>
    );
}
