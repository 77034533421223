import { AnelmaReportingBtnGroupNameEnum, AnelmaReportTypeEnum } from '../interfaces/enums';
import ReportView from './ReportView';

export interface IButcheryReportProps {
    endQlikSession: (eligible: boolean) => void;
}

export default function ButcheryReport(props: IButcheryReportProps) {
    return (        
        <ReportView
            title={1046}
            btnGroupParameterName={AnelmaReportingBtnGroupNameEnum.SlaughterReport}
            type={AnelmaReportTypeEnum.SlaughterReport}
            endQlikSession={props.endQlikSession}
        />    
    );
}
