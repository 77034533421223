// Content
import { EditableContentType } from '../editableContent/IEditableContent';

// Feature
import InfoView from './InfoView';

export default function LabInfo() {
    return (       
        <InfoView
            anelmaInfoContentType={null}
            bovineContentType={EditableContentType.LabBovine}
            porkContentType={EditableContentType.LabPork}
            sharedContentType={EditableContentType.Lab}
            title={1044}
            applyContainer={true}
        />       
    );
}
