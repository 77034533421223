import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ResourceTextApplication } from '../../../core/resources';

export const CustomNoResultsOverlay = () => {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    return (
        <Stack height='100px' alignItems='center' justifyContent='center'>
            {t('AnelmaGeneral:1102')}
        </Stack>
    );
};
