// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import log from '../core/log';

// Common

// Interfaces
import { ISystemLogEvent } from '../interfaces/ILog';

// Feature
import {
    ADialog,
    ADialogContent,
    ADialogTitle,
    ADialogActions,
    ADialogButtonsProp,
} from '../common/dialog';
import SystemLogMessages from '../logging/SystemLogMessages';

// API
import api from '../api/systemLogApi';

export interface ISystemLogDialogProps {
    onClose: Function;
    eventCorrelationId: string;
    fromDatetime: string;
}

export default function SystemLogDialog(props: ISystemLogDialogProps) {
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [systemLogDialogData, setSystemLogDialogData] = useState<ISystemLogEvent[]>([]);

    useEffect(() => {
        setIsLoading(true);
        fetchSystemLogEvents(props.eventCorrelationId, props.fromDatetime);
    }, []);

    // #region API

    const fetchSystemLogEvents = async (correlationId: string, fromDatetime: string) => {
        await api
            .getSeqEvents(correlationId, fromDatetime)
            .then((response) => {
                if (!response) return;

                setIsLoading(false);
                setSystemLogDialogData(response.Items);
            })
            .catch((error) => {
                log('error', `Couldn't GET 'getSeqEvents'`, { error });
                handleSnackbarMessage(
                    `#Ongelma järjestelmälokin latauksen yhteydessä. Virheilmoitus: ${error}`,
                    'error'
                );
            });
    };
    // #endregion

    // #region Handlers
    const handleCancelBtnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onClose();
    };

    const handleSnackbarMessage = (
        message: string,
        variant: 'success' | 'error' | 'warning' | 'info'
    ) => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };
    // #endregion

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: handleCancelBtnClick,
                type: 'cancel',
            },
        ],
        right: [],
    };

    return (
        <ADialog open={true} onClose={() => props.onClose()} disableEnforceFocus>
            <ADialogTitle>{props.eventCorrelationId}</ADialogTitle>
            <ADialogContent size='xl' isLoading={isLoading}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {<SystemLogMessages logData={systemLogDialogData} />}
                </Grid>
            </ADialogContent>

            <ADialogActions buttons={footerActionButtons} />
        </ADialog>
    );
}
