// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Container } from '@mui/material';

// Core
import defaultData from '../core/defaultData';
import { paramRepository, ResourceTextApplication } from '../core/resources';
import FormValidator from '../core/FormValidator';

//Common
import { ViewTitle } from '../common/typography';

// Interfaces
import { IPersonSummary } from '../interfaces/IBusinessEntities';

// Feature
import PersonSearchBar from './search/PersonSearchBar';
import { PersonsView } from './PersonsView';
import PersonFormDialog from './PersonFormDialog';
import { useSnackbar } from 'notistack';
import ViewLoader from '../common/ViewLoader';

export default function Persons() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaPerson',
        'AnelmaCompany',
        'AnelmaLayout',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [addDialogVisible, setAddDialogVisible] = useState<boolean>(false);
    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const [personList, setPersonList] = useState<IPersonSummary[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        const promises = [paramRepository.load(['AnelmaCompany', 'AnelmaGeneral'])];
        Promise.all(promises).then(
            () => {
                setIsInitialized(true);
            },
            () => {
                setIsInitialized(false);
                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                    variant: 'error',
                });
            }
        );
    }, []);

    const personToEditData = defaultData.personDetails();

    return isInitialized ? (
        <Container data-robot-id = {"app-body-management-person-search"} >
            <ViewTitle>{t('AnelmaLayout:1002')}</ViewTitle>

            <PersonSearchBar
                afterSearch={(newPersonsList: IPersonSummary[]) => {
                    setPersonList(newPersonsList);
                    setIsLoading(false);
                }}
                beforeSearch={() => setIsLoading(true)}
                onShowInactiveChange={(v) => setShowInactive(v)}
                onShowMoreInfoChange={(v) => setShowMoreInfo(v)}
                openAddDialog={() => setAddDialogVisible(true)}
            />

            <PersonsView
                data={personList}
                isInactive={showInactive}
                isLoading={isLoading}
                showMoreInfo={showMoreInfo}
            />

            {addDialogVisible && (
                <PersonFormDialog
                    data={personToEditData}
                    isInactive={null}
                    mode='create'
                    onClose={() => setAddDialogVisible(false)}
                    validator={new FormValidator()}
                    fromCompanyModal={false}
                />
            )}
        </Container>
    ) : (
        <ViewLoader />
    );
}
