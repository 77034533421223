import moment from 'moment';
import { mergeRight } from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ParameterValues, paramRepository, ResourceTextApplication } from '../core/resources';
import { ICQRSEventBase } from '../interfaces/ILog';
import { ICQRSEventBaseRow } from './table/CollapsibleRow';
import CollapsibleTable from './table/CollapsibleTable';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import { TableHead } from '@mui/material';
import {
    GetNewData,
    GetOldData,
    ParseCommandToString,
    ParseField,
    ParseEntityTypeToString,
    ParseEntityName,
    GetFields,
} from './helpers/CommandEventParser';
import ViewLoader from '../common/ViewLoader';
import { useAppSelector } from '../store/hooks';
import { Configuration } from '../store/configurationSlice';
import defaultData from '../core/defaultData';
import { useSnackbar } from 'notistack';

// API
import messagingApi from '../api/messagingApi';

export interface LoggingViewProperties {
    data: ICQRSEventBase[];
    openDialog: (par: string | number) => any;
    openSystemLogDialog: (correlationId: string, fromDatetime: string) => void;
    initiateSearch: () => void; // to update data after action e.g. support msg is sent
}

export default function LoggingView(props: LoggingViewProperties) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAudit']);
    const [AALoggedOperationResources, setAALoggedOperationResources] =
        React.useState<ParameterValues>([]);
    const [config, setConfig] = React.useState<Configuration>(defaultData.configurations());
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const conf = useAppSelector((state) => state.configuration);

    useEffect(() => {
        paramRepository.load(['AnelmaAudit']).then(() => {
            setAALoggedOperationResources(
                paramRepository.resource('AnelmaAudit', 'LoggedOperation')
            );

            while (conf.status !== 'ready') continue;

            setConfig(conf.data);
            setLoaded(true);
        });
    }, []);

    const mapDataCell: (JsonData: string, tabs: number) => JSX.Element = (
        JsonData: string,
        tabs: number
    ) => {
        const style: (addTab?: boolean) => React.CSSProperties = (addTab?: boolean) => {
            var ret = {
                marginLeft: String(tabs * 30 + 'px'),
            };
            if (addTab) tabs++;

            return ret;
        };
        var res = JsonData.split('  ')
            .map((s) => {
                if (s.startsWith('{')) tabs++;
                else if (s.startsWith('}')) tabs--;
                else if (s.includes('{')) {
                    return <p style={style(true)}>{s.substr(0, s.length - 2)}</p>;
                } else if (s.includes('}')) {
                    return <p style={style()}>{s.substr(0, s.length - 2)}</p>;
                } else return <p style={style()}>{s}</p>;
            })
            .filter(function (e) {
                return e !== undefined;
            }) as JSX.Element[];
        return <TableCell>{res}</TableCell>;
    };

    const handleSendSupportMessage = (cqrsEvent: ICQRSEventBase) => {
        setLoaded(false);
        messagingApi
            .sendSupportMessage(
                config.emailMessages.supportRequest.from,
                config.emailMessages.supportRequest.to,
                cqrsEvent.CorrelationId
            )
            .then((response) => {
                if (!response) {
                    enqueueSnackbar(`${t('AnelmaGeneral:1176')}`, {
                        variant: 'error',
                    });
                    setLoaded(true);
                    return;
                }
                enqueueSnackbar(t('AnelmaGeneral:1063'), {
                    variant: 'success',
                });
                setLoaded(true);
                props.initiateSearch();
            });
    };

    const logDataRows = props.data.map<ICQRSEventBaseRow>((i, id) => {
        return mergeRight(i, {
            id: id.toString(),
            Command: ParseCommandToString(i, AALoggedOperationResources).text,
            Field: ParseField(i, t),
            OldData: GetOldData(i),
            NewData: GetNewData(i),
            Timestamp: moment(i.Timestamp).format('DD.MM.YYYY HH:mm'),
            Body: (
                <TableBody>
                    <TableRow>
                        <TableCell component='th' scope='row'>
                            {' '}
                            {t('AnelmaAudit:1023')} : {i.CorrelationId}{' '}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component='th' scope='row' onClick={(e) => props.openDialog(id)}>
                            <a href='' onClick={(e) => e.preventDefault()}>
                                {' '}
                                {t('AnelmaAudit:1024')}{' '}
                            </a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            component='th'
                            scope='row'
                            onClick={() => handleSendSupportMessage(i)}
                        >
                            <a href='' onClick={(e) => e.preventDefault()}>
                                {' '}
                                {t('AnelmaAudit:1025')}{' '}
                            </a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            component='th'
                            scope='row'
                            onClick={(e) =>
                                props.openSystemLogDialog(
                                    i.CorrelationId,
                                    moment(new Date(`${i.Timestamp}` as string) as Date)
                                        .utc()
                                        .format('YYYY-MM-DD HH:mm')
                                )
                            }
                        >
                            <a href='' onClick={(e) => e.preventDefault()}>
                                {t('AnelmaAudit:1063')}
                            </a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <Box margin={1}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('AnelmaAudit:1026')}</TableCell>
                                        <TableCell style={{ maxWidth: 200 }}>
                                            {t('AnelmaAudit:1027')}
                                        </TableCell>
                                        <TableCell style={{ maxWidth: 200 }}>
                                            {t('AnelmaAudit:1028')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {GetFields(i).map<JSX.Element>((f) => (
                                        <TableRow>
                                            <TableCell>{f.Field}</TableCell>
                                            {mapDataCell(f.OldData, 0)}
                                            {mapDataCell(f.NewData, 0)}
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </TableRow>
                </TableBody>
            ),
            EntityType: ParseEntityTypeToString(i, t),
            Name: ParseEntityName(i, AALoggedOperationResources, t),
        });
    });

    return loaded ? (
        <CollapsibleTable rows={logDataRows} rowOnClick={props.openDialog} />
    ) : (
        <ViewLoader />
    );
}
