// Libraries
import { useState } from 'react';

// MUI
import { ListItem, ListItemText } from '@mui/material';
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

// Core
import defaultData from '../../../../core/defaultData';
import FormValidator from '../../../../core/FormValidator';

// Common
import UserAvatar from '../../../../common/UserAvatar';

// Interfaces
import { IDocumentAddDialogData, IBasicDocument } from '../../../../documents/IDocuments';
import { IApiResult } from '../../../../interfaces/IGeneral';

// Store
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateProfilePictureAsync } from '../../../../store/profilePictureSlice';

// Documents
import DocumentAddDialog from '../../../../documents/addDialog/DocumentAddDialog';

// Feature
import './userInfo.scss';

export default function UserInfo() {
    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const userData = useAppSelector((state) => state.userData.data);

    const [uploadDialogVisible, setUploadDialogVisible] = useState<boolean>(false);

    const getName = () => {
        if (userData?.FirstName && userData.LastName)
            return `${userData.FirstName} ${userData.LastName}`;
        return loginStatus.name;
    };

    const dispatch = useAppDispatch();
    const saveProfilePicture = (itemToSave: IDocumentAddDialogData) => {
        return new Promise<IApiResult<IBasicDocument> | null>((resolve) => {
            dispatch(updateProfilePictureAsync(itemToSave)).then((response) => {
                setUploadDialogVisible(false);
                resolve(response);
            });
        });
    };

    return (
        <div className='an-user-info' data-robot-id= {"user-info"}>
            <ListItem>
                <div data-robot-id= {"user-info-avatar"} className='an-user-info-avatar' onClick={() => setUploadDialogVisible(true)}>
                    <UserAvatar personId={userData?.Id} />

                    <div  data-robot-id= {"user-info-change"} className='an-user-info-change'>
                        <AddPhotoIcon data-robot-id= {"user-info-add-photo"} className='an-user-menu-upload-image' />
                    </div>
                </div>
            </ListItem>

            <ListItem>
                <ListItemText primary={getName()} style={{ textAlign: 'center' }} data-robot-id= {"user-info-list-item-userId"} />
            </ListItem>

            {uploadDialogVisible && (
                <DocumentAddDialog
                    data-robot-id= {"user-info-document-add-dialog"}
                    afterSave={() => {}}
                    config='profilePicture'
                    data={defaultData.documentDetails()}
                    initialType={1}
                    onClose={() => setUploadDialogVisible(false)}
                    onSave={saveProfilePicture}
                    validator={new FormValidator()}
                />
            )}
        </div>
    );
}
