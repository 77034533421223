//React
import { useState, useEffect } from 'react';

// MUI
import { Container } from '@mui/material';

// Store
import { useAppSelector } from '../store/hooks';

// Libraries
// Common
import ViewLoader from '../common/ViewLoader';

// Core
import auth from '../core/authorization';

//api
import companyApi from '../api/companyApi';

//Feature
import AnimalBreedingListView, { AnimalBreedingsListViewProps } from './AnimalBreedingListView';

//interface
import { AliasTypeEnum } from '../interfaces/enums';

export default function AnimalBreedings() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [producerNumberContext, setProducerNumberContext] = useState<number>(0);

    const animalBreedingListViewProps: AnimalBreedingsListViewProps = {
        producerNumber: producerNumberContext,
    };

    const context = useAppSelector((state) => state.context);

    useEffect(() => {
        if (context.data.currentContext) {
            setIsLoading(true);
            companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    const producerNumberContext = response?.Entity.Aliases.find(
                        (i) => i.Type === AliasTypeEnum.ProducerNumber
                    );
                    setProducerNumberContext(producerNumberContext?.Value as unknown as number);
                });

            setIsLoading(false);
        } else setProducerNumberContext(0);
    }, [context]);
    return (
        <Container data-robot-id={'app-body-animal-breedings'}>
            {!isLoading ? (
                auth.canViewAnimalBreedings && (
                    <AnimalBreedingListView {...animalBreedingListViewProps} />
                )
            ) : (
                <ViewLoader />
            )}
        </Container>
    );
}
