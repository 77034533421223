export interface DocumentDialogConfig {
    acceptedFiles?: string[];
    displayName: boolean;
    name: DocumentDialogConfigName;
    validFrom: boolean;
    validDue: boolean;
    maxFileSize: number;
    filesLimit: number;
}

export type DocumentDialogConfigName =
    | 'none'
    | 'multi'
    | 'default'
    | 'profilePicture'
    | 'images'
    | 'infoDocuments'
    | 'farmDocuments';

const configs: DocumentDialogConfig[] = [
    {
        displayName: false,
        name: 'none',
        validFrom: false,
        validDue: false,
        maxFileSize: 5242880,

        filesLimit: 1,
    },
    {
        displayName: true,
        name: 'default',
        validFrom: true,
        validDue: true,
        maxFileSize: 5242880,
        filesLimit: 1,
    },
    {
        acceptedFiles: ['image/*'],
        displayName: false,
        name: 'profilePicture',
        validFrom: false,
        validDue: false,
        maxFileSize: 5242880,
        filesLimit: 1,
    },
    {
        acceptedFiles: ['image/*'],
        displayName: false,
        name: 'images',
        validFrom: false,
        validDue: false,
        maxFileSize: 5242880,
        filesLimit: 5,
    },
    {
        acceptedFiles: ['application/pdf'],
        displayName: true,
        name: 'infoDocuments',
        validFrom: false,
        validDue: false,
        maxFileSize: 5242880,
        filesLimit: 1,
    },
    {
        acceptedFiles: ['application/pdf', 'image/jpeg'],
        displayName: true,
        name: 'farmDocuments',
        validFrom: false,
        validDue: false,
        maxFileSize: 5242880,
        filesLimit: 1,
    },
];

const config = (name: DocumentDialogConfigName): DocumentDialogConfig => {
    const c = configs.find((i) => i.name === name);

    return c || (configs.find((i) => i.name === 'default') as DocumentDialogConfig);
};

export default config;
