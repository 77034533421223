// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Container, Grid } from '@mui/material';

// Core
import FormValidator from '../core/FormValidator';

// Common
import { ARadioButtons } from '../common/inputs';
import { ViewTitle } from '../common/typography';

// Interfaces
import { InfoSections, InfoSectionsGmFree, InfoSectionType } from '../interfaces/enums';

// Api
import api from '../api/contentApi';

// Content
import { EditableContentType } from '../editableContent/IEditableContent';
import Section from './Section';

export interface InfoViewProps {
    anelmaInfoContentType: EditableContentType | null;
    bovineContentType: EditableContentType;
    porkContentType: EditableContentType;
    sharedContentType: EditableContentType;
    title?: number;
    applyContainer: boolean;
}

export default function InfoView(props: InfoViewProps) {
    const { t } = useTranslation(['AnelmaLayout', 'AnelmaInstructions']);

    const [section, setSection] = useState<InfoSections>(InfoSections.general);
    const [gmFreeSection, setGmFreeSection] = useState<InfoSectionsGmFree>(
        InfoSectionsGmFree.general
    );
    const [shared, setShared] = useState<string>();
    const [bovine, setBovine] = useState<string>();
    const [pork, setPork] = useState<string>();
    const [anelmaInfo, setAnelmaInfo] = useState<string>();

    useEffect(() => {
        if (section === InfoSections.general && shared === undefined) {
            api.getContentByType(true, props.sharedContentType).then((response) => {
                setShared(response ? response.Entity.Content : '');
            });
        }

        if (section === InfoSections.bovine && bovine === undefined) {
            api.getContentByType(true, props.bovineContentType).then((response) => {
                setBovine(response ? response.Entity.Content : '');
            });
        }
        if (section === InfoSections.pork && pork === undefined) {
            api.getContentByType(true, props.porkContentType).then((response) => {
                setPork(response ? response.Entity.Content : '');
            });
        }
        if (
            section === InfoSections.anelmaInfo &&
            anelmaInfo === undefined &&
            props.anelmaInfoContentType
        ) {
            api.getContentByType(true, props.anelmaInfoContentType).then((response) => {
                setAnelmaInfo(response ? response.Entity.Content : '');
            });
        }
    }, [section]);

    useEffect(() => {
        if (gmFreeSection === '1' && shared === undefined) {
            api.getContentByType(true, props.sharedContentType).then((response) => {
                setShared(response ? response.Entity.Content : '');
            });
        }

        if (gmFreeSection === '2' && bovine === undefined) {
            api.getContentByType(true, props.bovineContentType).then((response) => {
                setBovine(response ? response.Entity.Content : '');
            });
        }
        if (gmFreeSection === '3' && pork === undefined) {
            api.getContentByType(true, props.porkContentType).then((response) => {
                setPork(response ? response.Entity.Content : '');
            });
        }
    }, [gmFreeSection]);

    const getTitle = () => {
        return props.title !== undefined ? (
            <ViewTitle>{t(`AnelmaLayout:${props.title}`)}</ViewTitle>
        ) : null;
    };

    /** Return the contents of the component. This way one can decide not to include the MUI container used in multiple other pages. On front-page that is not needed. */
    const getInfoView = (): JSX.Element => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {getTitle()}
                    <ARadioButtons
                        id='sections'
                        parameterName={
                            props.anelmaInfoContentType === EditableContentType.GenericContent
                                ? 'AnelmaInstructions:InfoSections'
                                : props.anelmaInfoContentType === EditableContentType.GMFree
                                ? 'AnelmaInstructions:StoreSections'
                                : props.anelmaInfoContentType ===
                                  EditableContentType.InstructionsAnelmaInfo
                                ? 'AnelmaInstructions:InfoSections'
                                : 'AnelmaInstructions:InstructionSections'
                        }
                        onChange={(v) => {
                            if (props.anelmaInfoContentType === EditableContentType.GMFree)
                                setGmFreeSection(v as any as InfoSectionsGmFree);
                            else setSection(v as any as InfoSections);
                        }}
                        validator={new FormValidator()}
                        value={
                            props.anelmaInfoContentType === EditableContentType.GMFree
                                ? gmFreeSection
                                : section
                        }
                        withoutContainer
                    />
                    <br />
                    <br />
                    <Section
                        section={
                            props.anelmaInfoContentType === EditableContentType.GMFree
                                ? gmFreeSection
                                : section
                        }
                        sectionType={
                            props.anelmaInfoContentType !== EditableContentType.GMFree
                                ? InfoSectionType.InfoSection
                                : InfoSectionType.InfoSectionsGmFree
                        }
                        shared={shared}
                        bovine={bovine}
                        pork={pork}
                        anelmaInfo={anelmaInfo}
                    />
                </Grid>
            </Grid>
        );
    };

    return props.applyContainer ? <Container>{getInfoView()}</Container> : getInfoView();
}
