import { ImageList, ImageListItem } from '@mui/material';

export interface AImageListProps {
    files: File[];
    cols: number;
    gap: number;
    imgMaxHeight: number;
    imgMaxWidth: number;
}

export default function AImageList(props: AImageListProps) {
    const filterImageFiles = (files: File[]) => {
        return files.filter((file) => file.type && file.type.startsWith('image/'));
    };

    const imageFiles = filterImageFiles(props.files);

    return (
        <ImageList cols={props.cols} gap={props.gap}>
            {imageFiles.map((file, index) => (
                <ImageListItem key={index}>
                    <img
                        style={{
                            maxWidth: props.imgMaxWidth + 'px',
                            maxHeight: props.imgMaxHeight + 'px',
                        }}
                        src={URL.createObjectURL(file)}
                        alt={`Preview ${index}`}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}
