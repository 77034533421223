import { animalListUrl, nasevaUrl } from './apiEndpoints';
import ApiBase from './ApiBase';
import { INasevaResult } from '../interfaces/IAnimal';
import { AnelmaCultureType, GUIDType } from '../interfaces/types';

class NasevaApi extends ApiBase {
    getLatestAnnualVisits = (farmGuid: GUIDType, culture: AnelmaCultureType | undefined) => {
        if(culture === 'fi') culture = 'fi-FI';
        return this.getList<INasevaResult>(`${nasevaUrl}/get-visits/${farmGuid}/${culture}`);
    };
}

export default new NasevaApi();
