// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

// Core
import { paramRepository, ParameterValues, ResourceTextApplication } from '../../core/resources';

// Common
import { InfoBlock } from '../../common/typography';

// Feature
import { FarmInfoSectionProps } from './FarmInfo';

export default function FarmInfo(props: FarmInfoSectionProps) {
    const { data, getParamText } = props;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    const [dairy, setDairy] = useState<ParameterValues>([]);
    const [feedSupplier, setFeedSupplier] = useState<ParameterValues>([]);

    useEffect(() => {
        paramRepository.load(['AnelmaCompany', 'AnelmaGeneral']).then(() => {
            setDairy(paramRepository.resource('AnelmaCompany', 'Dairy'));
            setFeedSupplier(paramRepository.resource('AnelmaCompany', 'FeedSupplier'));
        });
    }, []);

    return (
        <div data-robot-id = {"coop-info"}>
            <Typography variant='h3'>{t('AnelmaCompany:1041')}</Typography>

            <InfoBlock
                label={t('AnelmaCompany:1031')}
                value={data.FeedSuppliers.map((i) => getParamText(feedSupplier, i)).join(', ')}
            />

            <InfoBlock
                label={t('AnelmaCompany:1032')}
                value={data.Dairy.map((i) => getParamText(dairy, i)).join(', ')}
            />
        </div>
    );
}
