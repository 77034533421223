import { createTheme } from '@mui/material/styles';

const headerFontFamily = ['Taberna', 'Montserrat', 'Arial'].join(',');
const bodyFontFamily = [
    'TheSans',
    'PT Sans',
    'Helvetica Neue',
    'Helvetice',
    'Roboto',
    'Arial',
    'sans-serif',
].join(',');

export const anelmaTheme = createTheme({
    palette: {
        primary: {
            main: '#0a4c31',
        },
    },
    typography: {
        fontFamily: bodyFontFamily,
        h1: {
            color: '#0a4c31',
            fontFamily: headerFontFamily,
            fontSize: '56px',
            letterSpacing: 5,
            lineHeight: '63px',
            textTransform: 'lowercase',
        },
        h2: {
            color: '#0a4c31',
            fontFamily: headerFontFamily,
            fontSize: '42px',
            lineHeight: '47px',
            textTransform: 'lowercase',
        },
        h3: {
            color: '#0a4c31',
            fontFamily: headerFontFamily,
            fontSize: '32px',
            lineHeight: '36px',
            textTransform: 'lowercase',
        },
        h4: {
            color: '#0a4c31',
            fontFamily: bodyFontFamily,
            fontSize: '24px',
            lineHeight: '27px',
        },
        h5: {
            color: '#0a4c31',
            fontFamily: bodyFontFamily,
            fontSize: '18px',
            lineHeight: '27px',
        },
        h6: {
            color: '#0a4c31',
            fontFamily: bodyFontFamily,
            fontSize: '16px',
            lineHeight: '21px',
        },
    },
});
