import { IMainMenuItem } from '../mainMenu/IMainMenu';
import { RoutePaths } from '../../../../interfaces/enums';

export const reporting: IMainMenuItem = {
    itemGroups: [
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.ReportingButchery,
                    resource: 'AnelmaButcheryReport',
                    scope: 'LIST',
                    textCode: 1046,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.ReportingProduction,
                    resource: 'AnelmaProductionReport',
                    scope: 'LIST',
                    textCode: 1048,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.ReportingHealth,
                    resource: 'AnelmaHealthReport',
                    scope: 'LIST',
                    textCode: 1047,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.ReportingFinancial,
                    resource: 'AnelmaFinancialReport',
                    scope: 'LIST',
                    textCode: 1079,
                },
            ],
        },
    ],
    textCode: 1038,
};
