import { IAnimalSummary, IFilteredAnimalList } from '../../interfaces/IAnimal';
import { carouselConfig } from './ACarouselConfig';
import { ParamFilter } from './AnimalList';

export const getFilteredAnimals = (
    allAnimals: IFilteredAnimalList[],
    selectedFilter: ParamFilter
): IAnimalSummary[] => {
    return carouselConfig.getAnimals(allAnimals, selectedFilter);
};
