import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AEditIconButton from '../../../common/buttons/AEditIconButton';
import { ASwitch } from '../../../common/inputs';
import { IFormValidator } from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';
import { FarmingTypeEnum, OrganicProductionState } from '../../../interfaces/enums';
import { IFarmDetails } from '../../../interfaces/IBusinessEntities';
import {
    IFarmSupplier,
    IProductionPlan,
    IProductionPlanAccess,
} from '../../../interfaces/IProductionPlan';
import { flagSellerFarmsDeleted } from '../helpers/FarmSupplierHelper';
import ExternalSeller from './ExternalSeller';
import ExternalSellerDialog from './ExternalSellerDialog';

interface IProps {
    data: IProductionPlan;
    access: IProductionPlanAccess;
    readonly?: boolean;
    validator: IFormValidator;
    farmDetails: IFarmDetails;
    handleSellerFarmsChange: (_: IFarmSupplier[]) => void;
    handleBuyCalfsChange: (_: boolean) => void;
}

export default function BasicFarmInfo({
    data,
    access,
    readonly,
    validator,
    farmDetails,
    handleSellerFarmsChange,
    handleBuyCalfsChange,
}: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCattleProductionPlan']);

    const [showCowsAreInCowShed] = useState<boolean>(
        access.HasDairyAnimals || access.HasBreedingAnimals
    );

    const [organicProductionState, setOrganicProductionState] = useState<OrganicProductionState>(
        data.OrganicProductionState
    );

    const [showOrganicProduction, setShowOrganicProduction] = useState<boolean>(
        data.OrganicFarmingPerformed
    );

    const [showExternalSellerDialog, setShowExternalSellerDialog] = useState<boolean>(false);
    const [buysCalfsDirectlyFromOtherFarm, setBuysCalfsDirectlyFromOtherFarm] = useState<boolean>(
        data.BuysCalfsDirectlyFromOtherFarm
    );

    const organicProductionStateChanged = (checked: boolean, state: OrganicProductionState) => {
        if (checked) data.OrganicProductionState = state;
        else data.OrganicProductionState = OrganicProductionState.None;
        setOrganicProductionState(data.OrganicProductionState);
    };

    const organicFarmingPerformedChanged = (checked: boolean) => {
        data.OrganicFarmingPerformed = checked;

        if (!checked) {
            data.OrganicProductionState = OrganicProductionState.None;
            setOrganicProductionState(data.OrganicProductionState);
        }
        setShowOrganicProduction(checked);
    };

    const onBuysCalfsSwitchChange = (value: boolean) => {
        if (value) {
            setBuysCalfsDirectlyFromOtherFarm(value);
            handleBuyCalfsChange(value);
            setShowExternalSellerDialog(true);
        } else {
            setBuysCalfsDirectlyFromOtherFarm(false);
            handleSellerFarmsChange(flagSellerFarmsDeleted(data.SellingFarms));
            handleBuyCalfsChange(false);
        }
    };

    const onExternalSellerDialogSave = (farms: IFarmSupplier[]) => {
        if (!buysCalfsDirectlyFromOtherFarm) {
            handleBuyCalfsChange(true);
            setBuysCalfsDirectlyFromOtherFarm(true);
        }
        
        handleSellerFarmsChange(farms);
    };

    const getExternalSellers = () => {
        if (buysCalfsDirectlyFromOtherFarm)
            return data.SellingFarms.map((_) => {
                if (_.Flags === 0)
                    return (
                        <ExternalSeller
                            key={Date.now() + Math.random()}
                            readonly={true}
                            validator={validator}
                            sellerFarm={_}
                        />
                    );
            });
    };

    const isDairyFarmOnly: boolean =
        access.ProductionDirection.findIndex((d) => d === FarmingTypeEnum.Dairy) !== -1 &&
        access.ProductionDirection.findIndex((d) => d !== FarmingTypeEnum.Dairy) === -1;

    return (
        <Grid container direction='row' style={{ paddingLeft: 40, paddingTop: '20px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h4'>{t('AnelmaLayout:1070')}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                {showCowsAreInCowShed && (
                    <ASwitch
                        id={'CowsAreInCowshed'}
                        label={t('AnelmaCattleProductionPlan:1023')}
                        checked={data.CowsAreInCowshed}
                        disabled={readonly}
                        validator={validator}
                        onChange={(checked) => {
                            data.CowsAreInCowshed = checked;
                        }}
                    />
                )}

                <ASwitch
                    id={'LimousineMembershipExists'}
                    label={t('AnelmaCattleProductionPlan:1024')}
                    checked={data.LimousineMembershipExists}
                    disabled={readonly}
                    validator={validator}
                    onChange={(checked) => {
                        data.LimousineMembershipExists = checked;
                    }}
                />
                {/*
                Removed for now per customer request:   http://jira.mtech.fi/browse/AN-2204 
                {!isDairyFarmOnly && (
                 <ASwitch
                    id={'PreliminaryNotificationsRequired'}
                    label={t('AnelmaCattleProductionPlan:1025')}
                    checked={data.PreliminaryNotificationsRequired}
                    disabled={readonly || isDairyFarmOnly}
                    validator={validator}
                    onChange={(checked) => {
                        data.PreliminaryNotificationsRequired = checked;
                    }}
                />
                )}
               */}
                <ASwitch
                    id={'PaperAccounting'}
                    label={t('AnelmaCattleProductionPlan:1026')}
                    checked={data.PrintedClearanceRequested}
                    disabled={readonly}
                    validator={validator}
                    onChange={(checked) => {
                        data.PrintedClearanceRequested = checked;
                    }}
                />
                <ASwitch
                    id={'OrganicFarmingPerformed'}
                    label={t('AnelmaCattleProductionPlan:1027')}
                    checked={data.OrganicFarmingPerformed}
                    disabled={readonly}
                    validator={validator}
                    onChange={(checked) => {
                        organicFarmingPerformedChanged(checked);
                    }}
                />
            </Grid>

            {showOrganicProduction && (
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingLeft: '30px' }}>
                    <ASwitch
                        id={'organic-prod-fields-only'}
                        label={t('AnelmaCattleProductionPlan:1029')}
                        checked={organicProductionState === OrganicProductionState.FieldsOnly}
                        disabled={readonly}
                        validator={validator}
                        onChange={(checked) => {
                            organicProductionStateChanged(
                                checked,
                                OrganicProductionState.FieldsOnly
                            );
                        }}
                    />
                    <ASwitch
                        id={'organic-prod-fields-and-bovine'}
                        label={t('AnelmaCattleProductionPlan:1030')}
                        checked={organicProductionState === OrganicProductionState.FieldsAndBovine}
                        disabled={readonly}
                        validator={validator}
                        onChange={(checked) => {
                            organicProductionStateChanged(
                                checked,
                                OrganicProductionState.FieldsAndBovine
                            );
                        }}
                    />
                    <ASwitch
                        id={'organic-prod-transition-phase'}
                        label={t('AnelmaCattleProductionPlan:1028')}
                        checked={organicProductionState === OrganicProductionState.TransitionPhase}
                        disabled={readonly}
                        validator={validator}
                        onChange={(checked) => {
                            organicProductionStateChanged(
                                checked,
                                OrganicProductionState.TransitionPhase
                            );
                        }}
                    />
                </Grid>
            )}

            <Grid item xs={12} sm={12} md={12} lg={12}>
                {t('AnelmaCattleProductionPlan:1031')}
                <ASwitch
                    id={'NewSalmonellaSampleOrdered'}
                    label={t('AnelmaCattleProductionPlan:1032')}
                    checked={data.NewSalmonellaSampleOrdered}
                    disabled={readonly}
                    validator={validator}
                    onChange={(checked) => {
                        data.NewSalmonellaSampleOrdered = checked;
                    }}
                />
                {!isDairyFarmOnly && (
                    <AEditIconButton
                        onClick={() => setShowExternalSellerDialog(true)}
                        tooltip={t('AnelmaGeneral:1075')}
                        style={{ float: 'right', paddingTop: 0 }}
                    />
                )}
                {!isDairyFarmOnly && (
                    <ASwitch
                        id={'BuysCalfsDirectlyFromOtherFarm'}
                        label={t('AnelmaCattleProductionPlan:1033')}
                        checked={buysCalfsDirectlyFromOtherFarm}
                        disabled={readonly || isDairyFarmOnly}
                        validator={validator}
                        onChange={(_) => onBuysCalfsSwitchChange(_)}
                    /> 
                )}
            </Grid>

            {<>{getExternalSellers()}</>}

            {showExternalSellerDialog ? (
                <ExternalSellerDialog
                    readonly={readonly}
                    validator={validator}
                    onClose={() => setShowExternalSellerDialog(false)}
                    onSave={(_: IFarmSupplier[]) => onExternalSellerDialogSave(_)}
                    onCancel={(_: IFarmSupplier[]) => handleSellerFarmsChange(_)}
                    sellerFarms={data.SellingFarms}
                    farmDetails={farmDetails}
                />
            ) : null}
        </Grid>
    );
}
