// Libraries
import { ForwardedRef, forwardRef, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import {
    Autocomplete,
    AutocompleteInputChangeReason,
    StandardTextFieldProps,
    createFilterOptions,
} from '@mui/material';

// Core
import { paramRepository, ResourceTextApplication, ParameterSorting } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import { AInputContainer, AInputItem } from '../../common/inputs';

// Interfaces
// Store
// API
// Feature
import { StyledTextField } from './ATextInput';

interface AAutocompleteBaseProps {
    error?: boolean;
    id: string;
    label?: string;
    onChange: (value: AAutocompleteValue) => any;
    required?: boolean;
    style?: React.CSSProperties;
    validator: IFormValidator;
    value: AAutocompleteValue;
    withoutContainer?: boolean;
    forceValidEmpty?: boolean;
    isContextSelector?: boolean;
    disabled?: boolean;
}

interface AAutocompleteWithItemsProps extends AAutocompleteBaseProps {
    getOptionLabel?: (option: AInputItem<AAutocompleteValue>) => string;
    items: AInputItem<AAutocompleteValue>[];
}

interface AAutocompleteWithParamProps extends AAutocompleteBaseProps {
    parameterName: string;
    sortValues?: ParameterSorting;
}

interface AAutocompleteWithApiProps<T> extends AAutocompleteBaseProps {
    formatItem: (item: T) => AInputItem<AAutocompleteValue>;
    initialOptions?: AInputItem<AAutocompleteValue>[];
    minSearchLength: number;
    search: (inputValue: string) => Promise<T[]>;
}

interface InputValue {
    value: string;
    changeReason: AutocompleteInputChangeReason;
}

export type AAutocompleteValue = number | string | null;
export type AAutocompleteProps =
    | AAutocompleteWithItemsProps
    | AAutocompleteWithParamProps
    | AAutocompleteWithApiProps<any>;

export default forwardRef(function AAutocomplete(
    props: AAutocompleteProps,
    ref: ForwardedRef<any>
) {
    const { error, id, required, validator, forceValidEmpty } = props;
    const { parameterName, sortValues } = props as AAutocompleteWithParamProps;
    const { formatItem, initialOptions, minSearchLength, search } =
        props as AAutocompleteWithApiProps<any>;
    const { getOptionLabel, items } = props as AAutocompleteWithItemsProps;

    const mode = !!search ? 'api' : !!parameterName ? 'parameter' : 'items';

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const [options, setOptions] = useState<AInputItem<AAutocompleteValue>[]>(() => {
        if (mode === 'api' && initialOptions) return initialOptions;
        return items || [];
    });
    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<AInputItem<AAutocompleteValue> | null>(() => {
        if (mode === 'api' && initialOptions) {
            const v = initialOptions.find((o) => o.value === props.value);
            return v || null;
        }
        return null;
    });
    const [err, setErr] = useState<boolean>(false);
    const [inputVal, setInputVal] = useState<InputValue>({ value: '', changeReason: 'reset' });
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const filterOptions = createFilterOptions({
        matchFrom: props.isContextSelector === undefined ? 'start' : 'any',
        stringify: (option: AInputItem<AAutocompleteValue>) => option.text,
    });

    // All cases
    useEffect(() => {
        const isValid = () => {
            if (forceValidEmpty && val === null && inputVal.changeReason === 'reset') return true;
            if (error) return false;
            if (required && val === null) return false;
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setErr(!isValidAfterTouch());
    }, [error, id, required, touched, val, validator]);

    // Update if given value changed.
    useEffect(() => {
        if (initialOptions && props.value !== val?.value) {
            const v = initialOptions.find((o) => o.value === props.value);
            if (v) setVal(v);
        }
    }, [props.value]);

    // With parameters
    useEffect(() => {
        if (mode !== 'parameter' || !parameterName) return;
        const [groupName, paramName] = parameterName.split(':', 2);
        const group = groupName as ResourceTextApplication;
        paramRepository.load([group]).then(() => {
            const params = paramRepository.resource(group, paramName, sortValues);
            setOptions(params.map((p) => ({ text: p.text, value: p.code })));
        });
    }, [mode, parameterName]);

    // With parameters or items
    useEffect(() => {
        if (mode === 'api') return;
        const value = options.find((o) => o.value === props.value) || null;
        setVal(value);
    }, [mode, options, props]);

    // With api
    useEffect(() => {
        if (mode !== 'api') return;

        if (inputVal.value.length < minSearchLength) return;

        if (inputVal.changeReason === 'reset') {
            const option = options.find((o) => o.text === inputVal.value);
            setOptions(option ? [option] : []);
            return;
        } else if (inputVal.changeReason === 'clear') {
            setOptions([]);
            return;
        }

        if (timeoutRef.current) clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;

            search(inputVal.value).then((data) => {
                setOptions(data.map((i) => formatItem(i)));
            });
        }, 300);
    }, [mode, inputVal]);

    const textFieldProps: StandardTextFieldProps = {
        error: err || props.error,
        label: props.label || '',
        required: props.required,
        value: '',
        variant: 'standard',
    };

    return (
        <Autocomplete
            clearText={t('AnelmaGeneral:1066')}
            closeText={t('AnelmaGeneral:1067')}
            filterOptions={filterOptions}
            getOptionLabel={(o: AInputItem<AAutocompleteValue>) => {
                return getOptionLabel ? getOptionLabel(o) : o.text || '';
            }}
            onChange={(e, v) => {
                setTouched(true);
                setVal(v);
                props.onChange(v?.value || null);
            }}
            onInputChange={(e, v, r) => {
                setInputVal({ value: v, changeReason: r });
            }}
            openText={t('AnelmaGeneral:1068')}
            options={options}
            ref={ref}
            renderInput={(p) => {
                if (props.withoutContainer)
                    return (
                        <StyledTextField
                            {...p}
                            {...textFieldProps}
                            data-robot-id={'auto-complete-' + props.id}
                        />
                    );
                return (
                    <AInputContainer>
                        <StyledTextField
                            {...p}
                            {...textFieldProps}
                            data-robot-id={'auto-complete-' + props.id}
                        />
                    </AInputContainer>
                );
            }}
            style={props.style}
            value={val}
            disabled={props.disabled}
        />
    );
});
