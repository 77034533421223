// Libraries
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

// Interfaces
import { IPersonName } from '../interfaces/IBusinessEntities';
import { GUIDType } from '../interfaces/types';

// Api
import api from '../api/personNameApi';

interface PersonNameState {
    data: IPersonName[];
}

const initialState: PersonNameState = {
    data: [],
};

function loadName(state: Draft<PersonNameState>, action: PayloadAction<IPersonName>) {
    const newData = [...state.data];
    const index = state.data.findIndex((i) => i.Owner === action.payload.Owner);

    if (index === -1) newData.push(action.payload);
    else newData[index] = action.payload;

    state.data = newData;
}

function loadNames(state: Draft<PersonNameState>, action: PayloadAction<IPersonName[]>) {
    const newData = [...state.data];

    action.payload.forEach((d) => {
        const index = state.data.findIndex((i) => i.Owner === d.Owner);

        if (index === -1) newData.push(d);
        else newData[index] = d;
    });

    state.data = newData;
}

const personNameSlice = createSlice({
    name: 'personNames',
    initialState,
    reducers: {
        load: loadName,
        loadList: loadNames,
    },
});

const { load, loadList } = personNameSlice.actions;

export const loadPersonNameAsync = (id: GUIDType) => {
    return async (dispatch: any) => {
        try {
            await api.getPersonName(id).then((response) => {
                if (response?.Entity) dispatch(load(response.Entity));
            });
        } catch (err) {
            console.error(err);
        }
    };
};

export const loadPersonNamesAsync = (ids: GUIDType[]) => {
    return async (dispatch: any) => {
        try {
            await api.getPersonNames(ids).then((response) => {
                if (response) dispatch(loadList(response.Items));
            });
        } catch (err) {
            console.error(err);
        }
    };
};

export default personNameSlice.reducer;
