import moment from 'moment';

/** Checks if current timestamp in UTC format is ahead two days or the same
 *
 * @param from string - representing date
 */
export const currentUtcAheadOrSame = (from: string): boolean => {
    if (moment().utc() >= moment(from).subtract(2, 'days')) return true;

    return false;
};
