// Interfaces
import { IPersonName } from '../interfaces/IBusinessEntities';
import { IApiResult, IApiListResult } from '../interfaces/IGeneral';
import { GUIDType } from '../interfaces/types';

// Api
import ApiBase from './ApiBase';
import { personsUrl } from './apiEndpoints';

interface ProfilePictureCache {
    id: GUIDType;
    loading: Promise<IApiListResult<IPersonName> | null> | null;
    result: IApiResult<IPersonName> | null;
    status: 'waiting' | 'loading' | 'loaded';
    resolve: ResolveLoaded[];
    reject: RejectLoaded[];
}

type ResolveLoaded = (name: IApiResult<IPersonName> | null) => void;
type RejectLoaded = () => void;

class PersonNameApi extends ApiBase {
    private readonly cache: ProfilePictureCache[] = [];
    private waitingTimeout: NodeJS.Timeout | null = null;

    getPersonName(id: GUIDType) {
        return new Promise<IApiResult<IPersonName> | null>((resolve, reject) => {
            const cached = this.cache.find((i) => i.id === id);

            if (!cached) this.queue(id, resolve, reject);
            else {
                if (cached.status === 'loaded') this.resolveLoaded(id, resolve, reject);
                else if (cached.status === 'waiting' || cached.status === 'loading') {
                    cached.resolve.push(resolve);
                    cached.reject.push(reject);
                }
            }
        });
    }

    getPersonNames(ids: GUIDType[]) {
        return new Promise<IApiListResult<IPersonName> | null>((resolve, reject) => {
            const promises = ids.map((id) => this.getPersonName(id));
            const result: IApiListResult<IPersonName> = {
                Headers: {},
                Items: [],
                PageInfo: {
                    HasNextPage: false,
                    HasPreviousPage: false,
                    PageNumber: 1,
                    PageSize: 0,
                    TotalPages: 1,
                },
                Results: {
                    Errors: [],
                    HttpStatus: 200,
                    Title: '',
                    Type: '',
                    Warnings: [],
                },
                Type: 0,
            };

            Promise.all(promises).then(
                (responses) => {
                    responses.forEach((response) => {
                        if (response) result.Items.push(response.Entity);
                    });
                    result.PageInfo.PageSize = result.Items.length;
                    resolve(result);
                },
                () => {
                    resolve(null);
                    reject();
                }
            );
        });
    }

    private resolveLoaded(id: GUIDType, resolve: ResolveLoaded, reject: RejectLoaded) {
        const cached = this.cache.find((i) => i.id === id);

        if (cached?.status === 'loaded' && cached.result) resolve(cached.result);
        else {
            resolve(null);
            reject();
        }
    }

    private queue(id: GUIDType, resolve: ResolveLoaded, reject: RejectLoaded) {
        this.cache.push({
            id,
            loading: null,
            result: null,
            status: 'waiting',
            resolve: [resolve],
            reject: [reject],
        });

        if (this.waitingTimeout) clearTimeout(this.waitingTimeout);

        this.waitingTimeout = setTimeout(() => {
            const waiting = this.cache.filter((i) => i.status === 'waiting');
            const ids = waiting.map((i) => i.id);
            const promise = this.getPersonSummaries(ids);

            waiting.forEach((w) => {
                w.loading = promise;
                promise.then((response) => {
                    const items = response?.Items || [];
                    const index = items.findIndex((i) => i.Owner === w.id);
                    const found = index !== -1;

                    w.status = 'loaded';
                    w.result = response && found ? this.mapListToSEntity(response, index) : null;
                    w.resolve.forEach((r) => r(w.result));
                    if (!found) w.reject.forEach((r) => r());
                });
            });
        }, 100);
    }

    private getPersonSummaries(ids: string[]) {
        ids.forEach((id) => {
            const index = this.cache.findIndex((i) => i.id === id);
            if (index !== -1) this.cache[index].status = 'loading';
        });

        const promise = this.postList<IPersonName>(`${personsUrl}/person-names`, {
            Ids: ids,
        });

        ids.forEach((id) => {
            const index = this.cache.findIndex((i) => i.id === id);
            if (index !== -1) this.cache[index].loading = promise;
        });

        return promise;
    }
}

export default new PersonNameApi();
