// Libraries
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, InputAdornment, Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

// Core
import defaultData from '../../core/defaultData';
import { ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';
import utils from '../../core/utils';

// Common
import { ADropdown, ATextInput, AInputContainer, AZipAndCityInput } from '../../common/inputs';
import { AAddButton, ADeleteIconButton } from '../../common/buttons';

// Interfaces
import { AddressTypeEnum } from '../../interfaces/enums';
import { IAddress } from '../../interfaces/IBusinessEntities';

// Feature
import './addressesInputList.scss';

export interface AAddressesInputListProps {
    addressTypeParam: string;
    data: IAddress[];
    id: string;
    onChange: (data: IAddress[]) => void;
    required?: boolean;
    validator: IFormValidator;
}

export default function AAddressesInputList(props: AAddressesInputListProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { data } = props;

    const [uniqueIds, setUniqueIds] = useState<string[]>([]);
    const [addresses, setAddresses] = useState<IAddress[]>([]);

    useEffect(() => {
        // Add unique ids for validation
        data.forEach((a: any) => {
            if (!a._i) a._i = utils.generateUniqueId();
        });

        // Remove validation states for unused ids
        const newIds = data.map((a: any) => a._i as string);
        const unused = uniqueIds.filter((u) => !newIds.includes(u));
        unused.forEach((u) => {
            props.validator.removeState(addressId(u));
            props.validator.removeState(zipAndCityId(u));
            props.validator.removeState(addressTypeId(u));
        });

        setUniqueIds(newIds);
        setAddresses(data);
    }, [data]);

    enum AddressInputTypeEnum {
        IsDefault = 'IsDefault',
        Street = 'Steet',
        Type = 'Type',
        ZipAndCity = 'ZipAndCity',
    }

    const addAddress = () => {
        let newAddress = [...addresses];
        newAddress.push(defaultData.address(AddressTypeEnum.Street, false));
        props.onChange(newAddress);
    };

    const setDefault = (oldArray: IAddress[]): IAddress[] => {
        return oldArray.map((a: IAddress) => {
            let obj: IAddress = { ...a };
            obj.IsDefault = false;
            return obj;
        });
    };

    const updateAddress = (
        value: IAddress | string | number | null,
        index: number,
        property: string
    ) => {
        if (index < 0) return;
        let newAddresses = [...addresses];
        if (!newAddresses) return;
        let newAddress = { ...newAddresses[index] };
        if (!newAddress) return;

        switch (property) {
            case AddressInputTypeEnum.IsDefault:
                newAddresses = setDefault(newAddresses);
                newAddress = { ...newAddresses[index] };
                newAddress.IsDefault = true;
                break;
            case AddressInputTypeEnum.Street:
                newAddress.Street = value as string;
                break;
            case AddressInputTypeEnum.Type:
                newAddress.Type = value as AddressTypeEnum;
                break;
            case AddressInputTypeEnum.ZipAndCity:
                newAddress.Zip = (value as IAddress).Zip;
                newAddress.City = (value as IAddress).City;
                break;
            default:
                break;
        }
        newAddresses[index] = newAddress;
        props.onChange(newAddresses);
    };

    const deleteAddress = (i: number, id: string) => {
        const newAddresses = addresses.filter((p, index) => i !== index);
        if (newAddresses.length === 0) {
            newAddresses.push(defaultData.address(AddressTypeEnum.Street, true));
        } else if (!newAddresses.find((n) => n.IsDefault)) {
            newAddresses[0].IsDefault = true;
        }
        props.onChange(newAddresses);
    };

    const getInputAdornment = (isDefault: boolean, updateIsDefault: Function): ReactNode => {
        return isDefault ? (
            <InputAdornment position='start'>
                <Tooltip title={t('AnelmaGeneral:1011') as string}>
                    <CheckCircleOutlineIcon />
                </Tooltip>
            </InputAdornment>
        ) : (
            <InputAdornment position='start' onClick={() => updateIsDefault()}>
                <Tooltip title={t('AnelmaGeneral:1021') as string}>
                    <RadioButtonUncheckedIcon />
                </Tooltip>
            </InputAdornment>
        );
    };

    const addressStyle = (withLabel: boolean) => {
        if (withLabel) return {};

        // NOTE: Add top margin to fix visual bug when label text is not given. -MM
        return {
            marginTop: '16px',
        };
    };

    const addressId = (id: string): string => `${props.id}-address-1-${id}`;
    const zipAndCityId = (id: string): string => `${props.id}-address-2-${id}`;
    const addressTypeId = (id: string): string => `${props.id}-address-type-${id}`;

    const addressesElement = addresses.map((a, i) => {
        const id = (a as any)._i;
        return (
            <Grid container key={i}>
                <Grid item sm={6}>
                    <ATextInput
                        id={addressId(id)}
                        label={i === 0 ? t('AnelmaGeneral:1008') : undefined}
                        maxLength={8000}
                        minLength={1}
                        onChange={(v) => updateAddress(v, i, AddressInputTypeEnum.Street)}
                        placeholder={t('AnelmaGeneral:1006')}
                        required={props.required}
                        startAdornment={getInputAdornment(a.IsDefault, () =>
                            updateAddress(null, i, AddressInputTypeEnum.IsDefault)
                        )}
                        style={addressStyle(i === 0)}
                        validator={props.validator}
                        value={a.Street || ''}
                    />
                </Grid>

                <Grid item sm={6}>
                    <div className='an-address'>
                        <div className='an-address-zip-and-city'>
                            <AZipAndCityInput
                                id={zipAndCityId(id)}
                                onChange={(v) =>
                                    updateAddress(v, i, AddressInputTypeEnum.ZipAndCity)
                                }
                                placeholder={t('AnelmaGeneral:1062')}
                                required={props.required}
                                validator={props.validator}
                                value={a}
                            />
                        </div>

                        <div className='an-address-type'>
                            <ADropdown
                                id={addressTypeId(id)}
                                onChange={(v) => updateAddress(v, i, AddressInputTypeEnum.Type)}
                                parameterName={props.addressTypeParam}
                                validator={props.validator}
                                value={a.Type || AddressTypeEnum.Street}
                            />
                        </div>

                        <div className='an-address-delete'>
                            <ADeleteIconButton onClick={() => deleteAddress(i, id)} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    });

    return (
        <>
            {addressesElement}

            <Grid container>
                <Grid item sm={12}>
                    <AInputContainer>
                        <AAddButton onClick={addAddress} size='small'>
                            {t('AnelmaGeneral:1004')}
                        </AAddButton>
                    </AInputContainer>
                </Grid>
            </Grid>
        </>
    );
}
