import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Container } from '@mui/material';

import { ResourceTextApplication } from '../core/resources';
import { useAppDispatch, useAppSelector } from '../store/hooks';

import { BulletinEvent, BulletinView } from './BulletinView';
import { addMessageAsync, loadAsync, updateMessageAsync } from './bulletinSlice';
import { BulletinRequest } from './api/bulletinRequest';
import { ViewTitle } from '../common/typography';

export default function Bulletin() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaLayout']);

    const messages = useAppSelector((state) => state.bulletinMessages);
    const isInitial = messages.initial;
    const data = messages.data;
    const dispatch = useAppDispatch();
    const loadData = useCallback(() => dispatch(loadAsync()), [dispatch]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isInitial) loadData();
    }, [isInitial, loadData]);

    function onSave(event: BulletinEvent, request: BulletinRequest) {
        if (event === 'UPDATE') {
            dispatch(updateMessageAsync(request));
        } else if (event === 'ADD') {

            if (request.Entity.IsPublic) {
                request.Entity.ReceiverUsers = [];
            }

            const timeCorrectedRequest: BulletinRequest = { ...request };
            timeCorrectedRequest.Entity.ValidDueDate = moment(
                timeCorrectedRequest.Entity.ValidDueDate
            )
                .utc()
                .format();
            timeCorrectedRequest.Entity.ValidFromDate = moment(
                timeCorrectedRequest.Entity.ValidFromDate
            )
                .utc()
                .format();
            dispatch(addMessageAsync(timeCorrectedRequest));
        }
    }

    useEffect(() => {
        setIsLoading(messages.isLoading || messages.initial);
    }, [messages]);

    return (        
        <Container data-robot-id = {"app-body-management-bulletin"}>
            <ViewTitle>{t('AnelmaLayout:1004')}</ViewTitle>

            <BulletinView data={data} isLoading={isLoading} onSave={onSave} />
        </Container>      
    );
}
