import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Type } from "typescript";
import { ResourceTextApplication } from "../../../core/resources";
import { IFarmTrend, IProductionPlan, IProductionPlanAccess } from "../../../interfaces/IProductionPlan";
import EditProductionAnimalDataDialog from "../data-grid/EditProductionAnimalDataDialog";
import ProductionPlanAnimalsDataGrid from "../data-grid/ProductionPlanAnimalDataGrid";
import { BuildFarmTrends, IProductionPlanDataGrid } from "../data-grid/ProductionPlanDataGrids";
import SectionTitle from "../data-grid/SectionTitle";

interface IProps {
    data: IProductionPlan;
    access: IProductionPlanAccess;
    readonly?: boolean;
}

export default function  FarmTrends({ data, access, readonly }: IProps){
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaLayout', 'AnelmaCattleProductionPlan']);
    
    const [farmTrends, setFarmTrends] = useState<IProductionPlanDataGrid>();
    const [editData, setEditData] = useState<IProductionPlanDataGrid>();

    const trendYears = [data.TargetYear + 1, data.TargetYear + 2, data.TargetYear + 3];

    const defaultFarmTrend = (year: number) => {
        return {
            Year: year,
            PlannedTotalAnimalAmount: 0,
            PlannedDairyCowAmount: 0,
            PlannedBreedingCowAmount: 0,
            PlannedBeefBullAmount: 0,
            PlannedBeefHeiferAmount: 0,
            PlannedTransmissionCalfAmount: 0,
        };
    }

    useEffect(() => {
        if (!data.FarmTrends) data.FarmTrends = [];

        if (data.FarmTrends.length === 0) {
            for (let year of trendYears) {
                data.FarmTrends.push(defaultFarmTrend(year));
            }
        }
        else
            setFarmTrends(
                BuildFarmTrends(
                    access,
                    data.FarmTrends,
                    trendYears
                )
            );
    }, [data.FarmTrends]);

    const onSave = (newData: IProductionPlanDataGrid) => {
        const rows: Type[] = [];
        for (const row of newData.rows) {
            let counter = 0;
            for (let index = 0; index < trendYears.length; index++) {
                if (row[trendYears[index]] > 0) counter++;
                if (counter >= 1) {
                    rows.push(row);
                    break;
                }
            }
        }
        newData.rows = rows;
        for (let row of newData.rows) {
            const field: keyof IFarmTrend = row.field;
            for (let year of trendYears) {
                let farmTrend = data.FarmTrends.find(x => x.Year === year);
                if (!farmTrend) {
                    farmTrend = defaultFarmTrend(year);
                    data.FarmTrends.push(farmTrend);
                }
                farmTrend[field] = row[farmTrend.Year.toString()]
            }
        }
        setFarmTrends(newData);
    }

    return (<>
        <Grid container direction='row' style={{ paddingLeft: 40 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <SectionTitle 
                    title={t('AnelmaLayout:1071')}
                    onEdit={() => setEditData(BuildFarmTrends(
                        access,
                        data.FarmTrends,
                        trendYears,
                        true
                    ))}
                    readonly={readonly}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <ProductionPlanAnimalsDataGrid data={farmTrends}/>
            </Grid>
        </Grid>
        {editData && !readonly && (
            <EditProductionAnimalDataDialog 
                title={t('AnelmaLayout:1071')}
                data={editData}
                onSave={onSave}
                onClose={() => setEditData(undefined)}
            />
        )}
    </>);
}