// Libraries
import fileDownload from 'js-file-download';

import { printingUrl } from './apiEndpoints';
import { IApiResult } from '../interfaces/IGeneral';
import ApiBase from './ApiBase';
import { IAnimalSummary } from '../interfaces/IAnimal';
import { IAnimalAnnouncement } from '../interfaces/IAnnouncement';
import { IMainGroup } from '../interfaces/IGroup';
import { GUIDType } from '../interfaces/types';
import { group } from 'node:console';

class PrintingApi extends ApiBase {
    printInvoice(invoiceNumber: number) {
        return new Promise<IApiResult<Blob> | null>((resolve, reject) => {
            this.get<Blob>(`${printingUrl}/printInvoice?InvoiceNumber=${invoiceNumber}`, {
                responseType: 'blob',
                incompatibleResult: true,
            }).then((response) => {
                if (!response) {
                    resolve(null);
                    reject();
                    return;
                }

                let fileName = response.Headers['content-disposition']
                    ?.split('filename=')[1]
                    ?.split(';')[0];

                let parts = fileName.split('.');
                let fileExtension = '.' + parts[parts.length - 1];
                fileName = fileName.substring(0, fileName.lastIndexOf('.'));

                fileDownload(response.Entity, fileName + fileExtension);

                resolve(response);
            });
        });
    }

    printPayment(paymentNumber: number) {
        return new Promise<IApiResult<Blob> | null>((resolve, reject) => {
            this.get<Blob>(`${printingUrl}/printPayment?PaymentNumber=${paymentNumber}`, {
                responseType: 'blob',
                incompatibleResult: true,
            }).then((response) => {
                if (!response) {
                    resolve(null);
                    reject();
                    return;
                }

                let fileName = response.Headers['content-disposition']
                    ?.split('filename=')[1]
                    ?.split(';')[0];

                let parts = fileName.split('.');
                let fileExtension = '.' + parts[parts.length - 1];
                fileName = fileName.substring(0, fileName.lastIndexOf('.'));

                fileDownload(response.Entity, fileName + fileExtension);

                resolve(response);
            });
        });
    }

    printAnimals(
        selectedFilter: string,
        farmName: string,
        farmId: GUIDType,
        animals: IAnimalSummary[],
        announcements: IAnimalAnnouncement[],
        groups: IMainGroup[]
    ) {
        var payload: {
            FarmName: string;
            SelectedAnimalListFilterType: number;
            FarmId: GUIDType;
            Animals: IAnimalSummary[];
            Announcements: IAnimalAnnouncement[];
            Groups: IMainGroup[];
        } = {
            FarmName: farmName,
            FarmId: farmId,
            SelectedAnimalListFilterType: Number(selectedFilter),
            Animals: animals,
            Announcements: announcements,
            Groups: groups,
        };

        return new Promise<IApiResult<Blob> | null>((resolve, reject) => {
            this.post<Blob>(`${printingUrl}/printAnimals`, JSON.parse(JSON.stringify(payload)), {
                responseType: 'blob',
                incompatibleResult: true,
            }).then((response) => {
                if (!response) {
                    resolve(null);
                    reject();
                    return;
                }

                let fileName = response.Headers['content-disposition']
                    ?.split('filename=')[1]
                    ?.split(';')[0];

                let parts = fileName.split('.');
                let fileExtension = '.' + parts[parts.length - 1];
                fileName = fileName.substring(0, fileName.lastIndexOf('.'));

                fileDownload(response.Entity, fileName + fileExtension);

                resolve(response);
            });
        });
    }

    printAnimalAnnouncement(announcementNumber: number) {
        return new Promise<IApiResult<Blob> | null>((resolve, reject) => {
            this.get<Blob>(`${printingUrl}/printAnimalAnnouncement?AnnouncementNumber=${announcementNumber}`, {
                responseType: 'blob',
                incompatibleResult: true,
            }).then((response) => {
                if (!response) {
                    resolve(null);
                    reject();
                    return;
                }

                let fileName = response.Headers['content-disposition']
                    ?.split('filename=')[1]
                    ?.split(';')[0];

                let parts = fileName.split('.');
                let fileExtension = '.' + parts[parts.length - 1];
                fileName = fileName.substring(0, fileName.lastIndexOf('.'));

                fileDownload(response.Entity, fileName + fileExtension);

                resolve(response);
            });
        });
    }

    printAnimalPayload(payloadNumber: number, farmGUID: GUIDType) {
        return new Promise<IApiResult<Blob> | null>((resolve, reject) => {
            this.get<Blob>(`${printingUrl}/printAnimalLoad?AnimalLoadNumber=${payloadNumber}&FarmGUID=${farmGUID}`, {
                responseType: 'blob',
                incompatibleResult: true,
            }).then((response) => {
                if (!response) {
                    resolve(null);
                    reject();
                    return;
                }

                let fileName = response.Headers['content-disposition']
                    ?.split('filename=')[1]
                    ?.split(';')[0];

                let parts = fileName.split('.');
                let fileExtension = '.' + parts[parts.length - 1];
                fileName = fileName.substring(0, fileName.lastIndexOf('.'));

                fileDownload(response.Entity, fileName + fileExtension);

                resolve(response);
            });
        });
    }
}

export default new PrintingApi();
