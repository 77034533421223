import { useState } from 'react';

import { GUIDType } from '../interfaces/types';
import { useSnackbar } from 'notistack';
import { IFormValidator } from '../core/FormValidator';
import { useTranslation } from 'react-i18next';
import { ResourceTextApplication } from '../core/resources';
import { cancelMessage } from './api/APIShortMessage';

import {
    ADialog,
    ADialogActions,
    ADialogContent,
    ADialogSubHeading,
    ADialogTitle,
    ADialogButtonsProp,
} from '../common/dialog';

export interface CancelMessageDialogDialogProps {
    afterDelete: (id: GUIDType) => void;
    id: GUIDType;
    onClose: () => void;
    validator?: IFormValidator;
}

export default function CancelMessageDialog(props: CancelMessageDialogDialogProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const showMessage = (message: string, variant: 'success' | 'error') => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaPerson']);

    const deletefooterButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [
            {
                type: 'delete',
                onClick: () => onDelete(props.id as string),
            },
        ],
    };

    const onDelete = (guid: GUIDType) => {
        if (props.validator?.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }
        setIsLoading(true);
        cancelMessage(guid).then((data) => {
            if (data.Results.HttpStatus === 202) {
                showMessage(t('AnelmaGeneral:1018'), 'success');
                props.afterDelete(props.id);
                props.onClose();
                return;
            } else {
                showMessage(t('AnelmaGeneral:1019'), 'error');
                props.onClose();
            }
        });
    };

    return (
        <>
            <ADialog open={true} onClose={() => props.onClose()}>
                <ADialogTitle>{t('##Cancel Sent Message')}</ADialogTitle>
                <ADialogContent isLoading={isLoading} size='md'>
                    <ADialogSubHeading>
                        {t('##You are about to cancel message status')}
                    </ADialogSubHeading>
                </ADialogContent>
                <ADialogActions buttons={deletefooterButtons} isLoading={isLoading} />
            </ADialog>
        </>
    );
}
