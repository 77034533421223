import { ExtendedCalendarEvent } from '../../interfaces/calendar/CalendarEvent';
import { CalendarEventTypes } from '../../interfaces/enums';
//import SnelmanIcon from '../../../public/favicon/favicon-32x32.png'

export interface EventProps {
    event: ExtendedCalendarEvent;
}

export default function CustomEvent(props: EventProps) {

const showSnelmanIcon = () => {
    if(props.event.type === CalendarEventTypes.Event)
        return true
    else 
        return false
}

  return (
    <div style={{display: 'flex'}}>
        { showSnelmanIcon() && (
            <div style={{marginRight: '5px'}}>
                <img src={process.env.PUBLIC_URL + '/favicon/favicon-32x32.png'} height={16} width={16} />
            </div>
        )}
        {props.event.title}
    </div>
  )
}
