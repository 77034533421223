const Header = require('@editorjs/header');
const NestedList = require('@editorjs/nested-list');
//const Raw = require('@editorjs/raw');
const SimpleImage = require('@editorjs/simple-image');
const Table = require('@editorjs/table');
const Underline = require('@editorjs/underline');
const Delimiter = require('@editorjs/delimiter');

export const EDITOR_TOOLS = {
    header: {
        class: Header,
        inlineToolbar: true,
    },
    image: {
        class: SimpleImage,
        inlineToolbar: true,
    },
    // image: {
    //     class: Image,
    //     config: {
    //         uploader: {
    //             uploadByFile(file: File) {
    //                 return afterImageUpload(api.uploadImageByFile(file));
    //             },
    //             uploadByUrl(url: string) {
    //                 return afterImageUpload(api.uploadImageByUrl(url));
    //             },
    //         },
    //     },
    //     inlineToolbar: true,
    // },
    list: {
        class: NestedList,
        inlineToolbar: true,
    },
    // Disabled for now AN-2233
    //raw: {
    //    class: Raw,
    //    inlineToolbar: true,
    //},
    table: {
        class: Table,
        config: {
            cols: 3,
            rows: 2,
        },
        inlineToolbar: true,
    },
    underline: {
        class: Underline,
        inlineToolbar: true,
    },
    delimiter: {
        class: Delimiter,
        inlineToolbar: true,
    }
};
