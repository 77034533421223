// Content
import { EditableContentType } from '../editableContent/IEditableContent';

// Feature
import InfoView from './InfoView';

export default function MPInfo() {
    return (      
        <InfoView
            anelmaInfoContentType={null}
            bovineContentType={EditableContentType.MPBovine}
            porkContentType={EditableContentType.MPPork}
            sharedContentType={EditableContentType.MP}
            title={1042}
            applyContainer={true}
        />    
    );
}
