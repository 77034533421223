import Axios from 'axios';
import { updateExpirationTime } from '../store/loginStatusSlice';
import { baseUrl } from './constants';

let currentContext = '';
let currentContextType = '';

export const interceptor = function (store: any) {
    Axios.interceptors.response.use(
        (response) => {
            if (response?.status === 401) window.location.href = `${baseUrl}/logout`;
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) window.location.href = `${baseUrl}/logout`;
            return Promise.reject(error);
        }
    );

    Axios.interceptors.response.use((response) => {
        if (response.headers && 'x-session-expires-utc' in response.headers) {
            updateExpirationTime(response.headers['x-session-expires-utc'])(store.dispatch);
        }
        return response;
    }, (error) => {
        return Promise.reject(error);
    });

    Axios.interceptors.request.use((value) => {
        value.headers['x-current-context'] = encodeURIComponent(currentContext);
        value.headers['x-current-context-type'] = currentContextType;

        return value;
    });
};

export const updateCurrentContextToRequestHeaders = (context: string, contextType: string) => {
    currentContext = context;
    currentContextType = contextType;
};
