// Libraries
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import CloseIcon from '@mui/icons-material/Close';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADefaultButton, AButtonBaseProps } from '../../common/buttons';

export interface ACloseButtonProps extends Omit<AButtonBaseProps, 'children'> {
    children?: ReactNode;
}

export default function ACloseButton(props: ACloseButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { children, ...actionButtonProps } = props;
    const children2 = children ? children : t('AnelmaGeneral:1067');

    return <ADefaultButton {...actionButtonProps} children={children2} icon={<CloseIcon />} />;
}
