import { GUIDType } from './types';

import { ICompanyDetails, IPersonDetails } from './IBusinessEntities';

import { IUpdatePayload } from './IGeneral';

//Enums

export enum AnelmaAuditTrailSortBy {
    Time = '01',
    Operation = '02',
    User = '03',
}

export enum AnelmaEntityType {
    Unknown = 0,
    //Solmu Entity type
    Person,
    Company,
    Association,
    Contract,
    Office,
    // Continuum to Solmu Entity type
    Farm = 6,
    TrafficContractor = 7,
    Car = 8,
    Announcement = 9,
    Snellman = 10,
}

export enum AnelmaLogFilterByTimePeriod {
    OneDay = '01',
    TwoDays = '02',
    OneWeek = '03',
    All = '04',
}

export enum CQRSCommandType {
    CQRSUnknown = 0,
    CQRSCreate = 1,
    CQRSModify = 2,
    CQRSChangeType = 3,
    CQRSRemoveEntity = 4,
    CQRSSupportRequest = 6,
}

export enum Originator {
    NoOriginator = 0,
    //ML2000Subscriber = 1,
    //MyllyCRMSubscriber = 2,
    //TiltuSubscriber = 3
}

export interface IEventSourceEntity {
    Entity: IPersonDetails;
}

export type AnelmaCreateEventSource = ICompanyDetails | IPersonDetails | IEventSourceEntity;

export type AnelmaEventSource = AnelmaCreateEventSource | AnelmaUpdateEventSource;

export type AnelmaUpdateEventSource =
    | IUpdatePayload<ICompanyDetails>
    | IUpdatePayload<IPersonDetails>;

type AnelmaUpdateFieldDataRow = [string, string, string];

//Interfaces
export interface ICQRSCommandEvent extends ICQRSEventBase {
    Command: CQRSCommandType;
    EntityType: AnelmaEntityType;
    EventSource: AnelmaEventSource;
    Id: GUIDType;
    Originator: Originator;
}

export interface ICQRSErrorEvent extends ICQRSEventBase {
    Application: string;
    ErrorCode: number;
    ExceptionData: unknown;
    Request: string;
}

export interface ICQRSEventBase {
    Author: string;
    CorrelationId: GUIDType;
    Timestamp: string;
}

export interface ICQRSQueryEvent extends ICQRSEventBase {
    QueryData: string;
}

interface ISupportEmailRequestBody {
    From: string;
    Subject: string;
    To: string;
}

interface IUpdateDataItem {
    Data: any;
    Field: string;
    NewData: any;
    OldData: any;
}

export interface ISystemLogEvent {
    Level: string;
    Application: string;
    CorrelationId: string;
    Message: string;
    HttpStatusCode: number;
    Timestamp: string;
}
