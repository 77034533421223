import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { getCommunications, postAdd, postUpdate } from './bulletinApi';
import type { RootState } from '../store/store';
import { IBulletinItemDetails } from '../interfaces/ICommunication';

import { concat } from 'ramda';
import { BulletinRequest } from './api/bulletinRequest';

interface BulletinState {
    data: IBulletinItemDetails[];
    isLoading: boolean;
    initial: boolean;
}

const initialState: BulletinState = { data: [], isLoading: false, initial: true };

function loadData(state: Draft<BulletinState>, action: PayloadAction<IBulletinItemDetails[]>) {
    state.data = action.payload;
    state.isLoading = false;
    state.initial = false;
}

function addBulletin(state: Draft<BulletinState>, action: PayloadAction<IBulletinItemDetails[]>) {
    state.data = concat(state.data, action.payload);
    state.isLoading = false;
    state.initial = false;
}

function updateBulletin(
    state: Draft<BulletinState>,
    action: PayloadAction<IBulletinItemDetails[]>
) {
    const replace = (data: IBulletinItemDetails[]) => {
        action.payload.forEach((p) => {
            var index = data.findIndex((a) => a.Id === p.Id);

            if (index !== -1) data[index] = p;
        });

        return data;
    };

    state.data = replace(state.data);
    state.isLoading = false;
    state.initial = false;
}

export const bulletinSlice = createSlice({
    name: 'bulletinMessages',
    initialState,
    reducers: {
        load: loadData,
        add: addBulletin,
        update: updateBulletin,
        updateIsLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
});

const { load, add, update, updateIsLoading } = bulletinSlice.actions;

export const loadAsync = () => {
    return async (dispatch: any) => {
        try {
            const messages = await getCommunications();
            dispatch(load(messages));
        } catch (err) {
            console.log(err);
        }
    };
};

export function updateMessageAsync(request: BulletinRequest) {
    return async function (dispatch: any) {
        try {
            dispatch(updateIsLoading(true));
            const messages = await postUpdate(request);
            dispatch(update([messages]));
        } catch (err) {
            console.log(err);
        }
    };
}

export function addMessageAsync(request: BulletinRequest) {
    return async function (dispatch: any) {
        try {
            const messages = await postAdd(request);
            dispatch(add([messages]));
        } catch (err) {
            console.log(err);
        }
    };
}

export const selectData = (state: RootState) => state.bulletinMessages;
export default bulletinSlice.reducer;
