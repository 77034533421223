// Libraries
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { TFunction } from 'i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { IFormValidator } from '../../core/FormValidator';
import utils from '../../core/utils';

// Common
import {
    ADateInput,
    ADateValue,
    ATimeInput,
    ATimeValue,
    ATextarea,
    AInputContainer,
} from '../../common/inputs';
import { ASaveButton } from '../../common/buttons';

// Feature
import { IEditableContent } from '../IEditableContent';

export interface ContentFormProps {
    content: IEditableContent;
    onSave: (from: string, due: string, comment: string) => void;
    t: TFunction<string[]>;
    validator: IFormValidator;
}

export default function ContentForm(props: ContentFormProps) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = props;

    const [from, setFrom] = useState<moment.Moment | null>(
        utils.date.utcStringToLocalMoment(props.content.From)
    );
    const [due, setDue] = useState<moment.Moment | null>(
        utils.date.utcStringToLocalMoment(props.content.Due)
    );
    const [comment, setComment] = useState<string>(props.content.Comment || '');

    useEffect(() => {
        setFrom(utils.date.utcStringToLocalMoment(props.content.From));
        setDue(utils.date.utcStringToLocalMoment(props.content.Due));
        setComment(props.content.Comment || '');
    }, [props.content]);

    useEffect(() => {
        props.validator.setState('dates', !due || !!from?.isBefore(due), (valid) => {
            if (!valid) {
                enqueueSnackbar(t('AnelmaContent:1001'), {
                    variant: 'error',
                });
            }
        });
    }, [from, due, enqueueSnackbar, props.validator, t]);

    const updateFrom = (v: ADateValue | ATimeValue, t: 'date' | 'time') => {
        if (!v) setFrom(v);
        else {
            const m = moment(v);
            if (t === 'date' && !from) m.startOf('day');
            setFrom(m);
        }
    };

    const updateDue = (v: ADateValue | ATimeValue, t: 'date' | 'time') => {
        if (!v) setDue(v);
        else {
            const m = moment(v);
            if (t === 'date' && !due) m.endOf('day');
            setDue(m);
        }
    };

    const save = () => {
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }

        const f = utils.date.localMomentToUtcString(from);
        const d = utils.date.localMomentToUtcString(due);

        props.onSave(f, d, comment);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item sm={4}>
                        <ADateInput
                            id='from-date'
                            label={t('AnelmaContent:1021')}
                            onChange={(v) => updateFrom(v, 'date')}
                            required
                            validator={props.validator}
                            value={from?.toDate() || null}
                        />

                        <ATimeInput
                            disabled={!from}
                            id='from-time'
                            label={t('AnelmaContent:1022')}
                            onChange={(v) => updateFrom(v, 'time')}
                            required
                            validator={props.validator}
                            value={from?.toDate() || null}
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <ADateInput
                            id='due-date'
                            label={t('AnelmaContent:1023')}
                            onChange={(v) => updateDue(v, 'date')}
                            validator={props.validator}
                            value={due?.toDate() || null}
                        />

                        <ATimeInput
                            disabled={!due}
                            id='due-time'
                            label={t('AnelmaContent:1024')}
                            onChange={(v) => updateDue(v, 'time')}
                            validator={props.validator}
                            value={due?.toDate() || null}
                        />
                    </Grid>

                    <Grid item sm={4}>
                        <ATextarea
                            id='comment'
                            label={t('AnelmaContent:1015')}
                            lengthBoundaryName='AnelmaContent:CommentLength'
                            onChange={(v) => setComment(v)}
                            validator={props.validator}
                            value={comment}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <AInputContainer>
                            <ASaveButton onClick={() => save()} />
                        </AInputContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
