import { CircularProgress, Grid } from '@mui/material';

export default function ViewLoader(){
    return (
        <Grid container>
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <CircularProgress />
            </Grid>
        </Grid>
    )
}