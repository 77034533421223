import { animalPayloadsUrl } from './apiEndpoints';
import { IAnimalPayloadItem } from '../interfaces/IAnimal';

import ApiBase from './ApiBase';
import { IAnimalPayloadCalendarEvent } from '../interfaces/calendar/AnimalPayloadCalendarEvent';

class AnimalPayloadsApi extends ApiBase {
    getAnimalPayloads = (
        producerNumber: number | null = null,
        status: number | null = null,
        fromDate: string | null = null,
        toDate: string | null = null
    ) => {
        let url: string = `${animalPayloadsUrl}/list?`;

        if (producerNumber) url = url.concat(`producerNumber=${producerNumber}&`);
        if (status) url = url.concat(`Status=${status}&`);
        if (fromDate) url = url.concat(`FromDate=${fromDate}&`);
        if (toDate) url = url.concat(`ToDate=${toDate}&`);

        if (url.substr(url.length - 1, 1) === '&') url = url.substr(0, url.length - 1);

        return this.getList<IAnimalPayloadItem>(url);
    };

    getAnimalPayloadByPayloadNumber = (payloadNumber: number, isAdmin: boolean | null = null) => {
        if (isAdmin)
            return this.get<IAnimalPayloadItem>(
                `${animalPayloadsUrl}/payload-by-payload-number?payloadNumber=${payloadNumber}`
            );
        else
            return this.get<IAnimalPayloadItem>(
                `${animalPayloadsUrl}/payload-by-payload-number-with-context?payloadNumber=${payloadNumber}`
            );
    };

    getAnimalPayloadsByCompanyName = (
        companyName: string,
        fromDate: string | null = null,
        toDate: string | null = null
    ) => {
        let url: string = `${animalPayloadsUrl}/list-by-company-name?`;

        if (companyName) url = url.concat(`CompanyName=${companyName}&`);
        if (fromDate) url = url.concat(`FromDate=${fromDate}&`);
        if (toDate) url = url.concat(`ToDate=${toDate}&`);

        if (url.substr(url.length - 1, 1) === '&') url = url.substr(0, url.length - 1);

        return this.getList<IAnimalPayloadItem>(url);
    };

    getAnimalPayloadCalendar = (
        producerNumber: string,
        fromDate: string | null,
        toDate: string | null
    ) => {
        let url: string = `${animalPayloadsUrl}/payloads-for-calendar?`;

        if (producerNumber) url = url.concat(`producerNumber=${producerNumber}&`);
        if (fromDate) url = url.concat(`FromDate=${fromDate}&`);
        if (toDate) url = url.concat(`ToDate=${toDate}&`);

        if (url.substr(url.length - 1, 1) === '&') url = url.substr(0, url.length - 1);

        return this.getList<IAnimalPayloadCalendarEvent>(url);
    };
}

export default new AnimalPayloadsApi();
