// Libraries
import React, { CSSProperties, useState } from 'react';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// MUI
import {
    DataGridPro,
    GridColumnHeaderParams,
    GridComparatorFn,
    GridInputRowSelectionModel,
    GridPaginationModel,
    GridRenderCellParams,
    GridRowParams,
    GridRowSelectionModel,
    GridRowsProp,
    GridValueGetterParams,
    LicenseInfo,
    MuiEvent,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { SxProps, Theme, styled } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Feature - Data Grid
import ADataGridButtonGroup from './ADataGridButtonGroup';
import { CustomToolbar } from './customSlots/CustomToolbar';
import { CustomNoResultsOverlay } from './customSlots/CustomNoResultsOverlay';
import { CustomNoRowsOverlay } from './customSlots/CustomNoRowsOverlay';
import { CustomLoadingOverlay } from './customSlots/CustomLoadingOverlay';
import gridUtils from './gridUtils';
import { isNil, isNotNil } from 'ramda';

interface ADataGridAction {
    (params: GridRenderCellParams): React.ReactNode;
}

export type ADataGridActions = ADataGridAction[];

export interface AGridColumn {
    field: string;
    filterable?: boolean;
    flex?: number;
    headerName?: string;
    renderCell?: (params: GridRenderCellParams) => React.ReactNode;
    renderHeader?: (params: GridColumnHeaderParams) => React.ReactNode;
    sortable?: boolean;
    sortComparator?: GridComparatorFn;
    type?: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect';
    valueGetter?: (params: GridValueGetterParams) => any;
    valueOptions?: Array<
        | string
        | number
        | {
              value: any;
              label: string;
          }
    >;
    width?: number;
    description?: string;
    align?: 'left' | 'right' | 'center';
    headerAlign?: 'left' | 'right' | 'center';
}

export type AGridColumns = AGridColumn[];

export interface ADataGridProps {
    actions?: ADataGridActions;
    className?: string;
    columns: AGridColumns;
    enableFiltering?: boolean;
    enablePagination?: boolean;
    enableRowNumbers?: boolean;
    disableSelectionOnClick?: boolean;
    getRowClassName?: (params: GridRowParams, details?: any) => string;
    isRowSelectable?: (params: GridRowParams, details?: any) => boolean;
    loading?: boolean;
    onRowClick?: (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
        details?: any
    ) => void;
    onSelectionModelChange?: (model: GridRowSelectionModel) => void;
    rows: GridRowsProp;
    selectionModel?: GridInputRowSelectionModel | undefined;
    stickyHeader?: boolean;
    apiRef?: React.MutableRefObject<GridApiPro> | undefined;
}

LicenseInfo.setLicenseKey(
    '1d792262e4550aaf9cb5f0546ec6fbffTz04NjA0MSxFPTE3NDE1MDY4NTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    border: 'none !important',
    zIndex: 0,
    '&.an-data-grid-sticky-header > div:first-of-type': {
        backgroundColor: '#fff',
        height: '36px',
        position: 'sticky',
        top: '0px',
        zIndex: 1,
    },
    '&.an-data-grid-sticky-header .MuiDataGrid-main > .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff',
        position: 'sticky',
        top: '36px',
        zIndex: 1,
    },
    position: 'relative',
    '.MuiDataGrid-main': {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    '.MuiDataGrid-hiddenSortBtns': {
        'div[aria-sort]': {
            '.MuiDataGrid-iconButtonContainer': {
                width: 'inherit',
                display: 'inherit',
            },
        },
        '.MuiDataGrid-columnHeaderTitleContainer': {
            '.MuiDataGrid-iconButtonContainer': {
                width: '0',
                display: 'none',
            },
            '&:hover': {
                '.MuiDataGrid-iconButtonContainer': {
                    width: 'inherit',
                    display: 'inherit',
                },
            },
        },
    },
}));

export default forwardRef(function ADataGrid(props: ADataGridProps, ref: ForwardedRef<any>) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const apiRef = useGridApiRef();
    const selectionModel: GridInputRowSelectionModel | undefined = props.selectionModel;

    const {
        actions,
        enableFiltering,
        enablePagination,
        enableRowNumbers,
        disableSelectionOnClick,
        onSelectionModelChange,
        loading,
        stickyHeader,
        getRowClassName,
        isRowSelectable,
        ...nativeProps
    } = props;

    const enableRowSelection = !!onSelectionModelChange;
    const stickyHeaderClass = stickyHeader ? 'an-data-grid-sticky-header' : '';
    const columns2 = [...props.columns];
    const rowNumbersCss: CSSProperties = { color: '#888', fontSize: '12px' };

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        pageSize: 20,
        page: 0,
    });

    if (actions && actions.length) {
        columns2.unshift({
            field: 'Actions',
            filterable: false,
            renderCell: (params: any) => {
                return <ADataGridButtonGroup>{actions.map((a) => a(params))}</ADataGridButtonGroup>;
            },
            renderHeader: () => <div />,
            sortable: false,
            width: actions.length * 36 + 28,
        });
    }

    if (enableRowNumbers) {
        columns2.unshift({
            field: 'RowNumber',
            filterable: false,
            headerName: '#',
            sortable: false,
            renderCell: (params) => (
                <span style={rowNumbersCss} data-robot-id={`data-grid-row-id-${params.value}`}>
                    {params.value}
                </span>
            ),
            valueGetter: (params) =>
                isNotNil(params.row.id)
                    ? params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
                    : 0,
            renderHeader: () => <span style={rowNumbersCss}>#</span>,
            width: 50,
        });
    }

    const handleFilterModelChange = () => {
        if (props.apiRef !== undefined) {
            if (props.apiRef.current.state.pagination.paginationModel.page > 0)
                props.apiRef.current.setPage(0);
        } else {
            if (apiRef.current.state.pagination.paginationModel.page > 0) apiRef.current.setPage(0);
        }
    };

    // Conditional styles applied here. Please, you are welcome to move this to StyledDataGrid if you know how to use styled outside of component with component props http://jira.mtech.fi/browse/AN-2061
    const enableConditionalOverlayStyling = (): SxProps<Theme> | undefined => {
        return props.rows.length === 0
            ? {
                  '.MuiDataGrid-overlayWrapper': {
                      height: '100px',
                  },
                  '.MuiDataGrid-virtualScroller': {
                      overflowX: 'hidden',
                  },
              }
            : {};
    };

    return (
        <StyledDataGrid
            apiRef={props.apiRef ? props.apiRef : apiRef}
            slots={{
                toolbar: CustomToolbar,
                noResultsOverlay: CustomNoResultsOverlay,
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: CustomLoadingOverlay,
            }}
            className={clsx(props.className, stickyHeaderClass)}
            columns={columns2}
            ref={ref}
            rowSelectionModel={selectionModel}
            onFilterModelChange={() => handleFilterModelChange()}
            rows={props.rows}
            disableRowSelectionOnClick={disableSelectionOnClick ?? false}
            checkboxSelection={enableRowSelection}
            disableColumnFilter={!enableFiltering}
            pagination={enablePagination ?? false}
            pageSizeOptions={enablePagination ? [paginationModel.pageSize] : undefined}
            paginationMode={'client'}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableColumnMenu={true}
            disableColumnReorder={true}
            disableColumnResize={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            getRowClassName={getRowClassName}
            isRowSelectable={isRowSelectable}
            hideFooter={!enablePagination && !enableRowSelection}
            hideFooterPagination={!enablePagination}
            hideFooterRowCount={enablePagination === true ? false : true}
            hideFooterSelectedRowCount={!enableRowSelection}
            onRowSelectionModelChange={onSelectionModelChange}
            localeText={gridUtils.getGridLocaleTxt(t)}
            loading={loading}
            onRowClick={props.onRowClick}
            rowHeight={33}
            sx={enableConditionalOverlayStyling()}
        />
    );
});
