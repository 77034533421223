// Libraries
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { ResourceTextApplication } from "../../core/resources";

export interface WeekNumberProps {
    date: Date;
}

export default function WeekNumber(props: WeekNumberProps) {
    const date = moment(props.date);
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCalendar']);

    return (
        <>
            {date.weekday() === 0 && (
                <span
                    style={{
                        color: '#999',
                        float: 'left',
                        fontSize: '16px',
                        fontWeight: 'normal',
                        lineHeight: '22px',
                        padding: '0 5px',
                    }}
                >
                  {t('AnelmaCalendar:1016')} {date.week()}
                </span>
            )}
        </>
    );
}
