// Libraries
import { ReactNode } from 'react';

// MUI
import { Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material';

// Common
import { AIconButtonBaseProps } from '../../common/buttons';

const StyledButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(90deg, #0a4c31 0%, #237a57 100%)',
    borderRadius: '100px',
    textTransform: 'none',
}));

export interface AActionIconButtonProps extends AIconButtonBaseProps {
    icon: ReactNode;
}

export default function ADeleteIconButton(props: AActionIconButtonProps) {
    const { icon, tooltip, ...buttonProps } = props;

    return (
        <Tooltip title={tooltip || ''}>
            <span>
                <StyledButton {...buttonProps} color='primary' variant='contained'>
                    {icon}
                </StyledButton>
            </span>
        </Tooltip>
    );
}
