// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { mergeRight } from 'ramda';

// MUI
import {
    Container,
    Grid,
    Table,
    Typography,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import { TableContainer } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../../core/resources';

// Common
import { ADataGrid, AGridColumns } from '../../../common/dataGrid';

// Interfaces
import { IAnimalReturn } from '../../../interfaces/IAccounting';

// Other
import InvoiceMathHelper from '../../invoices/InvoiceMathHelper';

export interface AnimalReturnTableProps {
    list: IAnimalReturn[];
}

export default function AnimalReturnTable({ list }: AnimalReturnTableProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccounting']);

    const [carcassPartResources, setCarcassPartResources] = useState<ParameterValues>([]);

    useEffect(() => {
        paramRepository
            .load(['AnelmaAccounting'])
            .then(() =>
                setCarcassPartResources(paramRepository.resource('AnelmaAccounting', 'CarcassPart'))
            );
    }, []);

    const getCarcassPartText = (carcassPartText: string, carcassPart: number | null) => {
        if (carcassPartText.length === 0) {
            let resourceIndex = -1;

            if (carcassPart != null && carcassPart !== 0)
                resourceIndex = carcassPartResources.findIndex(
                    (r) => Number(r.code) === carcassPart
                );

            if (resourceIndex !== -1) return carcassPartResources[resourceIndex].text;

            return '';
        }

        return carcassPartText;
    };

    return (
        <>
            {list.length > 0 && (
                <Container>
                    <TableContainer
                        key={'accounting-animal-removals'}
                        component={Paper}
                        style={{ maxWidth: '600px' }}
                    >
                        <Typography variant='h5'>{t('AnelmaAccounting:1101')}</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>
                                        {t('AnelmaAccounting:1052')}{' '}
                                    </TableCell>
                                    <TableCell align='left'>
                                        {t('AnelmaAccounting:1101')}{' '}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('AnelmaAccounting:1073')}{' '}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {t('AnelmaAccounting:1070')}{' '}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align='left'> {row.AnimalIdentifier}</TableCell>
                                        <TableCell align='left'>
                                            {' '}
                                            {getCarcassPartText(
                                                row.CarcassPartText,
                                                row.CarcassPart
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {InvoiceMathHelper.roundThousandLeading(row.Kilograms)}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {InvoiceMathHelper.roundThousandLeading(
                                                row.PriceBeforeTaxes
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            )}
        </>
    );
}
