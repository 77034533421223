// Libraries
import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Collapse, List, Typography } from '@mui/material';

// Feature
import { MainMenuItemType, IMainMenuItem } from '../mainMenu/IMainMenu';
import MainMenuItem from '../mainMenu/MainMenuItem';
import SubMenuItem from '../subMenu/SubMenuItem';

export interface MobileMenuGroupProps {
    closeMenu: () => void;
    key: Key;
    item: MainMenuItemType;
}

export default function MobileMenuGroup(props: MobileMenuGroupProps) {
    const { t } = useTranslation('AnelmaLayout');

    const [open, setOpen] = useState<boolean>(false);

    const groups = (props.item as IMainMenuItem).itemGroups || [];

    return (
        <div key={props.key}>
            <div
                className={`an-mobile-menu-main-level ${
                    open ? 'an-mobile-menu-main-level-open' : ''
                }`}
                onClick={() => setOpen(!open)}
            >
                <MainMenuItem closeSubMenu={() => props.closeMenu()} item={props.item} />
            </div>

            <Collapse className='an-mobile-menu-sub-level' in={open}>
                {groups.map((group, index) => (
                    <List
                        key={index}
                        component='nav'
                        subheader={
                            group.headerCode ? (
                                <Typography variant='h4'>
                                    {t(`AnelmaLayout:${group.headerCode}`)}
                                </Typography>
                            ) : undefined
                        }
                    >
                        {group.items.map((item, index) => (
                            <div className='an-mobile-menu-sub-level-item'>
                                <SubMenuItem closeSubMenu={() => props.closeMenu()} item={item} />
                            </div>
                        ))}
                    </List>
                ))}
            </Collapse>
        </div>
    );
}
