// Libraries
import { useTranslation } from 'react-i18next';

// MUI
// Core
import { ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

// Common
import {
    ADataGrid,
    gridColumns,
    ADataGridActions,
    AGridColumns,
    gridUtils,
} from '../common/dataGrid';
import { AEditIconButton } from '../common/buttons';

// Interfaces
import { ICompanyRow } from '../interfaces/IBusinessEntities';
import { CompanyEntityTypeEnum } from '../interfaces/enums';

// Store
// API
// Feature

export interface CompanyViewProps {
    data: ICompanyRow[];
    isLoading: boolean;
    openEdit: (companyId: string, companyType: CompanyEntityTypeEnum) => void;
}

export function CompanyView(props: CompanyViewProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    let companyDataRows = gridUtils.copyGridId(props.data);
    companyDataRows = gridUtils.addMissingDataPlaceholder(companyDataRows, [
        'County',
        'Municipality',
    ]);

    const columns: AGridColumns = [
        gridColumns.businessCode(t('AnelmaCompany:1004')),
        gridColumns.companyName(t('AnelmaCompany:1016')),
        gridColumns.emailAddress(t('AnelmaCompany:1018')),
        gridColumns.phoneNumber(t('AnelmaCompany:1013')),
        gridColumns.address(t('AnelmaCompany:1017')),
        gridColumns.county(companyDataRows, t('AnelmaCompany:1009')),
        gridColumns.municipality(companyDataRows, t('AnelmaCompany:1008')),
        gridColumns.producerNumber(t('AnelmaCompany:1003')),
        gridColumns.farmId(t('AnelmaCompany:1021')),
    ];

    const actions: ADataGridActions = [];

    if (auth.canUpdateCompany) {
        actions.push((params) => {
            const row = params.row as unknown as ICompanyRow;
            const title = row.CompanyType === CompanyEntityTypeEnum.Farm ? 1076 : 1105;
            return (
                <AEditIconButton
                    key={`${params.id}-edit`}
                    onClick={() => {
                        props.openEdit(row.Id, row.CompanyType);
                    }}
                    tooltip={t(`AnelmaCompany:${title}`)}
                />
            );
        });
    }

    return (
        <ADataGrid
            actions={actions}
            columns={columns}
            enableFiltering
            enableRowNumbers
            loading={props.isLoading}
            rows={companyDataRows}
            stickyHeader
        />
    );
}
