// Core
import { eventsUrl, publicApiUrl } from '../api/apiEndpoints';
import { IEvent, IRegistrationForm } from '../interfaces/IEvent';
import { GUIDType } from '../interfaces/types';
import axios from 'axios';
import { IApiResult } from '../interfaces/IGeneral';

import ApiBase from './ApiBase';

class EventsApi extends ApiBase {
    createEvent(payload: IEvent) {
        return this.post<IEvent>(`${eventsUrl}StoreEvent`, payload);
    }

    updateEvent(payload: IEvent) {
        return this.put<IEvent>(`${eventsUrl}UpdateEvent`, payload);
    }

    getEvents() {
        return this.getList<IEvent>(`${eventsUrl}GetEvents`);
    }

    getEventsByDate(loggedIn: boolean, from: string, to: string) {
        const root = loggedIn ? eventsUrl : `${publicApiUrl}/`;
        return this.getList<IEvent>(`${root}GetEventsByDate/from/${from}/to/${to}`);
    }

    registerToEvent(data: IRegistrationForm[], loggedIn?: boolean) {
        const url = !!loggedIn ? eventsUrl : `${publicApiUrl}/`;
        var payload = JSON.stringify({ items: data });
        return this.postList<IRegistrationForm>(`${url}StoreRegistration`, JSON.parse(payload));
    }

    editRegistration(data: IRegistrationForm[]) {
        var payload = JSON.stringify({ items: data });
        return this.postList<IRegistrationForm>(
            `${eventsUrl}EditRegistration`,
            JSON.parse(payload)
        );
    }


    checkRegistration(userId: GUIDType | null, eventId: GUIDType) {
        return this.getList<IRegistrationForm>(
            `${eventsUrl}CheckRegistration/${userId}/${eventId}`
        );
    }

    deleteEvent(id: GUIDType, contentId: GUIDType){
        return axios.delete<IApiResult<IEvent>>(`${eventsUrl}DeleteEvent`, {data: {id: id, contentId: contentId}}).then((d) => d.data);
    }
}

export default new EventsApi();
