import moment from 'moment';
import { paramRepository } from '../../core/resources';
import {
    AnimalFilterTypeEnum,
    AnimalTypeEnum,
    AnnouncementTypeEnum,
    CarouselBoxTypeEnum,
    FarmingTypeEnum,
} from '../../interfaces/enums';
import { IAnimalSummary, IFilteredAnimalList } from '../../interfaces/IAnimal';
import { IAnimalAnnouncement } from '../../interfaces/IAnnouncement';
import { IFarmDetails, IProductionLine } from '../../interfaces/IBusinessEntities';
import { ACourouselItemList } from './ACarousel';
import { ACarouselDropdownItem } from './ACarouselItem';
import { ParamFilter } from './AnimalList';

export interface IACarouselMainConfig {
    [key: number]: IACarouselMainItemConfig;
}
export interface IACarouselMainItemConfig {
    [key: number]: IACarouselSubItemConfig;

    hide?: (productionLine: IProductionLine | undefined | null) => boolean;
    allAnimals: (allAnimalsData: IFilteredAnimalList[]) => IFilteredAnimalList | undefined | null;
    allAnnouncements?: (allAnnouncements: IAnimalAnnouncement[]) => IAnimalAnnouncement[];
    items: (productionLine: IProductionLine | undefined | null, showItems: number[]) => void;
}
export interface IACarouselSubItemConfig {
    animals: (
        allAnimals: IFilteredAnimalList | undefined | null,
        getMilkBreeds: number[]
    ) => IAnimalSummary[] | undefined | null;
    announcements?: (announcements: IAnimalAnnouncement[]) => IAnimalAnnouncement[];
}

const containsFarmingTypes = (
    productionLine: IProductionLine | undefined | null,
    types: FarmingTypeEnum[]
): boolean => {
    if (productionLine && productionLine.FarmingTypes.find((f) => types.find((ft) => ft === f)))
        return true;

    return false;
};

const ageInMonths = (birthDate: Date) => {
    return moment().diff(moment(birthDate), 'months', true);
};

// Odotustila - 1
const waitingStatus: IACarouselMainItemConfig = {
    allAnimals: (aad) => aad.find((al) => al.FilterType === AnimalFilterTypeEnum.WaitingRoom),
    // - Hiehot
    8: {
        animals: (aa) => aa?.Animals.filter((x) => x.Sex === 1),
    },
    // - Sonnit
    16: {
        animals: (aa) => aa?.Animals.filter((x) => x.Sex === 0),
    },
    hide: (pl) =>
        !containsFarmingTypes(pl, [FarmingTypeEnum.Upbringing, FarmingTypeEnum.Transmission]),
    items: (pl, showItems) => {
        if (containsFarmingTypes(pl, [FarmingTypeEnum.Upbringing, FarmingTypeEnum.Transmission]))
            showItems.push(8, 16);
    },
};

// Lehmät - 2
const cows: IACarouselMainItemConfig = {
    allAnimals: (aad) => aad.find((al) => al.FilterType === AnimalFilterTypeEnum.Cow),
    // - lehmät
    9: {
        animals: (aa, milkbreeds) =>
            aa?.Animals.filter((x) => milkbreeds.find((b) => b === x.Breed)),
    },
    // - Emot
    7: {
        animals: (aa, milkbreeds) =>
            aa?.Animals.filter((x) => !milkbreeds.find((b) => b === x.Breed)),
    },
    // - AC-emot
    1: {
        animals: (aa) => aa?.Animals.filter((x) => [34].find((st) => st === x.SalesType)),
    },
    hide: (pl) => !containsFarmingTypes(pl, [FarmingTypeEnum.Calving, FarmingTypeEnum.Dairy]),
    items: (pl, showItems) => {
        if (containsFarmingTypes(pl, [FarmingTypeEnum.Dairy])) showItems.push(9);

        if (containsFarmingTypes(pl, [FarmingTypeEnum.Calving])) showItems.push(7, 1);
    },
};

// Hiehot - 3
const heifers: IACarouselMainItemConfig = {
    allAnimals: (aad) => aad.find((al) => al.FilterType === AnimalFilterTypeEnum.Heifer),
    // - Liharotu 50 %
    // 10: {
    //     animals: (aa, milkbreeds) =>
    //         aa?.Animals.filter(
    //             (x) => Number(x.CrossBreed) === 1 && !milkbreeds.find((b) => b === x.Breed)
    //         ),
    // },
    // - Vasikat, alle 6kk
    21: {
        animals: (aa) => aa?.Animals.filter((x) => ageInMonths(x.BirthDate) < 6),
    },
    // - Siitoshiehot
    11: {
        animals: (aa) => aa?.Animals.filter((x) => [73, 67].find((st) => st === x.SalesType)),
    },
    // - AC-hiehot
    2: {
        animals: (aa) => aa?.Animals.filter((x) => [35].find((st) => st === x.SalesType)),
    },
    items: (pl, showItems) => {
        // if (containsFarmingTypes(pl, [FarmingTypeEnum.Dairy])) showItems.push(10);

        if (containsFarmingTypes(pl, [FarmingTypeEnum.Calving])) showItems.push(21, 11, 2);
    },
};

// Sonnit - 4
const bulls: IACarouselMainItemConfig = {
    allAnimals: (aad) => aad.find((al) => al.FilterType === AnimalFilterTypeEnum.Bull),
    // - Liharotu 50%
    // 10: {
    //     animals: (aa, milkbreeds) =>
    //         aa?.Animals.filter(
    //             (x) => Number(x.CrossBreed) === 1 && !milkbreeds.find((b) => b === x.Breed)
    //         ),
    // },
    // - Sonnit, alle 6kk
    15: {
        animals: (aa) => aa?.Animals.filter((x) => ageInMonths(x.BirthDate) < 6),
    },
    // - Sonnit, >= 6kk
    13: {
        animals: (aa) => aa?.Animals.filter((x) => ageInMonths(x.BirthDate) >= 6),
    },
    // - Sonnit, 12 kk ja yli
    14: {
        animals: (aa) => aa?.Animals.filter((x) => ageInMonths(x.BirthDate) >= 12),
    },
    // - Siitossonnit
    12: {
        animals: (aa) => aa?.Animals.filter((x) => [72].find((st) => st === x.SalesType)),
    },
    // - AC-sonnit
    3: {
        animals: (aa) => aa?.Animals.filter((x) => [34].find((st) => st === x.SalesType)),
    },
    items: (pl, showItems) => {
        // if (containsFarmingTypes(pl, [FarmingTypeEnum.Dairy])) showItems.push(10, 14);
        if (containsFarmingTypes(pl, [FarmingTypeEnum.Dairy])) showItems.push(14);

        if (containsFarmingTypes(pl, [FarmingTypeEnum.Calving])) showItems.push(15, 13, 12, 3);
    },
};

// Myynti-ilmoitukset - 5
const saleAnnouncements: IACarouselMainItemConfig = {
    allAnimals: (aad) => aad.find((al) => al.FilterType === AnimalFilterTypeEnum.Sales),
    allAnnouncements: (allAnnouncements) =>
        allAnnouncements.filter(
            (x) =>
                x.Type === AnnouncementTypeEnum.Intermediation ||
                x.Type === AnnouncementTypeEnum.ManualBovineIntermediation ||
                x.Type === AnnouncementTypeEnum.Slaughter ||
                x.Type === AnnouncementTypeEnum.ManualBovineSlaughter
        ),
    // - Teurasilmoitukset
    17: {
        animals: (aa) => aa?.Animals,
        announcements: (announcements) =>
            announcements.filter(
                (x) =>
                    x.Type === AnnouncementTypeEnum.Slaughter ||
                    x.Type === AnnouncementTypeEnum.ManualBovineSlaughter
            ),
    },
    // - Välitystilmoitukset
    22: {
        animals: (aa) => aa?.Animals,
        announcements: (announcements) =>
            announcements.filter(
                (x) =>
                    x.Type === AnnouncementTypeEnum.Intermediation ||
                    x.Type === AnnouncementTypeEnum.ManualBovineIntermediation
            ),
    },
    items: (pl, showItems) => {
        showItems.push(17, 22);
    },
};

// Vasikkatilaukset - 6
const calfOrders: IACarouselMainItemConfig = {
    allAnimals: (aad) => aad.find((al) => al.FilterType === AnimalFilterTypeEnum.Calvings),
    allAnnouncements: (allAnnouncements) =>
        allAnnouncements.filter((x) => x.Type === AnnouncementTypeEnum.BovinePurchase),
    23: {
        animals: (aa) => aa?.Animals,
        announcements: (announcements) => announcements,
    },
    hide: (pl) =>
        !containsFarmingTypes(pl, [FarmingTypeEnum.Upbringing, FarmingTypeEnum.Transmission]),
    items: (pl, showItems) => {
        if (containsFarmingTypes(pl, [FarmingTypeEnum.Upbringing, FarmingTypeEnum.Transmission]))
            showItems.push(23);
    },
};

// Kaikki - 7
const all: IACarouselMainItemConfig = {
    allAnimals: (aad) => aad.find((al) => al.FilterType === AnimalFilterTypeEnum.All),
    items: () => {},
};

// Poistuneet - 8
const deceased: IACarouselMainItemConfig = {
    allAnimals: (aad) => aad.find((al) => al.FilterType === AnimalFilterTypeEnum.Deceased),
    items: () => { },
};

export class ACarouselConfig {
    config: IACarouselMainConfig = {};
    milkBreeds: number[] | null = null;

    /**
     *
     */
    constructor() {
        this.config[CarouselBoxTypeEnum.WaitingStatus] = waitingStatus;
        this.config[CarouselBoxTypeEnum.Cow] = cows;
        this.config[CarouselBoxTypeEnum.Heifer] = heifers;
        this.config[CarouselBoxTypeEnum.Bull] = bulls;
        this.config[CarouselBoxTypeEnum.SaleAnnouncement] = saleAnnouncements;
        this.config[CarouselBoxTypeEnum.CalfOrder] = calfOrders;
        this.config[CarouselBoxTypeEnum.AllAnimals] = all;
        this.config[CarouselBoxTypeEnum.Deceased] = deceased;
    }

    private getMilkBreeds = (): number[] => {
        if (this.milkBreeds === null) {
            this.milkBreeds = paramRepository
                .resource('AnelmaBovine', 'MilkBreedGroup')
                .map((x) => Number(x.code));
        }
        return this.milkBreeds || [];
    };

    public getAnimals = (
        allAnimals: IFilteredAnimalList[],
        selectedFilter: ParamFilter
    ): IAnimalSummary[] => {
        const main = this.config[selectedFilter.paramCode];
        const milkBreeds = this.getMilkBreeds();
        if (main) {
            const sub = main[selectedFilter.subParam];
            const mainAnimals = main.allAnimals(allAnimals);
            if (sub && mainAnimals) {
                return sub.animals(mainAnimals, milkBreeds) ?? [];
            }
            if (mainAnimals) {
                return mainAnimals.Animals;
            }
        }
        return [];
    };

    public getCarouselBoxes = (
        farmData: IFarmDetails | null | undefined,
        allAnimalsData: IFilteredAnimalList[],
        allAnnouncementsData: IAnimalAnnouncement[]
    ): ACourouselItemList[] => {
        const productionLine = farmData
            ? farmData.ProductionLines.find((p) => p.Type === AnimalTypeEnum.Bovine)
            : null;

        const milkBreeds = this.getMilkBreeds();

        const boxes: ACourouselItemList[] = [];

        for (let mainKey in this.config) {
            const main = this.config[mainKey];

            if (main.hide && main.hide(productionLine)) continue;

            const useAnimals = !main.allAnnouncements;

            const allAnimals = useAnimals ? main.allAnimals(allAnimalsData) : null;
            const allAnnouncements = main.allAnnouncements
                ? main.allAnnouncements(allAnnouncementsData)
                : [];

            const box = {
                additionalInfo: [],
                id: Number(mainKey),
                dropdownItems: [
                    {
                        code: Number(mainKey),
                        count: useAnimals ? allAnimals?.AnimalCount || 0 : allAnnouncements.length,
                        isDefaultSelection: true,
                    } as ACarouselDropdownItem,
                ],
                additionalText: '',
            } as ACourouselItemList;

            const showDropdownItemIds: number[] = [];
            main.items(productionLine, showDropdownItemIds);

            for (let subKey of showDropdownItemIds) {
                const sub = main[subKey];
                const subItems = useAnimals
                    ? sub.animals(allAnimals, milkBreeds)
                    : sub.announcements
                    ? sub.announcements(allAnnouncements)
                    : allAnnouncements;

                if (subItems && subItems.length > 0)
                    box.dropdownItems.push({
                        code: subKey,
                        count: subItems.length,
                        isDefaultSelection: false,
                    } as ACarouselDropdownItem);
            }

            boxes.push(box);
        }

        return boxes;
    };
}

export const carouselConfig = new ACarouselConfig();
