//React
import React, { useState, useEffect } from 'react';

// MUI
import { Grid, Typography, Container } from '@mui/material';

// Libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

//Store
import { useAppSelector } from '../../store/hooks';

// Common

import ViewLoader from '../../common/ViewLoader';

// Core
import { paramRepository, ResourceTextApplication } from '../../core/resources';
import auth from '../../core/authorization';

//API
import companyApi from '../../api/companyApi';

//Feature
import AccountingListView, { AccountingListViewProps } from './AccountingListView';

//Interface
import { AliasTypeEnum } from '../../interfaces/enums';
import { IProducerInfo } from '../../interfaces/IAccounting';

export default function Accounting() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaLayout']);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [producerNumberContext, setProducerNumberContext] = useState<number>(0);
    const [nameContext, setNameContext] = useState<string>('');
    const [yTunnusContext, setYTunnusContext] = useState<string>('');
    const [addressContext, setAddressContext] = useState<string>('');

    const producerInfoContext: IProducerInfo = {
        ProducerAddress: addressContext,
        ProducerName: nameContext,
        ProducerYTunnus: yTunnusContext,
        ProducerNumber: producerNumberContext,
    };

    const accountsListViewProps: AccountingListViewProps = {
        contextData: { ...producerInfoContext },
    };

    const context = useAppSelector((state) => state.context);
    useEffect(() => {
        if (context.data.currentContext) {
            setIsLoading(true);
            const companyByContext = companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    const producerNumberContext = response?.Entity.Aliases.find(
                        (i) => i.Type === AliasTypeEnum.ProducerNumber
                    );
                    setProducerNumberContext(producerNumberContext?.Value as unknown as number);
                    setNameContext(
                        response?.Entity.Names.find((i) => i.IsDefault)?.Value as string
                    );
                    setYTunnusContext(response?.Entity.BusinessCode.Value as string);
                    setAddressContext(
                        response?.Entity.Addresses.find((i) => i.IsDefault)
                            ?.NormalizedAddress as string
                    );
                });

            setIsLoading(false);
        } else setProducerNumberContext(0);
    }, [context]);

    return (
        <div data-robot-id = {"app-body-economy-accounting"}>
            <Container>
                {!isLoading ? (
                    auth.canViewEconomyAccounting && (
                        <AccountingListView {...accountsListViewProps} />
                    )
                ) : (
                    <ViewLoader />
                )}
            </Container>
        </div>
    );
}
