// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import DeleteIcon from '@mui/icons-material/Delete';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AIconButtonBaseProps, ADangerIconButton } from '../../common/buttons';

export interface ADeleteIconButtonProps extends AIconButtonBaseProps {}

export default function ADeleteIconButton(props: ADeleteIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1012');

    return <ADangerIconButton {...buttonProps} icon={<DeleteIcon />} tooltip={tooltip2} />;
}
