// MUI
import { Typography } from '@mui/material';

// Common
import { AInputContainer } from '../../common/inputs';

export interface ADialogSubHeadingProps {
    children: React.ReactNode;
}

export default function ADialogSubHeading(props: ADialogSubHeadingProps) {
    return (
        <AInputContainer>
            <Typography
                variant='h4'
                style={{ fontSize: '1.5rem', marginTop: '20px', textTransform: 'uppercase' }}
            >
                {props.children}
            </Typography>
        </AInputContainer>
    );
}
