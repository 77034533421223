// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../common/dialog';
import { ATextInput, AZipAndCityInput } from '../../common/inputs';

// Interfaces
import { AnimalTypeEnum } from '../../interfaces/enums';
import { IHoldingSite, IHoldingSiteWithId, IAddress } from '../../interfaces/IBusinessEntities';

export interface AddCompanyHoldingSiteProps {
    data: IHoldingSiteWithId;
    mode: 'create' | 'modify';
    onAdd: (data: IHoldingSite) => void;
    onClose: () => any;
    onEdit: (data: IHoldingSiteWithId) => void;
    specieType: number;
    validator: IFormValidator;
}

export default function AddCompanyHoldingSite(props: AddCompanyHoldingSiteProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);
    const { enqueueSnackbar } = useSnackbar();

    const [city, setCity] = useState<string>(props.data.Address.City);
    const [entryCode, setEntryCode] = useState<string>(props.data.EntryCode);
    const [holdingSiteId, setHoldingSiteId] = useState<string>(props.data.HoldingSiteId);
    const [street, setStreet] = useState<string>(props.data.Address.Street);
    const [zip, setZip] = useState<string>(props.data.Address.Zip);

    const onHoldingSiteSave = () => {
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }

        let newAddress = { ...props.data.Address };
        newAddress.Zip = zip;
        newAddress.City = city;
        newAddress.Street = street;

        const newHoldingSite: IHoldingSiteWithId = {
            Address: newAddress,
            EntryCode: entryCode,
            HoldingSiteId: holdingSiteId,
            Type: props.specieType,
            _i: props.data._i,
        };

        if (props.mode === 'create') props.onAdd(newHoldingSite);
        else props.onEdit(newHoldingSite);
    };

    const handleTextChange = (value: IAddress | string | number | null, property: string) => {
        switch (property) {
            case 'entryCode':
                setEntryCode(value as string);
                break;
            case 'holdingSiteId':
                setHoldingSiteId(value as string);
                break;
            case 'street':
                setStreet(value as string);
                break;
            case 'zipAndCity':
                setZip((value as IAddress).Zip);
                setCity((value as IAddress).City);
                break;
        }
    };

    return (
        <ADialog open onClose={() => props.onClose()}>
            <ADialogTitle>
                {t(`AnelmaCompany:${props.specieType === AnimalTypeEnum.Bovine ? '1050' : '1051'}`)}
            </ADialogTitle>

            <ADialogContent size='md'>
                <Grid container>
                    <Grid item xs={6}>
                        <ATextInput
                            lengthBoundaryName='AnelmaCompany:HoldingNumberLength'
                            id='id'
                            label={t('AnelmaCompany:1025')}
                            onChange={(v) => {
                                handleTextChange(v, 'holdingSiteId');
                            }}
                            regExp={/^[A-Z]{2}\d{12}$/}
                            required
                            validator={props.validator}
                            value={holdingSiteId}
                        />
                    </Grid>

                    {props.specieType === AnimalTypeEnum.Pork ? (
                        <Grid item xs={6}>
                            <ATextInput
                                lengthBoundaryName='AnelmaCompany:PigMarking'
                                id='entry-code'
                                label={t('AnelmaCompany:1028')}
                                onChange={(v) => {
                                    handleTextChange(v, 'entryCode');
                                }}
                                required
                                validator={props.validator}
                                value={entryCode ?? ''}
                            />
                        </Grid>
                    ) : (
                        ''
                    )}
                </Grid>

                <Grid container>
                    <Grid item xs={6}>
                        <ATextInput
                            id='street'
                            label={t('AnelmaCompany:1026')}
                            maxLength={8000}
                            minLength={1}
                            onChange={(v) => {
                                handleTextChange(v, 'street');
                            }}
                            required
                            validator={props.validator}
                            value={street}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <AZipAndCityInput
                            id='zip-city'
                            label={t('AnelmaCompany:1095')}
                            onChange={(v) => handleTextChange(v, 'zipAndCity')}
                            required
                            validator={props.validator}
                            value={props.data.Address}
                        />
                    </Grid>
                </Grid>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onClose(),
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => onHoldingSiteSave(),
                            type: 'ok',
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
