// Libraries
import moment from 'moment';

// MUI
// Core
// Common
// Interfaces
import { INotification } from '../../interfaces/INotification';

// Store
// API
// Feature - Notifications

/** Construct resource text for production plan notifications
 *
 * @param t TFunction
 * @param notifications INotification[]
 * @param prodPlanCompletedParams string
 * @returns
 */
export const getConstructedProdPlanNotifications = (
    resourceTxt: string,
    notifications: INotification[],
    prodPlanCompletedParams: string
) => {
    let constructedNotifications: INotification[] = [];

    for (const notification of notifications) {
        notification.ProductionPlanResourceTxt = resourceTxt.replace(
            '{ProductionPlanCompleted}',
            moment(prodPlanCompletedParams).format('DD.MM.YYYY')
        );
        constructedNotifications.push(notification);
    }

    return notifications;
};
