import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';

export const CustomToolbar = () => {
    return (
        <GridToolbarContainer data-robot-id='data-grid-custom-toolbar'>
            <GridToolbarColumnsButton data-robot-id='data-grid-custom-toolbar-columns-button' />
            <GridToolbarFilterButton data-robot-id='data-grid-custom-toolbar-filter-button' />
        </GridToolbarContainer>
    );
};
