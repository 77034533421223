// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication, paramRepository } from '../../core/resources';
import auth from '../../core/authorization';
import FormValidator from '../../core/FormValidator';

// Common
import { ATextInput, ASwitch, AAutocomplete } from '../../common/inputs';
import { AAddButton, ASearchIconButton } from '../../common/buttons';

// Interfaces
import { ICompanyRow } from '../../interfaces/IBusinessEntities';
import { CompanyEntityTypeEnum, AliasTypeEnum } from '../../interfaces/enums';

// Feature
import CompanySearchType from './CompanySearchType';
import companyApi from '../../api/companyApi';

export interface CompanySearchBarProps {
    afterSearch: (newPersonsList: ICompanyRow[]) => any;
    beforeSearch: () => void;
    onShowInactiveChange: (v: boolean) => void;
    openAddDialog: (companyType: CompanyEntityTypeEnum) => any;
}

export default function CompanySearchBar(props: CompanySearchBarProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaCompany']);
    const validator = new FormValidator();

    const [companies, setCompanies] = useState<ICompanyRow[]>([]);
    const [seletedSearchType, setSelectedSearchType] = useState<string>('Name');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchTermAutocomplete, setSearchTermAutocomplete] = useState<string>('');
    const [showInactive, setShowInactive] = useState<boolean>(false);

    const search = () => {
        if ((!isAutocomplete() && !searchTerm) || (isAutocomplete() && !searchTermAutocomplete))
            return;

        props.beforeSearch();

        searchCompanies(isAutocomplete() ? searchTermAutocomplete.trim() : searchTerm.trim());
    };

    const searchCompanies = (term: string) => {
        companyApi.getCompanies(seletedSearchType, term, showInactive).then((response) => {
            paramRepository.load(['AnelmaGeneral']).then(() => {
                var dataRows: ICompanyRow[] = [];

                if (!response) {
                    setCompanies([]);
                    return;
                }

                response.Items.forEach((data) => {
                    var dataRow: ICompanyRow;
                    const municipality = paramRepository.resource('AnelmaGeneral', 'Municipality');
                    const municipalityText =
                        municipality.find((p) => parseInt(p.code, 10) === data.MunicipalityCode)
                            ?.text ?? '';
                    const county = paramRepository.resource('AnelmaGeneral', 'Regions');
                    data.County = county.find((p) => p.code === data.County)?.text ?? '';

                    dataRow = {
                        ...data,
                        Municipality: municipalityText,
                        FarmId:
                            data.Aliases?.find((a) => a.Type === AliasTypeEnum.FarmId)?.Value ?? '',
                        ProducerNumber:
                            data.Aliases?.find((a) => a.Type === AliasTypeEnum.ProducerNumber)
                                ?.Value ?? '',
                    };
                    dataRows.push(dataRow);
                });

                setCompanies(dataRows);
            });
        });
    };

    useEffect(() => {
        props.onShowInactiveChange(showInactive);
        search();
    }, [showInactive]);

    useEffect(() => {
        props.afterSearch(companies);
    }, [companies]);

    const isAutocomplete = (): boolean => {
        if (seletedSearchType === 'Municipality' || seletedSearchType === 'County') return true;
        return false;
    };

    const getParamName = (): string[] => {
        if (seletedSearchType === 'Municipality') return ['AnelmaGeneral', 'Municipality'];
        else if (seletedSearchType === 'County') return ['AnelmaGeneral', 'Regions'];

        return [];
    };

    return (
        <form autoComplete='off' noValidate>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <CompanySearchType
                        onSearchTypeChange={(type) => setSelectedSearchType(type)}
                        seletedSearchType={seletedSearchType}
                        validator={validator}
                    />

                    {!isAutocomplete() ? (
                        <ATextInput
                            id='search'
                            label={t('AnelmaCompany:1054')}
                            onChange={(v) => setSearchTerm(v)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') search();
                            }}
                            style={{ marginLeft: '10px', marginRight: '10px', width: '300px' }}
                            validator={validator}
                            value={searchTerm}
                            withoutContainer
                        />
                    ) : (
                        <AAutocomplete
                            id='search'
                            label={t('AnelmaCompany:1054')}
                            onChange={(v) => setSearchTermAutocomplete(v as string)}
                            parameterName={getParamName().join(':')}
                            required={false}
                            style={{
                                display: 'inline-flex',
                                marginLeft: '10px',
                                marginRight: '10px',
                                width: '300px',
                                verticalAlign: 'top',
                            }}
                            validator={validator}
                            value={searchTermAutocomplete}
                            withoutContainer
                        />
                    )}

                    <ASearchIconButton
                        onClick={() => search()}
                        style={{ marginTop: '12px' }}
                        tooltip={t('AnelmaCompany:1054')}
                        type='action'
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} style={{ textAlign: 'right' }}>
                    {auth.canCreateCompany ? (
                        <>
                            <AAddButton
                                onClick={() =>
                                    props.openAddDialog(CompanyEntityTypeEnum.TrafficContractor)
                                }
                                type='action'
                            >
                                {t('AnelmaCompany:1096')}
                            </AAddButton>

                            <AAddButton
                                onClick={() => props.openAddDialog(CompanyEntityTypeEnum.Farm)}
                                style={{ marginLeft: '10px' }}
                                type='action'
                            >
                                {t('AnelmaCompany:1075')}
                            </AAddButton>
                        </>
                    ) : undefined}
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <ASwitch
                        checked={showInactive}
                        id='show-inactive'
                        label={t('AnelmaCompany:1097')}
                        onChange={() => setShowInactive(!showInactive)}
                        validator={validator}
                        withoutContainer
                    />
                </Grid>
            </Grid>
        </form>
    );
}
