//React
import { useState, useEffect } from 'react';

// Libraries / MUI
import { Container } from '@mui/material';

//Store
import { useAppSelector } from '../store/hooks';

// Common
import ViewLoader from '../common/ViewLoader';

// Core
import auth from '../core/authorization';

//API
import utilityApi from '../api/utilityApi';

//Feature
import SlaughterVariablesView, { SlaughterVariablesViewProps } from './SlaughterVariablesView';

//Interface
import { ICompanySummary } from '../interfaces/IBusinessEntities';

export default function SlaughterVariables() {
    const context = useAppSelector((state) => state.context);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [adminFarmInfo, setAdminFarmInfo] = useState<ICompanySummary | null>(null);

    const masterBovineData = () => {
        utilityApi.getHostingInfo().then((response) => {
            if (response) {
                setAdminFarmInfo(response.Entity);
            }
        });
    };

    useEffect(() => {
        if (auth.canViewVariablesAdmin) {
            setIsLoading(true);
            //As for master data on Adminstration -> SlaughterVariables
            //using api-endpoint /Utility/hosting-company-info
            masterBovineData();
        }
        setIsLoading(false);
    }, [context]);

    const slaughterVariableViewProps: SlaughterVariablesViewProps = {
        farmId: adminFarmInfo?.Id ?? '',
        farmInfo: null,
        adminValues: true,
    };
    return (
        <Container data-robot-id={'app-body-management-slaughter-variables'}>
            {!isLoading ? (
                auth.canViewVariablesAdmin && (
                    <SlaughterVariablesView {...slaughterVariableViewProps} />
                )
            ) : (
                <ViewLoader />
            )}
        </Container>
    );
}
