// Libraries
import { OutputBlockData } from '@editorjs/editorjs';

// MUI
// Core
import log from '../core/log';

// Common
// Interfaces
// Store
// API
// Feature
import ContentHeading from './render/ContentHeading';
import ContentImage from './render/ContentImage';
import ContentOrderedList from './render/ContentOrderedList';
import ContentParagraph from './render/ContentParagraph';
import ContentRaw from './render/ContentRaw';
import ContentTable from './render/ContentTable';
import ContentUnorderedList from './render/ContentUnorderedList';
import ContentDelimiter from './render/ContentDelimiter';

interface IProps {
    block: OutputBlockData<string, any>;
    id: string;
}

const Block = (props: IProps) => {
    const id = props.id;
    const data = props.block.data;
    const blockType = props.block.type;

    switch (props.block.type) {
        case 'header':
            return (
                <ContentHeading
                    data-robot-id={'render-content-header-' + id}
                    data={data}
                    key={id}
                    id={id}
                />
            );
        case 'image':
            return (
                <ContentImage
                    data={data}
                    key={id}
                    id={id}
                    data-robot-id={'render-content-image-' + id}
                />
            );
        case 'list':
            switch (data.style) {
                case 'ordered':
                    return (
                        <ContentOrderedList
                            data-robot-id={'render-content-items-' + id}
                            items={data.items}
                            key={id}
                            id={id}
                        />
                    );
                case 'unordered':
                    return (
                        <ContentUnorderedList
                            data-robot-id={'render-content-unorderedlist-' + id}
                            items={data.items}
                            key={id}
                            id={id}
                        />
                    );
                default:
                    log('error', `Missing implementation for WYSIWYG data type "${blockType}".`);
                    return <></>;
            }
        case 'paragraph':
            return (
                <ContentParagraph
                    data-robot-id={'render-content-paragraph-' + id}
                    data={data}
                    key={id}
                    id={id}
                />
            );
        case 'raw':
            return (
                <ContentRaw
                    data-robot-id={'render-content-raw-' + id}
                    html={(data as any).html}
                    key={id}
                    id={id}
                />
            );
        case 'table':
            return (
                <ContentTable
                    data={data}
                    key={id}
                    id={id}
                    data-robot-id={'render-content-table-' + id}
                />
            );
         case 'delimiter':
            return (
                <ContentDelimiter
                    data-robot-id={'render-content-delimiter-' + id}
                    data={data}
                    key={id}
                    id={id}
                />
            );
        default:
            log('error', `Missing implementation for WYSIWYG data type "${blockType}".`);
            return <></>;
    }
};

export default Block;
