import { IContactPerson, IPersonDetails, IPersonSummary } from '../../interfaces/IBusinessEntities';
import { ContactPersonTypeEnum } from '../../interfaces/enums';

export const getUsername = (person: IContactPerson): string => {
    // NOTE: Currently person can have only one username.
    const user = person.Logins[0];
    return user?.Login || '';
};

export const mapFromDetails = (person: IPersonDetails): IContactPerson => {
    const address = person.Addresses.find((i) => i.IsDefault)?.NormalizedAddress || '';
    const emailAddress = person.EmailAddresses.find((i) => i.IsDefault)?.Value || '';
    const name: string[] = [];
    const phoneNumber = person.PhoneNumbers.find((i) => i.IsDefault)?.NormalizedNumber || '';
    if (person.FirstName) name.push(person.FirstName);
    if (person.LastName) name.push(person.LastName);
    const contactPerson: IContactPerson = {
        Address: address,
        EmailAddress: emailAddress,
        Hash: '',
        Id: 0,
        IsDefault: false,
        Logins: person.Logins,
        Name: name.join(' '),
        PersonId: person.Id || '',
        PhoneNumber: phoneNumber,
        Roles: [],
        Type: ContactPersonTypeEnum.Unknown,
    };
    return contactPerson;
};

export const mapFromSummary = (person: IPersonSummary): IContactPerson => {
    const contactPerson: IContactPerson = {
        Address: person.Address,
        EmailAddress: person.EmailAddress,
        Hash: '',
        Id: 0,
        IsDefault: false,
        Logins: person.Logins.map((login) => {
            return { Login: login };
        }),
        Name: person.Name,
        PersonId: person.Id,
        PhoneNumber: person.PhoneNumber,
        Roles: [],
        Type: ContactPersonTypeEnum.Unknown,
    };
    return contactPerson;
};
