import { Authorization } from '../../../../../core/authorization';

export const canViewBovineSlaughterAnnouncementDialog = (auth: Authorization): boolean => {
    if (auth.canCreateBovineSlaughterAnnouncement) return false;
    else if (auth.canViewBovineSlaughterAnnouncementDialog) return false;
    else return true;
};

export const canViewBovineSlaughterAnnouncementSaveAsAdmin = (auth: Authorization): boolean => {
    return auth.canViewBovineSlaughterAnnouncementDialogAdmin ||
        auth.canCreateBovineSlaughterAnnouncementAdmin
        ? true
        : false;
};

export const canCreateSlaughterAnnouncement = (auth: Authorization): boolean => {
    return auth.canCreateBovineSlaughterAnnouncement ? false : true;
};

export const canCreateOrUpdateSlaughterAnnouncementWithoutChainInformation = (
    auth: Authorization
): boolean => {
    if (
        auth.canCreateBovineSlaughterAnnouncementAdmin ||
        auth.canUpdateBovineSlaughterAnnouncementAdmin
    )
        return false;
    return true;
};

export const canCreateSlaughterAnnouncementWithoutChainInformation = (
    auth: Authorization
): boolean => {
    return auth.canCreateBovineSlaughterAnnouncementAdmin ? false : true;
};
