import { AnelmaReportingBtnGroupNameEnum, AnelmaReportTypeEnum } from '../interfaces/enums';
import ReportView from './ReportView';

export interface IHealthReportProps {
    endQlikSession: (eligible: boolean) => void;
}

export default function HealthReport(props: IHealthReportProps) {
    return (       
        <ReportView
            title={1047}
            btnGroupParameterName={AnelmaReportingBtnGroupNameEnum.HealthReport}
            type={AnelmaReportTypeEnum.HealthReport}
            endQlikSession={props.endQlikSession}
        />
     
    );
}
