import axios from 'axios';
import { accountingUrl } from './apiEndpoints';
import { IApiListResult, IApiResult } from '../interfaces/IGeneral';

import { IAccounting, IAccountingDetails } from '../interfaces/IAccounting';
import ApiBase from './ApiBase';

class AccountingApi extends ApiBase {
    getProducerNumberAccounting(producerNumber: number) {
        return axios
            .get<IApiListResult<IAccounting>>(`${accountingUrl}?ProducerNumber=${producerNumber}`)
            .then((d) => d.data);
    }

    getPaymentNumberAccounting(paymentNumber: number) {
        return axios
            .get<IApiResult<IAccountingDetails>>(`${accountingUrl}/${paymentNumber}`)
            .then((d) => d.data);
    }
}

export default new AccountingApi();
