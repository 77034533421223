// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../../../core/resources';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonProp,
    ADialogContent,
    ADialogTitle,
} from '../../../../common/dialog';

export interface IConfirmDialogProps {
    title: string;
    content: string;
    actionButtonType: ADialogButtonProp['type'];
    onClose: () => void;
    onConfirm: () => void;
}

export default function ConfirmDialog(props: IConfirmDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBovine',
        'AnelmaGeneral',
        'AnelmaAnimalAnnouncement',
    ]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <ADialog open onClose={() => props.onClose()}>
            <ADialogTitle>{t(props.title)}</ADialogTitle>

            <ADialogContent size='sm' isLoading={isLoading}>
                <Typography variant='body1' sx={{ padding: '30px 60px', textAlign: 'center' }}>
                    {t(props.content)}
                </Typography>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            type: 'cancel',
                            onClick: () => props.onClose(),
                        },
                    ],
                    right: [
                        {
                            type: props.actionButtonType,
                            onClick: () => {
                                props.onConfirm();
                                props.onClose();
                            },
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
