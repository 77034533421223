// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Tooltip } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';

// Core
import { ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Interfaces
import { IPhoneNumber } from '../../interfaces/IBusinessEntities';

// Inputs
import { ATextInput } from '../inputs';

export interface APhoneNumberInputProps {
    defaultSelector?: JSX.Element;
    id: string;
    label?: React.ReactNode;
    onChange: (value: IPhoneNumber) => void;
    placeholder?: string;
    required?: boolean;
    style?: CSSProperties;
    validator: IFormValidator;
    value: IPhoneNumber;
    disabled?: boolean;
}

export default function APhoneNumberInput(props: APhoneNumberInputProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const updateValue = (value: string) => {
        const newValue: IPhoneNumber = {
            IsDefault: props.value.IsDefault,
            NormalizedNumber: value,
            Type: props.value.Type,
        };
        // Add the ui generated id if present
        if ((props.value as any)._i) (newValue as any)._i = (props.value as any)._i;
        props.onChange(newValue);
    };

    const validPhonNumberTypes: string[] = [
        '+358 40 1234567',
        '+358 (0)40 1234567',
        '(040) 1234567',
        '040-1234567',
        '0401234567',
    ];
    const phoneNumberTooltipText = (
        <span style={{ whiteSpace: 'pre-line' }}>{`${t(
            'AnelmaGeneral:1028'
        )} \n ${validPhonNumberTypes.join('\n')}`}</span>
    );

    const inputStyle = (withLabel: boolean) => {
        if (withLabel) return {};

        // NOTE: Add top margin to fix visual bug when label text is not given. -MM
        return {
            marginTop: '16px',
        };
    };

    return (
        <Tooltip title={phoneNumberTooltipText}>
            <ATextInput
                id={props.id}
                label={props.label}
                onChange={(v) => updateValue(v)}
                placeholder={props.placeholder || undefined}
                regExp={
                    /^(\d+)$|^(\d+[-]\d+)$|^([(]\d+[)][ ]\d+)$|^([+]\d+[ ]\d+[ ]\d+)$|^([+]\d+[ ][(][0][)]\d+[ ]\d+)$/
                }
                required={props.required}
                startAdornment={props.defaultSelector}
                style={inputStyle(!!props.label)}
                value={props.value.NormalizedNumber || ''}
                validator={props.validator}
                disabled={props.disabled !== undefined ? props.disabled : false}
            />
        </Tooltip>
    );
}
