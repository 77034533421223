import axios from 'axios';
import { invoiceUrl } from './apiEndpoints';
import { IApiListResult, IApiResult } from '../interfaces/IGeneral';

import { IInvoice, IInvoiceDetails } from '../interfaces/IInvoice';
import ApiBase from './ApiBase';

class InvoiceApi extends ApiBase {
    getProducerInvoice(producerNumber: number) {
        return axios
            .get<IApiListResult<IInvoice>>(`${invoiceUrl}?ProducerNumber=${producerNumber}`)
            .then((d) => d.data);
    }

    getInvoice(invoiceNumber: number) {
        return axios
            .get<IApiResult<IInvoiceDetails>>(`${invoiceUrl}/${invoiceNumber}`)
            .then((d) => d.data);
    }
}

export default new InvoiceApi();
