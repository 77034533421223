import { animalUrl, bovineUrl } from './apiEndpoints';
import ApiBase from './ApiBase';
import { GUIDType } from '../interfaces/types';
import {
    IAddManualAnimalResult,
    IAnimalSalesTypeUpdate,
    IAnimalSummaryExtraInfo,
    IBovineRegisterDataSyncResult,
    IDeleteAnimalResult,
    IFarmAnimal,
    ILatestBovineRegisterDataSyncRequest,
    IManualAnimal,
} from '../interfaces/IAnimal';

class AnimalsApi extends ApiBase {
    syncAnimals = (FarmGUID: GUIDType) => {
        return this.get<IBovineRegisterDataSyncResult>(
            `${bovineUrl}/SyncAnimalsFromBovineRegister?FarmGUID=${FarmGUID}`
        );
    };

    getLatestBovineRegisterDataSyncRequest = (farmGuid: GUIDType, successful = true) => {
        return this.get<ILatestBovineRegisterDataSyncRequest>(
            `${bovineUrl}/GetLatestBovineRegisterDataSyncRequest?FarmGUID=${farmGuid}&Successful=${successful}`
        );
    };

    updateAnimalSalesType(payload: IAnimalSalesTypeUpdate) {
        return this.putList<IFarmAnimal>(`${animalUrl}/UpdateSalesType`, payload);
    }

    addAnimalManually = (payload: IManualAnimal) => {
        return this.post<IAddManualAnimalResult>(`${animalUrl}/add-animal-manually`, payload);
    };

    deleteAnimal = (animalGuid: string) => {
        return this.delete<IDeleteAnimalResult>(`${animalUrl}/${animalGuid}`);
    };

    invalidateTilTuAnimal = (animal: IAnimalSummaryExtraInfo) => {
        let payload = { AnimalId: animal.Id, FarmId: animal.FarmId }
        return this.patch<IFarmAnimal>(`${animalUrl}/invalidate`, payload);
    }
}

export default new AnimalsApi();
