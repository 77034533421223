import { IClientResourcesWithLang, ParameterValueWithLang } from '../../../../core/resources';

/** Get parameter data with different languages.
 *
 * This was extracted from animal payload sms feature because of repetitive code when working on ticket: AN-961. Original ticket: AN-784. Is in it's original state, looks a bit tedious.
 */
export const getParameterValues = (
    paramData: IClientResourcesWithLang,
    paramName: string
): ParameterValueWithLang[] => {
    let paramArray: ParameterValueWithLang[] = [];
    for (let i = 0; i < paramData.parameterGroups.length; i++) {
        let lang = paramData.parameterGroups[i].langCode;
        let param = paramData.parameterGroups[i].parameters.find((i) => i.name === paramName);
        if (param !== undefined) {
            for (let ind = 0; ind < param.values.length; ind++) {
                paramArray.push({
                    text: param.values[ind].text,
                    code: param.values[ind].code,
                    langCode: lang,
                });
            }
        }
    }

    return paramArray;
};
