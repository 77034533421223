// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { CircularProgress } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';

// Common
// Interfaces
// Store
// API
// Feature

export interface ContentLoaderProps {
    size?: number;
    text?: string;
}

export default function ContentLoader(props: ContentLoaderProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaLayout']);

    const size = props.size || 36;

    return (
        <div>
            <CircularProgress size={size} style={{ float: 'left' }} />
            <div style={{ float: 'left', lineHeight: `${size}px`, marginLeft: '10px' }}>
                {props.text || t('AnelmaLayout:1082')}
            </div>
            <div style={{ clear: 'both' }}></div>
        </div>
    );
}
