// Libraries
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { mergeRight } from 'ramda';

// Api
import api from '../api/startupApi';

// Store
import { SliceStatus } from './IStore';

// Person
import { IPersonDetailsExtended } from '../interfaces/IBusinessEntities';
import { fetchPersonRelations } from '../persons/fetchPersonRelations';

interface UserDataState {
    data: IPersonDetailsExtended;
    status: SliceStatus;
}

const initialState: UserDataState = {
    data: {
        Addresses: [],
        Aliases: [],
        EffectiveTo: null,
        EmailAddresses: [],
        FirstName: '',
        Id: null,
        LanguageShortCode: 'FI',
        LastName: '',
        Logins: [],
        PhoneNumbers: [],
        IsSnellmanUser: null,
        CompanyRelations: [],
    },
    status: 'initial',
};

function loadData(state: Draft<UserDataState>, action: PayloadAction<IPersonDetailsExtended>) {
    state.data = mergeRight(state.data, action.payload);
    state.status = 'ready';
}

const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        load: loadData,
        updateIsLoading(state) {
            state.status = 'loading';
        },
    },
});

const { load, updateIsLoading } = userDataSlice.actions;

export const loadUserDataAsync = () => {
    return async (dispatch: any) => {
        try {
            dispatch(updateIsLoading());
            const userData = await api.getUserInfo();
            if (userData && userData?.Entity.Logins[0].Login)
                await fetchPersonRelations(userData.Entity.Logins[0].Login).then((response) => {
                    userData.Entity.IsSnellmanUser = response[0];
                    userData.Entity.CompanyRelations = response[1];
                });
            dispatch(load(userData?.Entity || initialState.data));
        } catch (err) {
            console.error(err);
        }
    };
};

export default userDataSlice.reducer;
