// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Typography } from '@mui/material';

// Core
import { paramRepository, ResourceTextApplication } from '../../core/resources';
import auth from '../../core/authorization';

// Common
import { InfoBlock } from '../../common/typography';

// Interfaces
import { AliasTypeEnum } from '../../interfaces/enums';

// Feature
import { FarmInfoSectionProps } from './FarmInfo';
import PhoneNumbers from '../PhoneNumbers';
import Language from '../Language';
import Addresses from './Addresses';

export default function BasicInfo(props: FarmInfoSectionProps) {
    const { data, getParamText } = props;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    const businessForms = paramRepository.resource('AnelmaCompany', 'CompanyType');

    return (
        <div data-robot-id = {"basic-info"}>
            <Typography variant='h3'>{t('AnelmaCompany:1123')}</Typography>

            <Grid container>
                <Grid item sm={6}>
                    <InfoBlock
                        label={t('AnelmaCompany:1005')}
                        value={data.Names.find((i) => i.IsDefault)?.Value}
                    />
                </Grid>

                <Grid item sm={6}>
                    <InfoBlock
                        label={t('AnelmaCompany:1007')}
                        value={getParamText(businessForms, data.BusinessForm.Type)}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item sm={6}>
                    <InfoBlock
                        label={t('AnelmaCompany:1003')}
                        value={
                            data.Aliases.find((i) => i.Type === AliasTypeEnum.ProducerNumber)?.Value
                        }
                    />
                </Grid>

                <Grid item sm={6}>
                    <InfoBlock label={t('AnelmaCompany:1004')} value={data.BusinessCode.Value} />
                </Grid>
            </Grid>

            <Grid container>
                {auth.canViewFarmId && (
                    <Grid item sm={6}>
                        <InfoBlock
                            label={t('AnelmaCompany:1021')}
                            value={data.Aliases.find((i) => i.Type === AliasTypeEnum.FarmId)?.Value}
                        />
                    </Grid>
                )}

                {auth.canViewHerdId && (
                    <Grid item sm={6}>
                        {props.bovineProduction && (
                            <InfoBlock label={t('AnelmaCompany:1022')} value={data.CattleId} />
                        )}
                    </Grid>
                )}
            </Grid>

            <Grid container>
                {auth.canViewAccountNumber && (
                    <Grid item sm={6}>
                        <InfoBlock
                            label={t('AnelmaCompany:1034')}
                            value={
                                data.Aliases.find((i) => i.Type === AliasTypeEnum.BankAccount)
                                    ?.Value
                            }
                        />
                    </Grid>
                )}

                <Grid item sm={6}>
                    <Language
                        label={t('AnelmaCompany:1006')}
                        languageCode={data.LanguageShortCode}
                    />
                </Grid>
            </Grid>

            <InfoBlock label={t('AnelmaCompany:1018')} value={data.EmailAddresses[0]?.Value} />

            <PhoneNumbers
                data={data.PhoneNumbers}
                types={paramRepository.resource('AnelmaGeneral', 'PhoneNumberType')}
            />

            <Addresses {...props} />
        </div>
    );
}
