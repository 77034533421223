import { IAnimalAnnouncement, IAnimalAnnouncementAnimal } from '../../../interfaces/IAnnouncement';

export const getDistinctSalesTypeAnnotationMsgs = (
    announcement: IAnimalAnnouncement | undefined | null,
    animals: IAnimalAnnouncementAnimal[] | null = null
): string[] => {
    const uniqueAnnotations: any = {};
    let annotations: string[] = [];

    if (!announcement && animals && animals?.length > 0) {
        for (const animal of animals) {
            for (let annotation of getSalesTypeAnnotationMsg(animal.SalesType)) {
                if (!uniqueAnnotations[annotation]) {
                    uniqueAnnotations[annotation] = true;
                    annotations.push(annotation);
                }
            }
        }

        return annotations;
    }

    if (announcement) {
        for (const batch of announcement.Batches) {
            for (const animal of batch.Animals) {
                for (let annotation of getSalesTypeAnnotationMsg(animal.SalesType)) {
                    if (!uniqueAnnotations[annotation]) {
                        uniqueAnnotations[annotation] = true;
                        annotations.push(annotation);
                    }
                }
            }
        }
    }

    return annotations;
};

export const getSalesTypeAnnotationMsg = (salesType: number): string[] => {
    const common = ['AnelmaBovine:1086'];
    switch (salesType) {
        case 28:
            return [...common, 'AnelmaBovine:1079'];
        case 53:
        case 55:
            return [...common, 'AnelmaBovine:1082'];
        case 54:
        case 56:
            return [...common, 'AnelmaBovine:1083', 'AnelmaBovine:1084', 'AnelmaBovine:1085'];
        case 61:
        case 62:
        case 63:
        case 64:
            return [...common, 'AnelmaBovine:1080'];
        case 65:
        case 66:
            return [...common, 'AnelmaBovine:1081'];
        default:
            return common;
    }
};
