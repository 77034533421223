import { TFunction } from 'i18next';
import { ParameterValues, ResourceTextApplication } from '../../core/resources';
import { ICompanyDetails, IPersonDetails } from '../../interfaces/IBusinessEntities';
import { FieldUpdate, IUpdatePayload } from '../../interfaces/IGeneral';
import {
    AnelmaEntityType,
    AnelmaUpdateEventSource,
    CQRSCommandType,
    ICQRSCommandEvent,
    ICQRSEventBase,
    IEventSourceEntity,
} from '../../interfaces/ILog';

export function ParseCommandToString(
    command: ICQRSEventBase,
    AALoggedOperation: ParameterValues
): { code: string; text: string } {
    var t: { code: string; text: string }[] = AALoggedOperation;

    switch ((command as ICQRSCommandEvent).Command) {
        case CQRSCommandType.CQRSCreate:
            return t.find((text) => text.code === '01') ?? { code: '01', text: '#failed for 01' };
        case CQRSCommandType.CQRSModify:
            if (HasUpdates(command))
                return (
                    t.find((text) => text.code === '02') ?? { code: '02', text: '#failed for 02' }
                );
            else if (HasAdditions(command))
                return (
                    t.find((text) => text.code === '03') ?? { code: '03', text: '#failed for 03' }
                );
            else if (HasDeletions(command))
                return (
                    t.find((text) => text.code === '04') ?? { code: '04', text: '#failed for 04' }
                );
            else
                return (
                    t.find((text) => text.code === '05') ?? { code: '05', text: '#failed for 05' }
                );
        case CQRSCommandType.CQRSRemoveEntity:
            return t.find((text) => text.code === '06') ?? { code: '06', text: '#failed for 06' };
        case CQRSCommandType.CQRSSupportRequest:
            return t.find((text) => text.code === '08') ?? { code: '08', text: '#failed for 08' };
        case CQRSCommandType.CQRSUnknown:
        default:
            return t.find((text) => text.code === '07') ?? { code: '07', text: '#failed for 07' };
    }
}

export function ParseEntityTypeToString(
    command: ICQRSEventBase,
    t: TFunction<ResourceTextApplication[]>
) {
    switch ((command as ICQRSCommandEvent).EntityType) {
        case AnelmaEntityType.Person:
            return t('AnelmaAudit:1053');
        case AnelmaEntityType.Farm:
            return t('AnelmaAudit:1054');
        case AnelmaEntityType.TrafficContractor:
            return t('AnelmaAudit:1055');
        case AnelmaEntityType.Announcement:
            return t('AnelmaAudit:1056');
        case AnelmaEntityType.Unknown:
        default:
            return t('AnelmaAudit:1057');
    }
}

export function ParseEntityName(
    command: ICQRSEventBase,
    AALoggedOperation: ParameterValues,
    t: TFunction<ResourceTextApplication[]>
): string {
    if (ParseCommandToString(command, AALoggedOperation).code === '01') {
        if (ParseEntityTypeToString(command, t) === t('AnelmaAudit:1053'))
            if (command) {
                return (
                    ((command as ICQRSCommandEvent).EventSource as IEventSourceEntity)?.Entity
                        ?.FirstName +
                    ' ' +
                    ((command as ICQRSCommandEvent).EventSource as IEventSourceEntity)?.Entity
                        ?.LastName
                );
            }
        if (
            ParseEntityTypeToString(command, t) === t('AnelmaAudit:1054') ||
            ParseEntityTypeToString(command, t) === t('AnelmaAudit:1055')
        )
            return (
                ((command as ICQRSCommandEvent).EventSource as ICompanyDetails).Names[0]?.Value ??
                t('AnelmaAudit:1058')
            );
    } else if (
        ParseCommandToString(command, AALoggedOperation).code === '02' ||
        ParseCommandToString(command, AALoggedOperation).code === '03' ||
        ParseCommandToString(command, AALoggedOperation).code === '04'
    ) {
        if (ParseEntityTypeToString(command, t) === t('AnelmaAudit:1053'))
            return (
                ((command as ICQRSCommandEvent).EventSource as IUpdatePayload<IPersonDetails>)
                    .Entity.FirstName +
                ' ' +
                ((command as ICQRSCommandEvent).EventSource as IUpdatePayload<IPersonDetails>)
                    .Entity.LastName
            );
        if (
            ParseEntityTypeToString(command, t) === t('AnelmaAudit:1054') ||
            ParseEntityTypeToString(command, t) === t('AnelmaAudit:1055')
        )
            return (
                ((command as ICQRSCommandEvent).EventSource as IUpdatePayload<ICompanyDetails>)
                    .Entity.Names[0]?.Value ?? t('AnelmaAudit:1058')
            );
    }

    return ParseCommandToString(command, AALoggedOperation).code === '01'
        ? t('AnelmaAudit:1058')
        : '';
}

export function GetFields(command: ICQRSEventBase) {
    var res: FieldUpdate[] = [];

    if (HasUpdates(command)) {
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates.forEach(
            (u) =>
                res.push({
                    OldData: JSON.stringify(u.OldData, null, 2),
                    NewData: JSON.stringify(u.NewData, null, 2),
                    Field: u.Field,
                })
        );
    }

    if (HasAdditions(command)) {
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Additions.forEach(
            (a) =>
                res.push({ OldData: '', NewData: JSON.stringify(a.Data, null, 2), Field: a.Field })
        );
    }

    if (HasDeletions(command)) {
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Deletions.forEach(
            (d) =>
                res.push({ OldData: JSON.stringify(d.Data, null, 2), NewData: '', Field: d.Field })
        );
    }

    return res;
}

export function ParseField(command: ICQRSEventBase, t: TFunction<ResourceTextApplication[]>) {
    if (HasUpdates(command)) {
        if (
            ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates
                .length === 1
        )
            return ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource)
                .Updates[0].Field;
        else return t('AnelmaAudit:1059');
    } else if (HasAdditions(command)) {
        if (
            ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Additions
                .length === 1
        )
            return ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource)
                .Additions[0].Field;
        else return t('AnelmaAudit:1059');
    } else if (HasDeletions(command)) {
        if (
            ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Deletions
                .length === 1
        )
            return ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource)
                .Deletions[0].Field;
        else return t('AnelmaAudit:1059');
    } else
        return (command as ICQRSCommandEvent).Command === CQRSCommandType.CQRSCreate
            ? t('AnelmaAudit:1060')
            : '';
}

export function GetOldData(command: ICQRSEventBase) {
    if (HasUpdates(command)) {
        if (
            ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates[0]
                .OldData !== null
        )
            return JSON.stringify(
                ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates[0]
                    .OldData,
                null,
                ' '
            );
        else return 'null';
    } else if (HasDeletions(command)) {
        if (
            ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Deletions[0]
                .Data !== null
        )
            return JSON.stringify(
                ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Deletions[0]
                    .Data,
                null,
                ' '
            );
        else return 'null';
    } else return '';
}

export function GetNewData(command: ICQRSEventBase) {
    if (HasUpdates(command)) {
        if (
            ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates[0]
                .NewData !== null
        )
            return JSON.stringify(
                ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates[0]
                    .NewData,
                null,
                ' '
            );
        else return 'null';
    } else if (HasAdditions(command)) {
        if (
            ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Additions[0]
                .Data
        )
            return JSON.stringify(
                ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Additions[0]
                    .Data,
                null,
                ' '
            );
        else return 'null';
    } else return '';
}

export function HasAdditions(command: ICQRSEventBase) {
    return (
        (command as ICQRSCommandEvent).EventSource &&
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Additions &&
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Additions.length > 0
    );
}

export const AdditionsLength = (command: ICQRSEventBase) => {
    if (HasAdditions(command))
        return ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Additions
            .length;

    return 0;
};

export function HasDeletions(command: ICQRSEventBase) {
    return (
        (command as ICQRSCommandEvent).EventSource &&
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Deletions &&
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Deletions.length > 0
    );
}

export const DeletionsLength = (command: ICQRSEventBase) => {
    if (HasDeletions(command))
        return ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Deletions
            .length;

    return 0;
};

export function HasUpdates(command: ICQRSEventBase) {
    return (
        (command as ICQRSCommandEvent).EventSource &&
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates &&
        ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates.length > 0
    );
}

export const UpdatesLength = (command: ICQRSEventBase) => {
    if (HasUpdates(command))
        return ((command as ICQRSCommandEvent).EventSource as AnelmaUpdateEventSource).Updates
            .length;

    return 0;
};
