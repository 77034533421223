// Interfaces
import { AnelmaCultureType } from '../interfaces/types';

export enum EditableContentType {
    DashboardNews = 24,
    DashboardNewsBovine = 25,
    DashboardNewsPork = 26,
    Feeding = 11,
    FeedingBovine = 12,
    FeedingPork = 13,
    GenericContent = 0, // not used from parameters
    GMFree = 17,
    GMFreeBovine = 18,
    GMFreePork = 19,
    Instructions = 7,
    InstructionsBovine = 8,
    InstructionsPork = 10,
    InstructionsAnelmaInfo = 28,
    Lab = 14,
    LabBovine = 15,
    LabPork = 16,
    MP = 20,
    MPBovine = 5,
    MPPork = 6,
    PublicDashboardBanner = 1,
    PublicDashboardMagazine = 2,
    PublicDashboardNews = 21,
    PublicDashboardNewsBovine = 22,
    PublicDashboardNewsPork = 23,
    PublicMPBovine = 3,
    PublicMPPork = 4,
    PrivacyStatement = 27,
    PriceListBovine = 29, // Hinnastot - nauta 29
    PriceListPig = 30, // Hinnastot - sika 30
}

export interface IEditableContent {
    _uiId: string;
    Comment: string;
    Created?: string;
    Creator?: string;
    Due?: string;
    From?: string;
    Id?: string;
    Modificator?: string;
    Modified?: string;
    Translations: IEditableContentTranslation[];
    Type: EditableContentType;
}

export interface IEditableContentTranslation {
    Content: string;
    ContentId?: string;
    Culture: AnelmaCultureType;
}

export interface IPlainContent {
    Content: string;
}

export interface IEditableContentImage {
    Url: string;
}
