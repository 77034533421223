// Libraries
import { useEffect, useState } from 'react';
import { OutputData } from '@editorjs/editorjs';

// MUI
// Core
// Common
import ViewLoader from '../common/ViewLoader';

// Interfaces
// Store
// API
import api from '../api/contentApi';

// Feature
import { EditableContentType } from './IEditableContent';
import Block from './Block';
import contentUtils from './editor/contentUtils';

interface IProps {
    content?: string;
    displayLoader?: boolean;
    id?: string;
    publicSite: boolean;
    type?: EditableContentType;
}

const RenderContent = (props: IProps) => {
    const [data, setData] = useState<OutputData>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setData(contentUtils.parseOutputData(props.content));
    }, [props.content]);

    useEffect(() => {
        if (props.displayLoader) setLoading(true);
    }, [props.displayLoader]);

    useEffect(() => {
        resolveContent(props.publicSite, props.type, props.id);
    }, [props.id, props.type]);

    const resolveContent = (
        publicSite: boolean,
        type: EditableContentType | undefined,
        id: string | undefined
    ) => {

        if (type) {
            api.getContentByType(publicSite, type).then((_) => {
                if (!_) {
                    setData(undefined);
                    return;
                }
                setData(contentUtils.parseOutputData(_.Entity.Content));
            });
        }
        if (id) {
            api.getContentById(publicSite, id).then((_) => {
                if (!_) {
                    setData(undefined);
                    return;
                }
                setData(contentUtils.parseOutputData(_.Entity.Content));
            });
        }

        if (props.displayLoader) {
            setLoading(false);
        }
    };

    if (loading) return <ViewLoader />;

    return (
        <div className='an-content-container'>
            {data && data.blocks.map((_, idx) => <Block block={_} id={idx.toString()} key={idx.toString()} />)}

            <div style={{ clear: 'both' }}></div>
        </div>
    );
};

export default RenderContent;
