import { IUpdatePayload, NamedField, FieldUpdate } from '../interfaces/IGeneral';
import { IPersonDetails } from '../interfaces/IBusinessEntities';

export default function buildUpdatePayload(
    oldData: IPersonDetails,
    newData: IPersonDetails
): IUpdatePayload<IPersonDetails> {
    const result: IUpdatePayload<IPersonDetails> = {
        Additions: [],
        Deletions: [],
        Entity: oldData,
        Updates: [],
    };
    handlePersonData(oldData, newData, result);
    return result;
}

const addressProps = [
    'City',
    'CountryShortCode',
    'IsDefault',
    'NormalizedAddress',
    'Street',
    'Type',
    'Zip',
];
const phoneNumberProps = ['IsDefault', 'NormalizedNumber', 'Type'];

function handlePersonData(
    oldData: IPersonDetails,
    newData: IPersonDetails,
    result: IUpdatePayload<IPersonDetails>
) {
    addresses(oldData, newData, result);
    aliases(oldData, newData, result);
    emailAddresses(oldData, newData, result);
    firstName(oldData, newData, result);
    languageShortCode(oldData, newData, result);
    lastName(oldData, newData, result);
    phoneNumbers(oldData, newData, result);
}

function addresses(
    oldData: IPersonDetails,
    newData: IPersonDetails,
    result: IUpdatePayload<IPersonDetails>
) {
    handleValueListWithId(
        '_i',
        'Addresses',
        result,
        oldData.Addresses,
        newData.Addresses,
        addressProps
    );
}

function aliases(
    oldData: IPersonDetails,
    newData: IPersonDetails,
    result: IUpdatePayload<IPersonDetails>
) {
    handleValueListWithId('Type', 'Aliases', result, oldData.Aliases, newData.Aliases, ['Value']);
}

function emailAddresses(
    oldData: IPersonDetails,
    newData: IPersonDetails,
    result: IUpdatePayload<IPersonDetails>
) {
    // emailAddresses needs to be handled with Updates
    handleValue(
        'EmailAddresses',
        result,
        oldData.EmailAddresses[0],
        oldData.EmailAddresses[0].Value,
        newData.EmailAddresses[0],
        newData.EmailAddresses[0].Value
    );
}

function firstName(
    oldData: IPersonDetails,
    newData: IPersonDetails,
    result: IUpdatePayload<IPersonDetails>
) {
    handleValue(
        'FirstName',
        result,
        oldData.FirstName,
        oldData.FirstName,
        newData.FirstName,
        newData.FirstName
    );
}

function languageShortCode(
    oldData: IPersonDetails,
    newData: IPersonDetails,
    result: IUpdatePayload<IPersonDetails>
) {
    handleValue(
        'LanguageShortCode',
        result,
        oldData.LanguageShortCode,
        oldData.LanguageShortCode,
        newData.LanguageShortCode,
        newData.LanguageShortCode
    );
}

function lastName(
    oldData: IPersonDetails,
    newData: IPersonDetails,
    result: IUpdatePayload<IPersonDetails>
) {
    handleValue(
        'LastName',
        result,
        oldData.LastName,
        oldData.LastName,
        newData.LastName,
        newData.LastName
    );
}

function phoneNumbers(
    oldData: IPersonDetails,
    newData: IPersonDetails,
    result: IUpdatePayload<IPersonDetails>
) {
    handleValueListWithId(
        '_i',
        'PhoneNumbers',
        result,
        oldData.PhoneNumbers,
        newData.PhoneNumbers,
        phoneNumberProps
    );
}

type ValueType = number | string | null;

function handleValue(
    field: string,
    result: IUpdatePayload<IPersonDetails>,
    oldData: any,
    oldValue: ValueType,
    newData: any,
    newValue: ValueType
) {
    if (
        (oldValue === undefined || oldValue === null) &&
        newValue !== undefined &&
        newValue !== null
    ) {
        result.Additions.push({ Field: field, Data: newData });
    } else if (
        oldValue !== undefined &&
        oldValue !== null &&
        (newValue === undefined || newValue === null)
    ) {
        result.Deletions.push({ Field: field, Data: oldData });
    } else if (oldValue !== newValue) {
        result.Updates.push({ Field: field, OldData: oldData, NewData: newData });
    }
}

function handleValueListWithId(
    idProp: string,
    field: string,
    result: IUpdatePayload<IPersonDetails>,
    oldData: any[],
    newData: any[],
    props: string[]
) {
    const oldIds = oldData.map((o: any) => o[idProp] as string);
    const newIds = newData.map((n: any) => n[idProp] as string);

    const additions = newData
        .filter((n: any) => isAddition(n, oldIds, idProp))
        .map((n) => {
            const addition: NamedField = {
                Data: n,
                Field: field,
            };
            return addition;
        });
    result.Additions.push(...additions);

    const deletions = oldData
        .filter((o: any) => !newIds.includes(o[idProp]))
        .map((o) => {
            const deletion: NamedField = {
                Data: o,
                Field: field,
            };
            return deletion;
        });
    result.Deletions.push(...deletions);

    oldData.forEach((o) => {
        const n = newData.find((n2: any) => n2[idProp] === (o as any)[idProp]);
        if (!n) return;
        if (objectValuesAreEqual(o, n, props)) return;
        const update: FieldUpdate = {
            Field: field,
            NewData: n,
            OldData: o,
        };
        result.Updates.push(update);
    });
}

function isAddition(newItem: any, oldIds: any[], idProp: string) {
    return !newItem[idProp] || !oldIds.includes(newItem[idProp]);
}

function objectValuesAreEqual(oldData: any, newData: any, props: string[]): boolean {
    for (const i in props) {
        if (oldData[props[i]] !== newData[props[i]]) return false;
    }
    return true;
}
