// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import SearchIcon from '@mui/icons-material/Search';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AActionIconButton, ADefaultIconButton, AIconButtonBaseProps } from '../../common/buttons';

export interface ASearchIconButtonProps extends AIconButtonBaseProps {
    type?: 'action' | 'default';
}

export default function ASearchIconButton(props: ASearchIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, type, ...actionButtonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1055');

    if (type === 'action')
        return (
            <AActionIconButton {...actionButtonProps} icon={<SearchIcon />} tooltip={tooltip2} />
        );
    return <ADefaultIconButton {...actionButtonProps} icon={<SearchIcon />} tooltip={tooltip2} />;
}
