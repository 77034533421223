import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import { styled } from '@mui/material';

export interface ALabelProps extends InputLabelProps {}

const Wrapper = styled(InputLabel)(({ theme }) => ({
    color: '#0a4c31',
}));

export default function ALabel(props: ALabelProps) {
    return <Wrapper {...props} />;
}
