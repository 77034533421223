import { GUIDType } from '../interfaces/types';
import dateUtils, { UiFormats, UtcString, LocalMoment } from './utils.date';
import contextUtils, { IFarmContext } from './utils.context';
import { AnelmaEnvironment } from '../interfaces/enums';
import moment from 'moment';
import { IFarmDetails } from '../interfaces/IBusinessEntities';

class Utils {
    private readonly storageKeyPrefix = 'anelma3-';
    private readonly uniqueIds: string[] = [];

    get context() {
        return contextUtils;
    }

    get date() {
        return dateUtils;
    }

    addUniqueIdForArrayItems(array: any[]): void {
        array.forEach((i) => (i._i = this.generateUniqueId()));
    }

    calculateAgeInMonths = (
        startDate: LocalMoment,
        birthDate: LocalMoment,
        valueToUseInDivision: number
    ): number => {
        // Fix bug where c# datetime minvalue is in the birthdate, means that theres actually not value.
        if (birthDate && birthDate.format('YYYY-MM-DD') === '1753-01-01') return 0;

        const age = startDate?.diff(birthDate, 'days', true);

        return age && valueToUseInDivision !== 0 ? age / valueToUseInDivision : 0;
    };

    detectEnvironment(): AnelmaEnvironment {
        switch (process.env.NODE_ENV) {
            case 'development':
                return AnelmaEnvironment.Development;
            case 'test':
                return AnelmaEnvironment.Test;
            case 'production':
                return AnelmaEnvironment.Production;
        }
    }

    // Only supports if the EU identifier is in number format.
    formatEuIdentifier = (
        euNumber: string,
        euCheck: string | null = null,
        prefix: string = 'FI'
    ): string => {
        if (isNaN(Number(euNumber)) || !euNumber || euNumber?.length === 0) return euNumber;

        let eu: string = '',
            check: string = '';

        if (euNumber.length > 12) {
            eu = euNumber.substring(0, 12);
            check = euNumber.substring(12, 13);
        } else if (euNumber.length <= 12) {
            check = euCheck ?? euNumber.substring(euNumber.length - 1, euNumber.length);
            const zeros = '0'.repeat(12 - euNumber.length + 1);

            eu = zeros.concat(euNumber.substring(0, euNumber.length - 1));
        }

        return `${prefix}${eu}-${euCheck ?? check}`;
    };

    generateUniqueId(): string {
        const uniqueId = Math.round(new Date().valueOf() * Math.random());
        let id = `${this.getUiGeneratedString()}${uniqueId}`;
        if (this.uniqueIds.includes(id)) id = this.generateUniqueId();
        else this.uniqueIds.push(id);
        return id;
    }

    getFromLocalStorage<T>(key: string): T | null {
        const strValue = localStorage.getItem(this.storageKey(key));
        if (strValue === null) return null;
        return strValue !== 'undefined' ? JSON.parse(strValue) : undefined;
    }

    getFromSessionStorage<T>(key: string): T | null {
        const strValue = sessionStorage.getItem(this.storageKey(key));
        if (strValue === null) return null;
        return strValue !== 'undefined' ? JSON.parse(strValue) : undefined;
    }

    getUiGeneratedString(): string {
        return 'ui-generated-id-';
    }

    isGuidEmpty(value: GUIDType | undefined | null): boolean {
        return (
            value == undefined || value === '00000000-0000-0000-0000-000000000000' || value === ''
        );
    }

    /**
     * Provide details for farm as parameter to check in case farm is inactivated.
     * @param farmDetails farm's detailed information
     */
    isFarmInactivated(farmDetails: IFarmDetails): boolean {
        return farmDetails.Inactivated ? true : false;
    }

    removeFromLocalStorage(key: string): void {
        localStorage.removeItem(this.storageKey(key));
    }

    removeFromSessionStorage(key: string): void {
        sessionStorage.removeItem(this.storageKey(key));
    }

    setToLocalStorage(key: string, value: any): void {
        const strValue = value !== undefined ? JSON.stringify(value) : 'undefined';
        localStorage.setItem(this.storageKey(key), strValue);
    }

    setToSessionStorage(key: string, value: any): void {
        const strValue = value !== undefined ? JSON.stringify(value) : 'undefined';
        sessionStorage.setItem(this.storageKey(key), strValue);
    }

    private storageKey(key: string): string {
        return `${this.storageKeyPrefix}${key}`;
    }
}

export default new Utils();

export type { UiFormats, UtcString, LocalMoment, IFarmContext };
