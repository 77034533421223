interface FormValidatorState {
    id: string;
    onCheck?: FormValidatorOnCheck;
    valid: boolean;
}

interface FormValidatorOnCheck {
    (valid: boolean): void;
}

export interface IFormValidator {
    readonly invalid: boolean;
    readonly valid: boolean;
    removeState(id: string): void;
    setState(id: string, valid: boolean, onCheck?: FormValidatorOnCheck): void;
}

export default class FormValidator implements IFormValidator {
    private readonly states: FormValidatorState[] = [];

    constructor(private readonly debug?: boolean) {}

    get invalid(): boolean {
        this.onCheck();
        const result = !!this.states.find((i) => !i.valid);
        if (this.debug)
            console.log(
                'Form is invalid:',
                result,
                this.states,
                '\nValid:',
                this.states.filter((s) => s.valid).map((s) => s.id),
                '\nInvalid:',
                this.states.filter((s) => !s.valid).map((s) => s.id)
            );
        return result;
    }

    get valid(): boolean {
        this.onCheck();
        const result = !this.states.find((i) => !i.valid);
        if (this.debug)
            console.log(
                'Form is valid:',
                result,
                this.states,
                '\nValid:',
                this.states.filter((s) => s.valid).map((s) => s.id),
                '\nInvalid:',
                this.states.filter((s) => !s.valid).map((s) => s.id)
            );
        return result;
    }

    private getState(id: string): FormValidatorState | null {
        const state = this.states.find((i) => i.id === id);
        return state || null;
    }

    private initState(id: string): FormValidatorState {
        const state = this.getState(id);
        if (state) return state;
        const newState: FormValidatorState = {
            id,
            valid: true,
        };
        this.states.push(newState);
        return newState;
    }

    private onCheck(): void {
        this.states.forEach((i) => {
            if (i.onCheck) i.onCheck(i.valid);
        });
    }

    removeState(id: string): void {
        const index = this.states.findIndex((s) => s.id === id);
        if (this.debug) console.log('Remove state:', id, index !== -1);
        if (index === -1) return;
        this.states.splice(index, 1);
    }

    setState(id: string, valid: boolean, onCheck?: FormValidatorOnCheck): void {
        if (this.debug) console.log('Set validation state:', id, valid, !!onCheck);
        let state = this.getState(id);
        if (!state) {
            this.initState(id);
            state = this.getState(id);
        }
        if (state) {
            state.onCheck = onCheck;
            state.valid = valid;
        }
    }
}
