import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import auth from '../../core/authorization';
import {
    ResourceTextApplication,
    paramRepository,
    ParameterValues,
    ParameterValue,
} from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogContentProps,
    ADialogTitle,
} from '../../common/dialog';
import {
    ADropdown,
    AInputItem,
    AMultiselect,
    ATextInput,
    ATextarea,
    ALabel,
} from '../../common/inputs';
import { AAddButton } from '../../common/buttons';
import AttachmentDropzone from '../../common/AttachmentDropzone';
import { Context } from '../../common/contextSelector/ContextSelector';
import { ICustomRenderOption } from '../../common/inputs/AMultiselect';

// Interfaces
import { IFarmDetails, IPersonSummary } from '../../interfaces/IBusinessEntities';
import { INewConversation, INewConversationResponse } from '../../interfaces/ICommunication';
import { GUIDType } from '../../interfaces/types';
import config from '../../documents/addDialog/documentAddDialogConfigs';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import api from '../../api/communicationApi';
import companyApi from '../../api/companyApi';

// Messaging
import {
    capitalizeFirstLetter,
    composeProducerRecipientOptions,
    propContactPersonsAreDefined,
    propSnellmanContactPersonsDefined,
} from '../general/helpers/helperFunctions';

// Communication feature
import CommunicationInputContainer from '../general/components/CommunicationInputContainer';
import IContactPersonRecipient from '../general/interfaces/IContactPersonRecipient';
import SearchRecipientsDialog from '../general/components/SearchRecipientsDialog';
import Recipient from '../general/components/Recipient';

export interface ICreateConversationDialogProps {
    handleConversationResponseChange: (response: INewConversationResponse | null) => void;
    onClose: () => void;
    personCompanyContactPersons: IContactPersonRecipient[];
    recipients: IPersonSummary[];
    snellmanContactPersons: IContactPersonRecipient[];
    validator: IFormValidator;
}

export default function CreateConversationDialog(props: ICreateConversationDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
        'AnelmaLayout',
        'AnelmaPerson',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const renderOption: ICustomRenderOption = { render: true, feature: 'Messaging' };
    const dialogConfig = config('multi');

    const userData = useAppSelector((state) => state.userData.data);
    const context = useAppSelector((state) => state.context);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recipientsFieldVisible, setRecipientsFieldVisible] = useState<boolean>(true);
    const [searchRecipientsDialogVisible, setSearchRecipientsDialogVisible] =
        useState<boolean>(false);
    const [topicParameterCode, setTopicParameterCode] = useState<number>(-1);
    const [recipient, setRecipient] = useState<GUIDType[]>([]);
    const [recipients, setRecipients] = useState<Set<string>>(new Set());
    const [title, setTitle] = useState<string>('');
    const [body, setBody] = useState<string>('');
    const [recipientOptions, setRecipientOptions] = useState<AInputItem<string>[]>([]);
    const [producerRecipientOptions, setProducerRecipientOptions] = useState<
        IContactPersonRecipient[]
    >([]);
    const [attachments, setAttachments] = useState<File[]>([]);
    const [communicationParameters, setCommunicationParameters] = useState<ParameterValues>([]);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();

    useEffect(() => {
        paramRepository.load(['AnelmaCommunication']).then(() => {
            setCommunicationParameters(
                paramRepository.resource('AnelmaCommunication', 'CommunicationTopic')
            );
        });

        prepareInitialRecipients();
    }, []);

    useEffect(() => {
        if (context.data.currentContext) initializeFarmDetails(context.data.currentContext);
        else setFarmDetails(undefined);
    }, [context]);

    const initializeFarmDetails = (context: Context) => {
        setIsLoading(true);
        const companyByContext = companyApi
            .getCompanyByContext(context.context)
            .then((response) => {
                setFarmDetails(response?.Entity as IFarmDetails);
            });
        Promise.all([companyByContext]).then(() => setIsLoading(false));
    };

    const handleTopicChange = (value: number) => {
        {
            communicationParameters.forEach((element) => {
                if ((element.code as unknown as number) === value) {
                    setTopicParameterCode(element.code as unknown as number);
                }
            });
        }
    };

    const handleOpenSearchRecipientsDialog = () => {
        setSearchRecipientsDialogVisible(true);
    };

    const handleCloseSearchRecipientsDialog = () => {
        setSearchRecipientsDialogVisible(false);
    };

    const handleRecipientChange = (value: string[]) => {
        setRecipient(value);
    };

    const handleSaveRecipientsFromDialog = (params: string[]) => {
        setSearchRecipientsDialogVisible(false);
        setRecipientsFieldVisible(false);

        params.map((value) => setRecipients((previousState) => new Set(previousState).add(value)));
    };

    const handleSnackbarMessage = (
        message: string,
        variant: 'success' | 'error' | 'warning' | 'info'
    ) => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const composeConversation = (): INewConversation => {
        return {
            SenderId: userData.Id || '',
            RecipientList: recipientSaveHandler(),
            Topic: String(topicParameterCode),
            Title: title,
            Body: body,
            Attachments: attachments?.map((attachment) => {
                return { File: attachment };
            }),
        };
    };

    const recipientSaveHandler = () => {
        if (recipients.size !== 0) return Array.from(recipients.values());
        else return recipient;
    };

    const handleCreateConversation = () => {
        setIsLoading(true);
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setIsLoading(false);
            return;
        }

        api.createConversation(composeConversation(), farmDetails?.Id as string)
            .then((response) => {
                if (!response) {
                    handleSnackbarMessage(`${t('AnelmaCommunication:1059')}, ${response}`, 'error');
                    setIsLoading(false);
                    return;
                }

                handleSnackbarMessage(t('AnelmaCommunication:1106'), 'success');
                setIsLoading(false);
                props.onClose();
                props.handleConversationResponseChange(response!);
            })
            .catch((error) => {
                handleSnackbarMessage(`${t('AnelmaCommunication:1059')}, ${error}`, 'error');
                setIsLoading(false);
            });
    };

    const prepareInitialRecipients = () => {
        if (props.recipients !== undefined) {
            setIsLoading(true);
            setRecipientOptions(
                props.recipients
                    .filter((value) => {
                        if (value.Name !== null && value.Id !== null) return value;
                    })
                    .map((p) => mergeRight(p, { text: p.Name, value: p.Id, email: p.EmailAddress }))
            );
            setIsLoading(false);
        }
        /* Check if both Snellman Contact Persons and producer's own company persons have values in the respected array and map them to producer recipient options
               if not then use Snellman Contact Persons only */
        if (
            propContactPersonsAreDefined(
                props.snellmanContactPersons,
                props.personCompanyContactPersons
            )
        ) {
            const composedProducerRecipientOptions = [
                ...composeProducerRecipientOptions(props.snellmanContactPersons),
            ];
            composedProducerRecipientOptions.push(
                ...composeProducerRecipientOptions(props.personCompanyContactPersons)
            );
            setProducerRecipientOptions(
                composedProducerRecipientOptions.filter(
                    (val, idx, self) => self.findIndex((newVal) => newVal.Id === val.Id) === idx
                )
            );
        } else if (propSnellmanContactPersonsDefined(props.snellmanContactPersons))
            setProducerRecipientOptions([
                ...composeProducerRecipientOptions(props.snellmanContactPersons),
            ]);
    };

    const checkCreateBtnVisibility = (): boolean => {
        if (recipients.size !== 0) return false;
        else if (recipient.length !== 0) return false;
        return true;
    };

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: handleCreateConversation,
                type: 'send',
                disabled: checkCreateBtnVisibility(),
            },
        ],
    };

    const dialogContentProps: ADialogContentProps = {
        size: 'md',
        isLoading: isLoading,
    };

    return (
        <>
            <ADialog open onClose={() => props.onClose()}>
                <ADialogTitle>{t('AnelmaCommunication:1000')}</ADialogTitle>

                <ADialogContent {...dialogContentProps}>
                    <Grid container direction='column' alignItems='stretch'>
                        <Grid item sm={12}>
                            <ADropdown
                                id='topic-type'
                                label={t('AnelmaCommunication:1001')}
                                onChange={(v) => handleTopicChange(v as number)}
                                value={topicParameterCode === -1 ? '' : topicParameterCode}
                                items={communicationParameters
                                    .map((parameter: ParameterValue) => ({
                                        text: parameter.text,
                                        value: parameter.code,
                                    }))
                                    .filter((option) => option.value !== String(11))} // 11 equals feedback
                                validator={props.validator}
                                required
                            ></ADropdown>
                        </Grid>
                    </Grid>

                    <Grid container direction='row' alignItems='stretch'>
                        {auth.canAddAnyEntityToConversation ? (
                            <>
                                <Grid item sm={12}>
                                    {recipientsFieldVisible ? (
                                        <AMultiselect
                                            label={capitalizeFirstLetter(
                                                t('AnelmaCommunication:1002')
                                            )}
                                            getOptionLabel={(o) => o.text}
                                            id='recipient'
                                            items={recipientOptions}
                                            keyProp={'value'}
                                            onChange={(v) => handleRecipientChange(v as string[])}
                                            validator={props.validator}
                                            value={recipient}
                                            customRenderOption={renderOption}
                                        />
                                    ) : null ? (
                                        recipients.size > 0
                                    ) : (
                                        <>
                                            {
                                                new Set(
                                                    Array.from(recipients).map((key: string) => {
                                                        return (
                                                            <Recipient
                                                                key={key}
                                                                styleClassName={'create'}
                                                                personGuid={key}
                                                            />
                                                        );
                                                    })
                                                )
                                            }
                                        </>
                                    )}
                                </Grid>

                                <CommunicationInputContainer>
                                    <Grid item sm={12} style={{ textAlign: 'right' }}>
                                        <AAddButton
                                            onClick={() => handleOpenSearchRecipientsDialog()}
                                            type='default'
                                            children={t('AnelmaCommunication:1068')}
                                        ></AAddButton>
                                    </Grid>
                                </CommunicationInputContainer>

                                {searchRecipientsDialogVisible && (
                                    <SearchRecipientsDialog
                                        onClose={() => handleCloseSearchRecipientsDialog()}
                                        snellmanContactPersons={props.snellmanContactPersons}
                                        handleSaveRecipientsFromDialog={
                                            handleSaveRecipientsFromDialog
                                        }
                                        validator={props.validator}
                                    ></SearchRecipientsDialog>
                                )}
                            </>
                        ) : auth.canAddRestrictedEntityToConversation ? (
                            <Grid item sm={12}>
                                <AMultiselect
                                    label={capitalizeFirstLetter(t('AnelmaCommunication:1002'))}
                                    getOptionLabel={(o) => o.text}
                                    id='producer-recipient'
                                    items={producerRecipientOptions}
                                    keyProp={'value'}
                                    onChange={(v) => handleRecipientChange(v as string[])}
                                    required
                                    validator={props.validator}
                                    value={recipient}
                                    customRenderOption={renderOption}
                                />
                            </Grid>
                        ) : null}
                    </Grid>

                    <Grid container direction='column'>
                        <Grid item sm={12}>
                            <ATextInput
                                id='title'
                                label={t('AnelmaCommunication:1008')}
                                onChange={(v) => setTitle(v)}
                                value={title}
                                lengthBoundaryName={'AnelmaCommunication:MessageTitleLength'}
                                required
                                validator={props.validator}
                            ></ATextInput>
                        </Grid>
                        <Grid container direction='row'>
                            <Grid
                                item
                                sm={12}
                                style={{ paddingLeft: '40px', paddingRight: '40px' }}
                            >
                                <ALabel>{t('AnelmaCommunication:1115')}</ALabel>

                                <AttachmentDropzone
                                    filesLimit={dialogConfig.filesLimit}
                                    maxFileSize={dialogConfig.maxFileSize}
                                    Text={t('AnelmaCommunication:1116')}
                                    onAttachmentsChanged={(files) => {
                                        setAttachments(files);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <ATextarea
                                id='body'
                                label={t('AnelmaCommunication:1069')}
                                onChange={(v) => setBody(v)}
                                value={body}
                                lengthBoundaryName={'AnelmaCommunication:MessageBodyLength'}
                                required
                                validator={props.validator}
                            ></ATextarea>
                        </Grid>
                    </Grid>
                </ADialogContent>

                <ADialogActions buttons={footerActionButtons} />
            </ADialog>
        </>
    );
}
