// Core
import auth from '../../../../core/authorization';

// Feature
import { MainMenuItemType, IMainMenuItem } from './IMainMenu';
import MainMenuItem from './MainMenuItem';
import './mainMenu.scss';

export interface MainMenuProps {
    items: MainMenuItemType[];
    openMenuItem: number | null;
    openSubMenu: (index: number | null, touch: boolean) => void;
}

export default function MainMenu(props: MainMenuProps) {
    const constructMenu = (item: MainMenuItemType): JSX.Element => {
        if (item.textCode === 1035) {
            if (
                !auth.canViewEconomyAccounting &&
                !auth.canViewEconomyInvoice &&
                !auth.canViewEconomyPriceList &&
                !auth.canViewEconomyTaxYearSummary
            )
                return <></>;
            return <MainMenuItem closeSubMenu={() => props.openSubMenu(null, false)} item={item} />;
        }
        return <MainMenuItem closeSubMenu={() => props.openSubMenu(null, false)} item={item} />;
    };

    return (
        <div className='an-main-menu'>
            {props.items.map((item, index) => {
                const hasSubItems = !!(item as IMainMenuItem).itemGroups?.length;

                return (
                    <div
                        className={`an-main-menu-item ${
                            hasSubItems && index === props.openMenuItem
                                ? 'an-main-menu-item-open'
                                : ''
                        }`}
                        key={index}
                        onMouseEnter={() => props.openSubMenu(index, false)}
                        onTouchStart={() => props.openSubMenu(index, true)}
                    >
                        {constructMenu(item)}
                    </div>
                );
            })}
        </div>
    );
}
