// MUI
import { Card, CardContent, Typography } from '@mui/material';

// Common
import UserAvatar from '../common/UserAvatar';

// Feature
import { PublicContactPerson, ParamState } from './interfaces';
import ContactPersonLabels from './ContactPersonLabels';
import { reverseName } from './helpers';
import './ContactPersonBadge.scss';

interface ContactPersonBadgeProps {
    data: PublicContactPerson;
    responsibilities: ParamState[];
}

export default function ContactPersonBadge(props: ContactPersonBadgeProps) {
    return (
        <Card className={'an-badge'}>
            <UserAvatar personId={props.data.PersonGUID} />

            <CardContent className='an-badge-content'>
                <Typography className={'an-badge-name'} variant='h5'>
                    {reverseName(props.data.Name)}
                </Typography>

                <Typography className={'an-badge-job'} variant='h6'>
                    {props.data.JobTitleFI}
                </Typography>

                <Typography className={'an-badge-phone'} variant='h6' color={'textPrimary'}>
                    {props.data.PhoneNumber}
                </Typography>

                <Typography className={'an-badge-email'} variant='h6' color={'textPrimary'}>
                    {props.data.EmailAddress}
                </Typography>
            </CardContent>

            <ContactPersonLabels
                data={props.data}
                responsibilities={props.responsibilities}
                type='badge'
            />
        </Card>
    );
}
