// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

// Core
import { paramRepository, ParameterValues, ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../common/dialog';
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';
import { TextsWithTooltip, TextWithTooltip } from '../../common/typography';

// Interfaces
import { IPredefinedSearchFarmResult } from '../../interfaces/ISearchParameters';
import { IContactPersonRow, IProductionLine } from '../../interfaces/IBusinessEntities';
import { ProductionLineType } from '../../interfaces/enums';

// Store
// API
// Feature

export interface CompaniesGroupDialogProps {
    data: IPredefinedSearchFarmResult[];
    onClose: () => void;
    onSelect: (item: IContactPersonRow[]) => void;
    validator: IFormValidator;
    groupName: string;
    searchGuid: string;
    usePersonsConnectedToTheFarm: boolean;
}

export default function CompaniesGroupDialog(props: CompaniesGroupDialogProps) {
    const { data, validator } = props;
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCompany',
        'AnelmaCommunication',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [
            {
                type: 'ok',
                onClick: () => {
                    props.onSelect(receiverInfos);
                    props.onClose();
                },
            },
        ],
    };

    const personColumns: AGridColumns = [];
    const farmColumns: AGridColumns = [];

    if (props.usePersonsConnectedToTheFarm) {
        personColumns.push(gridColumns.companyName(t('AnelmaCommunication:1070'), 'CompanyName'));
        personColumns.push(gridColumns.personName(t('AnelmaCommunication:1071'), 'Name'));
        personColumns.push({
            field: 'Roles',
            headerName: t('AnelmaCommunication:1072'),
            renderCell: (params) => {
                const contactPerson = params.row as unknown as IContactPersonRow;
                return <>{getRoleNamesColumn(contactPerson)}</>;
            },
            valueGetter: (params) => {
                const contactPerson = params.row as unknown as IContactPersonRow;
                return getRoleNames(contactPerson)?.join(', ');
            },
            width: 200,
        });
        personColumns.push(gridColumns.companyName(t('AnelmaCommunication:1157'), 'PhoneNumber'));
        personColumns.push({
            field: 'PhoneNumberType',
            headerName: t('AnelmaCommunication:1151'),
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            width: 250,
        });
    } else {
        farmColumns.push(
            gridColumns.producerNumber(t('AnelmaCommunication:1078'), 'ProducerId', 150)
        );
        farmColumns.push(gridColumns.companyName(t('AnelmaCommunication:1070'), 'CompanyName'));
        farmColumns.push(gridColumns.companyName(t('AnelmaCommunication:1157'), 'PhoneNumber'));
        /* Not needed yet
        farmColumns.push(
            {
                field: 'PhoneNumberType',
                headerName: t('AnelmaCommunication:1096'),
                renderCell: (params) => <TextWithTooltip text={params.value as string} />,
                width: 250
            });
        */
        farmColumns.push({
            field: 'FarmingTypes',
            headerName: t('AnelmaCommunication:1048'),

            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            width: 200,
        });
    }

    const [receiverInfos, setReceiverInfos] = useState<IContactPersonRow[]>([]);

    const phoneNumberTypePersonArr = paramRepository.resource(
        'AnelmaGeneral',
        'PhoneNumberTypePerson'
    );
    const farmingTypeBovineArr = paramRepository.resource('AnelmaCompany', 'FarmingTypeBovine');
    const farmingTypePigArr = paramRepository.resource('AnelmaCompany', 'FarmingTypePig');

    const getFarmingTypes = (productionLines: IProductionLine[]) => {
        const returnValue: string[] = [];

        productionLines.forEach((p) => {
            if (p.Type.toString() == ProductionLineType.Bovine.toString()) {
                p.FarmingTypes.forEach((q) => {
                    const farmingTypeText = farmingTypeBovineArr.find(
                        (x) => parseInt(x.code) == parseInt(q.toString())
                    )?.text;
                    if (farmingTypeText) returnValue.push(farmingTypeText);
                });
            } else if (p.Type.toString() == ProductionLineType.Pork.toString()) {
                p.FarmingTypes.forEach((q) => {
                    const farmingTypeText = farmingTypePigArr.find(
                        (x) => parseInt(x.code) == parseInt(q.toString())
                    )?.text;
                    if (farmingTypeText) returnValue.push(farmingTypeText);
                });
            }
        });
        return returnValue;
    };

    const personRows: IContactPersonRow[] = data
        .reduce((res: IContactPersonRow[], company) => {
            var companyContracts = company.ContactPersons.reduce(
                (contacts: IContactPersonRow[], p) => {
                    p.PhoneNumbers?.forEach((phoneNumber) => {
                        contacts.push({
                            id: p.Id + phoneNumber.NormalizedNumber,
                            ContactPersonId: p.Id,
                            Name: p.Name,
                            ProducerId: company.ProducerId,
                            CompanyName: company.Name,
                            FarmId: p.OwnerId,
                            PhoneNumber: phoneNumber.NormalizedNumber,
                            PhoneNumberType: phoneNumberTypePersonArr.find(
                                (x) => x.code == phoneNumber.Type.toString()
                            )?.text,
                            Roles: p.Roles,
                            RowId: null,
                            usePersonsConnectedToTheFarm: true,
                            SearchId: props.searchGuid,
                            CommunicationGroupName: props.groupName,
                        });
                    });

                    return contacts;
                },
                []
            );

            return res.concat(companyContracts);
        }, [])
        .map((i, index) => {
            i.RowId = index;
            return i;
        });

    const farmRows: IContactPersonRow[] = data
        .reduce((res: IContactPersonRow[], company) => {
            res.push({
                id: company.FarmGuid,
                Name: company.Name,
                ProducerId: company.ProducerId,
                CompanyName: company.Name,
                FarmId: company.FarmGuid,
                PhoneNumber: company.CompanyPhoneNumber as string,
                PhoneNumberType: '',
                FarmingTypes: getFarmingTypes(company.ProductionLines),
                RowId: null,
                usePersonsConnectedToTheFarm: false,
                SearchId: props.searchGuid,
                CommunicationGroupName: props.groupName,
            });

            return res;
        }, [])
        .map((i, index) => {
            i.RowId = index;
            return i;
        });

    const [companyRoles, setCompanyRoles] = useState<ParameterValues>([]);

    const getRoleNames = (contactPerson: IContactPersonRow) => {
        return contactPerson.Roles?.map((i) => {
            return companyRoles.find((role) => role.code === `${i.Type}`)?.text || `${i.Type}`;
        });
    };

    const getRoleNamesColumn = (contactPerson: IContactPersonRow) => {
        const texts = getRoleNames(contactPerson);
        return (
            <>
                <TextsWithTooltip texts={texts as string[]} />
            </>
        );
    };

    useEffect(() => {
        paramRepository.load(['AnelmaGeneral']).then(() => {
            let params: ParameterValues = [];
            params = paramRepository.resource('AnelmaGeneral', 'FarmTitles');
            setCompanyRoles(params);
            setIsLoading(false);
        });
    }, []);

    const onRowSelectHandler = (selectedItems: IContactPersonRow[]) => {
        setReceiverInfos(selectedItems);
    };

    return (
        <ADialog open={true} onClose={() => props.onClose()}>
            <ADialogTitle>{props.groupName}</ADialogTitle>
            <ADialogContent
                isLoading={isLoading}
                size={props.usePersonsConnectedToTheFarm ? 'xl' : 'lg'}
            >
                {!isLoading ? (
                    <ADataGrid
                        columns={props.usePersonsConnectedToTheFarm ? personColumns : farmColumns}
                        rows={props.usePersonsConnectedToTheFarm ? personRows : farmRows}
                        onSelectionModelChange={(item: GridRowSelectionModel) => {
                            const selectedID = new Set(item);
                            const selectedRowData = props.usePersonsConnectedToTheFarm
                                ? personRows.filter((row) => selectedID.has(row.id))
                                : farmRows.filter((row) => selectedID.has(row.id));
                            onRowSelectHandler(selectedRowData);
                        }}
                        enableFiltering
                    />
                ) : undefined}
            </ADialogContent>
            <ADialogActions buttons={footerButtons} isLoading={isLoading} />
        </ADialog>
    );
}
