import { AnelmaReportingBtnGroupNameEnum, AnelmaReportTypeEnum } from '../interfaces/enums';
import ReportView from './ReportView';

export interface IProductionReportProps {
    endQlikSession: (eligible: boolean) => void;
}

export default function ProductionReport(props: IProductionReportProps) {
    return (       
        <ReportView
            title={1048}
            btnGroupParameterName={AnelmaReportingBtnGroupNameEnum.ProductionReport}
            type={AnelmaReportTypeEnum.ProductionReport}
            endQlikSession={props.endQlikSession}
        />
      
    );
}
