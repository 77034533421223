// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import {
    Container,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
} from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../core/resources';

//Interface
import {
    IVariableTypeEnum,
    IVariableGroup,
    IVariablesList,
    IItem,
    IBovineVariablesPayload,
} from '../interfaces/IBovineVariables';
import { IFormValidator } from '../core/FormValidator';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../common/dialog';
import { ANumberInput } from '../common/inputs';

//Feature
import BovineVariablesApi from '../api/bovineVariablesApi';
import { GUIDType } from '../interfaces/types';
import {
    calculateOtherSlaughterVariables
    , calculateOtherPurchaseVariables
} from './bovineVariablesCalculator';
import { getBoundaryValue } from './helpers/boundariesHelper';

export interface SlaughterVariablesDialogProps {
    farmId: GUIDType;
    variables: IVariableGroup | null;
    variableType: IVariableTypeEnum;
    validator: IFormValidator;
    onClose: () => void;
}

export default function SlaughterVariablesDialog(props: SlaughterVariablesDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaVariables', 'AnelmaGeneral']);
    const [brokerVariableParams, setBrokerVariableParams] = useState<ParameterValues>([]);
    const [slaughterVariableParams, setSlaughterVariableParams] = useState<ParameterValues>([]);

    const { enqueueSnackbar } = useSnackbar();
    const [saveButtonIsActive, setSaveButtonIsActive] = useState<boolean>(true);

    const defaultItems: IItem[] = [
        {
            VariableGroupBovine: 1,
            VariableValue: 0
        },
        {
            VariableGroupBovine: 2,
            VariableValue: 0
        }
    ]

    const defaultVariables: IVariablesList[] = [
        {
            Variable: 1,
            // Item: [...defaultItems] didn't work as a proper copy, so using this JSON workaround
            Item: JSON.parse(JSON.stringify(defaultItems))
        },
        {
            Variable: 2,
            Item: JSON.parse(JSON.stringify(defaultItems))
        },
        {
            Variable: 3,
            Item: JSON.parse(JSON.stringify(defaultItems))
        },
        {
            Variable: 4,
            Item: JSON.parse(JSON.stringify(defaultItems))
        },
        {
            Variable: 5,
            Item: JSON.parse(JSON.stringify(defaultItems))
        }
    ];

    const [variables, setVariables] = useState<IVariablesList[]>([]);

    useEffect(() => {
        if (props.variables)
            setVariables(props.variables.VariablesList);
        else
            setVariables(defaultVariables);

        paramRepository.load(['AnelmaVariables']).then(() => {
            setSlaughterVariableParams(
                paramRepository.resource('AnelmaVariables', 'SlaughterVariable', 'default')
            );
            setBrokerVariableParams(
                paramRepository.resource('AnelmaVariables', 'BrokerVariable', 'default')
            );
        });
    }, [])

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => {
                    props.onClose();
                },
            },
        ],
        right: [
            {
                type: 'save',
                onClick: () => {
                    setSaveButtonIsActive(false);
                    handleBeforeSave(variables);
                },
                disabled: !saveButtonIsActive,
            },
        ],
    };

    const getVariableParams = (params: number, groupFilter: number): any => {
        if (groupFilter === IVariableTypeEnum.Slaughter) {
            let slaughterParams = slaughterVariableParams.find(
                (p) => Number(p.code) === Number(params)
            );
            return slaughterParams?.text;
        } else if (groupFilter === IVariableTypeEnum.Purchase) {
            let brokerParams = brokerVariableParams.find((p) => Number(p.code) === Number(params));
            return brokerParams?.text;
        }
    };

    const handleBeforeSave = (variables: IVariablesList[]) => {
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setSaveButtonIsActive(true);
            return;
        }

        let filterCreateBovineData = [...variables.filter((v) => v.Variable < 4)];

        if (filterCreateBovineData.length === 3) {
            onSave({
                FarmGUID: props.farmId,
                VariableType: props.variableType,
                VariablesList: filterCreateBovineData
            } as IBovineVariablesPayload);
        }
    };

    const onSave = (filterCreateBovineData: IBovineVariablesPayload) => {
        BovineVariablesApi.createOrUpdateBovineVariables(filterCreateBovineData).then(
            (response) => {
                if (response.Results.HttpStatus === 202) {
                    enqueueSnackbar(t('AnelmaGeneral:1018'), {
                        variant: 'success',
                    });
                } else {
                    enqueueSnackbar(t('AnelmaGeneral:1030'), { variant: 'error' });
                }
                props.onClose();
            },
            (fail) => {
                enqueueSnackbar(t('AnelmaGeneral:1019'), { variant: 'error' });
                props.onClose();
            }
        );
    };

    const handleInputChange = (
        variable: number,
        variableGroupBovine: number,
        variableValue: number
    ) => {
        let temp = variables.filter(v => v.Variable < 4);
        let variableIndex = temp.findIndex(v => v.Variable === variable);

        if (variableIndex != -1) {
            let variableGroupBovineIndex = temp[variableIndex].Item.findIndex(i => i.VariableGroupBovine === variableGroupBovine);

            if (variableGroupBovineIndex != -1)
                temp[variableIndex].Item[variableGroupBovineIndex].VariableValue = variableValue;
        }

        switch (props.variableType) {
            case IVariableTypeEnum.Slaughter:
                setVariables(calculateOtherSlaughterVariables(temp));
                break;
            case IVariableTypeEnum.Purchase:
                setVariables(calculateOtherPurchaseVariables(temp));
                break;
            default: return null;
        }
    };

    const getEditTable = (editBovineData: IVariablesList[]): JSX.Element => {
        return (
            <>
                {editBovineData.map((row, index) => {
                    return (
                        <TableRow key={`variables-table-row-${index}`}>
                            <TableCell padding='none' align='left'>
                                {getVariableParams(row.Variable as number, props.variableType)}
                            </TableCell>
                            {row.Item.map((item, idx) => {
                                return (
                                    <TableCell padding='none' key={`variables-table-row-${index}-${idx}`}>
                                        <ANumberInput
                                            id={`variables-table-row-${index}-value-${idx}`}
                                            label={''}
                                            mode='int'
                                            disabled={
                                                row.Variable === 4 || row.Variable === 5
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => {
                                                handleInputChange(
                                                    row.Variable,
                                                    item.VariableGroupBovine as number,
                                                    e as number
                                                );
                                            }}
                                            value={item.VariableValue}
                                            validator={props.validator}
                                            boundaryName={getBoundaryValue(props.variableType, row.Variable)}
                                            required
                                        />
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <ADialog open={true} onClose={() => props.onClose()}>
                {props.variableType === IVariableTypeEnum.Slaughter ? (
                    <ADialogTitle>{t('AnelmaVariables:1000')}</ADialogTitle>
                ) : (
                    <ADialogTitle>{t('AnelmaVariables:1010')}</ADialogTitle>
                )}
                <ADialogContent isLoading={false} size='md'>
                    <Container id={'changeBovineVariablesDialogTable'}>
                        <TableContainer
                            component={Paper}
                            style={{ maxWidth: '600px' }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'> </TableCell>
                                        <TableCell align='center'>
                                            {t('AnelmaVariables:1003')}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {t('AnelmaVariables:1004')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getEditTable(variables)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </ADialogContent>
                <ADialogActions buttons={footerActionButtons}></ADialogActions>
            </ADialog>
        </>
    );
}
