// MUI
import { DialogContent, styled } from '@mui/material';

// Dialog
import './ADialogLoader';
import './dialogContent.scss';
import ADialogLoader from './ADialogLoader';

export interface ADialogContentProps {
    children?: React.ReactNode;
    className?: string;
    isLoading?: boolean;
    size: DialogSize;
}

type DialogSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const Wrapper = styled(DialogContent)(({ theme }) => ({
    padding: '20px 0',
}));

export default function ADialogContent(props: ADialogContentProps) {
    let { children, isLoading, size, ...other } = props;
    if (!size) size = 'md';
    other.className = `${other.className || ''} an-dialog-content-${size}`;

    return (
        <Wrapper {...other}>
            {children}
            {isLoading ? <ADialogLoader /> : undefined}
        </Wrapper>
    );
}
