import { ProductionPlanAnimalType } from "../../../../interfaces/enums";
import { 
    IPlannedAnimal,
    IProductionPlan,
    IProductionPlanSlaughterSales 
} from "../../../../interfaces/IProductionPlan";
import { PlannedAnimalMapperBase } from "./PlannedAnimalMapperBase";

class Mapper extends PlannedAnimalMapperBase {

    ToDataGrid(a: IPlannedAnimal[]): IProductionPlanSlaughterSales {
        return {
            Cows: a.find(x => x.Type === ProductionPlanAnimalType.BeefCow)?.Amount || 0,
            Bulls: a.find(x => x.Type === ProductionPlanAnimalType.BeefBull)?.Amount || 0,
            Heifers: a.find(x => x.Type === ProductionPlanAnimalType.BeefHeifer)?.Amount || 0
        }
    }

    ToPlan(
        plan: IProductionPlan,
        collection: keyof IProductionPlan,
        x: IProductionPlanSlaughterSales
    ) {
        const a = plan[collection] as IPlannedAnimal[];
        this.MapToArrayByType(a, ProductionPlanAnimalType.BeefCow, x.Cows);
        this.MapToArrayByType(a, ProductionPlanAnimalType.BeefBull, x.Bulls);
        this.MapToArrayByType(a, ProductionPlanAnimalType.BeefHeifer, x.Heifers);
    }
}

export default new Mapper();