// Libraries
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

// Core
import auth, { IAuthorizationResource } from '../core/authorization';

// Api
import api from '../api/authorizationApi';

// Store
import { SliceStatus } from './IStore';

interface AccessListState {
    data: {
        hash: number | null;
    };
    status: SliceStatus;
}

const initialState: AccessListState = {
    data: {
        hash: null,
    },
    status: 'initial',
};

// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
const generateHash = (data: any) => {
    const str = `${JSON.stringify(data)}`;
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }

    return hash;
};

const updateAccessList = (
    state: Draft<AccessListState>,
    action: PayloadAction<IAuthorizationResource[]>
) => {
    auth.updateAccessList(action.payload);
    state.data.hash = generateHash(action.payload);
    state.status = 'ready';
};

const accessListSlice = createSlice({
    name: 'accessList',
    initialState,
    reducers: {
        updateAccessList,
        isLoading(state) {
            state.status = 'loading';
        },
    },
});

export const loadAccessList = (context?: string | null) => {
    return async (dispatch: any) => {
        try {
            dispatch(accessListSlice.actions.isLoading());
            const accessList = await api.getAccessList(context);
            if (accessList) dispatch(accessListSlice.actions.updateAccessList(accessList.Items));
        } catch (err) {
            console.error(err);
        }
    };
};

export default accessListSlice.reducer;
