// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import { ADropdown } from '../../common/inputs';

export interface PersonSearchTypeProps {
    onSearchTypeChange: (type: string) => void;
    seletedSearchType: string;
    validator: IFormValidator;
}

export default function PersonSearchType(props: PersonSearchTypeProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaPerson']);

    const [seletedSearchType, setSelectedSearchType] = useState<string>(props.seletedSearchType);

    const menuItemParams = [
        { text: t('AnelmaPerson:1021'), value: 'Name' },
        { text: t('AnelmaPerson:1000'), value: 'FirstName' },
        { text: t('AnelmaPerson:1001'), value: 'LastName' },
        { text: t('AnelmaPerson:1004'), value: 'Login' },
        { text: t('AnelmaGeneral:1006'), value: 'Address' },
        { text: t('AnelmaPerson:1007'), value: 'PhoneNumber' },
        { text: t('AnelmaPerson:1008'), value: 'EmailAddress' },
    ];

    return (
        <ADropdown
            id='search-type'
            items={menuItemParams}
            onChange={(v) => {
                const type = v as string;
                setSelectedSearchType(type);
                props.onSearchTypeChange(type);
            }}
            style={{ minWidth: '160px' }}
            validator={props.validator}
            value={seletedSearchType}
            withoutContainer
        />
    );
}
