// Libraries
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';

// MUI
// Core
// Common
// Interfaces
// Store
// API
// Feature - Notifications

/*  Can be moved to node's environment variables when builds are checked on tc that are we even able to use node environment variables.
    Have to consider about the fact we have many builds for different envs like integration, demo, prod-demo etc.... */
const getNotificationsHubUrl = () => {
    const hubRelPath = 'notificationhub';

    switch (window.location.host) {
        case 'snellman-anelma3-dev.mtech.fi':
            return `${window.location.protocol}//snellman-notification-api-dev.mtech.fi/${hubRelPath}`;
        case 'snellman-anelma3-test.mtech.fi':
            return `${window.location.protocol}//snellman-notification-api-test.mtech.fi/${hubRelPath}`;
        case 'snellman-anelma3-demo.mtech.fi':
            return `${window.location.protocol}//snellman-notification-api-demo.mtech.fi/${hubRelPath}`;
        case 'anelma3.snellman.fi':
        case 'snellman-anelma3.mtech.fi':
            return `${window.location.protocol}//snellman-notification-api.mtech.fi/${hubRelPath}`;
        default:
            return `${window.location.protocol}//${window.location.hostname}/Snellman.Notification.API.Presentation/${hubRelPath}`;
    }
};

const connection = new HubConnectionBuilder()
    .withUrl(getNotificationsHubUrl(), {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
        withCredentials: true,
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

export default connection;
