import { IGridSize } from '../../announcements/components/bovine-slaughter-announcement/BovineSlaughterAnnouncement';

export const constructAnnouncementNumberGridSize = (): IGridSize => {
    return {
        Xs: 8,
        Sm: 8,
        Md: 8,
        Lg: 4,
    };
};

export const constructCollectWeekGridSize = (): IGridSize => {
    return {
        Xs: 5,
        Sm: 5,
        Md: 4,
        Lg: 2,
    };
};
