import { DialogTitle, Typography, styled } from '@mui/material';

export interface ADialogTitleProps {
    children: React.ReactNode;
}

const Wrapper = styled(DialogTitle)(({ theme }) => ({
    root: {
        borderBottom: '2px solid #0a4c31',
        padding: '5px 15px',
        '& h2': {
            fontSize: '20px',
        },
    },
}));

export default function ADialogTitle(props: ADialogTitleProps) {
    return (
        <Wrapper>
            <Typography variant='h3'>{props.children}</Typography>
        </Wrapper>
    );
}
