import { ParameterValue } from '../../core/resources';
import { IAnimalSummaryExtraInfo } from '../../interfaces/IAnimal';

export const getBovineSexString = (
    row: IAnimalSummaryExtraInfo,
    params: ParameterValue[] | null | undefined
): string => {
    if (!row || isNaN(row?.Sex) || !params || params?.length < 1) {
        return '';
    }

    return params.find((p) => Number(p.code) === Number(row?.Sex))?.text ?? '';
};

export const getBovineBreedString = (
    row: IAnimalSummaryExtraInfo,
    params: ParameterValue[] | null | undefined
): string => {
    if (!row || isNaN(row?.Breed) || !params || params?.length < 1) {
        return '';
    }

    return params.find((p) => Number(p.code) === Number(row?.Breed))?.text ?? '';
};

export const getUsageTypeString = (
    row: IAnimalSummaryExtraInfo,
    params: ParameterValue[] | null | undefined
): string => {
    if (!row || isNaN(Number(row?.UsageType)) || !params || params?.length < 1) {
        return '';
    }

    return params.find((p) => Number(p.code) === Number(row?.UsageType))?.text ?? '';
};

export const getSalesTypeString = (
    row: IAnimalSummaryExtraInfo,
    params: ParameterValue[] | null | undefined
): string => {
    if (!row || isNaN(row?.SalesType) || !params || params?.length < 1) {
        return '';
    }

    return params.find((p) => Number(p.code) === Number(row?.SalesType))?.text ?? '';
};
