import React, { useState, useEffect, CSSProperties } from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import {
    Container,
    Grid,
    Table,
    Typography,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import { TableContainer } from '@mui/material';
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../common/dialog';

//Core
import { ResourceTextApplication, paramRepository, ParameterValues } from '../../core/resources';
import { constants } from 'os';

// Common
import { InfoBlock, ViewTitle } from '../../common/typography';
import dateUtils, { UiFormats } from '../../core/utils.date';
import ViewLoader from '../../common/ViewLoader';

// Interfaces
import {
    IAccounting,
    IAccountingDetails,
    IPaymentRemovals,
    IPaymentAnimals,
    IProducerInfo,
    IAllowances,
    IMeatInspections,
    ISalesTypeStatistics,
    IAnimalReturn,
} from '../../interfaces/IAccounting';

// API
import AccountingApi from '../../api/accountingApi';

//Feature
import '../../economy/invoices/InvoiceDetailView.scss';
import InvoiceMathHelper from '../invoices/InvoiceMathHelper';
import AnelmaLogo from '../../common/layout/snellman-logo.png';
import PaymentRemovalsDetailGrid from './PaymentRemovalsDetailGrid';
import MeatInspectionDetailGrid from './MeatInspectionDetailGrid';
import PaymentAnimalsDetailGrid from './PaymentAnimalsDetailGrid';
import AnimalReturnTable from './components/AnimalReturnTable';

export interface AccountingDetailViewProps {
    data: IAccounting;
    contextData: IProducerInfo;
    onClose: () => void;
}

export enum Type {
    Unknown = 0,
    BovineSlaughter = 1,
    PigSlaughter = 2,
    BovinePurchase = 3,
    PigPurchase = 4,
    Loan = 5,
}

export default function AccountingDetailView(props: AccountingDetailViewProps) {
    const gridContainerCssProperties: CSSProperties = { overflow: 'hidden ', paddingTop: '20px' };

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { contextData, data } = props;
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccounting', 'AnelmaLayout']);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues>([]);

    const [rowsData, setRowsData] = useState<IAccountingDetails[]>([]);
    const [allowancesData, setAllowancesData] = useState<IAllowances[]>([]);
    const [paymentRemovals, setPaymentRemovals] = useState<IPaymentRemovals[]>([]);
    const [paymentAnimals, setPaymentAnimals] = useState<IPaymentAnimals[]>([]);
    const [meatInspections, setMeatInspections] = useState<IMeatInspections[]>([]);
    const [salesTypeStats, setSalesTypeStats] = useState<ISalesTypeStatistics[]>([]);
    const [animalReturns, setAnimalReturns] = useState<IAnimalReturn[]>([]);

    const [typeLoan, setTypeLoan] = useState<boolean>(false);
    const [typeBovinePurchase, setTypeBovinePurchase] = useState<boolean>(false);

    const footerButton: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [],
    };

    const paymentNumberDetailData = () => {
        AccountingApi.getPaymentNumberAccounting(props.data.PaymentNumber).then((response) => {
            if (!response.Entity) {
                return;
            }
            setRowsData([response.Entity]);
            switch (response.Entity.Type) {
                case Type.Loan:
                    setMeatInspections([]);
                    setAllowancesData([]);
                    setPaymentRemovals(response.Entity.PaymentRemovals);
                    setPaymentAnimals([]);
                    setTypeLoan(true);
                    break;
                case Type.BovinePurchase:
                    setAllowancesData(
                        response.Entity.Allowances.sort((a, b) => a.Name.localeCompare(b.Name))
                    );
                    /** assign data from IPaymentRemovals[] */
                    setPaymentRemovals(response.Entity.PaymentRemovals);
                    /** assign data from IPaymentAnimals[] */
                    setPaymentAnimals(response.Entity.PaymentAnimals);
                    /** IMeatInspections[] */
                    setMeatInspections([]);
                    setSalesTypeStats(response.Entity.SalesTypeStatistics);
                    setTypeBovinePurchase(true);
                    break;

                default:
                    setAllowancesData(
                        response.Entity.Allowances.sort((a, b) => a.Name.localeCompare(b.Name))
                    );
                    /** assign data from IPaymentRemovals[] */
                    setPaymentRemovals(response.Entity.PaymentRemovals);
                    /** assign data from IPaymentAnimals[] */
                    setPaymentAnimals(response.Entity.PaymentAnimals);
                    /** IMeatInspections[] */
                    setMeatInspections(response.Entity.MeatInspection);
                    setSalesTypeStats(response.Entity.SalesTypeStatistics);
                    /** assign data from IAnimalReturn[] */
                    setAnimalReturns(response.Entity.AnimalReturns);

                    break;
            }
            setIsLoading(false);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        /** api call with PaymentNumber */
        paymentNumberDetailData();

        /** Parameters load
         *  */
        paramRepository.load(['AnelmaAccounting', 'AnelmaAnimalPayload']).then(() => {
            setSalesTypeParams(paramRepository.resource('AnelmaAnimalPayload', 'SalesTypeBovine'));
        });
    }, [props.data.PaymentNumber]);

    const getNameAddress = (person: IProducerInfo): JSX.Element[] => {
        return [person.ProducerName, person.ProducerAddress].map((str, index) => (
            <div key={index}>{str}</div>
        ));
    };

    const getEntityPayLoadInfo = (data: IAccountingDetails[]): JSX.Element => {
        return (
            <Container>
                <TableContainer
                    key={'entity-payload'}
                    component={Paper}
                    style={{ maxWidth: '600px' }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>{''} </TableCell>
                                <TableCell align='center'>{t('AnelmaAccounting:1030')} </TableCell>
                                <TableCell align='center'>{t('AnelmaAccounting:1031')} </TableCell>
                                <TableCell align='center'>{t('AnelmaAccounting:1032')} </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align='left'>{t('AnelmaAccounting:1027')}</TableCell>
                                    <TableCell align='center'>
                                        {InvoiceMathHelper.roundThousandLeading(row.PriceBeforeTaxes)}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {InvoiceMathHelper.roundThousandLeading(row.VATAmount)}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {InvoiceMathHelper.roundThousandLeading(row.PriceAfterTaxes)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align='left'>{t('AnelmaAccounting:1028')}</TableCell>
                                    <TableCell align='center'>
                                        {InvoiceMathHelper.roundThousandLeading(
                                            row.RemovalPriceBeforeTaxes
                                        )}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {InvoiceMathHelper.roundThousandLeading(row.RemovalVatAmount)}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {InvoiceMathHelper.roundThousandLeading(
                                            row.RemovalPriceAfterTaxes
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align='left'>{t('AnelmaAccounting:1029')}</TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'></TableCell>
                                    <TableCell align='center'>
                                        {InvoiceMathHelper.roundThousandLeading(row.SumAfterTaxes)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        );
    };

    const getSalesType = (salesType: number): string => {
        const foundParam = salesTypeParams.find((p) => Number(p.code) == Number(salesType));
        return foundParam?.text as string;
    };

    const getAnimalsSummary = (data: ISalesTypeStatistics[]): JSX.Element => {
        return (
            <Container>
                <TableContainer
                    key={'animals-payload'}
                    component={Paper}
                    style={{ maxWidth: '800px' }}
                >
                    <Typography variant='h5'>{t('AnelmaAccounting:1045')}</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'> </TableCell>
                                <TableCell align='center'>{t('AnelmaAccounting:1046')} </TableCell>
                                {!typeBovinePurchase && (
                                    <TableCell align='center'>
                                        {t('AnelmaAccounting:1047')}
                                    </TableCell>
                                )}
                                <TableCell align='center'>{t('AnelmaAccounting:1048')} </TableCell>
                                {!typeBovinePurchase && (
                                    <TableCell align='center'>
                                        {t('AnelmaAccounting:1049')}
                                    </TableCell>
                                )}
                                <TableCell align='center'>{t('AnelmaAccounting:1050')} </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align='left'>
                                        {getSalesType(row.SalesType)}
                                    </TableCell>
                                    <TableCell align='center'>{row.AmountOfAnimals}</TableCell>
                                    {!typeBovinePurchase && (
                                        <TableCell align='center'>
                                            {InvoiceMathHelper.roundThousandLeading(row.TotalWeight)}
                                        </TableCell>
                                    )}
                                    <TableCell align='center'>{row.AverageWeight}</TableCell>
                                    {!typeBovinePurchase && (
                                        <TableCell align='center'>
                                            {row.AverageDailyGrowth}
                                        </TableCell>
                                    )}
                                    <TableCell align='center'>
                                        {InvoiceMathHelper.roundThousandLeading(row.AveragePrice)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        );
    };

    const getAllowancesInfo = (data: IAllowances[]): JSX.Element => {
        return (
            <Container>
                <TableContainer
                    key={'allowances-payload'}
                    component={Paper}
                    style={{ maxWidth: '600px' }}
                >
                    <Typography variant='h5'>{t('AnelmaAccounting:1044')}</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>{t('AnelmaAccounting:1071')} </TableCell>
                                <TableCell align='center'>{t('AnelmaAccounting:1072')} </TableCell>
                                <TableCell align='center'>{t('AnelmaAccounting:1073')} </TableCell>
                                <TableCell align='center'>{t('AnelmaAccounting:1038')} </TableCell>
                                <TableCell align='center'>{t('AnelmaAccounting:1012')} </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell> {row.Name}</TableCell>
                                    <TableCell> {row.AmountOfAnimals}</TableCell>
                                    <TableCell>
                                        {InvoiceMathHelper.roundThousandLeading(row.AdditionalWeight)}
                                    </TableCell>
                                    <TableCell>
                                        {InvoiceMathHelper.roundThousandLeading(row.ItemPrice)}
                                    </TableCell>
                                    <TableCell>
                                        {InvoiceMathHelper.roundThousandLeading(row.Price)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        );
    };

    return (
        <ADialog open={true} onClose={() => props.onClose()}>
            <ADialogTitle>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={'auto'}>
                        {t('AnelmaAccounting:1022')}
                    </Grid>
                </Grid>
            </ADialogTitle>
            <ADialogContent isLoading={isLoading} size='xl' className={'invoice-to-print'}>
                <Grid container style={{ overflow: 'hidden' }}>
                    <Grid item md={6} style={{ paddingLeft: '50px' }}>
                        {getNameAddress(contextData as IProducerInfo)}

                        <InfoBlock
                            key={'ytunnus'}
                            label={t('AnelmaAccounting:1023')}
                            value={contextData?.ProducerYTunnus}
                        />
                        <InfoBlock
                            key={'producername'}
                            label={t('AnelmaAccounting:1024')}
                            value={contextData?.ProducerNumber}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <InfoBlock
                                    key={'purchasecontarct'}
                                    label={t('AnelmaAccounting:1083')}
                                    value={
                                        rowsData.find((i) => i.PurchaceContractNumber)
                                            ?.PurchaceContractNumber
                                    }
                                />
                                <InfoBlock
                                    key={'paymentnumber'}
                                    label={t('AnelmaAccounting:1007')}
                                    value={rowsData.find((i) => i.PaymentNumber)?.PaymentNumber}
                                />
                            </Grid>

                            <Grid item md={3} xs={'auto'}>
                                <img src={AnelmaLogo} className={'invoice-logo'} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item md={3}>
                                <InfoBlock
                                    key={'collectingdate'}
                                    label={t('AnelmaAccounting:1005')}
                                    value={dateUtils.formatUtcString(
                                        rowsData.find((i) => i.CollectingDate)?.CollectingDate,
                                        'DD.MM.YYYY'
                                    )}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <InfoBlock
                                    key={'paymentdate'}
                                    label={t('AnelmaAccounting:1006')}
                                    value={dateUtils.formatUtcString(
                                        rowsData.find((i) => i.PaymentDate)?.PaymentDate,
                                        'DD.MM.YYYY'
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item md={3}>
                                <InfoBlock
                                    key={'productinvoicenumber'}
                                    label={t('AnelmaAccounting:1025')}
                                    value={
                                        rowsData.find((i) => i.ProducersInvoiceNumber)
                                            ?.ProducersInvoiceNumber
                                    }
                                />
                            </Grid>
                            <Grid item md={3}>
                                <InfoBlock
                                    key={'ownbilling'}
                                    label={t('AnelmaAccounting:1026')}
                                    value={' '}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container spacing={5} style={gridContainerCssProperties}>
                        <Grid item md={12}>
                            {getEntityPayLoadInfo(rowsData)}
                        </Grid>
                    </Grid>

                    {paymentRemovals.length > 0 && (
                        <Grid container spacing={2} style={gridContainerCssProperties}>
                            <Grid item md={6}>
                                <Typography variant='h5' style={{ marginLeft: '30px' }}>
                                    {t('AnelmaAccounting:1033')}
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <PaymentRemovalsDetailGrid
                                    key={'paymentremovals'}
                                    data={
                                        paymentRemovals.length > 0
                                            ? [...paymentRemovals].sort((a, b) =>
                                                a.InvoiceNumber < b.InvoiceNumber ? 1 : -1
                                            )
                                            : []
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={4} sx={{ paddingTop: '20px' }}>
                        {animalReturns.length > 0 && (
                            <Grid item md={12}>
                                {<AnimalReturnTable list={animalReturns} />}
                            </Grid>
                        )}

                        {allowancesData.length > 0 && (
                            <Grid item md={12}>
                                {getAllowancesInfo(allowancesData)}
                            </Grid>
                        )}

                        {!typeLoan && salesTypeStats.length > 0 && (
                            <Grid item md={12}>
                                {getAnimalsSummary(salesTypeStats)}
                            </Grid>
                        )}
                    </Grid>

                    {paymentAnimals.length > 0 && (
                        <Grid container spacing={2} style={gridContainerCssProperties}>
                            <Grid item md={12}>
                                <Typography variant='h5' style={{ marginLeft: '30px' }}>
                                    {t('AnelmaAccounting:1051')}
                                </Typography>
                                <PaymentAnimalsDetailGrid
                                    key={'paymentanimals'}
                                    data={paymentAnimals}
                                    type={rowsData.find((t) => t.Type)?.Type as number}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {meatInspections.length > 0 && (
                        <Grid container spacing={2} style={gridContainerCssProperties}>
                            <Grid item md={6}>
                                <Typography variant='h5' style={{ marginLeft: '30px' }}>
                                    {t('AnelmaAccounting:1074')}
                                </Typography>
                            </Grid>

                            <Grid item md={3}>
                                <InfoBlock
                                    key={'nro'}
                                    label={t('AnelmaAccounting:1075')}
                                    value={
                                        meatInspections.find((p) => p.MeatInspectionNumber)
                                            ?.MeatInspectionNumber
                                    }
                                />
                            </Grid>
                            <Grid item md={3}>
                                <InfoBlock
                                    key={'inspection-date'}
                                    label={t('AnelmaAccounting:1035')}
                                    value={dateUtils.formatUtcString(
                                        meatInspections.find((p) => p.ApprovalDate)?.ApprovalDate,
                                        'DD.MM.YYYY'
                                    )}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <MeatInspectionDetailGrid
                                    key={'meatinspection'}
                                    data={meatInspections}
                                />
                            </Grid>
                            {rowsData[0].MeatInspectionAdditionalText && (
                                <Grid item md={12} style={{ marginLeft: '30px' }}>
                                    <InfoBlock
                                        key={'inspection-additionalText'}
                                        label={t('AnelmaAccounting:1102')}
                                        value={rowsData[0].MeatInspectionAdditionalText}
                                    />
                                </Grid>
                            )}
                            <Grid item md={6} style={{ marginLeft: '30px' }}>
                                <InfoBlock
                                    key={'inspection-person'}
                                    label={t('AnelmaAccounting:1082')}
                                    value={meatInspections
                                        .map((m) => {
                                            return `${m.VeterinarianName} ${m.VeterinarianPhoneNumber}` as string;
                                        })
                                        .pop()}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <Grid container style={{ overflow: 'hidden', paddingTop: '20px' }}>
                        <Grid item sm={6} style={{ marginLeft: '30px' }}>
                            <InfoBlock
                                key={'buyer'}
                                label={t('AnelmaAccounting:1085')}
                                value={t('AnelmaAccounting:1086')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </ADialogContent>
            <ADialogActions buttons={footerButton} isLoading={isLoading} />
        </ADialog>
    );
}
