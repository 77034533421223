import { useEffect, useState } from 'react';
// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
//Core
import { ResourceTextApplication, paramRepository, ParameterValues } from '../../core/resources';
// Interfaces
import { IMeatInspections } from '../../interfaces/IAccounting';

// Common
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';
import { TextWithTooltip } from '../../common/typography';
import InvoiceMathHelper from '../invoices/InvoiceMathHelper';

export interface MeatInspectionGridProps {
    data: IMeatInspections[];
}

export default function MeatInspectionDetailGrid(props: MeatInspectionGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaAccounting', 'AnelmaLayout']);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues>([]);
    enum ColumnsWidth {
        minSize = 80,
        midSize = 100,
        maxSize = 150,
    }
    const rowsMeatInspection = props.data.map((i) =>
        mergeRight(i, { id: i.CarcassNumber ?? Number('def') })
    );

    const columnsMeatInspection: AGridColumns = [
        gridColumns.singleSelectText(
            'AnimalIdentifier',
            props.data,
            t('AnelmaAccounting:1052'),
            ColumnsWidth.maxSize
        ),
        {
            field: 'GivenSalesType',
            sortable: false,
            headerName: t('AnelmaAccounting:1053'),
            description: t('AnelmaAccounting:1094'),
            type: 'string',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                const foundParam = salesTypeParams.find(
                    (p) => Number(p.code) === Number(params.value)
                );
                return <TextWithTooltip text={foundParam?.text ?? '-'} />;
            },
        },
        gridColumns.singleSelectText(
            'CarcassNumber',
            props.data,
            t('AnelmaAccounting:1076'),
            ColumnsWidth.maxSize
        ),
        gridColumns.dateTime(
            'SlaughterDate',
            t('AnelmaAccounting:1077'),
            'DD.MM.YYYY',
            ColumnsWidth.maxSize
        ),
        gridColumns.singleSelectText(
            'GivenQualityClass',
            props.data,
            t('AnelmaAccounting:1059'),
            ColumnsWidth.maxSize
        ),
        gridColumns.singleSelectText(
            'RejectedReason',
            props.data,
            t('AnelmaAccounting:1078'),
            ColumnsWidth.maxSize
        ),
        gridColumns.singleSelectText(
            'RejectedPart',
            props.data,
            t('AnelmaAccounting:1079'),
            ColumnsWidth.midSize + 30
        ),
        {
            field: 'MeasuredKg',
            sortable: false,
            headerName: t('AnelmaAccounting:1054'),
            description: t('AnelmaAccounting:1088'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'RejectedKg',
            sortable: false,
            headerName: t('AnelmaAccounting:1080'),
            type: 'number',
            width: ColumnsWidth.midSize,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'ApprovedKg',
            sortable: false,
            headerName: t('AnelmaAccounting:1081'),
            type: 'number',
            width: ColumnsWidth.midSize + 20,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
    ];

    useEffect(() => {
        paramRepository.load(['AnelmaAccounting', 'AnelmaAnimalPayload']).then(() => {
            setSalesTypeParams(paramRepository.resource('AnelmaAnimalPayload', 'SalesType'));
        });
    }, []);
    /**
     * Disable sorting on columns done differently here
     * https://mui.com/components/data-grid/sorting/#disable-the-sorting
     * <DataGrid columns={[...columns, { field: 'AnimalIdentifier', sortable: false }]} />
     */
    return (
        <div>
            <ADataGrid
                columns={columnsMeatInspection}
                rows={rowsMeatInspection.length > 0 ? rowsMeatInspection : []}
            ></ADataGrid>
        </div>
    );
}
