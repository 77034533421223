// Libraries
import moment from 'moment';

// Core
import utils from '../../../../../core/utils';
import { AnnouncementTypeEnum } from '../../../../../interfaces/enums';

// Interfaces
import {
    IAnimalAnnouncement,
    IAnimalAnnouncementBatch,
    IAnimalAnnouncementAnimal,
    IBovineAnnouncementBatchResult,
    IUpdateAnnouncementResult,
} from '../../../../../interfaces/IAnnouncement';

// Feature - Helpers

export const mapBovineSlaughterToIAnimalAnnouncement = (
    data: IUpdateAnnouncementResult,
    holdingSiteId: string,
    isManualAnnouncement: boolean = false
): IAnimalAnnouncement => mapAnnouncementResult(data, holdingSiteId, isManualAnnouncement 
    ? AnnouncementTypeEnum.ManualBovineSlaughter 
    : AnnouncementTypeEnum.Slaughter);

export const mapBovineIntermediationAnnouncementResult = (
    data: IUpdateAnnouncementResult,
    holdingSiteId: string,
    isManualAnnouncement: boolean = false
): IAnimalAnnouncement => mapAnnouncementResult(data, holdingSiteId, isManualAnnouncement 
    ? AnnouncementTypeEnum.ManualBovineIntermediation 
    : AnnouncementTypeEnum.Intermediation);

export const mapBovinePurchaseToIAnimalAnnouncement = (
    data: IUpdateAnnouncementResult,
    holdingSiteId: string
): IAnimalAnnouncement => mapAnnouncementResult(data, holdingSiteId, AnnouncementTypeEnum.BovinePurchase);

export const mapAnnouncementResult = (
    data: IUpdateAnnouncementResult,
    holdingSiteId: string,
    type: AnnouncementTypeEnum
): IAnimalAnnouncement => {
    let mappedAnimalAnnouncement: IAnimalAnnouncement = {
        Id: data?.AnnouncementId || utils.generateUniqueId(),
        Number: Number(data.AnnouncementNumber),
        Type: type,
        Created: moment().utc().toString(), // Always current date
        HoldingSiteId: holdingSiteId,
        Info: data.AnnouncementInfo,
        Status: data.Status || '',
        Batches: mapAnnouncementResultBatchToIAnnouncement(data.Batches),
    };
    return mappedAnimalAnnouncement;
}

export const mapRemovedBatchToAnnouncement = (
    announcement: IAnimalAnnouncement,
    deletedBatch: IAnimalAnnouncementBatch
) => {
    const batches = announcement.Batches;
    const updatedAnimalAnnouncementBatch = Array.from(batches).filter(
        (b) => b.Animals[0].EuIdentifier !== deletedBatch.Animals[0].EuIdentifier
    );
    announcement.Batches = [];
    announcement.Batches = updatedAnimalAnnouncementBatch;
    return announcement;
};

// At the moment local fnc declarations
const mapAnnouncementResultBatchToIAnnouncement = (
    batches: IBovineAnnouncementBatchResult[]
): IAnimalAnnouncementBatch[] => {
    let results: IAnimalAnnouncementBatch[] = [];
    for (const batch of batches) {
        let result: IAnimalAnnouncementBatch = {
            Id: batch.Id,
            BatchNumber: batch.AnnouncementBatchNumber as unknown as number,
            BatchStatus: batch.BatchStatus || '',
            CollectWeekStart: {
                Year: batch.CollectWeekStart.Year,
                Week: batch.CollectWeekStart.Week,
            },
            CollectWeekEnd: {
                Year: batch.CollectWeekEnd.Year,
                Week: batch.CollectWeekEnd.Week,
            },
            CollectWeekDay: batch.CollectWeekDay,
            SalesType: batch.SalesType,
            Amount: batch.AnimalCount,
            BatchInfo: batch.AddInfo,
            Animals: mapUpdateAndAdditionsAnimalDataToIAnnouncement(
                batch.AnimalId,
                batch.EUIdentifier,
                batch.Sex,
                batch.Breed,
                batch.BirthDate,
                batch.SalesType
            ),
        };
        results.push(result);
    }
    return results;
};


const mapUpdateAndAdditionsAnimalDataToIAnnouncement = (
    animalId: string,
    euId: string|null,
    sex: number,
    breed: number,
    birthDate: string,
    salesType: number
): IAnimalAnnouncementAnimal[] => {
    if (!euId) return [];
    if (euId && euId[euId.length - 2] !== '-') {
        // If the api response is missing the dash from the eu id, re-add it.
        euId = euId.substring(0, euId.length - 1) + '-' + euId[euId.length - 1];
    }
    let animals: IAnimalAnnouncementAnimal[] = [];
    let animal: IAnimalAnnouncementAnimal = {
        AnimalId: animalId,
        EuIdentifier: euId,
        Sex: sex,
        Breed: breed,
        BirthDate: birthDate,
        SalesType: salesType,
    };
    animals.push(animal);
    return animals;
};
