// Libraries
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogContent,
    ADialogTitle,
    ADialogButtonsProp,
} from '../common/dialog';

// Core
import FormValidator from '../core/FormValidator';
import { ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

// API
import farmVisitApi from '../api/farmVisitApi';

export interface DeleteFarmVisitDialogProps {
    afterDelete: (id: number) => void;
    id: number;
    onClose: () => void;
}

export default function DeleteFarmVisitDialog(props: DeleteFarmVisitDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaFarmVisit']);
    const { enqueueSnackbar } = useSnackbar();

    const validator = new FormValidator();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onDelete = (id: number) => {
        setIsLoading(true);

        if (validator.invalid) {
            handleSnackbarMessage(t('AnelmaFarmVisit:1019'), 'error');
            setIsLoading(false);
            return;
        }

        farmVisitApi.deleteFarmVisit(id).then((response) => {
            if (response.Results.HttpStatus !== 202) {
                handleSnackbarMessage(`${t('AnelmaFarmVisit:1020')}, ${response}`, 'error');
                props.onClose();
                return;
            }

            handleSnackbarMessage(t('AnelmaFarmVisit:1021'), 'success');
            setIsLoading(false);
            props.afterDelete(id);
            props.onClose();
        });
    };

    const handleSnackbarMessage = (
        message: string,
        variant: 'success' | 'error' | 'warning' | 'info'
    ) => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const deletefooterButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [
            {
                type: 'delete',
                onClick: () => onDelete(props.id as number),
                disabled: auth.canDeleteFarmVisit ? false : true,
            },
        ],
    };

    return (
        <>
            <ADialog open={true} onClose={() => props.onClose()}>
                <ADialogTitle>{t('AnelmaGeneral:1142')}</ADialogTitle>
                <ADialogContent isLoading={isLoading} size='md'>
                    <Typography variant='body1' sx={{ padding: '30px 60px', textAlign: 'center' }}>
                        {t('AnelmaFarmVisit:1014')}
                    </Typography>
                </ADialogContent>
                <ADialogActions buttons={deletefooterButtons} isLoading={isLoading} />
            </ADialog>
        </>
    );
}
