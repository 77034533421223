// MUI
import { Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Feature
import './mobileMenuOpen.scss';

export interface MobileMenuOpenProps {
    onOpen: () => void;
}

export default function MobileMenuOpen(props: MobileMenuOpenProps) {
    return (
        <div className='an-header-open-mobile-menu'>
            <Button onClick={() => props.onOpen()}>
                <MenuIcon />
            </Button>
        </div>
    );
}
