import axios from 'axios';
import { IApiResult } from '../interfaces/IGeneral';
import { ISupportRequest } from '../interfaces/logging/ISupportRequest';
import ApiBase from './ApiBase';
import { sendSupportEmailUrl } from './apiEndpoints';

class MessagingApi extends ApiBase {
    sendSupportMessage(From: string, To: string, CorellationId: string) {
        return axios.post<IApiResult<ISupportRequest>>(`${sendSupportEmailUrl}/`, {
            CorrelationId: CorellationId,
            From: From,
            To: To,
            Subject: 'Support Request',
        });
    }
}

export default new MessagingApi();
