import { PublicContactPerson, ParamState } from './interfaces';

interface ContactPersonLabelsProps {
    data: PublicContactPerson;
    responsibilities: ParamState[];
    type: 'card' | 'badge';
}

export default function ContactPersonLabels(props: ContactPersonLabelsProps) {
    return (
        <ul className={`an-${props.type}-labels`}>
            {props.data.Responsibilities.map((item, index) => {
                const param = props.responsibilities.find((r) => r.code === item);
                return param ? (
                    <li key={index}>
                        <span>{param?.text}</span>
                    </li>
                ) : undefined;
            })}
        </ul>
    );
}
