// MUI
import CheckedIcon from '@mui/icons-material/CheckCircleOutline';
import UncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

// Libraries
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AIconButtonBaseProps, ADefaultIconButton } from '../../common/buttons';

export interface ACheckboxIconButtonProps
    extends Omit<AIconButtonBaseProps, 'onClick' | 'tooltip'> {
    checked: boolean;
    checkedTooltip?: NonNullable<React.ReactNode>;
    onClick: (
        checked: boolean,
        event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
    ) => void;
    uncheckedTooltip?: NonNullable<React.ReactNode>;
}

export default function ACheckboxIconButton(props: ACheckboxIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { checked, checkedTooltip, onClick, uncheckedTooltip, ...buttonProps } = props;
    const icon = checked ? <CheckedIcon /> : <UncheckedIcon />;
    const tooltip = checked
        ? checkedTooltip
            ? checkedTooltip
            : t('AnelmaGeneral:1073')
        : uncheckedTooltip
        ? uncheckedTooltip
        : t('AnelmaGeneral:1074');

    return (
        <ADefaultIconButton
            {...buttonProps}
            icon={icon}
            onClick={(e) => onClick(!checked, e)}
            tooltip={tooltip}
        />
    );
}
