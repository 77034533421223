import { searchParametersUrl } from './apiEndpoints';
import { IUpdatePayload } from '../interfaces/IGeneral';
import {
    IPredefinedSearch,
    IPredefinedSearchFarmResult,
    IPredefinedSearchQuery,
} from '../interfaces/ISearchParameters';
import ApiBase from './ApiBase';

class SearchParametersApi extends ApiBase {
    getAllPredefinedSearches() {
        return this.getList<IPredefinedSearch>(`${searchParametersUrl}/`);
    }

    savePredefinedSearch(item: IPredefinedSearch) {
        return this.post<IPredefinedSearch>(`${searchParametersUrl}/`, item);
    }

    modifyPredefinedSearch(item: IUpdatePayload<IPredefinedSearch>) {
        return this.patch<IPredefinedSearch>(`${searchParametersUrl}/`, item);
    }

    deletePredefinedSearch(id: string) {
        return this.delete<IPredefinedSearch>(`${searchParametersUrl}/${id}`);
    }

    runPredefinedSearch(queryItem: IPredefinedSearchQuery) {
        return this.postList<IPredefinedSearchFarmResult>(
            `${searchParametersUrl}/PredefinedSearchQuery`,
            queryItem
        );
    }
}

export default new SearchParametersApi();
