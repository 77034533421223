import { RoutePaths } from '../../../../interfaces/enums';
import { IMainMenuItem } from '../mainMenu/IMainMenu';

export const adviser: IMainMenuItem = {
    itemGroups: [
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.GeneralContacts,
                    resource: 'AnelmaBasicFeatures',
                    scope: 'LIST',
                    textCode: 1014,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.InfoInstructionsAndCode,
                    resource: 'AnelmaBasicFeatures',
                    scope: 'LIST',
                    textCode: 1041,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.InfoMP,
                    resource: 'AnelmaBasicFeatures',
                    scope: 'LIST',
                    textCode: 1042,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.InfoFeeding,
                    resource: 'AnelmaBasicFeatures',
                    scope: 'LIST',
                    textCode: 1043,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.InfoLab,
                    resource: 'AnelmaBasicFeatures',
                    scope: 'LIST',
                    textCode: 1044,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.InfoShop,
                    resource: 'AnelmaBasicFeatures',
                    scope: 'LIST',
                    textCode: 1045,
                },
            ],
        },
    ],
    textCode: 1015,
};
