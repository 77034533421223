import utils from './utils';
import './contentImage.scss';

interface ContentImageProps {
    data: {
        caption: string;
        stretched: boolean;
        url: string;
        withBackground: boolean;
        withBorder: boolean;
    };
    id: string;
}

export default function ContentImage(props: ContentImageProps) {
    const figureClasses: string[] = ['an-content-image'];
    if (props.data.withBorder) figureClasses.push('an-content-image-border');
    if (props.data.stretched) figureClasses.push('an-content-image-streched');
    if (props.data.withBackground) figureClasses.push('an-content-image-background');

    return (
        <figure key={props.id} className={figureClasses.join(' ')}>
            <img alt='' src={props.data.url} />
            {props.data.caption && (
                <figcaption dangerouslySetInnerHTML={utils.wrapHtml(props.data.caption)} />
            )}
        </figure>
    );
}
