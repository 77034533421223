// Libraries
import DefaultDateHeader from 'react-big-calendar/lib/DateHeader';

// Feature
import WeekNumber from './WeekNumber';

export interface DateHeaderProps {
    date: Date;
}

export default function DateHeader(props: DateHeaderProps) {
    return (
        <>
            <WeekNumber date={props.date} />
            <DefaultDateHeader {...props} />
        </>
    );
}
