// Libraries
import { clone } from 'ramda';

// MUI
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

// Feature
import utils from './utils';
import './contentTable.scss';

interface ContentTableProps {
    data: {
        content: string[][];
        withHeadings: boolean;
    };
    id: string;
}

export default function ContentTable(props: ContentTableProps) {
    const d = clone(props.data);
    const head = d.withHeadings ? d.content.splice(0, 1)[0] : undefined;
    const body = d.content;

    return (
        <Table className='an-content-table' key={props.id}>
            {head && (
                <TableHead>
                    <TableRow>
                        {head.map((c, index) => (
                            <TableCell
                                dangerouslySetInnerHTML={utils.wrapHtml(c)}
                                key={`${props.id}-th-${index}`}
                                style={{ width: 'auto' }}
                            />
                        ))}
                    </TableRow>
                </TableHead>
            )}

            <TableBody>
                {body.map((r, index) => (
                    <TableRow key={`${props.id}-tb-${index}`}>
                        {r.map((c, index2) => (
                            <TableCell
                                dangerouslySetInnerHTML={utils.wrapHtml(c)}
                                key={`${props.id}-tb-${index}-${index2}`}
                            />
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
