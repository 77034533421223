import utils from './utils';

interface ContentRawProps {
    className?: string;
    html: string;
    id: string;
}

export default function ContentRaw(props: ContentRawProps) {
    return (
        <div
            key={props.id}
            className={props.className}
            dangerouslySetInnerHTML={utils.wrapHtml(props.html)}
        />
    );
}
