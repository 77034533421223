import axios from 'axios';

import { auditTrailUrl } from '../../api/apiEndpoints';
import { IApiListResult } from '../../interfaces/IGeneral';
import { ICQRSEventBase, ISystemLogEvent } from '../../interfaces/ILog';

export function getUserLog() {
    return axios.get<IApiListResult<ICQRSEventBase>>(`${auditTrailUrl}/`);
}

export function getLog(id: string) {
    return axios.get<IApiListResult<ICQRSEventBase>>(`${auditTrailUrl}/${id}`);
}

export function getLogFromPerod(id: string, fromDate: string, toDate: string) {
    return axios.get<IApiListResult<ICQRSEventBase>>(
        `${auditTrailUrl}/${id}?FromDate=${fromDate}&ToDate=${toDate}`
    );
}

export function getLogByCompanyName(name: string, fromDate?: string, toDate?: string) {
    if (fromDate && toDate)
        return axios.get<IApiListResult<ICQRSEventBase>>(
            `${auditTrailUrl}/Company/${name}?FromDate=${fromDate}&ToDate=${toDate}`
        );
    return axios.get<IApiListResult<ICQRSEventBase>>(`${auditTrailUrl}/Company/${name}`);
}

export function getLogByPersonName(name: string, fromDate?: string, toDate?: string) {
    if (fromDate && toDate)
        return axios.get<IApiListResult<ICQRSEventBase>>(
            `${auditTrailUrl}/Person/${name}?FromDate=${fromDate}&ToDate=${toDate}`
        );
    return axios.get<IApiListResult<ICQRSEventBase>>(`${auditTrailUrl}/Person/${name}`);
}

export function getSeqEvents(correlationId: string, fromDatetime: string) {
    return axios.get<IApiListResult<ISystemLogEvent>>(
        `${auditTrailUrl}/SeqEvents?CorrelationId=${correlationId}&FromDateTime=${fromDatetime}`
    );
}
