// Core
import { calendarEventsUrl } from './apiEndpoints';

// Interfaces
import { IUpdatePayload } from '../interfaces/IGeneral';
import { CalendarEvent } from '../interfaces/calendar/CalendarEvent';

import ApiBase from './ApiBase';

class CalendarApi extends ApiBase {
    createCalendarEvent(payload: CalendarEvent) {
        return this.post<CalendarEvent>(calendarEventsUrl, payload);
    }

    deleteCalendarEvent(id: string) {
        return this.delete<CalendarEvent>(`${calendarEventsUrl}/${id}`);
    }

    getCalendarEvents(farmId: string, from: string, to: string) {
        return this.getList<CalendarEvent>(`${calendarEventsUrl}/farmId/${farmId}/from/${from}/to/${to}`);
    }

    updateCalendarEvent(payload: IUpdatePayload<CalendarEvent>) {
        return this.patch<CalendarEvent>(`${calendarEventsUrl}/${payload.Entity.Id}`, payload);
    }
}

export default new CalendarApi();
