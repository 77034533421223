// Libraries
import { MouseEventHandler } from 'react';

// MUI
// Core
// Common
import { ACloseIconButton } from '../../../../common/buttons';

// Interfaces
// Store
// API
// Messaging
// Feature

export interface ICloseDialogProps {
    className: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function CloseDialogButton(props: ICloseDialogProps) {
    return (
        <ACloseIconButton className={props.className} onClick={props.onClick}></ACloseIconButton>
    );
}
