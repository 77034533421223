// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../common/dialog';
import { AAddIconButton } from '../../common/buttons';
import { AInputContainer } from '../../common/inputs';

// Interfaces
import { IPersonSummary } from '../../interfaces/IBusinessEntities';

// Persons
import PersonSearchForm from '../../persons/search/PersonSearchForm';
import PersonSearchResult from '../../persons/search/PersonSearchResult';

export interface ContactPersonSearchProps {
    onClose: () => void;
    onSelect: (data: IPersonSummary) => void;
}

export default function ContactPersonSearch(props: ContactPersonSearchProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    const [persons, setPersons] = useState<IPersonSummary[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <ADialog open onClose={() => props.onClose()}>
            <ADialogTitle>{t('AnelmaCompany:1078')}</ADialogTitle>

            <ADialogContent size='md'>
                <AInputContainer>
                    <PersonSearchForm
                        afterSearch={(data) => {
                            setPersons(data);
                            setIsLoading(false);
                        }}
                        beforeSearch={() => setIsLoading(true)}
                        showInactive={false}
                        showMoreInfo={false}
                    />
                </AInputContainer>

                <PersonSearchResult
                    actionColumn={[
                        (params) => {
                            const data = (params.row as unknown) as IPersonSummary;
                            return (
                                <AAddIconButton
                                    onClick={() => props.onSelect(data)}
                                    tooltip={t('AnelmaGeneral:1072')}
                                />
                            );
                        },
                    ]}
                    data={persons}
                    enableFiltering={false}
                    isLoading={isLoading}
                    showMoreInfo={false}
                />
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onClose(),
                            type: 'cancel',
                        },
                    ],
                    right: [],
                }}
            />
        </ADialog>
    );
}
