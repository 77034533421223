// Feature
import { Typography } from '@mui/material';
import './contentDelimiter.scss';

interface ContentDelimiterProps {
    data: {
        text: string;
    };
    id: string;
}

export default function ContentDelimiter(props: ContentDelimiterProps) {
    return (
        <div className='an-content-delimiter' key={props.id}>
            <Typography {...props.data} dangerouslySetInnerHTML={{__html: '<hr>'}}/>
        </div>
    );
}
