// Libraries
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// MUI
import { Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import ConfirmDeletionDialog from '../../common/dialog/ConfirmDeletionDialog';

// Interfaces
import { INotification } from '../../interfaces/INotification';

// Store
// API
// Messaging
// Feature - Notifications

const StyledDeleteElement = styled(CloseIcon)(({ theme }) => ({
    cursor: 'pointer',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'right',
    marginLeft: 'auto',
    paddingRight: '5px',
}));

interface INotificationItemDeleteProps {
    notification: INotification;
    deleteNotification: (data: INotification) => void;
}

/** Display notification delete element. Call deleteNotification when delete element is clicked
 *
 * @props notification: INotification
 * @props deleteNotification: (id: string) => void
 */

export default function NotificationItemDelete(props: INotificationItemDeleteProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);

    return (
        <>
            <Tooltip title={t('AnelmaGeneral:1012')} placement='top-start'>
                <StyledDeleteElement onClick={() => setConfirmDialogVisible(true)} />
            </Tooltip>

            <ConfirmDeletionDialog
                open={confirmDialogVisible}
                onClose={() => setConfirmDialogVisible(false)}
                onDelete={() => props.deleteNotification(props.notification)}
                confirmation={t('AnelmaGeneral:1142')}
            />
        </>
    );
}
