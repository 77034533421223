// Libraries
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

// Common
import { Context } from '../common/contextSelector/ContextSelector';

// Store
import { SliceStatus } from './IStore';

export interface ContextState {
    data: ContextData;
    status: SliceStatus;
}

export interface ContextData {
    currentContext: Context | null;
}

const initialState: ContextState = {
    data: {
        currentContext: null,
    },
    status: 'initial',
};

function update(state: Draft<ContextState>, action: PayloadAction<Context | null>) {
    state.data.currentContext = action.payload;
    state.status = 'ready';
}

const contextSlice = createSlice({
    name: 'context',
    initialState,
    reducers: {
        updateContext: update,
    },
});

const { updateContext } = contextSlice.actions;

export const updateCurrentContext = (context: Context | null) => {
    return (dispatch: any) => {
        dispatch(updateContext(context));
    };
};

export default contextSlice.reducer;
