// Libraries
import 'date-fns';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { TimePickerProps, MobileTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Core
import { IFormValidator } from '../../core/FormValidator';
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AInputContainer } from '../../common/inputs';

const StyledKeyboardTimePicker = styled(MobileTimePicker)(({ theme }) => ({
    width: '100%',
    '& label': {
        color: '#0a4c31',
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderBottom: '1px solid',
        borderRadius: '0',
        borderColor: 'rgba(0, 0, 0, 0.23)',
    },
}));

export interface ATimeInputProps {
    disabled?: boolean;
    error?: boolean;
    id: string;
    label?: string;
    maxTime?: Date;
    minTime?: Date;
    onChange: (value: ATimeValue) => any;
    required?: boolean;
    validator: IFormValidator;
    value: ATimeValue;
    withoutContainer?: boolean;
}

export type ATimeValue = Date | null;

export default forwardRef(function ATimeInput(props: ATimeInputProps, ref: ForwardedRef<any>) {
    const {
        error,
        id,
        onChange,
        required,
        validator,
        value,
        withoutContainer,
        ...propsForTimePicker
    } = props;
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<ATimeValue>(value);
    const [err, setError] = useState<boolean>(false);

    useEffect(() => {
        setVal(value);
    }, [value]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (required && val === null) return false;
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setError(!isValidAfterTouch());
    }, [error, id, required, touched, val, validator]);

    const timePickerProps: TimePickerProps<Date> = propsForTimePicker;
    timePickerProps.ampm = false;
    timePickerProps.onChange = (t) => {
        setTouched(true);
        setVal(t);
        onChange(t);
    };
    timePickerProps.value = val;
    timePickerProps.localeText = {
        cancelButtonLabel: t('AnelmaGeneral:1067'),
        clearButtonLabel: t('AnelmaGeneral:1066'),
        okButtonLabel: t('AnelmaGeneral:1054'),
        toolbarTitle: '',
    };

    const input = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledKeyboardTimePicker {...propsForTimePicker} />
        </LocalizationProvider>
    );

    return withoutContainer ? input : <AInputContainer>{input}</AInputContainer>;
});
