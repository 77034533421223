// Libraries
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// MUI
// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
// Interfaces
import { DialogModeEnum, RoutePaths } from '../../interfaces/enums';
import { IFarmVisit } from '../../interfaces/IFarmVisit';

// Store
// API
import FarmVisitAPI from '../../api/farmVisitApi';

// Feature
import CreateFarmVisitDialog from '../CreateFarmVisitDialog';
import ViewLoader from '../../common/ViewLoader';
import { IFarmVisitNotificationLocationData } from '../IFarmVisitNotificationLocationData';

/** Used to render CreateFarmVisitDialog when clicked notification X when pathname equals RoutePaths.FarmVisitDialogEdit.
 *
 * ***If you find a better way to achieve this then obliterate the component.***
 */
export default function FarmVisitNotification() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaSolmuErrors']);
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [locationData] = useState<IFarmVisitNotificationLocationData | null>(
        initializeLocationData()
    );
    const [farmVisit, setFarmVisit] = useState<IFarmVisit>();

    useEffect(() => {
        if (locationData) {
            setLoading(true);
            FarmVisitAPI.getFarmVisitById(locationData.FarmVisitId).then((_) => {
                if (_ === null) {
                    enqueueSnackbar(t('AnelmaSolmuErrors:1000'), {
                        variant: 'error',
                    });
                    setLoading(false);

                    return;
                }
                setFarmVisit(_.Entity);
                setLoading(false);
            });
        }
    }, [locationData]);

    function initializeLocationData() {
        return location.state !== null ? location.state.options : null;
    }

    /** When farm visit dialog is closed, navigate back to farm info with  */
    const onClose = () => {
        navigate(RoutePaths.GeneralFarmInfo, {
            state: {
                FarmVisitId: locationData?.FarmVisitId,
                FarmId: locationData?.FarmId,
            } as IFarmVisitNotificationLocationData,
        });
    };

    return !loading ? (
        farmVisit ? (
            <CreateFarmVisitDialog
                mode={DialogModeEnum.Edit}
                farmDetails={undefined}
                data={farmVisit}
                onClose={() => onClose()}
                handleMapFarmVisit={() => false}
            />
        ) : null
    ) : (
        <ViewLoader />
    );
}
