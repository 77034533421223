// MUI
import { Button, Container, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Feature
import './mobileMenu.scss';
import { MainMenuItemType } from '../mainMenu/IMainMenu';
import MobileMenuGroup from './MobileMenuGroup';
import CultureSelector from '../cultureSelector/CultureSelector';
import MobileHeaderLogo from '../headerLogo/MobileHeaderLogo';

export interface MobileMenuProps {
    items: MainMenuItemType[];
    onClose: () => void;
    open: boolean;
}

export default function MobileMenu(props: MobileMenuProps) {
    if (!props.open) return <></>;

    return (
        <div className='an-mobile-menu'>
            <Container>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <MobileHeaderLogo />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        className='an-mobile-menu-close-container'
                        justifyContent='flex-end'
                        alignContent='center'
                        alignItems='center'
                        style={{ display: 'flex' }}
                    >
                        <Button onClick={() => props.onClose()} style={{ alignSelf: 'flex-end' }}>
                            <CloseIcon />
                        </Button>
                    </Grid>
                </Grid>

                {props.items.map((item, index) => (
                    <MobileMenuGroup closeMenu={() => props.onClose()} item={item} key={index} />
                ))}
                <CultureSelector isInMobileMenu={true} />
            </Container>
        </div>
    );
}
