import { companiesUrl } from './apiEndpoints';
import {
    ICompanyContactPersons,
    ICompanyDetails,
    ICompanySummary,
    IContactPerson,
} from '../interfaces/IBusinessEntities';
import { GUIDType } from '../interfaces/types';
import { IUpdatePayload } from '../interfaces/IGeneral';

import ApiBase from './ApiBase';

export interface CompanyInactivationPayload {
    reason: {
        inactivationReason: number[];
        inactivationTypeID: number | null;
        info: string;
    };
}

export interface CompanyContactPersonPayload {
    ids: string[];
}

class CompanyApi extends ApiBase {
    activateCompany(guid: GUIDType) {
        return this.post<void>(`${companiesUrl}/activate/${guid}`, undefined);
    }

    createCompany(data: ICompanyDetails) {
        return this.post<ICompanyDetails>(companiesUrl, data);
    }

    getCompanies(searchType: string, searchTerm: string, returnExpiredOnes: boolean) {
        return this.getList<ICompanySummary>(
            `${companiesUrl}?${searchType}=${searchTerm}&&ReturnExpiredOnes=${returnExpiredOnes}`
        );
    }

    getCompaniesByIdList(ids: string[]) {
        return this.postList<ICompanyDetails>(`${companiesUrl}/companies-list`, ids);
    }

    getCompanyByGuid(guid: GUIDType) {
        return this.get<ICompanyDetails>(`${companiesUrl}/${guid}`);
    }

    getCompanyByContext(context: string) {
        return this.get<ICompanyDetails>(`${companiesUrl}/context/${encodeURIComponent(context)}`);
    }

    getCompanyContactPersons(payload: CompanyContactPersonPayload) {
        return this.postList<IContactPerson>(`${companiesUrl}/ContactPerson`, payload);
    }

    getAllCompanyContactPersons(context: string) {
        return this.getList<IContactPerson>(
            `${companiesUrl}/context/ContactPerson/${encodeURIComponent(context)}`
        );
    }

    searchCompaniesContactPersonsByCompanyName(companyName: string) {
        return this.getList<ICompanyContactPersons>(
            `${companiesUrl}/SearcContactPersons/${encodeURIComponent(companyName)}`
        );
    }

    getCompanyFullDetailsByName(searchTerm: string) {
        return this.getList<ICompanyDetails>(
            `${companiesUrl}?Name=${searchTerm}&ReturnExpiredOnes=false&DetailLevel=0`
        );
    }

    inactivateCompany(guid: GUIDType, payload?: CompanyInactivationPayload) {
        return this.post<void>(`${companiesUrl}/inactivate/${guid}`, payload || {});
    }

    updateCompany(data: IUpdatePayload<ICompanyDetails>) {
        return this.post<ICompanyDetails>(`${companiesUrl}/${data.Entity.Id}`, data);
    }
}

export default new CompanyApi();
