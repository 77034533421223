// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Container } from '@mui/material';

// Core
import { ParameterValues, paramRepository } from '../../core/resources';
import utils from '../../core/utils';
import auth from '../../core/authorization';

// Common
import { ViewTitle } from '../../common/typography';
import ViewLoader from '../../common/ViewLoader';

// Interfaces
import { AnelmaReportTypeEnum, ReportStatus } from '../../interfaces/enums';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import reportApi, { IGroups } from '../../api/reportingApi';
import { ReportingApiUnintercepted } from '../../api/reportingApiUnintercepted';

// Feature - Report
import Report from '../../reports/Report';
import { constructLanguageParamsForQlik } from '../../reports/helpers';

export interface ITaxYearSummaryProps {
    endQlikSession: (eligible: boolean) => void;
}

export default function TaxYearSummary(props: ITaxYearSummaryProps) {
    const { t } = useTranslation(['AnelmaLayout', 'AnelmaReporting', 'AnelmaGeneral']);

    const qlikUninterceptor = new ReportingApiUnintercepted();

    const culture = useAppSelector((state) => state.configuration.selectedCulture);
    const context = useAppSelector((state) => state.context);
    const qlikConfiguration = useAppSelector((state) => state.configuration.data.qlik);

    const [status, setStatus] = useState<ReportStatus>(ReportStatus.Loading);
    const [crsfToken, setCrsfToken] = useState<string>();
    const [reportParameters, setReportParameters] = useState<ParameterValues>([]);
    const [reportUrl, setReportUrl] = useState<string>('');
    const [producerNumber, setProducerNumber] = useState<string>('');
    const [farmId, setFarmId] = useState<string>('');
    const [parametersInitialized, setParametersInitialized] = useState<boolean>(false);

    useEffect(() => {
        initializeParameters();
    }, []);

    useEffect(() => {
        if (parametersInitialized) prepareReportUrl();
    }, [parametersInitialized]);

    useEffect(() => {
        if (qlikConfiguration) {
            qlikUninterceptor.initValues(
                qlikConfiguration?.qlikCloudBaseUrl,
                qlikConfiguration?.webIntegrationId
            );
            reportApi.getToken(composeGroupsPayload()).then((response) => {
                if (!response) {
                    setStatus(ReportStatus.Failed);
                    return;
                }
                qlikUninterceptor.login(response.Entity).then((response2) => {
                    if (!response2) {
                        setStatus(ReportStatus.Failed);
                        return;
                    }
                    qlikUninterceptor.getCrsfToken().then((response3) => {
                        if (!response3) {
                            setStatus(ReportStatus.Failed);
                            return;
                        }
                        setCrsfToken(response3.headers['qlik-csrf-token']);
                        setStatus(ReportStatus.Ready);
                    });
                });
            });
        }
    }, [qlikConfiguration]);

    // useEffect with callback is same as componentWillUnmount in the prev. React version (when class components where the thing)
    useEffect(() => () => props.endQlikSession(true), []);

    useEffect(() => {
        if (context.data.currentContext) {
            // Take producer number, if failed set status as failed as we cannot proceed
            let producerId = utils.context.parseFarmContext(
                context.data.currentContext.context
            )?.producerId;
            setFarmId(
                utils.context.parseFarmContext(context.data.currentContext.context)?.farmId || ''
            );
            producerId ? setProducerNumber(producerId) : setStatus(ReportStatus.Failed);
            prepareReportUrl();
        }
    }, [context]);

    useEffect(() => {
        if (crsfToken === undefined) setStatus(ReportStatus.Failed);
    }, [crsfToken]);

    // Every time producer number from new context selection changes create report url again
    useEffect(() => {
        if (producerNumber) prepareReportUrl();
    }, [producerNumber]);

    useEffect(() => {
        if (status === ReportStatus.Ready) prepareReportUrl();
    }, [status]);

    const initializeParameters = () => {
        paramRepository.load(['AnelmaReporting', 'AnelmaGeneral']).then(() => {
            setReportParameters(
                paramRepository.resource(
                    'AnelmaReporting',
                    AnelmaReportTypeEnum.TaxYearSummaryReport.toString()
                )
            );
            setParametersInitialized(true);
        });
    };

    const prepareReportUrl = () => {
        if (
            reportParameters.find((_) => _.code) &&
            reportParameters.find((_) => _.code)?.text !== undefined
        ) {
            let reportUrl: string = t('AnelmaReporting:1014')
                .replace('{sheet}', String(reportParameters.find((_) => _.code)?.text))
                .replace('{integrationId}', qlikUninterceptor._webIntegrationId ?? '')
                .replace('{language}', constructLanguageParamsForQlik(culture))
                .replace('{farmId}', producerNumber);
            setReportUrl(`${reportUrl}&qlik-csrf-token=${crsfToken}&identity=${farmId}`);
        }
    };

    const composeGroupsPayload = (): IGroups => {
        let groups: IGroups = {};
        if (auth.canViewProductionReport) groups.ProductionReport = String(1048);
        if (auth.canViewHealthReport) groups.HealthReport = String(1047);
        if (auth.canViewFinancialReport) groups.FinancialReport = String(1079);
        if (auth.canViewButcheryReport) groups.ButcheryReport = String(1046);
        if (auth.canViewEconomyTaxYearSummary) groups.TaxYearSummary = String(1081);
        return groups;
    };

    return (     
        <Container data-robot-id = {"app-body-tax-year-summary"}>
            <ViewTitle>{t('AnelmaLayout:1081')}</ViewTitle>
            {status === ReportStatus.Loading && <ViewLoader />}
            {reportUrl !== undefined && status === ReportStatus.Ready ? (
                <Report reportUrl={reportUrl} />
            ) : null}
        </Container>       
    );
}
