// API
import ApiBase from './ApiBase';
import { animalAnnouncementImportUrl, animalAnnouncementUrl } from './apiEndpoints';

// Interfaces
import {
    IAnimalAnnouncement,
    IAnimalAnnouncementContainsAnimals,
    IAnimalAnnouncementContainsAnimalsResult,
    IAnnouncementImport,
    ICreateBovinePurchaseAnnouncement,
    ICreateAnnouncement,
    ITiltuAnnouncement,
    IUpdateAnnouncementResult,
    IUpdateBovineAnnouncement,
    IUpdateBovinePurchaseAnnouncement,
} from '../interfaces/IAnnouncement';
import { GUIDType } from '../interfaces/types';
import { AnnouncementStatus } from '../animals/shared/types/announcementStatus';

class AnimalAnnouncementApi extends ApiBase {
    getAnimalAnnouncementsFromAnelma(farmGuid: string) {
        return this.getList<IAnimalAnnouncement>(
            `${animalAnnouncementUrl}/list-from-anelma/${farmGuid}`
        );
    }

    getAnimalAnnouncementFromTiltu(announcementNumber: number) {
        return this.get<ITiltuAnnouncement>(`${animalAnnouncementUrl}/${announcementNumber}`);
    }

    getImportData(importGUID: string) {
        return this.get<IAnnouncementImport>(`${animalAnnouncementImportUrl}/${importGUID}`);
    }

    animalAnnouncementState(id: GUIDType) {
        return this.get<{ Status: AnnouncementStatus }>(`${animalAnnouncementUrl}/announcement-state/${id}`);
    }

    animalAnnouncementContainsAnimals(payload: IAnimalAnnouncementContainsAnimals) {
        return this.post<IAnimalAnnouncementContainsAnimalsResult>(
            `${animalAnnouncementUrl}/animal-announcements-contains-animals`,
            payload
        );
    }

    createBovineSlaughterAnnouncement(payload: ICreateAnnouncement) {
        return this.postToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/bovine-slaughter`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    createManualBovineSlaughterAnnouncement(payload: ICreateAnnouncement) {
        return this.postToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/manual-bovine-slaughter`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    createBovineIntermediationAnnouncement(payload: ICreateAnnouncement) {
        return this.postToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/bovine-intermediation`,
            payload
        );
    }

    createManualBovineIntermediationAnnouncement(payload: ICreateAnnouncement) {
        return this.postToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/manual-bovine-intermediation`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    createBovinePurchaseAnnouncement(payload: ICreateBovinePurchaseAnnouncement) {
        return this.postToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/bovine-purchase`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    updateBovineSlaughterAnnouncement(payload: IUpdateBovineAnnouncement) {
        return this.putToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/bovine-slaughter`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    updateManualBovineSlaughterAnnouncement(payload: IUpdateBovineAnnouncement) {
        return this.putToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/manual-bovine-slaughter`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    updateBovineIntermediationAnnouncement(payload: IUpdateBovineAnnouncement) {
        return this.putToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/bovine-intermediation`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    updateManualBovineIntermediationAnnouncement(payload: IUpdateBovineAnnouncement) {
        return this.putToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/manual-bovine-intermediation`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    updateBovinePurchaseAnnouncement(payload: IUpdateBovinePurchaseAnnouncement) {
        return this.putToTiltu<IUpdateAnnouncementResult>(
            `${animalAnnouncementUrl}/bovine-purchase`,
            payload,
            {
                throwValidationErrors: true,
            }
        );
    }

    // Using any return type because the api somehow returns only ResultSummary object.
    deleteAnimalAnnouncement(announcementNumber: number) {
         return this.deleteToTiltu<any>(`${animalAnnouncementUrl}/${announcementNumber}`);
    }

    deleteAnimalAnnouncementById(announcementId: GUIDType) {
        return this.delete<any>(`${animalAnnouncementUrl}/by-id/${announcementId}`);
    }

    deleteAnimalAnnouncementBatch(announcementNumber: number, announcementBatchNumbers: string[]) {
        return this.delete<any>(
            `${animalAnnouncementUrl}/${announcementNumber}/batches?announcementBatchNumbers=${announcementBatchNumbers}`
        );
    }
}

export default new AnimalAnnouncementApi();
