// Core
import { configurationUrl, loginStatusUrl, personsUrl } from './apiEndpoints';

// Store
import { Configuration } from '../store/configurationSlice';
import { LoginStatus } from '../store/loginStatusSlice';

// Api
import ApiBase from './ApiBase';

// Interfaces
import { IPersonDetailsExtended } from '../interfaces/IBusinessEntities';

class StartupApi extends ApiBase {
    getConfiguration() {
        return this.get<Configuration>(configurationUrl, { incompatibleResult: true });
    }

    getLoginStatus() {
        return this.get<LoginStatus>(loginStatusUrl, { incompatibleResult: true });
    }

    getUserInfo() {
        return this.get<IPersonDetailsExtended>(`${personsUrl}/user-info`);
    }
}

export default new StartupApi();
