import {
    IClientResources,
    IResourceRepository,
    ResourceBoundaryApplication,
    ResourceGroup,
} from './resources';
import axios from 'axios';
import { clientResourceUrl } from '../api/apiEndpoints';
import { assoc, forEach, pathOr, reduce } from 'ramda';

export interface IBoundaryRepository<TResource, TApplication> {
    load(groups: TApplication[]): Promise<void>;
    resource(group: TApplication, code: string): TResource;
}

export interface IBoundaryValue {
    code: string;
    maxValue: number | null;
    minValue: number | null;
}

type BoundaryValues = IBoundaryValue[];

export interface IBoundaryValueGroup {
    id: ResourceBoundaryApplication;
    values: BoundaryValues;
}

export interface BoundaryResource {
    [Key: string]: IBoundaryValue;
}

function resourceQueryBuilder(groups: ResourceBoundaryApplication[]): string {
    return groups.map((i) => `b=${i}`).join('&');
}

function createBoundaryGroup(group: IBoundaryValueGroup): BoundaryResource {
    const init: BoundaryResource = {};
    const addParameter = (a: BoundaryResource, c: IBoundaryValue) =>
        assoc(c.code, { code: c.code, minValue: c.minValue, maxValue: c.maxValue }, a);

    return reduce(addParameter, init, group.values);
}

class BoundaryValueRepositoryImpl
    implements IResourceRepository<IBoundaryValue, ResourceBoundaryApplication>
{
    private resources: ResourceGroup<BoundaryResource> = {};

    async load(groups: ResourceBoundaryApplication[]): Promise<void> {
        const result = await axios
            .get<IClientResources>(`${clientResourceUrl}?${resourceQueryBuilder(groups)}`)
            .then((response) => response.data);

        forEach(
            (group: IBoundaryValueGroup) => (this.resources[group.id] = createBoundaryGroup(group)),
            result.boundaryValueGroups
        );
    }

    resource(group: string, code: string): IBoundaryValue {
        return pathOr(
            {
                code,
                maxValue: null,
                minValue: null,
            },
            [group, code],
            this.resources
        );
    }
}

export const boundaryRepository = new BoundaryValueRepositoryImpl();
