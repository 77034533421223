// Libraries
import moment from 'moment';
import { mergeRight } from 'ramda';
import { ParameterValues } from '../../../core/resources';
import { CompanyEntityTypeEnum, ContactPersonRoleTypeEnum } from '../../../interfaces/enums';

// Interfaces
import {
    IConversationResult,
    INewConversationResponse,
    INewMessageResult,
} from '../../../interfaces/ICommunication';
import IContactPersonRecipient from '../interfaces/IContactPersonRecipient';
import { PublicContactPerson } from '../../../publicContactPersons/interfaces';
import { AnelmaCultureType } from '../../../interfaces/types';
import { ICompanyContactPersons } from '../../../interfaces/IBusinessEntities';

export const capitalizeFirstLetter = (value: string): string => {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

export const capitalizeString = (value: string): string => {
    return `${value.toUpperCase()}`;
};

export const timeFormat = (value: string): string => {
    return moment(new Date(`${value}Z` as string) as Date).format('DD.MM.YYYY HH:mm');
};

// #region Multiple participant type and initiator of message/conversation checks
// If it's actual participant
export const isLoggedInUserParticipant = (
    message: INewMessageResult,
    senderId: string,
    currentUserId: string | null
): boolean => {
    if (message.ParticipantList.some((participant) => participant === currentUserId)) {
        if (senderId !== currentUserId) return true;
    }
    return false;
};

export const isLoggedInUserLatestMessageSender = (
    message: INewMessageResult,
    currentUserId: string | null
): boolean => {
    return currentUserId !== null
        ? new Set(message.ParticipantList).size === 1 &&
              message.ParticipantList.some((participant) => participant === currentUserId)
        : false;
};

export const isLoggedInUserInitiatorOfConversation = (
    message: INewMessageResult,
    currentUserId: string | null
) => {
    if (message.ParticipantList.find((participant) => participant === currentUserId)) {
        if (message.SenderId === currentUserId) {
            return true;
        }
    }
    return false;
};
// #endregion

export const mapINewConversationResponseToConversationResult = (
    conversation: INewConversationResponse
): IConversationResult => {
    return {
        ConversationId: conversation.ConversationId,
        ParticipantList: conversation.RecipientList,
        Topic: String(conversation.Topic),
        Title: conversation.Title,
        CreatedDateTime: conversation.CreatedDateTime,
        WasRead: true,
    };
};

export const mapToINewMessageResult = (data: INewConversationResponse): INewMessageResult[] => {
    return [
        {
            SenderId: data.SenderId,
            MessageId: data.MessageId,
            ConversationId: data.ConversationId,
            Body: data.Body,
            CreatedDateTime: data.CreatedDateTime,
            ParticipantList: data.RecipientList,
            Attachments: data.Attachments !== undefined ? data.Attachments : [],
        },
    ];
};

// Result will default to the code in case no match to the company type
export const resolveContactPersonRole = (
    companyType: CompanyEntityTypeEnum,
    roleTypeCode: ContactPersonRoleTypeEnum,
    farmCompanyTitles: ParameterValues,
    logisticsCompanyTitles: ParameterValues,
    snellmanCompanyTitles: ParameterValues
): string => {
    switch (companyType) {
        // Cast the result to string if the result somehow would return to undefined object
        case CompanyEntityTypeEnum.Farm:
            return String(
                farmCompanyTitles.find((title) => title.code === String(roleTypeCode))?.text
            );
        case CompanyEntityTypeEnum.TrafficContractor:
            return String(
                logisticsCompanyTitles.find((title) => title.code === String(roleTypeCode))?.text
            );
        case CompanyEntityTypeEnum.Snellman:
            return String(
                snellmanCompanyTitles.find((title) => title.code === String(roleTypeCode))?.text
            );
    }
    return String(roleTypeCode);
};

export const propContactPersonsAreDefined = (
    snellmanContactPersons: IContactPersonRecipient[] | undefined,
    personCompanyContactPersons: IContactPersonRecipient[] | undefined
) => {
    if (snellmanContactPersons !== undefined && personCompanyContactPersons !== undefined)
        return true;
    return false;
};

export const propSnellmanContactPersonsDefined = (
    snellmanContactPersons: IContactPersonRecipient[] | undefined
) => {
    if (snellmanContactPersons !== undefined) return true;
    return false;
};

export const composeProducerRecipientOptions = (contactPersons: IContactPersonRecipient[]) => {
    return contactPersons
        .filter((value) => {
            if (value.Name !== null && value.Id !== null) return value;
        })
        .map((value) =>
            mergeRight(value, {
                text: `${value.Name}, ${
                    value.Title !== undefined && value.Title !== null ? value.Title : ''
                }`,
                value: value.Id,
                email: value.EmailAddress,
            })
        );
};

/** Get job title for Snellman contact person.
 *
 *  At the time of writing defaults to finnish title. Additional information from JIRA ticket: http://jira.mtech.fi/browse/AN-1096
 */
export const getSnellmanContactPersonTitle = (
    contactPerson: PublicContactPerson,
    culture: AnelmaCultureType | undefined
): string => {
    if (culture) {
        switch (culture) {
            case 'sv-SE':
                return contactPerson.JobTitleSE;
            case 'en-GB':
                return contactPerson.JobTitleEN;
            case 'fi':
            case 'fi-FI':
                return contactPerson.JobTitleFI;
        }
    }
    return contactPerson.JobTitleFI;
};

/** Get work titles from parameters and apply and return the result.
 *
 * @param contacts ICompanyContactPersons[]
 * @param farmCompanyTitles ParameterValues
 * @param logisticsCompanyTitles ParameterValues
 * @param snellmanCompanyTitles ParameterValues
 * @returns IContactPersonRecipient[]
 */
export const mapCompanyContactPersons = (
    contacts: ICompanyContactPersons[],
    farmCompanyTitles: ParameterValues,
    logisticsCompanyTitles: ParameterValues,
    snellmanCompanyTitles: ParameterValues
) => {
    const contactPersons: IContactPersonRecipient[] = [];

    contacts.forEach((companyDetail) => {
        companyDetail.ContactPersons.forEach((contactPerson) => {
            contactPersons.push({
                Id: contactPerson.Id,
                Name:
                    contactPerson.Name !== undefined && contactPerson.Name.length > 0
                        ? contactPerson.Name
                        : 'Nimetön henkilö',
                EmailAddress: contactPerson.EmailAddress,
                CompanyId: '',
                Title:
                    contactPerson.Roles.length > 0
                        ? resolveContactPersonRole(
                              companyDetail.CompanyType,
                              contactPerson.Roles[0].Type,
                              farmCompanyTitles,
                              logisticsCompanyTitles,
                              snellmanCompanyTitles
                          )
                        : '-',
                ProfilePicture: '',
                CompanyName: companyDetail.CompanyName,
            });
        });
    });

    return contactPersons;
};
