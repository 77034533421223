// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
// Core
import defaultData from '../../../core/defaultData';
import { IFormValidator } from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';

// Common
import {
    ADialog,
    ADialogTitle,
    ADialogContent,
    ADialogActions,
    ADialogButtonsProp,
} from '../../../common/dialog';

// Interfaces
import { IFarmSupplier } from '../../../interfaces/IProductionPlan';
import { IFarmDetails } from '../../../interfaces/IBusinessEntities';

// Store
// API
// Feature - Animal announcements
import CloseDialogButton from '../../announcements/components/general/CloseDialogButton';

// Feature
import ExternalSeller from './ExternalSeller';
import { ChangeCanEdit, flagOrRemoveSellerFarm, MapAndRemoveDuplicates, RemoveDuplicates, RemoveEmptyFarms } from '../helpers/FarmSupplierHelper';

interface IExternalSellerDialogProps {
    readonly?: boolean;
    validator: IFormValidator;
    onClose: () => void;
    onSave: (data: IFarmSupplier[]) => void;
    onCancel: (data: IFarmSupplier[]) => void;
    sellerFarms: IFarmSupplier[];
    farmDetails: IFarmDetails;
}

export default function ExternalSellerDialog(props: IExternalSellerDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCattleProductionPlan',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [sellerFarms, setSellerFarms] = useState<IFarmSupplier[]>(
        (props.sellerFarms.length > 0) ? props.sellerFarms : [defaultData.sellerFarm()]
    );

    const [initialSellerFarms, setInitialSellerFarms] = useState<IFarmSupplier[]>([]);

    useEffect(() => {
        setInitialSellerFarms(sellerFarms);
    }, []);

    const onSellerFarmsChange = (newFarmSupplier: IFarmSupplier) => {
        if (props.farmDetails.Id === newFarmSupplier.Id) {
            enqueueSnackbar(t('AnelmaCattleProductionPlan:1076'), {
                variant: 'error',
            });
            setSellerFarms([...sellerFarms]);
            return;
        }

        var idx = sellerFarms.findIndex((_) => _.Id === newFarmSupplier.Id);

        if (newFarmSupplier.ValidId !== '' && newFarmSupplier.ValidId !== null && newFarmSupplier.ValidId !== undefined) {
            newFarmSupplier.Id = newFarmSupplier.ValidId;
            newFarmSupplier.ValidId = '';
        }

        if (idx === -1) {
            sellerFarms.push(newFarmSupplier);
        }
        else {
            sellerFarms[idx] = newFarmSupplier;
        }

        if (initialSellerFarms.find((_) => _.Id === newFarmSupplier.Id) && idx !== -1) {
            var farms = MapAndRemoveDuplicates(sellerFarms, initialSellerFarms);

            setSellerFarms([...farms]);
        }
        else {
            setSellerFarms([...RemoveDuplicates(sellerFarms)]);
        }
    };

    const cancelAndClose = () => {
        props.onCancel(RemoveEmptyFarms(initialSellerFarms));
        props.onClose();
    }

    const onSave = () => {
        var farms = MapAndRemoveDuplicates(RemoveEmptyFarms(sellerFarms), initialSellerFarms);

        if (farms.filter(x => x.GMFreedomValidated === false).length > 0) {
            enqueueSnackbar(t('AnelmaCattleProductionPlan:1083'), { variant: 'error' });
            return;
        }
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaCattleProductionPlan:1084'), { variant: 'error' });
            return;
        }

        props.onSave(ChangeCanEdit(farms));
        props.onClose();
    }

    const hasSellerFarmsEmptyFarms = () => {
        return (sellerFarms.find((_) => _.SellingFarmName === '') ? true : false);
    }

    const dialogBtns: ADialogButtonsProp = {
        left: [
            {
                onClick: () => cancelAndClose(),
                label: t('AnelmaGeneral:1009'),
                type: 'cancel',
                disabled: props.readonly,
            },
        ],
        right: [
            {
                onClick: () => setSellerFarms((previousState) => [...previousState, defaultData.sellerFarm()]),
                label: t('AnelmaGeneral:1175'),
                type: 'ok',
                disabled: hasSellerFarmsEmptyFarms()
            },
            {
                onClick: () => {
                    onSave();
                },
                label: `${t('AnelmaGeneral:1054')} + ${t('AnelmaGeneral:1152')}`,
                type: 'ok',
                disabled: props.readonly,
            },
        ],
    };

    const getExternalSellerElement = () => {
        if (sellerFarms.filter(_ => _.Flags === 0).length === 0 && hasSellerFarmsEmptyFarms()) {
                sellerFarms.push(defaultData.sellerFarm());
        }

        return [...sellerFarms].map((_) =>
            _.Flags === 0 ? (
                <ExternalSeller
                    key={_.Id}
                    readonly={props.readonly}
                    validator={props.validator}
                    sellerFarm={_}
                    enableRowDelete={true}
                    handleSellerFarmsChange={(_) => onSellerFarmsChange(_)}
                    handleRowRemove={(id: string) => setSellerFarms([...flagOrRemoveSellerFarm(sellerFarms, initialSellerFarms, id)])}
                />
            ) : null
        )
    }

    return (
        <ADialog open onClose={props.onClose} disableEnforceFocus>
            <ADialogTitle>
                {t('AnelmaCattleProductionPlan:1033')}
                <CloseDialogButton className='animal-announcement-close-button-float-right' onClick={cancelAndClose} />
            </ADialogTitle>
            <ADialogContent size={'lg'}>
                {<>{getExternalSellerElement()}</>}
            </ADialogContent>
            <ADialogActions buttons={dialogBtns} />
        </ADialog>
        );
    }
