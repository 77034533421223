// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Feature
import ADialog from '../../common/dialog/ADialog';
import ADialogTitle from '../../common/dialog/ADialogTitle';
import CloseDialogButton from '../announcements/components/general/CloseDialogButton';
import ADialogContent from '../../common/dialog/ADialogContent';
import { IFarmSupplier, IProductionPlan, IProductionPlanAccess, IProductionPlanUserAccess } from '../../interfaces/IProductionPlan';
import productionPlanApi from '../../api/productionPlanApi';
import { IFarmDetails } from '../../interfaces/IBusinessEntities';
import FormValidator from '../../core/FormValidator';

// Dialog blocks
import BasicFarmInfo from './blocks/BasicFarmInfo';
import PlanHeaderInfo from './blocks/PlanHeaderInfo';
import AnimalDataGrids from './blocks/AnimalDataGrids';
import FarmTrends from './blocks/FarmTrends';
import Environment from './blocks/Environment';
import Confirmation from './blocks/Confirmation';
import { ITiltuApiResult } from '../../interfaces/IGeneral';
import { AnimalTypeEnum, FarmingTypeEnum, ProductionPlanAnimalType } from '../../interfaces/enums';
import companyApi from '../../api/companyApi';
import '../shared/styles/sharedStyles.scss';
import EndingOfProduction from './blocks/EndingOfProduction';

interface IProductionPlanProps {
    data: IProductionPlan;
    access: IProductionPlanUserAccess;
    farmDetails: IFarmDetails | null;
    readonly?: boolean;
    onClose: (reload?: boolean) => void;
    handleSellerFarmsChange: (farms: IFarmSupplier[]) => void;
    handleBuyCalfsChange: (_: boolean) => void;
    changeProductionEndYear: (_: number) => void;
    handleNoSlaughterBovineSoldChange: (_: boolean) => void;
    handleChangeNoTransmissionBovineSoldChange: (_: boolean) => void;
}

export default function ProductionPlan(props: IProductionPlanProps){
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaCattleProductionPlan']);
    const { enqueueSnackbar } = useSnackbar();
    const validator = new FormValidator();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState(props.data);
    const [readonly, setReadonly] = useState(false);
    const [access, setAccess] = useState<IProductionPlanAccess | null>(null);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails | null>(null);

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    useEffect(() => {
        setData(props.data);
        setReadonly(props.readonly != null ? props.readonly : false);
        if(props.farmDetails)
            setFarmDetails(props.farmDetails);
        else if(props.access.IsAdmin) 
            companyApi.getCompanyByGuid(props.data.FarmId).then(response => {
                setFarmDetails(response?.Entity as IFarmDetails);
            });
    }, [props.data, props.readonly])

    useEffect(() => {
        if(!farmDetails) return;
        setAccess({
            ...props.access,
            ProductionDirection: farmDetails.ProductionLines.find(x => x.Type === AnimalTypeEnum.Bovine)?.FarmingTypes || [],
            HasDairyAnimals: props.data.AnimalCapacities.filter(x => x.AnimalType === ProductionPlanAnimalType.DairyCow).length > 0,
            HasBreedingAnimals: props.data.AnimalCapacities.filter(x => x.AnimalType === ProductionPlanAnimalType.BreedingCow).length > 0
        });
        setIsLoading(false);
    }, [props.access, farmDetails])

    const handleSaveResponse = (response: ITiltuApiResult<IProductionPlan> | null): boolean => {
        if (!response) {
            enqueueSnackbar(t('AnelmaGeneral:1019'), {
                variant: 'error',
            });
            return false;
        }
        else if (response.Summary && response.Summary.Errors && response.Summary.Errors.length > 0) {
            for (let error of response.Summary.Errors) {
                enqueueSnackbar(t(`${error.Explanation}`), {
                    variant: 'error',
                });
            }
            return false;
        }
        enqueueSnackbar(t('AnelmaGeneral:1018'), {
            variant: 'success',
        });
        return true;
    };

    const closeAfterSave = (success: boolean) => {
        if(success)
            props.onClose(true);
    }

    const handleSaveButtonClick = () => {
        if(readonly) return;
        
        setIsLoading(true);
        if (isBuysCalfsDirectlyFromOtherFarmValid() && isOrganicProductionStateValid() && isAnimalCapacitiesValid()) {
            if(!data.Id)
                productionPlanApi.createPlan(data)
                    .then(handleSaveResponse)
                    .then(closeAfterSave)
                    .finally(() => setIsLoading(false)); 
            else
                productionPlanApi.updatePlan(data)
                    .then(handleSaveResponse)
                    .then(closeAfterSave)
                    .finally(() => setIsLoading(false));
        }
    }

    const isOrganicProductionStateValid = () => {
        if (data.OrganicFarmingPerformed)
            if (data.OrganicProductionState === 0) {
                enqueueSnackbar(t('AnelmaCattleProductionPlan:1075'), { variant: 'error' });
                setIsLoading(false);
                return false;
            } else return true;
        return true;
    };

    const isBuysCalfsDirectlyFromOtherFarmValid = () => {
        if (data.BuysCalfsDirectlyFromOtherFarm) {
            if (data.SellingFarms.every((_) => _.Flags === 1) || data.SellingFarms.length < 1) {
                enqueueSnackbar(t('AnelmaCattleProductionPlan:1078'), { variant: 'error' });
                setIsLoading(false);
                return false;
            }
        }
        return true;
    }

    const isAnimalCapacitiesValid = () => {
        if (!farmDetails) return false;

        let farmingTypes = farmDetails.ProductionLines.find(x => x.Type === AnimalTypeEnum.Bovine)?.FarmingTypes

        if (data.AnimalCapacities.filter(x => x.Capacity > 0).length === 0) {
            enqueueSnackbar(t('AnelmaCattleProductionPlan:1082'), { variant: 'error' });
            setIsLoading(false);
            return false;
        }

        // lypsykarjatila
        if (farmingTypes?.includes(FarmingTypeEnum.Dairy)) {
            const dairyCowCapacity = data.AnimalCapacities.find(x => x.AnimalType === ProductionPlanAnimalType.DairyCow)?.Capacity;

            if (dairyCowCapacity !== undefined && dairyCowCapacity > 0) {
                return true;
            }
        }

        // emolehm�tila
        if (farmingTypes?.includes(FarmingTypeEnum.Calving)) {
            const breedCowCapacity = data.AnimalCapacities.find(x => x.AnimalType === ProductionPlanAnimalType.BreedingCow)?.Capacity;

            if (breedCowCapacity !== undefined && breedCowCapacity > 0) {
                return true;
            }
        }

        // kasvattaja
        if (farmingTypes?.includes(FarmingTypeEnum.Upbringing)) {
            const bullCapacity = data.AnimalCapacities.find(x => x.AnimalType === ProductionPlanAnimalType.Bull)?.Capacity;
            const heiferCapacity = data.AnimalCapacities.find(x => x.AnimalType === ProductionPlanAnimalType.Heifer)?.Capacity;

            if (bullCapacity !== undefined && bullCapacity > 0) {
                return true;
            }
            if (heiferCapacity !== undefined && heiferCapacity > 0) {
                return true;
            }
        }

        // v�likasvattaja
        if (farmingTypes?.includes(FarmingTypeEnum.Transmission)) {
            const calfCapacity = data.AnimalCapacities.find(x => x.AnimalType === ProductionPlanAnimalType.Calf)?.Capacity;

            if (calfCapacity !== undefined && calfCapacity > 0) {
                return true;
            }
        }

        enqueueSnackbar(t('AnelmaCattleProductionPlan:1082'), { variant: 'error' });
        setIsLoading(false);
        return false;
    }

    const getDialogTitle = () => {
        return data.Id != null
            ? `${t('AnelmaLayout:1065')} ${props.data.TargetYear ?? ''}`
            : `${t('AnelmaCattleProductionPlan:1079')} ${props.data.TargetYear ?? ''}`;
    };

    return (<ADialog open onClose={props.onClose} disableEnforceFocus>
        <ADialogTitle>
            {getDialogTitle()}
            <CloseDialogButton
                className='animal-announcement-close-button-float-right'
                onClick={() => props.onClose()}
            />
        </ADialogTitle>
        <ADialogContent size={'xl'} isLoading={isLoading}>
        {access === null || farmDetails === null ? (<></>) : (<>
                <PlanHeaderInfo 
                    data={data}
                    farmDetails={farmDetails}
                />
                <AnimalDataGrids 
                    data={data}
                    access={access}
                    readonly={readonly}
                    validator={validator}
                    handleNoSlaughterBovineSoldChange={(_: boolean) => props.handleNoSlaughterBovineSoldChange(_)}
                    handleChangeNoTransmissionBovineSoldChange={(_: boolean) => props.handleChangeNoTransmissionBovineSoldChange(_)}
                />
                <BasicFarmInfo 
                    data={data}
                    access={access}
                    readonly={readonly}
                    validator={validator}
                    handleSellerFarmsChange={(_: IFarmSupplier[]) => props.handleSellerFarmsChange(_)}
                    handleBuyCalfsChange={(_: boolean) => props.handleBuyCalfsChange(_)}
                    farmDetails={farmDetails}
                />
                <FarmTrends 
                    data={data}
                    access={access}
                    readonly={readonly}
                />
                <Environment 
                    data={data}
                    readonly={readonly}
                    validator={validator}
                />
                {/*
                Disable questions per customer request. http://jira.mtech.fi/browse/AN-2099
                <Questions 
                    data={data}
                    readonly={readonly}
                    validator={validator}
                /> */}
                <EndingOfProduction
                    data={data}
                    readonly={readonly}
                    validator={validator}
                    changeProductionEndYear={(_) => props.changeProductionEndYear(_)}
                    currentTargetYear={props.data.TargetYear}
                />
                <Confirmation 
                    data={data}
                    access={access}
                    readonly={readonly}
                    validator={validator}
                    onSave={handleSaveButtonClick}
                />
        </>)}
        </ADialogContent>
    </ADialog>);
}