import { Grid } from "@mui/material";
import { ADialog, ADialogActions, ADialogContent } from "../../../../common/dialog";

interface IProps {
    text: string;
    onConfirm: () => void;
    setVisible: (isVisible: boolean) => void;
}

export default function MidSaveConfirmDialog(props: IProps) {
    return (
        <ADialog open onClose={() => props.setVisible(false)}>
            <ADialogContent size='md'>
                <Grid container style={{paddingLeft: 10}}>
                    {props.text}
                </Grid>
            </ADialogContent>
            <ADialogActions buttons={{
                left: [
                    { 
                        onClick: () => props.setVisible(false), 
                        type: 'cancel' 
                    }
                ],
                right: [
                    {
                        onClick: () => {
                            props.setVisible(false);
                            props.onConfirm();
                        },
                        type: 'save',
                    },
                ],
            }}/>
        </ADialog>
    );
}