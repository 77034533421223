//Interface
import {
    IVariableGroupBovineEnum,
    IVariablesList,
    IVariableTypeEnum,
} from '../interfaces/IBovineVariables';

export const calculateOtherSlaughterVariables = (variables: IVariablesList[]) => {
    let newdataSlaughter = [...variables];

    newdataSlaughter.push(
        {
            Variable: 4,
            Item: [
                {
                    VariableGroupBovine: 1,
                    VariableValue: Math.round(
                        calculateVariableBull4(
                            variables,
                            IVariableTypeEnum.Slaughter
                        )
                    ),
                },
                {
                    VariableGroupBovine: 2,
                    VariableValue: Math.round(
                        calculateVariableHeifer4(
                            variables,
                            IVariableTypeEnum.Slaughter
                        )
                    ),
                },
            ],
        },
        {
            Variable: 5,
            Item: [
                {
                    VariableGroupBovine: 1,
                    VariableValue: calculateVariableBull5(variables, IVariableTypeEnum.Slaughter)
                        .toFixed(1) as unknown as number,
                },
                {
                    VariableGroupBovine: 2,
                    VariableValue: calculateVariableHeifer5(
                        newdataSlaughter,
                        IVariableTypeEnum.Slaughter
                    )
                        .toFixed(1) as unknown as number,
                },
            ],
        }
    );

    return newdataSlaughter;
}

export const calculateOtherPurchaseVariables = (variables: IVariablesList[]) => {
    let newdataPurchase = [...variables];

    newdataPurchase.push(
        {
            Variable: 4,
            Item: [
                {
                    VariableGroupBovine: 1,
                    VariableValue: Math.round(
                        calculateVariableBull4(
                            variables,
                            IVariableTypeEnum.Purchase
                        )
                    ),
                },
                {
                    VariableGroupBovine: 2,
                    VariableValue: Math.round(
                        calculateVariableHeifer4(
                            variables,
                            IVariableTypeEnum.Purchase
                        )
                    ),
                },
            ],
        },
        {
            Variable: 5,
            Item: [
                {
                    VariableGroupBovine: 1,
                    VariableValue: calculateVariableBull5(variables, IVariableTypeEnum.Purchase)
                        .toFixed(1) as unknown as number,
                },
                {
                    VariableGroupBovine: 2,
                    VariableValue: calculateVariableHeifer5(variables, IVariableTypeEnum.Purchase)
                        .toFixed(1) as unknown as number,
                },
            ],
        }
    );

    return newdataPurchase;
}

const calculateVariableBull5 = (data: IVariablesList[], variableType: number) => {
    let calc = 0;
    const calcBull4 = calculateVariableBull4(data, variableType);
    calc = calcBull4 / 30.33;

    return Number(calc);
}

const calculateVariableBull4 = (data: IVariablesList[], variableType: number) => {
    let calc = 0;

    const bull1 = data
        .find((i) => i.Variable === Number('01'))
        ?.Item.find(
            (f) => f.VariableGroupBovine === IVariableGroupBovineEnum.Bull
        )?.VariableValue;

    const bull2 = data
        .find((i) => i.Variable === Number('02'))
        ?.Item.find(
            (f) => f.VariableGroupBovine === IVariableGroupBovineEnum.Bull
        )?.VariableValue;

    const bull3 = data
        .find((i) => i.Variable === Number('03'))
        ?.Item.find(
            (f) => f.VariableGroupBovine === IVariableGroupBovineEnum.Bull
        )?.VariableValue;

    if (bull1 && bull2 && bull3) {
        //prettier-ignore
        if (variableType === IVariableTypeEnum.Slaughter) {
            calc = (bull2 - (bull1 * 40 / 100)) / (bull3 / 1000)
        }
        else if (variableType === IVariableTypeEnum.Purchase) {
            calc = (bull2 - bull1) / (bull3 / 1000)
        }
    }

    return Number(calc) ?? 0;
}

const calculateVariableHeifer5 = (data: IVariablesList[], variableType: number) => {
    let calc = 0;
    const calcHeifer4 = calculateVariableHeifer4(data, variableType);
    calc = calcHeifer4 / 30.33;
    return Number(calc) ?? 0;
}

const calculateVariableHeifer4 = (data: IVariablesList[], variableType: number) => {
    let calc4 = 0;
    const heifer1 = data
        .find((i) => i.Variable === Number('01'))
        ?.Item.find(
            (f) => f.VariableGroupBovine === IVariableGroupBovineEnum.Heifer
        )?.VariableValue;

    const heifer2 = data
        .find((i) => i.Variable === Number('02'))
        ?.Item.find(
            (f) => f.VariableGroupBovine === IVariableGroupBovineEnum.Heifer
        )?.VariableValue;

    const heifer3 = data
        .find((i) => i.Variable === Number('03'))
        ?.Item.find(
            (f) => f.VariableGroupBovine === IVariableGroupBovineEnum.Heifer
        )?.VariableValue;

    if (heifer1 && heifer2 && heifer3) {
        //prettier-ignore

        if (variableType === IVariableTypeEnum.Slaughter) {
            calc4 = (heifer2 - (heifer1 * 40 / 100)) / (heifer3 / 1000);
        }
        else if (variableType === IVariableTypeEnum.Purchase) {
            calc4 = (heifer2 - heifer1) / (heifer3 / 1000)
        }
    }
    return Number(calc4) ?? 0;
}
