import { useState } from 'react';
import { deleteSnellmanPerson } from './publicContactPersonsApi';
import { GUIDType } from '../interfaces/types';
import { useSnackbar } from 'notistack';
import { IFormValidator } from '../core/FormValidator';
import { useTranslation } from 'react-i18next';
import { ResourceTextApplication } from '../core/resources';

import { ADialogButtonsProp, ConfirmDeletionDialog } from '../common/dialog';

export interface DeleSnellmanPersonDialogProps {
    afterDelete: (id: GUIDType) => void;
    id: GUIDType;
    onClose: () => void;
    validator?: IFormValidator;
}

export default function DeleteContactPersonDialog(props: DeleSnellmanPersonDialogProps) {
    const { enqueueSnackbar } = useSnackbar();
    const showMessage = (message: string, variant: 'success' | 'error') => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaPerson']);

    const onDelete = (guid: GUIDType) => {
        if (props.validator?.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }
        deleteSnellmanPerson(guid).then((data) => {
            if (data.status === 200) {
                showMessage(t('AnelmaGeneral:1018'), 'success');
                props.afterDelete(props.id);
                props.onClose();
                return;
            } else {
                showMessage(t('AnelmaGeneral:1019'), 'error');
            }
        });
    };

    return (
        <>
            <ConfirmDeletionDialog
                confirmation={t('AnelmaPerson:1042')}
                open={true}
                onClose={() => props.onClose()}
                onDelete={() => onDelete(props.id as string)}
            />
        </>
    );
}
