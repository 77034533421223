import axios from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/IGeneral';
import { IContactPerson } from '../interfaces/IBusinessEntities';
import { publicContactPersonUrl, publicApiUrl } from '../api/apiEndpoints';
import { NewPublicContactPerson, PublicContactPerson } from '../publicContactPersons/interfaces';

export function searchContactPerson(
    searchType: string,
    searchTerm: string
): Promise<IApiListResult<IContactPerson>> {
    return axios
        .get<IApiListResult<IContactPerson>>(
            `${publicContactPersonUrl}?${searchType}=${searchTerm}`
        )
        .then((r) => r.data);
}

//List contactPersons returns
//for reference interface PublicContactPerson
export function getContactPersons(loggedIn: boolean) {
    const baseUrl = loggedIn ? publicContactPersonUrl : publicApiUrl;
    return axios
        .get<IApiListResult<PublicContactPerson>>(`${baseUrl}/ContactPersonList`)
        .then((d) => d.data);
}

//List snellman ContactPerson returns
//for reference interface IPersonDetails
export function snellmanContactPersons(loggedIn: boolean) {
    const baseUrl = loggedIn ? publicContactPersonUrl : publicApiUrl;
    return axios.get<IApiListResult<IContactPerson>>(`${baseUrl}`).then((d) => d.data);
}

export function updateSnellmanPerson(guid: string, payload: PublicContactPerson) {
    return axios.patch<IApiResult<PublicContactPerson>>(
        `${publicContactPersonUrl}/${guid}`,
        payload
    );
}

export function createSnellmanPerson(payload: NewPublicContactPerson) {
    return axios.post<IApiResult<PublicContactPerson>>(`${publicContactPersonUrl}/`, payload);
}

export function deleteSnellmanPerson(guid: string) {
    return axios.delete<IApiResult<PublicContactPerson>>(`${publicContactPersonUrl}/${guid}`);
}
