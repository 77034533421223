// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../core/resources';
import log from '../../core/log';
import utils from '../../core/utils';
import auth from '../../core/authorization';

// Common
import { ViewTitle } from '../../common/typography';
import ViewLoader from '../../common/ViewLoader';

// Interfaces
import { IFarmDetails } from '../../interfaces/IBusinessEntities';
import {
    AnimalFilterTypeEnum,
    AnimalTypeEnum,
    AnnouncementListDataGridColumnType,
    AnnouncementTypeEnum,
    DialogModeEnum,
    FarmingTypeEnum,
} from '../../interfaces/enums';
import {
    IAnimalSummaryExtraInfo,
    IFarmAnimal,
    IFillingCapacityStatistic,
    IFilteredAnimalList,
    ILatestBovineRegisterDataSyncRequest,
    INasevaResult,
} from '../../interfaces/IAnimal';
import { IAnimalAnnouncement, IUpdateAnnouncementResult } from '../../interfaces/IAnnouncement';
import { ITiltuApiResult } from '../../interfaces/IGeneral';
import { IMainGroup } from '../../interfaces/IGroup';
import { IProductionPlanSummary } from '../../interfaces/IProductionPlan';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import companyApi from '../../api/companyApi';
import animalAnnouncementApi from '../../api/animalAnnouncementApi';
import Api from '../../api/animalListApi';
import AnimalsApi from '../../api/animalsApi';
import NasevaApi from '../../api/nasevaApi';
import groupsApi from '../../api/groupsApi';
import productionPlanApi from '../../api/productionPlanApi';

// Feature
import { ACarouselAdditionalBoxInfo } from './ACarousel';
import AnimalListFilter, { AnimalListFilterProps } from './AnimalListFilter';
import AnimalListGrid, { AnimalListGridProps } from './AnimalListGrid';
import AnnouncementListDataGrid from '../announcements/components/data-grid/announcement-list/AnnouncementListDataGrid';
import {
    isAnimalAnnouncementUpdateRestricted,
    mapAnimalAnnouncementAnimalsToIAnimalSummaryExtraInfo,
    omitBovinePurchaseAnnouncements,
} from '../announcements/helpers/data';
import { getFilteredAnimals } from './helper';
import BovineIntermediationAnnouncement from '../announcements/components/bovine-intermediation-announcement/BovineIntermediationAnnouncement';
import BovinePurchaseAnnouncement from '../announcements/components/bovine-purchase-announcement/BovinePurchaseAnnouncement';
import BovineSlaughterAnnouncement from '../announcements/components/bovine-slaughter-announcement/BovineSlaughterAnnouncement';
import { getAnimalCount, getBovinePurchaseAnimalCount } from '../announcements/helpers/animal';
import { mapAnnouncementResult } from '../announcements/components/bovine-slaughter-announcement/helpers/mapper';
import ManualAnnouncementDialog from '../manual-announcements/components/ManualAnnouncementDialog';
import { checkCanCreateAnimalAnnouncement } from '../shared/helpers/data';
import { GUIDType } from '../../interfaces/types';
import { IAnnouncementListDataGridProps } from '../shared/types/propTypes';
import { Container } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

export interface ParamFilter {
    paramCode: number;
    subParam: number;
}

export default function AnimalList() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine', 'AnelmaLayout']);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [announcementActionLoading, setAnnouncementActionLoading] = useState<boolean>(false);
    const [allAnimalsData, setAllAnimalsData] = useState<IFilteredAnimalList[] | null>(null);
    const [animalData, setAnimalData] = useState<IAnimalSummaryExtraInfo[]>([]);
    const [farmData, setFarmData] = useState<IFarmDetails | null>(null);
    const [animalListFilterProps, setAnimalListFilterProps] =
        useState<AnimalListFilterProps | null>(null);
    const [additionalInfo, setAdditionalInfo] = useState<ACarouselAdditionalBoxInfo[]>([]);
    const [paramsInit, setParamsInit] = useState<boolean>(false);
    const [selectedParamFilter, setSelectedParamFilter] = useState<ParamFilter | null>(null);
    const [filteredAnimalData, setFilteredAnimalData] = useState<IAnimalSummaryExtraInfo[] | null>(
        []
    );
    const [animalListGridProps, setAnimalListGridProps] = useState<AnimalListGridProps | null>(
        null
    );
    const [animalAnnouncementData, setAnimalAnnouncementData] = useState<IAnimalAnnouncement[]>([]);
    const [announcementDialogVisible, setAnnouncementDialogVisible] = useState<boolean>(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState<IAnimalAnnouncement>();
    const [slaughterAnnouncements, setSlaughterAnnouncements] = useState<IAnimalAnnouncement[]>([]);
    const [intermediationAnnouncements, setIntermediationAnnouncements] = useState<
        IAnimalAnnouncement[]
    >([]);
    const [bovinePurchaseAnnouncements, setBovinePurchaseAnnouncements] = useState<
        IAnimalAnnouncement[]
    >([]);
    const [animalAnnouncementCount, setAnimalAnnouncementCount] = useState<number>(0);
    const [slaughterAnnouncementCount, setSlaughterAnnouncementCount] = useState<number>(0);
    const [intermediationAnnouncementCount, setIntermediationAnnouncementCount] =
        useState<number>(0);
    const [bovinePurchaseAnnouncementCount, setBovinePurchaseAnnouncementCount] =
        useState<number>(0);
    const [slaughterAnnouncementAnimalCount, setSlaughterAnnouncementAnimalCount] =
        useState<number>(0);
    const [intermediationAnnouncementAnimalCount, setIntermediationAnnouncementAnimalCount] =
        useState<number>(0);
    const [bovinePurchaseAnnouncementAnimalCount, setBovinePurchaseAnnouncementAnimalCount] =
        useState<number>(0);

    const [lastSync, setLastSync] = useState<ILatestBovineRegisterDataSyncRequest | null>(null);
    const [nasevaVisits, setNasevaVisits] = useState<INasevaResult[] | null>(null);
    const [animalGroups, setAnimalGroups] = useState<IMainGroup[] | null>(null);
    const [fillingStatistic, setFillingStatistic] = useState<IFillingCapacityStatistic | null>(
        null
    );
    const context = useAppSelector((state) => state.context);
    const culture = useAppSelector((state) =>
        state.configuration?.data?.culture?.supportedCultures?.find((i) => i.selected)
    );
    const [canCreateAnimalAnnouncement, setCanCreateAnimalAnnouncement] = useState<boolean | null>(
        null
    );
    const [planSummaries, setPlanSummaries] = useState<IProductionPlanSummary[] | null>(null);
    const [productionPlanCompletedParameters, setProductionPlanCompletedParameters] =
        useState<ParameterValues | null>(null);
    const [nasevaDataLoading, setNasevaDataLoading] = useState<boolean>(true);
    const [chainInformationFound, setChainInformationFound] = useState<boolean>(false);

    const handleParamFilterChange = (filter: ParamFilter) => {
        setSelectedParamFilter(filter);
    };

    useEffect(() => {
        const promises = [paramRepository.load(['AnelmaBovine'])];
        Promise.all(promises).then(
            () => {
                setParamsInit(true);
            },
            () => {
                setParamsInit(false);
                handleShowSnackbarGeneralError();
            }
        );
    }, []);

    useEffect(() => {
        if (context?.data?.currentContext) {
            setIsLoading(true);
            companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    if (response?.Entity) setFarmData(response.Entity as IFarmDetails);
                    else setFarmData(null);
                });

            setIsLoading(false);
        } else setFarmData(null);
    }, [context]);

    useEffect(() => {
        if (farmData?.Id) {
            refreshData();
            fetchGroupsData();
            loadProductionPlans();
        } else {
            setAnimalData([]);
            setAllAnimalsData([]);
            setFilteredAnimalData([]);
            setNasevaVisits([]);
            setAnimalGroups([]);
        }
        initialAnnouncementDataFetch();
    }, [farmData]);

    useEffect(() => {
        if (selectedParamFilter !== null && allAnimalsData) {
            setAnimalData(
                getFilteredAnimals(allAnimalsData, selectedParamFilter) as IAnimalSummaryExtraInfo[]
            );
        }
    }, [selectedParamFilter]);

    useEffect(() => {
        filterAnimals();
    }, [animalData]);

    useEffect(() => {
        if (selectedParamFilter !== null && allAnimalsData) {
            setAnimalData(
                getFilteredAnimals(allAnimalsData, selectedParamFilter) as IAnimalSummaryExtraInfo[]
            );
        }

        const theFlag = 2;
        let info: ACarouselAdditionalBoxInfo[] = [...additionalInfo];

        if (
            allAnimalsData &&
            allAnimalsData.length &&
            allAnimalsData.length > 0 &&
            allAnimalsData.findIndex(
                (filteredList) => filteredList.FilterType === AnimalFilterTypeEnum.WaitingRoom
            ) != -1 &&
            allAnimalsData.find(
                (filteredList) => filteredList.FilterType === AnimalFilterTypeEnum.WaitingRoom
            )?.Animals &&
            allAnimalsData
                .find(
                    (filteredList) => filteredList.FilterType === AnimalFilterTypeEnum.WaitingRoom
                )
                ?.Animals.findIndex((a) => (a.Flags & theFlag) === theFlag) != -1 &&
            info.findIndex((i) => i.boxId == 1) == -1
        ) {
            info.push({
                boxId: 1,
                iconInfo: 'missingPurchaseDate',
            } as ACarouselAdditionalBoxInfo);
            setAdditionalInfo(info);
        } else {
            info = info.filter((i) => i.iconInfo != 'missingPurchaseDate');
            setAdditionalInfo(info);
        }
    }, [allAnimalsData]);

    const refreshData = () => {
        if (farmData?.Id) {
            setIsLoading(true);

            AnimalsApi.getLatestBovineRegisterDataSyncRequest(farmData.Id, true)
                .then((response) => {
                    if (response?.Entity)
                        setLastSync(
                            (response.Entity as ILatestBovineRegisterDataSyncRequest) || null
                        );
                })
                .catch(() => handleShowSnackbarGeneralError());

            NasevaApi.getLatestAnnualVisits(farmData.Id, culture?.value).then((response) => {
                if (!response) {
                    setNasevaDataLoading(false);
                    handleShowSnackbarGeneralError();
                    return;
                }
                setNasevaVisits(response?.Items ?? null);
                setNasevaDataLoading(false);
            });

            if (
                farmData.ProductionLines.findIndex(
                    (p) =>
                        p.Type == AnimalTypeEnum.Bovine &&
                        p.FarmingTypes.findIndex((t) => t == FarmingTypeEnum.Upbringing) != -1
                ) != -1 &&
                auth.canViewFillingStatistic
            ) {
                Api.getFillingStatistic(farmData.Id)
                    .then((response) => {
                        if (response && response.Entity && response.Entity.CanShowFillingStatistic)
                            setFillingStatistic(response.Entity);
                        else setFillingStatistic(null);
                    })
                    .catch(() => handleShowSnackbarGeneralError());
            } else {
                setFillingStatistic(null);
            }

            Api.getFilteredAnimals(farmData.Id)
                .then((response) => {
                    if (response && response?.Items?.length > 0) setAllAnimalsData(response.Items);
                })
                .catch(() => handleShowSnackbarGeneralError())
                .finally(() => setIsLoading(false));
        }
    };

    const filterAnimals = () => {
        let filteredAnimals: IAnimalSummaryExtraInfo[] = [...animalData];

        filteredAnimals.forEach((a, i) => {
            a.Eu4 =
                a.EuIdentifier?.length > 0
                    ? a.EuIdentifier.substring(a.EuIdentifier.length - 6, a.EuIdentifier.length - 2)
                    : null;

            a.AgeMonths = utils.calculateAgeInMonths(moment(), moment(a.BirthDate), 30.33);
        });

        setFilteredAnimalData(filteredAnimals.length > 0 ? filteredAnimals : []);
    };

    useEffect(() => {
        setAnimalListGridProps({
            allAnimalsData: allAnimalsData,
            animalData:
                filteredAnimalData && filteredAnimalData.length > 0 ? filteredAnimalData : [],
            animalGroups: animalGroups,
            selectedParamFilter: selectedParamFilter,
            isLoading: isLoading,
            handleAnimalAnnouncementMapToState: (data: any, type: AnnouncementTypeEnum) =>
                mapNewAnnouncementToState(data, type),
            mapUpdatedSalesTypeToAllAnimalsData: (data: IFarmAnimal[]) =>
                handleUpdatedSalesType(data),
            farmAnnouncements: animalAnnouncementData,
            farmData: farmData ?? null,
            canCreateAnimalAnnouncement: canCreateAnimalAnnouncement ?? null,
            removeAnimal: removeAnimal
        });
    }, [filteredAnimalData, isLoading, selectedParamFilter, animalAnnouncementData]);

    useEffect(() => {
        setAnimalListFilterProps({
            additionalInfo: additionalInfo,
            allAnimalsData: allAnimalsData ?? [],
            allAnnouncementsData: animalAnnouncementData ?? [],
            farmData: farmData ?? null,
            onFilterChange: (filter: ParamFilter) => handleParamFilterChange(filter),
            isLoading: isLoading,
            onIsLoadingChange: (loading: boolean) => setIsLoading(loading),
            lastSync: lastSync,
            nasevaVisits: nasevaVisits,
            fillingStatistic: fillingStatistic,
            onAfterSyncSuccess: refreshData,
            animalAnnouncementCount: slaughterAnnouncementCount + intermediationAnnouncementCount,
            slaughterAnnouncementCount: slaughterAnnouncementCount,
            intermediationAnnouncementCount: intermediationAnnouncementCount,
            bovinePurchaseAnnouncementCount: bovinePurchaseAnnouncementCount,
            slaughterAnnouncementAnimalCount: slaughterAnnouncementAnimalCount,
            intermediationAnnouncementAnimalCount: intermediationAnnouncementAnimalCount,
            bovinePurchaseAnnouncementAnimalCount: bovinePurchaseAnnouncementAnimalCount,
            nasevaDataLoading: nasevaDataLoading,
        });
    }, [
        farmData,
        additionalInfo,
        lastSync,
        isLoading,
        allAnimalsData,
        animalAnnouncementCount,
        slaughterAnnouncementCount,
        bovinePurchaseAnnouncementCount,
        slaughterAnnouncementAnimalCount,
        intermediationAnnouncementAnimalCount,
        bovinePurchaseAnnouncementAnimalCount,
        slaughterAnnouncements,
        intermediationAnnouncements,
        bovinePurchaseAnnouncements,
    ]);

    useEffect(() => {
        let slaughterAnnouncements: IAnimalAnnouncement[] = [];
        let intermediationAnnouncements: IAnimalAnnouncement[] = [];
        let bovinePurchaseAnnouncements: IAnimalAnnouncement[] = [];
        if (animalAnnouncementData !== null) {
            for (const announcement of animalAnnouncementData) {
                switch (announcement.Type as AnnouncementTypeEnum) {
                    case AnnouncementTypeEnum.Slaughter:
                    case AnnouncementTypeEnum.ManualBovineSlaughter:
                        slaughterAnnouncements.push(announcement);
                        break;
                    case AnnouncementTypeEnum.Intermediation:
                    case AnnouncementTypeEnum.ManualBovineIntermediation:
                        intermediationAnnouncements.push(announcement);
                        break;
                    case AnnouncementTypeEnum.BovinePurchase:
                        bovinePurchaseAnnouncements.push(announcement);
                        break;
                }
            }
        }
        setSlaughterAnnouncements(slaughterAnnouncements);
        setIntermediationAnnouncements(intermediationAnnouncements);
        setBovinePurchaseAnnouncements(bovinePurchaseAnnouncements);
    }, [animalAnnouncementData]);

    useEffect(() => {
        if (slaughterAnnouncements.length > 0) {
            setSlaughterAnnouncementCount(slaughterAnnouncements.length);
            setAnimalCountInAnnouncement(slaughterAnnouncements, AnnouncementTypeEnum.Slaughter);
        } else if (slaughterAnnouncements.length === 0) {
            getAnnouncementCounterDefaultValues(AnnouncementTypeEnum.Slaughter);
        }
    }, [slaughterAnnouncements]);

    useEffect(() => {
        if (intermediationAnnouncements.length > 0) {
            setIntermediationAnnouncementCount(intermediationAnnouncements.length);
            setAnimalCountInAnnouncement(
                intermediationAnnouncements,
                AnnouncementTypeEnum.Intermediation
            );
        } else if (intermediationAnnouncements.length === 0) {
            getAnnouncementCounterDefaultValues(AnnouncementTypeEnum.Intermediation);
        }
    }, [intermediationAnnouncements]);

    useEffect(() => {
        if (bovinePurchaseAnnouncements.length > 0) {
            setBovinePurchaseAnnouncementCount(bovinePurchaseAnnouncements.length);
            setAnimalCountInAnnouncement(
                bovinePurchaseAnnouncements,
                AnnouncementTypeEnum.BovinePurchase
            );
        } else if (bovinePurchaseAnnouncements.length === 0) {
            getAnnouncementCounterDefaultValues(AnnouncementTypeEnum.BovinePurchase);
        }
    }, [bovinePurchaseAnnouncements]);

    useEffect(() => {
        setProductionPlanCompletedParameters(
            paramRepository.resource('AnelmaBovine', 'ProductionPlanCompleted')
        );
    }, [paramsInit]);

    useEffect(() => {
        if (planSummaries && productionPlanCompletedParameters)
            setCanCreateAnimalAnnouncement(
                checkCanCreateAnimalAnnouncement(
                    auth,
                    planSummaries,
                    productionPlanCompletedParameters
                )
            );
    }, [planSummaries]);

    const loadProductionPlans = () => {
        setIsLoading(true);
        if (farmData && farmData.Id)
            productionPlanApi.getPlans(farmData.Id).then((response) => {
                if (!response) {
                    enqueueSnackbar(t('#Ongelma tuotantosuunnitelmien latauksessa'), {
                        variant: 'error',
                    });
                    return;
                }
                setPlanSummaries(response.Items);
                setIsLoading(false);
            });
    };

    const handleUpdatedSalesType = (data: IFarmAnimal[]) => {
        if (allAnimalsData !== null) {
            let currentAnimalsData = allAnimalsData;
            for (const changedAnimal of data) {
                for (const dataRow of currentAnimalsData) {
                    for (const animal of dataRow.Animals) {
                        if (changedAnimal.AnimalGUID === animal.Id) {
                            animal.SalesType = changedAnimal.SalesType;
                        }
                    }
                }
            }
            setAllAnimalsData([...currentAnimalsData]);
        }
    };

    const removeAnimal = (animal: IFarmAnimal) => {
        if (!animal || !animal.ValidTo)
            return;

        if (allAnimalsData !== null) {
            let currentAnimalsData = [...allAnimalsData];
            var animalToRemove;

            for (const l of currentAnimalsData) {
                if (l.FilterType === AnimalFilterTypeEnum.All) {
                    animalToRemove = l.Animals.find(a => a.Id === animal.AnimalGUID);
                    break;
                }
            }

            for (const l of currentAnimalsData) {
                if (l.FilterType === AnimalFilterTypeEnum.Deceased) {
                    if (animalToRemove) {
                        animalToRemove.Flags = 0
                        l.Animals.push(animalToRemove);
                        l.AnimalCount += 1;
                    }
                }
                else {
                    l.Animals = l.Animals.filter(a => a.Id !== animal.AnimalGUID);
                    l.AnimalCount = l.Animals.length
                }
            }

            setAllAnimalsData(currentAnimalsData);
        }
    }

    const getAnnouncementCounterDefaultValues = (type: AnnouncementTypeEnum) => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
            case AnnouncementTypeEnum.ManualBovineSlaughter:
                setSlaughterAnnouncementCount(0);
                setSlaughterAnnouncementAnimalCount(0);
                break;
            case AnnouncementTypeEnum.Intermediation:
            case AnnouncementTypeEnum.ManualBovineIntermediation:
                setIntermediationAnnouncementCount(0);
                setIntermediationAnnouncementAnimalCount(0);
                break;
            case AnnouncementTypeEnum.BovinePurchase:
                setBovinePurchaseAnnouncementCount(0);
                setBovinePurchaseAnnouncementAnimalCount(0);
                break;
        }
    };

    const setAnimalCountInAnnouncement = (
        animalAnnouncement: IAnimalAnnouncement[],
        type: AnnouncementTypeEnum
    ): number => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
            case AnnouncementTypeEnum.ManualBovineSlaughter:
                setSlaughterAnnouncementAnimalCount(getAnimalCount(animalAnnouncement));
                break;
            case AnnouncementTypeEnum.Intermediation:
            case AnnouncementTypeEnum.ManualBovineIntermediation:
                setIntermediationAnnouncementAnimalCount(getAnimalCount(animalAnnouncement));
                break;
            case AnnouncementTypeEnum.BovinePurchase:
                setBovinePurchaseAnnouncementAnimalCount(
                    getBovinePurchaseAnimalCount(animalAnnouncement)
                );
                break;
        }
        return 0;
    };

    const initialAnnouncementDataFetch = async () => {
        if (farmData !== null) {
            let farmGuid = String(farmData.Id);
            await animalAnnouncementApi
                .getAnimalAnnouncementsFromAnelma(farmGuid)
                .then((response) => {
                    if (!response) {
                        log(
                            'info',
                            `getAnimalAnnouncementsFromAnelma response returned no results.`
                        );
                        return;
                    }
                    let filtered = response.Items.filter((a) => a.Status !== 'LO'
                        && a.Batches.findIndex(b => b.BatchStatus != 'LO') != -1);
                    setAnimalAnnouncementData(filtered);
                });
        }
    };

    const fetchGroupsData = () => {
        if (farmData?.Id) {
            setIsLoading(true);
            groupsApi
                .getGroups(String(farmData.Id))
                .then((response) => {
                    if (response?.Items) {
                        setAnimalGroups(response.Items);
                    }
                })
                .catch((err) => {
                    log('error', `groupsApi.getGroups error: ${err}`);
                    return;
                })
                .finally(() => setIsLoading(false));
        }
    };

    const handleAnnouncementDialogVisible = (
        item: IAnimalAnnouncement,
        chainInformationFound: boolean
    ) => {
        setChainInformationFound(chainInformationFound);
        setSelectedAnnouncement(item);
        setAnnouncementDialogVisible(true);
    };

    const handleAnnouncementDeletionFromTiltu = (announcementNumber: number) => {
        setIsLoading(true);
        setAnimalAnnouncementData(
            animalAnnouncementData.filter((_) => _.Number !== announcementNumber)
        );
        setIsLoading(false);
    };

    const handleAnnouncementDeletionFromTiltuById = (announcementId: GUIDType) => {
        setIsLoading(true);
        setAnimalAnnouncementData(animalAnnouncementData.filter((_) => _.Id !== announcementId));
        setIsLoading(false);
    };

    const mapAnimalAnnouncementBatchToIAnimalSummary = (
        selectionModel: GridRowSelectionModel
    ): IAnimalSummaryExtraInfo[] => {
        if (selectionModel.length > 0) {
            let selectedAnimalAnnouncementData: IAnimalAnnouncement[] = [];
            for (const selectionRow of selectionModel) {
                for (const announcement of [...animalAnnouncementData]) {
                    if ((selectionRow.valueOf() as string) === announcement.Id)
                        selectedAnimalAnnouncementData.push(announcement);
                }
            }

            const tempAllAnimas =
                (allAnimalsData?.find((a) => a.FilterType === AnimalFilterTypeEnum.All)
                    ?.Animals as IAnimalSummaryExtraInfo[]) ?? [];

            return mapAnimalAnnouncementAnimalsToIAnimalSummaryExtraInfo(
                selectedAnimalAnnouncementData,
                tempAllAnimas
            );
        }
        return [];
    };

    const handleSelectedAnnouncement = (selectionModel: GridRowSelectionModel) => {
        setAnimalListGridProps({
            allAnimalsData: allAnimalsData,
            animalData: mapAnimalAnnouncementBatchToIAnimalSummary(selectionModel),
            animalGroups: animalGroups,
            selectedParamFilter: selectedParamFilter,
            isLoading: isLoading,
            handleAnimalAnnouncementMapToState: (data: any, type: AnnouncementTypeEnum) =>
                mapNewAnnouncementToState(data, type),
            mapUpdatedSalesTypeToAllAnimalsData: (data: IFarmAnimal[]) =>
                handleUpdatedSalesType(data),
            farmAnnouncements: animalAnnouncementData,
            farmData: farmData ?? null,
            canCreateAnimalAnnouncement: canCreateAnimalAnnouncement ?? null,
            removeAnimal: removeAnimal,
        });
    };

    const handleAnnouncementReload = (id: GUIDType) => {
        if (farmData?.Id)
            animalAnnouncementApi.getAnimalAnnouncementsFromAnelma(farmData?.Id).then((res) => {
                for (let item of res?.Items || []) {
                    if (item.Id === id) {
                        const index = animalAnnouncementData.findIndex((x) => x.Id === id);
                        if (index !== -1) {
                            animalAnnouncementData[index] = item;
                            setAnimalAnnouncementData([...animalAnnouncementData]);
                        } else {
                            setAnimalAnnouncementData([item, ...animalAnnouncementData]);
                        }
                        return;
                    }
                }
            });
    };

    const updateAnnouncementState = (animalAnnouncement: IAnimalAnnouncement) => {
        const animalAnnouncementBeforeUpdate = animalAnnouncementData.filter(
            (_) =>
                _.Number ===
                [...animalAnnouncementData].find((data) => {
                    return data.Number === animalAnnouncement.Number;
                })?.Number
        );
        // [AN-1279] Preserve some of the current field values. Made because of Created-value is not returned from API/Tiltu.
        const updatedObject = animalAnnouncementBeforeUpdate.map((_) => {
            if (_.Number === animalAnnouncement.Number)
                return {
                    Id: _.Id, // Does not change
                    Number: _.Number, // Does not change
                    Type: animalAnnouncement.Type,
                    Created: _.Created, // Cannot change
                    HoldingSiteId: animalAnnouncement.HoldingSiteId,
                    Info: animalAnnouncement.Info,
                    Status: animalAnnouncement.Status,
                    Batches: animalAnnouncement.Batches,
                };
        });
        const updatedAnimalAnnouncementData = animalAnnouncementData.filter(
            (_) =>
                _.Number !==
                [...animalAnnouncementData].find((data) => {
                    return data.Number === updatedObject[0]?.Number;
                })?.Number
        );
        updatedAnimalAnnouncementData.push(updatedObject[0] as IAnimalAnnouncement);
        setAnimalAnnouncementData(updatedAnimalAnnouncementData);
    };

    const mapNewAnnouncementToState = (data: any, type: AnnouncementTypeEnum) => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
                setAnimalAnnouncementData((previousState) => [
                    ...previousState,
                    data as IAnimalAnnouncement,
                ]);
                break;
            case AnnouncementTypeEnum.Intermediation:
                setAnimalAnnouncementData((previousState) => [
                    ...previousState,
                    data as IAnimalAnnouncement,
                ]);
                break;
            case AnnouncementTypeEnum.BovinePurchase:
                setAnimalAnnouncementData((previousState) => [
                    ...previousState,
                    data as IAnimalAnnouncement,
                ]);
                break;
            case AnnouncementTypeEnum.ManualBovineSlaughter:
                setAnimalAnnouncementData((previousState) => [
                    ...previousState,
                    data as IAnimalAnnouncement,
                ]);
                break;
            case AnnouncementTypeEnum.ManualBovineIntermediation:
                setAnimalAnnouncementData((previousState) => [
                    ...previousState,
                    data as IAnimalAnnouncement,
                ]);
                break;
        }
    };

    // This is used when mofications are made to the announcement
    const handleAnnouncementUpdate = (
        data: ITiltuApiResult<IUpdateAnnouncementResult>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => {
        updateAnnouncementState(mapAnnouncementResult(data.Entity, holdingSiteId, type));
    };

    const prepareAnimalsDataForAnimalAnnouncementEditDialog = () => {
        return allAnimalsData !== null
            ? (allAnimalsData[0].Animals as IAnimalSummaryExtraInfo[])
            : null;
    };

    const handleShowSnackbarGeneralError = () => {
        enqueueSnackbar(t('AnelmaGeneral:1020'), {
            variant: 'error',
        });
    };

    const filteredAnnouncementData = useCallback(() => {
        if (!selectedParamFilter) return [];

        /* Bovine purchase animal announcement data main view and dropdown selection based view */
        if (
            selectedParamFilter.paramCode === 6 &&
            (selectedParamFilter.subParam === 6 || selectedParamFilter.subParam === 23)
        )
            return bovinePurchaseAnnouncements;

        if (selectedParamFilter.paramCode === 5) {
            /* 'Myynti-ilmoitukset' carousel view. Consists of slaughter and bovine intermediation announcements */
            if (selectedParamFilter.subParam === 5)
                return omitBovinePurchaseAnnouncements(animalAnnouncementData);

            /* Slaughter animal announcement data only */
            if (selectedParamFilter.subParam === 17) return slaughterAnnouncements;

            /* Intermediation animal announcement data only */
            if (selectedParamFilter.subParam === 22) return intermediationAnnouncements;
        }
        return null;
    }, [
        selectedParamFilter,
        selectedParamFilter?.paramCode,
        selectedParamFilter?.subParam,
        animalAnnouncementData,
        bovinePurchaseAnnouncements,
        intermediationAnnouncements,
    ]);

    const announcementListGridColumnType = useCallback(() => {
        if (
            selectedParamFilter?.paramCode === 6 &&
            (selectedParamFilter.subParam === 6 || selectedParamFilter.subParam === 23)
        )
            return AnnouncementListDataGridColumnType.BovinePurchase;

        if (selectedParamFilter?.paramCode === 5 && selectedParamFilter.subParam === 22)
            return AnnouncementListDataGridColumnType.BovineIntermediation;

        return AnnouncementListDataGridColumnType.Default;
    }, [selectedParamFilter, selectedParamFilter?.paramCode, selectedParamFilter?.subParam]);

    const [announcementListGridProps, setAnnouncementListGridProps] =
        useState<IAnnouncementListDataGridProps>();
    useEffect(() => {
        const data = filteredAnnouncementData();
        if (!data) {
            setAnnouncementListGridProps(undefined);
            return;
        }
        setAnnouncementListGridProps({
            handleModification: (item, chainInformationFound) =>
                handleAnnouncementDialogVisible(
                    data.find((x) => x.Id === item.Id) as IAnimalAnnouncement,
                    chainInformationFound
                ),
            handleAnnouncementDeletionFromTiltu: (announcementNumber) => {
                handleAnnouncementDeletionFromTiltu(announcementNumber);
            },
            handleAnnouncementDeletionFromTiltuById: (announcementId) => {
                handleAnnouncementDeletionFromTiltuById(announcementId);
            },
            handleAnnouncementReload: handleAnnouncementReload,
            data: data,
            handleSelectedAnnouncement: handleSelectedAnnouncement,
            columnType: announcementListGridColumnType(),
            canCreateAnimalAnnouncement: canCreateAnimalAnnouncement,
        });
    }, [
        selectedParamFilter,
        selectedParamFilter?.paramCode,
        selectedParamFilter?.subParam,
        animalAnnouncementData,
        bovinePurchaseAnnouncements,
        intermediationAnnouncements,
    ]);

    return !paramsInit ? (
        <ViewLoader />
    ) : (
        <Container id='anelma-animals-animal-list-container'>
            <ViewTitle>{t('AnelmaLayout:1010')}</ViewTitle>

            {animalListFilterProps !== null && (
                <AnimalListFilter {...animalListFilterProps} data-robot-id={'animal-list-filter'} />
            )}
            {selectedParamFilter && (
                <>
                    {isLoading && <ViewLoader />}
                    {!isLoading && announcementListGridProps && (
                        <AnnouncementListDataGrid
                            key={'announcement-list'}
                            {...announcementListGridProps}
                            data-robot-id={'animal-list-announcement-list'}
                        />
                    )}
                    {!isLoading && animalListGridProps && (
                        <AnimalListGrid
                            key={'animal-list'}
                            {...animalListGridProps}
                            data-robot-id={'animal-list-grid'}
                        />
                    )}
                </>
            )}

            {announcementDialogVisible && (
                <>
                    {selectedAnnouncement?.Type === AnnouncementTypeEnum.BovinePurchase && (
                        <BovinePurchaseAnnouncement
                            data-robot-id={
                                'bovine-purchase-announcement-' + selectedAnnouncement.Type
                            }
                            data={selectedAnnouncement}
                            onClose={() => setAnnouncementDialogVisible(false)}
                            mode={DialogModeEnum.Edit}
                            processAnnouncementSave={handleAnnouncementUpdate}
                        />
                    )}
                    {selectedAnnouncement?.Type === AnnouncementTypeEnum.Slaughter && (
                        <BovineSlaughterAnnouncement
                            data-robot-id={
                                'bovine-slaughter-announcement-' + selectedAnnouncement.Type
                            }
                            data={selectedAnnouncement}
                            onClose={() => setAnnouncementDialogVisible(false)}
                            mode={DialogModeEnum.Edit}
                            selectedAnimals={null}
                            animals={prepareAnimalsDataForAnimalAnnouncementEditDialog()}
                            processAnnouncementSave={handleAnnouncementUpdate}
                            mapUpdatedSalesTypeToAllAnimalsData={handleUpdatedSalesType}
                            loading={announcementActionLoading}
                            readOnly={isAnimalAnnouncementUpdateRestricted(selectedAnnouncement)}
                            chainInformationFound={chainInformationFound}
                        />
                    )}
                    {selectedAnnouncement?.Type === AnnouncementTypeEnum.Intermediation && (
                        <BovineIntermediationAnnouncement
                            data-robot-id={
                                'bovine-intermediation-announcement-' + selectedAnnouncement.Type
                            }
                            data={selectedAnnouncement}
                            onClose={() => setAnnouncementDialogVisible(false)}
                            mode={DialogModeEnum.Edit}
                            selectedAnimals={null}
                            animals={prepareAnimalsDataForAnimalAnnouncementEditDialog()}
                            processAnnouncementSave={handleAnnouncementUpdate}
                            mapUpdatedSalesTypeToAllAnimalsData={handleUpdatedSalesType}
                            loading={announcementActionLoading}
                            readOnly={isAnimalAnnouncementUpdateRestricted(selectedAnnouncement)}
                            chainInformationFound={chainInformationFound}
                        />
                    )}
                    {selectedAnnouncement?.Type === AnnouncementTypeEnum.ManualBovineSlaughter && (
                        <ManualAnnouncementDialog
                            data-robot-id={
                                'bovine-manual-slaughter-announcement-' + selectedAnnouncement.Type
                            }
                            announcementData={selectedAnnouncement}
                            announcementType={AnnouncementTypeEnum.ManualBovineSlaughter}
                            mode={DialogModeEnum.Edit}
                            onClose={() => setAnnouncementDialogVisible(false)}
                            processAnnouncementSave={handleAnnouncementUpdate}
                            mapUpdatedSalesTypeToAllAnimalsData={handleUpdatedSalesType}
                            animals={prepareAnimalsDataForAnimalAnnouncementEditDialog()}
                            withChainInformation={false}
                            readOnly={isAnimalAnnouncementUpdateRestricted(selectedAnnouncement)}
                            chainInformationFound={chainInformationFound}
                        />
                    )}
                    {selectedAnnouncement?.Type ===
                        AnnouncementTypeEnum.ManualBovineIntermediation && (
                        <ManualAnnouncementDialog
                            data-robot-id={
                                'bovine-manual-intermediation-announcement-' +
                                selectedAnnouncement.Type
                            }
                            announcementData={selectedAnnouncement}
                            announcementType={AnnouncementTypeEnum.ManualBovineIntermediation}
                            mode={DialogModeEnum.Edit}
                            onClose={() => setAnnouncementDialogVisible(false)}
                            processAnnouncementSave={handleAnnouncementUpdate}
                            mapUpdatedSalesTypeToAllAnimalsData={handleUpdatedSalesType}
                            animals={prepareAnimalsDataForAnimalAnnouncementEditDialog()}
                            withChainInformation={false}
                            readOnly={isAnimalAnnouncementUpdateRestricted(selectedAnnouncement)}
                            chainInformationFound={chainInformationFound}
                        />
                    )}
                </>
            )}
        </Container>
    );
}
