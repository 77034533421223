// MUI
import { styled } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../../../core/resources';
import auth, { Authorization } from '../../../../core/authorization';

// Common
import { useTranslation } from 'react-i18next';
import { ADefaultButton } from '../../../../common/buttons';

// Interfaces
import { AnnouncementTypeEnum, DialogModeEnum } from '../../../../interfaces/enums';

// Feature
import {
    canCreateOrUpdateSlaughterAnnouncementWithoutChainInformation,
    canCreateSlaughterAnnouncement,
    canViewBovineSlaughterAnnouncementDialog,
    canViewBovineSlaughterAnnouncementSaveAsAdmin,
} from '../../../announcements/components/bovine-slaughter-announcement/helpers/bovineSlaughterAnnouncementAccessRights';

export interface IAdditionalDialogActionButtonsProps {
    announcementType: AnnouncementTypeEnum;
    mode: DialogModeEnum;
    handleSalesTypeDialogVisibility: (value: boolean) => void;
    handleCollectWeekDialogVisibility: (value: boolean) => void;
    isLoading?: boolean;
    readOnly?: boolean;
}

const Wrapper = styled('div')(({ theme }) => ({
    flex: '0 0 auto',
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

export default function AdditionalDialogActionButtons(props: IAdditionalDialogActionButtonsProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);

    const parentDialogLoading = () => {
        return props.isLoading !== undefined && props.isLoading === true ? true : false;
    };

    const collectWeekModificationVisibility = (auth: Authorization) => {
        return props.mode === DialogModeEnum.Create ? true : false;
    };

    const salesTypeDialogActionIsDisabled = (): boolean => {
        if (parentDialogLoading() || (props.readOnly && props.readOnly === true)) return true;
        if (
            props.announcementType === AnnouncementTypeEnum.Intermediation &&
            auth.canViewBovineBrokerSales &&
            ((props.mode === DialogModeEnum.Create && auth.canCreateBovineBrokerSales) ||
                (props.mode === DialogModeEnum.Edit && auth.canModifyBovineBrokerSales))
        ) {
            return false;
        } else if (props.announcementType === AnnouncementTypeEnum.Slaughter) {
            if (canViewBovineSlaughterAnnouncementSaveAsAdmin(auth)) {
                return canCreateOrUpdateSlaughterAnnouncementWithoutChainInformation(auth);
            } else
                return canCreateSlaughterAnnouncement(auth)
                    ? true
                    : canViewBovineSlaughterAnnouncementDialog(auth);
        }
        return true;
    };

    const collectWeekModificationBtnVisibility = (): boolean => {
        if (props.readOnly && props.readOnly === true) return true;
        if (
            canViewBovineSlaughterAnnouncementSaveAsAdmin(auth) &&
            !collectWeekModificationVisibility(auth)
        ) {
            return canCreateOrUpdateSlaughterAnnouncementWithoutChainInformation(auth);
        } else
            return collectWeekModificationVisibility(auth) || canCreateSlaughterAnnouncement(auth)
                ? true
                : canViewBovineSlaughterAnnouncementDialog(auth);
    };

    return (
        <Wrapper>
            <ADefaultButton
                onClick={() => props.handleSalesTypeDialogVisibility(true)}
                children={t('AnelmaBovine:1098')}
                disabled={salesTypeDialogActionIsDisabled()}
            ></ADefaultButton>
            <ADefaultButton
                onClick={() => props.handleCollectWeekDialogVisibility(true)}
                children={t('AnelmaBovine:1099')}
                disabled={collectWeekModificationBtnVisibility()}
                style={{ marginLeft: '8px' }}
            ></ADefaultButton>
        </Wrapper>
    );
}
