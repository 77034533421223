import ContentTypography, { ContentTypographyVariant } from './ContentTypography';

interface ContentHeadingProps {
    data: {
        level: number;
        text: string;
    };
    id: string;
}

export default function ContentHeading(props: ContentHeadingProps) {
    let variant: ContentTypographyVariant = 'body1';
    if (props.data.level === 1) variant = 'h1';
    else if (props.data.level === 2) variant = 'h2';
    else if (props.data.level === 3) variant = 'h3';
    else if (props.data.level === 4) variant = 'h4';
    else if (props.data.level === 5) variant = 'h5';
    else if (props.data.level === 6) variant = 'h6';

    return <ContentTypography key={props.id} text={props.data.text} variant={variant} />;
}
