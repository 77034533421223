import { IVariableTypeEnum } from "../../interfaces/IBovineVariables";

export const getBoundaryValue = (type: IVariableTypeEnum, value: number): string => {
    switch (type) {
        case IVariableTypeEnum.Slaughter:
            return resolveBoundaryForSlaughterVariable(value);
        case IVariableTypeEnum.Purchase:
            return resolveBoundaryForPurchaseVariable(value);
        default:
            return '';
    }
};

const resolveBoundaryForSlaughterVariable = (value: number): string => {
    if (value === 1) return 'AnelmaVariables:BirthWeight';
    else if (value === 2) return 'AnelmaVariables:SlaughterWeight';
    else if (value === 3) return 'AnelmaVariables:DailyGainFromBirth';
    return '';
};

const resolveBoundaryForPurchaseVariable = (value: number): string => {
    if (value === 1) return 'AnelmaVariables:PurchaseWeight';
    else if (value === 2) return 'AnelmaVariables:DeliveryWeight';
    else if (value === 3) return 'AnelmaVariables:DailyGainFromDelivery';
    return '';
};
