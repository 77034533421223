// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Done, Warning } from '@mui/icons-material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AIconButtonBaseProps, ADefaultIconButton } from '../buttons';

export interface AWarningIconButtonProps extends AIconButtonBaseProps {}

export default function AWarningIconButton(props: AWarningIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1075');

    return (
        <ADefaultIconButton
            {...buttonProps}
            icon={<Warning style={{ color: '#d32f2f' }} />}
            tooltip={tooltip2}
        />
    );
}
