// MUI
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { styled } from '@mui/material';

// Common
import PersonName from '../../../common/PersonName';

// Interfaces
import { GUIDType } from '../../../interfaces/types';
import { CSSProperties } from 'react';

export interface IRecipientProps {
    styleClassName: string;
    personGuid: GUIDType;
}

const CreateNewRecipientsAdd = styled('div')(({ theme }) => ({
    paddingTop: '5px',
    paddingLeft: '35px',
    display: 'inline-block',
}));

const AddNewRecipient = styled('div')(({ theme }) => ({
    paddingTop: '5px',
    paddingRight: '35px',
    display: 'inline-block',
}));

const Wrapper = styled('div')(({ theme }) => ({
    paddingTop: '5px',
    paddingLeft: '1.5em',
    textAlign: 'center',
}));

export default function Recipient(props: IRecipientProps) {
    const personPinStyle: CSSProperties = {
        margin: '0 auto',
        display: 'block',
        fontSize: '30px', // To render icon larger or smaller
    };

    return props.styleClassName === 'create' ? (
        <CreateNewRecipientsAdd>
            <PersonPinIcon style={personPinStyle} />
            <PersonName personId={props.personGuid} />
        </CreateNewRecipientsAdd>
    ) : props.styleClassName === 'add' ? (
        <AddNewRecipient>
            <PersonPinIcon style={personPinStyle} />
            <PersonName personId={props.personGuid} />
        </AddNewRecipient>
    ) : (
        <Wrapper>
            <PersonPinIcon style={personPinStyle} />
            <PersonName personId={props.personGuid} />
        </Wrapper>
    );
}
