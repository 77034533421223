// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Typography } from '@mui/material';

// Core
import { paramRepository, ParameterValues, ResourceTextApplication } from '../../core/resources';

// Common
import { InfoBlock, InfoBlockProps } from '../../common/typography';

// Interfaces
import { CompanyEntityTypeEnum } from '../../interfaces/enums';
import { IContactPerson } from '../../interfaces/IBusinessEntities';

// Feature
import { FarmInfoSectionProps } from './FarmInfo';

export default function PersonelInfo(props: FarmInfoSectionProps) {
    const { data, getParamText } = props;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    const [companyRoles, setCompanyRoles] = useState<ParameterValues>([]);

    useEffect(() => {
        if (!data) setCompanyRoles([]);
        else if (data.CompanyType === CompanyEntityTypeEnum.Farm)
            setCompanyRoles(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
        else if (data.CompanyType === CompanyEntityTypeEnum.TrafficContractor)
            setCompanyRoles(paramRepository.resource('AnelmaGeneral', 'LogisticsTitles'));
        else if (data.CompanyType === CompanyEntityTypeEnum.Snellman)
            setCompanyRoles(paramRepository.resource('AnelmaGeneral', 'SnellmanTitles'));
    }, [data]);

    const defaultPerson = data.ContactPersons.find((i) => i.IsDefault);
    const otherPersons = data.ContactPersons.filter((i) => !i.IsDefault);

    const formatPerson = (person?: IContactPerson): string => {
        if (!person) return '';
        if (!person.Roles.length) return person.Name;
        const roles = person.Roles.map((r) => getParamText(companyRoles, r.Type));
        return `${person.Name} (${roles.join(', ')})`;
    };

    return (
        <div data-robot-id={'personel-info'}>
            <Typography variant='h3'>{t('AnelmaCompany:1039')}</Typography>

            <InfoBlock label={t('AnelmaCompany:1125')} value={formatPerson(defaultPerson)} />

            {otherPersons.map((person, index) => {
                const props: InfoBlockProps = {
                    // here we need to use some other name for InfoBlock key prop
                    // otherwise getting
                    // Warning: InfoBlock: `key` is not a prop.Trying to access it will result in `undefined` being returned. If you need to access the same value within the child component, you should pass it as a different prop.
                    elementKey: `contact-person-${person.Id}`,
                    label: '',
                    value: formatPerson(person),
                };

                return (
                    <div
                        key={'info-block-personel-key-' + index}
                        data-robot-id={'info-block-personel-' + index}
                    >
                        <InfoBlock {...props} />{' '}
                    </div>
                );
            })}
        </div>
    );
}
