import ApiBase from './ApiBase';
import axios from 'axios';

// MUI
// Core
// Common
// Interfaces
import { ICompanySummary } from '../interfaces/IBusinessEntities';
import { IApiResult } from '../interfaces/IGeneral';
import { IPersonLoginSummary } from '../interfaces/utility/IPersonLoginSummary';

// Store
// API
import { utilityUrl } from './apiEndpoints';

class UtilityApi extends ApiBase {
    getHostingInfo() {
        return axios
            .get<IApiResult<ICompanySummary>>(`${utilityUrl}/hosting-company-info`)
            .then((d) => d.data);
    }

    resolveRoleMembers(roleName: string) {
        return this.getList<IPersonLoginSummary>(`${utilityUrl}/resolve-role-members/${roleName}`);
    }
}

export default new UtilityApi();
