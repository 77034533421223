import { IMainMenuItem } from '../mainMenu/IMainMenu';

import { RoutePaths } from '../../../../interfaces/enums';

export const admin: IMainMenuItem = {
    itemGroups: [
        {
            headerCode: 1023,
            items: [
                {
                    href: RoutePaths.ManagementPersonSearch,
                    resource: 'AnelmaPerson',
                    scope: 'LIST',
                    textCode: 1002,
                },
                {
                    href: RoutePaths.ManagementCompanySearch,
                    resource: 'AnelmaCompanyAdmin',
                    scope: 'LIST',
                    textCode: 1003,
                },
                {
                    href: RoutePaths.ManagementAccessRights,
                    resource: 'AnelmaAccessRights',
                    scope: 'LIST',
                    textCode: 1006,
                },
            ],
        },
        {
            headerCode: 1024,
            items: [
                {
                    href: RoutePaths.ManagementLogs,
                    resource: 'AnelmaAudit',
                    scope: 'LIST',
                    textCode: 1007,
                },
            ],
        },
        {
            headerCode: 1022,
            items: [
                // {
                //     resource: 'AnelmaUnimplemented',
                //     scope: 'LIST',
                //     text: '#Yhteydenotot',
                // },
                // {
                //     href: RoutePaths.GeneralFeedback,
                //     resource: 'AnelmaFeedbackAdmin',
                //     scope: 'LIST',
                //     textCode: 1013,
                // },
                {
                    href: RoutePaths.ManagementBulletin,
                    resource: 'AnelmaBulletin',
                    scope: 'LIST',
                    textCode: 1004,
                },
                {
                    href: RoutePaths.ManagementSearchParameters,
                    resource: 'AnelmaCommunicationGroups',
                    scope: 'LIST',
                    textCode: 1012,
                },
                {
                    href: RoutePaths.ManagementShortMessageService,
                    resource: 'AnelmaTextMessage',
                    scope: 'LIST',
                    textCode: 1018,
                },
            ],
        },
        {
            headerCode: 1025,
            items: [
                {
                    href: RoutePaths.DocumentsInfo,
                    resource: 'AnelmaInstructionFiles',
                    scope: 'LIST',
                    textCode: 1049,
                },
                {
                    href: RoutePaths.DocumentsNews,
                    resource: 'AnelmaNewsFiles',
                    scope: 'LIST',
                    textCode: 1054,
                },
                {
                    href: RoutePaths.GeneralFarmVisits,
                    resource: 'AnelmaFarmVisitAdmin',
                    scope: 'LIST',
                    textCode: 1029,
                },
            ],
        },
        {
            headerCode: 1021,
            items: [
                {
                    href: RoutePaths.ResourceManagement,
                    externalLink: true,
                    resource: 'AnelmaResourceTexts',
                    scope: 'LIST',
                    textCode: 1001,
                },
                {
                    href: RoutePaths.ManagementContentEditor,
                    resource: 'AnelmaContentProduction',
                    scope: 'LIST',
                    textCode: 1032,
                },
                {
                    href: RoutePaths.ManagementEvents,
                    resource: 'AnelmaEvents',
                    scope: 'LIST',
                    textCode: 1020,
                },
                {
                    href: RoutePaths.ManagementSlaughterVariables,
                    resource: 'AnelmaVariablesAdmin',
                    scope: 'LIST',
                    textCode: 1063,
                },
            ],
        },
    ],
    textCode: 1000,
};
