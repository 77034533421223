// Libraries
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

// MUI

// Core
import { ResourceTextApplication } from '../../../core/resources';
import FormValidator from '../../../core/FormValidator';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../../common/dialog';
import { ATextInput } from '../../../common/inputs';
import { AAddButton, ADeleteIconButton } from '../../../common/buttons';

// Interfaces
import { EmptyGUID, GUIDType } from '../../../interfaces/types';
import { IMainGroup, ISubGroup } from '../../../interfaces/IGroup';
// Store
import { useAppSelector } from '../../../store/hooks';
// API
import groupsApi from '../../../api/groupsApi';
import companyApi from '../../../api/companyApi';
import authorization from '../../../core/authorization';
import { Grid } from '@mui/material';

export interface IGroupDialogProps {
    onClose: () => void;
    mode: string;
    data: IMainGroup;
    existingGroups: IMainGroup[];
}

export default function AddGroupDialog(props: IGroupDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine', 'AnelmaSolmuErrors']);

    const validator = new FormValidator();
    const { enqueueSnackbar } = useSnackbar();

    const auth = authorization;

    const [mainGroup, setMainGroup] = useState<IMainGroup>({ Name: '', Id: '', ChildGroups: [] });
    const [subGroups, setSubGroups] = useState<ISubGroup[]>([{ Name: '', Id: '', Animals: [] }]);
    const context = useAppSelector((state) => state.context);
    const [farmGuid, setFarmGuid] = useState<GUIDType>('');
    const [isLoading, setIsLoading] = useState<boolean>();

    useEffect(() => {
        if (context.data.currentContext) {
            setIsLoading(true);
            companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    setFarmGuid(response?.Entity.Id || '');
                });

            setIsLoading(false);
        } else setFarmGuid('');
    }, [context]);

    useEffect(() => {
        if (props.mode === 'edit') {
            //Fill main groups with existing data
            setMainGroup(props.data);

            //Fill sub groups with existing data
            setSubGroups(props.data.ChildGroups);
        }
    }, [props.data]);

    const addNewSubGroup = () => {
        setSubGroups((previousState) => [...previousState, { Id: '', Name: '', Animals: [] }]);
    };

    const deleteDisabled = (group: ISubGroup, index: number) => {
        if (subGroups.length === 1 || group.Animals.length > 0 || !auth.canDeleteGrouping) {
            return true;
        } else {
            return false;
        }
    };

    const updateSubGroups = (index: number, value: string) => {
        const newValues = [...subGroups];
        newValues[index].Name = value;
        setSubGroups(newValues);
    };

    const updateMainGroup = (value: string) => {
        const newValues = mainGroup;
        newValues.Name = value;
        setMainGroup(newValues);
    };

    const deleteSubGroup = (index: number) => {
        setSubGroups((previousState) => [...previousState.filter((_, i) => i !== index)]);
    };

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => saveGroups(),
                type: 'save',
                disabled: !auth.canUpdateGrouping || !auth.canCreateGrouping,
            },
        ],
    };

    const saveGroups = () => {
        if (props.mode === 'add') {
            let subGroupsToSave = [];
            let mainGroupsToCheck = props.existingGroups.filter((m) => m.Name === mainGroup.Name);

            if (mainGroupsToCheck.length > 0) {
                enqueueSnackbar(t('AnelmaBovine:1113'), {
                    variant: 'error',
                });
                return;
            }

            for (let i = 0; i < subGroups.length; i++) {
                subGroupsToSave.push({ name: subGroups[i].Name });
            }

            if (farmGuid === '' || farmGuid === EmptyGUID) {
                enqueueSnackbar(t('AnelmaSolmuErrors:10013'), {
                    variant: 'error',
                });
                return;
            }

            let payloadForAdd = {
                name: mainGroup.Name,
                childGroups: subGroupsToSave,
                ownerId: farmGuid,
            };

            if (validator.valid) {
                groupsApi.saveGroups(payloadForAdd).then((response) => {
                    enqueueSnackbar(t('AnelmaGeneral:1018'), {
                        variant: 'success',
                    });
                    props.onClose();
                });
            } else {
                enqueueSnackbar(t('AnelmaGeneral:1019'), {
                    variant: 'error',
                });
                return;
            }
        } else if (props.mode === 'edit') {
            let subGroupsToEdit = [];
            let mainGroupsToCheckWithId = props.existingGroups.filter(
                (m) => m.Name === mainGroup.Name && m.Id !== mainGroup.Id
            );
            if (mainGroupsToCheckWithId.length > 0) {
                enqueueSnackbar(t('AnelmaBovine:1113'), {
                    variant: 'error',
                });
                return;
            }

            for (let i = 0; i < subGroups.length; i++) {
                if (subGroups[i].Id.length > 0) {
                    subGroupsToEdit.push({
                        name: subGroups[i].Name,
                        groupId: subGroups[i].Id,
                    });
                } else {
                    subGroupsToEdit.push({
                        name: subGroups[i].Name,
                    });
                }
            }
            let payloadForEdit = {
                groupId: mainGroup.Id,
                name: mainGroup.Name,
                childGroups: subGroupsToEdit,
            };
            if (validator.valid) {
                groupsApi.updateGroups(payloadForEdit).then((response) => {
                    props.onClose();
                });
            } else {
                enqueueSnackbar(t('AnelmaGeneral:1019'), {
                    variant: 'error',
                });
                return;
            }
        }
    };

    return (
        <>
            <ADialog
                open
                onClose={() => {
                    props.onClose();
                }}
            >
                {props.mode === 'add' && <ADialogTitle>{t('AnelmaBovine:1111')}</ADialogTitle>}
                {props.mode === 'edit' && <ADialogTitle>{t('AnelmaBovine:1109')}</ADialogTitle>}

                <ADialogContent size={'lg'} isLoading={isLoading}>
                    <Grid container style={{ minHeight: '300px' }}>
                        <Grid item style={{ flexGrow: 1, maxWidth: '50%' }}>
                            <ATextInput
                                id='main-group'
                                label={t('AnelmaBovine:1102')}
                                onChange={(v) => updateMainGroup(v)}
                                required
                                validator={validator}
                                value={mainGroup.Name}
                            />
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                            {subGroups.map((item, index) => {
                                return (
                                    <Grid container style={{ alignItems: 'center' }}>
                                        <Grid>
                                            <ADeleteIconButton
                                                key={index}
                                                disabled={deleteDisabled(item, index)}
                                                onClick={() => deleteSubGroup(index)}
                                            ></ADeleteIconButton>
                                        </Grid>
                                        <Grid>
                                            <ATextInput
                                                id='sub-group'
                                                label={t('AnelmaBovine:1104')}
                                                onChange={(v) => updateSubGroups(index, v)}
                                                required
                                                validator={validator}
                                                value={subGroups[index].Name}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingRight: '20px',
                        }}
                    >
                        <AAddButton
                            onClick={() => addNewSubGroup()}
                            type='action'
                            style={{ marginBottom: '10px' }}
                            disabled={!auth.canUpdateGrouping || !auth.canCreateGrouping}
                        >
                            {t('AnelmaBovine:1112')}
                        </AAddButton>
                    </Grid>
                    <ADialogActions buttons={footerActionButtons} />
                </ADialogContent>
            </ADialog>
        </>
    );
}
