// Libraries
import { useSnackbar, VariantType } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
// Core
import defaultData from '../../../core/defaultData';
import FormValidator from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../../common/dialog';
import { ADialogButtonsProp } from '../../../common/dialog/ADialogActions';
import { ATextInput } from '../../../common/inputs';

// Interfaces
import { IUpdatePayload } from '../../../interfaces/IGeneral';
import {
    IPredefinedSearch,
    IPredefinedSearchFarmResult,
    ISearchParameter,
    SearchGroup,
    SearchType,
} from '../../../interfaces/ISearchParameters';

// API
import searchParametersApi from '../../../api/searchParametersApi';

// Feature
import PredefinedSearchAccordion from '../../components/general/PredefinedSearchAccordion';
import Recipients from '../../../common/communicationGroup/Recipients';
import { IParameterExtension } from '../../shared/styles/types/subTypes';
import { mapEditDataToRequest, isUsingPersonsConnectedToTheFarm } from '../../Helpers/general';
import { AnimalTypeEnum } from '../../../interfaces/enums';

export interface ISearchParameterDialogProps {
    onClose: () => any;
    mode: 'create' | 'modify';
    parameters: IParameterExtension[];
    personParameters: IParameterExtension[];
    data?: IPredefinedSearch;
}

export default function SearchParameterDialog(props: ISearchParameterDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
    ]);

    const { enqueueSnackbar } = useSnackbar();
    const validator = new FormValidator();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<IPredefinedSearch>(
        props.data ?? defaultData.predefinedSearch()
    );
    const [foundFarms, setFoundFarms] = useState<IPredefinedSearchFarmResult[]>([]);
    const [foundFarmsDialogOpen, setFoundFarmsDialogOpen] = useState<boolean>(false);
    const [originalSearch, setOriginalSearch] = useState<IPredefinedSearch>(
        props.data ?? defaultData.predefinedSearch()
    );
    const [languageCodes, setLanguageCodes] = useState<string[]>([]);

    const [usePersonsConnectedToTheFarm, setUsePersonsConnectedToTheFarm] =
        useState<boolean>(false);
    const [personGuids, setPersonGuids] = useState<string[]>([]);

    const buttons: ADialogButtonsProp = {
        left: [
            {
                type: 'default',
                label: t('AnelmaGeneral:1009'),
                onClick: () => {
                    props.onClose();
                },
            },
            {
                type: 'with-label',
                label: t('AnelmaCommunication:1050'),
                onClick: () => {
                    setFoundFarmsDialogOpen(true);
                },
                leftSideText: usePersonsConnectedToTheFarm
                    ? t('AnelmaCommunication:1150').replace(
                          '{count}',
                          personGuids.length.toString()
                      )
                    : foundFarms.length > 0
                    ? t('AnelmaCommunication:1033').replace('{count}', foundFarms.length.toString())
                    : '',
                disabled: isLoading,
            },
        ],
        right: [
            {
                type: 'save',
                label: t('AnelmaGeneral:1001'),
                onClick: () => {
                    if (validator.invalid) {
                        enqueueSnackbar(t('AnelmaGeneral:1030'), {
                            variant: 'error',
                        });
                        return;
                    }
                    save();
                },
                disabled: isLoading,
            },
        ],
    };

    useEffect(() => {
        if (props.mode === 'modify') searchFarms(search.GUID);
        setUsePersonsConnectedToTheFarm(isUsingPersonsConnectedToTheFarm(props.data?.Parameters));
    }, []);

    useEffect(() => {
        const languageCodes = search.Parameters.filter((parameter) => {
            if (Number(parameter.Type) === Number(SearchType.Language)) return parameter;
        });

        if (languageCodes.values()) {
            let codes: string[] = [];

            for (const code of languageCodes) {
                code.Value.forEach((v) => {
                    codes.push(v);
                });
            }
            setLanguageCodes(codes);
        }
    }, [search]);

    const handleSnackbarMessage = (message: string, variant: VariantType) => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const initializeFarmsState = () => {
        setFoundFarms([]);
    };

    const handleSearchStateChange = (entity: IPredefinedSearch, id: string) => {
        setOriginalSearch(entity);
        setSearch(entity);
        searchFarms(id);
    };

    const handlePredefinedSearchSave = (request: IPredefinedSearch) => {
        setIsLoading(true);
        searchParametersApi.savePredefinedSearch(request).then((response) => {
            if (!response) {
                setIsLoading(false);
                return;
            }
            handleSearchStateChange(response.Entity, response.Entity.GUID);
            props.onClose();
            handleSnackbarMessage(t('AnelmaGeneral:1018'), 'success');
        });
    };

    const handlePredefinedSearchModify = (request: IUpdatePayload<IPredefinedSearch>) => {
        setIsLoading(true);
        searchParametersApi.modifyPredefinedSearch(request).then((response) => {
            if (!response) {
                setIsLoading(false);
                return;
            }
            handleSearchStateChange(response.Entity, response.Entity.GUID);
            handleSnackbarMessage(t('AnelmaGeneral:1018'), 'success');
        });
    };

    const save = async () => {
        if (search && props.mode === 'create') handlePredefinedSearchSave(search);
        else handlePredefinedSearchModify(mapEditDataToRequest(originalSearch, search));
    };

    const searchFarms = async (searchId: string) => {
        setIsLoading(true);
        initializeFarmsState();

        await searchParametersApi
            .runPredefinedSearch({
                PredefinedSearchGuid: searchId,
                ShowMoreInfo: true,
                LanguageCodes: languageCodes,
            })
            .then((response) => {
                if (!response) return;
                const tmpArr: string[] = [];
                response.Items.forEach((farm) => {
                    farm.ContactPersons.forEach((per) => {
                        tmpArr.push(per.Id);
                    });
                });
                setPersonGuids(tmpArr);
                setFoundFarms(response.Items);
                setIsLoading(false);
            });
    };

    return (
        <>
            <ADialog open onClose={() => props.onClose()}>
                <ADialogTitle>
                    {props.mode === 'create'
                        ? t('AnelmaCommunication:1035')
                        : t('AnelmaCommunication:1031')}
                </ADialogTitle>

                <ADialogContent size='lg' isLoading={isLoading}>
                    <ATextInput
                        id='input-for-name'
                        required
                        validator={validator}
                        placeholder={t('AnelmaCommunication:1037')}
                        label={t('AnelmaCommunication:1036')}
                        value={search.Name}
                        key={search.GUID}
                        onChange={(v) => setSearch({ ...search, Name: v })}
                        minLength={1}
                        maxLength={128}
                    />

                    <PredefinedSearchAccordion
                        title={t('AnelmaCommunication:1038')} // Tila
                        placeholder={t('AnelmaCommunication:1039')}
                        data={search}
                        searchChanged={(c) => setSearch(c)}
                        parameters={props.parameters}
                        personParameters={props.personParameters}
                        searchGroup={SearchGroup.Place}
                        usePersonsConnectedToTheFarm={usePersonsConnectedToTheFarm}
                        setUsePersonsConnectedToTheFarm={setUsePersonsConnectedToTheFarm}
                        expanded={true}
                    />
                    <PredefinedSearchAccordion
                        title={t('AnelmaCommunication:1130')} // Henkilö
                        placeholder={t('AnelmaCommunication:1155')}
                        data={search}
                        searchChanged={(c) => setSearch(c)}
                        parameters={props.personParameters}
                        personParameters={props.personParameters}
                        searchGroup={SearchGroup.Person}
                        usePersonsConnectedToTheFarm={usePersonsConnectedToTheFarm}
                        setUsePersonsConnectedToTheFarm={setUsePersonsConnectedToTheFarm}
                    />
                    <PredefinedSearchAccordion
                        title={t('AnelmaCommunication:1135')} // Nauta
                        placeholder={t('AnelmaCommunication:1156')}
                        data={defaultData.predefinedSearch()}
                        searchChanged={(c) => setSearch(c)}
                        parameters={props.parameters}
                        personParameters={props.personParameters}
                        searchGroup={SearchGroup.Bovine}
                        usePersonsConnectedToTheFarm={usePersonsConnectedToTheFarm}
                        setUsePersonsConnectedToTheFarm={setUsePersonsConnectedToTheFarm}
                        disabled
                    />
                    <PredefinedSearchAccordion
                        title={t('AnelmaCommunication:1162')} // Sika
                        placeholder={t('AnelmaCommunication:1156')}
                        data={defaultData.predefinedSearch()}
                        searchChanged={(c) => setSearch(c)}
                        parameters={props.parameters}
                        personParameters={props.personParameters}
                        searchGroup={SearchGroup.Pig}
                        usePersonsConnectedToTheFarm={usePersonsConnectedToTheFarm}
                        setUsePersonsConnectedToTheFarm={setUsePersonsConnectedToTheFarm}
                        disabled
                    />
                </ADialogContent>

                <ADialogActions buttons={buttons} />
            </ADialog>

            {foundFarmsDialogOpen && (
                <Recipients
                    onClose={() => setFoundFarmsDialogOpen(false)}
                    items={foundFarms}
                    parameters={props.parameters}
                    personParameters={props.personParameters}
                    titleParameter={
                        usePersonsConnectedToTheFarm
                            ? 'AnelmaCommunication:1150'
                            : 'AnelmaCommunication:1033'
                    }
                    usePersonsConnectedToTheFarm={usePersonsConnectedToTheFarm}
                    communicationGroups={[]}
                    announcementSearches={[]}
                    addingNewCommunicationGroupName=''
                />
            )}
        </>
    );
}
