import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import AuditTrailSearchBar from './search/AuditTrailSearchBar';
import { ResourceTextApplication } from '../core/resources';
import LoggingView from './LoggingView';
import { useState } from 'react';
import { ICQRSEventBase } from '../interfaces/ILog';
import LogInfoDialog from './LogInfoDialog';
import ViewLoader from '../common/ViewLoader';
import { ViewTitle } from '../common/typography';
import SystemLogDialog from './SystemLogDialog';

export default function Logging() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaLayout']);

    const [logList, setLogList] = useState<ICQRSEventBase[]>([]);
    const [infoDialogIsOpen, setInfoDialogIsOpen] = useState<boolean>(false);
    const [systemLogDialogOpen, setSystemLogDialogOpen] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<string>('');
    const [eventCorrelationId, setEventCorrelationId] = useState<string>('');
    const [fromDatetime, setFromDatetime] = useState<string>('');
    const [loaded, setLoaded] = useState<boolean>(false);
    const [initiateDefaultSearch, setInitiateDefaultSearch] = useState<boolean>(false);

    const handleRowClick = (p: string | number) => {
        setDialogData(JSON.stringify(logList[parseInt(p.toString())], null, ' '));
        setInfoDialogIsOpen(true);
    };

    const handleSystemLogDialogOpen = async (correlationId: string, fromDatetime: string) => {
        setEventCorrelationId(correlationId);
        setFromDatetime(fromDatetime);
        setSystemLogDialogOpen(true);
    };

    const closeSystemLogDialog = () => {
        setSystemLogDialogOpen(false);
    };

    const initiateSearch = () => {
        setInitiateDefaultSearch(true);
    }

    return (
        <Container data-robot-id = {"app-body-management-log"}>
            <ViewTitle>{t('AnelmaLayout:1007')}</ViewTitle>
            <br />
            <AuditTrailSearchBar
                beforeSearch={() => {
                    setLoaded(false);
                }}
                afterSearch={setLogList}
                setLoaded={(v: boolean) => setLoaded(v)}
                loaded={() => loaded}
                searchInitiated={initiateDefaultSearch}
            />

            {loaded ? (
                <LoggingView
                    data={logList}
                    openDialog={(p) => handleRowClick(p)}
                    openSystemLogDialog={(correlationId, fromDatetime) =>
                        handleSystemLogDialogOpen(correlationId, fromDatetime)
                    }
                    initiateSearch={() => initiateSearch()}
                />
            ) : (
                <ViewLoader />
            )}

            {infoDialogIsOpen && (
                <LogInfoDialog onClose={() => setInfoDialogIsOpen(false)} data={dialogData} />
            )}

            {systemLogDialogOpen && (
                <SystemLogDialog
                    onClose={() => {
                        closeSystemLogDialog();
                    }}
                    eventCorrelationId={eventCorrelationId}
                    fromDatetime={fromDatetime}
                />
            )}
        </Container>
    );
}
