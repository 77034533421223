import ContentTypography from './ContentTypography';

interface ContentParagraphProps {
    data: {
        text: string;
    };
    id: string;
}

export default function ContentParagraph(props: ContentParagraphProps) {
    return <ContentTypography key={props.id} text={props.data.text} variant='body1' />;
}
