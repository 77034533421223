import { ExtendedCalendarEvent } from '../interfaces/calendar/CalendarEvent';
import { CalendarEventTypes } from '../interfaces/enums';
import { CalendarEventStyles } from './CalendarEventStyles';

export const getEventStyle = (
    event: ExtendedCalendarEvent,
    start: string | Date,
    end: string | Date,
    isSelected: boolean
) => {
    switch (event.type) {
        case CalendarEventTypes.PickupTransMissionAnimal: {
            return {
                style: CalendarEventStyles.PickupTransMissionAnimal,
            };
        }
        case CalendarEventTypes.PickupSlaughterAnimal: {
            return {
                style: CalendarEventStyles.PickupSlaughterAnimal,
            };
        }
        case CalendarEventTypes.FarmVisit: {
            return {
                style: CalendarEventStyles.FarmVisit,
            };
        }
        case CalendarEventTypes.Event: {
            return {
                style: CalendarEventStyles.Event,
            };
        }
        case CalendarEventTypes.EventRegistration: {
            return {
                style: CalendarEventStyles.EventRegistration,
            };
        }
        case CalendarEventTypes.OwnBookings: {
            return {
                style: CalendarEventStyles.OwnBookings,
            };
        }
        case CalendarEventTypes.Other: {
            return {
                style: CalendarEventStyles.Other,
            };
        }
        case CalendarEventTypes.ReligiousHolidays: {
            return {
                style: CalendarEventStyles.ReligiousHolidays,
            };
        }
        default: {
            return {};
        }
    }
};

export const getScrollToTimeValue = (): Date => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate(), 4, 0, 0);
};
