import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AActionButton } from '../../../common/buttons';
import { ADialog, ADialogContent, ADialogTitle } from '../../../common/dialog';
import { ResourceTextApplication } from '../../../core/resources';
import { AnnouncementTypeEnum } from '../../../interfaces/enums';
import CloseDialogButton from '../../announcements/components/general/CloseDialogButton';
import '../../shared/styles/sharedStyles.scss'

export interface IManualAnnouncementSelectionDialogProps {
    onClose: () => void;
    onManualAnnouncementSelect: (type: AnnouncementTypeEnum) => void;
}

export const ManualAnnouncementSelectionDialog = (
    props: IManualAnnouncementSelectionDialogProps
) => {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);

    return (
        <ADialog open onClose={props.onClose} disableEnforceFocus>
            <ADialogTitle>
                {t('AnelmaBovine:1136')}
                <CloseDialogButton className='animal-announcement-close-button-float-right' onClick={props.onClose} />
            </ADialogTitle>
            <ADialogContent size='md'>
                <Grid container style={{ justifyContent: 'center', padding: '1em' }}>
                    <Grid item>
                        <AActionButton
                            style={{ margin: '0.5em' }}
                            onClick={() =>
                                props.onManualAnnouncementSelect(
                                    AnnouncementTypeEnum.ManualBovineSlaughter
                                )
                            }
                        >
                            {t('AnelmaBovine:1070')}
                        </AActionButton>
                        <AActionButton
                            style={{ margin: '0.5em' }}
                            onClick={() =>
                                props.onManualAnnouncementSelect(
                                    AnnouncementTypeEnum.ManualBovineIntermediation
                                )
                            }
                        >
                            {t('AnelmaBovine:1071')}
                        </AActionButton>
                    </Grid>
                </Grid>
            </ADialogContent>
        </ADialog>
    );
};
