// Libraries
import axios from 'axios';
import { assoc, forEach, pathOr, reduce } from 'ramda';

// Core
import { clientResourceUrl, systemResourcesUrl } from '../api/apiEndpoints';

export interface IClientResourcesQuery {
    resourceTextGroupId?: string;
    parameterGroupId?: string;
}
export interface IBoundaryValue {
    code: string;
    maxValue: number;
    minValue: number;
}

export interface IBoundaryValueGroup {
    id: ResourceBoundaryApplication;
    values: IBoundaryValue[];
}

export interface IClientResources {
    boundaryValueGroups: IBoundaryValueGroup[];
    parameterGroups: IParameterGroup[];
    resourceTextGroups: IResourceTextGroup[];
}

export interface IClientResourcesWithLang {
    boundaryValueGroups: IBoundaryValueGroup[];
    parameterGroups: IParameterGroupWithLang[];
    resourceTextGroups: IResourceTextGroup[];
}

export interface IResourceTextGroup {
    id: ResourceTextApplication;
    resourceTexts: IResourceText[];
}

export interface IResourceText {
    code: number;
    value: string;
}

export interface ISystemText {
    en: IResourceText;
    fi: IResourceText;
    sv: IResourceText;
}

export interface IParameterGroup {
    id: ResourceParameterApplication;
    parameters: IParameter[];
}

export interface IParameterGroupWithLang {
    id: ResourceParameterApplication;
    parameters: IParameter[];
    langCode: string;
}

export interface IParameter {
    name: string;
    values: IParameterValue[];
}

export interface IParameterValue {
    code: string;
    text: string;
}

export interface IResources {
    load(
        textApps: ResourceTextApplication[],
        paramApps: ResourceParameterApplication[],
        boundaryApps: ResourceBoundaryApplication[]
    ): Promise<void>;
    loadBoundaryValues(boundaryApps: ResourceBoundaryApplication[]): Promise<void>;
    loadParameters(paramApps: ResourceParameterApplication[]): Promise<void>;
    loadTexts(textApps: ResourceTextApplication[]): Promise<void>;
    parameter(application: ResourceParameterApplication, name: string): IParameterValue[];
    reload(
        textApps: ResourceTextApplication[],
        paramApps: ResourceParameterApplication[],
        boundaryApps: ResourceBoundaryApplication[]
    ): Promise<void>;
    reloadBoundaryValue(boundaryApps: ResourceBoundaryApplication[]): Promise<void>;
    reloadParameters(paramApps: ResourceParameterApplication[]): Promise<void>;
    reloadTexts(textApps: ResourceTextApplication[]): Promise<void>;
    text(application: ResourceTextApplication, code: number): string;
}

export type ResourceTextApplication =
    | 'AnelmaAccounting'
    | 'AnelmaAccessRights'
    | 'AnelmaAnimalAnnouncement'
    | 'AnelmaAnimalPayload'
    | 'AnelmaAudit'
    | 'AnelmaBreedingContract'
    | 'AnelmaBovine'
    | 'AnelmaBulletin'
    | 'AnelmaCalendar'
    | 'AnelmaCattleProductionPlan'
    | 'AnelmaCommunication'
    | 'AnelmaContent'
    | 'AnelmaCompany'
    | 'AnelmaContent'
    | 'AnelmaDocument'
    | 'AnelmaEvents'
    | 'AnelmaFeedback'
    | 'AnelmaFarmVisit'
    | 'AnelmaGeneral'
    | 'AnelmaInstructions'
    | 'AnelmaInvoice'
    | 'AnelmaLayout'
    | 'AnelmaPerson'
    | 'AnelmaReporting'
    | 'AnelmaSolmuErrors'
    | 'AnelmaSMS'
    | 'AnelmaPriceList'
    | 'AnelmaVariables'
    | 'AnelmaReporting'
    | 'AnelmaChainInformation'
    | 'AnelmaTiltu'
    | 'AnelmaFarmFiles'
    | 'AnelmaNotifications';

export type ResourceParameterApplication = 'AnelmaCompany';

export type ResourceBoundaryApplication =
    | 'AnelmaCompany'
    | 'AnelmaAnimalPayload'
    | 'AnelmaAnimalAnnouncement'
    | 'AnelmaChainInformation';

export interface IResourceRepository<TResource, TApplication> {
    load(groups: TApplication[]): Promise<void>;
    resource(group: TApplication, code: string): TResource;
}

export interface ParameterValue {
    code: string;
    text: string;
}

export interface ParameterValueWithLang {
    code: string;
    text: string;
    langCode: string;
}

export type ParameterValues = ParameterValue[];

export interface ParameterResource {
    [Key: string]: ParameterValue[];
}

export interface ResourceGroup<TResource> {
    [Key: string]: TResource;
}

function createResourceParameterGroup(group: IParameterGroup): ParameterResource {
    const init: ParameterResource = {};
    const addParameter = (a: ParameterResource, c: IParameter) => assoc(c.name, c.values, a);

    return reduce(addParameter, init, group.parameters);
}

function resourceQueryBuilder(groups: ResourceTextApplication[]): string {
    return groups.map((i) => `p=${i}`).join('&');
}

class ParamResourceRepositoryImpl
    implements IResourceRepository<ParameterValues, ResourceTextApplication>
{
    private resources: ResourceGroup<ParameterResource> = {};

    async load(groups: ResourceTextApplication[]): Promise<void> {
        const loadedGroups = Object.keys(this.resources);
        const groupsToGet = groups.filter((g) => !loadedGroups.includes(g));

        if (groupsToGet.length) {
            const result = await axios
                .get<IClientResources>(`${clientResourceUrl}?${resourceQueryBuilder(groupsToGet)}`)
                .then((response) => response.data);
            forEach(
                (group: IParameterGroup) =>
                    (this.resources[group.id] = createResourceParameterGroup(group)),
                result.parameterGroups
            );
        } else return;
    }

    async loadAllLanguages(groups: ResourceTextApplication[]): Promise<IClientResourcesWithLang> {
        const result = await axios
            .get<IClientResourcesWithLang>(
                `${clientResourceUrl}/lang/?${resourceQueryBuilder(groups)}`
            )
            .then((response) => {
                return response.data;
            });
        return result;
    }

    async getCodeFromText(type: string, searchTerm: string): Promise<string> {
        let term: string = '0';

        if (type === 'Municipality') {
            await paramRepository
                .load(['AnelmaGeneral'])
                .then(() => {
                    const params = paramRepository.resource('AnelmaGeneral', 'Municipality');
                    term = params.find((p) => p.text == searchTerm)?.code ?? '0';
                })
                .then(() => {
                    return term;
                });
        } else if (type === 'County') {
            await paramRepository
                .load(['AnelmaGeneral'])
                .then(() => {
                    const params = paramRepository.resource('AnelmaGeneral', 'Regions');
                    term = params.find((p) => p.text == searchTerm)?.code ?? '0';
                })
                .then(() => {
                    return term;
                });
        } else return searchTerm;

        return term;
    }

    resource(
        group: ResourceTextApplication,
        paramName: string,
        sortValues?: ParameterSorting
    ): ParameterValues {
        const params = [...pathOr<ParameterValues>([], [group, paramName], this.resources)];

        if (sortValues === 'alphabetical') params.sort((a, b) => (a.text < b.text ? -1 : 1));
        else if (sortValues === 'default') params.sort((a, b) => Number(a.code) - Number(b.code));

        return params;
    }

    resourceByLang(
        group: ResourceTextApplication,
        paramName: string,
        sortValues?: ParameterSorting
    ): ParameterValues {
        const params = [...pathOr<ParameterValues>([], [group, paramName], this.resources)];

        if (sortValues === 'alphabetical') params.sort((a, b) => (a.text < b.text ? -1 : 1));

        return params;
    }
}

export type ParameterSorting = 'default' | 'alphabetical';

export const paramRepository = new ParamResourceRepositoryImpl();

export interface SystemText {
    additionalInfo: string;
    application: string;
    code: number | null;
    cultures: SystemTextCultureValue[];
}

interface SystemTextCultureValue {
    culture: SystemTextCulture;
    value: string;
}

export type SystemTextCulture = 'fi' | 'sv-SE' | 'en-GB';

class SystemTextApi {
    get(application: ResourceTextApplication, code: number): Promise<SystemText> {
        return new Promise<SystemText>((resolve, reject) => {
            axios.get(`${systemResourcesUrl}/system-text/${application}/${code}`).then(
                (response) => resolve(response.data),
                () => reject()
            );
        });
    }

    getByLang(application: ResourceTextApplication, code: number): Promise<SystemText> {
        return new Promise<SystemText>((resolve, reject) => {
            axios.get(`${systemResourcesUrl}/system-text/${application}/${code}`).then(
                (response) => resolve(response.data),
                () => reject()
            );
        });
    }

    save(data: SystemText): Promise<SystemText> {
        return new Promise<SystemText>((resolve, reject) => {
            axios.post(`${systemResourcesUrl}/system-text`, data).then(
                (response) => resolve(response.data),
                () => reject()
            );
        });
    }
}

export const systemTextApi = new SystemTextApi();
