// Libraries
import moment from 'moment';

// MUI
// Core
// Common
// Interfaces
import {
    IModifyNotifications,
    IModifyNotificationsPayload,
    INotification,
    NotificationFlagsEnum,
} from '../../interfaces/INotification';

// Store
// API
// Feature - Notifications

export const mapPayload = (
    data: INotification[],
    operation: 'setRead' | 'setDeleted',
    userId: string | null
) => {
    let payload: IModifyNotifications = { Items: [] };
    let dateTimeFormat: string = 'YYYY-MM-DDTHH:mm:ss';

    if (operation === 'setRead') {
        for (const _ of data) {
            let row: IModifyNotificationsPayload = {
                UserId: userId || '',
                NotificationId: _.NotificationId,
                Flags: NotificationFlagsEnum.Read,
                ReadDateTime: moment().utc().format(dateTimeFormat),
                DeletedDateTime: null,
            };
            payload.Items.push(row);
        }
    } else if (operation === 'setDeleted') {
        for (const _ of data) {
            let row: IModifyNotificationsPayload = {
                UserId: userId || '',
                NotificationId: _.NotificationId,
                Flags: NotificationFlagsEnum.Deleted,
                ReadDateTime: null,
                DeletedDateTime: moment().utc().format(dateTimeFormat),
            };
            payload.Items.push(row);
        }
    }

    return payload;
};
