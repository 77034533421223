// Core
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { ResourceTextApplication } from '../core/resources';
import { useAppSelector } from '../store/hooks';
import moment from 'moment';

// Miscellaneous
import { useSnackbar } from 'notistack';
import defaultData from '../core/defaultData';
import eventsApi from '../api/eventsApi';
import { dayFormat } from '../core/constants';

// UI
import {
    ADialog,
    ADialogActions,
    ADialogTitle,
    ADialogSubHeading,
    ADialogContent,
    ConfirmDeletionDialog,
} from '../common/dialog';
import { AEmailAddressInput, APhoneNumberInput, ATextInput } from '../common/inputs';
import { Grid, Typography } from '@mui/material';
import { InfoBlock } from '../common/typography';
import { IFormValidator } from '../core/FormValidator';
import { AAddButton, ADeleteIconButton } from '../common/buttons';
import auth from '../core/authorization';

// Interfaces
import { IPersonSummary, IPhoneNumber } from '../interfaces/IBusinessEntities';
import { IEvent, IRegistrationForm } from '../interfaces/IEvent';
import { EventDialogMode } from '../interfaces/enums';
import RenderContent from '../editableContent/RenderContent';

export interface EventRegistrationDialogProps {
    admin: boolean;
    data: IEvent;
    user: IPersonSummary | null;
    onClose: () => void;
    onSave: (data: IRegistrationForm[], mode: EventDialogMode) => any;
    validator: IFormValidator;
    mode: EventDialogMode;
    loading?: boolean;
}

export default function EventRegistrationDialog(props: EventRegistrationDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaEvents']);

    let userId = useAppSelector((state) => state.userData.data?.Id || null);

    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const langConfig = useAppSelector((state) => state.configuration.selectedCulture);
    if (props.user === null) {
        userId = null;
    }

    const { enqueueSnackbar } = useSnackbar();
    const [showConfirmDeletion, setShowConfirmDeletion] = useState<boolean>(false);
    const [showConfirmDeleteSingle, setShowConfirmDeleteSingle] = useState<boolean>(false);
    const [mode, setMode] = useState<EventDialogMode>(props.mode);
    const [eventValidFrom] = useState<moment.Moment>(moment(props.data.EventValidFrom));
    const [eventValidDue] = useState<moment.Moment>(moment(props.data.EventValidDue));
    const [registrationValidFrom] = useState<moment.Moment>(
        moment(props.data.RegistrationValidFrom)
    );
    const [registrationValidDue] = useState<moment.Moment>(moment(props.data.RegistrationValidDue));
    const [personsToSave, setPersonsToSave] = useState<IRegistrationForm[]>([]);
    const [idForDeletion, setIdForDeletion] = useState<string>();
    const [attendeesEdit, setAttendeesEdit] = useState<boolean>(false);

    useEffect(() => {
        if (props.user != null) {
            eventsApi.checkRegistration(userId, props.data.Id).then((response) => {
                setMode(EventDialogMode.Edit);
                if (response != null && response.Items.length > 0) {
                    setPersonsToSave(response.Items);
                }
                else {
                    setPersonsToSave([defaultData.registrationForm(props.data.Id, userId)]);
                }
            });
        }
        else {
            if (props.data.Registrants.length > 0) {
                setMode(EventDialogMode.Edit);
                setAttendeesEdit(true);
                setPersonsToSave(props.data.Registrants);
            }
            else {
                setMode(EventDialogMode.Save);
                setPersonsToSave([defaultData.registrationForm(props.data.Id, userId)]);
            }
        }
    }, []);

    const checkReadOnly = () => {
        if (props.admin === true) {
            return false;
        }
        let today = moment();
        let from = moment(props.data.RegistrationValidFrom).startOf('day');
        let to = moment(props.data.RegistrationValidDue).endOf('day');
        if (from <= today && to >= today) {
            return false;
        } else {
            return true;
        }
    };

    const deleteDisabled = () => {
        return checkReadOnly() || personsToSave.length <= 1;
    };

    const cancelDisabled = () => {
        if (props.admin === true && mode !== EventDialogMode.Edit && auth.canDeleteEventEnrolment) {
            return true;
        } else {
            return checkReadOnly() || mode === EventDialogMode.Save;
        }
    };

    const setRegistrationTime = () => {
        let tempArray = [...personsToSave];
        let currentTime = moment().format('YYYY-MM-DDTHH:mm:ss');
        for (let i = 0; i < tempArray.length; i++) {
            tempArray[i].RegistrationTime = currentTime;
        }
    };

    const prepareSave = (modeChange: string) => {
        if (props.validator.invalid && modeChange !== EventDialogMode.Delete) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }

        setRegistrationTime();

        let saveableMode = mode;

        if (modeChange === EventDialogMode.Delete) {
            saveableMode = modeChange;

            const newValues = [...personsToSave];
            newValues.forEach(x => x.Flags = 1);
            setPersonsToSave(newValues);
        }

        const currentRegistree = [...personsToSave];

        currentRegistree.forEach((x) => {
           if (x.Flags === -1) {
               x.Flags = 0;
               x.Id = '';
           }
        });

        props.onSave(currentRegistree, saveableMode);
    };

    const setBodyText = () => {
        if (langConfig === 'fi') {
            return props.data.BodyTextFI;
        } else if (langConfig === 'sv-SE') {
            return props.data.BodyTextSE;
        } else if (langConfig === 'en-GB') {
            return props.data.BodyTextEN;
        }
    };

    const setHeader = () => {
        if (langConfig === 'fi') {
            return props.data.HeaderFI;
        } else if (langConfig === 'sv-SE') {
            return props.data.HeaderSE;
        } else if (langConfig === 'en-GB') {
            return props.data.HeaderEN;
        }
    };

    const addNewPerson = () => {
        setPersonsToSave((previousState) => [
            ...previousState,
            defaultData.registrationForm(props.data.Id, userId),
        ]);
    };

    const prepareDelete = (id: string) => {
        setIdForDeletion(id);
        setShowConfirmDeleteSingle(true);
    };

    const deletePerson = () => {
        const currentRegistree = [...personsToSave];

        var index = currentRegistree.findIndex(x => x.Id === idForDeletion);

        if (currentRegistree.findIndex(x => x.Id === idForDeletion && x.Flags !== -1) === -1) {
            currentRegistree.splice(index,1);
        }
        else {
            currentRegistree[index].Flags = 1;
        }

        setPersonsToSave(currentRegistree);

        props.validator.removeState('first-name ' + idForDeletion);
        props.validator.removeState('last-name ' + idForDeletion);
        props.validator.removeState('phone-number ' + idForDeletion);
        props.validator.removeState('email ' + idForDeletion);
        props.validator.removeState('diet ' + idForDeletion);
        props.validator.removeState('additional-info ' + idForDeletion);
    };

    const getRegistrationDate = () => {
        let invalid = moment.invalid().toString();
        if (
            registrationValidFrom.format(dayFormat) !== invalid &&
            registrationValidDue.format(dayFormat) !== invalid
        ) {
            return `${moment(registrationValidFrom).format(dayFormat)} - ${moment(
                registrationValidDue
            ).format(dayFormat)}`;
        } else {
            return '-';
        }
    };

    const updateFirstName = (id: string, value: string) => {
        const newValues = [...personsToSave];

        var index = newValues.findIndex(x => x.Id === id);
        newValues[index].FirstName = value;
        setPersonsToSave(newValues);
    };

    const updateLastName = (id: string, value: string) => {
        const newValues = [...personsToSave];

        var index = newValues.findIndex(x => x.Id === id);
        newValues[index].LastName = value;
        setPersonsToSave(newValues);
    };

    const updatePhoneNumber = (id: string, value: IPhoneNumber) => {
        const newValues = [...personsToSave];

        var index = newValues.findIndex(x => x.Id === id);
        newValues[index].PhoneNumber = value.NormalizedNumber;
        setPersonsToSave(newValues);
    };

    const updateEmail = (id: string, value: string) => {
        const newValues = [...personsToSave];

        var index = newValues.findIndex(x => x.Id === id);
        newValues[index].Email = value;
        setPersonsToSave(newValues);
    };

    const updateDiet = (id: string, value: string) => {
        const newValues = [...personsToSave];

        var index = newValues.findIndex(x => x.Id === id);
        newValues[index].Diet = value;
        setPersonsToSave(newValues);
    };

    const updateAdditionalInformation = (id: string, value: string) => {
        const newValues = [...personsToSave];

        var index = newValues.findIndex(x => x.Id === id);
        newValues[index].AdditionalInformation = value;
        setPersonsToSave(newValues);
    };

    return (
        <ADialog open onClose={() => props.onClose()}>
            <ADialogTitle>{t('AnelmaEvents:1011')}</ADialogTitle>

            <ADialogContent size='md' isLoading={props.loading !== undefined ? props.loading : false}>
                <ADialogSubHeading>{setHeader()}</ADialogSubHeading>

                <Grid container style={{ paddingLeft: '40px' }}>
                    <Grid item sm={4}>
                        <InfoBlock
                            label={t('AnelmaEvents:1010')}
                            value={`${eventValidFrom.format(dayFormat)} - ${eventValidDue.format(
                                dayFormat
                            )}`}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <InfoBlock label={t('AnelmaEvents:1040')} value={getRegistrationDate()} />
                    </Grid>

                    <Grid item sm={4}>
                        <InfoBlock
                            label={t('AnelmaEvents:1004')}
                            value={`${props.data.Location}`}
                        />
                    </Grid>
                </Grid>

                <Grid container style={{ padding: '40px' }}>
                    <RenderContent publicSite={!loginStatus.loggedIn} id={props.data.ContentId} />

                    {setBodyText()}
                </Grid>

                {personsToSave.filter(x => x.Flags !== 1).map((item, index) => {
                    return (
                        <Grid container key={item.Id} style={{ marginBottom: '20px' }}>
                            <Grid item sm={11}>
                                <Typography variant='h5' style={{ paddingLeft: '40px' }}>
                                    {`${t('AnelmaEvents:1042')} ${index + 1}`}
                                </Typography>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <ATextInput
                                            id={`first-name ${item.Id}`}
                                            label={t('AnelmaEvents:1031')}
                                            value={item.FirstName}
                                            onChange={(v) => updateFirstName(item.Id, v)}
                                            validator={props.validator}
                                            disabled={checkReadOnly()}
                                            minLength={2}
                                            maxLength={64}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <ATextInput
                                            id={`last-name ${item.Id}`}
                                            label={t('AnelmaEvents:1032')}
                                            value={item.LastName}
                                            onChange={(v) => updateLastName(item.Id, v)}
                                            validator={props.validator}
                                            disabled={checkReadOnly()}
                                            minLength={2}
                                            maxLength={64}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <APhoneNumberInput
                                            id={`phone-number ${item.Id}`}
                                            label={t('AnelmaGeneral:1015')}
                                            value={{
                                                IsDefault: true,
                                                NormalizedNumber: item.PhoneNumber,
                                                Type: 53,
                                            }}
                                            onChange={(v) => updatePhoneNumber(item.Id, v)}
                                            disabled={checkReadOnly()}
                                            validator={props.validator}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <AEmailAddressInput
                                            id={`email ${item.Id}`}
                                            label={t('AnelmaGeneral:1013')}
                                            value={item.Email}
                                            onChange={(v) => updateEmail(item.Id, v)}
                                            disabled={checkReadOnly()}
                                            validator={props.validator}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <ATextInput
                                            id={`diet ${item.Id}`}
                                            label={t('AnelmaGeneral:1157')}
                                            value={item.Diet}
                                            onChange={(v) => updateDiet(item.Id, v)}
                                            disabled={checkReadOnly()}
                                            validator={props.validator}
                                            maxLength={8000}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <ATextInput
                                            id={`additional-info ${item.Id}`}
                                            label={t('AnelmaGeneral:1137')}
                                            value={item.AdditionalInformation}
                                            disabled={checkReadOnly()}
                                            onChange={(v) => updateAdditionalInformation(item.Id, v)}
                                            validator={props.validator}
                                            maxLength={8000}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={1} style={{ paddingTop: '110px' }}>
                                <ADeleteIconButton
                                    key={item.Id}
                                    disabled={deleteDisabled()}
                                    onClick={() => prepareDelete(item.Id)}
                                ></ADeleteIconButton>
                            </Grid>

                            <ConfirmDeletionDialog
                                open={showConfirmDeletion}
                                onClose={() => setShowConfirmDeletion(false)}
                                onDelete={() => prepareSave('delete')}
                                confirmation={t('AnelmaEvents:1037')}
                            />

                            <ConfirmDeletionDialog
                                key={item.Id}
                                open={showConfirmDeleteSingle}
                                onClose={() => setShowConfirmDeleteSingle(false)}
                                onDelete={() => deletePerson()}
                                confirmation={t('AnelmaEvents:1039')}
                            />
                        </Grid>
                    );
                })}
                <Grid container style={{ paddingRight: '40px', justifyContent: 'flex-end' }}>
                    <Grid item>
                        <AAddButton
                            size='small'
                            onClick={() => addNewPerson()}
                            disabled={checkReadOnly() || attendeesEdit}
                        >
                            {t('AnelmaEvents:1030')}
                        </AAddButton>
                    </Grid>
                </Grid>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            enabledWhileLoading: true,
                            label: t('AnelmaGeneral:1009'),
                            onClick: props.onClose,
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            label: t('AnelmaEvents:1026'),
                            disabled: cancelDisabled(),
                            onClick: () => {
                                setShowConfirmDeletion(true);
                            },
                            type: 'delete',
                        },
                        {
                            label: t('AnelmaGeneral:1001'),
                            disabled: checkReadOnly(),
                            onClick: () => {
                                prepareSave('');
                            },
                            type: 'save',
                        },
                    ],
                }}
            ></ADialogActions>
        </ADialog>
    );
}
