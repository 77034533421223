// Libraries
// MUI
import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material';

// Core
// Common
import { ADownloadIconButton } from '../../../common/buttons';

// Interfaces
import { IBasicDocumentInfo } from '../../../documents/IDocuments';

// Store
// API
import documentApi from '../../../api/documentApi';

// Feature - Communication
import Recipient from './Recipient';
import { timeFormat } from '../helpers/helperFunctions';

export interface IMessageListMessage {
    Created: string;
    SenderId: string;
    Body: string;
    Attachments?: IBasicDocumentInfo[];
}

export interface IMessageListProps {
    messages: IMessageListMessage[];
    loggedInUserId?: string | null;
}

const Wrapper = styled(Grid)<GridProps>(({ theme }) => ({
    padding: '20px 0px',
}));

const AttachmentList = styled('ul')(({ theme }) => ({
    listStyleType: 'none',
    margin: '0',
    padding: '0',
}));

const AttachtmentItem = styled('li')(({ theme }) => ({
    margin: '0',
    padding: '0',
    fontSize: '12px',
}));

const MessageLoggedInUser = styled('div')(({ theme }) => ({
    backgroundColor: '#DADADA',
    padding: '20px',
    margin: '0 40px 0 20px',
    wordWrap: 'break-word',
    position: 'relative',
}));

const MessageOtherUser = styled('div')(({ theme }) => ({
    padding: '20px',
    wordWrap: 'break-word',
    position: 'relative',
    backgroundColor: '#D5EDDA',
    margin: '0 20px 0 40px',
}));

const MessageCreatedTime = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '5px',
    bottom: '5px',
    fontSize: '0.75em',
}));

/** React component for displaying Anelma style message lists in Feedback and Messaging features.
 *  Can be used also for other features, when the need is somewhat same as in Feedback or Messaging.
 */
export default function MessageList({ messages, loggedInUserId }: IMessageListProps) {
    const attachmentsHandler = (attachments: IBasicDocumentInfo[]): any => {
        return (
            <AttachmentList>
                {attachments?.map((attachment) => (
                    <AttachtmentItem key={attachment.Id}>
                        <ADownloadIconButton
                            key='download'
                            onClick={() =>
                                documentApi.downloadFile(attachment.FileType, attachment.Id)
                            }
                            tooltip='download'
                        />
                        {attachment.DisplayName}
                    </AttachtmentItem>
                ))}
            </AttachmentList>
        );
    };

    const getMessageBody = (message: IMessageListMessage): JSX.Element => {
        return (
            <>
                {message.Body}
                <MessageCreatedTime>{timeFormat(message.Created)}</MessageCreatedTime>
                {message.Attachments ? <div>{attachmentsHandler(message.Attachments)}</div> : <></>}
            </>
        );
    };

    return (
        <>
            {messages
                .sort((a, b) => new Date(b.Created).valueOf() - new Date(a.Created).valueOf())
                .map((message) => {
                    return (
                        <Wrapper key={message.Created} container>
                            <Grid item xs={4} sm={4}>
                                <Recipient styleClassName={''} personGuid={message.SenderId} />
                            </Grid>

                            <Grid item xs={8} sm={8}>
                                {loggedInUserId === message.SenderId ? (
                                    <MessageLoggedInUser>
                                        {getMessageBody(message)}
                                    </MessageLoggedInUser>
                                ) : (
                                    <MessageOtherUser>{getMessageBody(message)}</MessageOtherUser>
                                )}
                            </Grid>
                        </Wrapper>
                    );
                })}
        </>
    );
}
