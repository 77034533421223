export class AnimalAnnouncementDataGridStrings {
    static readonly AnnouncementNumber = 'Number';

    // data grid column fields
    static readonly TypeField = 'Type';
    static readonly CreatedField = 'Created';
    static readonly BatchesFields = 'Batches';
    static readonly CollectWeekDayField = 'CollectWeekDay';
    static readonly ChainInformationField = 'ChainInformation';
    static readonly AmountField = 'Amount';
    static readonly SalesTypeField = 'SalesType';
    static readonly StatusField = 'Status';
    static readonly HoldingSiteField = 'HoldingSiteId';
    static readonly InfoField = 'Info';
    static readonly BatchInfoField = 'BatchInfo';
}
