import { GridLocaleText } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';

interface DataWithId {
    Id: number | string;
}

class GridUtils {
    addMissingDataPlaceholder<T>(data: T[], props: string[], placeholder: string = '-'): T[] {
        return data.map((i: any) => {
            const d = { ...i };
            props.forEach((prop) => {
                if (d[prop] === undefined || d[prop] === '') d[prop] = placeholder;
            });
            return d;
        });
    }

    addGridId<T>(data: T[]): T[] {
        return data.map((i, index) => {
            return {
                ...i,
                id: index,
            };
        });
    }

    copyGridId<T extends DataWithId>(data: T[]): T[] {
        return data.map((i) => {
            return {
                ...i,
                id: i.Id,
            };
        });
    }

    getGridLocaleTxt(t: TFunction): GridLocaleText {
        return {
            noRowsLabel: t('AnelmaGeneral:1101'),
            noResultsOverlayLabel: t('AnelmaGeneral:1102'),
            toolbarDensity: 'Density',
            toolbarDensityLabel: 'Density',
            toolbarDensityCompact: 'Compact',
            toolbarDensityStandard: 'Standard',
            toolbarDensityComfortable: 'Comfortable',
            toolbarColumns: 'Columns',
            toolbarColumnsLabel: 'Select columns',
            toolbarFilters: t('AnelmaGeneral:1081'),
            toolbarFiltersLabel: t('AnelmaGeneral:1082'),
            toolbarFiltersTooltipHide: t('AnelmaGeneral:1083'),
            toolbarFiltersTooltipShow: t('AnelmaGeneral:1082'),
            toolbarFiltersTooltipActive: (count) =>
                t(`AnelmaGeneral:${count !== 1 ? '1100' : '1099'}`).replace('{count}', `${count}`),
            toolbarQuickFilterPlaceholder: '',
            toolbarQuickFilterLabel: '',
            toolbarQuickFilterDeleteIconLabel: '',
            toolbarExport: 'Export',
            toolbarExportLabel: 'Export',
            toolbarExportCSV: 'Download as CSV',
            toolbarExportPrint: 'Print',
            toolbarExportExcel: 'Download as Excel',
            columnsPanelTextFieldLabel: 'Find column',
            columnsPanelTextFieldPlaceholder: 'Column title',
            columnsPanelDragIconLabel: 'Reorder column',
            columnsPanelShowAllButton: 'Show all',
            columnsPanelHideAllButton: 'Hide all',
            filterPanelAddFilter: t('AnelmaGeneral:1111'),
            filterPanelRemoveAll: 'Remove all',
            filterPanelDeleteIconLabel: t('AnelmaGeneral:1087'),
            filterPanelLogicOperator: 'Logical operator',
            filterPanelOperator: t('AnelmaGeneral:1086'),
            filterPanelOperatorAnd: t('AnelmaGeneral:1113'),
            filterPanelOperatorOr: t('AnelmaGeneral:1114'),
            filterPanelColumns: t('AnelmaGeneral:1084'),
            filterPanelInputLabel: t('AnelmaGeneral:1085'),
            filterPanelInputPlaceholder: '',
            filterOperatorContains: t('AnelmaGeneral:1088'),
            filterOperatorEquals: t('AnelmaGeneral:1089'),
            filterOperatorStartsWith: t('AnelmaGeneral:1090'),
            filterOperatorEndsWith: t('AnelmaGeneral:1091'),
            filterOperatorIs: t('AnelmaGeneral:1092'),
            filterOperatorNot: t('AnelmaGeneral:1112'),
            filterOperatorAfter: t('AnelmaGeneral:1115'),
            filterOperatorOnOrAfter: t('AnelmaGeneral:1117'),
            filterOperatorBefore: t('AnelmaGeneral:1116'),
            filterOperatorOnOrBefore: t('AnelmaGeneral:1118'),
            filterOperatorIsEmpty: t('AnelmaGeneral:1096'),
            filterOperatorIsNotEmpty: t('AnelmaGeneral:1097'),
            filterOperatorIsAnyOf: 'Is any of',
            filterValueAny: t('AnelmaGeneral:1093'),
            filterValueTrue: t('AnelmaGeneral:1094'),
            filterValueFalse: t('AnelmaGeneral:1095'),
            columnMenuLabel: 'Menu',
            columnMenuShowColumns: 'Show columns',
            columnMenuManageColumns: 'Manage columns',
            columnMenuFilter: 'Filter',
            columnMenuHideColumn: 'Hide',
            columnMenuUnsort: 'Unsort',
            columnMenuSortAsc: 'Sort by ASC',
            columnMenuSortDesc: 'Sort by DESC',
            columnHeaderFiltersTooltipActive: (count) =>
                t(`AnelmaGeneral:${count !== 1 ? '1100' : '1099'}`).replace('{count}', `${count}`),
            columnHeaderFiltersLabel: 'Show filters',
            columnHeaderSortIconLabel: t('AnelmaGeneral:1098'),
            footerRowSelected: (count: number) =>
                count !== 1
                    ? `${count.toLocaleString()} rows selected`
                    : `${count.toLocaleString()} row selected`,
            footerTotalRows: 'Total Rows:',
            footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
            checkboxSelectionHeaderName: 'Checkbox selection',
            checkboxSelectionSelectAllRows: 'Select all',
            checkboxSelectionUnselectAllRows: 'Unselect all',
            checkboxSelectionSelectRow: 'Select row',
            checkboxSelectionUnselectRow: 'Unselect row',
            booleanCellTrueLabel: 'true',
            booleanCellFalseLabel: 'false',
            actionsCellMore: 'More',
            pinToLeft: 'Pin to left',
            pinToRight: 'Pin to right',
            unpin: 'Unpin',
            treeDataGroupingHeaderName: 'Group',
            treeDataExpand: 'Expand',
            treeDataCollapse: 'Collapse',
            groupingColumnHeaderName: 'Group',
            groupColumn: (name: string) => name,
            unGroupColumn: (name: string) => name,
            detailPanelToggle: 'Toggle',
            expandDetailPanel: 'Expand',
            collapseDetailPanel: 'Collapse',
            rowReorderingHeaderName: 'Reorder',
            aggregationMenuItemHeader: 'Aggregate',
            aggregationFunctionLabelSum: 'Total',
            aggregationFunctionLabelAvg: 'Average',
            aggregationFunctionLabelMin: 'Min',
            aggregationFunctionLabelMax: 'Max',
            aggregationFunctionLabelSize: 'Size',
            MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} of ${count}`,
            },
            'filterOperator=': '=',
            'filterOperator!=': '!=',
            'filterOperator>': '>',
            'filterOperator>=': '>=',
            'filterOperator<': '<',
            'filterOperator<=': '<=',
            headerFilterOperatorContains: t('AnelmaGeneral:1088'),
            headerFilterOperatorEquals: t('AnelmaGeneral:1089'),
            headerFilterOperatorStartsWith: t('AnelmaGeneral:1090'),
            headerFilterOperatorEndsWith: t('AnelmaGeneral:1091'),
            headerFilterOperatorIs: t('AnelmaGeneral:1092'),
            headerFilterOperatorNot: t('AnelmaGeneral:1112'),
            headerFilterOperatorAfter: t('AnelmaGeneral:1115'),
            headerFilterOperatorOnOrAfter: t('AnelmaGeneral:1117'),
            headerFilterOperatorBefore: t('AnelmaGeneral:1116'),
            headerFilterOperatorOnOrBefore: t('AnelmaGeneral:1118'),
            headerFilterOperatorIsEmpty: t('AnelmaGeneral:1096'),
            headerFilterOperatorIsNotEmpty: t('AnelmaGeneral:1097'),
            headerFilterOperatorIsAnyOf: 'Is any of',
            'headerFilterOperator=': '=',
            'headerFilterOperator!=': '!=',
            'headerFilterOperator>': '>',
            'headerFilterOperator>=': '>=',
            'headerFilterOperator<': '<',
            'headerFilterOperator<=': '<=',
        };
    }
}

export default new GridUtils();
