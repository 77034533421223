// Libraries
import moment from 'moment';
import { useState, useEffect, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Container } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../core/resources';
import FormValidator from '../../core/FormValidator';

// Common
import ViewLoader from '../../common/ViewLoader';
import { ASearchIconButton } from '../../common/buttons';
import { ARadioButtons, ATextInput, AInputItem } from '../../common/inputs';

// Interfaces
import { ICQRSEventBase } from '../../interfaces/ILog';

// Feature
import {
    getLog,
    getLogByCompanyName,
    getLogByPersonName,
    getLogFromPerod,
    getUserLog,
} from '../api/AuditTrailAPI';
import { ParseCommandToString } from '../helpers/CommandEventParser';

export interface AuditTrailSearchBarProps {
    beforeSearch: Function;
    afterSearch: (newLogList: ICQRSEventBase[]) => any;
    setLoaded: (v: boolean) => void;
    loaded: () => boolean;
    searchInitiated: boolean;
}

export default function AuditTrailSearchBar(props: AuditTrailSearchBarProps) {
    const validator = new FormValidator();
    const [AALoggedOperationResources, setAALoggedOperationResources] = useState<ParameterValues>(
        []
    );
    const [AAFilterByDateResources, setAAFilterByDateResources] = useState<ParameterValues>([]);
    const [
        AAFilterByOperationResources,
        setAAFilterByOperationResources,
    ] = useState<ParameterValues>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [loaded, setLoaded] = useState<boolean>(false);
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaAudit']);
    var searchPeriodOptions: AInputItem<number>[] = [];
    if (AAFilterByDateResources[0])
        searchPeriodOptions = [
            { text: AAFilterByDateResources[0].text, value: 24 },
            { text: AAFilterByDateResources[1].text, value: 48 },
            { text: AAFilterByDateResources[2].text, value: 168 },
            { text: AAFilterByDateResources[3].text, value: 0 },
        ];
    const [searchPeriod, setSearchPeriod] = useState<number>(0);
    var commandTypeOptions: AInputItem<string>[] = [];
    if (AAFilterByOperationResources[0])
        commandTypeOptions = [
            { text: AAFilterByOperationResources[0].text, value: 'create' },
            { text: AAFilterByOperationResources[1].text, value: 'update' },
            { text: AAFilterByOperationResources[3].text, value: 'delete' },
            { text: AAFilterByOperationResources[2].text, value: 'all' },
        ];
    const [searchCommandType, setSearchCommandType] = useState<string>('all');

    const onKeyUp = (ev: KeyboardEvent<HTMLDivElement>) => {
        if (ev.key === 'Enter') {
            search();
        }
    };

    const search = () => {
        if (!searchTerm) return;
        props.beforeSearch();

        var res: ICQRSEventBase[] = [];

        if (searchPeriod === searchPeriodOptions[3].value)
            getLog(searchTerm).then((r) => {
                getLogByCompanyName(searchTerm).then((r) => {
                    getLogByPersonName(searchTerm).then((r) => {
                        filterResults(r.data.Items).forEach((r) => res.push(r));
                        props.afterSearch(res);
                    });
                    filterResults(r.data.Items).forEach((r) => res.push(r));
                });
                filterResults(r.data.Items).forEach((r) => res.push(r));
            });
        else {
            var toDate = moment().utc().toISOString();
            var fromDate = moment().utc().subtract(searchPeriod, 'hours').toISOString();

            getLogFromPerod(searchTerm, fromDate, toDate).then((r) => {
                getLogByCompanyName(searchTerm, fromDate, toDate).then((r) => {
                    getLogByPersonName(searchTerm, fromDate, toDate).then((r) => {
                        filterResults(r.data.Items).forEach((r) => res.push(r));
                        props.afterSearch(res);
                    });
                    filterResults(r.data.Items).forEach((r) => res.push(r));
                });
                filterResults(r.data.Items).forEach((r) => res.push(r));
            });
        }

        props.setLoaded(true);
    };

    function filterResults(results: ICQRSEventBase[]) {
        if (searchCommandType != '') {
            switch (searchCommandType) {
                case 'create':
                    results = results.filter(
                        (e) => ParseCommandToString(e, AALoggedOperationResources).code === '01'
                    );
                    break;
                case 'update':
                    results = results.filter((e) => {
                        let command = ParseCommandToString(e, AALoggedOperationResources);
                        return command.code === '02' || command.code == '03';
                    });
                    break;
                case 'delete':
                    results = results.filter((e) => {
                        let command = ParseCommandToString(e, AALoggedOperationResources);
                        return command.code === '04' || command.code === '06';
                    });
                    break;
            }
        }
        return results;
    }

    useEffect(() => {
        getUserLog().then((r) => {
            if (r.data.Items.length > 0) setSearchTerm(r.data.Items[0].Author ?? '');
            props.afterSearch(r.data.Items);
            paramRepository.load(['AnelmaAudit']).then(() => {
                setAALoggedOperationResources(
                    paramRepository.resource('AnelmaAudit', 'LoggedOperation')
                );
                setAAFilterByDateResources(paramRepository.resource('AnelmaAudit', 'FilterByDate'));
                setAAFilterByOperationResources(
                    paramRepository.resource('AnelmaAudit', 'FilterByOperation')
                );
                props.setLoaded(true);
                setLoaded(true);
            });
        });
    }, []);

    useEffect(() => {
        search();
    }, [searchPeriod]);

    useEffect(() => {
        search();
    }, [searchCommandType]);

    useEffect(() => {
        search();
    }, [props.searchInitiated === true])

    return (
        <Container>
            {loaded ? (
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={8} lg={8} style={{ marginBottom: '12px' }}>
                        <ATextInput
                            id='search-term'
                            label={t('AnelmaAudit:1001')}
                            onChange={(v) => setSearchTerm(v)}
                            onKeyUp={onKeyUp}
                            style={{ marginLeft: '10px', marginRight: '10px', width: '400px' }}
                            validator={validator}
                            value={searchTerm}
                            withoutContainer
                        />

                        <ASearchIconButton
                            onClick={() => search()}
                            style={{ marginTop: '12px' }}
                            type='action'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} style={{ marginLeft: '-30px' }}>
                        <ARadioButtons
                            id='search-period'
                            items={searchPeriodOptions}
                            onChange={(v) => {
                                setSearchPeriod(v as number);
                            }}
                            validator={validator}
                            value={searchPeriod}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} style={{ marginLeft: '-30px' }}>
                        <ARadioButtons
                            id='operation'
                            items={commandTypeOptions}
                            onChange={(v) => {
                                setSearchCommandType(v as string);
                            }}
                            validator={validator}
                            value={searchCommandType}
                        />
                    </Grid>
                </Grid>
            ) : (
                <ViewLoader />
            )}
        </Container>
    );
}
