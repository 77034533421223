// Libraries
import EditorJS, { OutputData } from '@editorjs/editorjs';

// MUI
import { Grid, Typography } from '@mui/material';

// Common
import { ACopyIconButton, APasteIconButton } from '../../common/buttons';

// Feature
import Editor from './Editor';
import contentUtils from './contentUtils';

export interface EditorContainerProps {
    content: string | undefined;
    copiedContent: OutputData | undefined;
    editor: EditorJS | undefined;
    setCopiedContent: (content: OutputData) => void;
    setEditor: (editor: EditorJS) => void;
    title: string;
}

export default function EditorContainer(props: EditorContainerProps) {
    return (
        <>
            <br />
            <br />
            <Grid container>
                <Grid item sm={8}>
                    <Typography variant='h3'>{props.title}</Typography>
                </Grid>

                <Grid item sm={4} style={{ textAlign: 'right' }}>
                    <ACopyIconButton
                        onClick={() =>
                            props.editor?.save().then((copy) => props.setCopiedContent(copy))
                        }
                    />

                    <APasteIconButton
                        onClick={() => {
                            if (props.copiedContent) props.editor?.render(props.copiedContent);
                        }}
                        disabled={!props.copiedContent}
                    />
                </Grid>
            </Grid>

            <Editor
                data={contentUtils.parseOutputData(props.content)}
                setEditor={(e) => props.setEditor(e)}
            />
        </>
    );
}
