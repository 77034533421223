import ContentRaw from './ContentRaw';
import './contentList.scss';

interface ListItem {
    content: string;
    items: ListItem[];
}

export interface ContentListBaseProps {
    items: ListItem[];
    id: string;
}

interface ContentListProps extends ContentListBaseProps {
    type: 'ol' | 'ul';
    id: string;
}

export default function ContentList(props: ContentListProps) {
    const renderListHtml = (items: ListItem[], id: number | string): string => {
        if (!items.length) return '';

        return `<${props.type}>${items
            .map((i, index) => `<li>${i.content}${renderListHtml(i.items, `${id}-${index}`)}</li>`)
            .join('')}</${props.type}>`;
    };

    return (
        <ContentRaw
            html={renderListHtml(props.items, props.id.toString())}
            key={props.id.toString()}
            id={props.id.toString()}
        />
    );
}
