// Libraries
import { useEffect, useLayoutEffect, useState } from 'react';

// MUI
import { Container, Grid } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

// Core
// Common
// Interfaces
import { IFarmDetails } from '../../interfaces/IBusinessEntities';
import { IFilteredAnimalList } from '../../interfaces/IAnimal';

// Store
// API
// Feature - Animal Carousel
import ACarouselItem, { ACarouselDropdownItem } from './ACarouselItem';

interface ACarouselDropdownSelection {
    id: number;
    selectedDropdownOption: number;
}

export interface ACarouselAdditionalBoxInfo {
    boxId: number;
    iconInfo: string;
}

export interface ACourouselItemList {
    additionalText: string;
    additionalInfo: ACarouselAdditionalBoxInfo[];
    dropdownItems: ACarouselDropdownItem[];
    id: number;
}

export interface ACarouselProps {
    carouselItems: ACourouselItemList[];
    onItemSelected: (id: number, selection: number) => void;
    isLoading: boolean;
    farmData: IFarmDetails | null;
    allAnimalData: IFilteredAnimalList[];
    slaughterAnnouncementCount: number;
    intermediationAnnouncementCount: number;
    bovinePurchaseAnnouncementCount: number;
    slaughterAnnouncementAnimalCount: number;
    intermediationAnnouncementAnimalCount: number;
    bovinePurchaseAnnouncementAnimalCount: number;
}

export default function ACarousel(props: ACarouselProps) {
    const [carouselIndex, setCarouselIndex] = useState<number>(0);
    const [carouselItemList, setCarouselItemList] = useState<ACourouselItemList[]>([]);
    const [openDropdown, setOpenDropdown] = useState<number | null>(null);
    const [selectedDropdownOptions, setSelectedDropdownOptions] = useState<
        ACarouselDropdownSelection[]
    >([]);
    const [refreshCarousel, setRefreshCarousel] = useState<boolean>(true);
    const [initialRefreshDone, setinitialRefreshDone] = useState<boolean>(false);
    const [currentlySelectedCarouselId, setCurrentlySelectedCarouselId] = useState<number>(0);

    const maxItemsToDisplay: number =
        window.innerWidth >= 1280
            ? 5
            : window.innerWidth >= 960
            ? 3
            : window.innerWidth >= 600
            ? 2
            : 1;

    useLayoutEffect(() => {
        const updateSize = () => {
            setRefreshCarousel(true);
        };

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        if (refreshCarousel) {
            let carouselItems: ACourouselItemList[] = [];

            for (let i = carouselIndex; i <= carouselIndex + maxItemsToDisplay; i++) {
                if (carouselItems.length < maxItemsToDisplay && props.carouselItems[i]) {
                    carouselItems.push(props.carouselItems[i]);
                }
            }

            setCarouselItemList(carouselItems);
            setRefreshCarousel(false);
        }
    }, [refreshCarousel]);

    useEffect(() => {
        if (props.isLoading && !initialRefreshDone) {
            setinitialRefreshDone(true);
        } else if (!props.isLoading && initialRefreshDone) {
            setRefreshCarousel(true);
        }
    }, [props.isLoading, initialRefreshDone]);

    useEffect(() => {
        setTimeout(() => setRefreshCarousel(true), 300);
    }, [props.farmData, props.allAnimalData]);

    useEffect(() => {
        if (props.carouselItems.length > 0 && selectedDropdownOptions.length === 0) {
            const initialSelectedDropdowns: ACarouselDropdownSelection[] = props.carouselItems.map(
                (i) => {
                    return { id: i.id, selectedDropdownOption: 1 } as ACarouselDropdownSelection;
                }
            );
            setSelectedDropdownOptions(initialSelectedDropdowns);
        }
    }, [props.carouselItems]);

    const handleCarouselIndexChange = (isPlus: boolean) => {
        if (isPlus && carouselIndex + 1 <= props.carouselItems.length - maxItemsToDisplay) {
            setCarouselIndex(carouselIndex + 1);
        } else if (!isPlus && carouselIndex - 1 >= 0) {
            setCarouselIndex(carouselIndex - 1);
        }
        setRefreshCarousel(true);
    };

    const handleCarouselItemChange = (itemIndex: number, selectionIndex: number) => {
        props.onItemSelected(itemIndex, selectionIndex);
    };

    const onDropdownToggle = (value: number | null) => {
        if (openDropdown === value) {
            setOpenDropdown(null);
        } else setOpenDropdown(value);
    };

    return (
        <Container id='anelma-animals-animal-list-carousel-container' style={{ height: '100px' }}>
            <Grid container>
                <Grid
                    id={'carousel-wheel-start'}
                    item
                    xs={1}
                    onClick={() => handleCarouselIndexChange(false)}
                >
                    <ArrowBackIos id='animal-carusel-arrow-left' />
                </Grid>

                {!refreshCarousel &&
                    carouselItemList.length > 0 &&
                    carouselItemList.map((item) => (
                        <Grid
                            id={'carouselItem_' + item.id}
                            key={item.id}
                            item
                            xs={10}
                            sm={5}
                            md={3}
                            lg={2}
                        >
                            <ACarouselItem
                                key={item.id}
                                additionalIcon={item.additionalInfo}
                                additionalText={item.additionalText}
                                currentlySelectedCarouselId={currentlySelectedCarouselId}
                                dropdownOptions={
                                    item &&
                                    item.dropdownItems != null &&
                                    item.dropdownItems.length > 0
                                        ? item.dropdownItems
                                        : []
                                }
                                isDropdownOpen={item ? openDropdown === item.id : false}
                                index={item ? item.id : -1}
                                onDropdownToggle={(v: number | null) => onDropdownToggle(v)}
                                onItemSelection={(itemIndex: number, selectionIndex: number) => {
                                    handleCarouselItemChange(itemIndex, selectionIndex);
                                }}
                                onDropdownSelection={(boxChoice) =>
                                    setCurrentlySelectedCarouselId(boxChoice)
                                }
                                slaughterAnnouncementCount={props.slaughterAnnouncementCount}
                                intermediationAnnouncementCount={
                                    props.intermediationAnnouncementCount
                                }
                                bovinePurchaseAnnouncementCount={
                                    props.bovinePurchaseAnnouncementCount
                                }
                                slaughterAnnouncementAnimalCount={
                                    props.slaughterAnnouncementAnimalCount
                                }
                                intermediationAnnouncementAnimalCount={
                                    props.intermediationAnnouncementAnimalCount
                                }
                                bovinePurchaseAnnouncementAnimalCount={
                                    props.bovinePurchaseAnnouncementAnimalCount
                                }
                            />
                        </Grid>
                    ))}

                <Grid
                    id={'carousel-wheel-end'}
                    item
                    xs={1}
                    onClick={() => handleCarouselIndexChange(true)}
                >
                    <ArrowForwardIos id='animal-carusel-arrow-right' />
                </Grid>
            </Grid>
        </Container>
    );
}
