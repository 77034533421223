import { IMainMenuItem } from '../mainMenu/IMainMenu';
import { RoutePaths } from '../../../../interfaces/enums';

export const economy: IMainMenuItem = {
    itemGroups: [
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.EconomyAccountings,
                    resource: 'AnelmaAccounting',
                    scope: 'LIST',
                    textCode: 1056,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.EconomyInvoices,
                    resource: 'AnelmaInvoice',
                    scope: 'LIST',
                    textCode: 1057,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.EconomyPriceList,
                    resource: 'AnelmaPriceList',
                    scope: 'LIST',
                    textCode: 1064,
                },
            ],
        },
        {
            headerCode: null,
            items: [
                {
                    href: RoutePaths.EconomyTaxYearSummary,
                    resource: 'AnelmaReporting',
                    scope: 'LIST',
                    textCode: 1081,
                },
            ],
        },
    ],
    textCode: 1035,
};
