// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../core/resources';
import FormValidator from '../../core/FormValidator';

// Common
import { ATextInput } from '../../common/inputs';
import { ASearchIconButton } from '../../common/buttons';

// Interfaces
import { IPersonSummary } from '../../interfaces/IBusinessEntities';

// Feature
import PersonSearchType from './PersonSearchType';
import personApi from '../../api/personApi';

export interface PersonSearchFormProps {
    afterSearch: (data: IPersonSummary[]) => void;
    beforeSearch: () => void;
    showInactive: boolean;
    showMoreInfo: boolean;
}

export default function PersonSearchForm(props: PersonSearchFormProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaPerson']);
    const validator = new FormValidator();

    const [seletedSearchType, setSelectedSearchType] = useState<string>('Name');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showInactive, setShowInactive] = useState<boolean>(props.showInactive);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(props.showInactive);

    const search = () => {
        if (!searchTerm) return;
        props.beforeSearch();
        personApi
            .getPersons(seletedSearchType, searchTerm, showInactive, showMoreInfo)
            .then((response) => {
                if (!response) return;

                props.afterSearch(response.Items);
            });
    };

    useEffect(() => setShowInactive(props.showInactive), [props]);
    useEffect(() => setShowMoreInfo(props.showMoreInfo), [props]);

    useEffect(() => search(), [showInactive, showMoreInfo]);

    return (
        <>
            <PersonSearchType
                onSearchTypeChange={(type) => setSelectedSearchType(type)}
                seletedSearchType={seletedSearchType}
                validator={validator}
            />

            <ATextInput
                id='search'
                label={t('AnelmaPerson:1020')}
                onChange={(v) => setSearchTerm(v)}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') search();
                }}
                style={{ marginLeft: '10px', marginRight: '10px', width: '300px' }}
                validator={validator}
                value={searchTerm}
                withoutContainer
            />

            <ASearchIconButton
                onClick={() => search()}
                style={{ marginTop: '12px' }}
                type='action'
            />
        </>
    );
}
