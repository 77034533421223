import moment from 'moment';
import { DialogModeEnum, SalesType } from '../../../interfaces/enums';
import { IAnimalAnnouncementBatch, ICollectWeek } from '../../../interfaces/IAnnouncement';
import { statuses } from '../../shared/constants/status';

export const collectWeekStartNotValid = (
    collectWeekStart: ICollectWeek,
    collectWeekEnd: ICollectWeek
) => {
    if (collectWeekStart.Year === collectWeekEnd.Year) {
        if (collectWeekStart.Week > collectWeekEnd.Week) return true;
    } else if (collectWeekStart.Year > collectWeekEnd.Year) return true;
    return false;
};

export const collectWeekEndNotValid = (
    collectWeekEnd: ICollectWeek,
    collectWeekStart: ICollectWeek
) => {
    if (collectWeekEnd.Year === collectWeekStart.Year) {
        if (collectWeekEnd.Week < collectWeekStart.Week) return true;
    } else if (collectWeekEnd.Year < collectWeekStart.Year) return true;
    return false;
};

export const collectWeekStartHasValue = (collectWeekStart: ICollectWeek) => {
    if (collectWeekStart.Week !== -1 && collectWeekStart.Year !== -1) return true;
    return false;
};

export const collectWeekEndHasValue = (collectWeekEnd: ICollectWeek) => {
    if (collectWeekEnd.Week !== -1 && collectWeekEnd.Year !== -1) return true;
    return false;
};

export const collectWeekString = (
    collectWeekStart?: ICollectWeek,
    collectWeekEnd?: ICollectWeek
): string => {
    const start = collectWeekStart && collectWeekStart.Year > 0 ? collectWeekStart : null;
    const end = collectWeekEnd && collectWeekEnd.Year > 0 ? collectWeekEnd : null;

    if (start && end)
        return start.Week === end.Week ? start.Week.toString() : `${start.Week}-${end.Week}`;
    if (start) return start.Week.toString();
    if (end) return end.Week.toString();
    return '';
};

export const collectWeekEditAllowed = (animal: { SalesType: number | SalesType }, status?: string) => {
    return Number(animal.SalesType) !== Number(SalesType.Pikalehmä) && (status !== statuses.LO);
};

const createCollectWeek = (now: moment.Moment) => {
    return { Year: now.isoWeekYear(), Week: now.isoWeek() };
};

export const defaultCollectWeek = (animal: { SalesType: number | SalesType }) => {
    if (Number(animal.SalesType) === Number(SalesType.Pikalehmä)) {
        const now = moment();
        const weekDay = now.isoWeekday();
        
        if (1 <= weekDay && weekDay <= 3) {
            // Monday - Wednesday
            return {
                Start: createCollectWeek(now),
                End: createCollectWeek(now),
            };
        } else if (weekDay === 4) {
            // Thursday
            return {
                Start: createCollectWeek(now),
                End: createCollectWeek(now.add(1, 'week')),
            };
        } else {
            // Friday - Sunday
            return {
                Start: createCollectWeek(now.add(1, 'week')),
                End: createCollectWeek(now),
            };
        }
    }
    return {
        Start: { Year: 0, Week: 0 },
        End: { Year: 0, Week: 0 },
    };
};

export const getQuickCowCollectWeeks = () => {
    const now = moment();
    const weekDay = now.isoWeekday();

    if (1 <= weekDay && weekDay <= 3) {
        // Monday - Wednesday
        return {
            Start: createCollectWeek(now),
            End: createCollectWeek(now),
        };
    } else if (weekDay === 4) {
        // Thursday
        return {
            Start: createCollectWeek(now),
            End: createCollectWeek(now.add(1, 'week')),
        };
    } else {
        // Friday - Sunday
        return {
            Start: createCollectWeek(now.add(1, 'week')),
            End: createCollectWeek(now),
        };
    }
};

export const batchesHaveValidCollectWeek = (batches: IAnimalAnnouncementBatch[], mode: DialogModeEnum): boolean => {
    if (mode === DialogModeEnum.Create) return true;
    for (let batch of batches) {
        if (
            !collectWeekStartHasValue(batch.CollectWeekStart) ||
            !collectWeekEndHasValue(batch.CollectWeekEnd)
        )      
            return false;
    }

    return true;
};
