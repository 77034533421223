import { AnelmaCultureType } from '../interfaces/types';

export const constructLanguageParamsForQlik = (culture: AnelmaCultureType): string => {
    switch (culture) {
        case 'fi':
        case 'fi-FI':
            return 'FI';
        case 'en-GB':
            return 'EN';
        case 'sv-SE':
            return 'SV';
    }
};
