// Libraries
import { createReactEditorJS } from 'react-editor-js';
import { OutputData } from '@editorjs/editorjs';
import { useTranslation } from 'react-i18next';
import { EDITOR_TOOLS } from './EditorTools';

export interface EditorProps {
    data: OutputData | undefined;
    setEditor: (editor: any) => void; // editorcore
}

export default function Editor(props: EditorProps) {
    const { t } = useTranslation(['AnelmaContent']);

    const ReactEditorJs = createReactEditorJS();

    return (
        <div id='editor' className='an-content-editor'>
            <ReactEditorJs
                defaultValue={props.data}
                value={props.data}
                onInitialize={(instance) => props.setEditor(instance)}
                placeholder={t('AnelmaContent:1030') as string}
                tools={EDITOR_TOOLS}
            />
        </div>
    );
}
