// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Container } from '@mui/material';
import { HourglassEmpty, PictureAsPdf } from '@mui/icons-material';

// Core
import { dayFormat } from '../../../../../core/constants';
import {
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../../../../core/resources';
import auth from '../../../../../core/authorization';

// Common
import {
    ADataGrid,
    ADataGridActions,
    AGridColumns,
    gridColumns,
} from '../../../../../common/dataGrid';
import {
    ADefaultIconButton,
    ADeleteIconButton,
    AEditIconButton,
    ASearchIconButton,
} from '../../../../../common/buttons';
import { TextsWithTooltip, TextWithTooltip } from '../../../../../common/typography';
import AAttachmentIconButton from '../../../../../common/buttons/AAttachmentIconButton';
import APriorityHighIconButton from '../../../../../common/buttons/APriorityHighIconButton';
import ADoneIconButton from '../../../../../common/buttons/ADoneIconButton';
import AWarningIconButton from '../../../../../common/buttons/AWarningIconButton';

// Interfaces
import {
    AnnouncementListDataGridColumnType,
    AnnouncementTypeEnum,
} from '../../../../../interfaces/enums';
import {
    IAnimalAnnouncement,
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
} from '../../../../../interfaces/IAnnouncement';
import { GUIDType } from '../../../../../interfaces/types';

// Store
// API
import animalAnnouncementApi from '../../../../../api/animalAnnouncementApi';
import chainInformationApi from '../../../../../api/chainInformationApi';

// Feature
import { IAnnouncementListDataGridProps } from '../../../../shared/types/propTypes';
import ConfirmDialog from '../../../../shared/components/confirm/ConfirmDialog';
import { AnimalAnnouncementDataGridStrings } from '../../../../shared/constants/animalAnnouncementDataGridStrings';
import { Strings } from '../../../../shared/constants/strings';
import {
    calculateAmountOfAnimals,
    calculateOrderedAnimalCount,
    collectWeekComparator,
    getAnnouncementAnimals,
    getUniqueCollectWeeks,
    handleAnimalAnnouncementDeletionRestrictions,
    isAnimalAnnouncementUpdateRestricted,
    hasAnnouncementResponseErrors,
} from '../../../helpers/data';
import ChainInformationDialog from '../../../../chain-information/components/ChainInformationDialog';
import { IChainInformationData } from '../../../../chain-information/IChainInformationDialog';
import { isAnimalsAndSalesType } from '../../../../shared/helpers/data';
import { AnnouncementStatus } from '../../../../shared/types/announcementStatus';
import { GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useAppSelector } from '../../../../../store/hooks';
import { fetchPersonRelations } from '../../../../../persons/fetchPersonRelations';
import printingApi from '../../../../../api/printingApi';

export default function AnnouncementListDataGrid(props: IAnnouncementListDataGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBovine',
        'AnelmaGeneral',
        'AnelmaTiltu',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const userData = useAppSelector((state) => state.userData.data);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState<string>('');
    const [collectWeekDayParams, setCollectWeekDayParams] = useState<ParameterValues>([]);
    const [bovineSalesTypeParams, setBovineSalesTypeParams] = useState<ParameterValues>([]);
    const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>();
    const [chainInformationDialogVisible, setChainInformationDialogVisible] =
        useState<boolean>(false);
    const [announcementId, setAnnouncementId] = useState<GUIDType>('');
    const [announcementNumber, setAnnouncementNumber] = useState<string>('');
    const [animalsForChainInformation, setAnimalsForChainInformation] = useState<
        IAnimalAnnouncementAnimal[]
    >([]);
    const [chainInformations, setChainInformations] = useState<IChainInformationData[]>([]);
    const [isLoadingChainInfos, setIsLoadingChainInfos] = useState<boolean>(false);
    const [announcementIds, setAnnouncementIds] = useState<string[]>(props.data.map((i) => i.Id));
    const [snellmanUser, setSnellmanUser] = useState<boolean>(false);

    const openChainInformationDialog = (data: any) => {
        setAnnouncementId(data.Id);
        setAnnouncementNumber(data.Number);
        setAnimalsForChainInformation(getAnnouncementAnimals(data));
        setChainInformationDialogVisible(true);
    };

    useEffect(() => {
        if (userData) {
            fetchPersonRelations(userData.Logins[0].Login).then((response) => {
                setSnellmanUser(response[0]);
            });
        }
    }, [userData]);

    useEffect(() => {
        setIsLoadingChainInfos(true);
        setAnnouncementIds(props.data.map((i) => i.Id));
        setIsLoadingChainInfos(false);
    }, [props.data]);

    useEffect(() => {
        if (announcementIds.length > 0 && isLoadingChainInfos === false) {
            setIsLoadingChainInfos(true);
            chainInformationApi.loadMultipleChainInformations(announcementIds).then((response) => {
                if (response) {
                    let chainInfos: IChainInformationData[] = [];
                    for (let i = 0; i < response.Items.length; i++) {
                        let chainInfo: IChainInformationData = {
                            AnnouncementId: response.Items[i].AnnouncementId,
                            MedicatedFeed: response.Items[i].MedicatedFeed,
                            HealthInformation: response.Items[i].HealthInformation,
                            AuthorityRegulations: response.Items[i].AuthorityRegulations,
                            ForeignObject: response.Items[i].ForeignObject,
                            GmFree: response.Items[i].GmFree,
                            MedicalAccounting: response.Items[i].MedicalAccounting,
                            WaitingTimeOver: response.Items[i].WaitingTimeOver,
                            NasevaInfo: response.Items[i].NasevaInfo,
                            SlaughterHouseInfo: response.Items[i].SlaughterHouseInfo,
                            MedicationInfo: response.Items[i].MedicationInfo,
                            Anomalies: response.Items[i].Anomalies,
                            Regulations: response.Items[i].Regulations,
                            SamplingOrder: response.Items[i].SamplingOrder,
                            VetName: response.Items[i].VetName,
                            AdditionalInfo: response.Items[i].AdditionalInfo,
                            ForeignObjectInfo: response.Items[i].ForeignObjectInfo,
                            AnimalSymptoms: response.Items[i].Symptoms,
                            OtherSymptom: response.Items[i].OtherSymptom,
                            AnimalDiseases: response.Items[i].Diseases,
                            OtherDisease: response.Items[i].OtherDisease,
                            MedicatedAnimals: response.Items[i].Medications,
                            FormConfirmation: response.Items[i].FormConfirmation,
                            Signature: response.Items[i].Signature,
                            Signatory: response.Items[i].Signatory,
                            SignatureDate: response.Items[i].SignatureDate,
                        };
                        chainInfos.push(chainInfo);
                    }
                    setChainInformations(chainInfos);
                    setIsLoadingChainInfos(false);
                }
            });
        }
    }, [announcementIds, chainInformationDialogVisible]);

    useEffect(() => {
        if (
            props.canCreateAnimalAnnouncement !== null &&
            props.canCreateAnimalAnnouncement === false
        )
            enqueueSnackbar(t('AnelmaBovine:1093'), {
                variant: 'warning',
            });
    }, [props.canCreateAnimalAnnouncement]);

    const checkChainInfoFound = (announcementId: GUIDType) => {
        let chainInfoFound = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoFound) {
            return false;
        } else {
            return true;
        }
    };

    const checkChainInfoConfirmed = (announcementId: GUIDType) => {
        let chainInfoToCheck = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoToCheck?.FormConfirmation) {
            return true;
        } else {
            return false;
        }
    };

    const checkChainInfoNotify = (announcementId: GUIDType) => {
        let chainInfoToCheck = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoToCheck) {
            if (
                chainInfoToCheck.MedicatedFeed === true ||
                chainInfoToCheck.HealthInformation === true ||
                chainInfoToCheck.MedicalAccounting === true ||
                chainInfoToCheck.AuthorityRegulations === true ||
                chainInfoToCheck.ForeignObject === true ||
                chainInfoToCheck.GmFree === true ||
                chainInfoToCheck.AdditionalInfo.length > 0 ||
                chainInfoToCheck.MedicatedAnimals.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        }
    };

    const checkChainInformationAuth = (announcementId: GUIDType) => {
        if (
            auth.canUpdateChainInformationAdmin === true &&
            checkChainInfoFound(announcementId) === false &&
            auth.canViewChainInformation === false
        ) {
            return false;
        } else if (
            auth.canUpdateChainInformationAdmin &&
            checkChainInfoFound(announcementId) === true
        ) {
            return false;
        } else if (auth.canViewChainInformation) {
            return false;
        } else {
            return true;
        }
    };

    const awaitingAnnouncementButton = (params: GridRenderCellParams) => (
        <ADefaultIconButton
            icon={
                <>
                    <HourglassEmpty />
                    {params.value && Number(params.value) > 0 ? params.value : ''}
                </>
            }
            tooltip={t('AnelmaBovine:1159')?.toString() ?? ''}
            onClick={() => {
                const announcementId = params.row.Id as GUIDType;
                animalAnnouncementApi.animalAnnouncementState(announcementId).then((res) => {
                    if (
                        res?.Entity.Status === AnnouncementStatus.Completed ||
                        res?.Entity.Status === AnnouncementStatus.Failed
                    ) {
                        props.handleAnnouncementReload(announcementId);
                    }
                });
            }}
            disabled={false}
        />
    );

    const getAwaitingAnnouncementButton = (params: GridRenderCellParams) => {
        const value = params.value as number;

        return value === 0 || params.row.Status === '1'
            ? awaitingAnnouncementButton(params)
            : value;
    };

    const defaultColumns: AGridColumns = [
        {
            field: AnimalAnnouncementDataGridStrings.AnnouncementNumber,
            headerName: t('AnelmaBovine:1028'),
            renderCell: getAwaitingAnnouncementButton,
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.TypeField,
            headerName: t('AnelmaBovine:1030'),
            renderCell: (params) => resolveAnnouncementTypeAsText(params.row.Type),
            width: 160,
        },
        gridColumns.dateTime(
            AnimalAnnouncementDataGridStrings.CreatedField,
            t('AnelmaBovine:1033'),
            dayFormat
        ),
        {
            field: AnimalAnnouncementDataGridStrings.BatchesFields,
            headerName: t('AnelmaBovine:1034'),
            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params) => {
                return getUniqueCollectWeeks(params.row.Batches as IAnimalAnnouncementBatch[]);
            },
            sortComparator: (x1, x2) =>
                collectWeekComparator(String((x1 as any[])[0]), String((x2 as any[])[0])),
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.CollectWeekDayField,
            headerName: t('AnelmaBovine:1042'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return params.row.Batches.slice(0, 1).map((row: IAnimalAnnouncementBatch) => {
                    return collectWeekDayParams.find((_) => _.code === String(row.CollectWeekDay))
                        ?.text;
                });
            },
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.ChainInformationField,
            headerName: t('AnelmaBovine:1067'),
            renderCell: (params) =>
                params.row.Type === AnnouncementTypeEnum.Slaughter.valueOf() ||
                params.row.Type === AnnouncementTypeEnum.ManualBovineSlaughter.valueOf() ||
                params.row.Type === AnnouncementTypeEnum.SlaughterWithChainInformation.valueOf() ? (
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                        <AAttachmentIconButton
                            disabled={
                                params.row.Number === 0 || checkChainInformationAuth(params.row.Id)
                            }
                            key={`${params.id}-attachment`}
                            onClick={() => {
                                openChainInformationDialog(params.row);
                            }}
                            tooltip={t('AnelmaGeneral:1075')}
                        />
                        {checkChainInfoFound(params.row.Id) && (
                            <AWarningIconButton
                                onClick={() => {}}
                                tooltip={t('AnelmaBovine:1144')}
                            />
                        )}
                        {checkChainInfoConfirmed(params.row.Id) && (
                            <ADoneIconButton onClick={() => {}} tooltip={t('AnelmaBovine:1145')} />
                        )}
                        {checkChainInfoNotify(params.row.Id) && (
                            <APriorityHighIconButton
                                onClick={() => {}}
                                tooltip={t('AnelmaBovine:1146')}
                            />
                        )}
                    </Grid>
                ) : null,
            width: 120,
            sortable: false,
        },
        {
            field: AnimalAnnouncementDataGridStrings.AmountField,
            headerName: t('AnelmaBovine:1037'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return getAnimalCount(params.row.Batches, params.row.Type);
            },
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.SalesTypeField,
            headerName: t('AnelmaBovine:1019'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return (
                    params.row.Batches.slice(0, 1).map((row: IAnimalAnnouncementBatch) => {
                        return getSalesType(
                            isAnimalsAndSalesType(row) ? row.Animals[0].SalesType : 0,
                            bovineSalesTypeParams
                        );
                    })[0] || ''
                );
            },
            width: 160,
        },
        gridColumns.singleSelectText(
            AnimalAnnouncementDataGridStrings.HoldingSiteField,
            [],
            t('AnelmaBovine:1063'),
            160
        ),
        gridColumns.singleSelectText(
            AnimalAnnouncementDataGridStrings.InfoField,
            [],
            t('AnelmaBovine:1039'),
            160
        ),
    ];

    const bovineIntermediationAnnouncementColumns: AGridColumns = [
        {
            field: AnimalAnnouncementDataGridStrings.AnnouncementNumber,
            headerName: t('AnelmaBovine:1028'),
            renderCell: getAwaitingAnnouncementButton,
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.TypeField,
            headerName: t('AnelmaBovine:1030'),
            renderCell: (params) => resolveAnnouncementTypeAsText(params.row.Type),
            width: 160,
        },
        gridColumns.dateTime(
            AnimalAnnouncementDataGridStrings.CreatedField,
            t('AnelmaBovine:1033'),
            dayFormat
        ),
        {
            field: AnimalAnnouncementDataGridStrings.BatchesFields,
            headerName: t('AnelmaBovine:1034'),
            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params) => {
                return getUniqueCollectWeeks(params.row.Batches as IAnimalAnnouncementBatch[]);
            },
            sortComparator: (x1, x2) =>
                collectWeekComparator(String((x1 as any[])[0]), String((x2 as any[])[0])),
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.CollectWeekDayField,
            headerName: t('AnelmaBovine:1042'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return params.row.Batches.slice(0, 1).map((row: IAnimalAnnouncementBatch) => {
                    return collectWeekDayParams.find((_) => _.code === String(row.CollectWeekDay))
                        ?.text;
                });
            },
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.AmountField,
            headerName: t('AnelmaBovine:1037'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return getAnimalCount(params.row.Batches, params.row.Type);
            },
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.SalesTypeField,
            headerName: t('AnelmaBovine:1019'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return (
                    params.row.Batches.slice(0, 1).map((row: IAnimalAnnouncementBatch) => {
                        return getSalesType(
                            isAnimalsAndSalesType(row) ? row.Animals[0].SalesType : 0,
                            bovineSalesTypeParams
                        );
                    })[0] || ''
                );
            },
            width: 160,
        },
        gridColumns.singleSelectText(
            AnimalAnnouncementDataGridStrings.HoldingSiteField,
            [],
            t('AnelmaBovine:1063'),
            160
        ),
        gridColumns.singleSelectText(
            AnimalAnnouncementDataGridStrings.InfoField,
            [],
            t('AnelmaBovine:1039'),
            160
        ),
    ];

    const bovinePurchaseAnnouncementColumns: AGridColumns = [
        {
            field: AnimalAnnouncementDataGridStrings.AnnouncementNumber,
            headerName: t('AnelmaBovine:1028'),
            renderCell: getAwaitingAnnouncementButton,
            width: 160,
        },
        {
            field: AnimalAnnouncementDataGridStrings.SalesTypeField,
            headerName: t('AnelmaBovine:1015'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return (
                    params.row.Batches.slice(0, 1).map((row: IAnimalAnnouncementBatch) => {
                        return getSalesType(
                            row.SalesType !== null && row.SalesType !== undefined
                                ? row.SalesType
                                : 0,
                            bovineSalesTypeParams
                        );
                    })[0] || ''
                );
            },
            width: 150,
        },
        {
            field: AnimalAnnouncementDataGridStrings.AmountField,
            headerName: t('AnelmaBovine:1037'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return getAnimalCount(params.row.Batches, params.row.Type);
            },
            width: 130,
        },
        {
            field: AnimalAnnouncementDataGridStrings.BatchesFields,
            headerName: t('AnelmaBovine:1127'),
            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params) => {
                return getUniqueCollectWeeks(params.row.Batches as IAnimalAnnouncementBatch[]);
            },
            sortComparator: (x1, x2) =>
                collectWeekComparator(String((x1 as any[])[0]), String((x2 as any[])[0])),
            width: 145,
        },
        gridColumns.dateTime(
            AnimalAnnouncementDataGridStrings.CreatedField,
            t('AnelmaBovine:1033'),
            dayFormat,
            130
        ),
        {
            field: AnimalAnnouncementDataGridStrings.BatchInfoField,
            headerName: t('AnelmaBovine:1039'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return params.row.Batches[0].BatchInfo;
            },
            width: 140,
        },
        gridColumns.singleSelectText(
            AnimalAnnouncementDataGridStrings.HoldingSiteField,
            [],
            t('AnelmaBovine:1063'),
            150
        ),
    ];

    useEffect(() => {
        paramRepository.load(['AnelmaAnimalAnnouncement', 'AnelmaBovine']).then(() => {
            setCollectWeekDayParams(
                paramRepository.resource('AnelmaAnimalAnnouncement', 'CollectWeekDay')
            );
            setBovineSalesTypeParams(paramRepository.resource('AnelmaBovine', 'SalesType'));
        });
    }, []);

    const handleConfirmDeleteDialogData = (id: string) => {
        setConfirmDialogVisible(true);
        setSelectedAnnouncement(id);
    };

    const deleteAnimalAnnouncement = () => {
        setIsLoading(true);
        const animalAnnouncement = props.data.find((a) => a.Id === selectedAnnouncement);
        if (animalAnnouncement !== undefined) {
            if (animalAnnouncement.Number) {
                animalAnnouncementApi
                    .deleteAnimalAnnouncement(Number(animalAnnouncement.Number))
                    .then((response) => {
                        if (!response) {
                            enqueueSnackbar(`${t('AnelmaGeneral:1019')}`, {
                                variant: 'error',
                            });
                            setIsLoading(false);
                            return;
                        }
                        if (hasAnnouncementResponseErrors(response)) {
                            const errorCodes = Array.from(
                                new Set(response.Summary.Errors.map((_) => _.Code))
                            );
                            for (const errorCode of errorCodes) {
                                enqueueSnackbar(t(`AnelmaTiltu:${errorCode}`), {
                                    variant: 'error',
                                });
                            }
                            setIsLoading(false);
                            return;
                        }
                        setSelectedAnnouncement('');
                        props.handleAnnouncementDeletionFromTiltu(animalAnnouncement.Number);
                        setIsLoading(false);
                        const type = animalAnnouncement.Type;
                        if (
                            type !== AnnouncementTypeEnum.ManualBovineIntermediation &&
                            type !== AnnouncementTypeEnum.ManualBovineSlaughter
                        )
                            chainInformationApi.deleteChainInformation(selectedAnnouncement).then();
                    });
            } else {
                animalAnnouncementApi
                    .deleteAnimalAnnouncementById(animalAnnouncement.Id)
                    .then((response) => {
                        setSelectedAnnouncement('');
                        props.handleAnnouncementDeletionFromTiltuById(animalAnnouncement.Id);
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        console.log('ERR', err);
                    });
            }
        }
    };

    const resolveAnnouncementTypeAsText = (type: AnnouncementTypeEnum): string => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
            case AnnouncementTypeEnum.ManualBovineSlaughter:
            case AnnouncementTypeEnum.SlaughterWithChainInformation:
                return t('AnelmaBovine:1070');
            case AnnouncementTypeEnum.Intermediation:
            case AnnouncementTypeEnum.ManualBovineIntermediation:
                return t('AnelmaBovine:1071');
            case AnnouncementTypeEnum.BovinePurchase:
                return t('AnelmaBovine:1072');
        }
    };

    const resolveEditTooltipText = (type: AnnouncementTypeEnum): string => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
                return t('AnelmaBovine:1044');
            default:
                return t('AnelmaGeneral:1075');
        }
    };

    const getSalesType = (salesType: number, bovineSalesTypeParams: ParameterValues) => {
        return salesType > 0
            ? bovineSalesTypeParams.find((_) => _.code === String(salesType))?.text
            : '';
    };

    const getAnimalCount = (
        batches: IAnimalAnnouncementBatch[],
        announcementType: AnnouncementTypeEnum
    ): number => {
        switch (announcementType) {
            case AnnouncementTypeEnum.Slaughter:
            case AnnouncementTypeEnum.ManualBovineSlaughter:
            case AnnouncementTypeEnum.SlaughterWithChainInformation:
                return calculateAmountOfAnimals(batches);
            case AnnouncementTypeEnum.Intermediation:
            case AnnouncementTypeEnum.ManualBovineIntermediation:
                return calculateAmountOfAnimals(batches);
            case AnnouncementTypeEnum.BovinePurchase:
                return calculateOrderedAnimalCount(batches);
        }
    };

    const handleEditBovineAnnouncement = (item: IAnimalAnnouncement) => {
        props.handleModification(item as IAnimalAnnouncement, checkChainInfoFound(item.Id));
    };

    const handleAnimalAnnouncementUpdateBtnDisabled = (rowStatus: string) => {
        if (props.canCreateAnimalAnnouncement !== null && !props.canCreateAnimalAnnouncement)
            return true;
        if (rowStatus === '1') return true;
    };

    const toggleAnnouncementReadOnlyMode = (params: GridRenderCellParams): JSX.Element => {
        if (isAnimalAnnouncementUpdateRestricted(params.row as IAnimalAnnouncement))
            return (
                <ASearchIconButton
                    key={`${params.id}-edit`}
                    onClick={() => {
                        let item = params.row as unknown as IAnimalAnnouncement;
                        handleEditBovineAnnouncement(item);
                    }}
                    tooltip={t('AnelmaBovine:1163')}
                    disabled={
                        params.row.Number === 0 ||
                        handleAnimalAnnouncementUpdateBtnDisabled(params.row.Status)
                    }
                />
            );
        return (
            <AEditIconButton
                key={`${params.id}-edit`}
                onClick={() => {
                    let item = params.row as unknown as IAnimalAnnouncement;
                    handleEditBovineAnnouncement(item);
                }}
                tooltip={resolveEditTooltipText(params.row.Type)}
                disabled={handleAnimalAnnouncementUpdateBtnDisabled(params.row.Status)}
            />
        );
    };

    const dataGridActions: ADataGridActions = [
        (params) => {
            {
                return (
                    auth.canDeleteAnelmaBovineBrokerPurchase && (
                        <ADeleteIconButton
                            key={`${params.id}-delete`}
                            onClick={() => {
                                handleConfirmDeleteDialogData(String(params.id));
                            }}
                            tooltip={t('AnelmaGeneral:1012')}
                            disabled={handleAnimalAnnouncementDeletionRestrictions(
                                params.row as IAnimalAnnouncement,
                                snellmanUser
                            )}
                        />
                    )
                );
            }
        },
        (params) => {
            {
                return (
                    auth.canUpdateAnelmaBovineBrokerPurchase &&
                    toggleAnnouncementReadOnlyMode(params)
                );
            }
        },
        (params) => {
            let canPrint = auth.canViewAnelmaBovineBrokerPurchase
                && (params.row as IAnimalAnnouncement).Number !== 0
                && auth.canViewBovineSlaughterAnnouncementDialog;

            return (
                <ADefaultIconButton
                    icon={<PictureAsPdf />}
                    onClick={() => {
                        printingApi.printAnimalAnnouncement((params.row as IAnimalAnnouncement).Number);
                    }}
                    tooltip=""
                    disabled={!canPrint}
                />);
        }
    ];

    const toggleChainInformationReadOnly = (announcementId: GUIDType): boolean => {
        if (announcementId) {
            const selectedAnnouncement = props.data.find((_) => _.Id === announcementId);
            if (selectedAnnouncement !== undefined)
                return isAnimalAnnouncementUpdateRestricted(selectedAnnouncement);
        }
        return false;
    };

    return (
        <Container id={Strings.AnnouncementListGridContainer}>
            <ADataGrid
                disableSelectionOnClick={true}
                loading={isLoading}
                actions={dataGridActions}
                columns={
                    props.columnType === AnnouncementListDataGridColumnType.Default
                        ? defaultColumns
                        : props.columnType ===
                          AnnouncementListDataGridColumnType.BovineIntermediation
                        ? bovineIntermediationAnnouncementColumns
                        : bovinePurchaseAnnouncementColumns
                }
                enablePagination
                enableRowNumbers
                rows={props.data
                    .map((i) =>
                        mergeRight(i, {
                            id: i.Id,
                        })
                    )
                    .sort((a, b) => new Date(b.Created).valueOf() - new Date(a.Created).valueOf())}
                onSelectionModelChange={(selectionModel) => {
                    setSelectedRow(selectionModel);
                    props.handleSelectedAnnouncement(selectionModel);
                }}
            />

            {confirmDialogVisible && (
                <ConfirmDialog
                    title={'AnelmaGeneral:1142'}
                    content={'AnelmaGeneral:1012'}
                    actionButtonType={Strings.AnnouncementDeleteConfirmActionType}
                    onClose={() => setConfirmDialogVisible(false)}
                    onConfirm={() => deleteAnimalAnnouncement()}
                />
            )}
            {chainInformationDialogVisible && (
                <ChainInformationDialog
                    onClose={() => {
                        setChainInformationDialogVisible(false);
                    }}
                    announcementData={null}
                    announcementId={announcementId}
                    announcementNumber={announcementNumber}
                    animals={animalsForChainInformation}
                    forceReadOnly={toggleChainInformationReadOnly(announcementId)}
                />
            )}
        </Container>
    );
}
