// Libraries
import {
    ForwardedRef,
    forwardRef,
    KeyboardEventHandler,
    ReactNode,
    useEffect,
    useState,
} from 'react';

// MUI
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { styled } from '@mui/material';

// Core
import { boundaryRepository } from '../../core/boundaries';
import { ResourceBoundaryApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import { AInputContainer } from '../../common/inputs';

export const StyledTextField = styled(TextField)(({ theme }) => ({
    root: {
        '& label.Mui-focused': {
            color: '#0a4c31',
        },
        '& MuiInputBase-input:after': {
            borderBottomColor: '#0a4c31',
        },
    },
    'label.MuiFormLabel-root': {
        color: '#0a4c31',
    },
}));

export interface ATextInputBaseProps<T> {
    className?: string;
    disabled?: boolean;
    error?: boolean;
    forceValidEmpty?: boolean;
    id: string;
    label: React.ReactNode;
    lengthBoundaryName?: string;
    maxLength?: number;
    minLength?: number;
    onChange: (value: T) => void;
    onKeyUp?: KeyboardEventHandler<HTMLDivElement>;
    placeholder?: string;
    required?: boolean;
    startAdornment?: ReactNode;
    style?: CSSProperties;
    validator: IFormValidator;
    value: T;
    withoutContainer?: boolean;
    multiline?: boolean;
}

export interface ATextInputProps extends ATextInputBaseProps<string> {
    regExp?: RegExp;
}

export default forwardRef(function ATextInput(props: ATextInputProps, ref: ForwardedRef<any>) {
    const {
        lengthBoundaryName,
        error,
        id,
        forceValidEmpty,
        maxLength,
        minLength,
        onChange,
        regExp,
        required,
        startAdornment,
        validator,
        value,
        withoutContainer,
        ...propsForTextField
    } = props;
    const textFieldProps = propsForTextField as StandardTextFieldProps;

    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<string>(value || '');
    const [err, setErr] = useState<boolean>(!!error);
    const [maxLengthVal, setMaxLengthVal] = useState<number | undefined>(maxLength);
    const [minLengthVal, setMinLengthVal] = useState<number | undefined>(minLength);

    useEffect(() => {
        setVal(value);
    }, [value]);

    useEffect(() => {
        if (!lengthBoundaryName) return;
        const [groupName, valueName] = lengthBoundaryName.split(':', 2);
        const group = groupName as ResourceBoundaryApplication;
        boundaryRepository.load([group]).then(() => {
            const params = boundaryRepository.resource(group, valueName);
            setMaxLengthVal(
                Number.isInteger(params.maxValue) ? (params.maxValue as number) : undefined
            );
            setMinLengthVal(
                Number.isInteger(params.minValue) ? (params.minValue as number) : undefined
            );
        });
    }, [lengthBoundaryName]);

    useEffect(() => {
        const isValid = () => {
            if (forceValidEmpty && val.length === 0) return true;
            if (error) return false;
            if (!val) return !required;
            if (maxLengthVal !== undefined && val.length > maxLengthVal) return false;
            if (minLengthVal !== undefined && val.length < minLengthVal) return false;
            if (regExp && !regExp.test(val)) return false;
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setErr(!isValidAfterTouch());
    }, [error, id, required, touched, val, validator]);

    textFieldProps.variant = 'standard';
    textFieldProps.error = err || error;
    textFieldProps.fullWidth = !withoutContainer;
    textFieldProps.InputProps = {
        startAdornment: startAdornment,
    };
    if (maxLengthVal !== undefined) {
        textFieldProps.inputProps = {
            maxLength: maxLengthVal,
        };
    }
    textFieldProps.onChange = (e) => {
        const value = e.target.value;
        setTouched(true);
        setVal(value);
        onChange(value);
    };
    textFieldProps.ref = ref;
    if (textFieldProps.label) textFieldProps.required = required;
    textFieldProps.value = val;

    textFieldProps.multiline = props.multiline || false;

    return withoutContainer ? (
        <StyledTextField {...textFieldProps} data-robot-id={'text-input-' + props.id} />
    ) : (
        <AInputContainer>
            <StyledTextField {...textFieldProps} data-robot-id={'text-input-' + props.id} />
        </AInputContainer>
    );
});
