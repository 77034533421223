import { INotification } from '../../interfaces/INotification';

export const getModifiedNotificationsWhenSetRead = (
    notifications: INotification[],
    updatedNotifications: INotification[]
): INotification[] => {
    const updatedData: INotification[] = [];

    notifications.forEach((n) => {
        const updatedNotification = updatedNotifications.find(
            (_) => _.NotificationId === n.NotificationId
        );

        if (updatedNotification) {
            let notification: INotification = {
                NotificationId: n.NotificationId,
                NumbericKey: n.NumbericKey,
                UuidKey: n.UuidKey,
                TypeId: n.TypeId,
                Flags: updatedNotification.Flags,
                ApplicationResource: n.ApplicationResource,
                Created: n.Created,
                ReadDateTime: updatedNotification.ReadDateTime,
                DeletedDateTime: updatedNotification.DeletedDateTime,
                UserId: updatedNotification.UserId,
                FarmId: n.FarmId,
                FarmVisitResourceTxt: n.FarmVisitResourceTxt,
                CompanyData: {
                    CompanyResourceTxt: n.CompanyData?.CompanyResourceTxt ?? '',
                    FarmId: n.CompanyData?.FarmId ?? '',
                },
                ProductionPlanResourceTxt: n.ProductionPlanResourceTxt,
                ConversationResourceTxt: n.ConversationResourceTxt,
                RelatedEntities: n.RelatedEntities,
            };
            updatedData.push(notification);
        }
    });

    notifications.forEach((notification, idx, _) => {
        updatedData.forEach((updatedNotification) => {
            if (notification.NotificationId === updatedNotification.NotificationId)
                _[idx] = updatedNotification;
        });
    });

    return notifications;
};
