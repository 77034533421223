import axios from 'axios';
import { farmVisitUrl } from './apiEndpoints';
import { IApiListResult, IApiResult } from '../interfaces/IGeneral';
import { IFarmVisit, IFarmVisitAttachement, IFarmVisitRequest } from '../interfaces/IFarmVisit';
import ApiBase from './ApiBase';

class FarmVisitApi extends ApiBase {
    getAllFarmVisits() {
        return axios
            .get<IApiListResult<IFarmVisit>>(`${farmVisitUrl}/ListAllFarmVisits`)
            .then((d) => d.data);
    }

    getFarmVisitsByFarmId(farmId: string) {
        return axios
            .get<IApiListResult<IFarmVisit>>(`${farmVisitUrl}/${farmId}`)
            .then((d) => d.data);
    }

    /** Get a farm visit by it's id.
     * NOTE! Doesn't return attachments
     * In case needed for the use case then please add attachment/producer data retrievement to the handler. */
    getFarmVisitById(id: number) {
        return axios.get<IApiResult<IFarmVisit>>(`${farmVisitUrl}?id=${id}`).then((_) => _.data);
    }

    getFarmVisitAttachments(idList: number[]) {
        return axios
            .post<IApiListResult<IFarmVisitAttachement>>(`${farmVisitUrl}/GetAttachments`, {
                IdList: idList,
            })
            .then((d) => d.data);
    }

    modifyFarmVisit(id: number, payload: IFarmVisitRequest) {
        return axios
            .post<IApiResult<IFarmVisit>>(
                `${farmVisitUrl}/modify`,
                this.constructFormData(payload, id)
            )
            .then((d) => d.data);
    }

    deleteFarmVisit(Id: number) {
        return axios.delete<IApiResult<IFarmVisit>>(`${farmVisitUrl}/${Id}`).then((d) => d.data);
    }

    createFarmVisit(payload: IFarmVisitRequest) {
        return axios
            .post<IApiResult<IFarmVisit>>(`${farmVisitUrl}`, this.constructFormData(payload, null))
            .then((d) => d.data);
    }

    constructFormData = (payload: IFarmVisitRequest, id: number | null): FormData => {
        const formData = new FormData();
        formData.append('FarmGUID', payload.FarmGUID?.toString() || '');
        formData.append('FromDateTime', payload.FromDateTime);
        formData.append('ToDateTime', payload.ToDateTime);
        formData.append('Notes', payload.Notes);
        formData.append('Flags', payload.Flags.toString());
        formData.append('ContactId', payload.ContactId?.toString() || '');
        formData.append('Subject', payload.Subject.toString());
        if (id !== null) formData.append('Id', id.toString());

        payload.Attachments?.forEach((file) => {
            formData.append('Attachments', file);
        });

        return formData;
    };
}

export default new FarmVisitApi();
