// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
import moment from 'moment';

// Material UI
import { Container, Grid } from '@mui/material';

// Core
import { ResourceTextApplication, paramRepository, ParameterValues } from '../core/resources';
import FormValidator from '../core/FormValidator';

// Common
import { AAddButton } from '../common/buttons';
import ViewLoader from '../common/ViewLoader';
import { ADataGrid, AGridColumns, gridColumns } from '../common/dataGrid';
import { TextWithTooltip, ViewTitle } from '../common/typography';
import { AInputContainer } from '../common/inputs';
import SearchCalendar from '../common/search/SearchCalendar';

// Interfaces
import { CompanyEntityTypeEnum } from '../interfaces/enums';
import defaultData from '../core/defaultData';
import { IMessages } from './ISms';

// Store
// API
import { getMessagesList } from './api/APIShortMessage';

// Feature - SMS
import SendMessageFormDialog from './SendMessageDialogForm';
import CancelMessageDialog from './CancelMessageDialog';
import { SMS } from './constants/SMS';

interface MessageGridData {
    id: number;
    phoneNumber: string;
    receiverCompany: string;
    receiverPerson: string;
    content: string;
    deliveryState: string;
    requestedDeliveryDate: string | null;
    creationDate: string;
    receiverRoles: string;
}

export default function ShortMessageServiceView() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaAccessRights',
        'AnelmaCompany',
        'AnelmaCommunication',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);
    const [farmTitles, setFarmTitles] = useState<ParameterValues>([]);
    const [logisticsTitles, setLogisticsTitles] = useState<ParameterValues>([]);
    const [snellmanTitles, setSnellmanTitles] = useState<ParameterValues>([]);
    const [senderFormVisible, setSenderFormVisible] = useState<boolean>(false);
    const [cancelFromVisible, setCancelFormVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formMode, setFormMode] = useState<'create' | 'edit'>('edit');
    const [messages, setMessages] = useState<IMessages>(defaultData.sendDirectMessages());
    const [deliveryStateColumn, setDeliveryStateColumn] = useState<ParameterValues>([]);
    const [rowsData, setRowsData] = useState<IMessages[]>([]);
    const [calendarFromDate, setCalendarFromDate] = useState<moment.Moment>(moment().add(-1, 'M'));
    const [calendarToDate, setCalendarToDate] = useState<moment.Moment>(moment());

    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

    useEffect(() => {
        paramRepository.load(['AnelmaGeneral']).then(() => {
            setFarmTitles(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
            setLogisticsTitles(paramRepository.resource('AnelmaGeneral', 'LogisticsTitles'));
            setSnellmanTitles(paramRepository.resource('AnelmaGeneral', 'SnellmanTitles'));
        });
    }, []);

    const messageData: MessageGridData[] = [];
    let id = 0;
    rowsData.forEach((x) => {
        messageData.push({
            id: id++,
            phoneNumber: x.Receiver.toString(),
            receiverCompany:
                x.ReceiverFarmProducerId === null || x.ReceiverFarmName === null
                    ? '***'
                    : x.ReceiverFarmProducerId + ' - ' + x.ReceiverFarmName,
            receiverPerson: x.ReceiverName || '***',
            content: x.Content,
            deliveryState: x.DeliveryState,
            requestedDeliveryDate: x.RequestedDeliveryDate,
            creationDate: x.CreationDate,
            receiverRoles: getRoles(x.ReceiverRole, x.ReceiverFarmType),
        });
    });

    const rows = messageData
        .map((i, index) => mergeRight(i, { id: index }))
        .sort((a, b) => new Date(b.creationDate).valueOf() - new Date(a.creationDate).valueOf());

    function getRoles(relations: string[], type: number): string {
        if (relations.length === 0 || type === null) return '***';

        let titles: ParameterValues | null = null;
        var result = '';

        if (type === CompanyEntityTypeEnum.Farm) {
            titles = farmTitles;
        } else if (type === CompanyEntityTypeEnum.TrafficContractor) {
            titles = logisticsTitles;
        } else if (type === CompanyEntityTypeEnum.Snellman) {
            titles = snellmanTitles;
        } else {
            return '***';
        }

        relations.map((r) => {
            if (result.length !== 0) {
                result += ', ';
            }
            result += titles?.find((o) => o.code == r)?.text;
        });

        return result;
    }

    const getMessagesBySelectedDates = () => {
        setIsLoading(true);
        getMessagesList(
            calendarFromDate.format(dateFormat),
            calendarToDate.format(dateFormat)
        ).then((response) => {
            setRowsData(response.Items);
            setIsLoading(false);
        });
    };

    const handleDateChange = (from: moment.Moment, to: moment.Moment, changeFrom: boolean) => {
        var fromDate = from.toDate();
        var toDate = to.toDate();

        if (changeFrom) {
            var newFromDate = from.toDate();
            newFromDate = new Date(newFromDate.setMonth(newFromDate.getMonth() + 3));

            if (fromDate > toDate || newFromDate < toDate) {
                setCalendarToDate(moment(newFromDate));
            }

            setCalendarFromDate(from);
        } else {
            var newToDate = to.toDate();
            newToDate = new Date(newToDate.setMonth(newToDate.getMonth() - 3));

            if (fromDate > toDate || newToDate > fromDate) {
                setCalendarFromDate(moment(newToDate));
            }

            setCalendarToDate(to);
        }
    };

    const columns: AGridColumns = [
        {
            field: 'phoneNumber',
            headerName: t('AnelmaCommunication:1083'),
            type: 'string',
            width: 170,
        },
        {
            field: 'receiverCompany',
            headerName: t('AnelmaCompany:1003') + ' - ' + t('AnelmaCommunication:1070'),
            type: 'string',
            width: 220,
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
        },
        {
            field: 'receiverPerson',
            headerName: t('AnelmaGeneral:1014'),
            type: 'string',
            width: 130,
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
        },
        {
            field: 'receiverRoles',
            headerName: t('AnelmaCommunication:1072'),
            type: 'string',
            width: 160,
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
        },
        {
            field: 'content',
            headerName: t('AnelmaCommunication:1069'),
            type: 'string',
            flex: 1,
            renderCell: (params) => {
                return <TextWithTooltip text={params.value as string} />;
            },
        },
        {
            field: 'deliveryState',
            headerName: t('AnelmaCommunication:1038'),
            type: 'string',
            width: 115,
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params) => {
                return (
                    deliveryStateColumn.find(
                        (p) => p.code === (params.row as MessageGridData).deliveryState
                    )?.text ?? ''
                );
            },
        },

        gridColumns.dateTime(
            'requestedDeliveryDate',
            t('AnelmaCommunication:1076'),
            'DD.MM.YYYY HH:mm',
            130
        ),
    ];

    useEffect(() => {
        const deliveryStateParam = paramRepository.load(['AnelmaSMS']).then(() => {
            setDeliveryStateColumn(
                paramRepository.resource('AnelmaSMS', 'DeliveryStates').map((resourceParam) => {
                    return {
                        ...resourceParam,
                    };
                })
            );
        });

        const getmessagePromise = getMessagesList(
            calendarFromDate.format(dateFormat),
            calendarToDate.format(dateFormat)
        ).then((response) => {
            setRowsData(response.Items);
        });

        Promise.all([deliveryStateParam, getmessagePromise]).then(() => {
            setIsLoading(false);
        });
    }, []);

    return (
        <>
            <Container data-robot-id={'app-body-management-msm'}>
                <ViewTitle>{t('AnelmaLayout:1018')}</ViewTitle>
                <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginBottom: '20px', textAlign: 'right' }}>
                        <AInputContainer>
                            <AAddButton
                                type='action'
                                onClick={() => {
                                    setFormMode('create');
                                    setSenderFormVisible(!senderFormVisible);
                                    setMessages(defaultData.sendDirectMessages());
                                }}
                            >
                                {t('AnelmaCommunication:1061')}
                            </AAddButton>
                        </AInputContainer>
                    </Grid>
                </Grid>

                {senderFormVisible && (
                    <SendMessageFormDialog
                        mode={formMode}
                        data={messages}
                        onClose={() => {
                            setFormMode('create');
                            setSenderFormVisible(false);
                            setMessages(defaultData.sendDirectMessages());
                        }}
                        validator={new FormValidator()}
                    />
                )}
                <SearchCalendar
                    handleSort={() => getMessagesBySelectedDates()}
                    handleFromDateChange={(fromDate) =>
                        handleDateChange(fromDate, calendarToDate, true)
                    }
                    handleToDateChange={(toDate) =>
                        handleDateChange(calendarFromDate, toDate, false)
                    }
                    fromDate={calendarFromDate}
                    toDate={calendarToDate}
                    className={SMS.SearchCalendar}
                    childrenText={t('AnelmaCommunication:1160')}
                    fromDateId={SMS.SearchCalendarFromDate}
                    toDateId={SMS.SearchCalendarToDate}
                />

                {cancelFromVisible && (
                    <CancelMessageDialog
                        id={messages.Guid}
                        afterDelete={(guid) => {
                            const removeSMS = [...rowsData];
                            const index = removeSMS.findIndex((p) => p.Guid === guid);
                            removeSMS.splice(index, 1);
                            setRowsData(removeSMS);
                        }}
                        onClose={() => {
                            setCancelFormVisible(!cancelFromVisible);
                        }}
                    />
                )}

                {!isLoading ? (
                    <ADataGrid
                        columns={columns}
                        rows={rows}
                        enableFiltering
                        enableRowNumbers
                        enablePagination
                    />
                ) : (
                    <ViewLoader />
                )}
            </Container>
        </>
    );
}
