/*  DESCRIPTION : Always in error state
 * Error text input created here separately to make it possible to set error when
 * text isn't touched or input is desabled. Also because I didn't want to affect
 * other application parts. If at some point we want to rise error straight
 * when error-property comes from parameters, change AErrorTextInput-isValidAfterTouch()
 */

// Libraries
import {
    ForwardedRef,
    forwardRef,
    KeyboardEventHandler,
    ReactNode,
    useEffect,
    useState,
} from 'react';

// MUI
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { styled } from '@mui/material';

// Core
import { IFormValidator } from '../../core/FormValidator';

// Common
import { AInputContainer } from '../../common/inputs';

const StyledErrorTextField = styled(TextField)(({ theme }) => ({
    'label.MuiFormLabel-root': {
        color: '#0a4c31',
    },
    'label.Mui-focused': {
        color: '#0a4c31',
    },
    'MuiInputBase-input:after': {
        borderBottomColor: '#0a4c31',
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderBottom: '1px solid',
        borderRadius: '0',
    },
}));

export interface AErrorTextInputBaseProps<T> {
    disabled?: boolean;
    id: string;
    label: React.ReactNode;
    onChange: (value: T) => void;
    onKeyUp?: KeyboardEventHandler<HTMLDivElement>;
    placeholder?: string;
    startAdornment?: ReactNode;
    style?: CSSProperties;
    validator: IFormValidator;
    value: T;
    withoutContainer?: boolean;
}

export interface AErrorTextInputProps extends AErrorTextInputBaseProps<string> {
    regExp?: RegExp;
}

export default forwardRef(function AErrorTextInput(
    props: AErrorTextInputProps,
    ref: ForwardedRef<any>
) {
    const {
        id,
        onChange,
        regExp,
        startAdornment,
        validator,
        value,
        withoutContainer,
        ...propsForTextField
    } = props;
    const textFieldProps = propsForTextField as StandardTextFieldProps;

    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<string>(value || '');

    useEffect(() => {
        setVal(value);
    }, [value]);

    useEffect(() => {
        validator.setState(id, false, () => setTouched(true));
    }, [id, touched, val, validator]);

    textFieldProps.error = true;
    textFieldProps.fullWidth = !withoutContainer;
    textFieldProps.InputProps = {
        startAdornment: startAdornment,
    };
    textFieldProps.onChange = (e) => {
        const value = e.target.value;
        setTouched(true);
        setVal(value);
        onChange(value);
    };
    textFieldProps.ref = ref;
    textFieldProps.value = val;

    return withoutContainer ? (
        <StyledErrorTextField {...textFieldProps} />
    ) : (
        <AInputContainer>
            <StyledErrorTextField {...textFieldProps} />
        </AInputContainer>
    );
});
