import { DialogModeEnum } from '../../../interfaces/enums';

export const isReadOnlyMode = (dialogMode: DialogModeEnum, readOnly: boolean): boolean => {
    return dialogMode === DialogModeEnum.Edit && readOnly === true;
};

export const holdingSiteHasValue = (holdingSite: string | undefined | null): boolean => {
    if (holdingSite !== undefined && holdingSite !== null) if (holdingSite.length > 0) return true;
    return false;
};
