// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { paramRepository, ResourceTextApplication } from '../../core/resources';

// Common
import { InfoBlock } from '../../common/typography';

// Feature
import { FarmInfoSectionProps } from './FarmInfo';

export default function Addresses(props: FarmInfoSectionProps) {
    const { data, getParamText } = props;

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    const addressTypes = paramRepository.resource('AnelmaGeneral', 'AddressType');

    const defaultAddress = data.Addresses.find((i) => i.IsDefault);
    const otherAddresses = data.Addresses.filter((i) => !i.IsDefault);

    return (     
            <Grid container data-robot-id = {"addresses-info"}>
                <Grid item sm={6}>
                    <InfoBlock
                        label={t('AnelmaCompany:1017')}
                        value={defaultAddress?.NormalizedAddress}
                    />
                </Grid>

                {otherAddresses.map((i, key) => (
                    <Grid key={key} item sm={12}>
                        <InfoBlock
                            label={getParamText(addressTypes, i.Type)}
                            value={i.NormalizedAddress}
                        />
                    </Grid>
                ))}
            </Grid>      
    );
}
