import {
    IGetPayloadMessage,
    IPayloadMessageResult,
    IPayloadSms,
    IPayloadSmsResult,
} from '../interfaces/IAnimal';
import { sendDirectMessageUrl } from './apiEndpoints';

import ApiBase from './ApiBase';

class ShortMessageApi extends ApiBase {
    getPayloadMessages = (messagesToGet: IGetPayloadMessage[]) => {
        return this.postList<IPayloadMessageResult>(`${sendDirectMessageUrl}/GetPayloadMessages`, {
            MessagesToGet: messagesToGet,
        });
    };

    sendPayloadMessages = (messages: IPayloadSms[]) => {
        return this.postList<IPayloadSmsResult>(`${sendDirectMessageUrl}/SendPayloadMessages`, {
            PayloadSmsMessages: messages,
        });
    };
}

export default new ShortMessageApi();
