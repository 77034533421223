// Libraries
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../core/resources';

export default function NoContexts() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);

    return <div className='an-context-selector--none'>{t('AnelmaGeneral:1150')}</div>;
}
