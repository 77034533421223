// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// Store
import { useAppSelector } from '../store/hooks';

// MUI
import { Grid } from '@mui/material';

// Core
import FormValidator from '../core/FormValidator';
import {
    ParameterValue,
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../core/resources';
import auth from '../core/authorization';
import utils from '../core/utils';
import log from '../core/log';

// Interfaces
import { IFarmDetails } from '../interfaces/IBusinessEntities';
import { IFarmVisit, IFarmVisitAttachement, IFarmVisitRequest } from '../interfaces/IFarmVisit';
import { DialogModeEnum } from '../interfaces/enums';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../common/dialog';
import { ADownloadIconButton } from '../common/buttons';
import {
    ADateInput,
    ADropdown,
    ADropdownItemProps,
    ATextarea,
    ATimeInput,
    ALabel,
    ADateValue,
} from '../common/inputs';
import AttachmentDropzone from '../common/AttachmentDropzone';

import config, { DocumentDialogConfig } from '../documents/addDialog/documentAddDialogConfigs';

// API
import farmVisitApi from '../api/farmVisitApi';
import { snellmanContactPersons } from '../publicContactPersons/publicContactPersonsApi';
import documentApi from '../api/documentApi';

// Feature
import { getDateString, parseDateTime } from './helpers/date';
import { isFieldDisabled } from './helpers/userRightsHelper';
import {
    isContactPersonValid,
    isNoteValueValid,
    isFromDateValid,
    isToDateValid,
    isSubjectValid,
} from './helpers/farmVisitValidator';
import ViewLoader from '../common/ViewLoader';

interface CreateFarmVisitDialogProps {
    farmDetails: IFarmDetails | undefined;
    data: IFarmVisit;
    onClose: () => void;
    mode: DialogModeEnum;
    handleMapFarmVisit: Function;
}

export default function CreateFarmVisitDialog(props: CreateFarmVisitDialogProps) {
    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaFarmVisit',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);
    const { enqueueSnackbar } = useSnackbar();
    const validator = new FormValidator();

    const dialogConfig = config('multi');

    const { data, farmDetails } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(false);
    const [contactPerson, setContactPerson] = useState(props.data.ContactId);
    const [notes, setNotes] = useState(props.data.Notes);
    const [visitFromDate, setVisitFromDate] = useState<moment.Moment | null>(() =>
        props.data.FromTime ? moment(props.data.FromTime + 'Z') : null
    );
    const [visitToDate, setVisitToDate] = useState<moment.Moment | null>(() =>
        props.data.ToTime ? moment(props.data.ToTime + 'Z') : null
    );
    const [contactPersonList, setContactPersonList] = useState<ADropdownItemProps[]>([]);
    const [newAttachments, setNewAttachments] = useState<File[]>([]);
    const [subject, setSubject] = useState<number>(data.Subject);
    const [subjects, setSubjects] = useState<ParameterValues>([]);
    const [currentAttachments, setCurrentAttachments] = useState<IFarmVisitAttachement[]>([]);

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [
            {
                type: 'save',
                onClick: () => onSave(),
                disabled: auth.canCreateNewFarmVisit ? false : true,
            },
        ],
    };

    useEffect(() => {
        setIsLoading(true);
        const dropDownPersonList: ADropdownItemProps[] = [];
        snellmanContactPersons(!!loginStatus.loggedIn).then((data) => {
            if (!data) {
                setIsLoading(false);
                log('info', `'snellmanContactPersons' response returned no results.`);
                return;
            }
            data.Items.forEach((person) => {
                dropDownPersonList.push({
                    text: person.Name,
                    value: person.Id,
                });
            });
            setContactPersonList(dropDownPersonList);
            setIsLoading(false);
        });

        if (props.mode === DialogModeEnum.Edit) loadAttachments();

        paramRepository.load(['AnelmaFarmVisit']).then(() => {
            setSubjects(paramRepository.resource('AnelmaFarmVisit', 'FarmVisitSubject'));
        });
    }, []);

    const loadAttachments = () => {
        setAttachmentsLoading(true);
        farmVisitApi.getFarmVisitAttachments([data.Id]).then((response) => {
            if (!response) {
                handleSnackbarMessage(`${t('AnelmaGeneral:1020')}: ${response}`, 'error');
                setAttachmentsLoading(false);
                return;
            }
            setCurrentAttachments(response.Items);
            setAttachmentsLoading(false);
        });
    };

    const composePayload = (): IFarmVisitRequest => {
        return {
            ContactId: contactPerson,
            FarmGUID: farmDetails !== undefined ? farmDetails.Id : data.FarmGUID,
            FromDateTime: getDateString(visitFromDate),
            Flags: 0,
            Notes: notes,
            ToDateTime: getDateString(parseDateTime(visitFromDate, visitToDate)),
            Attachments: newAttachments,
            Subject: subject,
        };
    };

    const onSave = () => {
        if (isFarmVisitDataValid())
            switch (props.mode) {
                case DialogModeEnum.Create:
                    handleCreateFarmVisit();
                    break;
                case DialogModeEnum.Edit:
                    handleModifyFarmVisit();
                    break;
            }
    };

    const handleCreateFarmVisit = () => {
        setIsLoading(true);
        farmVisitApi.createFarmVisit(composePayload()).then((response) => {
            if (!response) {
                handleSnackbarMessage(`${t('AnelmaFarmVisit:1015')}: ${response}`, 'error');
                setIsLoading(false);
                return;
            }
            props.handleMapFarmVisit(response.Entity);
            handleSnackbarMessage(t('AnelmaFarmVisit:1016'), 'success');
            setIsLoading(false);
            props.onClose();
        });
    };

    const handleModifyFarmVisit = () => {
        setIsLoading(true);
        farmVisitApi.modifyFarmVisit(data.Id, composePayload()).then((response) => {
            if (!response) {
                handleSnackbarMessage(`${t('AnelmaFarmVisit:1017')}: ${response}`, 'error');
                setIsLoading(false);
                return;
            }
            props.handleMapFarmVisit(response.Entity);
            handleSnackbarMessage(t('AnelmaFarmVisit:1018'), 'success');
            setIsLoading(false);
            props.onClose();
        });
    };

    const isFarmVisitDataValid = (): boolean => {
        if (
            !validator.invalid &&
            isContactPersonValid(contactPerson) &&
            isNoteValueValid(notes) &&
            isFromDateValid(visitFromDate) &&
            isToDateValid(visitToDate) &&
            isSubjectValid(subject)
        )
            return true;
        handleSnackbarMessage(t('AnelmaGeneral:1169'), 'error');
        return false;
    };

    const updateDateAndTimePickerValue = (
        d: ADateValue,
        type: 'date' | 'time',
        typeOfDay: 'from' | 'to'
    ): void => {
        switch (typeOfDay) {
            case 'from':
                if (d === null || !moment(d).isValid()) return;
                setVisitFromDate(utils.date.updateDatePickerDate(type, d, visitFromDate));
                break;
            case 'to':
                if (d === null || !moment(d).isValid()) return;
                setVisitToDate(utils.date.updateDatePickerDate(type, d, visitToDate));
                break;
        }
    };

    const handleSnackbarMessage = (
        message: string,
        variant: 'success' | 'error' | 'warning' | 'info'
    ) => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const getOfficerName = (contactPersonId: number): string => {
        return contactPersonList.find((_) => _.value === contactPersonId)?.text ?? '';
    };

    const attachmentsHandler = (attachments: IFarmVisitAttachement[] | undefined): any => {
        return (
            <ul>
                {attachments?.map((attachment) => (
                    <li key={attachment.Id}>
                        <ADownloadIconButton
                            key='download'
                            onClick={() =>
                                documentApi.downloadFile(attachment.FileType, attachment.Id)
                            }
                            tooltip='download'
                        />
                        {attachment.DisplayName}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <ADialog open onClose={() => props.onClose()}>
            {props.mode === DialogModeEnum.Create ? (
                <ADialogTitle>{t('AnelmaFarmVisit:1010')}</ADialogTitle>
            ) : (
                <ADialogTitle>{t('AnelmaFarmVisit:1005')}</ADialogTitle>
            )}
            <ADialogContent isLoading={isLoading} size='md'>
                <Grid container sx={{ paddingTop: '10px' }}>
                    <Grid item sm={4}>
                        <ADateInput
                            id='valid-from-date'
                            label={t('AnelmaFarmVisit:1003')}
                            onChange={(v) => updateDateAndTimePickerValue(v, 'date', 'from')}
                            validator={validator}
                            value={visitFromDate?.toDate() || null}
                            required
                            disabled={isFieldDisabled(auth)}
                            error={!isFromDateValid(visitFromDate)}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <ATimeInput
                            id='valid-from-time'
                            label={t('AnelmaFarmVisit:1012')}
                            validator={validator}
                            value={visitFromDate?.toDate() || null}
                            onChange={(v) => {
                                updateDateAndTimePickerValue(v, 'time', 'from');
                            }}
                            required
                            disabled={isFieldDisabled(auth)}
                            error={!isFromDateValid(visitFromDate)}
                        ></ATimeInput>
                    </Grid>
                    <Grid item sm={4}>
                        <ATimeInput
                            id='valid-to-time'
                            label={t('AnelmaFarmVisit:1013')}
                            validator={validator}
                            value={visitToDate?.toDate() || null}
                            onChange={(v) => {
                                updateDateAndTimePickerValue(v, 'time', 'to');
                            }}
                            required
                            disabled={isFieldDisabled(auth)}
                            error={!isToDateValid(visitToDate)}
                        ></ATimeInput>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={12}>
                        {isFieldDisabled(auth) ? (
                            <ATextarea
                                id={'contact-person'}
                                label={t('AnelmaFarmVisit:1009')}
                                onChange={() => false}
                                validator={validator}
                                value={contactPerson !== null ? getOfficerName(contactPerson) : ''}
                                disabled={true}
                            ></ATextarea>
                        ) : (
                            <ADropdown
                                id='contact-person'
                                items={contactPersonList}
                                label={t('AnelmaFarmVisit:1009')}
                                onChange={(v) => {
                                    if (v !== null) setContactPerson(Number(v));
                                }}
                                required
                                validator={validator}
                                value={contactPerson}
                                error={!isContactPersonValid(contactPerson)}
                            />
                        )}
                    </Grid>
                    <Grid item sm={12}>
                        <ADropdown
                            id='farm-visit-subject'
                            items={subjects.map((_: ParameterValue) => ({
                                text: _.text,
                                value: _.code,
                            }))}
                            label={t('AnelmaFarmVisit:1022')}
                            onChange={(_) => {
                                if (_ !== null) setSubject(Number(_));
                            }}
                            required
                            validator={validator}
                            value={subject}
                            error={!isSubjectValid(subject)}
                            disabled={isFieldDisabled(auth)}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ATextarea
                            id='farm-visit-notes'
                            label={t('AnelmaFarmVisit:1023')}
                            validator={validator}
                            onChange={(t) => {
                                setNotes(t);
                            }}
                            value={notes}
                            lengthBoundaryName={'AnelmaFarmVisit:NotesMaxLength'}
                            required
                            disabled={isFieldDisabled(auth)}
                            error={!isNoteValueValid(notes)}
                        ></ATextarea>
                    </Grid>

                    <Grid item sm={12} style={{ marginInline: 40 }}>
                        <ALabel>{t('AnelmaFarmVisit:1024')}</ALabel>

                        <AttachmentDropzone
                            filesLimit={dialogConfig.filesLimit}
                            maxFileSize={dialogConfig.maxFileSize}
                            Text={t('AnelmaFarmVisit:1025')}
                            onAttachmentsChanged={(files) => {
                                setNewAttachments(files);
                            }}
                            disabled={isFieldDisabled(auth)}
                        />
                    </Grid>
                    {!attachmentsLoading ? (
                        currentAttachments.length > 0 && (
                            <Grid item sm={12} style={{ marginInline: 40 }}>
                                <ALabel>{t('AnelmaFarmVisit:1026')}</ALabel>
                                <div>{attachmentsHandler(currentAttachments)}</div>
                            </Grid>
                        )
                    ) : (
                        <ViewLoader />
                    )}
                </Grid>
            </ADialogContent>
            <ADialogActions buttons={footerActionButtons} />
        </ADialog>
    );
}
