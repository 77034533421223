// Core
import { IAuthorizationResource } from '../core/authorization';

// Access management
import {
    IAccessRole,
    IAccessRoleMemberList,
    IAccessRolePermissions,
    IAccessResourceMap,
    IRoleDetails,
} from '../accessRightsManagement/IAccessRightsManagement';

// Api
import { accessManagementUrl } from './apiEndpoints';
import ApiBase from './ApiBase';

// Companies
import { AccessRoleChanges, UsernameAccessRoles } from '../companies/contactPersons/ContactPersons';

// Interfaces
import { IApiListResult, IApiResult } from '../interfaces/IGeneral';
import { UserDataEntityTypeEnum, CompanyEntityTypeEnum } from '../interfaces/enums';

interface IAjaxResult<T> {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    items: T[];
    pageIndex: number;
    totalCount: number;
    totalPages: number;
}

class AccessManagementApi extends ApiBase {
    private base64Encoded = 'base64EncodedRouteParameters=true';

    createNewRole(role: IAccessRole) {
        return this.post<IAccessRole>(`${accessManagementUrl}/role`, role, {
            incompatibleResult: true,
        });
    }

    getAccessRoles(username: string) {
        return this.get<UsernameAccessRoles>(`${accessManagementUrl}/user/${username}/roles`, {
            incompatibleResult: true,
        });
    }

    getResourceMap() {
        return this.getList<IAccessResourceMap>(
            `${accessManagementUrl}/query/three-tier-resource-maps`,
            {
                incompatibleResult: true,
            }
        );
    }

    getResourcePermissions(roleNames: string[]) {
        return new Promise<IApiListResult<IAccessRolePermissions> | null>((resolve, reject) => {
            const promises = roleNames.map((roleName) => this.getResourcePermission(roleName));
            Promise.all(promises).then(
                (result) => resolve(this.wrapInListResult<IAccessRolePermissions>(result)),
                () => {
                    resolve(null);
                    reject();
                }
            );
        });
    }

    getRoleMembers(roleNames: string[]) {
        return new Promise<IApiListResult<IAccessRoleMemberList> | null>((resolve, reject) => {
            const result: IAccessRoleMemberList[] = [];
            const promises = roleNames.map((roleName) => {
                const promise = this.get<IAccessRoleMemberList>(
                    `${accessManagementUrl}/role/${this.base64Encode(
                        roleName
                    )}/list-members?includeContextScopes=true&${this.base64Encoded}`,
                    {
                        incompatibleResult: true,
                    }
                );
                promise.then(
                    (d) => {
                        if (!d) return;

                        result.push(d.Entity);
                    },
                    () => {}
                );
                return promise;
            });

            Promise.all(promises).then(
                () => resolve(this.wrapInListResult(result)),
                () => {
                    resolve(null);
                    reject();
                }
            );
        });
    }

    async getRoles(filter?: CompanyEntityTypeEnum) {
        const result: IAccessRole[] = [];
        await this.recursiveRolesPages(result, 1);

        if (filter == CompanyEntityTypeEnum.Snellman) {
            const internalRoles = await this.getRole(AccessManagementApi.internalRole);
            if (internalRoles) {
                return this.wrapInListResult(
                    result.filter((x) => internalRoles.roleMembers.includes(x.name))
                );
            }
        } else if (filter == CompanyEntityTypeEnum.Farm) {
            const producerRoles = await this.getRole(AccessManagementApi.farmRole);
            if (producerRoles) {
                return this.wrapInListResult(
                    result.filter((x) => producerRoles.roleMembers.includes(x.name))
                );
            }
        } else if (filter == CompanyEntityTypeEnum.TrafficContractor) {
            const producerRoles = await this.getRole(AccessManagementApi.trafficContractorRole);
            if (producerRoles) {
                return this.wrapInListResult(
                    result.filter((x) => producerRoles.roleMembers.includes(x.name))
                );
            }
        }

        return this.wrapInListResult(result);
    }

    grantPermission(roleName: string, permission: IAuthorizationResource) {
        return this.patch<IAuthorizationResource>(
            `${accessManagementUrl}/role/${this.base64Encode(roleName)}/grant-permission?${
                this.base64Encoded
            }`,
            permission,
            { incompatibleResult: true }
        );
    }

    revokePermission(roleName: string, permission: IAuthorizationResource) {
        return this.patch<IAuthorizationResource>(
            `${accessManagementUrl}/role/${this.base64Encode(roleName)}/revoke-permission?${
                this.base64Encoded
            }`,
            permission,
            { incompatibleResult: true }
        );
    }

    updateUsersAccessRoles(
        changes: AccessRoleChanges[],
        contextType: string = UserDataEntityTypeEnum.FarmContext,
        context?: string
    ) {
        return new Promise<IApiResult<boolean> | null>((resolve, reject) => {
            const contextParams = context
                ? `?context=${encodeURIComponent(context)}&contextType=${contextType}&${
                      this.base64Encoded
                  }`
                : `?${this.base64Encoded}`;
            const promises: Promise<any>[] = [];

            changes.forEach((user) => {
                user.oldRoles.forEach((oldRole) => {
                    if (user.newRoles.includes(oldRole)) return;
                    promises.push(
                        this.patch(
                            `${accessManagementUrl}/role/${this.base64Encode(
                                oldRole
                            )}/remove-members${contextParams}`,
                            [user.username],
                            { incompatibleResult: true }
                        )
                    );
                });
                user.newRoles.forEach((newRole) => {
                    if (user.oldRoles.includes(newRole)) return;
                    promises.push(
                        this.patch(
                            `${accessManagementUrl}/role/${this.base64Encode(
                                newRole
                            )}/add-members${contextParams}`,
                            [user.username],
                            { incompatibleResult: true }
                        )
                    );
                });
            });

            Promise.all(promises).then(
                () => resolve(this.wrapInResult(true)),
                () => {
                    resolve(null);
                    reject();
                }
            );
        });
    }

    updateRole(role: IAccessRole) {
        return this.put<IAccessRole>(`${accessManagementUrl}/role/${role.id}`, role, {
            incompatibleResult: true,
        });
    }

    async getRoleGroup(roleName: string) {
        var role = await this.getRole(AccessManagementApi.internalRole);
        if (role && role.roleMembers.includes(roleName)) {
            return CompanyEntityTypeEnum.Snellman;
        }

        role = await this.getRole(AccessManagementApi.farmRole);
        if (role && role.roleMembers.includes(roleName)) {
            return CompanyEntityTypeEnum.Farm;
        }

        role = await this.getRole(AccessManagementApi.trafficContractorRole);
        if (role && role.roleMembers.includes(roleName)) {
            return CompanyEntityTypeEnum.TrafficContractor;
        }

        return CompanyEntityTypeEnum.Unknown;
    }

    private async getRole(roleName: string): Promise<IRoleDetails | undefined> {
        const x = await this.get(`${accessManagementUrl}/role/${roleName}`);
        return x as unknown as IRoleDetails;
    }

    private static readonly internalRole = 'SnellmanInternalUserRoles';
    private static readonly farmRole = 'SnellmanProducerUserRoles';
    private static readonly trafficContractorRole = 'SnellmanOperatorUserRoles';
    attachRole(roleGroup: CompanyEntityTypeEnum, role: IAccessRole): Promise<any> {
        var roleName = AccessManagementApi.internalRole;
        switch (roleGroup) {
            case CompanyEntityTypeEnum.Snellman:
                roleName = AccessManagementApi.internalRole;
                break;
            case CompanyEntityTypeEnum.Farm:
                roleName = AccessManagementApi.farmRole;
                break;
            case CompanyEntityTypeEnum.TrafficContractor:
                roleName = AccessManagementApi.trafficContractorRole;
                break;
            default:
                return Promise.resolve('Unknown Company Entity');
        }
        return this.patch(`${accessManagementUrl}/role/${roleName}/add-role-members`, [role.name]);
    }

    detachRole(roleGroup: CompanyEntityTypeEnum, role: IAccessRole): Promise<any> {
        var roleName = AccessManagementApi.internalRole;
        switch (roleGroup) {
            case CompanyEntityTypeEnum.Snellman:
                roleName = AccessManagementApi.internalRole;
                break;
            case CompanyEntityTypeEnum.Farm:
                roleName = AccessManagementApi.farmRole;
                break;
            case CompanyEntityTypeEnum.TrafficContractor:
                roleName = AccessManagementApi.trafficContractorRole;
                break;
            default:
                return Promise.resolve('Unknown Company Entity');
        }
        return this.patch(`${accessManagementUrl}/role/${roleName}/remove-role-members`, [
            role.name,
        ]);
    }

    private getResourcePermission(roleName: string) {
        return new Promise<IAccessRolePermissions>((resolve, reject) => {
            this.getList<IAuthorizationResource>(
                `${accessManagementUrl}/role/${this.base64Encode(roleName)}/resource-permissions?${
                    this.base64Encoded
                }`,
                { incompatibleResult: true }
            ).then(
                (response) => {
                    const rolePermission: IAccessRolePermissions = {
                        role: roleName,
                        permissions: response?.Items || [],
                    };
                    resolve(rolePermission);
                },
                () => reject()
            );
        });
    }

    private recursiveRolesPages(result: IAccessRole[], pageNumber: number) {
        return new Promise<void>((resolve, reject) => {
            this.getList<IAccessRole>(
                `${accessManagementUrl}/role/list?pageNumber=${pageNumber}`
            ).then(
                (response) => {
                    const r = response as any as IAjaxResult<IAccessRole>;
                    result.push(...r.items);
                    if (r.hasNextPage)
                        this.recursiveRolesPages(result, r.pageIndex + 1).then(
                            () => resolve(),
                            () => reject()
                        );
                    else resolve();
                },
                () => reject()
            );
        });
    }

    private wrapInListResult<T>(data: T[]): IApiListResult<T> {
        return {
            Headers: {},
            Items: data,
            PageInfo: {
                HasNextPage: false,
                HasPreviousPage: false,
                PageNumber: 1,
                PageSize: data.length,
                TotalPages: 1,
            },
            Results: {
                Errors: [],
                HttpStatus: 200,
                Title: '',
                Type: '',
                Warnings: [],
            },
            Type: 0,
        };
    }

    private wrapInResult<T>(data: T): IApiResult<T> {
        return {
            Entity: data,
            Headers: {},
            Results: {
                Errors: [],
                HttpStatus: 200,
                Title: '',
                Type: '',
                Warnings: [],
            },
            Type: 0,
        };
    }

    private base64Encode(str: string): string {
        const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let t = '';
        let n: number;
        let r: number;
        let i: number;
        let s: number;
        let o: number;
        let u: number;
        let a: number;
        let f = 0;

        str = this.utf8Encode(str);

        while (f < str.length) {
            n = str.charCodeAt(f++);
            r = str.charCodeAt(f++);
            i = str.charCodeAt(f++);
            s = n >> 2;
            o = ((n & 3) << 4) | (r >> 4);
            u = ((r & 15) << 2) | (i >> 6);
            a = i & 63;
            if (isNaN(r)) {
                u = a = 64;
            } else if (isNaN(i)) {
                a = 64;
            }
            t = t + keyStr.charAt(s) + keyStr.charAt(o) + keyStr.charAt(u) + keyStr.charAt(a);
        }

        return t;
    }

    private utf8Encode(str: string): string {
        let t = '';
        str = str.replace(/\r\n/g, '\n');

        for (let n = 0; n < str.length; n++) {
            const r = str.charCodeAt(n);

            if (r < 128) {
                t += String.fromCharCode(r);
            } else if (r > 127 && r < 2048) {
                t += String.fromCharCode((r >> 6) | 192);
                t += String.fromCharCode((r & 63) | 128);
            } else {
                t += String.fromCharCode((r >> 12) | 224);
                t += String.fromCharCode(((r >> 6) & 63) | 128);
                t += String.fromCharCode((r & 63) | 128);
            }
        }

        return t;
    }
}

export default new AccessManagementApi();
