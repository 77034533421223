// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import LinkIcon from '@mui/icons-material/Link';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADefaultIconButton, AIconButtonBaseProps } from '../../common/buttons';

export interface ACopyLinkIconButtonProps extends AIconButtonBaseProps {}

export default function ACopyLinkIconButton(props: ACopyLinkIconButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1162');

    return <ADefaultIconButton {...buttonProps} icon={<LinkIcon />} tooltip={tooltip2} />;
}
