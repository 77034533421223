// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

// MUI
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../core/resources';
import FormValidator from '../../core/FormValidator';
import auth from '../../core/authorization';

// Common
import { AAddButton } from '../../common/buttons';
import { ViewTitle } from '../../common/typography';
import { Context } from '../../common/contextSelector/ContextSelector';
import contextUtils from '../../common/contextSelector/contextUtils';

// Interfaces
import { IConversationResult, INewConversationResponse } from '../../interfaces/ICommunication';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import { getContactPersons } from '../../publicContactPersons/publicContactPersonsApi';
import companyApi from '../../api/companyApi';

// Feature
import Inbox from './inbox/Inbox';
import CreateConversationDialog from './CreateConversationDialog';
import IContactPersonRecipient from '../general/interfaces/IContactPersonRecipient';
import ConversationDialog from './inbox/ConversationDialog';
import {
    getSnellmanContactPersonTitle,
    mapCompanyContactPersons,
} from '../general/helpers/helperFunctions';

export default function Messaging() {
    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const context = useAppSelector((state) => state.context);
    const configuration = useAppSelector((state) => state.configuration);
    const location = useLocation();

    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
        'AnelmaLayout',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [createNewDialogVisible, setCreateNewDialogVisible] = useState<boolean>(false);
    const [snellmanContactPersons, setSnellmanContactPersons] = useState<IContactPersonRecipient[]>(
        []
    );
    const [companyContactPersons, setCompanyContactPersons] = useState<IContactPersonRecipient[]>(
        []
    );
    const [conversationResult, setConversationResult] = useState<INewConversationResponse | null>(
        null
    );
    const [messageIdForStatusUpdate, setMessageIdForStatusUpdate] = useState<string>('');
    const [farmCompanyTitles, setFarmCompanyTitles] = useState<ParameterValues>([]);
    const [logisticsCompanyTitles, setLogisticsCompanyTitles] = useState<ParameterValues>([]);
    const [snellmanCompanyTitles, setSnellmanCompanyTitles] = useState<ParameterValues>([]);

    // #region Effects
    useEffect(() => {
        paramRepository.load(['AnelmaCommunication', 'AnelmaGeneral']).then(() => {
            setFarmCompanyTitles(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
            setLogisticsCompanyTitles(paramRepository.resource('AnelmaGeneral', 'LogisticsTitles'));
            setSnellmanCompanyTitles(paramRepository.resource('AnelmaGeneral', 'SnellmanTitles'));
        });

        const fetchContactPersonsData = async () => {
            await getContactPersons(!!loginStatus.loggedIn).then(
                (data) => {
                    setSnellmanContactPersons(
                        data.Items.map((item) => ({
                            Id: item.PersonGUID,
                            Name: item.Name,
                            EmailAddress: item.EmailAddress,
                            CompanyId: item.CompanyGUID,
                            Title: getSnellmanContactPersonTitle(
                                item,
                                configuration.data.culture.supportedCultures.find((c) => c.selected)
                                    ?.value
                            ),
                            ProfilePicture: item.PictureUrl,
                            CompanyName: 'Snellmanin Lihanjalostus Oy',
                        }))
                    );
                },
                () => {
                    enqueueSnackbar(t('AnelmaCommunication:1109'), {
                        variant: 'error',
                    });
                }
            );
        };

        if (auth.canAddRestrictedEntityToConversation) fetchContactPersonsData();
    }, []);

    useEffect(() => {
        if (context.data.currentContext) getCompanyContactPersons(context.data.currentContext);
    }, [context, farmCompanyTitles, logisticsCompanyTitles, snellmanCompanyTitles]);
    // #endregion

    const getCompanyContactPersons = (context: Context) => {
        setLoading(true);
        companyApi
            .searchCompaniesContactPersonsByCompanyName(
                contextUtils.parseContextString(context.context).name
            )
            .then((response) => {
                if (!response) {
                    enqueueSnackbar('AnelmaGeneral:1020', { variant: 'error' });
                    setLoading(false);
                    return;
                }
                setCompanyContactPersons(
                    mapCompanyContactPersons(
                        response.Items,
                        farmCompanyTitles,
                        logisticsCompanyTitles,
                        snellmanCompanyTitles
                    )
                );
                setLoading(false);
            });
    };

    const handleUpdateMsgStatusAfterNewCreatingConversation = (id: string) => {
        setMessageIdForStatusUpdate(id);
    };

    const getConversationLocationData = () => {
        return location.state !== null && location.state.options.ConversationId
            ? location.state.options.ConversationId
            : null;
    };

    const getConversationData = () => {
        return {
            ConversationId: conversationResult?.ConversationId,
            ParticipantList: conversationResult?.RecipientList,
            Topic: conversationResult?.Topic.toString(),
            Title: conversationResult?.Title,
            CreatedDateTime: conversationResult?.CreatedDateTime,
            WasRead: false,
            RelatedFarmId: conversationResult?.FarmId,
            Attachments: conversationResult?.Attachments,
        } as IConversationResult;
    };

    const isCreateNewBtnVisible = () => {
        if (!auth.canCreateMessage) return true;
        if (loading) return true;

        return false;
    };

    return (
        <Container data-robot-id={'app-body-messages'}>
            <ViewTitle>{t('AnelmaLayout:1019')}</ViewTitle>

            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                    <AAddButton
                        disabled={isCreateNewBtnVisible()}
                        onClick={() => setCreateNewDialogVisible(true)}
                        type='action'
                    >
                        {t('AnelmaCommunication:1000')}
                    </AAddButton>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Inbox
                        personCompanyContactPersons={companyContactPersons}
                        snellmanContactPersons={snellmanContactPersons}
                        conversationResult={getConversationData()}
                        updateStatusForMessage={messageIdForStatusUpdate}
                        preSelectedConversationId={getConversationLocationData()}
                    />
                </Grid>

                {createNewDialogVisible && (
                    <CreateConversationDialog
                        handleConversationResponseChange={(response) => {
                            if (!response) return;
                            setConversationResult(response as INewConversationResponse);
                        }}
                        onClose={() => setCreateNewDialogVisible(false)}
                        personCompanyContactPersons={companyContactPersons}
                        snellmanContactPersons={snellmanContactPersons}
                        recipients={[]}
                        validator={new FormValidator()}
                    />
                )}
                {conversationResult && (
                    <ConversationDialog
                        onClose={() => setConversationResult(null)}
                        personCompanyContactPersons={companyContactPersons}
                        snellmanContactPersons={snellmanContactPersons}
                        updateMessageStatus={handleUpdateMsgStatusAfterNewCreatingConversation}
                        conversation={getConversationData()}
                    />
                )}
            </Grid>
        </Container>
    );
}
