// Core
import utils from '../../../core/utils';

// Interfaces
import { DialogModeEnum } from '../../../interfaces/enums';
import {
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
    ICollectWeek,
} from '../../../interfaces/IAnnouncement';
import { GUIDType } from '../../../interfaces/types';

export const isSalesTypeInvalidForSave = (salesType: number): boolean => {
    return salesType === 0 || salesType === 1 ? true : false;
};

export const isDataValidForSave = (
    mode: DialogModeEnum,
    farmId: GUIDType | undefined | null,
    producerNumber: string,
    batches: IAnimalAnnouncementBatch[] | undefined | null,
    collectWeekStart: ICollectWeek,
    collectWeekEnd: ICollectWeek,
    announcementNumber: number | undefined | null,
    holdingSiteId: string
): boolean => {
    {
        switch (mode) {
            case DialogModeEnum.Create:
                return isCreateDataValid(
                    farmId,
                    producerNumber,
                    batches,
                    collectWeekStart,
                    collectWeekEnd,
                    holdingSiteId
                );
            case DialogModeEnum.Edit:
                return isUpdateDataValid(
                    farmId,
                    producerNumber,
                    announcementNumber,
                    holdingSiteId
                );
        }
    }
};

export const isDataValidForCreate = (
    farmId: GUIDType | undefined | null,
    producerNumber: string,
    animals: IAnimalAnnouncementAnimal[],
    collectWeekStart: ICollectWeek,
    collectWeekEnd: ICollectWeek,
    holdingSiteId: string
): boolean => {
    if (
        !utils.isGuidEmpty(farmId) &&
        producerNumber &&
        producerNumber !== '' &&
        !isNaN(Number(producerNumber)) &&
        animals &&
        animals.length > 0 &&
        collectWeekStart.Week >= 0 &&
        collectWeekStart.Week <= 52 &&
        collectWeekEnd.Week >= 0 &&
        collectWeekEnd.Week <= 52 &&
        holdingSiteId.length > 0
    )
        return true;
    return false;
};

const isCreateDataValid = (
    farmId: GUIDType | undefined | null,
    producerNumber: string,
    batches: IAnimalAnnouncementBatch[] | undefined | null,
    collectWeekStart: ICollectWeek,
    collectWeekEnd: ICollectWeek,
    holdingSiteId: string
): boolean => {
    if (
        !utils.isGuidEmpty(farmId) &&
        producerNumber &&
        producerNumber !== '' &&
        !isNaN(Number(producerNumber)) &&
        batches &&
        batches.length > 0 &&
        collectWeekStart.Week >= 0 &&
        collectWeekStart.Week <= 52 &&
        collectWeekEnd.Week >= 0 &&
        collectWeekEnd.Week <= 52 &&
        holdingSiteId
    )
        return true;
    return false;
};

const isUpdateDataValid = (
    farmId: GUIDType | undefined | null,
    producerNumber: string,
    announcementNumber: number | undefined | null,
    holdingSiteId: string
): boolean => {
    if (
        !utils.isGuidEmpty(farmId) &&
        producerNumber &&
        producerNumber !== '' &&
        !isNaN(Number(producerNumber)) &&
        announcementNumber != null &&
        !isNaN(Number(announcementNumber)) &&
        holdingSiteId.length > 0
    )
        return true;

    return false;
};
