import axios from 'axios';

export class ReportingApiUnintercepted {
    readonly uninterceptedAxiosObject = axios.create();
    _qlikCloudBaseUrl: string | null = null;
    _webIntegrationId: string | null = null;

    initValues = (qlikCloudBaseUrl: string | null, webIntegrationId: string | null) => {
        this._qlikCloudBaseUrl = qlikCloudBaseUrl;
        this._webIntegrationId = webIntegrationId;
    };

    login(key: string) {
        return this.uninterceptedAxiosObject.post<any>(
            `${this._qlikCloudBaseUrl}/login/jwt-session?qlik-web-integration-id=${this._webIntegrationId}`,
            {},
            {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${key}`,
                    'Content-Type': 'application/json',
                    'qlik-web-integration-id': this._webIntegrationId,
                },
            }
        );
    }

    getCrsfToken() {
        return this.uninterceptedAxiosObject.get<any>(
            `${this._qlikCloudBaseUrl}/api/v1/csrf-token`,
            {
                withCredentials: true,
                headers: {
                    'qlik-web-integration-id': this._webIntegrationId,
                },
            }
        );
    }

    logout() {
        return this.uninterceptedAxiosObject.get<any>(`${this._qlikCloudBaseUrl}/logout`, {
            withCredentials: true,
        });
    }
}
