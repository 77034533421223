// Libraries
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { mergeRight } from 'ramda';

// Api
import api from '../api/startupApi';

// Store
import { SliceStatus } from './IStore';

interface LoginStatusState {
    data: LoginStatus;
    status: SliceStatus;
}

export interface LoginStatus {
    changePasswordUrl: string;
    firstName: string;
    lastName: string;
    loggedIn: boolean | null;
    loginSessionAbsoluteExpiration: string;
    loginSessionExpiration: string;
    name: string;
}

const initialState: LoginStatusState = {
    data: {
        changePasswordUrl: '',
        firstName: '',
        lastName: '',
        loggedIn: null,
        loginSessionAbsoluteExpiration: '',
        loginSessionExpiration: '',
        name: '',
    },
    status: 'initial',
};

function loadData(state: Draft<LoginStatusState>, action: PayloadAction<LoginStatus>) {
    state.data = mergeRight(state.data, action.payload);
    state.status = 'ready';
}

const loginStatusSlice = createSlice({
    name: 'loginStatus',
    initialState,
    reducers: {
        load: loadData,
        updateExpiration(state, action) {
            state.data.loginSessionExpiration = action.payload;
        },
        updateIsLoading(state) {
            state.status = 'loading';
        },
    },
});

const { load, updateExpiration, updateIsLoading } = loginStatusSlice.actions;

export const loadLoginStatusAsync = () => {
    return async (dispatch: any) => {
        try {
            dispatch(updateIsLoading());
            const loginStatus = await api.getLoginStatus();
            if (loginStatus) dispatch(load(loginStatus.Entity));
        } catch (err) {
            console.error(err);
        }
    };
};

export const updateExpirationTime = function (expiration: any) {
    return (dispatch: any) => {
        dispatch(updateExpiration(expiration));
    };
};

export default loginStatusSlice.reducer;
