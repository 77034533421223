// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// MUI
import { Container, Grid } from '@mui/material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../core/resources';
import utils from '../../core/utils';
import auth from '../../core/authorization';

// Common
import { ADataGrid, AGridColumns } from '../../common/dataGrid';
import { TextWithTooltip } from '../../common/typography';
import AAttachmentIconButton from '../../common/buttons/AAttachmentIconButton';
import AWarningIconButton from '../../common/buttons/AWarningIconButton';
import ADoneIconButton from '../../common/buttons/ADoneIconButton';
import APriorityHighIconButton from '../../common/buttons/APriorityHighIconButton';

// Interfaces
import { IAnimalPayloadItem, IBatch } from '../../interfaces/IAnimal';
import {
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
    ITiltuAnnouncement,
} from '../../interfaces/IAnnouncement';
import { EmptyGUID, GUIDType } from '../../interfaces/types';
import { AnnouncementTypeEnum } from '../../interfaces/enums';

// Store
// API
import chainInformationApi from '../../api/chainInformationApi';
import animalListApi from '../../api/animalListApi';
import animalAnnouncementApi from '../../api/animalAnnouncementApi';

// Feature - Chain Information
import {
    IChainInformationData,
    IChainInformationResult,
} from '../chain-information/IChainInformationDialog';
import ChainInformationDialog from '../chain-information/components/ChainInformationDialog';

// Feature - Animal Announcements
import { areUpdateRulesValid } from '../announcements/helpers/data';
import { addDashToEuIdentifier } from '../announcements/helpers/euIdentifier';

export interface AnimalPayloadsBatchesGridProps {
    payload: IAnimalPayloadItem;
    batches: IBatch[];
}

export const AnimalPayloadsBatchesGrid = (props: AnimalPayloadsBatchesGridProps) => {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaAnimalPayload',
        'AnelmaBovine',
    ]);

    const [loading] = useState<boolean>(false);
    const [animalAnnouncementTypeParams, setAnimalAnnouncementTypeParams] =
        useState<ParameterValues | null>(null);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues | null>(null);
    const [bovineSalesTypeParams, setBovineSalesTypeParams] = useState<ParameterValues | null>(
        null
    );
    const [chainInformationDialogVisible, setChainInformationDialogVisible] =
        useState<boolean>(false);
    const [announcementId, setAnnouncementId] = useState<GUIDType>('');
    const [announcementNumber, setAnnouncementNumber] = useState<string>('');
    const [animalsForChainInformation, setAnimalsForChainInformation] = useState<
        IAnimalAnnouncementAnimal[]
    >([]);
    const [chainInformations, setChainInformations] = useState<IChainInformationData[]>([]);
    const [companyName, setCompanyName] = useState<string>('');
    const [producerNumber, setProducerNumber] = useState<string>('');
    const [readOnly, setReadOnly] = useState<boolean>(false);

    const CHAIN_INFO_FIELD = 'ChainInfo';

    useEffect(() => {
        initializeParameters();
    }, []);

    useEffect(() => {
        if (props.batches.length > 0)
            loadAnnouncementRelatedChainInformationData(
                props.batches
                    .filter((b) => b.AnnouncementInfo.AnnouncementId !== EmptyGUID)
                    .flatMap((batch) => batch.AnnouncementInfo.AnnouncementId)
                    .filter((_, idx, batches) => batches.indexOf(_) === idx)
            );
    }, [props.batches]);

    const initializeParameters = () => {
        paramRepository
            .load(['AnelmaAnimalAnnouncement', 'AnelmaAnimalPayload', 'AnelmaBovine'])
            .then(() => {
                setSalesTypeParams(paramRepository.resource('AnelmaAnimalPayload', 'SalesType'));
                setAnimalAnnouncementTypeParams(
                    paramRepository.resource('AnelmaAnimalAnnouncement', 'AnimalAnnouncementType')
                );
                setBovineSalesTypeParams(paramRepository.resource('AnelmaBovine', 'SalesType'));
            });
    };

    const loadAnnouncementRelatedChainInformationData = (announcementIds: string[]) => {
        if (announcementIds.length > 0)
            chainInformationApi.loadMultipleChainInformations(announcementIds).then((response) => {
                if (response)
                    setChainInformations(
                        getChainInformationDataFromAnnouncementBatch(response.Items)
                    );
            });
    };

    const getChainInformationDataFromAnnouncementBatch = (
        chainInformationData: IChainInformationResult[]
    ) => {
        return chainInformationData.map((_) => ({
            AnnouncementId: _.AnnouncementId,
            MedicatedFeed: _.MedicatedFeed,
            HealthInformation: _.HealthInformation,
            AuthorityRegulations: _.AuthorityRegulations,
            ForeignObject: _.ForeignObject,
            GmFree: _.GmFree,
            MedicalAccounting: _.MedicalAccounting,
            WaitingTimeOver: _.WaitingTimeOver,
            NasevaInfo: _.NasevaInfo,
            SlaughterHouseInfo: _.SlaughterHouseInfo,
            MedicationInfo: _.MedicationInfo,
            Anomalies: _.Anomalies,
            Regulations: _.Regulations,
            SamplingOrder: _.SamplingOrder,
            VetName: _.VetName,
            AdditionalInfo: _.AdditionalInfo,
            ForeignObjectInfo: _.ForeignObjectInfo,
            AnimalSymptoms: _.Symptoms,
            OtherSymptom: _.OtherSymptom,
            AnimalDiseases: _.Diseases,
            OtherDisease: _.OtherDisease,
            MedicatedAnimals: _.Medications,
            FormConfirmation: _.FormConfirmation,
            Signature: _.Signature,
            Signatory: _.Signatory,
            SignatureDate: _.SignatureDate,
        }));
    };

    const checkChainInfoFound = (announcementId: GUIDType) => {
        let chainInfoFound = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoFound) {
            return false;
        } else {
            return true;
        }
    };

    const checkChainInfoConfirmed = (announcementId: GUIDType) => {
        let chainInfoToCheck = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoToCheck?.FormConfirmation) {
            return true;
        } else {
            return false;
        }
    };

    const checkChainInfoNotify = (announcementId: GUIDType) => {
        let chainInfoToCheck = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoToCheck) {
            if (
                chainInfoToCheck.MedicatedFeed === true ||
                chainInfoToCheck.HealthInformation === true ||
                chainInfoToCheck.MedicalAccounting === true ||
                chainInfoToCheck.AuthorityRegulations === true ||
                chainInfoToCheck.ForeignObject === true ||
                chainInfoToCheck.GmFree === true ||
                chainInfoToCheck.AdditionalInfo.length > 0 ||
                chainInfoToCheck.MedicatedAnimals.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        }
    };

    const openChainInformationDialog = (data: any) => {
        let animalArray: IAnimalAnnouncementAnimal[] = [];
        let euIds: string[] = [];
        setAnnouncementId(data.AnnouncementInfo.AnnouncementId);
        setAnnouncementNumber(data.AnnouncementInfo.AnnouncementNumber);
        setCompanyName(data.CompanyInfo.Name);
        setProducerNumber(data.CompanyInfo.ProducerNumber);

        animalAnnouncementApi
            .getAnimalAnnouncementFromTiltu(data.AnnouncementInfo.AnnouncementNumber)
            .then((response) => {
                if (response) {
                    var result = (response as unknown as any)?.Result as ITiltuAnnouncement;

                    checkReadOnly(result);
                    for (let i = 0; (i < result.Batches.length) as any; i++) {
                        var euId = addDashToEuIdentifier(result.Batches[i].EUIdentifier);
                        euIds.push(euId);
                    }
                }
            });

        animalListApi.getAnimals(data.CompanyInfo.Guid).then((response) => {
            if (response) {
                for (let i = 0; i < response.Items.length; i++) {
                    let animal: IAnimalAnnouncementAnimal = {
                        AnimalId: response.Items[i].Id,
                        BirthDate: response.Items[i].BirthDate
                            ? response.Items[i].BirthDate.toString()
                            : '',
                        Breed: response.Items[i].Breed,
                        EuIdentifier: response.Items[i].EuIdentifier,
                        SalesType: response.Items[i].SalesType,
                        Sex: response.Items[i].Sex,
                    };
                    if (euIds.includes(response.Items[i].EuIdentifier)) {
                        animalArray.push(animal);
                    }
                }
                setAnimalsForChainInformation(animalArray);
                setChainInformationDialogVisible(true);
            }
        });
    };

    const checkReadOnly = (announcement: ITiltuAnnouncement) => {
        if (announcement.Batches.filter((i) => i.AnnouncementBatchStatus !== 'LO').length > 0) {
            setReadOnly(true);
        }
        if (announcement) {
            let batchesToCheck: IAnimalAnnouncementBatch[] = [];
            for (let i = 0; i < announcement.Batches.length; i++) {
                let batch: IAnimalAnnouncementBatch = {
                    Id: '',
                    BatchNumber: parseInt(announcement.Batches[i].AnnouncementBatchNumber),
                    BatchStatus: announcement.Batches[i].AnnouncementBatchStatus,
                    CollectWeekStart: announcement.Batches[i].CollectWeekStart,
                    CollectWeekEnd: announcement.Batches[i].CollectWeekEnd,
                    CollectWeekDay: announcement.Batches[i].CollectWeekDay1,
                    Amount: 0,
                    BatchInfo: announcement.Batches[i].AddInfo,
                    Animals: [],
                };
                batchesToCheck.push(batch);
            }
            if (areUpdateRulesValid(batchesToCheck, false, 1) === false) {
                setReadOnly(true);
            }
        }
    };

    const columnPicker = (type: number) => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
            case AnnouncementTypeEnum.ManualBovineSlaughter:
            case AnnouncementTypeEnum.SlaughterWithChainInformation:
                return columns;
            default:
                return columns.filter((_) => _.field !== CHAIN_INFO_FIELD);
        }
    };

    const columns: AGridColumns = [
        {
            field: 'CompanyName',
            headerName: t('AnelmaAnimalPayload:1034'),
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            valueGetter: (params) => {
                const companyInfo = (params.row as IBatch).CompanyInfo;

                return companyInfo?.Name ?? '';
            },
            width: 200,
        },
        {
            field: 'AnnouncementNumber',
            headerName: t('AnelmaAnimalPayload:1027'),
        },
        {
            field: 'AnnouncementType',
            headerName: t('AnelmaAnimalPayload:1028'),
            valueGetter: (params) => {
                const announcementInfo = (params.row as IBatch).AnnouncementInfo;

                if (!animalAnnouncementTypeParams) return '';

                const paramFound = animalAnnouncementTypeParams.find(
                    (p) => Number(p.code) === announcementInfo?.AnnouncementType
                );
                return paramFound ? paramFound.text : '';
            },
            width: 150,
        },
        {
            field: CHAIN_INFO_FIELD,
            headerName: t('AnelmaAnimalPayload:1035'),
            renderCell: (params) => {
                return (
                    params.row.AnnouncementInfo.AnnouncementType !==
                        AnnouncementTypeEnum.Intermediation && (
                        <span>
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <AAttachmentIconButton
                                    disabled={!auth.canViewChainInformation}
                                    key={`${params.id}-attachment`}
                                    onClick={() => {
                                        openChainInformationDialog(params.row);
                                    }}
                                    tooltip={t('AnelmaBovine:1143')}
                                />
                                {checkChainInfoFound(
                                    params.row.AnnouncementInfo.AnnouncementId
                                ) && (
                                    <AWarningIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1144')}
                                    />
                                )}
                                {checkChainInfoConfirmed(
                                    params.row.AnnouncementInfo.AnnouncementId
                                ) && (
                                    <ADoneIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1145')}
                                    />
                                )}
                                {checkChainInfoNotify(
                                    params.row.AnnouncementInfo.AnnouncementId
                                ) && (
                                    <APriorityHighIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1146')}
                                    />
                                )}
                            </Grid>
                        </span>
                    )
                );
            },
            filterable: false,
        },
        {
            field: 'SalesType',
            headerName: t('AnelmaAnimalPayload:1029'),
            renderCell: (params) => {
                const text = (params?.formattedValue as string) ?? '';
                return <TextWithTooltip text={text} />;
            },
            valueGetter: (params) => {
                const rowValue = (params.row as IBatch).SalesType;

                if (!rowValue || !salesTypeParams) return '';

                const foundParam = salesTypeParams.find((p) => Number(p.code) === rowValue);
                return foundParam?.text ?? '';
            },
        },
        {
            field: 'AnimalCount',
            headerName: t('AnelmaAnimalPayload:1008'),
        },
        {
            field: 'PlannedTime',
            headerName: t('AnelmaAnimalPayload:1030'),
        },
        {
            field: 'EUNumber',
            headerName: t('AnelmaAnimalPayload:1042'),
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            valueGetter: (params) => {
                const euNumber = (params.row as IBatch)?.EUNumber;
                const salesType = (params.row as IBatch)?.SalesType;

                if (!euNumber || !salesType) return '';

                return bovineSalesTypeParams?.find((p) => Number(p.code) === salesType)
                    ? utils.formatEuIdentifier(euNumber)
                    : euNumber;
            },
            width: 150,
        },
    ];

    const dataRows = props.batches.map((i, n) => mergeRight(i, { id: n }));

    return (
        <Container disableGutters id='anelma-animals-animal-payloads-batches-grid'>
            <ADataGrid
                columns={columnPicker(props.batches[0].AnnouncementInfo.AnnouncementType)}
                enablePagination
                enableRowNumbers
                rows={!dataRows ? [] : dataRows}
                enableFiltering
                loading={loading}
            />

            {chainInformationDialogVisible && (
                <ChainInformationDialog
                    onClose={() => {
                        setChainInformationDialogVisible(false);
                    }}
                    forceReadOnly={readOnly}
                    announcementData={null}
                    announcementId={announcementId}
                    announcementNumber={announcementNumber}
                    animals={animalsForChainInformation}
                    companyName={companyName}
                    producerNumber={producerNumber}
                />
            )}
        </Container>
    );
};
