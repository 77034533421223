// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import ViewLoader from '../../common/ViewLoader';
import useQuery from '../../common/utils/useQuery';

import { contextControl } from '../../common/contextSelector/ContextSelector';

// Interfaces
import { IFarmDetails } from '../../interfaces/IBusinessEntities';
import { AnimalFilterTypeEnum } from '../../interfaces/enums';
import {
    IAnimalSummaryExtraInfo,
    IFarmAnimal,
    IFilteredAnimalList,
} from '../../interfaces/IAnimal';
import { IAnnouncementImport } from '../../interfaces/IAnnouncement';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import companyApi from '../../api/companyApi';
import animalAnnouncementApi from '../../api/animalAnnouncementApi';
import Api from '../../api/animalListApi';
import AnimalsApi from '../../api/animalsApi';

// Feature
import AnnouncementImport from '../announcements/components/general/AnnouncementImport';
import AnimalList from './AnimalList';

export default function AnimalListMain() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine', 'AnelmaLayout']);
    const { enqueueSnackbar } = useSnackbar();
    const query = useQuery();

    const importGUID = query.get('imported-announcement');

    const [importData, setImportData] = useState<IAnnouncementImport | null>(null);
    const [farmData, setFarmData] = useState<IFarmDetails | null>(null);
    const [allAnimalsData, setAllAnimalsData] = useState<IFilteredAnimalList[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const context = useAppSelector((state) => state.context);

    useEffect(() => {
        if (importGUID) {
            animalAnnouncementApi.getImportData(importGUID).then((response) => {
                if (!response) {
                    enqueueSnackbar(`${t(`404`)}`, {
                        variant: 'error',
                    });
                    return;
                }

                setImportData(response.Entity);
            });
        }
    }, [importGUID]);

    useEffect(() => {
        if (importGUID && context?.data?.currentContext) {
            companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    if (response?.Entity) setFarmData(response.Entity as IFarmDetails);
                    else
                        enqueueSnackbar(`${t(`Farm 404`)}`, {
                            variant: 'error',
                        });
                });
        }
    }, [context]);

    useEffect(() => {
        if (importGUID && importData && farmData) {
            const currentFarmId = farmData.Aliases.find((x) => x.Type === 3)?.Value;

            if (currentFarmId !== importData.FarmId) {
                contextControl.setFarm(importData.FarmId);
            } else if (farmData.Id) {
                const farmId = farmData.Id;
                const missingHoldingSites = [];

                if (importData.HoldingPlaces)
                    for (let holdingPlace of importData.HoldingPlaces) {
                        const holdingSite = farmData.HoldingSites.find(
                            (hs) => hs.HoldingSiteId === holdingPlace
                        );
                        if (!holdingSite) {
                            missingHoldingSites.push(holdingPlace);
                        }
                    }

                if (missingHoldingSites.length > 0) {
                    enqueueSnackbar(t('AnelmaBovine:1157'), {
                        variant: 'error',
                    });

                    setIsLoading(false);
                    return;
                }

                AnimalsApi.syncAnimals(farmId)
                    .then((response) => {
                        if (response?.Entity?.Success) {
                            enqueueSnackbar(t('AnelmaGeneral:1025'), {
                                variant: 'success',
                            });
                        } else {
                            enqueueSnackbar(t('AnelmaGeneral:1026'), {
                                variant: 'error',
                            });
                        }

                        Api.getFilteredAnimals(farmId)
                            .then((response) => {
                                if (response && response?.Items?.length > 0)
                                    setAllAnimalsData(response.Items);
                            })
                            .catch(() => {
                                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                                    variant: 'error',
                                });
                            })
                            .finally(() => setIsLoading(false));
                    })
                    .catch((err) => {
                        enqueueSnackbar(t('AnelmaGeneral:1020'), {
                            variant: 'error',
                        });
                    });
            }
        }
    }, [importData, farmData]);

    if (!importGUID) return <AnimalList />;
    if (!importData) return <ViewLoader />;

    const handleUpdatedSalesType = (data: IFarmAnimal[]) => {
        if (allAnimalsData !== null) {
            let currentAnimalsData = allAnimalsData;
            for (const changedAnimal of data) {
                for (const dataRow of currentAnimalsData) {
                    for (const animal of dataRow.Animals) {
                        if (changedAnimal.AnimalGUID === animal.Id) {
                            animal.SalesType = changedAnimal.SalesType;
                        }
                    }
                }
            }
            setAllAnimalsData([...currentAnimalsData]);
        }
    };

    return isLoading ? (
        <ViewLoader />
    ) : (
        <AnnouncementImport
            data-robot-id={'app-body-animal-list-main'}
            data={importData}
            farmData={farmData}
            animalData={
                (allAnimalsData?.find((al) => al.FilterType === AnimalFilterTypeEnum.All)
                    ?.Animals ?? []) as IAnimalSummaryExtraInfo[]
            }
            onClose={() => setImportData(null)}
            processAnnouncementSave={() => {}}
            mapUpdatedSalesTypeToAllAnimalsData={handleUpdatedSalesType}
        />
    );
}
