// Libraries
import { useEffect, useState, useCallback } from 'react';
import { isNil } from 'ramda';

// Core
import { boundaryRepository } from '../../core/boundaries';
import { ResourceBoundaryApplication } from '../../core/resources';

// Inputs
import { ATextInput, ATextInputBaseProps } from '../inputs';

export type ANumberInputMode = 'dec' | 'int';
export type ANumberInputValue = number | null;

export interface ANumberInputProps extends ATextInputBaseProps<ANumberInputValue> {
    boundaryName?: string;
    max?: number;
    mode: ANumberInputMode;
    min?: number;
}

export default function ANumberInput(props: ANumberInputProps) {
    const { boundaryName, error, max, mode, min, onChange, value, ...textInputProps } = props;
    const { required } = textInputProps;

    const valueToString = (v: ANumberInputValue): string => {
        return v === null ? '' : `${v}`;
    };

    const stringToValue = useCallback(
        (str: string): ANumberInputValue => {
            str = parseString(mode, str);
            if (mode === 'int') {
                const int = parseInt(str, 10);
                return Number.isNaN(int) ? null : int;
            }
            const flt = parseFloat(str);
            return Number.isNaN(flt) ? null : flt;
        },
        [mode]
    );

    const parseString = (mode: ANumberInputMode, str: string) => {
        str = str.replace(/ /g, '');
        if (mode === 'int') return str;
        return str.replace(/,/g, '.');
    };

    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<string>(valueToString(value));
    const [err, setErr] = useState<boolean>(false);
    const [maxValue, setMaxValue] = useState(max);
    const [minValue, setMinValue] = useState(min);

    useEffect(() => {
        setVal(valueToString(value));
    }, [value]);

    useEffect(() => {
        if (!boundaryName) return;
        const [groupName, valueName] = boundaryName.split(':', 2);
        const group = groupName as ResourceBoundaryApplication;
        boundaryRepository.load([group]).then(() => {
            const params = boundaryRepository.resource(group, valueName);
            setMaxValue(
                Number.isInteger(params.maxValue) ? (params.maxValue as number) : undefined
            );
            setMinValue(
                Number.isInteger(params.minValue) ? (params.minValue as number) : undefined
            );
        });
    }, [boundaryName]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (val === '') return !required;

            const parsedVal = parseString(mode, val);
            const regExp = mode === 'dec' ? /^-?\d*\.?\d*$/ : /^-?\d*$/;
            if (!regExp.test(parsedVal)) return false;

            const num = stringToValue(val);
            if (num === null) return false;

            if (!isNil(minValue) && minValue > num) return false;
            if (!isNil(maxValue) && maxValue < num) return false;
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            // In case boundaries are provided, check against isValid helper. Decided not to change the default behaviour by returning isValid by default... Done in http://jira.mtech.fi/browse/AN-2107
            if (boundaryName) return isValid();
            return true;
        };

        // NOTE: Validator state is set inside ATextInput based on error prop.
        setErr(!isValidAfterTouch());
    }, [error, maxValue, minValue, mode, required, stringToValue, touched, val]);

    return (
        <ATextInput
            data-robot-id={'text-input-' + val}
            {...textInputProps}
            error={err || error}
            onChange={(v) => {
                setTouched(true);
                setVal(() => v);
                onChange(stringToValue(v));
            }}
            value={val}
        />
    );
}
