// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { mergeRight } from 'ramda';
import moment from 'moment';

// MUI
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../../core/resources';
import FormValidator from '../../../core/FormValidator';
import Utils from '../../../core/utils';
import { dayFormat } from '../../../core/constants';
import utils from '../../../core/utils';
import authorization from '../../../core/authorization';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
    ConfirmDeletionDialog,
} from '../../../common/dialog';
import { ADataGrid, AGridColumns } from '../../../common/dataGrid';
import { AAutocompleteValue, AInputItem, ARadioButtons } from '../../../common/inputs';
import Annotation from '../../../common/annotations/Annotation';
import { AAutocomplete } from '../../../common/inputs';
import { AAddButton, ADeleteIconButton, AEditIconButton } from '../../../common/buttons';
import { TextWithTooltip } from '../../../common/typography';

// Interfaces
import {
    IAnimalSummary,
    IAnimalSummaryExtraInfo,
    IFilteredAnimalList,
} from '../../../interfaces/IAnimal';
import { EmptyGUID, GUIDType } from '../../../interfaces/types';
import { IMainGroup, ISubGroup } from '../../../interfaces/IGroup';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import groupsApi from '../../../api/groupsApi';
import companyApi from '../../../api/companyApi';

// Feature
import AddAnimalDialog from '../../shared/components/AddAnimalDialog';
import { SecondaryDialogTypeEnum } from '../../shared/types/enum';
import AddGroupDialog from './AddGroupDialog';
import '../../shared/styles/sharedStyles.scss';

export interface IGroupDialogProps {
    onClose: () => void;
    onDataChange: (maingroups: IMainGroup[]) => void;
    allAnimals: IFilteredAnimalList[] | null;
    animals: IAnimalSummaryExtraInfo[];
    selectedAnimals: IAnimalSummary[];
}

export interface IToggleTracker {
    id: GUIDType;
    toggleStatus: boolean;
}

export interface IAnimalDeletionModel {
    animalId: GUIDType;
    groupId: GUIDType;
}

export default function GroupDialog(props: IGroupDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);
    const { enqueueSnackbar } = useSnackbar();
    const auth = authorization;
    const COL_W_S: number = 100;
    const COL_W_M: number = 150;
    const validator = new FormValidator();
    const [paramBovineSex, setParamBovineSex] = useState<ParameterValues | null>(null);
    const [selectedAnimals, setSelectedAnimals] = useState<IAnimalSummary[]>(props.selectedAnimals);
    const [dataRows, setDataRows] = useState<GridValidRowModel[]>([]);
    const [selectedDialogOption, setSelectedDialogOption] = useState<number>(1);
    const [addAnimalDialogVisible, setAddAnimalDialogVisible] = useState<boolean>(false);
    const [addGroupDialogVisible, setAddGroupDialogVisible] = useState<boolean>(false);
    const [animals, setAnimals] = useState<IAnimalSummaryExtraInfo[]>([]);
    const [farmGuid, setFarmGuid] = useState<GUIDType>('');
    const context = useAppSelector((state) => state.context);
    const [mainGroups, setMainGroups] = useState<IMainGroup[]>([]);
    const [toggleTracker, setToggleTracker] = useState<IToggleTracker[]>([]);
    const [addDialogMode, setAddDialogMode] = useState<string>('add');
    const [mainGroupSelections, setMainGroupSelections] = useState<
        AInputItem<AAutocompleteValue>[]
    >([]);
    const [subGroupSelections, setSubGroupSelections] = useState<AInputItem<AAutocompleteValue>[]>(
        []
    );
    const [subGroupTargetSelections, setSubGroupTargetSelections] = useState<
        AInputItem<AAutocompleteValue>[]
    >([]);
    const [addDialogData, setAddDialogData] = useState<IMainGroup>({
        Name: '',
        Id: '',
        ChildGroups: [],
    });
    const [selectedMainGroup, setSelectedMainGroup] = useState<AAutocompleteValue>('');
    const [selectedSubGroup, setSelectedSubGroup] = useState<AAutocompleteValue>('');
    const [mainGroupTarget, setMainGroupTarget] = useState<AAutocompleteValue>('');
    const [subGroupTarget, setSubGroupTarget] = useState<AAutocompleteValue>('');
    const [showConfirmDeleteMain, setShowConfirmDeleteMain] = useState<boolean>(false);
    const [showConfirmDeleteSub, setShowConfirmDeleteSub] = useState<boolean>(false);
    const [showConfirmDeleteAnimal, setShowConfirmDeleteAnimal] = useState<boolean>(false);
    const [updateTrigger, setUpdateTrigger] = useState<boolean>(false);
    const [animalsToDelete, setAnimalsToDelete] = useState<IAnimalDeletionModel[]>([]);
    const [dataGridAnimals, setDataGridAnimals] = useState<GridRowSelectionModel>(
        props.selectedAnimals.map((r) => r.Id)
    );

    const [animalsToSave, setAnimalsToSave] = useState<string[]>([]);
    const [subGroupToDelete, setSubGroupToDelete] = useState<ISubGroup>();
    const [mainGroupToDelete, setMainGroupToDelete] = useState<IMainGroup>();
    const [showAnnotation, setShowAnnotation] = useState<boolean>(false);

    useEffect(() => {
        var trackerStatusArray = [];

        for (let i = 0; i < mainGroups.length; i++) {
            var trackerStatusItem: IToggleTracker = {
                id: mainGroups[i].Id,
                toggleStatus: false,
            };
            trackerStatusArray.push(trackerStatusItem);
        }
        setToggleTracker(trackerStatusArray);
    }, [mainGroups]);

    useEffect(() => {
        if (props.selectedAnimals.length > 0) {
            setSelectedDialogOption(2);
        }
    }, []);

    useEffect(() => {
        if (props.allAnimals && props.allAnimals.length > 0) {
            let allAnimalsArray = props.allAnimals.find((a) => a.FilterType === 99);
            if (allAnimalsArray !== undefined && allAnimalsArray.Animals.length > 0) {
                setAnimals(allAnimalsArray.Animals as IAnimalSummaryExtraInfo[]);
            }
        }
    }, [props.allAnimals]);

    useEffect(() => {
        let animalsToSave: string[] = [];
        let animalsToDelete: IAnimalDeletionModel[] = [];
        let childGroupId = '';
        let lastChildGroupId = '';

        for (let i = 0; i < props.selectedAnimals.length; i++) {
            animalsToSave.push(props.selectedAnimals[i].Id);
            let cg = mainGroups.find((m) =>
                m.ChildGroups.find((c) =>
                    c.Animals.find((a) => a.AnimalGUID === props.selectedAnimals[i].Id)
                )
            )?.ChildGroups;

            childGroupId =
                cg?.find(
                    (c) =>
                        c.Animals.find((ca) => ca.AnimalGUID === props.selectedAnimals[i].Id)
                            ?.AnimalGUID
                )?.Id || '';

            if (areGroupIdsInvalid(lastChildGroupId, childGroupId)) setShowAnnotation(true);

            lastChildGroupId = childGroupId;

            animalsToDelete.push({ animalId: props.selectedAnimals[i].Id, groupId: childGroupId });
        }

        setAnimalsToDelete(animalsToDelete);
        setAnimalsToSave(animalsToSave);
    }, [props.selectedAnimals, mainGroups]);

    const dialogOptions: AInputItem<number>[] = [
        { text: t('AnelmaBovine:1101'), value: 1 },
        { text: t('AnelmaBovine:1100'), value: 2 },
    ];

    useEffect(() => {
        setParamBovineSex(paramRepository.resource('AnelmaBovine', 'BovineSex'));
    }, []);

    useEffect(() => {
        if (context.data.currentContext) {
            setIsLoading(true);
            companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    setFarmGuid(response?.Entity.Id || '');
                });

            setIsLoading(false);
        } else setFarmGuid('');
    }, [context]);

    useEffect(() => {
        if (farmGuid.length > 0) {
            groupsApi.getGroups(farmGuid).then((response) => {
                var mainGroupsForSelection: AInputItem<AAutocompleteValue>[] = [];
                setMainGroups(response.Items);
                mainGroupsForSelection.push({
                    text: t('AnelmaBovine:1103'),
                    value: '00000000-0000-0000-0000-000000000000',
                });
                for (let i = 0; i < response.Items.length; i++) {
                    mainGroupsForSelection.push({
                        text: response.Items[i].Name,
                        value: response.Items[i].Id,
                    });
                }
                setMainGroupSelections(mainGroupsForSelection);
            });

            setIsLoading(false);
        }
    }, [farmGuid, updateTrigger]);

    useEffect(() => {
        var subGroupSelections: AInputItem<AAutocompleteValue>[] = [];
        var mainGroup = mainGroups.find((i) => i.Id === selectedMainGroup) || {
            Name: '',
            Id: '',
            ChildGroups: [],
        };
        if (mainGroup !== null && mainGroup.Id !== '00000000-0000-0000-0000-000000000000') {
            for (let i = 0; i < mainGroup.ChildGroups.length; i++) {
                subGroupSelections.push({
                    text: mainGroup.ChildGroups[i].Name,
                    value: mainGroup.ChildGroups[i].Id,
                });
            }
            setSubGroupSelections(subGroupSelections);
        }
    }, [selectedMainGroup]);

    useEffect(() => {
        if (selectedAnimals.length > 0) {
            setDataRows(selectedAnimals.map((i) => mergeRight(i, { id: i.Id })));
        } else {
            setDataRows([]);
        }
    }, [selectedAnimals]);

    useEffect(() => {
        var subGroupTargetSelections: AInputItem<AAutocompleteValue>[] = [];
        setSubGroupTargetSelections(subGroupTargetSelections);
        var mainGroup = mainGroups.find((i) => i.Id === mainGroupTarget) || {
            Name: '',
            Id: '',
            ChildGroups: [],
        };

        if (mainGroup !== null && mainGroup.Id !== '00000000-0000-0000-0000-000000000000') {
            for (let i = 0; i < mainGroup.ChildGroups.length; i++) {
                subGroupTargetSelections.push({
                    text: mainGroup.ChildGroups[i].Name,
                    value: mainGroup.ChildGroups[i].Id,
                });
            }
            setSubGroupTargetSelections(subGroupTargetSelections);
        }
    }, [mainGroupTarget]);

    useEffect(() => {
        let newValues = [...props.selectedAnimals];
        let maingrp = mainGroups.find((i) => i.Id === selectedMainGroup);
        if (maingrp !== undefined && maingrp?.ChildGroups.length > 0) {
            if (selectedSubGroup) {
                let subGrp: ISubGroup = maingrp.ChildGroups.find(
                    (c) => c.Id === selectedSubGroup
                ) || {
                    Animals: [],
                    Id: '',
                    Name: '',
                };
                if (subGrp !== undefined) {
                    for (let ind = 0; ind < subGrp.Animals.length; ind++) {
                        let animal = animals.find(
                            (i) => i.Id === subGrp.Animals[ind].AnimalGUID
                        ) as IAnimalSummary;
                        if (animal) {
                            newValues.push(animal as IAnimalSummary);
                        }
                    }

                    if (subGrp.Animals.length === 0) {
                        newValues = [] as IAnimalSummary[];
                    }
                }
            } else {
                for (let i = 0; i < maingrp?.ChildGroups.length; i++) {
                    var childgrp = maingrp.ChildGroups[i];
                    for (let index = 0; index < childgrp.Animals.length; index++) {
                        let animal = animals.find(
                            (a) => a.Id === childgrp.Animals[index].AnimalGUID
                        ) as IAnimalSummary;
                        if (animal) {
                            newValues.push(animal as IAnimalSummary);
                        }
                    }
                }
            }
        }

        if (selectedMainGroup === '00000000-0000-0000-0000-000000000000') {
            for (let ind = 0; ind < animals.length; ind++) {
                if (
                    !mainGroups.find((m) =>
                        m.ChildGroups.find((c) =>
                            c.Animals.find((a) => a.AnimalGUID === animals[ind].Id)
                        )
                    )
                ) {
                    newValues.push(animals[ind]);
                }
            }
        }
        setSelectedAnimals(newValues);
    }, [selectedMainGroup, selectedSubGroup]);

    const columns: AGridColumns = [
        {
            field: 'EuIdentifier',
            headerName: t('AnelmaBovine:1005'),
            renderCell: (params) => {
                const value = (params.row as IAnimalSummary)?.EuIdentifier;

                let formattedEU = Utils.formatEuIdentifier(value);
                if (formattedEU.indexOf('-')) {
                    const splittedValue = formattedEU.split('-');
                    const start = splittedValue[0].length - 4;
                    const end = splittedValue[0].length;

                    const startStr = formattedEU.substring(0, start);
                    const endStr = formattedEU.substring(start, end);

                    return (
                        <>
                            {startStr}
                            <b>{endStr}</b>-{splittedValue[1]}
                        </>
                    );
                }

                return formattedEU;
            },
            type: 'string',
            width: COL_W_M,

            valueGetter: (params) => {
                return (params.row as IAnimalSummary)?.EuIdentifier ?? '';
            },
        },
        {
            field: 'Sex',
            headerName: t('AnelmaBovine:1009'),
            type: 'string',
            width: COL_W_M,

            valueGetter: (params) => {
                const value = (params.row as IAnimalSummaryExtraInfo)?.Sex;

                return paramBovineSex
                    ? (paramBovineSex.find((p) => value === Number(p.code))?.text as string)
                    : '';
            },
        },
        {
            field: 'AgeMonths',
            headerName: t('AnelmaBovine:1012'),
            type: 'string',
            width: COL_W_S,

            renderCell: (params) => {
                return (
                    utils
                        .calculateAgeInMonths(moment(), moment(params.row.BirthDate), 30.33)
                        .toFixed(1) || ''
                );
            },

            valueGetter: (params) => {
                return utils.calculateAgeInMonths(moment(), moment(params.row.BirthDate), 30.33);
            },
        },

        {
            field: 'MainGroup',
            headerName: t('AnelmaBovine:1102'),
            type: 'string',
            width: COL_W_M,

            valueGetter: (params) => {
                let maingrp = mainGroups.find((i) => i.Id === selectedMainGroup);
                if (maingrp !== undefined && maingrp?.ChildGroups.length > 0) {
                    for (let i = 0; i < maingrp?.ChildGroups.length; i++) {
                        var childgrp = maingrp.ChildGroups[i];
                        var hasAnimal = childgrp.Animals.find(
                            (a) => a.AnimalGUID === params.row.Id
                        );

                        if (hasAnimal) {
                            return maingrp.Name;
                        }
                    }
                } else {
                    return mainGroups.find((m) =>
                        m.ChildGroups.find((c) =>
                            c.Animals.find((a) => a.AnimalGUID === params.row.Id)
                        )
                    )?.Name;
                }
                return params.value ? Number(params.value).toFixed(1) : '';
            },
        },

        {
            field: 'SubGroup',
            headerName: t('AnelmaBovine:1104'),
            type: 'string',
            width: COL_W_M,

            valueGetter: (params) => {
                let maingrp = mainGroups.find((i) => i.Id === selectedMainGroup);
                if (maingrp !== undefined && maingrp?.ChildGroups.length > 0) {
                    for (let i = 0; i < maingrp?.ChildGroups.length; i++) {
                        var childgrp = maingrp.ChildGroups[i];
                        var hasAnimal = childgrp.Animals.find(
                            (a) => a.AnimalGUID === params.row.Id
                        );

                        if (hasAnimal) {
                            return childgrp.Name;
                        }
                    }
                } else {
                    let cg = mainGroups.find((m) =>
                        m.ChildGroups.find((c) =>
                            c.Animals.find((a) => a.AnimalGUID === params.row.Id)
                        )
                    )?.ChildGroups;

                    return cg?.find((c) => c.Animals.find((ca) => ca.AnimalGUID === params.row.Id))
                        ?.Name;
                }
                return params.value ? Number(params.value).toFixed(1) : '';
            },
        },

        {
            field: 'PurchaseDate',
            headerName: t('AnelmaBovine:1013'),
            type: 'string',
            width: COL_W_M,
            renderCell: (params) => <TextWithTooltip text={String(params.value)} />,
            valueGetter: (params) => {
                if (params.row.DateOfPurchase !== null && params.row.DateOfPurchase !== '')
                    return moment(params.row.DateOfPurchase).format(dayFormat);
                return '';
            },
        },
    ];

    const [isLoading, setIsLoading] = useState<boolean>();

    const countAnimalAverageAge = (group: ISubGroup) => {
        let total = 0;
        let divider = 0;
        let averageAge = 0;
        let multiplier = Math.pow(10, 1 || 0);
        if (props.allAnimals != null) {
            for (let index = 0; index < group.Animals.length; index++) {
                let birthDate = props.allAnimals[0].Animals.find(
                    (i) => i.Id === group.Animals[index].AnimalGUID
                )?.BirthDate;
                if (birthDate) {
                    let age = utils.calculateAgeInMonths(moment(), moment(birthDate), 30.33);
                    total += age;
                    if (age != 0) {
                        divider += 1;
                    }
                }

                averageAge = total / divider;
            }
        }
        return Number(Math.round(averageAge * multiplier) / multiplier) || '';
    };

    const handleAnimalDataChange = (params: string[]) => {
        setAddAnimalDialogVisible(false);
        const newValues = [...selectedAnimals];
        for (let index = 0; index < params.length; index++) {
            let animal = animals.find((i) => i.Id === params[index]) as IAnimalSummary;
            if (newValues.includes(animal) === false) newValues.push(animal);
        }
        setSelectedAnimals(newValues);
    };

    const handleAnimalsToSave = (params: GridRowSelectionModel) => {
        var animalsArray = [];
        var animalsToDelete: IAnimalDeletionModel[] = [];

        for (let i = 0; i < params.length; i++) {
            let cg = mainGroups.find((m) =>
                m.ChildGroups.find((c) =>
                    c.Animals.find((a) => a.AnimalGUID === params[i].toString())
                )
            )?.ChildGroups;
            let groupId =
                cg?.find(
                    (c) => c.Animals.find((ca) => ca.AnimalGUID === params[i].toString())?.GroupId
                )?.Id || '';
            animalsArray.push(params[i].toString());
            animalsToDelete.push({ animalId: params[i].toString(), groupId: groupId });
        }

        setAnimalsToSave(animalsArray);
        setAnimalsToDelete(animalsToDelete);
    };

    const updateGroups = () => {
        setUpdateTrigger(!updateTrigger);
        setAddGroupDialogVisible(false);
    };

    const deleteSubDisabled = (item: IMainGroup, child: ISubGroup) => {
        if (item.ChildGroups.length <= 1 || !auth.canDeleteGrouping) {
            return true;
        } else if (item.ChildGroups.find((i) => i.Animals.length > 0 && i.Id === child.Id)) {
            return true;
        } else {
            return false;
        }
    };

    const deleteMainDisabled = (item: IMainGroup) => {
        if (item.ChildGroups.find((i) => i.Animals.length > 0) || !auth.canDeleteGrouping) {
            return true;
        } else {
            return false;
        }
    };

    const areGroupIdsInvalid = (currentGroupId: string, formerGroupId: string) => {
        if (
            !Utils.isGuidEmpty(formerGroupId) &&
            !Utils.isGuidEmpty(currentGroupId) &&
            currentGroupId !== formerGroupId
        )
            return true;
        return false;
    };

    const footerActionButtonsGroups: ADialogButtonsProp = {
        left: [
            {
                onClick: () => {
                    props.onDataChange(mainGroups);
                    props.onClose();
                },

                type: 'cancel',
            },
        ],
        right: [],
    };

    const footerActionButtonsGrouping: ADialogButtonsProp = {
        left: [
            {
                onClick: () => {
                    props.onDataChange(mainGroups);
                    props.onClose();
                },
                type: 'cancel',
            },
            {
                onClick: () => setAddAnimalDialogVisible(true),
                type: 'default',
                label: t('AnelmaBovine:1049'),
                disabled: !auth.canCreateGrouping,
            },
        ],
        right: [
            {
                onClick: () => {
                    if (validator.invalid) {
                        enqueueSnackbar(t('AnelmaGeneral:1169'), {
                            variant: 'error',
                        });
                        return;
                    }
                    if (mainGroupTarget === EmptyGUID) {
                        groupsApi.deleteAnimalsFromGroup(animalsToDelete).then((res) => {
                            setUpdateTrigger(!updateTrigger);
                            enqueueSnackbar(t('AnelmaGeneral:1018'), {
                                variant: 'success',
                            });
                        });
                    } else {
                        groupsApi
                            .setAnimalsInGroup(
                                animalsToSave,
                                selectedSubGroupToString(subGroupTarget)
                            )
                            .then((response) => {
                                let newValues = [...selectedAnimals];
                                newValues = newValues.filter((a) => !animalsToSave.includes(a.Id));
                                setSelectedAnimals(newValues);
                                setUpdateTrigger(!updateTrigger);
                                enqueueSnackbar(t('AnelmaGeneral:1018'), {
                                    variant: 'success',
                                });
                            });
                    }
                },
                disabled:
                    !(
                        (mainGroupTarget === EmptyGUID && animalsToSave.length !== 0) ||
                        ((subGroupTarget || '').toString().length > 0 && animalsToSave.length !== 0)
                    ) ||
                    !auth.canCreateGrouping ||
                    !auth.canDeleteGrouping ||
                    !auth.canUpdateGrouping,
                type: 'save',
            },
        ],
    };

    const openAddDialog = () => {
        setAddGroupDialogVisible(true);
    };

    const selectedSubGroupToString = (value: AAutocompleteValue) => {
        var str = value?.toString() || '';
        return str;
    };

    const deleteSubGroup = () => {
        if (subGroupToDelete) {
            groupsApi.deleteGroup(subGroupToDelete.Id).then(() => {
                enqueueSnackbar(t('AnelmaGeneral:1018'), {
                    variant: 'success',
                });
                updateGroups();
            });
        }
    };

    const deleteAnimalFromGroup = () => {
        groupsApi.deleteAnimalsFromGroup(animalsToDelete).then((res) => {
            setUpdateTrigger(!updateTrigger);
            enqueueSnackbar(t('AnelmaGeneral:1018'), {
                variant: 'success',
            });
        });
    };

    const deleteMainGroup = () => {
        if (mainGroupToDelete) {
            groupsApi.deleteGroup(mainGroupToDelete.Id).then(() => {
                enqueueSnackbar(t('AnelmaGeneral:1018'), {
                    variant: 'success',
                });
                updateGroups();
            });
        }
    };

    const toggleSubItems = (group: IMainGroup, index: number) => {
        const newValues = [...toggleTracker];
        newValues[index].toggleStatus = !newValues[index].toggleStatus;
        setToggleTracker(newValues);
    };

    const toggleChecker = (group: IMainGroup) => {
        return toggleTracker.find((i) => i.id === group.Id)?.toggleStatus;
    };

    const countAnimals = (group: IMainGroup) => {
        var animalCount = 0;
        for (let i = 0; i < group.ChildGroups.length; i++) {
            for (let index = 0; index < group.ChildGroups[i].Animals.length; index++) {
                animalCount += 1;
            }
        }
        return animalCount;
    };

    return (
        <>
            <ADialog open onClose={props.onClose} disableEnforceFocus>
                <ADialogTitle>{t('AnelmaBovine:1101')}</ADialogTitle>
                <ARadioButtons
                    validator={validator}
                    value={selectedDialogOption}
                    id={'dialog-selector'}
                    items={dialogOptions}
                    onChange={(e: any) => setSelectedDialogOption(e)}
                ></ARadioButtons>

                {selectedDialogOption === 2 && (
                    <Grid>
                        {showAnnotation && (
                            <Annotation Annotations={[t('AnelmaBovine:1115')]}></Annotation>
                        )}
                        <ADialogContent size={'lg'} isLoading={isLoading}>
                            {mainGroupSelections.length > 0 && (
                                <Grid container style={{ justifyContent: 'center' }}>
                                    <Grid style={{ flexGrow: 1 }}>
                                        <AAutocomplete
                                            label={t('AnelmaBovine:1102')}
                                            id='selected-main-group'
                                            onChange={(v) => {
                                                setSelectedMainGroup(v);
                                                setSubGroupSelections([]);
                                                setSelectedSubGroup('');
                                            }}
                                            items={mainGroupSelections}
                                            validator={validator}
                                            value={selectedMainGroup}
                                        />
                                    </Grid>
                                    <Grid style={{ flexGrow: 1 }}>
                                        <AAutocomplete
                                            label={t('AnelmaBovine:1105')}
                                            id='main-group-target'
                                            onChange={(v) => {
                                                setMainGroupTarget(v);
                                                setSubGroupTargetSelections([]);
                                                setSubGroupTarget('');
                                            }}
                                            items={mainGroupSelections}
                                            required
                                            validator={validator}
                                            value={mainGroupTarget}
                                            forceValidEmpty
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container style={{ justifyContent: 'center' }}>
                                <Grid style={{ flexGrow: 1, maxWidth: '50%' }}>
                                    {subGroupSelections.length > 0 && (
                                        <AAutocomplete
                                            label={t('AnelmaBovine:1104')}
                                            id='selected-sub-group'
                                            onChange={(v) => {
                                                setSelectedSubGroup(v);
                                            }}
                                            items={subGroupSelections}
                                            validator={validator}
                                            value={selectedSubGroup}
                                        />
                                    )}
                                </Grid>
                                <Grid style={{ flexGrow: 1, maxWidth: '50%' }}>
                                    {subGroupTargetSelections.length > 0 && (
                                        <AAutocomplete
                                            label={t('AnelmaBovine:1106')}
                                            id='sub-group-target'
                                            onChange={(v) => {
                                                setSubGroupTarget(v);
                                            }}
                                            items={subGroupTargetSelections}
                                            required
                                            validator={validator}
                                            value={subGroupTarget}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid>
                                <ADataGrid
                                    actions={[
                                        (params) => {
                                            return (
                                                selectedMainGroup !==
                                                    '00000000-0000-0000-0000-000000000000' && (
                                                    <Grid>
                                                        <ADeleteIconButton
                                                            key={`${params.id}-delete`}
                                                            onClick={() => {
                                                                setShowConfirmDeleteAnimal(true);
                                                                var animalToDelete: IAnimalDeletionModel[] =
                                                                    [];
                                                                let childGroupId = '';

                                                                let cg = mainGroups.find((m) =>
                                                                    m.ChildGroups.find((c) =>
                                                                        c.Animals.find(
                                                                            (a) =>
                                                                                a.AnimalGUID ===
                                                                                params.row.Id
                                                                        )
                                                                    )
                                                                )?.ChildGroups;

                                                                childGroupId =
                                                                    cg?.find(
                                                                        (c) =>
                                                                            c.Animals.find(
                                                                                (ca) =>
                                                                                    ca.AnimalGUID ===
                                                                                    params.row.Id
                                                                            )?.AnimalGUID
                                                                    )?.Id || '';

                                                                animalToDelete.push({
                                                                    animalId: params.row.Id,
                                                                    groupId: childGroupId,
                                                                });

                                                                setAnimalsToDelete(animalToDelete);
                                                            }}
                                                        />
                                                        <ConfirmDeletionDialog
                                                            open={showConfirmDeleteAnimal}
                                                            onClose={() =>
                                                                setShowConfirmDeleteAnimal(false)
                                                            }
                                                            onDelete={() => deleteAnimalFromGroup()}
                                                            confirmation={t(
                                                                'AnelmaBovine:1117'
                                                            ).replace(
                                                                '{EUIdentifier}',
                                                                params.row.EuIdentifier
                                                            )}
                                                        ></ConfirmDeletionDialog>
                                                    </Grid>
                                                )
                                            );
                                        },
                                    ]}
                                    columns={columns}
                                    rows={dataRows}
                                    enableFiltering={true}
                                    onSelectionModelChange={(selectionModel) => {
                                        handleAnimalsToSave(selectionModel);
                                        setDataGridAnimals(selectionModel);
                                    }}
                                    selectionModel={dataGridAnimals}
                                    enablePagination
                                ></ADataGrid>
                            </Grid>
                        </ADialogContent>
                        <Grid className='animal-announcement-grid-floating-bottom'>
                            <ADialogActions
                                buttons={footerActionButtonsGrouping}
                                hasBorder={false}
                            />
                        </Grid>
                    </Grid>
                )}

                {selectedDialogOption === 1 && (
                    <Grid>
                        <ADialogContent size={'lg'} isLoading={isLoading}>
                            <Table style={{ maxWidth: '80%', margin: '10px 40px' }}>
                                <TableRow>
                                    <TableCell style={{ minWidth: '50px' }}>#</TableCell>

                                    <TableCell style={{ minWidth: '50px' }}></TableCell>
                                    <TableCell style={{ minWidth: '80px' }}>
                                        {t('AnelmaBovine:1102')}
                                    </TableCell>
                                    <TableCell style={{ minWidth: '80px' }}>
                                        {t('AnelmaBovine:1110')}
                                    </TableCell>
                                    <TableCell>{t('AnelmaBovine:1037')}</TableCell>
                                </TableRow>
                                {mainGroups.map((item, index) => {
                                    return (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ maxWidth: '10px' }}>
                                                    {toggleChecker(item) === false && (
                                                        <ExpandMore
                                                            onClick={() =>
                                                                toggleSubItems(item, index)
                                                            }
                                                        />
                                                    )}
                                                    {toggleChecker(item) === true && (
                                                        <ExpandLess
                                                            onClick={() =>
                                                                toggleSubItems(item, index)
                                                            }
                                                        />
                                                    )}
                                                </TableCell>

                                                <TableCell style={{ minWidth: '15px' }}>
                                                    <ADeleteIconButton
                                                        style={{ marginRight: '20px' }}
                                                        onClick={() => {
                                                            setMainGroupToDelete(item);
                                                            setShowConfirmDeleteMain(true);
                                                        }}
                                                        disabled={deleteMainDisabled(item)}
                                                    />
                                                    <AEditIconButton
                                                        onClick={() => {
                                                            setAddDialogData(item);
                                                            setAddDialogMode('edit');
                                                            openAddDialog();
                                                        }}
                                                        disabled={!auth.canUpdateGrouping}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ minWidth: '50px' }}>
                                                    {item.Name}
                                                </TableCell>
                                                <TableCell style={{ minWidth: '50px' }}>
                                                    {item.ChildGroups.length}
                                                </TableCell>
                                                <TableCell style={{ minWidth: '50px' }}>
                                                    {countAnimals(item)}
                                                </TableCell>

                                                <ConfirmDeletionDialog
                                                    open={showConfirmDeleteMain}
                                                    onClose={() => setShowConfirmDeleteMain(false)}
                                                    onDelete={() => deleteMainGroup()}
                                                    confirmation={t('AnelmaBovine:1114')}
                                                ></ConfirmDeletionDialog>
                                            </TableRow>
                                            {toggleChecker(item) && (
                                                <TableRow>
                                                    <TableCell
                                                        style={{ minWidth: '50px' }}
                                                    ></TableCell>

                                                    <TableCell style={{ minWidth: '80px' }}>
                                                        {t('AnelmaBovine:1104')}
                                                    </TableCell>

                                                    <TableCell>{t('AnelmaBovine:1037')}</TableCell>
                                                    <TableCell>{t('AnelmaBovine:1166')}</TableCell>
                                                </TableRow>
                                            )}
                                            {toggleChecker(item) &&
                                                item.ChildGroups.map((child, ind) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell style={{ minWidth: '15px' }}>
                                                                <ADeleteIconButton
                                                                    style={{ marginRight: '20px' }}
                                                                    disabled={deleteSubDisabled(
                                                                        item,
                                                                        child
                                                                    )}
                                                                    onClick={() => {
                                                                        setSubGroupToDelete(child);
                                                                        setShowConfirmDeleteSub(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            </TableCell>

                                                            <TableCell style={{ minWidth: '50px' }}>
                                                                {child.Name}
                                                            </TableCell>
                                                            <TableCell style={{ minWidth: '50px' }}>
                                                                {child.Animals.length}
                                                            </TableCell>
                                                            <TableCell style={{ minWidth: '50px' }}>
                                                                {countAnimalAverageAge(child)}
                                                            </TableCell>
                                                            <ConfirmDeletionDialog
                                                                open={showConfirmDeleteSub}
                                                                onClose={() =>
                                                                    setShowConfirmDeleteSub(false)
                                                                }
                                                                onDelete={() => deleteSubGroup()}
                                                                confirmation={t(
                                                                    'AnelmaBovine:1114'
                                                                )}
                                                            ></ConfirmDeletionDialog>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    );
                                })}
                            </Table>

                            <Grid
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    paddingRight: '20px',
                                }}
                            >
                                <AAddButton
                                    onClick={() => {
                                        setAddDialogMode('add');
                                        openAddDialog();
                                    }}
                                    type='action'
                                    disabled={!auth.canCreateGrouping}
                                >
                                    {t('AnelmaBovine:1111')}
                                </AAddButton>
                            </Grid>
                        </ADialogContent>
                        <ADialogActions buttons={footerActionButtonsGroups} />
                    </Grid>
                )}

                {addAnimalDialogVisible && (
                    <AddAnimalDialog
                        onClose={() => setAddAnimalDialogVisible(false)}
                        animals={animals}
                        fromDialog={SecondaryDialogTypeEnum.AddAnimal}
                        handleAnimalDataChange={handleAnimalDataChange}
                    />
                )}

                {addGroupDialogVisible && (
                    <AddGroupDialog
                        onClose={() => updateGroups()}
                        mode={addDialogMode}
                        data={addDialogData}
                        existingGroups={mainGroups}
                    />
                )}
            </ADialog>
        </>
    );
}
