import auth from '../../../core/authorization';

export function isAdminUser(): boolean {
    return auth.hasAccess('AnelmaProductionPlanAdmin', 'LIST');
}

export function canCreate(): boolean {
    return auth.hasAccess('AnelmaProductionPlanCattle', 'CREATE')
        || auth.hasAccess('AnelmaProductionPlanAdmin', 'CREATE');
}

export function canUpdate(): boolean {
    return auth.hasAccess('AnelmaProductionPlanCattle', 'UPDATE')
        || auth.hasAccess('AnelmaProductionPlanAdmin', 'UPDATE');
}

export function canDelete(): boolean {
    return auth.hasAccess('AnelmaProductionPlanAdmin', 'DELETE');
}